import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { useNavigate, useParams } from "react-router-dom";
import Sheet from "react-modal-sheet";
import axios from "axios";

// components
import { GetOne } from "../../tool/localstorage";
import { fbevent } from "../../tool/firebase";

// img
import BackArrow from "../../icon/backArrow.png";
import colors from "../../styles/colors";

const H = window.innerHeight;

const snap = H < 740 ? 0.3 : 0.25;
const snap2 = H < 740 ? 0.25 : 0.22;

function ReviewReport() {
  const navigate = useNavigate();
  const { r } = useParams();
  const [modal, setModal] = useState({
    back: false,
    report: false,
  });
  const [text, setText] = useState("");
  const [focus, setFocus] = useState(false);

  const backFunction = (boolean) => {
    if (boolean) {
      navigate(-1);
    } else {
      setModal((prev) => ({ ...prev, back: false }));
    }
  };

  useEffect(() => {
    fbevent("B2B_ReviewReport_Page_View", {
      email : GetOne("email")
    });
  }, []);

  const reportFunction = () => {
    fbevent("B2B_ReviewReport_Report_Click", {
      content : text
    });

    var token = GetOne("token");

    const frm = new FormData();
    frm.append("mode", "review_policy");
    frm.append("token", token);
    frm.append("review_number", r);
    frm.append("text", text);

    axios
      .post("https://ai.semos.kr/semos_partner/coaching/review", frm, {
        headers: { "Content-Type": "multipart/form-data" },
      })
      .then((data) => {
        var Value = data.data;
        var Status = Value.status;
        var StatusMessage = Value.status_message;

        if (Status == "success") {
          setModal((prev) => ({ ...prev, report: true }));
        } else {
          window.alert(StatusMessage);
          setModal((prev) => ({ ...prev, report: true }));
        }
      });
  };

  return (
    <>
      <Container>
        <Header>
          <Arrow src={BackArrow} onClick={() => setModal((prev) => ({ ...prev, back: true }))} />
          <HeaderTitle>신고하기</HeaderTitle>
          <Box />
        </Header>
        <Modal>
          <div>
            <ModalHeader>해당 리뷰를 신고하시겠어요?</ModalHeader>
            <Divider h={10} />
            <ModalText>관리자 확인 후 근거 없는 신고 내용은 반영되지 않을 수 있어요.</ModalText>
            <ModalText>신고를 원하시면 ‘신고하기' 버튼을 눌러주세요.</ModalText>
            <Divider h={24} />
            <TextInputContainer focus={focus}>
              <TextInput
                disabled={modal.back || modal.report}
                placeholder="신고 내용을 작성해 주세요! *최대 200자"
                onChange={(e) => setText(e.target.value)}
                onFocus={() => setFocus(true)}
                onBlur={() => setFocus(false)}
                maxLength="200"
                value={text}
              />
            </TextInputContainer>
          </div>
          <ButtonContainer>
            <CancelButton onClick={() => backFunction(true)}>취소</CancelButton>
            <StayButton onClick={() => text && reportFunction()} active={text}>
              {text ? "신고하기" : "신고 내용을 작성해 주세요"}
            </StayButton>
          </ButtonContainer>
        </Modal>
      </Container>
      <BackButtonModal isOpen={modal.back} snapPoints={[snap]}>
        <BackButtonModal.Container>
          <BackModalLayout>
            <ModalText>지금 뒤로 가면 입력한 내용이 저장되지 않아요!</ModalText>
            <ModalText>정말 뒤로 가시겠어요?</ModalText>
            <Divider h={36} />
            <ButtonContainer>
              <BackButton onClick={() => backFunction(true)}>뒤로가기</BackButton>
              <StayButton onClick={() => backFunction(false)} active={true}>
                계속 작성하기
              </StayButton>
            </ButtonContainer>
          </BackModalLayout>
        </BackButtonModal.Container>
        <BackButtonModal.Backdrop />
      </BackButtonModal>
      <ReportModal isOpen={modal.report} snapPoints={[snap2]}>
        <ReportModal.Container>
          <BackModalLayout>
            <ModalHeader>리뷰 신고를 완료했어요!</ModalHeader>
            <Divider h={36} />
            <ConfirmButton
              onClick={() => {
                setModal((prev) => ({ ...prev, report: false }));
                navigate(-1, { replace: true });
              }}
            >
              확인
            </ConfirmButton>
          </BackModalLayout>
        </ReportModal.Container>
        <ReportModal.Backdrop />
      </ReportModal>
    </>
  );
}

export default ReviewReport;

const Container = styled.div`
  /* height: calc(var(--vh, 1vh) * 100); */
  height: 100%;

  overflow-x: hidden;
  background-color: ${colors.White};
`;

const Divider = styled.div`
  height: ${({ h }) => h}px;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px 22px;
  box-shadow: 0px 3px 8px ${colors.B10};
  position: sticky;
  top: 0;
  background-color: ${colors.White};
  z-index: 10;
`;

const Arrow = styled.img`
  width: 24px;
  height: 24px;
  opacity: ${({ hidden }) => (hidden ? "0" : "1")};
`;

const HeaderTitle = styled.div`
  font-size: 18px;
  font-weight: 600;
  line-height: 25.56px;
  color: ${colors.Gr06};
`;

const Box = styled.div`
  width: 24px;
  height: 24px;
`;

const Modal = styled.div`
  padding: 36px 20px 10px 24px;
  /* background-color: black; */
  height: ${H - 50}px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-bottom: 42px;
`;

const TextInputContainer = styled.div`
  border: 1px solid;
  border-radius: 8px;
  width: 100%;
  height: 200px;
  padding: 16px;
  border-color: ${({ focus }) => (focus ? colors.Gr04 : colors.Gr02)};
`;

const TextInput = styled.textarea`
  width: 100%;
  height: 100%;
  outline: none;
  border: none;
  border-radius: 8px;
  resize: none;
`;

const ModalHeader = styled.div`
  font-weight: 600;
  font-size: 18px;
  line-height: 142%;
  letter-spacing: -0.02em;
  color: ${colors.Gr06};
`;

const ModalText = styled.div`
  font-weight: 500;
  font-size: 14px;
  line-height: 142%;
  /* or 20px */

  letter-spacing: -0.02em;

  /* Greyscale/Gr 02 */

  color: ${colors.Gr04};
`;

const BackButtonModal = styled(Sheet)``;

const BackModalLayout = styled.div`
  padding: 36px 24px 20px 24px;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Button = styled.div`
  font-weight: 500;
  font-size: 14px;
  line-height: 142%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  letter-spacing: -0.02em;
  width: 48%;
  padding: 18px 0;
  border-radius: 8px;
`;

const BackButton = styled(Button)`
  color: ${colors.Bl07};
`;

const StayButton = styled(Button)`
  color: ${colors.White};
  background-color: ${({ active }) => (active ? colors.Bl07 : colors.Gr02)};
`;

const CancelButton = styled(Button)`
  color: ${colors.Gr05};
  background-color: ${colors.Bl02};
`;

const ReportModal = styled(Sheet)``;

const ConfirmButton = styled.div`
  font-weight: 500;
  font-size: 14px;
  line-height: 142%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  letter-spacing: -0.02em;
  width: 100%;
  padding: 18px 0;
  border-radius: 8px;
  color: ${colors.White};
  background-color: ${colors.Bl07};
`;
