import React, { useState } from "react";
import styled from "styled-components";

// components
import IdCardView from "../../components/signup/id_card_view";
import AccountView from "../../components/signup/account_view";
import PayId from "../../components/signup/pay_id_view";
import Tooltip from "../elements/tooltip";

// style
import colors from "../../styles/colors";

// img
import waringGrey from "../../icon/waringGrey.png";
import { ReactComponent as CheckCircle } from "../../icon/checkCircle.svg";

const FOCUS_STATUS = {
  default: "default",
  focus: "focus",
  blur: "blur",
};

/**
 * isRevise
 * true : 프로필 편집 페이지에서 import
 * false : 파트너 등록 페이지에서 import
 */
function AccountInfoInput({
  info,
  setInfo,
  bank,
  setBank,
  valid,
  setValid,
  isRevise = false,
  profileUpdated = false,
}) {
  const [document, setDocument] = useState("");

  const [focus, setFocus] = useState({
    account_number: FOCUS_STATUS.default,
    date_of_birth: FOCUS_STATUS.default,
    id_number: FOCUS_STATUS.default,
    register_number: FOCUS_STATUS.default,
  });

  const autoHyphen = (companyNum) => {
    companyNum = companyNum.replace(/[^0-9]/g, "");
    let tempNum = "";

    if (companyNum.length < 4) {
      return companyNum;
    } else if (companyNum.length < 6) {
      tempNum += companyNum.substr(0, 3);
      tempNum += "-";
      tempNum += companyNum.substr(3, 2);
      return tempNum;
    } else if (companyNum.length < 11) {
      tempNum += companyNum.substr(0, 3);
      tempNum += "-";
      tempNum += companyNum.substr(3, 2);
      tempNum += "-";
      tempNum += companyNum.substr(5);
      return tempNum;
    } else {
      tempNum += companyNum.substr(0, 3);
      tempNum += "-";
      tempNum += companyNum.substr(3, 2);
      tempNum += "-";
      tempNum += companyNum.substr(5);
      return tempNum;
    }
  };

  // 사업자등록번호
  const CompanyNumber = (number) => {
    let CNV = autoHyphen(number);

    CNV = CNV.substr(0, 12);

    setInfo((prev) => ({
      ...prev,
      register_number: CNV,
    }));

    CNV.length === 12
      ? setValid((prev) => ({ ...prev, register_number: true }))
      : setValid((prev) => ({ ...prev, register_number: false }));
  };

  // 사업자 등록번호 VALIDATION
  // const companyNumberValid = () => {
  //   // setFocus((prev) => ({ ...prev, company: "default" }));
  //   let number = info.register_number;

  //   let numberMap = number
  //     .replace(/-/gi, "")
  //     .split("")
  //     .map(function (d) {
  //       return parseInt(d, 10);
  //     });

  //   if (numberMap.length == 10) {
  //     let keyArr = [1, 3, 7, 1, 3, 7, 1, 3, 5];
  //     let chk = 0;

  //     keyArr.forEach(function (d, i) {
  //       chk += d * numberMap[i];
  //     });

  //     chk += parseInt((keyArr[8] * numberMap[8]) / 10, 10);

  //     setValid((prev) => ({
  //       ...prev,
  //       register_number: Math.floor(numberMap[9]) === (10 - (chk % 10)) % 10,
  //     }));
  //   } else {
  //     setValid((prev) => ({
  //       ...prev,
  //       register_number: false,
  //     }));
  //   }
  // };

  // 계좌번호
  const accountNumber = (number) => {
    let value = number.replace(/[^0-9]/g, "");

    value = value.substr(0, 20);

    setInfo((prev) => ({
      ...prev,
      account_number: value,
    }));

    value.length > 4
      ? setValid((prev) => ({ ...prev, account_number: true }))
      : setValid((prev) => ({ ...prev, account_number: false }));
  };

  // 주민등록번호
  const idNumberSet = (text, type) => {
    let idnum = text.replace(/[^0-9]/g, "");

    if (type == "birth") {
      idnum = idnum.substr(0, 6);

      setInfo((prev) => ({
        ...prev,
        date_of_birth: idnum,
      }));

      idnum.length === 6
        ? setValid((prev) => ({ ...prev, date_of_birth: true }))
        : setValid((prev) => ({ ...prev, date_of_birth: false }));
    } else {
      idnum = idnum.substr(0, 7);

      setInfo((prev) => ({
        ...prev,
        id_number: idnum,
      }));

      idnum.length === 7
        ? setValid((prev) => ({ ...prev, id_number: true }))
        : setValid((prev) => ({ ...prev, id_number: false }));
    }
  };

  return (
    <>
      {/* 개인/센터 여부 */}
      {isRevise ? (
        <></>
      ) : (
        <BackgroundContent
          wrap={`wrap`}
          padding={`16px 24px 0 24px`}
          ai={`center`}
        >
          {/* 개인/센터 툴팁 */}
          <TextContent
            width={`100%`}
            ai="center"
            style={{ justifyContent: "flex-start" }}
          >
            <TextView
              color={colors.Gr05}
              fw={`500`}
              fs={`14px`}
              lh={`142%`}
            >{`개인/센터 여부`}</TextView>
            <Tooltip
              boxLeft="-106px"
              boxRight="4px"
              left="93px"
              right="7px"
              type="left"
              title=""
            >
              <TooltipContainer>
                <TooltipTitle>개인 파트너</TooltipTitle>
                <TooltipText isLast={false}>
                  • 홈택스 전자세금계산서 발행 불가능 {`\n`}• 프리랜서 or
                  면세사업자 {`\n`}• 개설한 액티비티는 본인만 진행 가능 {`\n`}•
                  센터 소속 강사의 경우에도 개인으로 등록
                </TooltipText>
                <TooltipTitle>센터 파트너</TooltipTitle>
                <TooltipText isLast={false}>
                  • 홈택스 전자세금계산서 발행 가능 {`\n`}• 일반사업자 or
                  간이과세사업자 {`\n`}• 개설한 액티비티는 센터 산하 강사에게
                  자유롭게 배정 가능
                </TooltipText>
                <TooltipAlert>
                  <TooltipAlertTitle>*주의</TooltipAlertTitle>
                  <TooltipText isLast={true}>
                    개인/센터 여부는 최초 선택 이후 변경 불가해요! {`\n`}
                    추후 변경을 원하는 경우 세모스 고객센터로 문의주세요
                  </TooltipText>
                </TooltipAlert>
              </TooltipContainer>
            </Tooltip>
          </TextContent>

          <BackgroundContent jc={`flex-start`} padding={`8px 0`} gap={"8px"}>
            <TextContent width={`82px`} jc="center">
              <TextContent
                cursor="pointer"
                border={`1px solid ${
                  info.type === 0 ? `${colors.Bl07}` : `${colors.Gr02}`
                }`}
                height="40px"
                width="100%"
                jc="center"
                padding={`10px 0`}
                br={`8`}
                onClick={() => setInfo((prev) => ({ ...prev, type: 0 }))}
              >
                <TextView
                  color={info.type === 0 ? colors.Bl07 : colors.Gr03}
                  fw={`500`}
                  fs={`14px`}
                  lh={`142%`}
                >{`개인`}</TextView>
              </TextContent>
            </TextContent>

            <TextContent width={`82px`} jc="center">
              <TextContent
                cursor="pointer"
                border={`1px solid ${
                  info.type == 1 ? colors.Bl07 : colors.Gr02
                }`}
                height="40px"
                width="100%"
                jc="center"
                padding={`10px 0`}
                br={`8`}
                onClick={() => setInfo((prev) => ({ ...prev, type: 1 }))}
              >
                <TextView
                  color={info.type == 1 ? colors.Bl07 : colors.Gr03}
                  fw={`500`}
                  fs={`14px`}
                  lh={`142%`}
                >{`센터`}</TextView>
              </TextContent>
            </TextContent>
          </BackgroundContent>
        </BackgroundContent>
      )}

      {/* 정산 정보 */}
      <BackgroundContent
        wrap={`wrap`}
        padding={`16px 24px 0 24px`}
        ai={`center`}
      >
        <TextContent width={`100%`} style={{ justifyContent: "flex-start" }}>
          <TextView
            color={colors.Gr05}
            fw={`500`}
            fs={`14px`}
            lh={`142%`}
          >{`정산 정보`}</TextView>
        </TextContent>

        <BackgroundContent
          jc={`space-between`}
          padding={`8px 0`}
          ai={`center`}
          gap={"8px"}
        >
          <TextContent
            cursor="pointer"
            onClick={() => setBank(true)}
            jc={`center`}
            width={`82px`}
            height={"40px"}
            padding={`10px 0`}
            br={`8`}
            border={`1px solid ${bank ? colors.Bl07 : colors.Gr02}`}
          >
            <TextView
              color={info.bank ? colors.Gr05 : colors.Gr03}
              fw={`500`}
              fs={`14px`}
              lh={`142%`}
            >
              {info.bank ? info.bank : "은행 선택"}
            </TextView>
          </TextContent>

          <InputContainer
            style={{ flex: 1 }}
            isValid={valid.account_number}
            focus={focus.account_number}
          >
            <AccountInput
              type="tel"
              value={info.account_number}
              placeholder="계좌번호를 입력해 주세요"
              onChange={(e) => accountNumber(e.target.value)}
              maxLength={20}
              onFocus={() =>
                setFocus((prev) => ({
                  ...prev,
                  account_number: FOCUS_STATUS.focus,
                }))
              }
              onBlur={() =>
                setFocus((prev) => ({
                  ...prev,
                  account_number: FOCUS_STATUS.blur,
                }))
              }
            />
          </InputContainer>
        </BackgroundContent>
      </BackgroundContent>

      {/* 주민등록번호 */}
      <BackgroundContent
        wrap={`wrap`}
        padding={`16px 24px 0 24px`}
        ai={`center`}
      >
        <TextContent width={`100%`} style={{ justifyContent: "flex-start" }}>
          <TextView
            color={colors.Gr05}
            fw={`500`}
            fs={`14px`}
            lh={`142%`}
          >{`주민등록번호`}</TextView>
        </TextContent>

        <BackgroundContent
          jc={`space-between`}
          padding={`8px 0`}
          ai={`center`}
          gap={"8px"}
        >
          <InputContainer
            style={{ flex: 1 }}
            isValid={valid.date_of_birth}
            focus={focus.date_of_birth}
          >
            <Input
              type="tel"
              maxLength={6}
              value={info.date_of_birth}
              placeholder="생년월일"
              onChange={(e) => idNumberSet(e.target.value, "birth")}
              onFocus={() =>
                setFocus((prev) => ({
                  ...prev,
                  date_of_birth: FOCUS_STATUS.focus,
                }))
              }
              onBlur={() =>
                setFocus((prev) => ({
                  ...prev,
                  date_of_birth: FOCUS_STATUS.blur,
                }))
              }
            />
          </InputContainer>

          <InputContainer
            style={{ flex: 1 }}
            isValid={valid.id_number}
            focus={focus.id_number}
          >
            <IDInput
              type="tel"
              value={info.id_number}
              maxLength={7}
              onFocus={() =>
                setFocus((prev) => ({ ...prev, id_number: FOCUS_STATUS.focus }))
              }
              onBlur={() =>
                setFocus((prev) => ({ ...prev, id_number: FOCUS_STATUS.blur }))
              }
              placeholder="주민등록번호 뒷자리"
              onChange={(e) => idNumberSet(e.target.value, "idnum")}
            />
          </InputContainer>
        </BackgroundContent>
      </BackgroundContent>

      {/* 사업자 등록번호 */}
      {Number(info.type) === 0 ? (
        <></>
      ) : (
        <BackgroundContent
          wrap={`wrap`}
          padding={`16px 24px 0 24px`}
          ai={`center`}
        >
          <TextContent width={`100%`} style={{ justifyContent: "flex-start" }}>
            <TextView
              color={colors.Gr05}
              fw={`500`}
              fs={`14px`}
              lh={`142%`}
            >{`사업자등록번호`}</TextView>
          </TextContent>

          <BackgroundContent jc={`center`} padding={`8px 0`} ai={`center`}>
            <InputContainer
              w={100}
              isValid={valid.register_number}
              focus={focus.register_number}
            >
              <Input
                type="tel"
                value={info.register_number}
                placeholder="000 - 00 - 00000"
                onChange={(e) => CompanyNumber(e.target.value)}
                //   maxLength={`12`}
                onFocus={() =>
                  setFocus((prev) => ({
                    ...prev,
                    register_number: FOCUS_STATUS.focus,
                  }))
                }
                onfocus
                onBlur={() => {
                  // companyNumberValid();
                  setTimeout(() => {
                    setFocus((prev) => ({
                      ...prev,
                      register_number: FOCUS_STATUS.blur,
                    }));
                  }, 2000);
                }}
              />
            </InputContainer>
          </BackgroundContent>
        </BackgroundContent>
      )}

      {/* 서류 편집하기 */}
      <BackgroundContent
        wrap={`wrap`}
        padding={`24px 24px 0 24px`}
        ai={`center`}
        style={{ marginBottom: isRevise ? "10px" : "48px" }}
      >
        <TextContent width={`100%`} style={{ justifyContent: "flex-start" }}>
          <TextView
            color={colors.Gr05}
            fw={`500`}
            fs={`14px`}
            lh={`142%`}
          >{`서류 편집하기`}</TextView>
        </TextContent>
        <Divider height={"8"} color={colors.White} />
        <TextContent width={`100%`} jc={`space-between`}>
          <ImageIcon100 width={`16px`} height={`16px`}>
            <img src={waringGrey} />
          </ImageIcon100>

          <TextView
            width={`calc(100% - 20px)`}
            color={colors.Gr04}
            fw={`500`}
            fs={`12px`}
            lh={`142%`}
          >{`프리랜서 / 개인사업자인 경우 사업자 등록증 생략이 가능해요`}</TextView>
        </TextContent>

        <Divider height={"8"} color={colors.White} />

        <BackgroundContent
          jc={`space-between`}
          padding={`8px 0`}
          //   ai={`center`}
          gap={"8px"}
        >
          {info.id_card ? (
            <TextContent
              cursor="pointer"
              onClick={() => setDocument("id")}
              ai="center"
              jc={`center`}
              padding={`10px 0`}
              br={`8`}
              border={
                info.id_card &&
                `1px solid ${document == "id" ? colors.Bl07 : colors.Gr02}`
              }
              style={{ flexDirection: "column", flex: 1 }}
            >
              {info.id_card && <Preview src={info.id_card} />}
              <TextContent
                ai="center"
                jc={`center`}
                width={`100%`}
                br={`8`}
                border={
                  !info.id_card &&
                  `1px solid ${document == "id" ? colors.Bl07 : colors.Gr02}`
                }
                style={{ flexDirection: "column" }}
              >
                <TextView
                  color={document == "id" ? colors.Bl07 : colors.Gr03}
                  fw={`500`}
                  fs={`14px`}
                  lh={`142%`}
                >{`신분증`}</TextView>
              </TextContent>
            </TextContent>
          ) : (
            <TextContent
              // width={`calc((100% - 24px) / 3)`}
              style={{ flex: 1 }}
              jc="center"
            >
              <TextContent
                border={`1px solid ${
                  document == "id" ? colors.Bl07 : colors.Gr02
                }`}
                height="40px"
                width="100%"
                jc="center"
                padding={`10px 0`}
                br={`8`}
                onClick={() => setDocument("id")}
                cursor="pointer"
              >
                <TextView
                  color={document == "id" ? colors.Bl07 : colors.Gr03}
                  fw={`500`}
                  fs={`14px`}
                  lh={`142%`}
                >{`신분증`}</TextView>
              </TextContent>
            </TextContent>
          )}

          {info.bankbook ? (
            <TextContent
              cursor="pointer"
              onClick={() => setDocument("account")}
              ai="center"
              jc={`center`}
              // width={`calc((100% - 24px) / 3)`}
              padding={`10px 0`}
              br={`8`}
              border={`1px solid ${
                document == "account" ? colors.Bl07 : colors.Gr02
              }`}
              style={{ flexDirection: "column", flex: 1 }}
            >
              <Preview src={info.bankbook} />
              <TextView
                color={document == "account" ? colors.Bl07 : colors.Gr03}
                fw={`500`}
                fs={`14px`}
                lh={`142%`}
              >{`통장사본`}</TextView>
            </TextContent>
          ) : (
            <TextContent
              // width={`calc((100% - 24px) / 3)`}
              style={{ flex: 1 }}
              jc="center"
            >
              <TextContent
                cursor="pointer"
                border={`1px solid ${
                  document == "account" ? colors.Bl07 : colors.Gr02
                }`}
                height="40px"
                width="100%"
                jc="center"
                padding={`10px 0`}
                br={`8`}
                onClick={() => setDocument("account")}
              >
                <TextView
                  color={document == "account" ? colors.Bl07 : colors.Gr03}
                  fw={`500`}
                  fs={`14px`}
                  lh={`142%`}
                >{`통장사본`}</TextView>
              </TextContent>
            </TextContent>
          )}

          {/* 사업자등록증 */}
          {info.type == 1 ? (
            <>
              {info.register_card ? (
                <TextContent
                  cursor="pointer"
                  onClick={() => setDocument("pay_id")}
                  jc={`center`}
                  ai="center"
                  padding={`10px 0`}
                  br={`8`}
                  border={`1px solid ${
                    document == "pay_id" ? colors.Bl07 : colors.Gr02
                  }`}
                  style={{ flexDirection: "column", flex: 1 }}
                >
                  <Preview src={info.register_card} />
                  <TextView
                    color={document == "pay_id" ? colors.Bl07 : colors.Gr03}
                    fw={`500`}
                    fs={`14px`}
                    lh={`142%`}
                  >{`사업자등록증`}</TextView>
                </TextContent>
              ) : (
                <TextContent style={{ flex: 1 }} jc="center">
                  <TextContent
                    cursor="pointer"
                    border={`1px solid ${
                      document == "pay_id" ? colors.Bl07 : colors.Gr02
                    }`}
                    height="40px"
                    width="100%"
                    jc="center"
                    padding={`10px 0`}
                    br={`8`}
                    onClick={() => setDocument("pay_id")}
                  >
                    <TextView
                      color={document == "pay_id" ? colors.Bl07 : colors.Gr03}
                      fw={`500`}
                      fs={`14px`}
                      lh={`142%`}
                    >{`사업자등록증`}</TextView>
                  </TextContent>
                </TextContent>
              )}
            </>
          ) : (
            <TextContent
              jc={`center`}
              ai="center"
              // width={`calc((100% - 24px) / 3)`}
              padding={`10px 0`}
              br={`8`}
              border={`1px solid transparent`}
              style={{ flexDirection: "column", flex: 1 }}
            ></TextContent>
          )}
        </BackgroundContent>

        {/* 사진 등록 */}
        {document == "id" && (
          <>
            <IdCardView data={info.id_card} setData={setInfo} />
            {info.id_card && (
              <TextContent jc="center" gap="6px">
                <CheckCircle fill={colors.Bl05} />
                <TextView color={colors.Bl05} fs="12px" fw="700" lh="142%">
                  등록을 완료했어요!
                </TextView>
              </TextContent>
            )}
          </>
        )}

        {document == "account" && (
          <>
            <AccountView data={info.bankbook} setData={setInfo} />
            {info.bankbook && (
              <TextContent jc="center" gap="6px">
                <CheckCircle fill={colors.Bl05} />
                <TextView color={colors.Bl05} fs="12px" fw="700" lh="142%">
                  등록을 완료했어요!
                </TextView>
              </TextContent>
            )}
          </>
        )}

        {info.type == 1 && document == "pay_id" && (
          <>
            <PayId data={info.register_card} setData={setInfo} />
            {info.register_card && (
              <TextContent jc="center" gap="6px">
                <CheckCircle fill={colors.Bl05} />
                <TextView color={colors.Bl05} fs="12px" fw="700" lh="142%">
                  등록을 완료했어요!
                </TextView>
              </TextContent>
            )}
          </>
        )}
      </BackgroundContent>
    </>
  );
}

export default AccountInfoInput;

const BackgroundContent = styled.div`
  width: 100%;
  border: ${({ border }) => border && `${border}`};
  background-color: ${({ bgc }) => bgc && `${bgc}`};
  padding: ${({ padding }) => padding && `${padding}`};
  display: inline-flex;
  /* display: inline; */
  border-radius: ${({ br }) => br && `${br}px`};
  justify-content: ${({ jc }) => jc && `${jc}`};
  align-items: ${({ ai }) => ai && `${ai}`};
  flex-wrap: ${({ wrap }) => wrap && `${wrap}`};
  position: relative;
  gap: ${({ gap }) => gap && `${gap}`};
`;

const TextContent = styled.div`
  display: inline-flex;
  flex-wrap: ${({ wrap }) => wrap && `${wrap}`};
  flex-shrink: 1;
  align-items: ${({ ai }) => ai && `${ai}`};
  justify-content: ${({ jc }) => jc && `${jc}`};
  gap: ${({ gap }) => gap && `${gap}`};
  width: ${({ width }) => width && `${width}`};
  height: ${({ height }) => height && `${height}`};
  padding: ${({ padding }) => padding && `${padding}`};
  border: ${({ border }) => border && `${border}`};
  border-radius: ${({ br }) => br && `${br}px`};
  background-color: ${({ bgc }) => bgc && `${bgc}`};
  cursor: ${({ cursor }) => cursor && `${cursor}`};
  ${({ gmarket }) => gmarket && `font-family : "GmarketSans" !important;`}
`;

const TextView = styled.span`
  width: ${({ width }) => width && `${width}`};
  text-align: ${({ ta }) => ta && `${ta}`};
  color: ${({ color }) => color && `${color}`};
  font-weight: ${({ fw }) => fw && `${fw}`};
  font-size: ${({ fs }) => fs && `${fs}`};
  line-height: ${({ lh }) => lh && `${lh}`};
  display: inline-block;
  text-overflow: ${({ to }) => to && `${to}`};
  overflow: hidden;
  white-space: ${({ ws }) => ws && `${ws}`};
  ${({ gmarket }) => gmarket && `font-family : "GmarketSans" !important;`}
`;

const ImageIcon100 = styled.div`
  width: ${({ width }) => width && `${width}`};
  height: ${({ height }) => height && `${height}`};
  display: inline-block;

  img {
    width: ${({ width }) => width && `${width}`};
    display: inline-block;
  }
`;

const InputContainer = styled.div`
  border: 1px solid
    ${({ focus, isValid }) => {
      if (focus === FOCUS_STATUS.default) {
        return colors.Gr02;
      } else if (focus === FOCUS_STATUS.focus) {
        return colors.Bl07;
      } else {
        return isValid ? colors.Gr02 : colors.Red03;
      }
    }};
  width: ${({ w }) => w}%;
  border-radius: 8px;
  height: 40px;
`;

const AccountInput = styled.input`
  font-size: 14px;
  font-weight: 500;
  text-align: ${({ value }) => (value ? "start" : "center")};
  padding: 10px 18px;
  width: 100%;
  height: 100%;
  color: ${colors.Gr05};
  position: relative;
  cursor: text;

  &::placeholder {
    color: ${colors.Gr03};
  }

  &:focus {
    outline: none;
  }
`;

const Input = styled.input`
  font-size: 14px;
  font-weight: 500;
  text-align: center;
  padding: 10px 18px;
  width: 100%;
  height: 100%;
  color: ${colors.Gr05};
  cursor: text;
  &::placeholder {
    color: ${colors.Gr03};
  }

  &:focus {
    outline: none;
  }
`;

const IDInput = styled(Input)`
  -webkit-text-security: disc;
`;

const Divider = styled.div`
  height: ${({ height }) => height && `${height}px`};
  background-color: ${({ color }) => color && `${color}`};
  width: 100%;
`;

const Preview = styled.img`
  filter: blur(1.5px);
  width: 67px;
  height: 67px;
  margin-bottom: 10px;
`;

const Transparent = styled.div`
  width: 67px;
  height: 67px;
  margin-bottom: 10px;
`;

const TooltipContainer = styled.div`
  width: 100%;
`;

const TooltipTitle = styled.span`
  display: inline-block;
  margin-bottom: 8px;
  color: ${colors.Bl07};
  letter-spacing: -0.02em;
  line-height: 20px;
  font-size: 12px;
  font-weight: 600;
`;

const TooltipText = styled.p`
  margin-bottom: ${({ isLast }) =>
    isLast ? "0 !important" : "10px !important"};
  color: ${colors.Gr06};
  white-space: pre-wrap;
  letter-spacing: -0.02em;
  line-height: 20px;
  font-size: 12px;
  font-weight: 500;
`;

const TooltipAlert = styled.div`
  display: flex;
  gap: 4px;
`;

const TooltipAlertTitle = styled.span`
  color: ${colors.Red03};
  letter-spacing: -0.02em;
  line-height: 20px;
  font-size: 12px;
  font-weight: 500;
`;
