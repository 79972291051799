import styled from "styled-components";
import { useState, useEffect } from "react";
import Cropper from "cropperjs";
import "cropperjs/dist/cropper.css";
import { W } from "../styles/globalStyles";

export const setImageFromFile = ({ file, setImageUrl }) => {
  let reader = new FileReader();
  reader.onload = function () {
    setImageUrl({ result: reader.result });
  };

  reader.readAsDataURL(file);
};

export const CropImage = ({ status, OnOff, image, setImage, setImage2, imageSetting, inputClear, imageChange, defaultArray, page }) => {
  let cropper = "";

  useEffect(() => {
    if (cropper) {
      cropper.reset();
      cropper.destroy();
    }

    if (image.image) {
      const Img = document.getElementById("CropContent");
      if (image.width && image.height) {
        cropper = new Cropper(Img, {
          aspectRatio: image.width / image.height,
        });
      } else {
        cropper = new Cropper(Img);
      }
    }
  }, [image.image]);

  const SetStatus = () => {
    OnOff(!status);
    if (cropper) {
      cropper.reset();
      cropper.destroy();
    }
    inputClear(image.type);
    imageSetting({
      image: "",
      type: "",
      width: 0,
      height: 0,
    });
  };

  const CropImage = () => {
    if (window.confirm("이미지를 추가하시겠습니까?")) {
      cropper.getCroppedCanvas().toBlob((blob) => {
        var reader = new FileReader();
        reader.readAsDataURL(blob);
        reader.onloadend = () => {
          var base64data = reader.result;

          var background = {
            id: "new",
            image: base64data,
            status: 1,
          };

          if (image.type === "profile_img_sign_up") {
            setImage2(base64data);
            SetStatus();
          } else if (image.type === "profile_img") {
            setImage((prev) => ({ ...prev, [image.type]: base64data }));
            SetStatus();
          } else {
            if (image.type === "background") {
              setImage("background", {
                id: "new",
                image: base64data,
              });
              SetStatus();
            } else if (image.type === "license") {
              if (page === "profile_revise") {
                let copyImage = [...defaultArray];
                copyImage.push(background);

                setImage((prev) => ({ ...prev, license: copyImage }));
              } else {
                setImage("license", {
                  id: "new",
                  image: base64data,
                });
              }
              SetStatus();
            } else if (image.type === "bankbook" || image.type === "id_card" || image.type === "register_card") {
              setImage(image.type, base64data);
              SetStatus();
            } else if (image.type === "profile_image") {
              setImage(image.type, base64data);
              SetStatus();
            } else {
              let copyImage = [...defaultArray];
              copyImage.push(background);

              setImage((prev) => ({ ...prev, image: copyImage }));
              SetStatus();
            }
          }

          let InputFile = document.getElementById(image.type);
          InputFile.value = "";
        };
      });
    }
  };

  return (
    <CropContainer aria-hidden="true" status={status}>
      <CropBackground onClick={() => SetStatus()} />

      <CropContentView>
        <CropContent>
          <img id="CropContent" src={image && image.image} />
        </CropContent>
      </CropContentView>

      <CropGoView>
        <CropGo onClick={() => CropImage()}>이미지 추가</CropGo>
      </CropGoView>
    </CropContainer>
  );
};

const CropContainer = styled.div`
  width: 100%;
  height: calc(var(--vh, 1vh) * 100);
  height: 100vh;
  overflow-x: hidden;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 200;
  display: ${({ status }) => (status ? "inline-flex" : "none")};
  pointer-events: ${({ status }) => (status ? "unset" : "none")};
  align-items: center;
  justify-content: center;
  padding: 0 24px;
`;

const CropBackground = styled.div`
  width: 100%;
  height: calc(var(--vh, 1vh) * 100);
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  display: inline-block;
  overflow: hidden;
  z-index: 201;
  background-color: rgba(0, 0, 0, 0.2);
  backdrop-filter: blur(2px);
`;

const CropContentView = styled.div`
  padding: 0 24px 10px 24px;
  width: ${W}px;
  /* min-height: calc(var(--vh, 1vh) * 75); */
  min-height: 75vh;
  position: fixed;
  transform: translate(-50%, -50%);
  top: calc(50% - 76px);
  left: 50%;
  display: inline-block;
  overflow: hidden;
  z-index: 202;
`;

const CropContent = styled.div`
  width: 100%;
  height: calc(var(--vh, 1vh) * 75);
  height: 75vh;
  display: inline-block;
  overflow: hidden;
  z-index: 203;

  img {
    display: block;
    max-width: calc(100% - 48px);
  }
`;

const CropGoView = styled.div`
  cursor: pointer;
  padding: 10px 24px;
  width: ${W}px;
  position: fixed;
  bottom: 0;
  left: 0;
  display: inline-block;
  overflow: hidden;
  z-index: 202;
  left: 50%;
  transform: translate(-50%, 0);
`;

const CropGo = styled.div`
  width: 100%;
  height: 56px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  background-color: #0e6dff;
  cursor: pointer;
  z-index: 203;
  font-weight: 600;
  font-size: 16px;
  line-height: 142%;
  color: #ffffff;
`;
