import React, { useState, useEffect } from "react";
import styled from "styled-components";
// import { TimePicker } from "react-ios-time-picker";
import TimePicker from "rc-time-picker";
import "rc-time-picker/assets/index.css";
import moment from "moment";

//img
import SetVacation from "../../icon/setVacation.png";
import SetWork from "../../icon/setWork.png";
import colors from "../../styles/colors";

const H = window.innerHeight;
const DesignSize = H / 812;

function StatusBar({ data, index, selectDay, close, timeChange, setData }) {
  const item = data[selectDay];

  //   const { status, start_time, end_time } = data;
  let vac = document.getElementById("vacation")?.clientWidth;

  const [pickTime, setPickTime] = useState({
    start: moment(),
    end: moment(),
  });

  const onOff = () => {
    if (data[selectDay].status) {
      close(selectDay, "open");
    } else {
      close(selectDay, "close");
    }
  };

  useEffect(() => {
    if (data[selectDay]) {
      var start = data[selectDay]?.start_time;
      var end = data[selectDay]?.end_time;

      var starttime = parseInt(start.split(":")[0]) * 60 + parseInt(start.split(":")[1]);

      var endtime = parseInt(end.split(":")[0]) * 60 + parseInt(end.split(":")[1]);

      if (starttime > endtime) {
        alert("휴무 종료 시간이 휴무 시작 시간보다 빠를 수 없습니다.");
        // var copy = [...data];
        // copy.splice(index - 1, 1, { ...item, end_time: item.start_time });
        // setData(copy);
        setTimeout(() => {
          timeChange("end_time", "23:30", selectDay);
        }, 1000);
      }
    }
  }, [data[selectDay]?.end_time]);

  const handleChange = (value, type) => {
    setPickTime((prev) => ({ ...prev, [type]: value }));
    // var start = document.getElementById("start")?.value;
    // var end = document.getElementById("end")?.value;

    // console.log(start);
    // console.log(end);

    // timeChange("start_time", start, selectDay);

    // timeChange("end_time", end, selectDay);
  };

  useEffect(() => {
    var start = document.getElementById("start")?.value;
    var end = document.getElementById("end")?.value;

    if (start) {
      timeChange("start_time", start, selectDay);
    }

    if (end) {
      timeChange("end_time", end, selectDay);
    }
  }, [pickTime]);

  const defaultTime = (h, m) => {
    return moment().set("hour", h).set("minute", m);
  };

  if (data[selectDay]) {
    return (
      <>
        <Container status={data[selectDay].status}>
          <TopLine>
            <VacationContainer onClick={() => onOff()} id="vacation" status={data[selectDay].status}>
              <VacationText status={data[selectDay].status}>{data[selectDay].status ? "휴무설정 해지" : "휴무로 설정"}</VacationText>
              <Arrow src={data[selectDay].status ? SetWork : SetVacation} />
            </VacationContainer>
            <StatusBox status={data[selectDay].status}>
              <Dot status={data[selectDay].status} />
              <div style={{ width: "8.5px" }} />
              <StatusText status={item.status}>{data[selectDay].status ? "휴무" : "활동"}</StatusText>
            </StatusBox>
          </TopLine>
          {data[selectDay].status && (
            <>
              <DividingLine />
              <OnlyVacation>
                <TimePick>
                  <PickText>휴무 시작</PickText>
                  <StartTimePick
                    id="start"
                    inputReadOnly={true}
                    onChange={(time) => handleChange(time, "start")}
                    defaultValue={defaultTime(data[selectDay].start_time.split(":")[0], data[selectDay].start_time.split(":")[1])}
                    showSecond={false}
                    minuteStep={30}
                  />
                  {/* <TimePicker
                    id="vaca"
                    value={data[selectDay].start_time}
                    inputReadOnly={true}
                    saveButtonText="선택"
                    cancelButtonText="취소"
                    onChange={(time) =>
                      timeChange("start_time", time, selectDay)
                    }
                  /> */}
                </TimePick>
                <Partition />
                <TimePick>
                  <PickText>휴무 종료</PickText>
                  <StartTimePick
                    id="end"
                    defaultValue={defaultTime(data[selectDay].end_time.split(":")[0], data[selectDay].end_time.split(":")[1])}
                    inputReadOnly={true}
                    onChange={(time) => handleChange(time, "end")}
                    showSecond={false}
                    minuteStep={30}
                  />
                  {/* <TimePicker
                    id="vaca"
                    value={data[selectDay].end_time}
                    inputReadOnly={true}
                    saveButtonText="선택"
                    cancelButtonText="취소"
                    onChange={(time) => timeChange("end_time", time, selectDay)}
                  /> */}
                </TimePick>
              </OnlyVacation>
            </>
          )}
        </Container>
        {data[selectDay].status && (
          <BlueText>
            시간을 설정해 저장하면
            <br />
            원하는 특정 시간만 휴무로 설정할 수 있습니다.
          </BlueText>
        )}
      </>
    );
  }
}

export default StatusBar;

const Container = styled.div`
  border: 1px solid ${colors.Gr02};
  border-radius: 8px;
  margin: 0 24px;
  margin-bottom: ${({ status }) => (status ? "8" : "16")}px;
  font-size: 14px;
  font-weight: 600;
`;

const TopLine = styled.div`
  padding: 12px 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const VacationContainer = styled.div`
  background-color: ${({ status }) => (status ? colors.Gr02 : colors.Bl07)};
  color: ${colors.White};
  border-radius: 5px;
  padding: 4px;
  display: flex;
  flex-direction: ${({ status }) => (status ? "row-reverse" : "row")};
  justify-content: space-between;
  align-items: center;
  width: 110px;
  transition: 0.3s all ease;
  cursor: pointer;
`;

const VacationText = styled.div`
  ${({ status }) => (status ? "padding-right: 2px" : "padding-left: 8px")};
`;

const Arrow = styled.img`
  cursor: pointer;
  width: 20px;
  height: 20px;
`;

const StatusBox = styled.div`
  background-color: ${({ status }) => (status ? colors.Red02 : colors.Bl02)};
  border-radius: 16px;
  padding: 6px 22px;
  display: flex;
  flex-direction: ${({ status }) => (status ? "row-reverse" : "row")};
  align-items: center;
  transition: 0.3s all ease;
`;

const Dot = styled.div`
  width: 5.25px;
  height: 5.25px;
  background-color: ${({ status }) => (status ? colors.White : colors.Bl07)};
  border-radius: 50%;
`;

const StatusText = styled.div`
  color: ${({ status }) => (status ? colors.White : colors.Bl07)};
`;

const DividingLine = styled.div`
  height: 1px;
  background-color: ${colors.Gr01};
`;

const OnlyVacation = styled.div`
  padding: 10px 12px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: 500;
  font-size: 12px;
  letter-spacing: -0.02em;
  color: ${colors.Gr04};
`;

const TimePick = styled.div`
  display: flex;
  align-items: center;
`;

const PickText = styled.div`
  margin-right: 6px;
`;

const Partition = styled.div`
  height: ${23 * DesignSize}px;
  width: 1px;
  background-color: ${colors.Gr02};
`;

const BlueText = styled.div`
  font-weight: 500;
  font-size: 12px;
  line-height: 142%;
  letter-spacing: -0.02em;
  color: ${colors.Bl06};
  margin-left: 24px;
  margin-bottom: 16px;
`;

const StartTimePick = styled(TimePicker)`
  width: 64px;
  margin-left: 9px;

  &::placeholder {
    color: ${colors.Gr03};
  }
`;
