import React, { useState, useEffect } from "react";
import styled from "styled-components";
import axios from "axios";

import {
  Wrapper,
  Text,
  WrapperColumn,
  Icon,
  AbsoluteWrapper,
} from "../components/layouts/layoutStorage";
import Header from "../components/basic/Header";
import detectDevice from "../tool/detectDevice";
import { GetOne } from "../tool/localstorage";
import { fbevent } from "../tool/firebase";

// style
import colors from "../styles/colors";
import { W } from "../styles/globalStyles";

// icon
import BlueCheck from "../icon/blue_check.png";
import { ReactComponent as Arrow } from "../resource/icon/arrow.svg";

const DATEBOX = (W - 84) / 7;
const WM = (W > 800) ? 800 : W;
const DATEBOX_M = (WM - 84) / 7;

const DayOff = () => {
  const today = new Date();

  const [view, setView] = useState({
    year: today.getFullYear(),
    month:
      today.getMonth() + 1 < 10
        ? `0${today.getMonth() + 1}`
        : `${today.getMonth() + 1}`,
  });

  const [selected, setSelected] = useState({
    year: today.getFullYear(),
    month:
      today.getMonth() + 1 < 10
        ? `0${today.getMonth() + 1}`
        : `${today.getMonth() + 1}`,
    date: today.getDate() < 10 ? `0${today.getDate()}` : `${today.getDate()}`,
  });

  const [off, setOff] = useState([]);

  useEffect(() => {
    fbevent("B2B_DayOff_Page_View", {
      email : GetOne("email")
    });

    const token = GetOne("token");
    const frm = new FormData();

    frm.append("mode", "main");
    frm.append("token", token);

    axios
      .post("https://ai.semos.kr/semos_partner/v2/home/DayOff", frm, {
        headers: { "Content-Type": "multipart/form-data" },
      })
      .then((res) => {
        const value = res.data;
        setOff(value.list);
      });
  }, []);

  const restStatus = () => {
    const token = GetOne("token");
    const frm = new FormData();

    frm.append("mode", "change");
    frm.append("token", token);
    frm.append("year", selected.year);
    frm.append("month", selected.month);
    frm.append("date", selected.date);

    axios
      .post("https://ai.semos.kr/semos_partner/v2/home/DayOff", frm, {
        headers: { "Content-Type": "multipart/form-data" },
      })
      .then((res) => {
        const value = res.data;
        if (value.status === "success") {
          let offSchedule = [...off];
          let finder = offSchedule.find(
            (item) => JSON.stringify(item) === JSON.stringify(selected)
          );

          if (finder) {
            offSchedule = offSchedule.filter(
              (item) => JSON.stringify(item) !== JSON.stringify(selected)
            );
          } else {
            offSchedule.push({ ...selected });
          }

          setOff(offSchedule);

          fbevent("B2B_DayOff_Off_Click", {
            email : GetOne("email")
          });
        } else {
          window.alert("잠시 후 다시 시도해주세요");
        }
      });
  };

  const DayList = [];

  const lastDay = new Date(view.year, Number(view.month), 0).getDate();
  const lastMonth = new Date(view.year, Number(view.month) - 1, 0).getDate();

  for (let i = 0; i < lastDay; i++) {
    DayList.push(i + 1);
  }

  const DateSelector = (y, m, d) => {
    setSelected({
      year: y,
      month: m,
      date: d,
    });
  };

  const DayMaker = (date) => {
    var YoilCount = [0, 2, 3, 4, 5, 6, 7];

    var Year = view.year;
    var Month = view.month;
    var DateText = date > 9 ? `${date}` : `0${date}`;

    var DayCheckContent = [];
    var Yoil = new Date(Year, Month - 1, date).getDay();

    var YoilCheck = YoilCount[Yoil];

    var YoilColor =
      YoilCheck !== 0 && YoilCheck !== 7
        ? "#818792"
        : YoilCheck === 0
        ? "#FF9187"
        : "#0E6DFF";

    var YoilMargin = YoilCheck !== 0;

    var SelectedDate =
      Year == selected.year &&
      Number(Month) == selected.month &&
      Number(DateText) == selected.date;

    var BeforeDate =
      Number(Year) == Number(view.year) &&
      Number(Month) - 1 == Number(view.month) &&
      Number(date) < Number(view.date);

    var oneul =
      Number(Year) == Number(today.getFullYear()) &&
      Number(Month) == Number(today.getMonth() + 1) &&
      Number(date == Number(today.getDate()));

    const dateObj = {
      year: Year,
      month: Month,
      date: DateText,
    };

    if (date === 1) {
      // empty day
      var empty = lastMonth - YoilCheck + 2;
      for (var i = 0; i < YoilCheck - 1; i++) {
        DayCheckContent.push(
          <DayContentNotTouch mr={i !== 0 ? true : ""}>
            <DayContentText color={"#DCDCDC"}>{empty + i}</DayContentText>
          </DayContentNotTouch>
        );
      }

      if (BeforeDate) {
        DayCheckContent.push(
          <DayContentClose mr={YoilMargin}>
            <DayContentText color={"#DCDCDC"}>{date}</DayContentText>
          </DayContentClose>
        );
      } else {
        DayCheckContent.push(
          <DayContent
            onClick={() => DateSelector(view.year, view.month, DateText)}
            mr={YoilMargin}
          >
            <Wrapper
              width="60%"
              height="60%"
              radius="50%"
              jc="center"
              ai="center"
              bgc={SelectedDate ? colors.Bl07 : "transparent"}
              border={oneul ? `1px dashed ${colors.Bl07}` : ""}
            >
              <DayContentText
                oneul={oneul}
                active={SelectedDate}
                color={YoilColor}
              >
                {date}
              </DayContentText>
            </Wrapper>
            {off.find(
              (item) => JSON.stringify(dateObj) === JSON.stringify(item)
            ) && (
              <AbsoluteWrapper
                bottom={
                  detectDevice(window.navigator.userAgent) ? "-10%" : "0%"
                }
              >
                <Text
                  color={colors.Red03}
                  weight="600"
                  size={
                    detectDevice(window.navigator.userAgent) ? "8px" : "12px"
                  }
                >
                  휴무
                </Text>
              </AbsoluteWrapper>
            )}
          </DayContent>
        );
      }
    } else if (date === lastDay) {
      if (BeforeDate) {
        DayCheckContent.push(
          <DayContentClose mr={YoilMargin}>
            <DayContentText color={"#DCDCDC"}>{date}</DayContentText>
          </DayContentClose>
        );
      } else {
        DayCheckContent.push(
          <DayContent
            onClick={() => DateSelector(view.year, view.month, DateText)}
            mr={YoilMargin}
          >
            <Wrapper
              width="60%"
              height="60%"
              radius="50%"
              jc="center"
              ai="center"
              bgc={SelectedDate ? colors.Bl07 : "transparent"}
              border={oneul ? `1px dashed ${colors.Bl07}` : ""}
            >
              <DayContentText
                oneul={oneul}
                active={SelectedDate}
                color={YoilColor}
              >
                {date}
              </DayContentText>
            </Wrapper>
            {off.find(
              (item) => JSON.stringify(dateObj) === JSON.stringify(item)
            ) && (
              <AbsoluteWrapper
                bottom={
                  detectDevice(window.navigator.userAgent) ? "-10%" : "0%"
                }
              >
                <Text
                  color={colors.Red03}
                  weight="600"
                  size={
                    detectDevice(window.navigator.userAgent) ? "8px" : "12px"
                  }
                >
                  휴무
                </Text>
              </AbsoluteWrapper>
            )}
          </DayContent>
        );
      }
      for (var i = 0; i < 7 - YoilCheck; i++) {
        DayCheckContent.push(
          <DayContentNotTouch mr={true}>
            <DayContentText color="#DCDCDC">{i + 1}</DayContentText>
          </DayContentNotTouch>
        );
      }
    } else {
      if (BeforeDate) {
        DayCheckContent.push(
          <DayContentClose mr={YoilMargin}>
            <DayContentText color={"#DCDCDC"}>{date}</DayContentText>
          </DayContentClose>
        );
      } else {
        DayCheckContent.push(
          <>
            <DayContent
              onClick={() => DateSelector(view.year, view.month, DateText)}
              mr={YoilMargin}
            >
              <Wrapper
                width="60%"
                height="60%"
                radius="50%"
                jc="center"
                ai="center"
                bgc={SelectedDate ? colors.Bl07 : "transparent"}
                border={oneul ? `1px dashed ${colors.Bl07}` : ""}
              >
                <DayContentText
                  oneul={oneul}
                  active={SelectedDate}
                  color={YoilColor}
                >
                  {date}
                </DayContentText>
              </Wrapper>
              {off.find(
                (item) => JSON.stringify(dateObj) === JSON.stringify(item)
              ) && (
                <AbsoluteWrapper
                  bottom={
                    detectDevice(window.navigator.userAgent) ? "-10%" : "0%"
                  }
                >
                  <Text
                    color={colors.Red03}
                    weight="600"
                    size={
                      detectDevice(window.navigator.userAgent) ? "8px" : "12px"
                    }
                  >
                    휴무
                  </Text>
                </AbsoluteWrapper>
              )}
            </DayContent>
          </>
        );
      }
    }

    return DayCheckContent;
  };

  const prevMonth = () => {
    const monthChange = Number(view.month) - 1;
    if (view.month === "01") {
      setView({
        year: view.year - 1,
        month: "12",
      });
    } else {
      setView((prev) => ({
        ...prev,
        month: monthChange < 10 ? `0${monthChange}` : `${monthChange}`,
      }));
    }
  };

  const nextMonth = () => {
    const monthChange = Number(view.month) + 1;
    if (view.month === "12") {
      setView({
        year: view.year + 1,
        month: "01",
      });
    } else {
      setView((prev) => ({
        ...prev,
        month: monthChange < 10 ? `0${monthChange}` : `${monthChange}`,
      }));
    }
  };

  return (
    <WrapperColumn style={{alignItems : "center", width : "100%",}} height="100vh">
      <Header title="휴무 관리" back={true} />
      <MonthArrowLine jc="space-between" width="100%" padding="24px">
        <Arrow
          style={{ rotate: "180deg", cursor: "pointer" }}
          width="24px"
          height="24px"
          fill={colors.Gr05}
          onClick={() => prevMonth()}
        />
        <Text weight="600" size="16px">{`${view.year}년 ${view.month}월`}</Text>
        <Arrow
          width="24px"
          height="24px"
          fill={colors.Gr05}
          style={{ cursor: "pointer" }}
          onClick={() => nextMonth()}
        />
      </MonthArrowLine>

      <YoilContainer>
        <YoilContent>
          <YoilContentText color={"#FF9187"}>일</YoilContentText>
        </YoilContent>

        <YoilContent>
          <YoilContentText color={"#ACAFB5"}>월</YoilContentText>
        </YoilContent>

        <YoilContent>
          <YoilContentText color={"#ACAFB5"}>화</YoilContentText>
        </YoilContent>

        <YoilContent>
          <YoilContentText color={"#ACAFB5"}>수</YoilContentText>
        </YoilContent>

        <YoilContent>
          <YoilContentText color={"#ACAFB5"}>목</YoilContentText>
        </YoilContent>

        <YoilContent>
          <YoilContentText color={"#ACAFB5"}>금</YoilContentText>
        </YoilContent>

        <YoilContent>
          <YoilContentText color={"#0E6DFF"}>토</YoilContentText>
        </YoilContent>
      </YoilContainer>

      <Wrapper style={{maxWidth : 800}} wrap="wrap" jc="space-between" padding="0 24px">
        {DayList.map((item) => DayMaker(item))}
      </Wrapper>

      <Wrapper width={`100%`} bgc={colors.Gr01} padding="4px 24px" margin="24px 0 0 0">
        <Text
          color={colors.Gr03}
          weight="600"
          size={detectDevice(window.navigator.userAgent) ? "14px" : "16px"}
        >{`${selected.year}년 ${selected.month}월 ${selected.date}일`}</Text>
      </Wrapper>

      {Object.values(selected).indexOf(0) === -1 && (
        <Wrapper
          jc="space-between"
          padding="14px 24px"
          onClick={() => restStatus()}
          width={`100%`}
        >
          <Text size="16px" color={colors.Gr05} weight="500">
            휴무
          </Text>

          <Wrapper
            border={
              off?.find(
                (item) => JSON.stringify(selected) === JSON.stringify(item)
              )
                ? `1px solid ${colors.Bl07}`
                : `1px solid ${colors.Gr03}`
            }
            width="24px"
            height="24px"
            radius="4px"
            jc="center"
          >
            {off?.find(
              (item) => JSON.stringify(selected) === JSON.stringify(item)
            ) ? (
              <Icon src={BlueCheck} width="18px" height="18px" />
            ) : (
              ""
            )}
          </Wrapper>
        </Wrapper>
      )}
    </WrapperColumn>
  );
};

export default DayOff;

const MonthArrowLine = styled(Wrapper)`
  max-width : 800px;
`;

const DayContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  width: ${W - 48}px;
`;

const DayContentNotTouch = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${DATEBOX_M}px;
  height: ${DATEBOX_M}px;
  margin-bottom: 2px;
  opacity: 0.9;
  ${(props) => (props.mr ? `margin-left : 6px;` : "")};
`;

const DayContentClose = styled.div`
  align-items: center;
  justify-content: center;
  width: ${DATEBOX_M}px;
  height: ${DATEBOX_M}px;
  margin-bottom: 2px;
  ${(props) => (props.mr ? `margin-left : 6px;` : "")};
  border: 1px solid #dcdcdc;
  background: #f5f5f5;
  position: relative;
`;

const DayContentText = styled.div`
  color: ${(props) => props.color};
  ${(props) =>
    props.active
      ? `
  color : #ffffff;
  `
      : ""}
  font-weight: 500;
  font-size: ${({ oneul, active }) => (oneul && active ? "11.5px" : "14px")};
  line-height: 142%;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: -0.02em;
`;

const DayContent = styled.div`
  cursor: pointer;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${DATEBOX_M}px;
  height: ${DATEBOX_M}px;
  margin-bottom: 2px;
  ${(props) => (props.mr ? `margin-left : 6px;` : "")};
  ${(props) =>
    props.active
      ? `
  border-radius : 50%;
  background-color : #0E6DFF;
 
  `
      : ""}
  ${({ oneul }) =>
    oneul &&
    `border: 1px dashed #0E6DFF; 
  border-radius: 50%;
 `}
`;

const BlueDot = styled.div`
  background-color: #0e6dff;
  width: 4px;
  height: 4px;
  border-radius: 50%;
  position: absolute;
  top: 7px;
  right: 8px;
`;

const YoilContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: ${W}px;
  padding: 0 24px;
  max-width : 800px;
`;

const YoilContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${DATEBOX_M}px;
  height: ${DATEBOX_M}px;
`;

const YoilContentText = styled.div`
  color: ${(props) => props.color};
  font-weight: 500;
  font-size: 12px;
  line-height: 142%;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: -0.02em;
  margin-bottom: 2px;
`;
