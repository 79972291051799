import React, { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import axios from "axios";

// style
import colors from "../../styles/colors";

// component
import { Wrapper, Text } from "../../components/layouts/layoutStorage";
import PartnerInfoPhoneAuth from "../../components/signup/PartnerInfoPhoneAuth";

// img
import { ReactComponent as CheckCircle } from "../../resource/icon/check_circle.svg";
import RedWarning from "../../icon/redWarning.png";

const PartnerInfo = ({setPage, page, setData, data, status, setStatus, valid, setValid}) => {

    const navigate = useNavigate();

    const ChangeName = (Value) => {
        setData((prev) => ({ ...prev, name : Value }));

        if(Value?.length > 1) {
            setValid((prev) => ({ ...prev, name : true }));
        } else {
            setValid((prev) => ({ ...prev, name : 'default' }));
        }
    }

    const NextPage = () => {
        if(NextCheck) {
            setPage(2);
            navigate("/partner_register_new/2", {replace: true});
        }
    }

    const nameCheck = () => {

        if (data?.name?.length > 0) {

            const frm = new FormData();
            frm.append("mode", "name_duplicated");
            frm.append("name", data?.name);

            axios
                .post("https://ai.semos.kr/semos_partner/v3/profile/Login", frm, {
                headers: {
                    "Content-Type": "multipart/form-data",
                    Accept: "application/json",
                },
                })
                .then((data) => {
                    const Value = data.data;
                    const Status = Value.status;

                    if (Status === "success") {
                        setValid((prev) => ({ ...prev, name : true }));
                    } else if (Status === "duplicated") {
                        setValid((prev) => ({ ...prev, name : false }));
                    }
                });
        }
    };

    const NextCheck = (data?.name) 
    && (data?.phone?.length > 10) 
    && (valid?.name === true)
    && status?.auth == 'ok';

    return (
        <Content>
            <Wrapper
                width="100%"
                bgc={colors.White}
                wrap={`wrap`}
            >
                <Text
                    size="1.8rem"
                    weight="600"
                    color={colors.Gr05}
                    width="100%"
                    style={{
                        marginBottom : "12px"
                    }}
                >
                    {'파트너명'}
                </Text>

                <Wrapper
                    width="100%"
                    border={`1px solid ${valid?.name ? (valid?.name === 'default' ? colors.Gr02 : colors.Bl07 ) : colors.Red03}`}
                    bgc={colors.White}
                    padding={`0 16px`}
                    radius={"8px"}
                    ai={`center`}
                    jc={`space-between`}
                    height={`52px`}
                >
                    <Input onBlur={() => nameCheck()} value={data?.name} onChange={(e) => ChangeName(e.target.value)} maxLength={`50`} width={`calc(100% - 34px)`} type={`text`} placeholder={`파트너명을 적어주세요! (최소 2자)`} />
                    {
                        (valid?.name === true) && (valid?.name !== 'default') ?
                            <CheckCircle width={`24px`} height={`24px`} fill={colors.Bl07} style={{ cursor : "pointer" }} />
                        : <></>
                    }
                </Wrapper>
                <Text
                    size="1.3rem"
                    weight="500"
                    color={colors.Gr04}
                    width="100%"
                    style={{
                        marginTop : '3px'
                    }}
                >
                    {'- 회원님에게 보여질 파트너명을 적어주세요!'}
                </Text>

                {valid.name === false && (
                    <>
                    <Divider h={6} />
                    <WarningTextContainer>
                        <WarningIcon src={RedWarning} />
                        <WarningText negative={true}>중복된 파트너명이 있습니다. 다른 파트너명을 입력해주세요.</WarningText>
                    </WarningTextContainer>
                    </>
                )}

                <Divider h={`20`} />

                <Text
                    size="1.8rem"
                    weight="600"
                    color={colors.Gr05}
                    width="100%"
                    style={{
                        marginBottom : "12px"
                    }}
                >
                    {'휴대전화번호'}
                </Text>

                <PartnerInfoPhoneAuth
                    userInfo={data}
                    setUserInfo={setData}
                    status={status}
                    setStatus={setStatus}
                />

                <Divider h={`8`} />


            </Wrapper>

            <Wrapper
                width="100%"
                bgc={colors.White}
                padding={`10px 24px`}
                style={{
                    position : "fixed",
                    bottom : 0,
                    left : '50%',
                    transform : "translate(-50%, 0%)",
                    zIndex : 10,
                    borderTop : `1px solid ${colors.Gr01}`,
                    maxWidth : '800px'
                }}
            >
                <Wrapper
                    width="100%"
                    bgc={colors.Bl07}
                    radius={`8px`}
                    height={`52px`}
                    cursor={`pointer`}
                    ai={`center`}
                    jc={`center`}
                    onClick={() => NextPage()}
                    style={{
                        opacity : (NextCheck === true) ? 1 : 0.3,
                        position : "relative"
                    }}
                >
                    <Text
                        size="1.4rem"
                        weight="600"
                        color={colors.Gr05}
                        style={{
                            position : "absolute",
                            top : '-30px',
                            left : 0
                        }}
                    >
                        {"추가 정보는 입점 후 등록할 수 있어요."}
                    </Text>

                    <Text
                        size="1.6rem"
                        weight="600"
                        color={colors.White}
                    >
                        {"프로필 정보 저장하기"}
                    </Text>
                </Wrapper>
            </Wrapper> 

        </Content>

    )
}

export default PartnerInfo;

const Content = styled.div`
    padding: 24px 24px 70px 24px;
    background-color: ${colors.White};
    width : 100%;
    max-width : 800px;
    margin : 0 auto;
`;

const Input = styled.input`
    width: ${({ width }) => ( width ? width : '100%' )};
    background-color: ${colors.White};
    border-radius: 8px;
    font-size : 14px;   
    font-weight : 500;
`;

const Divider = styled.div`
    width : 100%;
    height: ${({ h }) => h}px;
    display : inline-block;
`;

const WarningTextContainer = styled.div`
    display: flex;
    align-items: ${({ need }) => (need ? "center" : "")};
`;

const WarningIcon = styled.img`
    width: 14px;
    height: 14px;
    margin-right: 6px;
`;

const WarningText = styled.div`
    font-weight: 600;
    font-size: 12px;
    line-height: 142%;
    display: flex;
    align-items: center;
    letter-spacing: -0.02em;
    color: ${({ negative }) => (negative ? colors.Red03 : colors.Bl05)};
`;
