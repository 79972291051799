import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";

// components
import Header from "../components/basic/Header";

// tool
import { GetOne } from "../tool/localstorage";
import { LoadingSpinner } from "../tool/loading";
import { fbevent } from "../tool/firebase";

// style
import { H, PC, WIDTH } from "../styles/globalStyles";
import colors from "../styles/colors";

// img
// import { ReactComponent as Arrow } from "../resource/icon/arrow.svg";

const MIN_CANCEL_LENGTH = 10;
const MAX_CANCEL_LENGTH = 30;

const CANCEL_LIST = [
  {
    cancel_message: "지정된 시간에 진행이 불가하여 취소합니다.",
    index: 0,
  },
  {
    cancel_message: "장소 진행이 불가하여 취소합니다.",
    index: 1,
  },
  {
    cancel_message: "파트너의 개인 일정으로 취소합니다.",
    index: 2,
  },
  {
    cancel_message: "기타",
    index: 3,
  },
];

const CancelActivityDetail = () => {
  const navigate = useNavigate();
  const params = useParams();

  const [selected, setSelected] = useState(0);
  const [isFocus, setIsFocus] = useState(false);
  const [loading, setLoading] = useState(false);
  const [cancelMessage, setCancelMessage] = useState(
    CANCEL_LIST[0].cancel_message
  );
  const [etcText, setEtcText] = useState("");
  const [isActive, setIsActive] = useState(false);

  useEffect(() => {
    fbevent("B2B_ReservationCancel_Page_View", {
      email : GetOne("email")
    });
  }, []);

  useEffect(() => {
    if (selected === 3) {
      setIsActive(
        cancelMessage.length >= MIN_CANCEL_LENGTH &&
          cancelMessage.length <= MAX_CANCEL_LENGTH
      );
    } else {
      cancelMessage ? setIsActive(true) : setIsActive(false);
    }
  }, [cancelMessage]);

  const handleSelect = (val, idx) => {
    setSelected(idx);
    if (idx === 3) {
      setCancelMessage(etcText);
    } else {
      setCancelMessage(val);
    }
  };

  const handleEtc = (val) => {
    const text = val.substr(0, MAX_CANCEL_LENGTH);
    const valLength = text.trim().length;

    setEtcText(text);

    if (valLength >= MIN_CANCEL_LENGTH && valLength <= MAX_CANCEL_LENGTH) {
      setCancelMessage(text);
      setIsActive(true);
    } else {
      setCancelMessage("");
      setIsActive(false);
    }
  };

  const confirmCancel = () => {
    setLoading(true);

    const token = GetOne("token");

    const frm = new FormData();

    frm.append("mode", "partner_cancel");
    frm.append("payment", params.id);
    frm.append("token", token);
    frm.append("cancel_message", cancelMessage);

    axios
      .post("https://ai.semos.kr/semos_partner/schedule/data", frm, {
        headers: { "Content-Type": "multipart/form-data" },
      })
      .then((data) => {
        const value = data.data;
        const status = value.status;
        const statusMessage = value.status_message;

        if (status === "success") {
          fbevent("B2B_ReservationCancel_Cancel_Click", {
            email : GetOne("email"),
            id : params.id
          });

          setLoading(false);
          navigate("/reservation_manage", {
            state: { index: 4, openCancelToast: true },
          });
        } else {
          alert(statusMessage);
          setLoading(false);
        }
      });
  };

  return (
    <CancelActivityDetailContainer>
      <Header title="예약 거절" back={true} />

      <CancelActivityBody>
        <TitleContainer>
          <Title>
            예약한 크루님께 전달할 <br />
            예약 취소 사유를 말씀해주세요.
          </Title>
          <SubTitle>
            발생한 예약을 취소하실 경우 액티비티 확정율이 낮아지며, <br />
            이는 신규 예약에 부정적인 영향을 미칩니다.
          </SubTitle>
        </TitleContainer>
        <CancelListContainer>
          <CancelList>
            {CANCEL_LIST.map((val, idx, arr) => {
              const { cancel_message, index } = val;
              return (
                <CancelItem
                  key={cancel_message}
                  onClick={() => handleSelect(cancel_message, index)}
                  isLast={idx + 1 === arr.length}
                >
                  <span>{cancel_message}</span>
                  <RadioContainer>
                    <RadioItem>
                      <RadioButton
                        type="radio"
                        value={selected === idx}
                        name="cancel"
                        checked={selected === idx}
                        onChange={() => setSelected(idx)}
                      />
                    </RadioItem>
                  </RadioContainer>
                </CancelItem>
              );
            })}
          </CancelList>
          {selected === 3 ? (
            <TextareaContainer focus={isFocus}>
              <TextArea
                maxLength={MAX_CANCEL_LENGTH}
                type="text"
                placeholder="작성한 취소 사유는 크루님에게 직접 전달됩니다."
                value={etcText}
                onChange={(e) => handleEtc(e.target.value)}
                onFocus={() => setIsFocus(true)}
                onBlur={() => setIsFocus(false)}
              />
              <TextLengthVali>
                <span>{etcText.length}</span> / {MAX_CANCEL_LENGTH}{" "}
                {`(최소 ${MIN_CANCEL_LENGTH}자)`}
              </TextLengthVali>
            </TextareaContainer>
          ) : (
            <></>
          )}
        </CancelListContainer>
      </CancelActivityBody>
      <CancelActivityFooter>
        <ConfirmButton
          onClick={() => isActive && confirmCancel()}
          isActive={isActive}
        >
          거절하기
        </ConfirmButton>
      </CancelActivityFooter>

      <LoadingSpinner loading={loading} title={`정보를 확인하고 있습니다.`} content={`잠시만 기다려 주세요!`} />
    </CancelActivityDetailContainer>
  );
};

export default CancelActivityDetail;

const CancelActivityDetailContainer = styled.div`
  height: ${H}px;
  position: relative;
`;

const GoBackButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;

  svg {
    transform: rotate(180deg);
  }
`;

const CancelActivityBody = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
  padding: 36px 24px;
`;

const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  width: 100%;
`;

const Title = styled.h2`
  color: ${colors.Gr06};
  line-height: 26px;
  letter-spacing: -0.02em;
  font-size: 18px;
  font-weight: 700;
`;

const SubTitle = styled.span`
  color: ${colors.Gr04};
  line-height: 22px;
  letter-spacing: -0.02em;
  font-size: 14px;
  font-weight: 500;
`;

const CancelListContainer = styled.div`
  width: 100%;
`;

const CancelList = styled.ul`
  display: flex;
  flex-direction: column;
  background-color: ${colors.White};
  width: 100%;
`;

const CancelItem = styled.li`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px 0;
  border-bottom: ${({ isLast }) =>
    isLast ? "none" : `1px solid ${colors.Gr01}`};

  span {
    color: ${colors.Gr05};
    line-height: 22px;
    letter-spacing: -0.02em;
    font-size: 14px;
    font-weight: 500;
  }
`;

const RadioContainer = styled.div`
  display: flex;
  align-items: center;
`;

const RadioItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  padding: 4px;
`;

const RadioButton = styled.input`
  width: 14px;
  height: 14px;
`;

const TextareaContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 126px;
  border: 1px solid ${({ focus }) => (focus ? colors.Bl07 : colors.Gr02)};
  border-radius: 8px;
`;

const TextArea = styled.textarea`
  display: inline-flex;
  width: 100%;
  height: 100%;
  padding: 16px 16px 0px 16px;
  border: none;
  border-radius: 8px;
  resize: none;
  line-height: 22px;
  letter-spacing: -0.02em;
  font-size: 14px;
  font-weight: 500;

  overflow: auto;
  outline: none;

  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;

  &::placeholder {
    color: ${colors.Gr03};
  }
`;

const TextLengthVali = styled.div`
  align-self: flex-end;
  padding: 8px 12px;
  color: ${colors.Gr03};
  line-height: 20px;
  letter-spacing: -0.02em;
  font-size: 12px;
  font-weight: 500;

  span {
    color: ${colors.Gr04};
  }
`;

const CancelActivityFooter = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 14px 24px;
  background-color: ${colors.White};
  position: absolute;
  /* position: fixed; */
  z-index: 48;
  bottom: 0;

  @media screen and (min-width: ${PC}px) {
    width: ${WIDTH}px;
  }
`;

const ConfirmButton = styled.button`
  width: 100%;
  height: 52px;
  padding: 14px 16px;
  background-color: ${({ isActive }) => (isActive ? colors.Bl07 : colors.Gr01)};
  border-radius: 8px;
  color: ${({ isActive }) => (isActive ? colors.White : colors.Gr03)};
`;
