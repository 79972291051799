import React, { useState, useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import styled from "styled-components";

import Header from "../components/basic/Header";
import InviteModal from "../components/profileRevise/InviteModal";
import { GetOne } from "../tool/localstorage";

// style
import colors from "../styles/colors";

// icon
import { ReactComponent as Arrow } from "../resource/icon/arrow.svg";
import PeopleIcon from "../icon/peopleIcon.png";

    const PartnerInvite = () => {
    const navigate = useNavigate();

    const [invite, setInvite] = useState(false);
    const [list, setList] = useState([]);

    const GetData = () => {
        const token = GetOne("token");

        const frm = new FormData();

        frm.append('token', token);
        frm.append('mode', 'main');

        axios
            .post('https://ai.semos.kr/semos_partner/v3/profile/PartnerInvite', frm, {
            headers: {'Content-Type': 'multipart/form-data'},
            })
            .then(data => {
                const Value = data.data;
                const Stauts = Value.status;
                const StatusMessage = Value.status_message;

                if (Stauts == 'success') {
                    const List = Value.list;
                    setList(List);
                } else {
                    window.alert(StatusMessage)
                    window.location.reload();
                }
            })
            .catch(err => console.log('PROFILE ERROR', err));
    }

    useEffect(() => {
        GetData();
    }, []);

    const InviteConfirm = (type, num) => {
        if(type == '수락하기') {
            const token = GetOne("token");

            const frm = new FormData();
    
            frm.append('token', token);
            frm.append('mode', 'confirm');
            frm.append('id', num);
    
            axios
                .post('https://ai.semos.kr/semos_partner/v3/profile/PartnerInvite', frm, {
                headers: {'Content-Type': 'multipart/form-data'},
            })
            .then(data => {
                const Value = data.data;
                const Stauts = Value.status;
                const StatusMessage = Value.status_message;
    
                if (Stauts == 'success') {
                    GetData();
                } else {
                    window.alert(StatusMessage)
                    window.location.reload();
                }
            })
            .catch(err => console.log('PROFILE ERROR', err));
        }
    }

    return (
        <StPartnerTip>
            <Header title="파트너 초대" back={false} jc="flex-start">
                <BackButton onClick={() => navigate(-1)}>
                <Arrow fill={colors.Gr05} />
                </BackButton>
            </Header>

            <SubTitleContainer>
                <div className="sub_title">
                    초대 리스트
                </div>

                <div onClick={() => setInvite(true)} className="invite">
                    파트너 초대하기
                </div>
            </SubTitleContainer>

            {list.map((item, index) => (
                <ItemContainer key={`invite_${index}`}>
                    <ItemBox status={item.status_text != '진행중'}>
                        <div className="inner_container">
                            <div className="image_box" >
                                <img src={item.profile ? item.profile : PeopleIcon} />
                            </div>

                            <div className="name">
                                {item.nickname}
                            </div>
                        </div>


                        <div onClick={() => InviteConfirm(item.status_text, item.id)} className="status">
                            {item.status_text}
                        </div>
                    </ItemBox>
                </ItemContainer>
            ))}



            <Divider />
            
            <InviteModal modal={invite} setModal={setInvite} GetData={GetData} />
        </StPartnerTip>
    );
};

export default PartnerInvite;

const Divider = styled.div`
    width: 100%;
    height: 2px;
    margin-top: 24px;
    background-color: ${colors.Gr01};
`;

const StPartnerTip = styled.div`
    height: 100vh;
    width: 100%;
    overflow: auto;
`;

const SubTitleContainer = styled.div`
    width: 100%;
    padding : 20px 24px;
    display : inline-flex;
    align-items: center;
    justify-content: space-between;

    .sub_title {
        font-size: 18px;
        font-weight: 600;
        display : inline-block;
    }

    .invite {
        font-size: 16px;
        font-weight: 600;
        display : inline-block;
        color: ${colors.Bl07};
        padding : 5px 8px;
        border-radius : 8px;
        border : 1px solid ${colors.Bl07};
        cursor: pointer;
    }
`;

const ItemContainer = styled.div`
    width: 100%;
    max-height: calc(100vh - 115px);
    max-height: calc((var(--vh, 1vh) * 100) - 115px);
    overflow : hidden auto;
    display : inline-block;
`;

const ItemBox = styled.div`
    width: 100%;
    padding : 10px 24px;
    border-bottom : 1px solid ${colors.Gr01};
    display : inline-flex;
    align-items: center;
    justify-content: space-between;

    .inner_container {
        display : inline-flex;
        align-items: center;
        width : calc(100% - 70px);
    }

    .image_box {
        width : 50px;
        height : 50px;
        display: inline-block;
        overflow: hidden;
        border-radius: 50%;
        margin-right: 10px;

        img {
            width : 50px;
            height : 50px;
            object-fit: cover;
        }
    }

    .name {
        width : calc(100% - 60px);
        display: inline-block;
        font-size: 16px;
        font-weight: 500;
    }

    .status {
        font-size: 16px;
        font-weight: 600;
        display : inline-flex;
        align-items: center;
        justify-content: center;
        border-radius: 8px;
        padding : 3px 6px;
        cursor: pointer;
        ${({ status }) => (status ? `
            border : 1px solid ${colors.Bl07};
            background-color : ${colors.Bl07};
            color : ${colors.White};
        ` : 
        `
            border : 1px solid ${colors.Gr02};
            background-color : ${colors.Gr02};
            color : ${colors.Gr05};
        `)};
    }
`;

const BackButton = styled.button`
  svg {
    width: 24px;
    height: 24px;
    transform: rotate(180deg);
  }
`;
