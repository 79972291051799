import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { useNavigate, useLocation } from "react-router-dom";
import axios from "axios";

// components
import Header from "../components/basic/Header";
import SemosActivity from "../components/mycalender/semos_activity";
import PersonalActivity from "../components/mycalender/personal_activity";
import { GetOne, SaveOne } from "../tool/localstorage";
import Footer from "../components/basic/footer";
import { LoadingSpinner } from "../tool/loading";

// img
import PrevMonthIcon from "../icon/prevMonth.png";
import NextMonthIcon from "../icon/nextMonth.png";
import Plus from "../icon/noAppointment.png";
import { W } from "../styles/globalStyles";

const DateBox = (W - (48 + 36)) / 7;

function MyCalender() {
  const navigate = useNavigate();
  const location = useLocation();
  const [loading, setLoading] = useState(true);

  const today = {
    year: new Date().getFullYear(),
    month:
      new Date().getMonth() + 1 > 9
        ? String(new Date().getMonth() + 1)
        : `0${new Date().getMonth() + 1}`,
    date:
      new Date().getDate() > 9
        ? String(new Date().getDate())
        : `0${new Date().getDate()}`,
  };

  const [nowTime, setNowTime] = useState({
    year: location.state ? location.state.year : today.year,
    month: location.state ? location.state.month : today.month,
    date: location.state ? "01" : today.date,
  });

  const [selectedDate, setSelectedDate] = useState({
    year: location.state ? location.state.year : today.year,
    month: location.state ? location.state.month : today.month,
    date: location.state ? location.state.date : today.date,
  });

  const [lastDay, setLastDay] = useState(
    new Date(nowTime.year, Number(nowTime.month), 0).getDate()
  );

  const [lastMonth, setLastMonth] = useState(
    new Date(nowTime.year, Number(nowTime.month) - 1, 0).getDate()
  );

  const [schedule, setSchedule] = useState();

  useEffect(() => {
    setLastDay(new Date(nowTime.year, Number(nowTime.month), 0).getDate());
    setLastMonth(
      new Date(nowTime.year, Number(nowTime.month) - 1, 0).getDate()
    );
  }, [nowTime.year, nowTime.month]);

  var Daylist = [];

  for (let i = 0; i < lastDay; i++) {
    Daylist.push(i + 1);
  }

  const PrevMonth = () => {
    if (nowTime.month === "01") {
      var PYear = nowTime.year - 1;
      var PMonth = "12";
      setNowTime({
        year: PYear,
        month: PMonth,
        date: "01",
      });
      setSelectedDate({
        year: PYear,
        month: PMonth,
        date: "01",
      });
    } else {
      var num = Number(nowTime.month) - 1;
      var PMonth = num > 9 ? String(num) : `0${num}`;
      setNowTime({
        year: nowTime.year,
        month: PMonth,
        date: "01",
      });
      setSelectedDate({
        year: nowTime.year,
        month: PMonth,
        date: "01",
      });
    }
  };

  const NextMonth = () => {
    if (nowTime.month === "12") {
      var NYear = nowTime.year + 1;
      var NMonth = "01";
      setNowTime({
        year: NYear,
        month: NMonth,
        date: "01",
      });
      setSelectedDate({
        year: NYear,
        month: NMonth,
        date: "01",
      });
    } else {
      var num = Number(nowTime.month) + 1;
      var NMonth = num > 9 ? String(num) : `0${num}`;
      setNowTime({
        year: nowTime.year,
        month: NMonth,
        date: "01",
      });
      setSelectedDate({
        year: nowTime.year,
        month: NMonth,
        date: "01",
      });
    }
  };

  const DateSelector = (y, m, d) => {
    setSelectedDate({
      year: y,
      month: m,
      date: d,
    });
  };

  const DayMaker = (date) => {
    var YoilCount = [0, 2, 3, 4, 5, 6, 7];

    var Year = nowTime.year;
    var Month = nowTime.month;
    var DateText = date > 9 ? date : `0${date}`;

    var DayCheckContent = [];
    var Yoil = new Date(Year, Month - 1, date).getDay();

    var YoilCheck = YoilCount[Yoil];

    var YoilColor =
      YoilCheck !== 0 && YoilCheck !== 7
        ? "#818792"
        : YoilCheck === 0
        ? "#FF9187"
        : "#0E6DFF";

    var YoilMargin = YoilCheck !== 0 ? true : false;

    var SelectedDate =
      Year == selectedDate.year &&
      Month == selectedDate.month &&
      DateText == selectedDate.date;

    var BeforeDate =
      Number(Year) == Number(nowTime.year) &&
      Number(Month) - 1 == Number(nowTime.month) &&
      Number(date) < Number(nowTime.date);

    var oneul =
      Number(Year) == Number(today.year) &&
      Number(Month) == Number(today.month) &&
      Number(date == Number(today.date));

    if (date === 1) {
      // empty day
      var empty = lastMonth - YoilCheck + 2;
      for (var i = 0; i < YoilCheck - 1; i++) {
        DayCheckContent.push(
          <DayContentNotTouch mr={i !== 0 ? true : ""}>
            <DayContentText color={"#DCDCDC"}>{empty + i}</DayContentText>
          </DayContentNotTouch>
        );
      }

      if (BeforeDate) {
        DayCheckContent.push(
          <DayContentClose mr={YoilMargin}>
            <DayContentText color={"#DCDCDC"}>{date}</DayContentText>
          </DayContentClose>
        );
      } else {
        DayCheckContent.push(
          <DayContent
            oneul={oneul}
            active={SelectedDate}
            onClick={() => DateSelector(nowTime.year, nowTime.month, DateText)}
            mr={YoilMargin}
          >
            <DayContentText
              oneul={oneul}
              active={SelectedDate}
              color={YoilColor}
            >
              {oneul && SelectedDate ? "오늘" : date}
            </DayContentText>

            {schedule &&
              schedule[`${nowTime.year}.${nowTime.month}.${DateText}`] &&
              (schedule[`${nowTime.year}.${nowTime.month}.${DateText}`]
                .length ? (
                <BlueDot />
              ) : (
                ""
              ))}
          </DayContent>
        );
      }
    } else if (date === lastDay) {
      if (BeforeDate) {
        DayCheckContent.push(
          <DayContentClose mr={YoilMargin}>
            <DayContentText color={"#DCDCDC"}>{date}</DayContentText>
          </DayContentClose>
        );
      } else {
        DayCheckContent.push(
          <DayContent
            oneul={oneul}
            active={SelectedDate}
            onClick={() => DateSelector(nowTime.year, nowTime.month, DateText)}
            mr={YoilMargin}
          >
            <DayContentText
              oneul={oneul}
              active={SelectedDate}
              color={YoilColor}
            >
              {oneul && SelectedDate ? "오늘" : date}
            </DayContentText>

            {schedule &&
              schedule[`${nowTime.year}.${nowTime.month}.${DateText}`] &&
              (schedule[`${nowTime.year}.${nowTime.month}.${DateText}`]
                .length ? (
                <BlueDot />
              ) : (
                ""
              ))}
          </DayContent>
        );
      }
      for (var i = 0; i < 7 - YoilCheck; i++) {
        DayCheckContent.push(
          <DayContentNotTouch mr={true}>
            <DayContentText color="#DCDCDC">{i + 1}</DayContentText>
          </DayContentNotTouch>
        );
      }
    } else {
      if (BeforeDate) {
        DayCheckContent.push(
          <DayContentClose mr={YoilMargin}>
            <DayContentText color={"#DCDCDC"}>{date}</DayContentText>
          </DayContentClose>
        );
      } else {
        DayCheckContent.push(
          <>
            <DayContent
              oneul={oneul}
              active={SelectedDate}
              onClick={() =>
                DateSelector(nowTime.year, nowTime.month, DateText)
              }
              mr={YoilMargin}
            >
              <DayContentText
                oneul={oneul}
                active={SelectedDate}
                color={YoilColor}
              >
                {oneul && SelectedDate ? "오늘" : date}
              </DayContentText>

              {schedule &&
                schedule[`${nowTime.year}.${nowTime.month}.${DateText}`] &&
                (schedule[`${nowTime.year}.${nowTime.month}.${DateText}`]
                  .length ? (
                  <BlueDot />
                ) : (
                  ""
                ))}
            </DayContent>
          </>
        );
      }
    }

    return DayCheckContent;
  };

  useEffect(() => {
    setLoading(true);
    var token = GetOne("token");

    const frm = new FormData();
    frm.append("mode", "my_calendar");
    frm.append("token", token);
    frm.append("year", nowTime.year);
    frm.append("month", nowTime.month);

    axios
      .post("https://ai.semos.kr/semos_partner/schedule/data", frm, {
        headers: { "Content-Type": "multipart/form-data" },
      })
      .then((data) => {
        var Value = data.data;
        var Status = Value.status;
        var StatusMessage = Value.status_message;
        var CurrentSchedule = Value.current_schedule;

        if (Status == "success") {
          setSchedule(CurrentSchedule);
          setLoading(false);
        } else {
          setLoading(false);
          window.alert(StatusMessage);
        }
      });
  }, [nowTime.year, nowTime.month]);

  const SaveSchedule = (data) => {
    setLoading(true);

    var token = GetOne("token");

    const frm = new FormData();
    frm.append("mode", "schedule_revise");
    frm.append("token", token);
    frm.append("number", data.schedule_number);
    frm.append("start_time", `${data.start_time}:${data.start_min}`);
    frm.append("finish_time", `${data.finish_time}:${data.finish_min}`);
    frm.append("memo", data.memo);
    frm.append("activity_name", data.activity_name);

    axios
      .post("https://ai.semos.kr/semos_partner/schedule/data", frm, {
        headers: { "Content-Type": "multipart/form-data" },
      })
      .then((data) => {
        var Value = data.data;
        var Status = Value.status;
        var StatusMessage = Value.status_message;
        var day = `${selectedDate.year}.${selectedDate.month}.${selectedDate.date}`;
        var Schedule = { ...schedule };
        var revisedValue = Value.this_date;

        if (Status == "success") {
          window.alert(StatusMessage);
          var copy = Schedule[day];
          var index = copy.findIndex(
            (x) => x.schedule_number == revisedValue[0].schedule_number
          );

          copy.splice(index, 1, revisedValue[0]);
          Schedule[day] = copy;
          //setSchedule(Schedule);
          setLoading(false);
          window.location.reload();
        } else {
          setLoading(false);
          window.alert(StatusMessage);
        }
      });
  };

  const DeleteSchedule = (data, number, date) => {
    var token = GetOne("token");

    const frm = new FormData();
    frm.append("mode", "schedule_delete");
    frm.append("token", token);
    frm.append("number", data.schedule_number);

    axios
      .post("https://ai.semos.kr/semos_partner/schedule/data", frm, {
        headers: { "Content-Type": "multipart/form-data" },
      })
      .then((data) => {
        var Value = data.data;
        var Status = Value.status;
        var StatusMessage = Value.status_message;

        if (Status == "success") {
          var SCH = schedule;
          let SCHD = SCH[date];

          SCHD.splice(number, 1);
          setSchedule((prev) => ({ ...prev, [date]: SCHD }));

          window.alert(StatusMessage);
          window.location.reload();
        } else {
          window.alert(StatusMessage);
        }
      });
  };

  return (
    <Container>
      <Header title="마이 캘린더" back={true} />

      <CalnederContent>
        <Styler pb={24} pt={24} pl={24} pr={24}>
          <CalendarMonthSelector>
            <MonthSelector onClick={PrevMonth}>
              <MonthSelectorIcon src={PrevMonthIcon} />
            </MonthSelector>

            <MonthSelectorYM>{`${nowTime.year}년 ${nowTime.month}월`}</MonthSelectorYM>

            <MonthSelector onClick={NextMonth}>
              <MonthSelectorIcon src={NextMonthIcon} />
            </MonthSelector>
          </CalendarMonthSelector>

          <YoilContainer>
            <YoilContent>
              <YoilContentText color={"#FF9187"}>일</YoilContentText>
            </YoilContent>

            <YoilContent>
              <YoilContentText color={"#ACAFB5"}>월</YoilContentText>
            </YoilContent>

            <YoilContent>
              <YoilContentText color={"#ACAFB5"}>화</YoilContentText>
            </YoilContent>

            <YoilContent>
              <YoilContentText color={"#ACAFB5"}>수</YoilContentText>
            </YoilContent>

            <YoilContent>
              <YoilContentText color={"#ACAFB5"}>목</YoilContentText>
            </YoilContent>

            <YoilContent>
              <YoilContentText color={"#ACAFB5"}>금</YoilContentText>
            </YoilContent>

            <YoilContent>
              <YoilContentText color={"#0E6DFF"}>토</YoilContentText>
            </YoilContent>
          </YoilContainer>

          <DayContainer>
            {Daylist ? Daylist.map((item, index) => DayMaker(item)) : ""}
          </DayContainer>
        </Styler>
      </CalnederContent>

      <Styler pt={30} pl={24} pr={24}>
        {/* <NoAppointment>
          아래 '일정 추가하기' 버튼을 눌러
          <br />
          일정을 추가해보세요!
        </NoAppointment> */}
        {/*<SemosActivity />
        <PersonalActivity />*/}

        {schedule &&
        selectedDate &&
        schedule[
          `${selectedDate.year}.${selectedDate.month}.${selectedDate.date}`
        ] ? (
          schedule[
            `${selectedDate.year}.${selectedDate.month}.${selectedDate.date}`
          ].length ? (
            schedule[
              `${selectedDate.year}.${selectedDate.month}.${selectedDate.date}`
            ].map((item, idx) =>
              item.schedule_type === "semos" ? (
                <SemosActivity
                  key={`schedule_${idx}_${item.activity_name}`}
                  data={item}
                />
              ) : (
                <PersonalActivity
                  save={SaveSchedule}
                  index={idx}
                  key={`schedule_${idx}_${item.activity_name}`}
                  close={DeleteSchedule}
                  data={item}
                  date={`${selectedDate.year}.${selectedDate.month}.${selectedDate.date}`}
                />
              )
            )
          ) : (
            <EmptySchedule>
              <span>아래 ‘일정 추가하기’ 버튼을 눌러</span>
              <span>일정을 추가해보세요!</span>
            </EmptySchedule>
          )
        ) : (
          <EmptySchedule>
            <span>아래 ‘일정 추가하기’ 버튼을 눌러</span>
            <span>일정을 추가해보세요!</span>
          </EmptySchedule>
        )}
      </Styler>

      <AddScheduleButton
        onClick={() => {
          navigate(
            `/add_schedule/${selectedDate.year}/${selectedDate.month}/${selectedDate.date}`
          );
          SaveOne("history2", "/my_calender");
        }}
      >
        <PlusIcon src={Plus} />
        <NoAppointmentText>일정 추가하기</NoAppointmentText>
      </AddScheduleButton>

      <div style={{ height: "183px" }} />

      {/*<Footer />*/}
    </Container>
  );
}

export default MyCalender;

const Container = styled.section`
  height: calc(var(--vh, 1vh) * 100);
  overflow-x: hidden;
`;

const CalnederContent = styled.div`
  border-bottom: 2px solid #f5f5f5;
`;

const Styler = styled.div`
  position: relative;
  display: ${({ flex }) => (flex ? "flex" : "")};
  flex-direction: ${({ direction }) => direction};
  justify-content: ${({ justify }) => justify};
  padding-top: ${({ pt }) => pt}px;
  padding-bottom: ${({ pb }) => pb}px;
  padding-left: ${({ pl }) => pl}px;
  padding-right: ${({ pr }) => pr}px;
`;

const CalendarMonthSelector = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  /* align-items: center; */
  margin-bottom: 18px;
`;

const MonthSelector = styled.div`
  cursor: pointer;
  width: 24px;
  height: 24px;
`;

const MonthSelectorIcon = styled.img`
  width: 24px;
  height: 24px;
`;

const MonthSelectorYM = styled.div`
  font-weight: 600;
  font-size: 16px;
  line-height: 142%;
  display: flex;
  align-items: center;
  letter-spacing: -0.02em;
  color: #1a1c1f;
`;

const YoilContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: ${W - 48}px;
`;

const YoilContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${DateBox}px;
  height: ${DateBox}px;
`;

const YoilContentText = styled.div`
  color: ${(props) => props.color};
  font-weight: 500;
  font-size: 12px;
  line-height: 142%;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: -0.02em;
  margin-bottom: 2px;
`;

const DayContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  width: ${W - 48}px;
`;

const DayContentNotTouch = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${DateBox}px;
  height: ${DateBox}px;
  margin-bottom: 2px;
  opacity: 0.9;
  ${(props) => (props.mr ? `margin-left : 6px;` : "")};
`;

const DayContentClose = styled.div`
  align-items: center;
  justify-content: center;
  width: ${DateBox}px;
  height: ${DateBox}px;
  margin-bottom: 2px;
  ${(props) => (props.mr ? `margin-left : 6px;` : "")};
  border: 1px solid #dcdcdc;
  background: #f5f5f5;
  position: relative;
`;

const DayContentText = styled.div`
  color: ${(props) => props.color};
  ${(props) =>
    props.active
      ? `
  color : #ffffff;
  `
      : ""}
  font-weight: 500;
  font-size: ${({ oneul, active }) => (oneul && active ? "11.5px" : "14px")};
  line-height: 142%;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: -0.02em;
`;

const DayContent = styled.div`
  cursor: pointer;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${DateBox}px;
  height: ${DateBox}px;
  margin-bottom: 2px;
  ${(props) => (props.mr ? `margin-left : 6px;` : "")};
  ${(props) =>
    props.active
      ? `
  border-radius : 50%;
  background-color : #0E6DFF;
 
  `
      : ""}
  ${({ oneul }) =>
    oneul &&
    `border: 1px dashed #0E6DFF; 
  border-radius: 50%;
 `}
`;

const BlueDot = styled.div`
  background-color: #0e6dff;
  width: 4px;
  height: 4px;
  border-radius: 50%;
  position: absolute;
  top: 7px;
  right: 8px;
`;

const NoAppointment = styled.div`
  padding: 24px;
  text-align: center;
  background-color: #e5f0ff;
  font-weight: 500;
  font-size: 14px;
  line-height: 142%;
  border-radius: 15px;
  letter-spacing: -0.02em;
  color: #3383ff;
`;

const AddScheduleButton = styled.div`
  cursor: pointer;
  position: absolute;
  width: ${W - 48}px;
  bottom: 107px;
  padding: 7px 0;
  margin: 0 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #66a2ff;
  border-radius: 8px;
  background-color: #ffffff;
`;

const PlusIcon = styled.img`
  width: 24px;
  height: 24px;
  margin-right: 10px;
`;

const NoAppointmentText = styled.div`
  font-size: 14px;
  font-weight: 500;
  line-height: 142%;
  color: #66a2ff;
`;

const EmptySchedule = styled.div`
  width: 100%;
  background-color: #e5f0ff;
  border-radius: 15px;
  color: #3383ff;
  font-weight: 500;
  align-items: cetner;
  justify-content: center;
  line-height: 142%;
  font-size: 14px;
  padding: 24px;
  display: inline-block;

  span {
    text-align: center;
    width: 100%;
    display: inline-block;
  }
`;
