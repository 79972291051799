import React, { useEffect, useState, useRef } from "react";
import styled from "styled-components";

// tool
import PhoneAuth from "./phone_auth";


//img
import ClearIcon from "../../icon/clearInput.png";
import RedWarning from "../../icon/redWarning.png";

const H = window.innerHeight;

const DesignSize = H / 812;

const SignUpSecond = ({
  page,
  status,
  setStatus,
  userInfo,
  setUserInfo,
  SetName
}) => {
  const nameRef = useRef(null);
  const phoneRef = useRef(null);
  const authRef = useRef(null);

  const [phoneText, setPhoneText] = useState("");
  const [verifyToken, setVerifyToken] = useState("");
  const [timer, setTimer] = useState("");

  const min = `0${Math.floor(timer / 60)}`;
  const sec = timer % 60 < 10 ? "0" + (timer % 60) : timer % 60;

  const [open, setOpen] = useState(false);

  const setUserData = (type, value) => {
    if (type == "phone") {
      if (status.phone !== "ok" || status.auth !== "ok") {
        setUserInfo((prev) => ({ ...prev, [type]: value }));
      }
    } else {
      setUserInfo((prev) => ({ ...prev, [type]: value }));
    }
  };

  const clearInput = (type) => {
    setUserData(type, "");
    if (type == "name") {
      nameRef.current.focus();
    } else if (type == "phone") {
      phoneRef.current.focus();
    } else if (type == "auth") {
      authRef.current.focus();
    }
  };



  useEffect(() => {
    SetName()

    /*let phone = userInfo.phone;

    phone = phone
    .replace(/[^0-9]/, "")
    .replace(/^(\d{2,3})(\d{3,4})(\d{4})$/, `$1-$2-$3`);

    setPhoneText(phone);*/

  }, [userInfo.name]);



  /*const phoneVerify = () => {
    var token = GetOne("token");

    if (userInfo.phone.length === 11 && !isNaN(userInfo.phone)) {
      const frm = new FormData();
      frm.append("mode", "phone_number_confirm");
      frm.append("phone_number", phoneText);
      frm.append("token", token);

      axios
        .post("https://ai.semos.kr/semos_partner/auth/login", frm, {
          headers: {
            "Content-Type": "multipart/form-data",
            Accept: "application/json",
          },
        })
        .then((data) => {
          var Value = data.data;
          var Status = Value.status;
          var CheckToken = Value.check_t;

          if (Status === "success") {
            setOpen(true);
            setStatus({ ...status, phone: "ok" });
            setTimer(180);
            setVerifyToken(CheckToken);
          } else if (Status === "fail") {
            alert("인증번호 전송에 실패하였습니다.");
          }
        });
    } else {
      alert("전화번호를 다시 한번 확인해주세요.");
    }
  };*/

  /*const authNumCheck = () => {
    var token = GetOne("token");

    if (userInfo.auth.length !== 6) {
      alert("인증번호 6자리를 입력해주세요.");
    } else {
      const frm = new FormData();
      frm.append("mode", "verification_confirm");
      frm.append("verification_token", verifyToken);
      frm.append("verification_number", userInfo.auth);
      frm.append("phone_number", phoneText);
      frm.append("token", token);

      axios
        .post("https://ai.semos.kr/semos_partner/auth/login", frm, {
          headers: {
            "Content-Type": "multipart/form-data",
            Accept: "application/json",
          },
        })
        .then((data) => {
          var Value = data.data;

          if (Value.status === "success") {
            setStatus({ ...status, auth: "ok" });
            alert("인증성공");
          } else {
            setStatus({ ...status, auth: "denied" });
            alert("인증번호가 올바르지 않습니다.");
          }
        });
    }
  };*/

  //   useEffect(() => {
  //     if (timer !== 0 && status.auth !== "ok") {
  //       const time = setInterval(() => {
  //         setTimer((prev) => prev - 1);
  //       }, 1000);

  //       return () => clearInterval(time);
  //     } else if (timer == 0 && status.auth !== "ok") {
  //       alert("인증번호가 만료되었습니다. 다시 시도해 주세요.");
  //       setOpen(false);
  //       setUserInfo({ ...userInfo, phone: "" });
  //       setStatus({ ...status, phone: "default" });
  //     }
  //   }, [timer]);

  useEffect(() => {
    if (status.phone !== "ok") {
      setStatus({ ...status, phone: "black" });
    }
  }, [userInfo.phone]);

  useEffect(() => {
    if (status.auth !== "ok") {
      setStatus({ ...status, auth: "black" });
    }
  }, [userInfo.auth]);

  const PhoneReset = () => {
    setStatus({ ...status, phone: "black", auth: "black" });

    setUserInfo({ ...userInfo, phone: "", auth: "" });
  };

  if(page === 2) {
    return (
      <>
        <InputContainer status={status.name}>
          <TextInput
            need={true}
            ref={nameRef}
            type="text"
            maxLength={15}
            placeholder="인증을 위해 본명을 입력해 주세요."
            onChange={(e) => setUserData("name", e.target.value)}
            value={userInfo.name}
          />
          {userInfo.name && (
            <ClearButton src={ClearIcon} onClick={() => clearInput("name")} />
          )}
        </InputContainer>

        {status.name === "denied" && <Divider h={6} />}

        {status.name === "denied" && (
          <WarningTextContainer need={true}>
            <WarningIcon src={RedWarning} />
            <WarningText negative={true}>
              올바른 이름으로 작성해 주세요!
            </WarningText>
          </WarningTextContainer>
        )}

        <Divider h={24} />

        <PhoneAuth
          userInfo={userInfo}
          setUserInfo={setUserInfo}
          status={status}
          setStatus={setStatus}
        />

        {/* <InputContainerWithButton phone={true} status={status.phone}>
          {open ? (
            <PhoneBox>{phoneText}</PhoneBox>
          ) : (
            <TextInput
              ref={phoneRef}
              type="tel"
              maxLength={11}
              placeholder="전화번호를 입력해 주세요."
              onChange={(e) => setUserData("phone", e.target.value)}
              value={userInfo.phone}
            />
          )}
          {status.phone !== "ok" || status.auth !== "ok" ? (
            <JustBetween>
              {userInfo.phone && !open && (
                <ClearButton
                  src={ClearIcon}
                  onClick={() => clearInput("phone")}
                />
              )}
              <PhoneButton open={open} onClick={() => phoneVerify()}>
                {open ? "전송완료" : "인증번호 전송"}
              </PhoneButton>
            </JustBetween>
          ) : (
            <JustBetween>
              <PhoneButton onClick={() => PhoneReset()} open={false}>
                수정하기
              </PhoneButton>
            </JustBetween>
          )}
        </InputContainerWithButton> */}
        <Divider h={8} />
        {/* {open && (
          <AnimationBox>
            <InputContainerWithButton status={status.auth}>
              {status.auth === "ok" ? (
                <>
                  <AuthBox>{userInfo.auth}</AuthBox>
                  <JustBetween>
                    <Okay src={OkaySign} />
                    <AuthButton status={status.auth}>인증완료</AuthButton>
                  </JustBetween>
                </>
              ) : (
                <>
                  <TextInput
                    ref={authRef}
                    type="tel"
                    maxLength={6}
                    placeholder="인증번호를 입력해 주세요!"
                    onChange={(e) => setUserData("auth", e.target.value)}
                    value={userInfo.auth}
                  />

                  <JustBetween>
                    {userInfo.auth && status.auth !== "ok" && (
                      <ClearButton
                        src={ClearIcon}
                        onClick={() => clearInput("auth")}
                      />
                    )}
                    {timer && (
                      <Timer>
                        {min}:{sec}
                      </Timer>
                    )}
                    <AuthButton
                      status={status.auth}
                      onClick={() => authNumCheck()}
                    >
                      인증완료
                    </AuthButton>
                  </JustBetween>
                </>
              )}
            </InputContainerWithButton>
            {status.phone === "ok" && status.auth !== "ok" && (
              <>
                <Divider h={6} />
                <WarningTextContainer need={true}>
                  <WarningIcon src={OkaySign} />
                  <WarningText negative={false}>
                    인증번호 전송이 완료되었습니다.
                  </WarningText>
                </WarningTextContainer>
              </>
            )}

            {status.auth === "ok" && (
              <>
                <Divider h={6} />
                <WarningTextContainer need={true}>
                  <WarningIcon src={OkaySign} />
                  <WarningText negative={false}>
                    인증이 완료되었습니다.
                  </WarningText>
                </WarningTextContainer>
              </>
            )}

            {status.auth === "denied" && (
              <>
                <Divider h={6} />
                <WarningTextContainer>
                  <WarningIcon src={RedWarning} />
                  <WarningText negative={true}>
                    올바른 인증번호로 다시 입력해주세요!
                  </WarningText>
                </WarningTextContainer>
              </>
            )}
          </AnimationBox>
        )} */}
      </>
    );
  }
}

export default SignUpSecond;

const InputContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid;
  border-radius: 8px;
  padding: 16px;
  border-color: ${({ status }) =>
    status == "ok"
      ? "#66A2FF"
      : status == "black"
      ? "#818792"
      : status == "default"
      ? "#F5F5F5"
      : "#FF9187"};
  height: 52px;
`;

const InputContainerWithButton = styled(InputContainer)`
  position: relative;
  padding: 7px 0px 7px 15px;
  height: 52px;
  background-color: white;
  z-index: ${({ phone }) => (phone ? 3 : 1)};
`;

const TextInput = styled.input`
  border: none;
  /* width: ${({ need }) => need && "65%"}; */
  width: 65%;
  font-size: 14px;
  outline: none;
  font-weight: 500;
  line-height: 19.88px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  &::placeholder {
    color: #acafb5;
  }
`;

const Divider = styled.div`
  height: ${({ h }) => h * DesignSize}px;
`;

const ClearButton = styled.img`
  width: 20px;
  height: 20px;
`;

const PhoneButton = styled.div`
  padding: 9px 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 700;
  font-size: 14px;
  line-height: 142%;
  background-color: ${({ open }) => (open ? "#DCDCDC" : "#0e6dff")};
  letter-spacing: -0.02em;
  color: #ffffff;
  border-radius: 8px;
  margin-left: 10px;
  margin-right: 10px;
  white-space: nowrap;
`;

const WarningTextContainer = styled.div`
  display: flex;
  align-items: ${({ need }) => (need ? "center" : "")};
`;

const WarningIcon = styled.img`
  width: 14px;
  height: 14px;
  margin-right: 6px;
`;

const WarningText = styled.div`
  font-weight: 600;
  font-size: 12px;
  line-height: 142%;
  display: flex;
  align-items: center;
  letter-spacing: -0.02em;
  color: ${({ negative }) => (negative ? "#ff9187" : "#66A2FF")};
`;

const JustBetween = styled.div`
  display: flex;
  align-items: center;
`;

const PhoneBox = styled.div`
  width: 65%;
  font-size: 14px;
  font-weight: 500;
  line-height: 19.88px;
`;

const AuthButton = styled(PhoneButton)`
  background-color: ${({ status }) => (status == "ok" ? "#DCDCDC" : "#0e6dff")};
`;

const Okay = styled.img`
  width: 24px;
  height: 24px;
`;

const AuthBox = styled.div`
  width: 65%;
  font-size: 14px;
  font-weight: 500;
  line-height: 19.88px;
`;

const AnimationBox = styled.div`
  @keyframes dropdown {
    0% {
      transform: translateY(-52px);
      /* opacity: 0; */
    }
    100% {
      transform: translateY(0);
      /* opacity: 1; */
    }
  }

  animation: dropdown 0.5s ease;
`;

const Timer = styled.div`
  font-weight: 600;
  font-size: 12px;
  line-height: 142%;
  display: flex;
  align-items: center;
  letter-spacing: -0.02em;
  color: #3383ff;
  margin-left: 6px;
`;
