import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { useParams, useNavigate } from "react-router-dom";
import axios from "axios";
import { LoadingSpinner } from "../tool/loading";

// components
import Header from "../components/basic/Header";
import { GetOne } from "../tool/localstorage";
import SemosSchedule from "../components/signup/semos_schedule";

function PartnerSchedule() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const [addressS, setAddressS] = useState(false);
  const [scheduleName, setScheduleName] = useState("");
  const [activeTime, setActiveTime] = useState({
    start: "06:00",
    end: "22:00",
  });

  const [yoil, setYoil] = useState([]);

  const [start, setStart] = useState([]);

  const [focus, setFocus] = useState({
    name: false,
    address_extra: false,
  });

  const [address, setAddress] = useState({
    address: "",
    address_extra: "",
    address_dong: "",
    address_gu: "",
    address_si: "",
  });

  const next =
    scheduleName &&
    activeTime.start &&
    activeTime.end &&
    yoil.length &&
    start.length &&
    address.address &&
    address.address_extra &&
    address.address_dong &&
    address.address_gu &&
    address.address_si;

  const checkNextStep = () => {
    var token = GetOne("token");
    setLoading(true);
    axios
      .post(
        "https://ai.semos.kr/semos_partner/auth/profile",
        {
          mode: "partner_schedule",
          token: token,
          title: scheduleName,
          address: address,
          activeTime: activeTime,
          yoil: yoil,
          start: start,
        },
        {
          headers: { "Content-Type": "application/x-www-form-urlencoded" },
        }
      )
      .then((data) => {
        var Value = data.data;
        var Success = Value.status;
        var SuccessMessage = Value.status_message;

        if (Success == "success") {
          setLoading(false);
          window.alert("파트너 입점신청이 완료되었습니다.");
          navigate("/home", { replace: true });
        } else {
          setLoading(false);
          window.alert(SuccessMessage);
        }
      });
  };

  return (
    <Container>
      <Header title="세모스 일정 등록하기" back={true} />

      <InnerContainer id="chatContainer">
        <BackgroundContent
          style={{ borderBottom: "1px solid #F5F5F5" }}
          jc={`space-between`}
          padding={`24px`}
          ai={`center`}
        >
          <TextContent
            gmarket={true}
            style={{ justifyContent: "space-between" }}
            wrap={`wrap`}
          >
            <TextView
              gmarket={true}
              color={`#0E6DFF`}
              fw={`400`}
              fs={`16px`}
              lh={`142%`}
            >{`3. 파트너 정보 등록하기`}</TextView>
          </TextContent>

          <TextContent
            gmarket={true}
            style={{ justifyContent: "space-between" }}
            wrap={`wrap`}
          >
            <TextView
              gmarket={true}
              color={`#0E6DFF`}
              fw={`400`}
              fs={`16px`}
              lh={`142%`}
            >{`3`}</TextView>

            <TextView
              gmarket={true}
              color={`#ACAFB5`}
              fw={`400`}
              fs={`16px`}
              lh={`142%`}
            >{`/3`}</TextView>
          </TextContent>
        </BackgroundContent>

        <SemosSchedule
          scheduleName={scheduleName}
          setScheduleName={setScheduleName}
          activeTime={activeTime}
          setActiveTime={setActiveTime}
          yoil={yoil}
          setYoil={setYoil}
          start={start}
          setStart={setStart}
          focus={focus}
          setFocus={setFocus}
          address={address}
          setAddress={setAddress}
          setAddressS={setAddressS}
        />

        <BackgroundContent padding={`40px 24px 0 24px`} ai={`center`}>
          <TextContent
            onClick={() => next && checkNextStep()}
            jc={`center`}
            width={`100%`}
            padding={`16.8px 0`}
            bgc={`${next ? `#0E6DFF` : "#F5F5F5"}`}
            br={`8`}
            border={`1px solid ${next ? `#0E6DFF` : "#DCDCDC"}`}
          >
            <TextView
              color={next ? `#ffffff` : `#ACAFB5`}
              fw={`600`}
              fs={`16px`}
              lh={`142%`}
            >{`완료`}</TextView>
          </TextContent>
        </BackgroundContent>
      </InnerContainer>

      <AddressPopup view={addressS} id={"PostCodeAdd"} />

      <LoadingSpinner
        loading={loading}
        title={`정보를 확인하고 있습니다.`}
        content={`잠시만 기다려 주세요!`}
      />
    </Container>
  );
}

export default PartnerSchedule;

const Container = styled.section`
  height: calc(var(--vh, 1vh) * 100);
  /* height: 100vh; */
  overflow: hidden;
`;

const AddressPopup = styled.section`
  height: calc(var(--vh, 1vh) * 100);
  /* height: 100vh; */
  width: calc(var(--vw, 1vw) * 100);
  overflow: hidden;
  position: fixed;
  z-index: 150;
  background-color: #ffffff;
  top: 0;
  right: 0;
  display: ${({ view }) => (view ? `inline-flex` : `none`)};
`;

const InnerContainer = styled.div`
  width: 100%;
  height: calc((var(--vh, 1vh) * 100) - (51px));
  /* height: calc(100vh - (51px)); */
  overflow-y: auto;
  overflow-x: hidden;
  display: inline-block;
  position: relative;
  z-index: 51;
  padding-bottom: 10px;
`;

const BackgroundContent = styled.div`
  width: 100%;
  border: ${({ border }) => border && `${border}`};
  background-color: ${({ bgc }) => bgc && `${bgc}`};
  padding: ${({ padding }) => padding && `${padding}`};
  display: inline-flex;
  border-radius: ${({ br }) => br && `${br}px`};
  justify-content: ${({ jc }) => jc && `${jc}`};
  align-items: ${({ ai }) => ai && `${ai}`};
  flex-wrap: ${({ wrap }) => wrap && `${wrap}`};
  position: relative;
`;

const TextContent = styled.div`
  width: ${({ width }) => width && `${width}`};
  border: ${({ border }) => border && `${border}`};
  background-color: ${({ bgc }) => bgc && `${bgc}`};
  padding: ${({ padding }) => padding && `${padding}`};
  display: inline-flex;
  flex-wrap: ${({ wrap }) => wrap && `${wrap}`};
  border-radius: ${({ br }) => br && `${br}px`};
  justify-content: ${({ jc }) => jc && `${jc}`};
  align-items: ${({ ai }) => ai && `${ai}`};
  ${({ gmarket }) => gmarket && `font-family : "GmarketSans" !important;`}
`;

const TextView = styled.span`
  width: ${({ width }) => width && `${width}`};
  text-align: ${({ ta }) => ta && `${ta}`};
  color: ${({ color }) => color && `${color}`};
  font-weight: ${({ fw }) => fw && `${fw}`};
  font-size: ${({ fs }) => fs && `${fs}`};
  line-height: ${({ lh }) => lh && `${lh}`};
  display: inline-block;
  text-overflow: ${({ to }) => to && `${to}`};
  overflow: hidden;
  white-space: ${({ ws }) => ws && `${ws}`};
  ${({ gmarket }) => gmarket && `font-family : "GmarketSans" !important;`}
`;
