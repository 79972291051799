import React from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

import Header from "../components/basic/Header";
import { kakaoChannel } from "../tool/kakao";

// style
import colors from "../styles/colors";
import { W } from "../styles/globalStyles";

// icon
import { ReactComponent as Arrow } from "../resource/icon/arrow.svg";
import { ReactComponent as SemosLogo } from "../icon/semos_logo.svg";
import DivingFlippers from "../icon/diving_flippers.svg";
import RoundCalculate from "../icon/round_calculate.svg";
import ShoppingBag from "../icon/shopping_bag.svg";
import Volcano from "../icon/volcano.svg";
import Brush from "../icon/brush.svg";
import Calendar from "../icon/calendar.svg";
import GraphBar from "../icon/graph_bar.svg";
import WhySemos01 from "../icon/why_semos_01.png";
import WhySemos02 from "../icon/why_semos_02.png";
import AffiliateItem01 from "../icon/affiliate_item_01.png";
import AffiliateItem02 from "../icon/affiliate_item_02.png";
import AffiliateItem03 from "../icon/affiliate_item_03.png";

const ICONS_01 = [DivingFlippers, RoundCalculate, ShoppingBag, Volcano];

const AFFILIATE_PLAN = [
  {
    title: "세모스 전담 디자이너가 직접 작업해요",
    content: "이벤트 프로모션 및 노출 광고 에셋 디자인을 제공해요",
    img: Brush,
  },
  {
    title: "원하는 날짜에 홍보할 수 있어요",
    content: "파트너님이 원하는 날짜에 최대한 배치해드려요",
    img: Calendar,
  },
  {
    title: "성과 보고서도 제공해드려요",
    content: "노출 성과와 도달 유저에 대한 보고서를 제공해요",
    img: GraphBar,
  },
];
const WHY_SEMOS = [
  {
    content:
      "기획전 액티비티는 타 액티비티보다 \n평균 4배 이상의 조회수를 증가시켜요",
    img: WhySemos01,
  },
  {
    content: "기획전 액티비티는 구매전환도 2배나 상승시켜줘요",
    img: WhySemos02,
  },
];
const AFFILIATE_ITEMS = [
  {
    title: "메인 배너 노출",
    subtitle: "Lv1. 액티비티 노출을 '더' 높여줄 수 있는",
    content: "유저들이 파트너님의 액티비티를 앱 최상단에서 볼 수 있어요.",
    img: AffiliateItem01,
  },
  {
    title: "팝업 노출",
    subtitle: "Lv2. 유저들의 시선을 한 번에 사로잡는",
    content: "유저들이 파트너님의 액티비티를 앱 오픈시 팝업으로 볼 수 있어요.",
    img: AffiliateItem02,
  },
  {
    title: "단독 기획전 (메인 배너 포함)",
    subtitle: "Lv3. 강사님의 모든 액티비티를 한 번에 홍보하는",
    content: "액티비티 기획전을 통해 상품 접근성 및 구매를 높여보세요.",
    img: AffiliateItem03,
  },
];
const NOTIFICATION = [
  {
    title: "플랜 안내사항",
    content: `• 모든 이용 요금은 부가세(10%) 별도예요. \n• 결제가 완료되면 영업일 기준 1일 이내에 연락을 드려요. \n• 디자인 시안 작업이 종료된 후에는 취소하실 수 없어요. \n• 이용 기간 종료 후, 2주 안으로 결과를 공유해드려요.`,
  },
  {
    title: "환불 안내",
    content: "상담 센터를 통해 문의하시면 안내받을 수 있어요.",
  },
];

const NOTION_URL =
  "https://www.notion.so/semos/47945ce42be94725a14dd79783acd53a?pvs=4";

const AffiliateInfo = () => {
  const navigate = useNavigate();

  return (
    <StAffiliateInfo>
      <Header title="세모스 제휴 상품 소개" back={true} />

      <StAffiliateHeader>
        <SemosLogo />
        <StAffiliateTitle>세모스 파트너님 반가워요!</StAffiliateTitle>
        <StAffiliateSubTitle>
          세모스 노출 구좌 제휴 상품을 통해 <br />
          더욱 다양한 고객들과 만나보세요
        </StAffiliateSubTitle>
      </StAffiliateHeader>

      <StAffiliateBody>
        <StStep>
          <StStepTitle pb="8px">왜 세모스 인가요?</StStepTitle>
          <StStep1Content>
            수업하고, 정산하고, 액티비티 상품 관리까지 <br />
            정신 없으시죠?
          </StStep1Content>
          <IconContainer>
            <IconList>
              {ICONS_01.map((val, idx) => (
                <li key={idx}>
                  <IconItem w="98px" h="98px">
                    <img src={val} alt="icon" />
                  </IconItem>
                </li>
              ))}
            </IconList>
          </IconContainer>
          <StStep1Content>
            다가오는 여름을 맞이해 <br />
            합리적인 제휴 상품으로 더욱 똑똑하게 판매해보세요.
          </StStep1Content>
        </StStep>

        <StStep>
          <StStepTitle pb="16px">제휴 상품 플랜을 이용하면,</StStepTitle>
          <StPlanList>
            {AFFILIATE_PLAN.map((val) => {
              const { title, content, img } = val;
              return (
                <li key={title}>
                  <IconItem w="50px" h="50px">
                    <img src={img} alt={`${title}-img`} />
                  </IconItem>
                  <StPlanText>
                    <StPlanTitle>{title}</StPlanTitle>
                    <StPlanContent>{content}</StPlanContent>
                  </StPlanText>
                </li>
              );
            })}
          </StPlanList>
        </StStep>

        <StStep>
          <StStepTitle pb="28px">왜 세모스 제휴상품일까요?</StStepTitle>
          <StWhySemosList>
            {WHY_SEMOS.map((val, idx) => {
              const { content, img } = val;
              return (
                <li key={content}>
                  <img src={img} alt={`whysemos-img-${idx}`} />
                  <StWhySemosContent>{content}</StWhySemosContent>
                </li>
              );
            })}
          </StWhySemosList>
        </StStep>

        <StStep>
          <StStepTitle pb="16px">어떤 상품이 있나요?</StStepTitle>
          <StItemList>
            {AFFILIATE_ITEMS.map((val) => {
              const { title, subtitle, content, img } = val;
              return (
                <li key={title}>
                  <StItemText>
                    <StItemSubtitle>{subtitle}</StItemSubtitle>
                    <StItemTitle>{title}</StItemTitle>
                    <StItemContent>{content}</StItemContent>
                  </StItemText>
                  <img src={img} alt={`${title}-img`} />
                </li>
              );
            })}
          </StItemList>
          <StConsultBtn onClick={() => kakaoChannel()}>
            <span>더 알아보고 싶다면? 컨설팅 문의하기</span>
            <Arrow fill={colors.Bl07} />
          </StConsultBtn>
        </StStep>

        <StNoti>
          <StNotiList>
            {NOTIFICATION.map((val) => {
              const { title, content } = val;
              return (
                <li key={title}>
                  <StNotiTitle>{title}</StNotiTitle>
                  <StNotiContent>{content}</StNotiContent>
                </li>
              );
            })}
          </StNotiList>
        </StNoti>
      </StAffiliateBody>

      <StFooter>
        <NextButton onClick={() => window.open(NOTION_URL, "_blank")}>
          <span>세모스 제휴 상품 보러가기</span>
        </NextButton>
      </StFooter>
    </StAffiliateInfo>
  );
};

export default AffiliateInfo;

const StAffiliateInfo = styled.div`
  width: ${W}px;
  height: 100vh;
  overflow-y: auto;
  overflow-x: hidden;
`;

const StGoBack = styled.button`
  svg {
    rotate: calc(180deg);
    path {
      fill: ${colors.Gr06};
    }
  }
`;

const StAffiliateHeader = styled.div`
  padding: 32px 24px 24px 24px;
  background-color: ${colors.Bl02};
`;

const StAffiliateTitle = styled.h2`
  margin-top: 16px;
  margin-bottom: 8px;
  color: ${colors.Bl07};
  line-height: 32px;
  font-size: 24px;
  font-weight: 700;
`;

const StAffiliateSubTitle = styled.span`
  color: ${colors.Gr05};
  line-height: 22px;
  letter-spacing: -0.02em;
  font-size: 14px;
  font-weight: 500;
`;

const StAffiliateBody = styled.div`
  padding: 0 24px 0 24px;
`;

const StStep = styled.div``;

const StStepTitle = styled.h3`
  padding-top: 32px;
  padding-bottom: ${({ pb }) => pb};
  color: ${colors.Gr06};
  line-height: 28px;
  letter-spacing: -0.02em;
  font-size: 20px;
  font-weight: 700;
`;

const StStep1Content = styled.span`
  display: inline-block;
  padding-bottom: 16px;
  color: ${colors.Gr04};
  line-height: 24px;
  letter-spacing: -0.02em;
  font-size: 16px;
  font-weight: 500;
`;

const IconContainer = styled.div`
  height: 98px;
  margin-bottom: 16px;
  position: relative;
`;

const IconList = styled.ul`
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  gap: 16px;
  overflow: hidden;
  position: absolute;
  left: ${() =>
    window.document.documentElement.clientWidth > 440 ? "0px" : "-54px"}; // 440 : iconWidth * 4 + gap * 3
`;

const IconItem = styled.div`
  width: ${({ w }) => w};
  height: ${({ h }) => h};
  border-radius: 50%;
  background-color: ${colors.Bl02};
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StPlanList = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding-bottom: 12px;

  li {
    display: flex;
    align-items: center;
    gap: 16px;
  }
`;

const StPlanText = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2px;
`;

const StPlanTitle = styled.h4`
  color: ${colors.Gr05};
  line-height: 24px;
  letter-spacing: -0.02em;
  font-size: 16px;
  font-weight: 600;
`;

const StPlanContent = styled.span`
  color: ${colors.Gr04};
  word-break: keep-all;
  line-height: 22px;
  letter-spacing: -0.02em;
  font-size: 14px;
  font-weight: 500;
`;

const StWhySemosList = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 24px;

  li {
    display: flex;
    flex-direction: column;
    gap: 16px;

    img {
      object-fit: cover;
    }
  }
`;

const StWhySemosContent = styled.span`
  color: ${colors.Gr05};
  white-space: pre-wrap;
  line-height: 24px;
  letter-spacing: -0.02em;
  font-size: 16px;
  font-weight: 600;
`;

const StItemList = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding-top: 8px;

  li {
    display: flex;
    flex-direction: column;
    gap: 12px;

    img {
      object-fit: cover;
    }
  }
`;

const StItemText = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

const StItemTitle = styled.h4`
  color: ${colors.Gr05};
  line-height: 26px;
  letter-spacing: -0.02em;
  font-size: 18px;
  font-weight: 700;
`;

const StItemSubtitle = styled.span`
  color: ${colors.Bl07};
  line-height: 22px;
  font-size: 14px;
  font-weight: 600;
`;

const StItemContent = styled.span`
  color: ${colors.Gr04};
  line-height: 22px;
  letter-spacing: -0.02em;
  font-size: 14px;
  font-weight: 500;
`;

const StConsultBtn = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  width: 100%;
  margin-top: 24px;
  margin-bottom: 16px;
  padding: 14px 0;
  background-color: transparent;

  span {
    color: ${colors.Bl07};
    line-height: 24px;
    letter-spacing: -0.02em;
    font-size: 16px;
    font-weight: 600;
  }
`;

const StNoti = styled.div``;

const StNotiList = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding-top: 36px;
  padding-bottom: 136px;

  li {
    display: flex;
    flex-direction: column;
    gap: 4px;
  }
`;

const StNotiTitle = styled.h4`
  color: ${colors.Gr04};
  line-height: 22px;
  font-size: 14px;
  font-weight: 600;
`;

const StNotiContent = styled.p`
  white-space: pre-wrap;
  color: ${colors.Gr04};
  line-height: 22px;
  letter-spacing: -0.02em;
  font-size: 14px;
  font-weight: 500;
`;

const StFooter = styled.div`
  width: ${W}px;
  padding: 16px 24px;
  position: fixed;
  bottom: 0;
  background-color: ${colors.White};
`;

const NextButton = styled.button`
  width: 100%;
  padding: 14px 0;
  background-color: ${colors.Bl07};
  border-radius: 8px;

  span {
    color: ${colors.White};
    line-height: 24px;
    letter-spacing: -0.02em;
    font-size: 16px;
    font-weight: 600;
  }
`;
