import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import axios from "axios";

//tool
import { GetOne } from "../../tool/localstorage";

//style
import { Wrapper, Text, AbsoluteWrapper } from "../layouts/layoutStorage";
import colors from "../../styles/colors";

//component
import ActivityModal from "./NewActivityModal";
import AddCurriculum from "./NewAddCurriculum";

//icon
import { ReactComponent as Plus } from "../../resource/icon/plus.svg";

const ActivityPriceModal = ({ data, setData, open, setOpen, setDelete, setChange}) => {

    const [activityItem, setActivityItem] = useState({
        curriculum: {
            day1: [
                {
                    id: "new",
                    content: "",
                    last: "",
                    title: "",
                },
            ],
        },
    });

    const [showCurriculumDay, setShowCurriculumDay] = useState(1);
    const [deleteModal, setDeleteModal] = useState({
        type: "",
        open: false,
    });

    const [deleteItem, setDeleteItem] = useState({
        curriculum : []
    });

    useEffect(() => {
        if (open) {
            setActivityItem({
                curriculum: data.curriculum,
            });
        };
    }, [open]);


    const checkCurriculumFilled = (item) => {
        if (item) {
            return (
                activityItem.curriculum[item].length > 0 &&
                activityItem.curriculum[item].every((el, idx) => ((idx === 0) ? (el.content && parseInt(el.last) ) : el.content))
            );
        }
    };


    const CurriHandler = ( key, idx, type, value) => {
        let CI = activityItem;
        let CI_LIST = CI.curriculum;

        CI_LIST[key][idx][type] = value;
        CI_LIST[key][idx][type] = value;

        setActivityItem({
            curriculum: CI_LIST,
        });
    }

    const CurriHandlerDel = ( key, idx,) => {
        let CI = activityItem;
        let CI_LIST = CI.curriculum;

        let CI_D = deleteItem;
        let CI_LIST_D = CI_D.curriculum;

        let DelOg = CI_LIST[key];
        let DelOgId = DelOg[idx].id;

        CI_LIST_D.push(DelOgId);

        DelOg.splice(idx, 1);

        CI_LIST[key] = DelOg;

        setActivityItem({
            curriculum: CI_LIST,
        });
        setDeleteItem((prev) => ({ ...prev, curriculum: CI_LIST_D }));
    }

    const CurriHandlerPlus = ( key, idx) => {
        let CI = activityItem;
        let CI_LIST = CI.curriculum;

        let DelOg = CI_LIST[key];

        DelOg.push({
            id: "new",
            content: "",
            last: "",
            title: "",
        })

        CI_LIST[key] = DelOg;

        setActivityItem({
            curriculum: CI_LIST,
        });
    };

    return (
        <ActivityModal
            buttonText="저장하기"
            buttonActive={activityItem.curriculum.day1[0].content && activityItem.curriculum.day1[0].last}
            title="액티비티 커리큘럼"
            open={open}
            setOpen={setOpen}
            saveData={() => {
                setData((prev) => ({
                    ...prev,
                    curriculum: activityItem.curriculum
                }));
                setDelete((prev) => ({ ...prev, curriculum: deleteItem.curriculum }));
                setOpen(false);
                setChange(true);
            }}
        >

            <Wrapper fd="column" height="100%" width="100%" ai="flex-start" padding="0 0 130px 0">

                <Wrapper padding={'0 24px'} margin="8px 0 12px 0" wrap="wrap">

                    <TextP color={colors.Gr04}>
                        {`1회차는 하루를 기준으로 작성해주세요.\n소요시간은 분 단위로, 숫자만 입력해주세요.`}
                    </TextP>
                
                </Wrapper>

                <Wrapper padding={'0 24px 24px 24px'} height="100%" fd="column" width="100%" ai="flex-start" of="auto">

                    <Wrapper width="100%" jc="space-between" margin="0 0 8px 0">
                        <Wrapper
                            cursor="pointer"
                            onClick={() => {
                                let dataCopy = { ...activityItem };
                                let curriculumLength = Object.keys(dataCopy.curriculum).length;
                                dataCopy.curriculum[`day${parseInt(curriculumLength) + 1}`] = [
                                    {
                                        id: "new",
                                        title: "",
                                        content: "",
                                        last: "",
                                    },
                                ];

                                setActivityItem(dataCopy);
                            }}
                        >
                            <Text size="14px" weight="600" lh="22px" color={colors.Bl07}>
                            회차 추가
                            </Text>
                            <Plus width="20px" height="20px" fill={colors.Bl07} />
                        </Wrapper>
                    </Wrapper>
                    
                    <Wrapper width="100%" fd="column" gap="12px">
                        {Object.keys(activityItem.curriculum).map((item, index) => (
                            <Wrapper width="100%" fd="column" key={`${index}_curriculum`}>
                                <Wrapper
                                    /*onClick={() => {
                                        setShowCurriculumDay(item.split("day")[1]);
                                        setCurriculumModal(true);
                                        checkCurriculumFilled();
                                    }}*/
                                    cursor="pointer"
                                    jc="space-between"
                                    width="100%"
                                    padding="8px 0"
                                    margin="0 0 10px 0"
                                    bgc={colors.White}
                                    bb={`1px solid ${colors.Gr02}`}
                                >
                                    <Text
                                        size="16px"
                                        weight="500"
                                        lh="24px"
                                        ls="-0.32px"
                                        width="calc(100% - 24px)"
                                        color={checkCurriculumFilled(item) ? colors.Gr05 : colors.Gr03}
                                    >
                                        {item.split("day")[1]}회차 커리큘럼
                                    </Text>
                                    {/*<Plus width="24px" height="24px" fill={colors.Gr06} />*/}
                                </Wrapper>

                                <Wrapper position="relative" fd="column" width="100%" jc="end" gap="24px">
                                    <AbsoluteWrapper top="3px" left="7.5px" height={activityItem.curriculum[item].length > 1 ? "calc(100% - 26px)" : "100%"}>
                                        <Wrapper width="2px" height="100%" bgc={colors.Gr02} />
                                    </AbsoluteWrapper>
                                    {activityItem.curriculum[item].map((value, idx) => (
                                        <AddCurriculum
                                            key={`curriculum_${index}_${idx}`}
                                            open={open}

                                            number={item}
                                            item={value}
                                            index={idx}

                                            CurriHandler={CurriHandler}
                                            CurriHandlerDel={CurriHandlerDel}
                                            CurriHandlerPlus={CurriHandlerPlus}

                                            last={(activityItem.curriculum[item].length - 1) == idx}
                                        />
                                    ))}
                                </Wrapper>

                                {(index !== 0 && index + 1) === Object.keys(activityItem.curriculum).length && (
                                    <Text
                                        cursor="pointer"
                                        margin="4px 0 0 0"
                                        onClick={() => {
                                            if (checkCurriculumFilled(item)) {
                                                setShowCurriculumDay(item.split("day")[1]);
                                                setDeleteModal({
                                                    type: "curriculum",
                                                    open: true,
                                                });
                                            } else {
                                                let dataCopy = { ...activityItem };
                                                delete dataCopy.curriculum[item];
                                                setActivityItem(dataCopy);
                                            }
                                        }}
                                        style={{ alignSelf: "end" }}
                                        size="14px"
                                        weight="600"
                                        lh="22px"
                                        color={colors.Gr04}
                                    >
                                        회차 삭제
                                    </Text>
                                )}
                            </Wrapper>
                        ))}
                    </Wrapper>

                </Wrapper>

            </Wrapper>

        </ActivityModal>
    );
};

export default ActivityPriceModal;

const TextP = styled.p`
    font-size: 14px;
    font-weight: 500;
    line-height: 22px;
    letter-spacing: -0.28px;
    color: ${colors.Gr04};
    word-break: keep-all;
    span {
        color: ${colors.Red03};
        word-break: keep-all;
    }
`;

