import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import QRCode from "react-qr-code";
import html2canvas from "html2canvas";
import saveAs from "file-saver";

// components
import {
  Wrapper,
  WrapperColumn,
  Icon,
  Text,
  AbsoluteWrapper,
  Label,
} from "../components/layouts/layoutStorage";
import Header from "../components/basic/Header.jsx";
import Modal from "../components/profileRevise/Modal.jsx";

// tool
import { setImageFromFile, CropImage } from "../tool/ImageCrop";
import { GetOne } from "../tool/localstorage";
import { fbevent } from "../tool/firebase";
import { LoadingSpinner } from "../tool/loading";

// style
import colors from "../styles/colors";

// icon
import { ReactComponent as ProfileEditIcon } from "../resource/icon/camera_edit.svg";

const MyInfo = () => {
  const navigate = useNavigate();

  const FacilityQR = useRef();
  const TicketQR = useRef();

  const [loading, setLoading] = useState(true);
  const [semosReview, setSemosReview] = useState(false);
  const [info, setInfo] = useState("");
  const [crop, setCrop] = useState(false);
  const [cropImageSetting, setCropImageSetting] = useState({
    image: "",
    type: "",
    width: 0,
    height: 0,
  });

  useEffect(() => {
    getData();

    fbevent("B2B_PartnerInfo_Page_View", {
      email : GetOne("email")
    });
  }, []);

  const getData = () => {
    setLoading(true);
    const token = GetOne("token");
    const frm = new FormData();

    frm.append("mode", "main");
    frm.append("token", token);

    axios
      .post("https://ai.semos.kr/semos_partner/v3/profile/MyInfo", frm, {
        headers: { "Content-Type": "multipart/form-data" },
      })
      .then((res) => {
        const value = res.data;
        const status = value.status;
        const statusMessage = value.status_message;
        const data = value.data;

        if (status === "success") {
          setInfo(data);
          setLoading(false);
        }
      });
  };

  const editImage = (file) => {
    if (window.confirm("프로필 사진을 변경하시겠습니까?")) {
      fbevent("B2B_PartnerInfo_ProfileImage_Click", {
        email : GetOne("email")
      });

      const frm = new FormData();
      const token = GetOne("token");

      frm.append("mode", "save");
      frm.append("token", token);
      frm.append("profile", file);

      axios
        .post("https://ai.semos.kr/semos_partner/v3/profile/MyInfo", frm, {
          headers: { "Content-Type": "multipart/form-data" },
        })
        .then((res) => {
          const value = res.data;

          if (value.status === "success") {
            window.alert(value.status_message);
            window.location.reload();
          } else {
            window.alert(value.status_message);
          }
        });
    }
  };

  const handleDownload = async (REF, NAME, TITLE, URL) => {
    if (!REF.current) return;

    setLoading(true);

    try {
      const div = REF.current;
      const canvas = await html2canvas(div, { scale: 2 });
      canvas.toBlob(async (blob) => {
        if (blob !== null) {
          if(navigator.share) {
            const Files = [
              new File([blob], `${NAME}.jpg`, { type: 'image/jpeg' }),
            ]

            const ShareData = {
              title: `${TITLE}_QR_Code`,
              text: `[${TITLE}] ${NAME}`,
              url: URL,
              files: Files,
            }

            await navigator.share(ShareData);

            setLoading(false);
  
          } else {
            saveAs(blob, `${NAME}`);

            setLoading(false);
          }
        } else {
          setLoading(false);
        }
      });
    } catch (error) {
      setLoading(false);
      console.error("Error converting div to image:", error);
      window.alert(error)
    }
  };

  return (
    <Container>

          <Header title="내 정보 관리" back />
          <WrapperColumn
            width="100%"
            padding="32px 0 0 0"
            jc="center"
            ai="center"
          >
            <Wrapper
              border={`1px solid ${colors.Gr02}`}
              radius="50%"
              width="160px"
              height="160px"
              margin=" 0 0 16px 0"
              cursor="pointer"
            >
              <Label width="100%" height="100%" htmlFor="profile">
                <Icon
                  width="100%"
                  height="100%"
                  radius="50%"
                  src={info.profile}
                  alt="파트너 이미지 자리"
                />

                <AbsoluteWrapper
                  bgc={colors.Gr02}
                  width="38px"
                  height="38px"
                  radius="50%"
                  bottom="0"
                  right="0"
                  jc="center"
                >
                  <ProfileEditIcon
                    fill={colors.White}
                    width="24px"
                    height="24px"
                  />
                </AbsoluteWrapper>

                <input
                  type="file"
                  id="profile"
                  accept="image/*"
                  onChange={({ target: { files } }) => {
                    if (files.length) {
                      setImageFromFile({
                        file: files[0],
                        setImageUrl: ({ result }) => {
                          setCropImageSetting({
                            image: result,
                            width: 5,
                            height: 5,
                            type: "profile",
                          });
                          setCrop(true);
                        },
                      });
                    }
                  }}
                />
              </Label>
            </Wrapper>

            <Wrapper width="100%" jc="center">
              <Text size="18px" weight="700" color={colors.Gr06}>
                {info.name} 파트너님
              </Text>
            </Wrapper>

            <Wrapper width="100%" jc="center" margin="8px 0 0 0">
              <Text size="12px" weight="600" color={colors.Gr03}>
                {info.login} 로그인
              </Text>
            </Wrapper>

            <WrapperColumn width="100%" padding="12px 24px" gap="16px">
              <WrapperColumn
                width="100%"
                border={`1px solid ${colors.Gr02}`}
                padding="16px"
                radius="8px"
                cursor="pointer"
                onClick={() => navigate("/profile")}
                //onClick={() => navigate("/profile_revise")}
              >
                <Wrapper width="100%" jc="space-between" margin="0 0 4px 0">
                  <Text size="16px" weight="600" color={colors.Gr05}>
                    파트너 프로필
                  </Text>
                  {!info.profile_status && (
                    <Text size="14px" weight="500" color={colors.Red03}>
                      프로필 정보를 입력해주세요!
                    </Text>
                  )}
                </Wrapper>

                <Wrapper width="100%" jc="space-between">
                  <Text size="12px" weight="500" color={colors.Gr03}>
                    링크 전송을 통해 파트너님을 홍보할 수 있어요.
                  </Text>
                </Wrapper>
              </WrapperColumn>

              {/*<WrapperColumn
                width="100%"
                border={`1px solid ${colors.Gr02}`}
                padding="16px"
                radius="8px"
                cursor="pointer"
                onClick={() => navigate("/subscribe_manage")}
              >
                <Wrapper width="100%" jc="space-between" margin="0 0 4px 0">
                  <Text size="16px" weight="600" color={colors.Gr05}>
                    구독 관리
                  </Text>
                </Wrapper>

                <Wrapper width="100%" jc="space-between">
                  <Text size="12px" weight="500" color={colors.Gr03}>
                    구독 정보를 확인하고 관리해요
                  </Text>
                </Wrapper>
              </WrapperColumn>*/}

              <WrapperColumn
                width="100%"
                border={`1px solid ${colors.Gr02}`}
                padding="16px"
                radius="8px"
                cursor="pointer"
                onClick={() => navigate("/account_info_revise")}
              >
                <Wrapper width="100%" jc="space-between" margin="0 0 4px 0">
                  <Text size="16px" weight="600" color={colors.Gr05}>
                    정산 관리
                  </Text>
                  {!info.account_status && (
                    <Text size="14px" weight="500" color={colors.Red03}>
                      정산에 필요한 정보를 알려주세요!
                    </Text>
                  )}
                </Wrapper>

                <Wrapper width="100%" jc="space-between">
                  <Text size="12px" weight="500" color={colors.Gr03}>
                    정산에 필요한 정보를 관리해요
                  </Text>
                </Wrapper>
              </WrapperColumn>

              {
                info.facility && (
                  <>
                    <WrapperColumn
                      width="100%"
                      border={`1px solid ${colors.Gr02}`}
                      padding="16px"
                      radius="8px"
                      cursor="pointer"
                      onClick={() => navigate("/facility_entrance")}
                    >
                      <Wrapper width="100%" jc="space-between">
                        <Text size="16px" weight="600" color={colors.Gr05}>
                          시설 입장 확인
                        </Text>
                      </Wrapper>

                      <Wrapper width="100%" jc="space-between">
                        <Text size="12px" weight="500" color={colors.Gr03}>
                          현장에서 전화번호 하나로 바로 입장/예약할 수 있어요!
                        </Text>
                      </Wrapper>
                    </WrapperColumn>

                    <WrapperColumn
                      width="100%"
                      border={`1px solid ${colors.Gr02}`}
                      padding="16px"
                      radius="8px"
                      cursor="pointer"
                    >
                      <Wrapper width="100%" jc="space-between">
                        <Text size="16px" weight="600" color={colors.Gr05}>
                          QR 코드
                        </Text>
                      </Wrapper>

                      <Wrapper width="100%" jc="space-between">
                        <Text size="12px" weight="500" color={colors.Gr03}>
                          QR 코드를 클릭해서 다운로드해보세요!
                        </Text>
                      </Wrapper>

                      <Wrapper 
                        style={{
                          overflowX : "auto",
                          overflowY : "hidden"
                        }}
                        width="100%" 
                        jc="space-between" 
                        margin={`10px 0 0 0`}
                        display="inline-block"
                      >
                        <Wrapper 
                          margin={`0 10px 0 0`}
                          ai={`center`}
                          style={{
                            flexDirection : "column",
                          }}
                          ref={FacilityQR}
                          onClick={() => handleDownload(FacilityQR, `${info?.name}_시설예약현황_QR_Code`, `${info?.name}`, `https://partner.semos.kr/facility_reservation_u/${info?.center_id}`)}
                        >
                          <Wrapper
                            width="90px"
                            height="90px"
                            border={`1px solid ${colors.Gr02}`}
                            radius={`8px`}
                            padding={`5px`}
                            margin={`0 0 5px 0`}
                          >
                            <QRCode
                              size={80}
                              style={{ height: "auto", maxWidth: "100%", width: "100%" }}
                              value={`https://partner.semos.kr/facility_reservation_u/${info?.center_id}`}
                              viewBox={`0 0 256 256`}
                            />
                          </Wrapper>

                          <Text size="16px" weight="600" color={colors.Gr05}>
                            시설예약현황
                          </Text>
                        </Wrapper>

                        <Wrapper 
                          margin={`0 10px 0 0`}
                          ai={`center`}
                          style={{
                            flexDirection : "column",
                          }}
                          ref={TicketQR}
                          onClick={() => handleDownload(TicketQR, `${info?.name}_이용권_선물_QR_Code`, `${info?.name}`, `https://partner.semos.kr/present/${info?.center_id}`)}
                        >
                          <Wrapper
                            width="90px"
                            height="90px"
                            border={`1px solid ${colors.Gr02}`}
                            radius={`8px`}
                            padding={`5px`}
                            margin={`0 0 5px 0`}
                          >
                            <QRCode
                              size={80}
                              style={{ height: "auto", maxWidth: "100%", width: "100%" }}
                              value={`https://partner.semos.kr/present/${info?.center_id}`}
                              viewBox={`0 0 256 256`}
                            />
                          </Wrapper>

                          <Text size="16px" weight="600" color={colors.Gr05}>
                            이용권 선물
                          </Text>
                        </Wrapper>

                      </Wrapper>
                    </WrapperColumn>
                  </>
                )
              }

              {/*info.profile_status && (
                <WrapperColumn
                  width="100%"
                  border={`1px solid ${colors.Gr02}`}
                  padding="16px"
                  radius="8px"
                  cursor="pointer"
                  onClick={() => navigate("/profile")}
                >
                  <Wrapper width="100%" jc="space-between" margin="0 0 4px 0">
                    <Text size="16px" weight="600" color={colors.Gr05}>
                      내 프로필 공유하기
                    </Text>
                  </Wrapper>

                  <Wrapper width="100%" jc="space-between">
                    <Text size="12px" weight="500" color={colors.Gr03}>
                      크루님들에게 보여지는 프로필을 공유할 수 있어요
                    </Text>
                  </Wrapper>
                </WrapperColumn>
              )*/}

              {/*<WrapperColumn
                width="100%"
                border={`1px solid ${colors.Gr02}`}
                padding="16px"
                radius="8px"
                cursor="pointer"
                onClick={() => navigate("/center_faq")}
              >
                <Wrapper width="100%" jc="space-between" margin="0 0 4px 0">
                  <Text size="16px" weight="600" color={colors.Gr05}>
                    자주 묻는 질문
                  </Text>
                </Wrapper>

                <Wrapper width="100%" jc="space-between">
                  <Text size="12px" weight="500" color={colors.Gr03}>
                    크루에게 답변할 질문을 적어주세요!
                  </Text>
                </Wrapper>
              </WrapperColumn>*/}

              {/*<WrapperColumn
                width="100%"
                border={`1px solid ${colors.Gr02}`}
                padding="16px"
                radius="8px"
                cursor="pointer"
                onClick={() => setSemosReview(true)}
              >
                <Wrapper width="100%" jc="space-between">
                  <Text size="16px" weight="600" color={colors.Gr05}>
                    세모스가 개선될 수 있도록 도와주세요!
                  </Text>
                </Wrapper>
              </WrapperColumn>*/}

            </WrapperColumn>

            <Wrapper
              width="100%"
              padding="12px 24px"
              wrap={`wrap`}
              bt={`1px solid ${colors.Gr01}`}
            >
              <Text width={`100%`} margin={`0 0 20px 0`} size="16px" weight="600" color={colors.Gr05}>
                {`리포츠 주식회사`}
              </Text>
              <Text width={`100%`} margin={`0 0 5px 0`} size="14px" weight="500" color={colors.Gr05}>
                {`CEO : 변민지  |  개인정보책임자 : 허재혁`}
              </Text>
              <Text width={`100%`} margin={`0 0 5px 0`} size="14px" weight="500" color={colors.Gr05}>
                {`사업자등록번호 : 206-88-01662`}
              </Text>
              <Text width={`100%`} margin={`0 0 5px 0`} size="14px" weight="500" color={colors.Gr05}>
                {`통신판매업신고번호 : 2022-인천연수구-0253`}
              </Text>
              <Text width={`100%`} margin={`0 0 5px 0`} size="14px" weight="500" color={colors.Gr05}>
                {`본사 : 인천 연수구 송도문화로 119, B1041-5,6`}
              </Text>
              <Text width={`100%`} margin={`0 0 5px 0`} size="14px" weight="500" color={colors.Gr05}>
                {`사업장 : 서울 강남구 테헤란로 501 502호`}
              </Text>
              <Text width={`100%`} margin={`0 0 15px 0`} size="14px" weight="500" color={colors.Gr05}>
                {`전화번호 : 070-5151-2547 | 이메일 : help@semos.kr`}
              </Text>
              <Text width={`100%`} margin={`0 0 5px 0`} size="16px" weight="600" color={colors.Bl07}>
                {`Copyright © 리포츠 (주).  All rights reserved.`}
              </Text>
              <Text width={`100%`} margin={`0 0 15px 0`} size="12px" weight="500" color={colors.Gr05}>
                {`리포츠는 통신판매 중개자로서 통신판매의 당사자가 아니며 상품의 예약, 이용 및 환불 등과 관련한 의무와 책임은 각 판매자에게 있습니다.`}
              </Text>
            </Wrapper>

          </WrapperColumn>
          
          <CropImage
            open={crop}
            setOpen={setCrop}
            setting={cropImageSetting}
            setSetting={setCropImageSetting}
            setImageFunc={editImage}
          />
        
          {!loading ? (
            <></>
          ) : (
            <LoadingSpinner
              loading={loading}
              title={`정보를 확인하고 있습니다.`}
              content={`잠시만 기다려 주세요!`}
            />
          )}

      <Modal modal={semosReview} setModal={setSemosReview} />
    </Container>
  );
};

export default MyInfo;

const Container = styled.section`
  height: calc(var(--vh, 1vh) * 100);
  overflow-x: hidden;
`;
