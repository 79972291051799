import React, { useRef, useState, useEffect } from "react";
import styled from "styled-components";

//img
import FaqQ from "../../icon/faqQ.png";
import FaqA from "../../icon/faqA.png";
import GreyPlus from "../../icon/grayPlus.png";
import BluePlus from "../../icon/bluePlus.png";
import Pencil from "../../icon/pencilIcon.png";
import PartnerProfileMinus from "../../icon/partnerProfileMinus.svg";
import { W } from "../../styles/globalStyles";
import colors from "../../styles/colors";
import { Wrapper, Text } from "../layouts/layoutStorage";

const MAX_KEYWORD_QUESTION_LENGTH = 300;
const MAX_KEYWORD_ANSWER_LENGTH = 60;

const MAX_KEYWORD_ADD_LENGTH = 5; // 추가대상/ 자격 요건 / 유의사항 / 준비물/포함사항/ 불포함사항 / 자주 묻는 질문 - 추가 리스트

function ActivityIntroduceFaqItem({ el, faq, setData, deleteD, setDelete, index, bigData, textRecommend }) {
  const ref = useRef();

  const [faqContent, setFaqContent] = useState({
    id: el.id,
    q: el.q,
    a: el.a,
  });

  const [open, setOpen] = useState(false);
  const [focus, setFocus] = useState(false);

  const curriculumContentSet = (text) => {
    ref.current.style.height = "auto";
    ref.current.style.height = ref.current.scrollHeight + "px";
    setFaqContent((prev) => ({ ...prev, a: text.substring(0, MAX_KEYWORD_ANSWER_LENGTH) }));
  };

  const settingKeyWords = () => {
    setOpen(false);
    setFaqContent((prev) => ({ ...prev, q: faqContent.q }));
  };

  const settingKeyWordsRecommend = (value) => {
    setOpen(false);
    setFaqContent((prev) => ({ ...prev, q: value }));
  };

  useEffect(() => {
    curriculumContentSet(faqContent.a);
  }, []);

  const deleteItem = () => {
    var copy = [...faq];

    var deleteData = copy[index];
    var deleted = [...deleteD.faq];
    deleted.push(deleteData);

    copy.splice(index, 1);

    setDelete((prev) => ({ ...prev, faq: deleted }));
    setData((prev) => ({ ...prev, faq: copy }));
  };

  useEffect(() => {
    if (!open) {
      var copy = [...faq];
      var newArr = [];
      for (let i = 0; i < copy.length; i++) {
        if (i === index) {
          newArr.push({ ...faqContent, change: "yes" });
        } else {
          newArr.push(copy[i]);
        }
      }

      setData((prev) => ({ ...prev, faq: newArr }));
    }
  }, [faqContent, open]);

  const SearchKeyword = (value, category) => {
    let text = value.substring(0, MAX_KEYWORD_QUESTION_LENGTH);
    setFaqContent((prev) => ({ ...prev, q: text }));

    if (text.length > 1) {
      bigData(category, text);
    }
  };

  return (
    <Container>
      <FaqLine>
        <FaqIcon src={FaqQ} />
        <TagInputDiv>
          <Sharp value={faqContent.q}>#</Sharp>
          <TagInput
            placeholder="태그로 검색해 보세요"
            value={faqContent.q}
            onChange={(e) => SearchKeyword(e.target.value, "faq")}
            onFocus={() => setOpen(true)}
            onBlur={() =>
              setTimeout(() => {
                setOpen(false);
              }, 200)
            }
          />
          <PencilIcon src={Pencil} />
        </TagInputDiv>
      </FaqLine>
      {faqContent.q && open && (
        <Styler>
          <SearchBox>
            <SearchList>
              {textRecommend.length > 0 &&
                textRecommend.map((el, idx) => (
                  <SearchItem onClick={() => settingKeyWordsRecommend(el)} key={`${idx}_faq_search`}>
                    <SearchText>{el}</SearchText>
                    <PlusIcon src={GreyPlus} />
                  </SearchItem>
                ))}
            </SearchList>
            <AddSearchItem onClick={() => settingKeyWords()}>
              <AddSearchText>{faqContent.q} 항목 추가하기</AddSearchText>
              <PlusIcon src={BluePlus} />
            </AddSearchItem>
          </SearchBox>
        </Styler>
      )}
      <FaqLine noAlign={true}>
        <FaqIcon src={FaqA} />
        <CurriculumContent focus={focus}>
          <CurriculumContentInput
            onFocus={() => setFocus(true)}
            onBlur={() => setFocus(false)}
            ref={ref}
            rows="1"
            placeholder="질문에 대한 답변을 입력해주세요"
            value={faqContent.a}
            onChange={(e) => curriculumContentSet(e.target.value)}
          />
        </CurriculumContent>
        <Wrapper cursor="pointer" margin="0 0 0 8px" radius="8px" bgc={colors.Red03} onClick={() => deleteItem()} height="38px" padding="0 12px">
          <Text size="14px" weight="600" lh="22px" color={colors.White}>
            제거
          </Text>
        </Wrapper>
        {/* <Button src={PartnerProfileMinus} onClick={() => deleteItem()} /> */}
      </FaqLine>
    </Container>
  );
}

export default ActivityIntroduceFaqItem;

const Container = styled.div`
  width: 100%;
`;

const Styler = styled.div`
  padding-bottom: 8px;
  margin-left: 29px;
`;

const FaqLine = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: ${({ noAlign }) => !noAlign && "center"};
  /* width: ${W - 48}px; */
  width: 100%;
  margin-bottom: 8px;
`;

const CurriculumTitle = styled.div`
  padding: 9px 12px;
  border: 1px solid ${colors.Gr02};
  border-radius: 8px;
  width: 100%;
  font-weight: 500;
  font-size: 14px;
  line-height: 142%;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: -0.02em;
  color: ${colors.Gr05};
  height: 40.45px;
`;

const CurriculumContentInput = styled.textarea`
  outline: none;
  resize: none;
  border: none;
  width: 100%;
  height: "auto";
  font-weight: 500;
  font-size: 14px;
  line-height: 142%;
  /* identical to box height, or 20px */
  cursor: text;
  letter-spacing: -0.02em;

  /* Greyscale/Gr 03 */

  color: ${colors.Gr05};

  ::placeholder {
    color: ${colors.Gr03};
  }
`;

const CurriculumContent = styled.div`
  padding: 9px 12px;
  border: ${({ focus }) => (focus ? `1px solid ${colors.Bl07}` : `1px solid ${colors.Gr02}`)};
  border-radius: 8px;
  width: calc(100% - 78px);
  font-weight: 500;
  font-size: 14px;
  line-height: 142%;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: -0.02em;
  color: ${colors.Gr05};
  /* height: 40.45px; */
`;

const Button = styled.img`
  width: 38px;
  height: 38px;
  margin-left: 8px;
  cursor: pointer;
`;

const FaqIcon = styled.img`
  width: 25px;
  height: 38px;
  margin-right: 4px;
`;

const TagInputDiv = styled.div`
  display: flex;
  align-items: center;
  padding: 9px 9px 9px 18px;
  gap: 4px;
  background-color: ${colors.Gr01};
  border: 1px solid ${colors.Gr02};
  border-radius: 6px;
  width: calc(100% - 29px);
  letter-spacing: -0.02em;
  color: ${colors.Gr05};
  font-weight: 500;
  font-size: 14px;
  line-height: 142%;
`;

const TagInput = styled.input`
  width: 100%;
  font-weight: 500;
  font-size: 14px;
  line-height: 142%;
  display: flex;
  align-items: center;
  letter-spacing: -0.02em;
  color: ${colors.Gr05};
  cursor: text;

  &::placeholder {
    color: ${colors.Gr02};
  }
`;

const Sharp = styled.span`
  color: ${({ value }) => (value ? colors.Gr05 : colors.Gr02)};
`;

const SearchBox = styled.div`
  border: 1px solid ${colors.Gr02};
  border-radius: 6px;
`;

const SearchList = styled.div``;

const SearchItem = styled.div`
  border-bottom: 1px solid ${colors.Gr02};
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 8px 10px 18px;
  cursor: pointer;
`;

const AddSearchItem = styled(SearchItem)`
  border: none;
  background-color: ${colors.Bl01};
  border-radius: 6px;
`;

const SearchText = styled.div`
  font-weight: 500;
  font-size: 12px;
  line-height: 142%;
  display: flex;
  align-items: center;
  letter-spacing: -0.02em;
  color: ${colors.Gr05};
`;

const AddSearchText = styled(SearchText)`
  color: ${colors.Bl07};
  max-width: ${W - 127}px;
  word-break: break-all;
  text-overflow: ellipsis;
`;

const PlusIcon = styled.img`
  width: 24px;
  height: 24px;
  cursor: pointer;
`;

const PencilIcon = styled.img`
  width: 20px;
  height: 20px;
`;
