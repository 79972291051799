import React, { useState, useRef, useEffect } from "react";
import styled from "styled-components";
import axios from "axios";

// component
import { GetOne } from "../../tool/localstorage";

// style
import { W, H } from "../../styles/globalStyles";
import colors from "../../styles/colors";

// img
import { ReactComponent as CheckCircle } from "../../resource/icon/check_circle.svg";
import { ReactComponent as BangMark } from "../../resource/icon/bang_mark.svg";

const AUTH_TIMER = 180;
const INPUT_MESSAGE = {
  timeout: "인증 시간이 초과되었어요",
  invalid: "인증번호를 다시 확인해 주세요",
  duplicate: "이미 가입된 전화번호입니다.",
  resend: "인증번호 전송이 완료되었어요",
};

const PhoneNumberConfirm = ({ type, STEP_LIST, step, data, setData, setNext, valid, setValid, setVerifyToken, confirmStatus, setConfirmStatus }) => {
  const [timer, setTimer] = useState(AUTH_TIMER);
  const [focused, setFocused] = useState(false);

  const min = `0${Math.floor(timer / 60)}`;
  const sec = timer % 60 < 10 ? "0" + (timer % 60) : timer % 60;

  // 인증번호 전송
  useEffect(() => {
    sendVerify();
  }, []);

  // TIMER
  useEffect(() => {
    if (timer !== 0) {
      const time = setInterval(() => {
        if (timer > 0) {
          setTimer((prev) => prev - 1);
        }
      }, 1000);

      return () => clearInterval(time);
    } else if (timer === 0) {
      setConfirmStatus("timeout");
    }
  }, [timer]);

  const sendVerify = () => {
    const token = GetOne("token");

    if (data.phoneNumber.length === 13 && Number(data.phoneNumber.replaceAll("-", "").substr(3, 13))) {
      const frm = new FormData();
      frm.append("mode", "phone_number_confirm");
      frm.append("phone_number", data.phoneNumber);
      frm.append("token", token);

      axios
        .post("https://ai.semos.kr/semos_partner/v2/profile/Login", frm, {
          headers: {
            "Content-Type": "multipart/form-data",
            Accept: "application/json",
          },
        })
        .then((res) => {
          const data = res.data;
          const status = data.status;
          const statusMessage = data.status_message;
          const checkToken = data.check_t;

          if (status === "success") {
            setVerifyToken(checkToken);
          } else {
            alert(statusMessage);
          }
        })
        .catch((err) => console.log(err));
    } else {
      alert(`전화번호를 다시 한번 확인해주세요.`);
    }
  };

  // 인증번호 재전송
  const resendVerify = () => {
    setData({ ...data, [type]: "" });
    setConfirmStatus("resend");
    setTimer(AUTH_TIMER);
    sendVerify();
  };

  // 인증번호 입력
  const handleChange = (target) => {
    let text = target.substr(0, 6);
    // console.log(text);
    if (text.length === 6 && !isNaN(Number(text))) {
      setValid({ ...valid, [type]: true });
      setNext(true);
    } else {
      setValid({ ...valid, [type]: false });
      setNext(false);
    }
    setData({ ...data, [type]: text });
  };

  return (
    <RegisterContent>
      <label htmlFor={`${STEP_LIST[type].label}`}>{`${STEP_LIST[type].label}`}</label>
      <InputContainer>
        <Input
          id={`${STEP_LIST[type].label}`}
          type={"tel"}
          placeholder="인증번호를 입력해 주세요"
          disabled={step.name !== type}
          isValid={true}
          isFocused={focused}
          status={confirmStatus}
          onFocus={() => setFocused(true)}
          onBlur={() => setFocused(false)}
          value={data[type]}
          onChange={(e) => handleChange(e.target.value)}
        />
        <InputIconContainer>
          <TimerText status={confirmStatus}>
            {min}:{sec}
          </TimerText>
        </InputIconContainer>
      </InputContainer>
      <SubContainer>
        <SubText status={confirmStatus}>
          {confirmStatus === "timeout" || confirmStatus === "invalid" ? <BangMark fill={colors.Red03} /> : <></>}
          {confirmStatus === "duplicate" ? <BangMark fill={colors.Red03} /> : <></>}
          {confirmStatus === "resend" ? <CheckCircle fill={colors.Bl07} /> : <></>}
          <span>{INPUT_MESSAGE[confirmStatus]}</span>
        </SubText>
        <ButtonContainer>
          <ResendButton onClick={() => resendVerify()}>인증번호 재전송</ResendButton>
        </ButtonContainer>
      </SubContainer>
    </RegisterContent>
  );
};

export default PhoneNumberConfirm;

const RegisterContent = styled.div`
  background-color: ${colors.White};
  padding: 24px 0px 8px 0px;

  label {
    display: inline-block;
    color: ${colors.Gr03};
    line-height: 142%;
    letter-spacing: -0.28px;
    font-size: 14px;
    font-weight: 500;
  }
`;

const InputContainer = styled.div`
  position: relative;
  padding: 8px 0px;

  svg {
    width: 24px;
    height: 24px;
  }
`;

const Input = styled.input`
  width: 100%;
  padding: 14px 56px 14px 16px;
  background-color: ${colors.White};
  border: 1px solid
    ${({ isValid, isFocused, status }) => (isFocused ? `${colors.Bl07}` : status === "timeout" || status === "invalid" ? `${colors.Red03}` : `${colors.Gr02}`)};
  border-radius: 8px;
`;

const InputIconContainer = styled.div`
  position: absolute;
  top: 50%;
  right: 16px;
  z-index: 11;
  transform: translateY(-50%);

  svg {
    width: 24px;
    height: 24px;
  }
`;

const TimerText = styled.span`
  color: ${({ status }) => (status === "timeout" ? colors.Red03 : colors.Bl07)};
  line-height: 142%;
  letter-spacing: -0.28px;
  font-size: 14px;
  font-weight: 500;
`;

const SubContainer = styled.div`
  display: flex;
  align-items: center;
  height: 20px;
`;

const SubText = styled.div`
  flex-grow: 1;
  display: flex;
  align-items: center;
  gap: 6px;
  width: 100%;

  span {
    display: inline-block;
    width: 100%;
    color: ${({ status }) => (status === "timeout" || status === "invalid" || status === "duplicate" ? colors.Red03 : colors.Bl07)};
    line-height: 20px;
    letter-spacing: -0.24px;
    font-size: 12px;
    font-weight: 500;
  }

  svg {
    width: 16px;
    height: 16px;
  }
`;

const ButtonContainer = styled.div`
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

const ResendButton = styled.button`
  width: 100%;
  background-color: transparent;
  color: ${colors.Gr04};
  text-decoration-line: underline;
  line-height: 142%;
  letter-spacing: -0.24px;
  font-size: 12px;
  font-weight: 500;
  white-space: nowrap;
`;
