import React, { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import Modal from "react-modal";
import axios from "axios";

//component 
import { GetOne } from "../../tool/localstorage";

// style
import colors from "../../styles/colors";
import { Wrapper, Input, Text, AbsoluteWrapper } from "../../components/layouts/layoutStorage";

// img
import { ReactComponent as Arrow } from "../../resource/icon/arrow.svg";

// default 
const MaxCapacityList = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 20];

const ClassManageModal = ({
    modal,
    setModal,
    title,
    data,
    setLoading,
    GetData
}) => {

    const MaxRef = useRef();
    const UserRef = useRef();

    const [startTime, setStartTime] = useState();
    const [endTime, setEndTime] = useState();
    const [status, setStatus] = useState(true);
    const [maxCapacity, setMaxCapacity] = useState(4);

    const [dropdown, setDropdown] = useState(false);
    const [dropdownU, setDropdownU] = useState(false);
    
    const maxCapacityHandleClose = (e) => {
        if (dropdown && (!MaxRef.current || !MaxRef.current.contains(e.target))) {
            setDropdown(false);
        }
    };

    const UserHandleClose = (e) => {
        if (dropdownU && (!UserRef.current || !UserRef.current.contains(e.target))) {
            setDropdownU(false);
        }
    };

    const ChangeSchedule = () => {
        setLoading(true);

        const Year = data?.year;
        const Month = data?.month;
        const Day = data?.day;

        var token = GetOne("token");

        axios
        .post("https://ai.semos.kr/semos_partner/v3/activity/Crew", {
            mode: "change_class_schedule",
            token: token,
            status: status,
            max_capacity: maxCapacity,
            class_id: data.classId,
            date : `${Year}.${Month}`,
            start : startTime,
            end : endTime
        }, {
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
            },
        })
        .then((data) => {
            var Value = data.data;
            var Status = Value.status;
            var StatusMessage = Value.status_message;

            if (Status == "success") {
                window.alert(StatusMessage);
                setLoading(false);
                GetData();
            } else {
                window.alert(StatusMessage);
                setLoading(false);
            }
        });
    }

    useEffect(() => {
        const Year = data?.year;
        const Month = data?.month;
        const Day = data?.day;

        const SelectDate = new Date(`${Year}-${Month}-${Day} ${data?.time}`);
        SelectDate.setHours(SelectDate.getHours() + 1);

        const Hour = SelectDate.getHours() > 9 ? SelectDate.getHours() : `0${SelectDate.getHours()}`;
        const Minunte = SelectDate.getMinutes() > 9 ? SelectDate.getMinutes()  : `0${SelectDate.getMinutes()}`;

        setStartTime(data?.time);
        setEndTime(`${Hour}:${Minunte}`);
        setStatus(data?.off);
        setMaxCapacity(data?.capacity);
    }, [data]);

    useEffect(() => {
        window.addEventListener("click", maxCapacityHandleClose);
    
        return () => {
            window.removeEventListener("click", maxCapacityHandleClose);
        };
    }, [dropdown]);

    useEffect(() => {
        window.addEventListener("click", UserHandleClose);
    
        return () => {
            window.removeEventListener("click", UserHandleClose);
        };
    }, [dropdownU]);

    return (
        <Modal
            isOpen={modal}
            onRequestClose={() => setModal(false)}
            contentLabel="Alert Modal"
            style={customStyles}
        >
            <Container>

                <AffiliateModalLayout>
                    <Wrapper
                        width={`100%`}
                        margin={`0 0 16px 0`}
                        jc={`center`}
                    >   
                        <Text 
                            width={`100%`} 
                            size="2rem" 
                            weight="700" 
                            lh="24px" 
                            ls="-0.32px" 
                            color={colors.Gr05}
                            ta={`center`}
                        >
                            {title}
                        </Text>
                    </Wrapper>      

                    <Wrapper
                        width={`100%`}
                        margin={`0 0 8px 0`}
                        jc={`space-between`}
                    >   
                        <Text 
                            size="1.8rem" 
                            weight="600" 
                            lh="24px" 
                            ls="-0.32px" 
                            color={colors.Gr05}
                            style={{
                                marginRight : 8
                            }}
                        >
                            {`${data?.year}년 ${data?.month}월 ${startTime}`}
                        </Text>

                    </Wrapper>   

                    <Wrapper
                        width={`100%`}
                        margin={`0 0 8px 0`}
                        jc={`space-between`}
                        ai={`center`}
                    >   
                        <Text 
                            size="1.8rem" 
                            weight="600" 
                            lh="24px" 
                            ls="-0.32px" 
                            color={colors.Gr05}
                            style={{
                                marginRight : 8
                            }}
                        >
                            {`예약 상태 :`}
                        </Text>

                        <Wrapper
                            margin={`0 0 0 3px`}
                            jc={`space-between`}
                            ai={`center`}
                        >   
                            <Wrapper
                                margin={`0 0 0 0`}
                                jc={`center`}
                                ai={`center`}
                                padding={`3px 5px`}
                                radius={`8px`}
                                border={`2px solid ${status ? colors.Bl07 : colors.Gr03}`}
                                onClick={() => setStatus(true)}
                                cursor={`pointer`}
                            >   
                                <Text 
                                    size="1.4rem" 
                                    weight="600" 
                                    lh="24px" 
                                    ls="-0.32px" 
                                    color={`${status ? colors.Bl07 : colors.Gr03}`}
                                >
                                    {`가능`}
                                </Text>
                            </Wrapper>  

                            <Wrapper
                                margin={`0 0 0 5px`}
                                jc={`space-between`}
                                ai={`center`}
                                padding={`3px 5px`}
                                radius={`8px`}
                                border={`2px solid ${!status ? colors.Red03 : colors.Gr03}`}
                                onClick={() => setStatus(false)}
                                cursor={`pointer`}
                            >   
                                <Text 
                                    size="1.4rem" 
                                    weight="600" 
                                    lh="24px" 
                                    ls="-0.32px" 
                                    color={`${!status ? colors.Red03 : colors.Gr03}`}
                                >
                                    {`불가능`}
                                </Text>
                            </Wrapper>  
                        </Wrapper>  
                    </Wrapper>   

                    <Wrapper
                        width={`100%`}
                        margin={`0 0 8px 0`}
                        jc={`space-between`}
                        ai={`center`}
                    >   
                        <Text 
                            size="1.8rem" 
                            weight="600" 
                            lh="24px" 
                            ls="-0.32px" 
                            color={colors.Gr05}
                            style={{
                                marginRight : 8
                            }}
                        >
                            {`최대 인원 :`}
                        </Text>

                        <Wrapper
                            ref={MaxRef}
                            padding="8px"
                            cursor="pointer"
                            position="relative"
                            onClick={(e) => {
                                e.stopPropagation();
                                setDropdown(!dropdown);
                            }}
                            height="48px"
                            radius="8px"
                            width="80px"
                            bgc={colors.White}
                            border={`1px solid ${colors.Gr02}`}
                            jc="space-between"
                        >
                                <Text size="14px" weight="500" lh="22px" ls="-0.28px" color={colors.Gr05}>
                                    {maxCapacity}인
                                </Text>

                                <Arrow
                                    width="16px"
                                    height="16px"
                                    fill={colors.Gr04}
                                    style={{ 
                                        transition: "all ease 0.5s", 
                                        transform: dropdown ? "rotate(-90deg)" : "rotate(90deg)" 
                                    }}
                                />

                                {dropdown && (
                                    <AbsoluteWrapper
                                        maxHeight="10rem"
                                        z={1}
                                        fd="column"
                                        width="100%"
                                        top="61px"
                                        left="0"
                                        bgc={colors.White}
                                        border={`1px solid ${colors.Gr02}`}
                                        radius="8px"
                                        of="auto"
                                    >
                                        {MaxCapacityList.map((x, idx) => (
                                            <DropDown
                                                active={x == maxCapacity}
                                                key={`${idx}_capacity`}
                                                last={idx + 1 === MaxCapacityList.length}
                                                onClick={() => {
                                                    setMaxCapacity(x);
                                                }}
                                            >
                                                {x}
                                            </DropDown>
                                        ))}
                                    </AbsoluteWrapper>
                                )}
                        </Wrapper>
                    </Wrapper>  

                    <Wrapper
                        onClick={() => ChangeSchedule()}
                        jc="center"
                        ai="center"
                        width="calc(100%)"
                        height="40px"
                        cursor={"pointer"}
                        radius="8px"
                        bgc={colors.Bl07}
                        margin={`15px 0 0 0`}
                    >
                        <Text 
                            ta="center" 
                            size="2rem" 
                            weight="600" 
                            lh="22px" 
                            ls="-0.28px" 
                            color={colors.White}
                        >
                            {`저장`}
                        </Text>
                    </Wrapper>


                </AffiliateModalLayout>
            </Container>

        </Modal>
    );
};

export default ClassManageModal;

const customStyles = {
    content: {
        width: "calc(100% - 24px)",
        maxWidth : "600px",
        marginRight: "-50%",
        padding: 0,
        borderRadius: "16px",
        top: "50%",
        left: "50%",
        right: "auto",
        bottom: "auto",
        transform: "translate(-50%, -50%)",
    },
};

const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`;

const AffiliateModalLayout = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    max-height: 600px;
    min-height: 300px;
    max-width: 600px;
    padding: 12px 10px;
    border-radius: 0;
`;

const DropDown = styled.div`
    width: 100%;
    padding: 3px 5px;
    border-bottom: ${({ last }) => !last && `1px solid ${colors.Gr02}`};
    color: ${({ active }) => active ? colors.Bl07 : colors.Gr04 };
    font-size: 1.4rem;
    font-weight: ${({ active }) => active ? 600 : 500};
    line-height: 142%;
    letter-spacing: -0.26px;
    text-align : center;
    cursor : pointer;
`;

const NewCrew = styled.div`
    z-index: 100;
    cursor : pointer;
    border : .2rem solid ${colors.Bl07};
    width : 2.5rem;
    height : 2.5rem;
    border-radius: 50%;
    display: inline-flex;
    align-items: center;
    justify-content: center;

    img {
        width : 2.2rem;
        display: inline-block;
    }
`;