import React, { useEffect, useState } from "react";
import styled from "styled-components";
import axios from "axios";

import { GetOne } from "../../tool/localstorage";
import { MAX_ID_NUMBER_LENGTH, MAX_REGISTER_NUMBER_LENGTH } from "../../data/config";

import colors from "../../styles/colors";

const SPECIAL_CHARACTERS = /[0-9~!@#$%^&*()_+={}[\]|\\:;"'<>,.?/`-]/g;

const AccountReviseInput = ({ STEP_LIST, type, data, setData, valid, setValid }) => {
  const [focused, setFocused] = useState(false);
  const [duplicated, setDuplicated] = useState(false);
  const [displayIdNumber, setDisplayIdNumber] = useState("");

  useEffect(() => {
    switch (type) {
      case "idNumber":
        if (data[type].length === MAX_ID_NUMBER_LENGTH) {
          const encode = data[type]
            .split("")
            .map((val, idx) => (idx > 7 ? "*" : val))
            .join("");
          setDisplayIdNumber(encode);
          setValid({ ...valid, [type]: true });
        } else {
          setValid({ ...valid, [type]: false });
        }
        break;
      case "registerNumber":
        if (data[type].length === MAX_REGISTER_NUMBER_LENGTH) {
          setValid({ ...valid, [type]: true });
        } else {
          setValid({ ...valid, [type]: false });
        }
        break;
      default:
        if (data[type]) {
          setValid({ ...valid, [type]: true });
        } else {
          setValid({ ...valid, [type]: false });
        }
        break;
    }
  }, []);

  const handleChange = (target, currentTarget) => {
    let text = target;

    switch (type) {
      case "name":
        text = target.replace(SPECIAL_CHARACTERS, "");
        break;
      case "idNumber":
        // 주민등록번호 DATA
        if (currentTarget !== " ") {
          // currentTarget !== " " : 띄어쓰기 방지
          const idArr = [];
          const idNum = data[type].replaceAll("-", "").replaceAll(" ", "");

          const currentNum =
            currentTarget === null
              ? `${idNum.substring(0, idNum.length - 1)}` // 지울 경우
              : `${idNum}${currentTarget}`;

          // 앞자리
          idArr.push(currentNum.substring(0, 6));
          // 뒷자리
          if (currentNum.length > 6) {
            const idStr = currentNum.substring(6, MAX_ID_NUMBER_LENGTH);
            idArr.push(idStr);
          }

          const idText = idArr.join("-").substring(0, MAX_ID_NUMBER_LENGTH);
          setData({ ...data, [type]: idText });
        }

        // 주민등록번호 DISPLAY
        const displayArr = [];
        const displayNum = target.replaceAll("-", "").replaceAll(" ", "");

        // 앞자리
        displayArr.push(displayNum.substr(0, 6));
        // 뒷자리
        if (displayNum.length > 6) {
          const displayStr = displayNum.substr(6, 7);
          const encode = displayStr
            .split("")
            .map((val, idx) => (idx > 0 ? "*" : val))
            .join("");
          displayArr.push(encode);
        }

        const displayText = displayArr.join("-").substring(0, MAX_ID_NUMBER_LENGTH);
        setDisplayIdNumber(displayText);

        if (displayText.length === MAX_ID_NUMBER_LENGTH) {
          setValid({ ...valid, [type]: true });
        } else {
          setValid({ ...valid, [type]: false });
        }
        return;
      case "registerNumber":
        let registerText = target;

        const registerArr = [];
        const registerNum = target.replaceAll("-", "").replaceAll(" ", "");

        registerArr.push(registerNum.substr(0, 3));
        if (registerNum.length > 3) registerArr.push(registerNum.substr(3, 2));
        if (registerNum.length > 5) registerArr.push(registerNum.substr(5, 5));

        registerText = registerArr.join("-");
        text = registerText.substr(0, MAX_REGISTER_NUMBER_LENGTH);

        if (text.length === MAX_REGISTER_NUMBER_LENGTH) {
          setValid({ ...valid, [type]: true });
        } else {
          setValid({ ...valid, [type]: false });
        }
        break;
      default:
        break;
    }

    setData({ ...data, [type]: text });
  };

  return (
    <RegisterContent>
      <label htmlFor={STEP_LIST[type].name}>{STEP_LIST[type].name}
      <span className="Description" >{STEP_LIST[type].description}</span>  
      </label>
      <InputContainer>
        <Input
          id={STEP_LIST[type].name}
          type={type === "name" ? "text" : "tel"}
          placeholder={STEP_LIST[type].placeholder}
          disabled={false}
          isValid={!duplicated}
          isFocused={focused}
          onFocus={() => setFocused(true)}
          onBlur={() => setFocused(false)}
          value={type === "idNumber" ? displayIdNumber : data[type]}
          onChange={(e) => handleChange(e.target.value, e.nativeEvent.data)}
        />
      </InputContainer>
    </RegisterContent>
  );
};

export default AccountReviseInput;

const RegisterContent = styled.div`
  background-color: ${colors.White};

  label {
    display: inline-block;
    color: ${colors.Gr05};
    line-height: 24px;
    letter-spacing: -0.32px;
    font-size: 16px;
    font-weight: 600;

    .Description {
      color: ${colors.Red03};
      font-size: 12px;
      font-weight: 500;
      line-height: 26px;
    }
  }
`;

const InputContainer = styled.div`
  position: relative;
  padding: 8px 0px;

  svg {
    width: 24px;
    height: 24px;
  }
`;

const Input = styled.input`
  width: 100%;
  height: 56px;
  padding: 14px 56px 14px 16px;
  background-color: ${colors.White};
  border: 1px solid ${({ isValid, isFocused }) => (isFocused ? `${colors.Bl07}` : isValid ? `${colors.Gr02}` : `${colors.Red03}`)};
  border-radius: 8px;
  color: ${colors.Gr06};
  line-height: 142%;
  letter-spacing: -0.28px;
  font-size: 14px;
  font-weight: 500;
`;

const InputIconContainer = styled.div`
  position: absolute;
  top: 50%;
  right: 16px;
  z-index: 11;
  transform: translateY(-50%);

  svg {
    width: 24px;
    height: 24px;
  }
`;
