import React, { useState } from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";

// style
import colors from "../../styles/colors";

// img
import SemosIcon from "../../icon/semosBlueLogo.png";
import SemosIconGrey from "../../icon/greySemosIcon.png";
import People from "../../icon/peopleIcon.png";

function SemosActivity({ data }) {
  const navigate = useNavigate();

  const [open, setOpen] = useState(false);

  if (open) {
    return (
      <ScheduleContainer>
        <Semos src={SemosIcon} />
        <ScheduleInfoContainer>
          <SemosActivityTitle>{data.activity_name}</SemosActivityTitle>
          <TimeNameContainer>
            <ScheduleTime>
              {`${data.start_time}:${data.start_min} ~ ${data.finish_time}:${data.finish_min}`}
              <DividingBar>|</DividingBar>
            </ScheduleTime>
            <ScheduleName>
              {data.schedule_name ? data.schedule_name : "등록된 스케줄 없음"}
            </ScheduleName>
          </TimeNameContainer>
          <AnimationBox className={open ? "slidedown" : "slideup"}>
            <DetailedInfoBox>
              <BoxLiner>
                <IconTitleContainer>
                  <Icon src={SemosIconGrey} />
                  <Title>
                    <Dye color={colors.Bl07}>스포츠</Dye>
                    <DividingBar>|</DividingBar>유형
                  </Title>
                </IconTitleContainer>
                <SportTypeContainer>
                  <Sport>{data.sport}</Sport>
                  <Type>
                    <DividingBar>|</DividingBar> {data.duration}
                  </Type>
                  <Type>
                    <DividingBar>|</DividingBar> {data.category}
                  </Type>
                </SportTypeContainer>
              </BoxLiner>
              <Divider h={6} />
              <BoxLiner>
                <IconTitleContainer>
                  <Icon src={People} />
                  <Title>예약 인원</Title>
                </IconTitleContainer>
                {data.people}인
              </BoxLiner>
            </DetailedInfoBox>
            <Divider h={8} />
            <FlexEnd>
              <GoDetailedScheduleText
                onClick={() =>
                  navigate(`/reservation_detail/${data.pay_number}`, {
                    state: {
                      from: "",
                    },
                  })
                }
              >
                예약 상세정보 보기
              </GoDetailedScheduleText>
            </FlexEnd>
            <FlexEnd>
              <ClosingButton onClick={() => setOpen(false)}>닫기</ClosingButton>
            </FlexEnd>
          </AnimationBox>
        </ScheduleInfoContainer>
      </ScheduleContainer>
    );
  } else {
    return (
      <ScheduleContainer
        style={{ cursor: "pointer" }}
        onClick={() => setOpen(true)}
        open={open}
      >
        <Semos src={SemosIcon} />
        <ScheduleInfoContainer>
          <SemosActivityTitle>{data.activity_name}</SemosActivityTitle>
          <TimeNameContainer>
            <ScheduleTime>
              {`${data.start_time}:${data.start_min} ~ ${data.finish_time}:${data.finish_min}`}
              <DividingBar>|</DividingBar>
            </ScheduleTime>
            <ScheduleName>
              {data.schedule_name ? data.schedule_name : "등록된 스케줄 없음"}
            </ScheduleName>
          </TimeNameContainer>
        </ScheduleInfoContainer>
      </ScheduleContainer>
    );
  }
}

export default SemosActivity;

const ScheduleContainer = styled.section`
  display: flex;
  margin-bottom: 32px;
  padding-bottom: 24px;
  border-bottom: 2px solid ${colors.Gr01};
`;

const Divider = styled.div`
  height: ${({ h }) => h}px;
`;

const Semos = styled.img`
  width: 20px;
  height: 20px;
  margin-right: 4px;
`;

const ScheduleInfoContainer = styled.div`
  display: inline-block;
  width: calc(100% - 25px);
`;

const SemosActivityTitle = styled.div`
  margin-bottom: 4px;
  color: ${colors.Bl07};
  line-height: 142%;
  font-size: 14px;
  font-weight: 600;
`;

const TimeNameContainer = styled.div`
  display: flex;
  line-height: 142%;
  letter-spacing: -0.02em;
  font-size: 12px;
  font-weight: 500;
`;

const ScheduleTime = styled.div`
  display: flex;
  align-items: center;
  color: ${colors.Gr03};
`;

const ScheduleName = styled.div`
  color: ${colors.Gr05};
`;

const DividingBar = styled.span`
  margin: 0 6px;
  font-size: 9px;
`;

const DetailedInfoBox = styled.div`
  margin-top: 16px;
  padding: 12px 20px;
  border: 1px solid ${colors.Gr02};
  border-radius: 8px;
`;

const IconTitleContainer = styled.div`
  display: flex;
  align-items: center;
  width: 38%;
`;

const Icon = styled.img`
  width: 16px;
  height: 16px;
  margin-right: 4px;
`;

const Title = styled.div`
  display: flex;
  align-items: center;
  color: ${colors.Gr04};
  line-height: 142%;
  letter-spacing: -0.02em;
  font-size: 12px;
  font-weight: 500;
`;

const GoDetailedScheduleText = styled.div`
  display: flex;
  align-items: center;
  color: ${colors.Bl07};
  line-height: 142%;
  letter-spacing: -0.02em;
  font-size: 12px;
  font-weight: 500;
  cursor: pointer;
`;

const ClosingButton = styled.div`
  width: 61px;
  margin-top: 16px;
  padding: 7.5px 20px;
  background-color: ${colors.Bl07};
  border-radius: 8px;
  color: ${colors.White};
  line-height: 142%;
  letter-spacing: -0.02em;
  font-size: 12px;
  font-weight: 600;
  cursor: pointer;
`;

const FlexEnd = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const AnimationBox = styled.div`
  @keyframes dropdown {
    0% {
      transform: translateY(-16px);
    }
    100% {
      transform: translateY(0);
    }
  }

  animation: dropdown 0.5s ease;
`;

const BoxLiner = styled.div`
  display: flex;
  align-items: center;
  color: ${colors.Gr05};
  line-height: 142%;
  letter-spacing: -0.02em;
  font-size: 12px;
  font-weight: 500;
`;

const SportTypeContainer = styled.div`
  display: flex;
  align-items: center;
`;

const Sport = styled.div`
  display: flex;
  align-items: center;
  color: ${colors.Bl07};
  line-height: 142%;
  letter-spacing: -0.02em;
  font-size: 12px;
  font-weight: 500;
`;

const Type = styled.div`
  display: flex;
  align-items: center;
  color: ${colors.Gr03};
  line-height: 142%;
  letter-spacing: -0.02em;
  font-size: 12px;
  font-weight: 500;
`;

const Dye = styled.span`
  color: ${({ color }) => color};
`;
