import React, { useRef, useState } from "react";
import styled from "styled-components";

// style
import { H, W } from "../../styles/globalStyles";
import colors from "../../styles/colors";

// img
import GreyPlus from "../../icon/grayPlus.png";
import BluePlus from "../../icon/bluePlus.png";
import XIcon from "../../icon/x.png";
import { ReactComponent as Search } from "../../icon/search.svg";
import { ReactComponent as CloseCircle } from "../../icon/close_circle.svg";
import { useEffect } from "react";

const MAX_EXPOSED_LENGTH = 2;
const MAX_KEYWORD_TEXT_LENGTH = 50; // 추가대상/ 자격 요건 / 유의사항 / 준비물/포함사항/ 불포함사항 / 자주 묻는 질문 - 검색 텍스트
const MAX_KEYWORD_ADD_LENGTH = 5; // 추가대상/ 자격 요건 / 유의사항 / 준비물/포함사항/ 불포함사항 / 자주 묻는 질문 - 추가 리스트

const TagSelector = ({
  // status,
  data,
  setData,
  bigData,
  textRecommend,
  deleteD,
  setDelete,
  POPULAR_LIST,
  titleText,
  tipText,
  requirementText,
  placeholderText,
  type,
  setChange
}) => {
  const [focus, setFocus] = useState(false);
  const [selectable, setSelectable] = useState(true);
  const [input, setInput] = useState({
    recommend: "",
    qualified: "",
    prepare: "",
    include: "",
    exclude: "",
    caution: "",
    exposed: "",
    search: "",
  });

  const searchBoxRef = useRef();

  const handleClose = (e) => {
    if (!searchBoxRef.current || !searchBoxRef.current.contains(e.target)) {
      setFocus(false);
    }
  };

  useEffect(() => {
    window.addEventListener("click", handleClose);

    return () => {
      window.removeEventListener("click", handleClose);
    };
  }, [focus]);

  useEffect(() => {
    if (type === "exposed") {
      setSelectable(data[type].length < MAX_EXPOSED_LENGTH);
    } else {
      setSelectable(true);
    }
  }, [data[type].length]);

  const setKeywords = (key) => {
    const tagArr = [...data[key].map((val) => val.tag_name)];
    const arr = [...data[key]];
    if (arr.length >= MAX_KEYWORD_ADD_LENGTH) {
      return window.alert(`최대 ${MAX_KEYWORD_ADD_LENGTH}개 까지 추가할 수 있습니다.`);
    }
    if (!tagArr.includes(input[key])) {
      // if (!arr.includes(input[key])) {
      arr.push({ id: "new", change: "yes", tag_name: input[key] });
      setData((prev) => ({ ...prev, [key]: arr }));
      setChange(true);
    } else {
      alert("키워드를 중복해서 추가 할 수 없습니다.");
    }
    setInput((prev) => ({ ...prev, [key]: "" }));
  };

  const reviseKeywords = (key, idx, value) => {
    const tagArr = [...data[key].map((val) => val.tag_name)];
    const arr = [...data[key]];


    if (!tagArr.includes(value)) {
      let ChangeTarget = arr[idx];
      let OGList = arr;

      ChangeTarget['tag_name'] = value;
      OGList[idx] = ChangeTarget;

      setData((prev) => ({ ...prev, [key]: OGList }));
      setChange(true);
    } else {
      alert("키워드를 중복해서 추가 할 수 없습니다.");
    }

  }

  const setKeywordsRecommend = (value, key) => {
    const tagArr = [...data[key].map((val) => val.tag_name)];
    const arr = [...data[key]];
    if (arr.length >= MAX_KEYWORD_ADD_LENGTH) {
      return window.alert(`최대 ${MAX_KEYWORD_ADD_LENGTH}개 까지 추가할 수 있습니다.`);
    }
    if (!tagArr.includes(value)) {
      arr.push({ id: "new", change: "yes", tag_name: value });
      setData((prev) => ({ ...prev, [key]: arr }));
      setChange(true);
    } else {
      alert("키워드를 중복해서 추가 할 수 없습니다.");
    }
    setInput((prev) => ({ ...prev, [key]: "" }));
  };

  const deleteKeywords = (key, value, index) => {
    let arr = [...data[key]];
    const deleted = [...deleteD[key]];
    deleted.push(arr[index]);

    arr = arr.filter((x) => x !== value);
    setDelete((prev) => ({ ...prev, [key]: deleted }));
    setData((prev) => ({ ...prev, [key]: arr }));
    setChange(true);
  };

  const searchKeyword = (value, category) => {
    let text = value.substring(0, MAX_KEYWORD_TEXT_LENGTH);
    setInput((prev) => ({ ...prev, [category]: text }));

    if (text.length > 1) {
      bigData(category, text);
    }
  };

  return (
    <TagSelectorContainer>
      <TitleContainer>
        <TagTitle>{titleText}</TagTitle>
        <RequirementText>{requirementText}</RequirementText>
        {/* <TagTitle status={status[type]}>{titleText}</TagTitle>
        <RequirementText status={status[type]}>{requirementText}</RequirementText> */}
      </TitleContainer>
      {tipText && <TipText>{tipText}</TipText>}

      <div ref={searchBoxRef}>
        <TagInputDiv tagInput={placeholderText.includes("#")} focus={focus}>
          {/* <TagInputDiv focus={focus} warning={status[type] === "empty"}> */}
          <TagInput
            tagInput={placeholderText.includes("#")}
            disabled={!selectable}
            value={input[type]}
            placeholder={placeholderText}
            onChange={(e) => searchKeyword(e.target.value, type)}
            onFocus={() => setFocus(true)}
            // onBlur={() => setFocus(false)}
          />
          <InputIcon>
            {input[type] ? <CloseCircle onClick={() => searchKeyword("", type)} fill={colors.Gr03} /> : <></>}
            <Search fill={focus ? colors.Bl07 : colors.Gr03} />
          </InputIcon>
        </TagInputDiv>

        {(input[type] && focus) && (
          <SearchBox>
            <SearchList>
              {textRecommend &&
                textRecommend.map((val, idx) => (
                  <SearchItem key={`${idx}-${val}-search`} onClick={() => setKeywordsRecommend(val, type)}>
                    <SearchText>{val}</SearchText>
                    <PlusIcon src={GreyPlus} />
                  </SearchItem>
                ))}
            </SearchList>
            <AddSearchItem onClick={() => setKeywords(type)}>
              <AddSearchText>{input[type]} 항목 추가하기</AddSearchText>
              <PlusIcon src={BluePlus} />
            </AddSearchItem>
          </SearchBox>
        )}
      </div>

      {POPULAR_LIST.length ? (
        <PopularTagContainer>
          <Indicator>인기 태그</Indicator>
          {POPULAR_LIST.map((val, idx) => (
            <BlueIndicator key={`${val}-${idx}`} onClick={() => selectable && setKeywordsRecommend(val, type)}>
              {val}
            </BlueIndicator>
          ))}
        </PopularTagContainer>
      ) : (
        <></>
      )}

      <TagContainer>
        {data[type].map((val, idx) => (
          <TagItem key={`${type}_${idx}`}>
            <TagText
              type={`text`}
              value={val.tag_name}
              onChange={(e) => reviseKeywords(type, idx, e.target.value)}
            />
            <Xbutton src={XIcon} onClick={() => deleteKeywords(type, val, idx)} />
          </TagItem>
        ))}
      </TagContainer>
    </TagSelectorContainer>
  );
};

export default TagSelector;

const TagSelectorContainer = styled.div`
  padding: 24px 0 0 0;
  width: 100%;
`;

const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
`;

const TagTitle = styled.div`
  font-weight: 600;
  font-size: 16px;
  line-height: 142%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${colors.Gr05};
  /* color: ${({ status }) => (status == "empty" ? `${colors.Red03}` : `${colors.Gr05}`)}; */
`;

const RequirementText = styled.span`
  font-weight: 500;
  font-size: 14px;
  line-height: 142%;
  display: flex;
  align-items: center;
  letter-spacing: -0.02em;
  color: ${colors.Gr03};
`;

const PopularTagContainer = styled.div`
  display: flex;
  flex-wrap: "wrap";
  gap: 10px;
  padding-top: 8px;
  position: relative;
`;

const BlueIndicator = styled.div`
  cursor: pointer;
  font-weight: 500;
  font-size: 14px;
  line-height: 142%;
  display: flex;
  align-items: center;
  letter-spacing: -0.02em;
  color: ${colors.Bl05};
  /* color: ${({ status }) => (status == "empty" ? `${colors.Red03}` : `${colors.Bl05}`)}; */
`;

const Indicator = styled.div`
  font-weight: 500;
  font-size: 14px;
  line-height: 142%;
  display: flex;
  align-items: center;
  letter-spacing: -0.02em;
  color: ${colors.Gr04};
`;

const TagInputDiv = styled.div`
  display: flex;
  align-items: center;
  padding: 0 14px;
  margin-top: 10px;
  height: 44px;
  gap: 10px;
  /* background-color: ${colors.White}; */
  background-color: ${({ tagInput }) => (tagInput ? colors.Gr01 : colors.White)};
  border: 1px solid ${({ focus, warning }) => (focus ? `${colors.Bl07}` : warning ? `${colors.Red03}` : `${colors.Gr02}`)};
  border-radius: 6px;
`;

const TagInput = styled.input`
  font-weight: 500;
  font-size: 14px;
  line-height: 142%;
  display: flex;
  align-items: center;
  letter-spacing: -0.02em;
  color: ${colors.Gr05};
  width: 100%;
  cursor: text;

  &::placeholder {
    color: ${({ tagInput }) => (tagInput ? colors.Gr02 : colors.Gr03)};
  }
`;

const SearchBox = styled.div`
  margin-top: 10px;
  border: 1px solid ${colors.Gr02};
  border-radius: 6px;
`;

const SearchList = styled.div``;

const SearchItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 8px 10px 18px;
  border-bottom: 1px solid ${colors.Gr02};
  cursor: pointer;
`;

const AddSearchItem = styled(SearchItem)`
  cursor: pointer;
  border: none;
  background-color: ${colors.Bl01};
  border-radius: 6px;
`;

const SearchText = styled.div`
  font-weight: 500;
  font-size: 12px;
  line-height: 142%;
  display: flex;
  align-items: center;
  letter-spacing: -0.02em;
  color: ${colors.Gr05};
  max-width: ${W - 98}px;
  word-break: break-all;
  text-overflow: ellipsis;
`;

const AddSearchText = styled(SearchText)`
  color: ${colors.Bl07};
`;

const PlusIcon = styled.img`
  width: 24px;
  height: 24px;
`;

const TagContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-top: 10px;
  justify-content: space-between;
`;

const TagItem = styled.div`
  border: 1.2px solid ${colors.Gr02};
  padding: 8px 12px;
  display: flex;
  align-items: center;
  background-color: ${colors.Gr01};
  border-radius: 12px;
  //margin-right: 9px;
  margin-bottom: 8px;
  width : calc(50% - 5px);
  justify-content: space-between;
`;

const TagText = styled.input`
  font-weight: 500;
  font-size: 14px;
  line-height: 142%;
  display: flex;
  align-items: center;
  border-bottom : 1px solid ${colors.Gr05};

  letter-spacing: -0.02em;
  color: ${colors.Gr05};
  width: calc(100% - 24px);
  word-break: break-all;
  text-overflow: ellipsis;
`;

const Xbutton = styled.img`
  width: 12px;
  height: 18px;
  margin-left: 4px;
  cursor: pointer;
`;

const InputIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;

  svg {
    width: 24px;
    height: 24px;
  }
`;

const TipText = styled.span`
  white-space: pre-line;
  font-size: 14px;
  font-weight: 500;
  line-height: 22px;
  letter-spacing: -0.28px;
  color: ${colors.Red03};
  padding-bottom: 6px;
`;
