import React, { useState, useEffect } from "react";
import styled from "styled-components";

//component
import { Wrapper, Text, Input } from "../layouts/layoutStorage";
import colors from "../../styles/colors";

//icon
import ActivityModal from "./ActivityModal";

const CATEGORY = [
    {
        id: 1,
        name: "프리다이빙",
    },
    {
        id: 2,
        name: "스쿠버다이빙",
    },
    {
        id: 3,
        name: "서핑",
    },
    {
        id: 4,
        name: "수영/스노클링",
    },
    {
        id: 5,
        name: "패들보드",
    },
    {
        id: 6,
        name: "수상레저",
    },
    {
        id: 7,
        name: "스키",
    },
    {
        id: 8,
        name: "스노보드",
    },
    {
        id: 9,
        name: "클라이밍",
    },
    {
        id: 10,
        name: "골프",
    },
    {
        id: 11,
        name: "피트니스",
    },
    {
        id: 12,
        name: "필라테스",
    },
    {
        id: 13,
        name: "GX",
    },
    {
        id: 14,
        name: "테니스",
    },
    {
        id: 15,
        name: "스쿼시",
    }
];

const CATEGORY_ARRAY = [
    "프리다이빙", "스쿠버다이빙", "스키", "스노보드", 
    "서핑", "클라이밍", "골프", "피트니스", 
    "필라테스", "GX", "수영/스노클링", "패들보드", 
    "수상레저", "요가", "테니스", "스쿼시"
];

    const TYPE = [
    {
        id: 1,
        name: "단기 레슨",
    },
    {
        id: 2,
        name: "정기 레슨",
    },
    {
        id: 3,
        name: "자격증",
    },
    {
        id: 4,
        name: "국내/해외 투어",
    },

    {
        id: 5,
        name: "이용권(횟수)",
    },
    {
        id: 6,
        name: "이용권(기간)",
    },
    {
        id: 7,
        name: "장비 대여",
    },
];

const MAX_CATEGORY = 3;
const MAX_CATEGORY_TEXT_LENGTH = 10;

const ActivityCategoryModal = ({ data, setData, open, setOpen, setChange }) => {
    const [addCategory, setAddCategory] = useState(false);
    const [focusAdd, setFocusAdd] = useState(false);
    const [categoryData, setCategoryData] = useState({
        category: [],
        activity_duration: "",
        activity_type: "",
    });

    useEffect(() => {
        if (data.category.length > 0) {
            let categoryArray = [...data.category];
            let otherCategoryCheck = CATEGORY_ARRAY.filter((el) => categoryArray.includes(el));
            if (categoryArray.length && otherCategoryCheck.length === 0) {
                setAddCategory(true);
            } else {
                setAddCategory(false);
            }
            setCategoryData((prev) => ({ ...prev, category: data.category }));
        }

        setCategoryData((prev) => ({ ...prev, activity_duration: data.activity_duration, activity_type: data.activity_type }));
        return () => {
            setAddCategory(false);
            setCategoryData({
                category: [],
                activity_duration: "",
                activity_type: "",
            });
        };
    }, [open]);

    return (
        <ActivityModal
            buttonText="저장하기"
            buttonActive={categoryData.category[0] && categoryData.activity_type}
            title="액티비티 카테고리"
            open={open}
            setOpen={setOpen}

            saveData={() => {
                setData((prev) => ({
                ...prev,
                    category: categoryData.category,
                    activity_type: categoryData.activity_type,
                }));
                setAddCategory(false);
                setChange(true);
                setOpen(false);
            }}
        >
        <Wrapper fd="column" height="100%" width="100%" ai="flex-start" padding="0 0 130px 0">
            <Text margin="8px 24px 12px 24px" size="14px" weight="500" lh="22px" ls="-0.28px" color={colors.Gr04}>
                진행할 액티비티의 종목을 선택해주세요
            </Text>
            <Wrapper fd="column" width="100%" ai="flex-start" of="auto">
                <Wrapper padding="16px 24px 8px 24px" width="100%" fd="column" ai="flex-start">
                    <ModalSubTitle>액티비티 종목</ModalSubTitle>

                    <TipWrap>
                        <span className="tip">Tip! </span>
                        <span>최대 3개까지 선택 가능해요</span>
                    </TipWrap>

                    <Wrapper margin="16px 0 0 0" wrap="wrap" width="100%" gap="8px">
                        {CATEGORY.map((item, idx) => (
                            <ButtonWrap
                                key={`${item.id}_${idx}`}
                                active={categoryData.category.includes(item.name) ? "active" : "dafault"}
                                onClick={() => {
                                    let sportArray = [...categoryData.category];
                                    let categoryCopy = [...categoryData.category];

                                    if (addCategory) {
                                        setAddCategory(false);
                                        categoryCopy = [];
                                    }
                                    if (sportArray.includes(item.name)) {
                                        categoryCopy = categoryCopy.filter((el) => el !== item.name);
                                    } else {
                                    if (categoryCopy.length !== MAX_CATEGORY) {
                                        categoryCopy.push(item.name);
                                    }
                                    }
                                    setCategoryData((prev) => ({ ...prev, category: categoryCopy }));
                                }}
                            >
                                <ButtonText>{item.name}</ButtonText>
                            </ButtonWrap>
                        ))}
                        <ButtonWrap
                            active={addCategory ? "active" : "dafault"}
                            onClick={() => {
                            if (addCategory) {
                                setAddCategory(false);
                                setCategoryData((prev) => ({
                                    ...prev,
                                    category: [],
                                }));
                            } else {
                                setAddCategory(true);
                                setCategoryData((prev) => ({
                                    ...prev,
                                    category: [""],
                                }));
                            }
                            }}
                        >
                            <ButtonText>그 외 종목</ButtonText>
                        </ButtonWrap>
                    {addCategory && (
                        <>
                            <Wrapper padding="12px" width="100%" radius="8px" border={`1px solid ${focusAdd ? colors.Bl07 : colors.Gr02}`}>
                                <Input
                                    maxLength={10}
                                    onFocus={() => setFocusAdd(true)}
                                    onBlur={() => setFocusAdd(false)}
                                    value={categoryData.category[0]}
                                    onChange={(e) => {
                                        setCategoryData((prev) => ({
                                        ...prev,
                                        category: [e.target.value.substring(0, MAX_CATEGORY_TEXT_LENGTH)],
                                        }));
                                    }}
                                    size="14px"
                                    weight="500"
                                    lh="22px"
                                    ls="-0.28px"
                                    width="100%"
                                    phColor={colors.Gr03}
                                    placeholder="종목을 직접 입력해주세요"
                                />
                            </Wrapper>
                            <Text size="14px" weight="500" lh="22px" ls="-0.28px" color={colors.Gr03}>
                                심사 후 종목명이 표준에 맞게 변경될 수 있어요
                            </Text>
                        </>
                    )}
                    </Wrapper>
                </Wrapper>

                <Wrapper padding="16px 24px 40px 24px" width="100%" fd="column" ai="flex-start">
                    <ModalSubTitle>액티비티 타입</ModalSubTitle>
                    <TipWrap>
                        <span style={{width : '100%', display : "inline-block"}} className="tip">Tip! </span>
                        <span style={{width : '100%', display : "inline-block"}}>단기 레슨 : 일회성/비정기적 단기 레슨 (예시 : 체험/원데이)</span>
                        <span style={{width : '100%', display : "inline-block"}}>정기 레슨 : 정기적으로 진행 되는 레슨 (예시 : 매주 화/목)</span>
                        <span style={{width : '100%', display : "inline-block"}}>자격증 : 자격증 취득을 위한 과정</span>
                        <span style={{width : '100%', display : "inline-block"}}>국내/해외 투어 : 1박 이상 숙박과 액티비티가 포함된 과정</span>
                        <span style={{width : '100%', display : "inline-block"}}>이용권(횟수) : 정해진 횟수만큼 사용할 수 있는 이용권</span>
                        <span style={{width : '100%', display : "inline-block"}}>이용권(기간) : 정해진 기간만큼 사용할 수 있는 이용권</span>
                        <span style={{width : '100%', display : "inline-block"}}>장비 대여 : 레슨 없이 장비만 대여</span>
                    </TipWrap>
                    <Wrapper 
                        margin="20px 0 0 0" 
                        width="100%" 
                        gap="8px" 
                        jc="flex-start" 
                        wrap='wrap'>
                        {TYPE.map((item) => (
                            <ButtonWrap
                                key={item.id}
                                active={categoryData.activity_type === item.name ? "active" : "dafault"}
                                onClick={() => {
                                    setCategoryData((prev) => ({ ...prev, activity_type: item.name }));
                                }}
                            >
                                <ButtonText>{item.name}</ButtonText>
                            </ButtonWrap>
                        ))}
                    </Wrapper>
                </Wrapper>

            </Wrapper>
        </Wrapper>
        </ActivityModal>
    );
};

export default ActivityCategoryModal;

const ModalSubTitle = styled.span`
  word-break: keep-all;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: -0.32px;
  color: ${colors.Gr05};
`;

const TipWrap = styled.p`
  font-size: 14px;
  font-weight: 500;
  line-height: 22px;
  letter-spacing: -0.28px;
  color: ${colors.Gr03};
  .tip {
    color: ${colors.Bl05};
  }
`;

const ButtonWrap = styled.div`
  width: ${({ width }) => `${width}`};
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  padding: 8px 12px;
  background-color: ${({ active }) => (active === "active" ? colors.Bl02 : active === "disabled" ? colors.Gr01 : colors.White)};
  border: 1px solid ${({ active }) => (active === "active" ? colors.Bl05 : colors.Gr02)};
`;

const ButtonText = styled.span`
  word-break: keep-all;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: -0.32px;
  color: ${({ active }) => (active === "disabled" ? colors.Gr02 : colors.Gr05)};
`;
