import React, { useEffect, useState, useRef } from "react";
import styled, { css } from "styled-components";
import axios from "axios";

//tool
import { GetOne } from "../tool/localstorage";

//style
import { Wrapper, Text, } from "../components/layouts/layoutStorage";
import colors from "../styles/colors";
import {PC, W, useWindowSize } from "../styles/globalStyles";

//component
import { Loading } from "../tool/Loading";
import FacilityHeader from "../components/facilityRegister/FacilityHeader";
import FacilityManageModal from "../components/facilityCrew/FacilityManageModal";

//icon
import { ReactComponent as Arrow } from "../resource/icon/arrow.svg";

// Default Data
const IMAGE_RATIO = 234.375 / 375;

const FacilityReservation = () => {

    const [loading, setLoading] = useState(false);
    const [manage, setManage] = useState(false);
    const [facility, setFacility] = useState();
    const [titleList, setTitleList] = useState();
    const [title, setTitle] = useState(0);
    const [data, setData] = useState();
    const [NowTime, setNowTime] = useState(new Date());

    const [user, setUser] = useState(false);

    const [selectDay, setSelectDay] = useState({
        year : new Date().getFullYear(),
        month : (new Date().getMonth() + 1),
        day : new Date().getDate()
    });

    const [checkTime, setCheckTime] = useState();

    const [width, setWidth] = useState(useWindowSize());

    let WID = useWindowSize();
    
    useEffect(() => {
        setWidth(WID);
    }, [WID]);

    const NextDay = () => {
        let ThisDate = `${selectDay.year}-${(selectDay.month > 9) ? selectDay.month : `0${selectDay.month}`}-${(selectDay.day > 9) ? selectDay.day : `0${selectDay.day}`}`;
    
        ThisDate = new Date(ThisDate);

        ThisDate.setDate(ThisDate.getDate() + 1);

        setSelectDay({
            year : ThisDate.getFullYear(),
            month : (ThisDate.getMonth() + 1),
            day : ThisDate.getDate()
        })
    }

    const PrevDay = () => {
        let ThisDate = `${selectDay.year}-${(selectDay.month > 9) ? selectDay.month : `0${selectDay.month}`}-${(selectDay.day > 9) ? selectDay.day : `0${selectDay.day}`}`;

        ThisDate = new Date(ThisDate);

        ThisDate.setDate(ThisDate.getDate() - 1);

        setSelectDay({
            year : ThisDate.getFullYear(),
            month : (ThisDate.getMonth() + 1),
            day : ThisDate.getDate()
        })
    }

    const GetData = () => {
        var token = GetOne("token");

        const frm = new FormData();

        frm.append("mode", "facility_reservation");
        frm.append("token", token);
        frm.append("year", selectDay.year);
        frm.append("month", (selectDay.month > 9) ? selectDay.month : `0${selectDay.month}`);
        frm.append("day", (selectDay.day > 9) ? selectDay.day : `0${selectDay.day}`);

        axios
        .post("https://ai.semos.kr/semos_partner/v3/activity/Facility", frm, {
            headers: { "Content-Type": "multipart/form-data" },
        })
        .then((data) => {
            var Value = data.data;
            var Status = Value.status;
            var StatusMessage = Value.status_message;
            var FacilityInfo = Value.facility_info;
            var FacilityTitle = Value.facility_title;

            if (Status == "success") {
                setFacility(FacilityInfo);
                setTitleList(FacilityTitle);
            } else {
                window.alert(StatusMessage);
            }
        });
    };

    useEffect(() => {
        const time = setInterval(() => {
            const Now = new Date();
            const Hour = Now.getHours();

            setNowTime(Now);

            if(checkTime != Hour) {
                setCheckTime(Hour);
            }
        }, 30000);

        return () => clearInterval(time);
    }, [checkTime]);

    useEffect(() => {
        GetData();
    }, [selectDay]);

    useEffect(() => {
        const Reload = setInterval(() => {
            if(user === true) {
                GetData();
            }
        }, 30000);

        return () => clearInterval(Reload);
    }, [user]);

    useEffect(() => {
        const Now = new Date();
        const Hour = Now.getHours();

        setCheckTime(Hour);
    }, []);

    const ReturnName = (NAME) => {
        let strName = NAME;

        if(user) {
            if (strName.length > 2) {
                var originName = strName.split('');
                originName.forEach(function(strName, i) {
                    if (i === 0 || i === originName.length - 1) return;
                    originName[i] = '*';
                });
                var joinName = originName.join();
                strName = joinName.replace(/,/g, '');
            } else {
                var pattern = /.$/; // 정규식
                strName = strName.replace(pattern, '*');
            }
        }

        return strName
    }

    const ReturnDeadline = (DATE, TIME) => {
        const DATESPLIT = DATE.split('.');

        const ThisTime = new Date(`${DATESPLIT[0]}-${DATESPLIT[1]}-${DATESPLIT[2]} ${TIME}`);

        const TimeCheck = (ThisTime.getTime() - NowTime.getTime()) / (60 * 1000);

        if(TimeCheck < 11) {
            return true;
        } else {
            return false;
        }
    }

    const ListReturn = () => {

        let Check = width < 1300;
        // let Check = true;

        switch(Check) {

            case true :
                if(facility){
                    let target = facility[title];
                    const ChartTime = target.chart_time;
                    const ChartUser = target.chart_user;
                    const TicketList = target.ticket_list;
                    const ChartCount = target.chart_count;
                    const TimeList = target.time_list;
                    const FacilityId = target.id;
                    const OffList = target.off_list;
                    const CapacityList = target.capacity_list;
    
                    return (
                        <>
                            <Wrapper
                                display="inline-block"
                                width="100%"
                                height={`50px`}
                                style={{
                                    overflowY : 'hidden',
                                    overflowX : 'auto',
                                    whiteSpace : "nowrap",
                                }}
                            >
                                {
                                    titleList?.map((item, idx) => (
                                        <Wrapper
                                            key={`${idx}_chart`}
                                            display="inline-flex"
                                            padding={`5px 8px`}
                                            //radius={`8px`}
                                            border={`1px solid ${(idx == title) ? colors.Bl07 : colors.Gr03}`}
                                            bgc={`${colors.White}`}
                                            ai={`center`}
                                            jc={`center`}
                                            cursor={`pointer`}
                                            margin={(idx == 0) ? `0` : `0 0 0 8px`}
                                            onClick={() => setTitle(idx)}
                                        >
                                            <Text 
                                                ta="center" 
                                                size="1.6rem" 
                                                weight="600" 
                                                color={(idx == title) ? colors.Bl07 :  colors.Gr05}
                                            >
                                                {item}
                                            </Text>
                                        </Wrapper>
                                    ))
                                }
                            </Wrapper>

                            <Wrapper
                                jc="flex-start"
                                display="inline-flex"
                                fd={`column`}
                                ai="center"
                                width="100%"
                                height={`calc(100% - 50px)`}
                                border={`1px solid ${colors.Gr02}`}
                                radius={`8px`}
                                of={`hidden auto`}
                                bgc={`${colors.Gr01}`}
                                wrap={`wrap`}
                            >
                                {
                                            <Wrapper
                                                jc="flex-start"
                                                display="inline-block"
                                                fd={`column`}
                                                ai="center"
                                                width={`calc(100%)`}
                                                height={`auto`}
                                                border={`1px solid ${colors.Gr02}`}
                                                radius={`8px`}
                                                of={`hidden auto`}
                                                bgc={`${colors.Gr01}`}
                                                wrap={`wrap`}
                                            >
                                                <Wrapper
                                                        bb={`solid 1px ${colors.Gr02}`}
                                                        //br={`solid 1px ${colors.Gr02}`}
                                                        bgc={`${colors.Gr01}`}
                                                        width={`100%`}
                                                        //height={`45px`}
                                                        position={`sticky`}
                                                        style={{
                                                            top : 0,
                                                            left : 0,
                                                            zIndex : 10,
                                                            minHeight : 45
                                                        }}
                                                >
                                                        <Wrapper
                                                            width={`100%`}
                                                            //height={`45px`}
                                                            ai={`center`}
                                                            jc={`center`}
                                                            bgc={`${colors.Gr01}`}
                                                            style={{
                                                                minHeight : 45
                                                            }}
                                                        >
                                                            <Text 
                                                                ta="center" 
                                                                size={(user == false) ? "1.8rem" : "3rem"} 
                                                                weight="600" 
                                                                color={colors.Gr05}
                                                            >
                                                                {target.title}
                                                            </Text>
                                                        </Wrapper>
                        
                                                </Wrapper>    
        
                                                {
                                                    (user == false) ?
                                                        ChartTime?.map((item, idx) => (
                            
                                                            <Wrapper
                                                                key={`${idx}_time_chart`}
                                                                bb={`solid 1px ${colors.Gr02}`}
                                                                //br={`solid 1px ${colors.Gr02}`}
                                                                width={`100%`}
                                                                display={`flex`}
                                                                //height={`45px`}
                                                                style={{
                                                                    minHeight : 45
                                                                }}
                                                                bgc={OffList[item] ? 'transparent' : colors.B10}
                                                                cursor={`pointer`}
                                                                onClick={() => {
                                                                    if(!user) { 
                                                                        setManage(true);
                                                                        setData({
                                                                            year : selectDay?.year,
                                                                            month : selectDay?.month > 9 ? selectDay?.month : `0${selectDay?.month}`,
                                                                            day : selectDay?.day > 9 ? selectDay?.day : `0${selectDay?.day}`,
                                                                            time : item.split(' ')[1],
                                                                            status : true,
                                                                            now : ChartCount[item],
                                                                            max : target.max_capacity,
                                                                            crew : ChartUser[item],
                                                                            timeList : TimeList,
                                                                            ticketList : TicketList[item],
                                                                            facilityId : FacilityId,
                                                                            off : OffList[item],
                                                                            capacity : CapacityList[item]
                                                                        })
                                                                    }
                                                                }}
                                                            >
                                                                <Wrapper
                                                                    width={`20%`}
                                                                    //height={`45px`}
                                                                    //br={`1px solid ${colors.Gr02}`}
                                                                    ai={`center`}
                                                                    jc={`center`}
                                                                    style={{
                                                                        minHeight : 45,
                                                                        height : '100%'
                                                                    }}
                                                                >
                                                                    <Text 
                                                                        ta="center" 
                                                                        size="1.6rem" 
                                                                        weight="600" 
                                                                        color={colors.Gr05}
                                                                    >
                                                                        {item.split(' ')[1]}
                                                                    </Text>
                                                                </Wrapper>
                                
                                                                <Wrapper
                                                                    width={`80%`}
                                                                    //height={`45px`}
                                                                    //bl={`1px solid ${colors.Gr02}`}
                                                                    padding={`2px 10px 2px 10px`}
                                                                    wrap={`nowrap`}
                                                                    gap={`5px`}
                                                                    style={{
                                                                        minHeight : 45
                                                                    }}
                                                                >
                                                                    <Wrapper
                                                                        width={`100%`}
                                                                        wrap={`wrap`}
                                                                        jc={`flex-end`}
                                                                        //br={`solid 1px ${colors.Gr02}`}
                                                                        padding={`0 3px 0 0 `}
                                                                        gap={`5px`}
                                                                    >
                                                                        {
                                                                            ChartUser[item]?.map((i, id) => (
                                                                                <Text 
                                                                                    key={`${idx}_${id}_chart`}
                                                                                    ta="right" 
                                                                                    size="1.6rem" 
                                                                                    weight="600" 
                                                                                    color={colors.Gr05}
                                                                                    jc={`flex-end`}
                                                                                >
                                                                                    {(id == 0) ? `${ReturnName(i.name)}` : `, ${ReturnName(i.name)}`}
                                                                                </Text>
                                                                            ))
                                                                        }
                                                                        
                                                                    </Wrapper>
                                
                                                                    <Text 
                                                                        ta="right" 
                                                                        size="1.6rem" 
                                                                        weight="600" 
                                                                        width={`50px`}
                                                                        height={`100%`}
                                                                        color={colors.Gr05}
                                                                        ai={`center`}
                                                                        jc={`center`}
                                                                    >
                                                                        {`${ChartCount[item]}/${CapacityList[item]}`}
                                                                    </Text>
                                
                                                                </Wrapper>
                                                            </Wrapper>
                                                        ))
                                                    : 
                                                        ChartTime?.map((item, idx) => {
                                                            const ThisTime = item.split(' ')[1];
                                                            const ThisHour = ThisTime.split(':')[0];

                                                            if(checkTime == ThisHour) {
                                                                return (
                                                                    <Wrapper
                                                                        key={`${idx}_time_chart`}
                                                                        bb={`solid 1px ${colors.Gr02}`}
                                                                        //br={`solid 1px ${colors.Gr02}`}
                                                                        width={`100%`}
                                                                        display={`flex`}
                                                                        wrap={`wrap`}
                                                                        //height={`45px`}
                                                                        position={`relative`}
                                                                        style={{
                                                                            minHeight : 45
                                                                        }}
                                                                        bgc={OffList[item] ? 'transparent' : colors.B10}
                                                                        cursor={`pointer`}
                                                                        onClick={() => {
                                                                            if(!user) { 
                                                                                setManage(true);
                                                                                setData({
                                                                                    year : selectDay?.year,
                                                                                    month : selectDay?.month > 9 ? selectDay?.month : `0${selectDay?.month}`,
                                                                                    day : selectDay?.day > 9 ? selectDay?.day : `0${selectDay?.day}`,
                                                                                    time : item.split(' ')[1],
                                                                                    status : true,
                                                                                    now : ChartCount[item],
                                                                                    max : target.max_capacity,
                                                                                    crew : ChartUser[item],
                                                                                    timeList : TimeList,
                                                                                    ticketList : TicketList[item],
                                                                                    facilityId : FacilityId,
                                                                                    off : OffList[item],
                                                                                    capacity : CapacityList[item]
                                                                                })
                                                                            }
                                                                        }}
                                                                    >

                                                                        <Text 
                                                                            ta="center" 
                                                                            size="2.2rem" 
                                                                            weight="500" 
                                                                            color={colors.Bl07}
                                                                            style={{
                                                                                position : 'absolute',
                                                                                top : 0,
                                                                                right : 10
                                                                            }}
                                                                        >
                                                                            {`${ChartCount[item]}/${CapacityList[item]}`}
                                                                        </Text>

                                                                        <Wrapper
                                                                            width={`100%`}
                                                                            //height={`45px`}
                                                                            ai={`center`}
                                                                            jc={`center`}
                                                                            style={{
                                                                                minHeight : 45,
                                                                                height : '100%',
                                                                                borderBottom : `1px solid ${colors.Gr02}`
                                                                            }}
                                                                        >
                                                                            <Wrapper>
                                                                                <Text 
                                                                                    ta="center" 
                                                                                    size="3rem" 
                                                                                    weight="600" 
                                                                                    color={colors.Gr05}
                                                                                >
                                                                                    {ThisTime}
                                                                                </Text>
                                                                            </Wrapper>
                                                                        </Wrapper>
                                        
                                                                        <Wrapper
                                                                            width={`100%`}
                                                                            //height={`45px`}
                                                                            //bl={`1px solid ${colors.Gr02}`}
                                                                            wrap={`wrap`}
                                                                            gap={`7px`}
                                                                            style={{
                                                                                minHeight : 45
                                                                            }}
                                                                        >
                                                                            {
                                                                                ChartUser[item]?.map((i, id) => {
                                                                                    const DeadlineCheck = ReturnDeadline(i.date, i.end);

                                                                                    return (
                                                                                        <Wrapper
                                                                                            width={`100%`}
                                                                                            wrap={`wrap`}
                                                                                            jc={`center`}
                                                                                            gap={`5px`}
                                                                                            bb={`solid 1px ${colors.Gr01}`}
                                                                                            padding={`5px 0`}
                                                                                        >

                                                                                            <Text 
                                                                                                key={`${idx}_${id}_chart`}
                                                                                                size="4.5rem" 
                                                                                                weight="600" 
                                                                                                color={colors.Bl07}
                                                                                                className={(DeadlineCheck == true) ? 'blink_effect' : ""}
                                                                                            >
                                                                                                {`${ReturnName(i.name)}`}
                                                                                                <Text 
                                                                                                    size="4rem" 
                                                                                                    weight="600" 
                                                                                                    color={colors.Gr05}
                                                                                                    style={{
                                                                                                        marginLeft : 10
                                                                                                    }}
                                                                                                >
                                                                                                    {`(~${i.end})`}
                                                                                                </Text>
                                                                                            </Text>
                                                                                            
                                                                                        </Wrapper>
                                                                                    )
                                                                                })
                                                                            }
                                                                        </Wrapper>
                                                                    </Wrapper>
                                                                )
                                                            }
                                                        })
                                                }
                                            </Wrapper>
                                }
                            </Wrapper> 
                        </>
                    )
                }
            break;

            case false :
                return (
                    <Wrapper
                        jc="flex-start"
                        display="inline-flex"
                        //fd={`column`}
                        ai="flex-start"
                        width="100%"
                        height={`calc(100% - 50px)`}
                        border={`1px solid ${colors.Gr02}`}
                        //radius={`8px`}
                        of={`hidden auto`}
                        bgc={`${colors.Gr01}`}
                        wrap={`wrap`}
                    >
                        {
                            facility?.map((item2, idx2) => {
                                const ChartTime = item2.chart_time;
                                const ChartUser = item2.chart_user;
                                const TicketList = item2.ticket_list;
                                const ChartCount = item2.chart_count;
                                const TimeList = item2.time_list;
                                const OffList = item2.off_list;
                                const CapacityList = item2.capacity_list;
                                const FacilityId = item2.id;

                                return (
                                    <Wrapper
                                        key={`${idx2}_chart`}
                                        jc="flex-start"
                                        display="inline-block"
                                        fd={`column`}
                                        ai="center"
                                        width={`calc(100% / ${facility?.length})`}
                                        height={`auto`}
                                        border={`1px solid ${colors.Gr02}`}
                                        //radius={`8px`}
                                        of={`hidden auto`}
                                        bgc={`${colors.Gr01}`}
                                        wrap={`wrap`}
                                    >
                                        <Wrapper
                                                bb={`solid 1px ${colors.Gr02}`}
                                                //br={`solid 1px ${colors.Gr02}`}
                                                bgc={`${colors.Gr01}`}
                                                width={`100%`}
                                                //height={`45px`}
                                                position={`sticky`}
                                                style={{
                                                    top : 0,
                                                    left : 0,
                                                    zIndex : 10,
                                                    minHeight : 45
                                                }}
                                        >
                                                <Wrapper
                                                    width={`100%`}
                                                    //height={`45px`}
                                                    ai={`center`}
                                                    jc={`center`}
                                                    bgc={`${colors.Gr01}`}
                                                    style={{
                                                        minHeight : 45
                                                    }}
                                                >
                                                    <Text 
                                                        ta="center" 
                                                        size={(user == true) ? "2.7rem" : "2rem"} 
                                                        weight="700" 
                                                        color={colors.Gr05}
                                                    >
                                                        {item2.title}
                                                    </Text>
                                                </Wrapper>
                
                                        </Wrapper>    

                                        {
                                            (user == false) ?
                                                ChartTime?.map((item, idx) => (
                    
                                                    <Wrapper
                                                        key={`${idx2}_${idx}_time_chart`}
                                                        bb={`solid 1px ${colors.Gr02}`}
                                                        //br={`solid 1px ${colors.Gr02}`}
                                                        width={`100%`}
                                                        //height={`45px`}
                                                        style={{
                                                            minHeight : 45
                                                        }}
                                                        cursor={`pointer`}
                                                        onClick={() => {
                                                            if(!user) {
                                                                setManage(true);
                                                                setData({
                                                                    year : selectDay?.year,
                                                                    month : selectDay?.month > 9 ? selectDay?.month : `0${selectDay?.month}`,
                                                                    day : selectDay?.day > 9 ? selectDay?.day : `0${selectDay?.day}`,
                                                                    time : item.split(' ')[1],
                                                                    status : true,
                                                                    now : ChartCount[item],
                                                                    max : item2.max_capacity,
                                                                    crew : ChartUser[item],
                                                                    timeList : TimeList,
                                                                    ticketList : TicketList[item],
                                                                    facilityId : FacilityId,
                                                                    off : OffList[item],
                                                                    capacity : CapacityList[item]
                                                                })
                                                            }
                                                        }}
                                                        bgc={OffList[item] ? 'transparent' : colors.B10}
                                                    >
                                                        <Wrapper
                                                            width={`20%`}
                                                            //height={`45px`}
                                                            //br={`1px solid ${colors.Gr02}`}
                                                            ai={`center`}
                                                            jc={`center`}
                                                            style={{
                                                                minHeight : 45,
                                                                height : '100%'
                                                            }}
                                                        >
                                                            <Text 
                                                                ta="center" 
                                                                size="1.8rem" 
                                                                weight="500" 
                                                                color={colors.Gr05}
                                                            >
                                                                {item.split(' ')[1]}
                                                            </Text>
                                                        </Wrapper>
                        
                                                        <Wrapper
                                                            width={`80%`}
                                                            //height={`45px`}
                                                            //bl={`1px solid ${colors.Gr02}`}
                                                            padding={`2px 10px 2px 10px`}
                                                            wrap={`nowrap`}
                                                            gap={`5px`}
                                                            style={{
                                                                minHeight : 45
                                                            }}
                                                        >
                                                            <Wrapper
                                                                width={`100%`}
                                                                wrap={`wrap`}
                                                                jc={`flex-end`}
                                                                //br={`solid 1px ${colors.Gr02}`}
                                                                padding={`0 3px 0 0 `}
                                                                gap={`5px`}
                                                            >
                                                                {
                                                                    ChartUser[item]?.map((i, id) => (
                                                                        <Text 
                                                                            key={`${idx2}_${idx}_${id}_user_chart`}
                                                                            ta="right" 
                                                                            size="1.8rem" 
                                                                            weight="500" 
                                                                            color={colors.Gr05}
                                                                            jc={`flex-end`}
                                                                        >
                                                                            {(id == 0) ? `${ReturnName(i.name)}` : `, ${ReturnName(i.name)}`}
                                                                        </Text>
                                                                    ))
                                                                }
                                                                
                                                            </Wrapper>
                        
                                                            <Text 
                                                                ta="right" 
                                                                size="1.8rem" 
                                                                weight="500" 
                                                                width={`50px`}
                                                                height={`100%`}
                                                                color={colors.Gr05}
                                                                ai={`center`}
                                                                jc={`center`}
                                                            >
                                                                {`${ChartCount[item]}/${CapacityList[item]}`}
                                                            </Text>
                        
                                                        </Wrapper>
                                                    </Wrapper>
                                                ))
                                            :
                                                ChartTime?.map((item, idx) => {
                                                    const ThisTime = item.split(' ')[1];
                                                    const ThisHour = ThisTime.split(':')[0];

                                                    if(checkTime == ThisHour) {
                                                        return (
                                                            <Wrapper
                                                                key={`${idx}_time_chart`}
                                                                bb={`solid 1px ${colors.Gr02}`}
                                                                //br={`solid 1px ${colors.Gr02}`}
                                                                width={`100%`}
                                                                display={`flex`}
                                                                wrap={`wrap`}
                                                                //height={`45px`}
                                                                position={`relative`}
                                                                style={{
                                                                    minHeight : 45
                                                                }}
                                                                bgc={OffList[item] ? 'transparent' : colors.B10}
                                                                cursor={`pointer`}
                                                                onClick={() => {
                                                                    if(!user) { 
                                                                        setManage(true);
                                                                        setData({
                                                                            year : selectDay?.year,
                                                                            month : selectDay?.month > 9 ? selectDay?.month : `0${selectDay?.month}`,
                                                                            day : selectDay?.day > 9 ? selectDay?.day : `0${selectDay?.day}`,
                                                                            time : item.split(' ')[1],
                                                                            status : true,
                                                                            now : ChartCount[item],
                                                                            max : item2.max_capacity,
                                                                            crew : ChartUser[item],
                                                                            timeList : TimeList,
                                                                            ticketList : TicketList[item],
                                                                            facilityId : FacilityId,
                                                                            off : OffList[item],
                                                                            capacity : CapacityList[item]
                                                                        })
                                                                    }
                                                                }}
                                                            >

                                                                <Text 
                                                                    ta="center" 
                                                                    size="2.5rem" 
                                                                    weight="500" 
                                                                    color={colors.Bl07}
                                                                    style={{
                                                                        position : 'absolute',
                                                                        top : 0,
                                                                        right : 10
                                                                    }}
                                                                >
                                                                    {`${ChartCount[item]}/${CapacityList[item]}`}
                                                                </Text>

                                                                <Wrapper
                                                                    width={`100%`}
                                                                    //height={`45px`}
                                                                    ai={`center`}
                                                                    jc={`center`}
                                                                    style={{
                                                                        minHeight : 45,
                                                                        height : '100%',
                                                                        borderBottom : `1px solid ${colors.Gr02}`
                                                                    }}
                                                                >
                                                                    <Wrapper>
                                                                        <Text 
                                                                            ta="center" 
                                                                            size="3.3rem" 
                                                                            weight="600" 
                                                                            color={colors.Gr05}
                                                                        >
                                                                            {ThisTime}
                                                                        </Text>
                                                                    </Wrapper>
                                                                </Wrapper>
                                
                                                                <Wrapper
                                                                    width={`100%`}
                                                                    //height={`45px`}
                                                                    //bl={`1px solid ${colors.Gr02}`}
                                                                    wrap={`wrap`}
                                                                    gap={`7px`}
                                                                    style={{
                                                                        minHeight : 45
                                                                    }}
                                                                >
                                                                    {
                                                                        ChartUser[item]?.map((i, id) => {
                                                                            const DeadlineCheck = ReturnDeadline(i.date, i.end);

                                                                            return (
                                                                                <Wrapper
                                                                                    width={`100%`}
                                                                                    wrap={`wrap`}
                                                                                    jc={`center`}
                                                                                    gap={`5px`}
                                                                                    bb={`solid 1px ${colors.Gr01}`}
                                                                                    padding={`5px 0`}
                                                                                >
    
                                                                                    <Text 
                                                                                        key={`${idx}_${id}_chart`}
                                                                                        size="4.5rem" 
                                                                                        weight="600" 
                                                                                        color={colors.Bl07}
                                                                                        className={(DeadlineCheck == true) ? 'blink_effect' : ""}
                                                                                    >
                                                                                        {`${ReturnName(i.name)}`}
                                                                                        <Text 
                                                                                            size="4rem" 
                                                                                            weight="600" 
                                                                                            color={colors.Gr05}
                                                                                            style={{
                                                                                                marginLeft : 10
                                                                                            }}
                                                                                        >
                                                                                            {`(~${i.end})`}
                                                                                        </Text>
                                                                                    </Text>
                                                                                    
                                                                                </Wrapper>
                                                                            )
                                                                        })
                                                                    }
                                                                </Wrapper>
                                                            </Wrapper>
                                                        )
                                                    }
                                                })
                                        }
                                    </Wrapper>
                                )

                            })

                        }
                    </Wrapper> 
                )
            default :
                return (
                    <></>   
                )

        }

    } 

    return (
        <Container id="description_container">

            <FacilityHeader
                status={true}
                title={'시설 예약 현황'}
                available={false}
                statusHandler={() => console.log("신규 회원 등록")}
                backHandler={user}
            />

            <Wrapper
                jc="flex-start"
                ai="center"
                fd={`column`}
                width="100%"
                height={`calc((var(--vh, 1vh) * 100) - 40px)`}
                padding={`0 24px 10px 24px`}
                margin={`0 0 0 0`}
                gap={`10px`}
                bb={`1px solid ${colors.Gr02}`}
            >
                <Wrapper
                    width={`100%`}
                    height={`50px`}
                    jc={'center'}
                    ai={`center`}
                    position={`relative`}
                >
                    {user == false ?  
                        <Wrapper
                            margin={`0 3rem 0 0`}
                            cursor={`pointer`}
                            onClick={() => PrevDay()}
                        >
                            <Arrow style={{transform : "rotate(180deg)"}} width="40px" height="40px" fill={colors.Gr06} />
                        </Wrapper>
                    : <></>}

                    <Text 
                        ta="center" 
                        size="3rem" 
                        weight="600" 
                        lh="22px" 
                        ls="-0.28px" 
                        color={colors.Gr05}
                    >
                        {`
                            ${selectDay.year}.
                            ${(selectDay.month > 9) ? selectDay.month : `0${selectDay.month}`}.
                            ${(selectDay.day > 9) ? selectDay.day : `0${selectDay.day}`}
                        `}
                    </Text>

                    {user == false ?  
                        <Wrapper
                            margin={`0 0 0 3rem`}
                            cursor={`pointer`}
                            onClick={() => NextDay()}
                        >
                            <Arrow width="40px" height="40px" fill={colors.Gr06} />
                        </Wrapper>
                    : <></>}

                    {
                        ((width >= 700) && user == false )?
                            <Wrapper
                                position={`absolute`}
                                onClick={() => setUser(!user)}
                                padding={`5px 8px`}
                                radius={`8px`}
                                bgc={colors.Bl07}
                                cursor={`pointer`}
                                style={{
                                    top : 0,
                                    right : 0
                                }}
                            >
                                <Text 
                                    ta="center" 
                                    size="2.2rem" 
                                    weight="600" 
                                    lh="22px" 
                                    ls="-0.28px" 
                                    color={colors.White}
                                >
                                    {user ? '회원' : '파트너'}
                                </Text>
                            </Wrapper>
                        : <></>
                    }

                </Wrapper>

                {ListReturn()}

            </Wrapper>

            {loading && <Loading />}


            {
                manage ?
                    <FacilityManageModal
                        modal={manage}
                        setModal={setManage}
                        title={`시설 예약 관리`}
                        data={data}
                        GetData={GetData}
                        setLoading={setLoading}
                    />
                :
                <></>
            }

        </Container>
    );
};

export default FacilityReservation;

const Container = styled.section`
    height: calc(var(--vh, 1vh) * 100);
    overflow-x: hidden;
    width: 100%;

    .slick-slider {
        width: 100%;
        height: ${W * IMAGE_RATIO}px;
    }
`;