import React, { useState, useRef } from "react";
import styled from "styled-components";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

// tool
import { Android, Mobile } from "../../tool/device_check";

// style
import colors from "../../styles/colors";
import { W, H } from "../../styles/globalStyles";

// img
import LoginSplash01 from "../../icon/homePageMockup.png";
import LoginSplash02 from "../../icon/homePageMockup2.png";
import LoginSplash03 from "../../icon/homePageMockup3.png";
import LoginSplash04 from "../../icon/homePageMockup4.png";
import TopServiceIntro from "../../icon/partner_home_3.png";
import PartnerSNS from "../../icon/partner_home_sns.png";
import PartnerSEMOS from "../../icon/partner_home_semos.png";
import PartnerSetting from "../../icon/partner_home_setting.png";
import PartnerNotification from "../../icon/partner_home_notification.png";

const DesignSize = H / 812;
const MobileCheck = Mobile() || (W < 500);
const DesignWidth = (W > 1000) ? 1000 : W;

const SPLASH = [
    {
        title: "수수료 부담은 Zero",
        content: "예약 건수에 상관없이\n중개수수료가 무료에요",
        button : "중개수수료\n0원",
        img: LoginSplash02,
    },
    {
        title: "간편/카드/현장 결제 지원",
        content: "SNS, 카페에 홈페이지 링크를 연결하고\n온라인에서도 예약/결제를 받으세요",
        button : "전용 예약\n홈페이지",
        img: LoginSplash01,
    },
    {
        title: "놓치는 고객 없이",
        content: "전화를 제때 받지 못해도\n세모스 파트너에서 확인할 수 있어요",
        button : "상담 예약\n받기",
        img: LoginSplash03,
    },
    {
        title: "프로그램 진행에만 집중",
        content: "일정/장소/준비물/불포함사항을\n자동으로 카톡/문자로 전달해요",
        button : "자동 알림\n발송",
        img: LoginSplash04,
    },
];

function Contents() {

    let SliderRef = useRef();

    const [sliderSelect, setSliderSelect] = useState(0);
    const [plan, setPlan] = useState('연 결제');

  // SPLASH SLIDE SETTINGS
    const carouselSettings = {
        arrows: false,
        dots: false,
        //dotsClass : "slick-dots-login",
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        beforeChange: (current, next) => {
            setSliderSelect(next)
        }
        //autoplay: true,
        //autoplaySpeed: 2500,
    };


    return (
                <Container>

                <TopServiceIntroContainer>
                    <div className="ServiceIntroText">
                    <div style={{
                        display : 'inline-block',
                        width : '100%'
                    }}>
                        {`스포츠 강사/시설을 위한\n판매관리 솔루션 '세모스 파트너'`}
                    </div>
                    <div onClick={() => window.location.href = 'https://forms.gle/mpMXYxmcxyXetueB8'} className="ServiceJoin">등록 상담 신청하기</div>
                    </div>
        
                    <img className="ServiceIntroImage" src={LoginSplash01} alt={`스포츠 강사/시설을 위한 판매관리 솔루션 '세모스 파트너'`} />
                </TopServiceIntroContainer>

                <MiddleTitle>쉽고, 빠르게 고객을 만나보세요</MiddleTitle>

                <Divider h={MobileCheck ? 20 : 30} />

                <div>
                    <SplashContainer>
                    <SliderControlContainer2>
                        <SliderControlContainer>
                            {SPLASH.map((val, idx) => (
                                <SliderControl
                                    onClick={() => {
                                        setSliderSelect(idx);
                                        SliderRef.slickGoTo(idx);
                                    }}
                                    width={`auto`} active={(sliderSelect == idx) ? "active" : "disabled"} key={val.button}>
                                    <SliderControlText active={(sliderSelect == idx) ? "active" : "disabled"}>
                                        {val.button}
                                    </SliderControlText>
                                </SliderControl>
                            ))}
                        </SliderControlContainer>
                    </SliderControlContainer2>

                    <Slider ref={slider => {SliderRef = slider}} {...carouselSettings}>
                            {SPLASH.map((val) => (
                                <ServiceFunctionInfo key={val.title}>
                                    <div style={{
                                        width : MobileCheck ? '100%' : '60%',
                                        display : "inline-flex",
                                        alignItems : 'center',
                                        justifyContent : 'center',
                                    }}>
                                    <img style={{
                                        width : MobileCheck ? '80%' : '90%',
                                        display : "inline-block"
                                    }} alt={val.title} src={val.img} />
                                    </div>

                                    <div style={{
                                    width : MobileCheck ? '100%' : '40%',
                                    display : "inline-flex",
                                    alignItems : 'center',
                                    justifyContent : MobileCheck ? 'center' : 'space-between',
                                    flexWrap : "wrap",
                                    paddingLeft : MobileCheck ? '0' : '15px',
                                    marginTop : MobileCheck ? '10px' : '0'
                                    }}>
                                    <SplashTitle>{val.title}</SplashTitle>
                                    <SplashContent>{val.content}</SplashContent>
                                    </div>

                                </ServiceFunctionInfo>
                            ))}
                    </Slider>
                    </SplashContainer>

                    <Divider h={MobileCheck ? 40 : 50} />

                    <MiddleTitle>제공되는 다양한 추가 혜택</MiddleTitle>

                    <Divider h={MobileCheck ? 20 : 30} />

                    <ServiceEffectContainer>
                    <ServiceEffect>
                        <div className="ServiceEffectIcon">
                            <img src={PartnerSNS} alt={'SemosSNS'} />
                        </div>
                        
                        <div className="ServiceEffectTitle">
                        SNS 홍보 지원
                        </div>

                        <div className="ServiceEffectSubTitle">
                        {`공식 SNS 채널을 통해\n세모스를 이용하는\n유저들에게 자신/시설을\n알릴 수 있도록 도와드려요.`}
                        </div>
                    </ServiceEffect>

                    <ServiceEffect>
                        <div className="ServiceEffectIcon">
                            <img src={PartnerSEMOS} alt={'SemosUpload'} />
                        </div>
                        
                        <div className="ServiceEffectTitle">
                        세모스 상품 등록
                        </div>

                        <div className="ServiceEffectSubTitle">
                        {`세모스 앱/웹에 자동으로\n등록되어 신규 고객을\n만나실 수 있어요.`}
                        </div>
                    </ServiceEffect>

                    <ServiceEffect>
                        <div className="ServiceEffectIcon">
                            <img src={PartnerSetting} alt={'SemosSetting'} />
                        </div>
                        
                        <div className="ServiceEffectTitle">
                        초기 설정 지원
                        </div>

                        <div className="ServiceEffectSubTitle">
                        {`초기 설정에 어려움을\n겪지 않으시도록,\n담당 매니저가\n초기 설정을 도와드려요.`}
                        </div>
                    </ServiceEffect>

                    <ServiceEffect>
                        <div className="ServiceEffectIcon">
                            <img src={PartnerNotification} alt={'SemosNotification'} />
                        </div>
                        
                        <div className="ServiceEffectTitle">
                        기존 고객 알림 지원
                        </div>

                        <div className="ServiceEffectSubTitle">
                        {`세모스 유저가 아닌\n고객님들도 세모스에서\n파트너님을 찾을 수 있도록\n무료로 알림을 보내드려요.`}
                        </div>
                    </ServiceEffect>

                    </ServiceEffectContainer>

                    <Divider h={MobileCheck ? 40 : 50} />

                    <MiddleTitle>나에게 맞는 플랜을 선택해보세요.</MiddleTitle>

                    <Divider h={MobileCheck ? 20 : 30} />

                    <ServicePriceInfo>
                    <div style={{margin : MobileCheck ? '0 0 0 24px' :'0 0 0 0'}} className="PlanSelectorContainer">
                        <PlanSelectorButton onClick={() => setPlan('연 결제')} active={plan === '연 결제' ? "active" : "disabled"}>연 결제</PlanSelectorButton>
                        <PlanSelectorButton onClick={() => setPlan('월 결제')} active={plan === '월 결제' ? "active" : "disabled"}>월 결제</PlanSelectorButton>
                    </div>

                    {
                        (plan === '연 결제') ?
                        <>
                            <div style={{margin : MobileCheck ? '0 24px 20px 24px' :'0 20px 0 0'}} className="PlanSelectorContents">
                            <div className="PlanSelectorContentsTitle">베이직 플랜 (강사)</div>
                            <div className="PlanSelectorContentsPrice"><div className="PlanSelectorContentsSubPrice">(20% 할인)</div> 26,400원/월</div>

                            <PlanFuntionContainer>
                                <div className="PlanFuntionContent" >✅ 전용 홈페이지 제작</div>
                                <div className="PlanFuntionContent" >✅ 다양한 온라인 결제수단(카드/간편결제, 현장결제)</div>
                                <div className="PlanFuntionContent" >✅ 상담 예약 신청 받기</div>
                                <div className="PlanFuntionContent" >✅ 레슨, 이용권, 자격증, 국내/해외 투어 카테고리</div>
                                <div className="PlanFuntionContent" >✅ 카테고리별 맞춤 상품 등록</div>
                                <div className="PlanFuntionContent" >✅ 예약 받을 일정 설정</div>
                                <div className="PlanFuntionContent" >✅ 휴무 설정</div>
                            </PlanFuntionContainer>
                            </div>

                            <div className="PlanSelectorContents">
                            <div className="PlanSelectorContentsTitle" >프로 플랜 (시설)</div>
                            <div className="PlanSelectorContentsPrice"><div className="PlanSelectorContentsSubPrice">(20% 할인)</div> 61,600원~184,800원/월</div>

                            <PlanFuntionContainer>
                                <div className="PlanFuntionContent2" >🏃‍♂️ 회원 수 100인 이하 : 월 61,600원 </div>
                                <div className="PlanFuntionContent2" >🏃 회원 수 101인~200인 이하 : 월 123,200원 </div>
                                <div style={{marginBottom : '10px'}} className="PlanFuntionContent2" >🏃🏻‍♀️ 회원 수 200인 이상 : 월 184,800원 </div>

                                <div className="PlanFuntionContent" >✅ 전용 홈페이지 제작</div>
                                <div className="PlanFuntionContent" >✅ 다양한 온라인 결제수단(카드/간편결제, 현장결제)</div>
                                <div className="PlanFuntionContent" >✅ 상담 예약 신청 받기</div>
                                <div className="PlanFuntionContent" >✅ 레슨, 이용권, 자격증, 국내/해외 투어 카테고리</div>
                                <div className="PlanFuntionContent" >✅ 카테고리별 맞춤 상품 등록</div>
                                <div className="PlanFuntionContent" >✅ 예약 받을 일정 설정</div>
                                <div className="PlanFuntionContent" >✅ 휴무 설정</div>
                                <div className="PlanFuntionContent" >🆙 시설 출입관리</div>
                                <div className="PlanFuntionContent" >🆙 실시간 이용현황 대시보드</div>
                                <div className="PlanFuntionContent" >🆙 회원 신청서 출력</div>
                                <div className="PlanFuntionContent" >🆙 회원 등록 및 관리</div>
                            </PlanFuntionContainer>
                            </div>
                        </>
                        : 
                        <>
                            <div style={{margin : MobileCheck ? '0 24px 20px 24px' :'0 20px 0 0'}} className="PlanSelectorContents">
                            <div className="PlanSelectorContentsTitle">베이직 플랜 (강사)</div>
                            <div className="PlanSelectorContentsPrice">33,000원/월</div>

                            <PlanFuntionContainer>
                                <div className="PlanFuntionContent" >✅ 전용 홈페이지 제작</div>
                                <div className="PlanFuntionContent" >✅ 다양한 온라인 결제수단(카드/간편결제, 현장결제)</div>
                                <div className="PlanFuntionContent" >✅ 상담 예약 신청 받기</div>
                                <div className="PlanFuntionContent" >✅ 레슨, 이용권, 자격증, 국내/해외 투어 카테고리</div>
                                <div className="PlanFuntionContent" >✅ 카테고리별 맞춤 상품 등록</div>
                                <div className="PlanFuntionContent" >✅ 예약 받을 일정 설정</div>
                                <div className="PlanFuntionContent" >✅ 휴무 설정</div>
                            </PlanFuntionContainer>
                            </div>

                            <div className="PlanSelectorContents">
                            <div className="PlanSelectorContentsTitle" >프로 플랜 (시설)</div>
                            <div className="PlanSelectorContentsPrice">77,000원~231,000원/월</div>

                            <PlanFuntionContainer>

                                <div className="PlanFuntionContent2" >🏃‍♂️ 회원 수 100인 이하 : 월 77,000원 </div>
                                <div className="PlanFuntionContent2" >🏃 회원 수 101인~200인 이하 : 월 154,000원 </div>
                                <div style={{marginBottom : '10px'}} className="PlanFuntionContent2" >🏃🏻‍♀️ 회원 수 200인 이상 : 월 231,000원 </div>

                                <div className="PlanFuntionContent" >✅ 전용 홈페이지 제작</div>
                                <div className="PlanFuntionContent" >✅ 다양한 온라인 결제수단(카드/간편결제, 현장결제)</div>
                                <div className="PlanFuntionContent" >✅ 상담 예약 신청 받기</div>
                                <div className="PlanFuntionContent" >✅ 레슨, 이용권, 자격증, 국내/해외 투어 카테고리</div>
                                <div className="PlanFuntionContent" >✅ 카테고리별 맞춤 상품 등록</div>
                                <div className="PlanFuntionContent" >✅ 예약 받을 일정 설정</div>
                                <div className="PlanFuntionContent" >✅ 휴무 설정</div>
                                <div className="PlanFuntionContent" >🆙 시설 출입관리</div>
                                <div className="PlanFuntionContent" >🆙 실시간 이용현황 대시보드</div>
                                <div className="PlanFuntionContent" >🆙 회원 신청서 출력</div>
                                <div className="PlanFuntionContent" >🆙 회원 등록 및 관리</div>
                            </PlanFuntionContainer>
                            </div>
                        </>
                    }

                    </ServicePriceInfo>

                    <Divider h={MobileCheck ? 40 : 50} />


                    <div style={{
                        width : '100%', 
                        display : 'inline-flex', 
                        alignItems : "center", 
                        justifyContent : "center",
                        backgroundColor : colors.Bl04
                    }}  
                    >

                    </div>

                </div>

                </Container>
    );
}

export default Contents;

const Container = styled.div`
    width: 100%;
    max-width : 1000px;
    background-color: ${colors.White};
    overflow-x: hidden;
    overflow-y: hidden;
    display: inline-block;
    padding-bottom: 100px;
`;

const Divider = styled.div`
    height: ${({ h }) => h * DesignSize}px;
    display : inline-block;
    width : 100%;
`;

const SplashContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 0 18px;
    flex-wrap : wrap;

    .slick-slider {
        //max-width: 35rem;
        width: 100%;

        img {
          width: 100%;
        }
    }

    .slick-dots {
        li {
          width: 16px;
          height: 16px;

          button {
              width: 100%;
              height: auto;
          }
          button::before {
              color: ${colors.Gr02};
              font-size: 10px;
          }
        }
        .slick-active {
          button::before {
              color: ${colors.Bl07};
          }
        }
    }
`;

const SplashTitle = styled.h2`
  margin: ${MobileCheck ? '0 0 10px 0' : '0 0 20px 0'};
  color: ${colors.Bl07};
  text-align: ${MobileCheck ? 'center' : 'left'};
  line-height: 142%;
  letter-spacing: 0.02em;
  font-size: ${MobileCheck ? '20px' : '30px'};
  font-weight: 700;
  width : 100%;
`;

const SplashContent = styled.p`
  white-space: pre-wrap;
  text-align: ${MobileCheck ? 'center' : 'left'};
  color: ${colors.Gr05};
  line-height: 142%;
  font-size: ${MobileCheck ? '16px' : '24px'};
  font-weight: 600;
  width : 100%;
`;

const CustomPartner = styled.p`
  white-space: pre-wrap;
  text-align: center;
  color: ${colors.White};
  font-size: 14px;
  font-weight: 600;
`;

const TooltipContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-end;
  width: 100%;
  margin: auto;
  padding : 0 24px;
  position: absolute;
  bottom : 105px;
  z-index : 10;
  right : 0;
`;

const TooltipContainer2 = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin: auto;
  padding : 0 24px;
  position: absolute;
  bottom : 90px;
  z-index : 10;
  right : 0;
`;

const TooltipBox = styled.div`
  padding: 12px;
  background-color: ${colors.White};
  border: 1px solid ${colors.Gr02};
  border-radius: 4px;
  filter: drop-shadow(0px 3px 16px rgba(0, 0, 0, 0.05))
    drop-shadow(0px 1px 11px rgba(0, 0, 0, 0.03));
`;

const TooltipText = styled.span`
  color: ${colors.Gr06};
  text-align: center;
  line-height: 142%;
  letter-spacing: -0.02em;
  font-size: ${MobileCheck ? '14px' : '18px'};
  font-weight: 500;
`;

const Triangle = styled.div`
    width: 10px;
    height: 10px;
    transform: rotate(45deg);
    border: 1px solid transparent;
    border-right-width: 1px;
    border-right-color: ${colors.Gr02};
    border-bottom-width: 1px;
    border-bottom-color: ${colors.Gr02};
    background-color: ${colors.White};
    position: absolute;
    z-index: 2;
    bottom: -5px;
    left: calc(100% - 35px);
`;

const Triangle2 = styled.div`
    width: 10px;
    height: 10px;
    transform: rotate(45deg);
    border: 1px solid transparent;
    border-right-width: 1px;
    border-right-color: ${colors.Gr02};
    border-bottom-width: 1px;
    border-bottom-color: ${colors.Gr02};
    background-color: ${colors.White};
    position: absolute;
    z-index: 2;
    bottom: -5px;
    left: 50%;
`;

const Button = styled.div`
    width: 56px;
    height: 56px;
    border-radius: 50%;
    font-size: 16px;
    line-height: 22.72px;
    font-weight: 500;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
`;

const LoginImg = styled.img`
  width: 28px;
  height: 28px;
`;

const KakaoButton = styled(Button)`
    background-color: ${colors.Kakao};
    color: ${colors.Gr06};
`;

const SemosButton = styled(Button)`
    background-color: ${colors.Bl07};
    color: ${colors.Bl07};
`;

const GoogleButton = styled(Button)`
    background-color: ${colors.White};
    border: 1px solid ${colors.Gr02};
    color: ${colors.Gr06};
`;

const NaverButton = styled(Button)`
    background-color: ${colors.Naver};
    color: ${colors.White};
`;

const SliderControlContainer = styled.div`
    width : auto;
    display : inline-flex;
    gap : 0px;
    //max-width : 400px;
    justify-content: center;
`;

const SliderControlContainer2 = styled.div`
    width : auto;
    display : inline-flex;
    justify-content: center;
    margin-bottom : ${MobileCheck ? '20px' : '30px'};
    border : 2px solid ${colors.Bl02};
    border-radius: ${MobileCheck ? '8px' : '31px'};
    overflow : hidden;
`;

const SliderControl = styled.div`
    width: ${({ width }) => `${width}`};
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: ${MobileCheck ? '8px' : '30px'};
    padding: ${MobileCheck ? '8px 12px' : '10px 28px'};
    background-color: ${({ active }) => (active === "active" ? colors.Bl02 : colors.White)};
`;

const SliderControlText = styled.div`
    white-space: pre-wrap;
    text-align: center;
    color: ${({ active }) => (active === "active" ? colors.Bl07 : colors.Bl05)};
    font-size: ${MobileCheck ? '16px' : '24px'};
    font-weight: 600;
`;

const Label = styled.label`
    display : inline-flex;
`;

const LoginButton = styled.div`
  display : inline-flex;
  width: 56px;
  height: 56px;
  align-items: center;
  justify-content : center;
  border-radius : 24px;
  background-color: ${colors.Bl07};
  cursor : pointer;
`;

const LoginButtonText = styled.div`
  white-space: pre-wrap;
  text-align: center;
  font-size: ${MobileCheck ? '13px' : '15px'};
  font-weight: 600;
  color : ${colors.White};
`;

const TopServiceIntroContainer = styled.div`
  width : 100%;
  background-image : url(${TopServiceIntro});
  background-size: cover;
  background-position : center;
  height : ${DesignWidth * (890 / 1920)}px;
  min-height : 380px;
  position : relative;
  margin-bottom : 40px;
  position : relative;


  .ServiceIntroText {
    white-space: pre-wrap;
    text-align: left;
    color: ${colors.White};
    font-size: ${MobileCheck ? '24px' : '30px'};
    font-weight: 600;
    position : absolute;
    top : 20px;
    left : 20px;
    text-shadow: 2px 3px 5px ${colors.B50};
    display : inline-flex;
    width : 100%;
    flex-wrap : wrap;
  }
  
  .ServiceJoin {
    background-color: ${colors.Bl02};
    //border : 1px solid ${colors.Bl07};
    color: ${colors.Bl07};
    font-size: ${MobileCheck ? '12px' : '18px'};
    text-shadow: none;
    font-weight: 700;
    padding : 8px 12px;
    border-radius : 8px;
    display : inline-block;
    cursor : pointer;
    margin-top : 10px;
  }

  .ServiceIntroImage {
    width : ${MobileCheck ? '280px' : '380px'};
    display : inline-block;
    position : absolute;
    bottom : 5px;
    left : ${MobileCheck ? '50%' : '50%'};
    transform : translate(-50%, 0);
  }
`;

const ServiceFunctionInfo = styled.div`
  width : 100%;
  display : inline-flex !important;
  justify-content : center;
  align-items : center;
  flex-wrap: ${MobileCheck ? 'wrap' : 'nowrap'};
`;

const MiddleTitle = styled.div`
  white-space: pre-wrap;
  text-align: center;
  font-size: ${MobileCheck ? '20px' : '30px'};
  font-weight: 600;
  color : ${colors.Bl07};
  width : 100%;
`;

const ServiceEffectContainer = styled.div`
  width : 100%;
  display : inline-flex;
  flex-wrap : wrap;
  gap : ${MobileCheck ? '10px' : '20px'};
  justify-content : center;
  align-items: flex-start;
`;

const ServiceEffect = styled.div`
  width : calc(100% / ${MobileCheck ? 2.5 : 5});
  display : inline-flex;
  flex-wrap : wrap;
  justify-content : center;
  align-items: center;

  .ServiceEffectIcon {
    width : 100%;
    height : ${DesignWidth / (MobileCheck ? 2.5 : 5)}px;
    display : inline-flex;
    justify-content : center;
    align-items: center;
    background-color : ${colors.Bl01};
    border : 1px solid ${colors.Bl03};
    margin-bottom : ${MobileCheck ? '10px' : '15px'};
    border-radius : 10%;

    img {
      width : 60%;
      display : inline-block;
    }
  }

  .ServiceEffectTitle {
    white-space: pre-wrap;
    text-align: left;
    font-size: ${MobileCheck ? '16px' : '24px'};
    font-weight: 600;
    color : ${colors.Bl07};
    width : 100%;
    margin-bottom : 10px;
  }

  .ServiceEffectSubTitle {
    white-space: pre-wrap;
    text-align: left;
    font-size: ${MobileCheck ? '12px' : '18px'};
    font-weight: 500;
    color : ${colors.Gr05};
    width : 100%;
  }
`;

const ServicePriceInfo = styled.div`
  width : 100%;
  display : inline-flex !important;
  justify-content : space-between;
  align-items : flex-start;
  flex-wrap : wrap;

  .PlanSelectorContainer {
    display : inline-flex;
    gap : 15px;
    padding-bottom : 20px;
    align-items : center;
    justify-content: flex-start;
    width : 100%;
  }

  .PlanSelectorContents {
    display : inline-flex;
    border-radius : 8px; 
    border : 1px solid ${colors.Bl07};
    padding : ${MobileCheck ? '12px' : '18px'};
    flex-wrap : wrap;
    max-width : ${MobileCheck ? '100%' : '48%'};
    margin: ${MobileCheck ? '0 24px' : '0'};
  }

  .PlanSelectorContentsTitle {
    white-space: pre-wrap;
    text-align: center;
    font-size: ${MobileCheck ? '16px' : '24px'};
    font-weight: 600;
    color : ${colors.Gr05};
    width : 100%;
    margin-bottom : ${MobileCheck ? '16px' : '24px'};
  }

  .PlanSelectorContentsPrice {
    white-space: pre-wrap;
    text-align: center;
    font-size: ${MobileCheck ? '16px' : '21px'};
    font-weight: 600;
    color : ${colors.Bl07};
    width : 100%;
    margin-bottom : ${MobileCheck ? '10px' : '15px'};
  }

  .PlanSelectorContentsSubPrice {
    font-size: ${MobileCheck ? '12px' : '15px'};
    font-weight: 500;
    color : ${colors.Red03};
    display : inline-block;
    margin-right : 5px; 
  }
`;

const PlanSelectorButton = styled.div`
  background-color: ${({ active }) => (active === "active" ? colors.Bl02 : colors.White)};
  color: ${({ active }) => (active === "active" ? colors.Bl07 : colors.Bl05)};
  border : 1px solid ${({ active }) => (active === "active" ? colors.Bl05 : colors.Gr02)};
  border-radius : 8px; 
  padding: ${MobileCheck ? '6px 9px' : '8px 12px'};
  font-size: ${MobileCheck ? '16px' : '24px'};
  cursor : pointer;
  font-weight: 600;
`;

const PlanFuntionContainer = styled.div`
  width : 100%;
  flex-wrap : wrap;
  gap : 10px;
  display : inline-flex;
  padding-top : ${MobileCheck ? '10px' : '15px'};
  border-top : 2px solid ${colors.Gr01};

  .PlanFuntionContent {
    white-space: pre-wrap;
    text-align: left;
    font-size: ${MobileCheck ? '14px' : '21px'};
    font-weight: 500;
    color : ${colors.Gr05};
    width : 100%;
  }

  .PlanFuntionContent2 {
    white-space: pre-wrap;
    text-align: left;
    font-size: ${MobileCheck ? '14px' : '21px'};
    font-weight: 600;
    color : ${colors.Gr05};
    width : 100%;
  }
`;

const HeaderServiceJoin = styled.div`
    background-color: ${colors.Bl02};
    color: ${colors.Bl07};
    font-size: ${MobileCheck ? '12px' : '14px'};
    font-weight: 700;
    padding : 6px 9px;
    border-radius : 6px;
    display : inline-block;
    cursor : pointer;
    position : absolute;
    top : 50%;
    right : ${MobileCheck ? '8px' : '0'};
    transform : translate(0, -50%);
`