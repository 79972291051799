import React, { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import Modal from "react-modal";
import axios from "axios";

//component 
import { GetOne } from "../../tool/localstorage";
import CrewScheduleManage from "./CrewScheduleManage";

// style
import colors from "../../styles/colors";
import { Wrapper, Input, Text, AbsoluteWrapper } from "../../components/layouts/layoutStorage";

// img
import { ReactComponent as Arrow } from "../../resource/icon/arrow.svg";
import { ReactComponent as Search } from "../../icon/search.svg";
import plusBlue from "../../icon/plusBlue.png";
import { ReactComponent as Close } from "../../resource/icon/close.svg";

// default 
const MaxCapacityList = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 20];

const FacilityManageModal = ({
    modal,
    setModal,
    title,
    data,
    setLoading,
    GetData
}) => {

    const MaxRef = useRef();
    const UserRef = useRef();

    const [startTime, setStartTime] = useState();
    const [endTime, setEndTime] = useState();
    const [user, setUser] = useState([]);
    const [userD, setUserD] = useState([]);
    const [ticket, setTicket] = useState([]);
    const [addUser, setAddUser] = useState();
    const [status, setStatus] = useState(true);
    const [maxCapacity, setMaxCapacity] = useState(4);

    const [dropdown, setDropdown] = useState(false);
    const [dropdownU, setDropdownU] = useState(false);

    const [selectUser, setSelectUser] = useState();
    const [ticketModal, setTicketModal] = useState(false);

    const [focus, setFocus] = useState(false);
    const [search, setSearch] = useState();
    
    const maxCapacityHandleClose = (e) => {
        if (dropdown && (!MaxRef.current || !MaxRef.current.contains(e.target))) {
            setDropdown(false);
        }
    };

    const UserHandleClose = (e) => {
        if (dropdownU && (!UserRef.current || !UserRef.current.contains(e.target))) {
            setDropdownU(false);
        }
    };

    const ChangeSchedule = () => {
        setLoading(true);

        const Year = data?.year;
        const Month = data?.month;
        const Day = data?.day;

        var token = GetOne("token");

        axios
        .post("https://ai.semos.kr/semos_partner/v3/activity/Crew", {
            mode: "change_facility_schedule",
            token: token,
            crew: user,
            delete_crew : userD,
            status: status,
            max_capacity: maxCapacity,
            facility_id: data.facilityId,
            date : `${Year}.${Month}.${Day}`,
            start : startTime,
            end : endTime
        }, {
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
            },
        })
        .then((data) => {
            var Value = data.data;
            var Status = Value.status;
            var StatusMessage = Value.status_message;

            if (Status == "success") {
                window.alert(StatusMessage);
                setLoading(false);
                GetData();
            } else {
                window.alert(StatusMessage);
                setLoading(false);
            }
        });
    }

    const CrewSearch = () => {

        setLoading(true);

        var token = GetOne("token");

        const frm = new FormData();

        frm.append("mode", "crew_add_search");
        frm.append("token", token);
        frm.append("date", `${data?.year}.${data?.month}.${data?.day}`);
        frm.append("start", startTime);
        frm.append("end", endTime);
        frm.append("search", search);

        axios
        .post("https://ai.semos.kr/semos_partner/v3/activity/Crew", frm, {
            headers: { "Content-Type": "multipart/form-data" },
        })
        .then((data) => {
            var Value = data.data;
            var Status = Value.status;
            var StatusMessage = Value.status_message;
            var List = Value.list;

            if (Status == "success") {
                setLoading(false);
                setAddUser(List);
            } else {
                window.alert(StatusMessage);
                setLoading(false);
            }
        });

    }

    const SelectTicket = (item) => {
        setSelectUser(item);
        setTicketModal(true);
    }

    const AddTicket = (item) => {

        let UserList = user;
        let TicketList = ticket;
        const User = selectUser;
        const TicketData = item;

        const Valid = ticket.indexOf(TicketData.id) == -1;

        if(Valid) {
            const TicketSchedule = {
                ticket_id : TicketData.id,
                title : TicketData.title,
                name : User.name,
                user_id : User.user_id,
                date : User.date,
                start : User.start,
                end : User.end,
                phone : User.phone,
                use_id : "new",
                type : "ticket"
            };

            UserList.push(TicketSchedule);
            TicketList.push(TicketData.id);

            setUser(UserList);
            setTicket(TicketList);
            setTicketModal(false);
            setSelectUser();
        } else {
            window.alert('이미 등록된 시설 예약입니다.\n다른 이용권을 선택해주세요!')
        }
    }
    
    const DeleteTicket = (item, idx) => {

        let UserList = [...user];
        let TicketList = [...ticket];
        let DeleteList = [...userD];

        const Valid = ticket.indexOf(item.ticket_id);

        UserList.splice(idx, 1);
        TicketList.splice(Valid, 1);

        setUser(UserList);
        setTicket(TicketList);

        if(item.use_id != 'new') {
            DeleteList.push(item)
            setUserD(DeleteList)
        }
    }

    useEffect(() => {
        const Year = data?.year;
        const Month = data?.month;
        const Day = data?.day;

        const SelectDate = new Date(`${Year}-${Month}-${Day} ${data?.time}`);
        SelectDate.setHours(SelectDate.getHours() + 1);

        const Hour = SelectDate.getHours() > 9 ? SelectDate.getHours() : `0${SelectDate.getHours()}`;
        const Minunte = SelectDate.getMinutes() > 9 ? SelectDate.getMinutes()  : `0${SelectDate.getMinutes()}`;

        setStartTime(data?.time);
        setEndTime(`${Hour}:${Minunte}`);
        setUser(data?.crew);
        setTicket(data?.ticketList);
        setStatus(data?.off);
        setMaxCapacity(data?.capacity);
    }, [data]);

    useEffect(() => {
        window.addEventListener("click", maxCapacityHandleClose);
    
        return () => {
            window.removeEventListener("click", maxCapacityHandleClose);
        };
    }, [dropdown]);

    useEffect(() => {
        window.addEventListener("click", UserHandleClose);
    
        return () => {
            window.removeEventListener("click", UserHandleClose);
        };
    }, [dropdownU]);

    return (
        <Modal
            isOpen={modal}
            onRequestClose={() => setModal(false)}
            contentLabel="Alert Modal"
            style={customStyles}
        >
            <Container>

                <AffiliateModalLayout>
                    <Wrapper
                        width={`100%`}
                        margin={`0 0 16px 0`}
                        jc={`center`}
                    >   
                        <Text 
                            width={`100%`} 
                            size="2rem" 
                            weight="700" 
                            lh="24px" 
                            ls="-0.32px" 
                            color={colors.Gr05}
                            ta={`center`}
                        >
                            {title}
                        </Text>
                    </Wrapper>      

                    <Wrapper
                        width={`100%`}
                        margin={`0 0 8px 0`}
                        jc={`space-between`}
                    >   
                        <Text 
                            size="1.8rem" 
                            weight="600" 
                            lh="24px" 
                            ls="-0.32px" 
                            color={colors.Gr05}
                            style={{
                                marginRight : 8
                            }}
                        >
                            {`${data?.year}년 ${data?.month}월 ${data?.day}일 ${startTime} ~ ${endTime}`}
                        </Text>

                    </Wrapper>   

                    <Wrapper
                        width={`100%`}
                        margin={`0 0 8px 0`}
                        jc={`space-between`}
                        ai={`center`}
                    >   
                        <Text 
                            size="1.8rem" 
                            weight="600" 
                            lh="24px" 
                            ls="-0.32px" 
                            color={colors.Gr05}
                            style={{
                                marginRight : 8
                            }}
                        >
                            {`예약 상태 :`}
                        </Text>

                        <Wrapper
                            margin={`0 0 0 3px`}
                            jc={`space-between`}
                            ai={`center`}
                        >   
                            <Wrapper
                                margin={`0 0 0 0`}
                                jc={`center`}
                                ai={`center`}
                                padding={`3px 5px`}
                                radius={`8px`}
                                border={`2px solid ${status ? colors.Bl07 : colors.Gr03}`}
                                onClick={() => setStatus(true)}
                                cursor={`pointer`}
                            >   
                                <Text 
                                    size="1.4rem" 
                                    weight="600" 
                                    lh="24px" 
                                    ls="-0.32px" 
                                    color={`${status ? colors.Bl07 : colors.Gr03}`}
                                >
                                    {`가능`}
                                </Text>
                            </Wrapper>  

                            <Wrapper
                                margin={`0 0 0 5px`}
                                jc={`space-between`}
                                ai={`center`}
                                padding={`3px 5px`}
                                radius={`8px`}
                                border={`2px solid ${!status ? colors.Red03 : colors.Gr03}`}
                                onClick={() => setStatus(false)}
                                cursor={`pointer`}
                            >   
                                <Text 
                                    size="1.4rem" 
                                    weight="600" 
                                    lh="24px" 
                                    ls="-0.32px" 
                                    color={`${!status ? colors.Red03 : colors.Gr03}`}
                                >
                                    {`불가능`}
                                </Text>
                            </Wrapper>  
                        </Wrapper>  
                    </Wrapper>   

                    <Wrapper
                        width={`100%`}
                        margin={`0 0 8px 0`}
                        jc={`space-between`}
                        ai={`center`}
                    >   
                        <Text 
                            size="1.8rem" 
                            weight="600" 
                            lh="24px" 
                            ls="-0.32px" 
                            color={colors.Gr05}
                            style={{
                                marginRight : 8
                            }}
                        >
                            {`최대 인원 :`}
                        </Text>

                        <Wrapper
                            ref={MaxRef}
                            padding="8px"
                            cursor="pointer"
                            position="relative"
                            onClick={(e) => {
                                e.stopPropagation();
                                setDropdown(!dropdown);
                            }}
                            height="48px"
                            radius="8px"
                            width="80px"
                            bgc={colors.White}
                            border={`1px solid ${colors.Gr02}`}
                            jc="space-between"
                        >
                                <Text size="14px" weight="500" lh="22px" ls="-0.28px" color={colors.Gr05}>
                                    {maxCapacity}인
                                </Text>

                                <Arrow
                                    width="16px"
                                    height="16px"
                                    fill={colors.Gr04}
                                    style={{ 
                                        transition: "all ease 0.5s", 
                                        transform: dropdown ? "rotate(-90deg)" : "rotate(90deg)" 
                                    }}
                                />

                                {dropdown && (
                                    <AbsoluteWrapper
                                        maxHeight="10rem"
                                        z={1}
                                        fd="column"
                                        width="100%"
                                        top="61px"
                                        left="0"
                                        bgc={colors.White}
                                        border={`1px solid ${colors.Gr02}`}
                                        radius="8px"
                                        of="auto"
                                    >
                                        {MaxCapacityList.map((x, idx) => (
                                            <DropDown
                                                active={x == maxCapacity}
                                                key={`${idx}_capacity`}
                                                last={idx + 1 === MaxCapacityList.length}
                                                onClick={() => {
                                                    setMaxCapacity(x);
                                                }}
                                            >
                                                {x}
                                            </DropDown>
                                        ))}
                                    </AbsoluteWrapper>
                                )}
                        </Wrapper>
                    </Wrapper>  

                    <Wrapper
                        width={`100%`}
                        margin={`0 0 5px 0`}
                        jc={`space-between`}
                        ai={`center`}
                    >   
                        <Text 
                            size="1.8rem" 
                            weight="600" 
                            lh="24px" 
                            ls="-0.32px" 
                            color={colors.Gr05}
                            style={{
                                marginRight : 8
                            }}
                        >
                            {`예약 회원 :`}
                        </Text> 

                    </Wrapper>  

                    <Wrapper
                        width={`100%`}
                        margin={`0 0 5px 0`}
                        fd={`column`}
                        jc={`flex-start`}
                        ai={`center`}
                    >   

                        <Wrapper 
                            width="100%" 
                            padding="3px 5px"
                            jc="space-between"
                            ai="center"
                            border={`2px solid ${focus ? colors.Bl07 : colors.Gr04}`}
                            radius={`8px`}
                            ref={UserRef}
                            position={`relative`}
                        >
                            <Input 
                                onFocus={() => setFocus(true)}
                                onBlur={() => setFocus(false)}
                                onKeyPress={(e) => {
                                    if(e.key == "Enter" || e.keyCode == "13") {
                                        CrewSearch();
                                        setDropdownU(true);
                                    }
                                }}
                                value={search}
                                onChange={(e) => setSearch(e.target.value)}
                                width="calc(100% - 3.5rem)"
                                size="1.6rem"
                                weight="600"
                                lh="22px"
                                ls="-0.28px"
                                maxLength={15}
                                phColor={colors.Gr03}
                                placeholder="예약 회원 추가 (회원 검색)"
                            />
                            <Search  
                                onClick={() => {
                                    CrewSearch();
                                    setDropdownU(true);
                                }}
                                fill={colors.Gr03} 
                                width="2.6rem" 
                                height="2.6rem" 
                            />

                            {
                                dropdownU ?
                                    <Wrapper 
                                        width="100%" 
                                        padding="5px"
                                        jc="flex-start"
                                        ai="center"
                                        border={`2px solid ${colors.Gr04}`}
                                        radius={`8px`}
                                        position={`absolute`}
                                        wrap={`wrap`}
                                        bgc={`${colors.White}`}
                                        style={{
                                            overflowX : "hidden",
                                            overflowY : "auto",
                                            maxHeight : 180,
                                            top : 40,
                                            left : 0,
                                            zIndex : 10
                                        }}
                                    >   {
                                            addUser?.map((x, idx) => (
                                                <Wrapper
                                                    width={`100%`}
                                                    margin={`0 0 3px 0`}
                                                    jc={`space-between`}
                                                    ai={`center`}
                                                    bb={`1px solid ${colors.Gr04}`}
                                                    padding={`2px 0`}
                                                    key={`${idx}_cres_add`}
                                                    cursor={`pointer`}
                                                >  
                                                    <Text 
                                                        size="1.6rem" 
                                                        weight="600" 
                                                        lh="24px" 
                                                        ls="-0.32px" 
                                                        color={colors.Gr05}
                                                        style={{
                                                            marginRight : 5
                                                        }}
                                                    >
                                                        {`${x.name} | ${x.phone}`}
                                                    </Text> 
        
                                                    <NewCrew
                                                        onClick={() => SelectTicket(x)}
                                                    >
                                                        <img src={plusBlue} />
                                                    </NewCrew>
                                                </Wrapper>
                                                ))
                                        }
                                    </Wrapper>
                                : <></>
                            }
                        </Wrapper>

                    </Wrapper>  

                    <Wrapper
                        width={`100%`}
                        height={`250px`}
                        margin={`0 0 0 0`}
                        jc={`flex-start`}
                        ai={`flex-start`}
                        padding={`3px`}
                        radius={`8px`}
                        border={`1px solid ${colors.Gr02}`}
                        wrap={`wrap`}
                        fd={`column`}
                    >  
                        {
                            user?.map((item, id) => (
                                <CrewScheduleManage
                                    item={item}
                                    id={id}
                                    time_list={data.timeList}
                                    setUser={setUser}
                                    user={user}
                                    DeleteTicket={DeleteTicket}
                                />
                            ))
                        }
                    </Wrapper>  

                    <Wrapper
                        onClick={() => ChangeSchedule()}
                        jc="center"
                        ai="center"
                        width="calc(100%)"
                        height="40px"
                        cursor={"pointer"}
                        radius="8px"
                        bgc={colors.Bl07}
                        margin={`15px 0 0 0`}
                    >
                        <Text 
                            ta="center" 
                            size="2rem" 
                            weight="600" 
                            lh="22px" 
                            ls="-0.28px" 
                            color={colors.White}
                        >
                            {`저장`}
                        </Text>
                    </Wrapper>


                </AffiliateModalLayout>
            </Container>
                            
            {
                ticketModal ?
                    <Wrapper
                        width={`100%`}
                        height={`100%`}
                        jc={`center`}
                        ai={`center`}
                        position={`fixed`}
                        style={{
                            top : 0,
                            right : 0,
                            zIndex : 200
                        }}
                        bgc={`${colors.B10}`}
                        radius={`8px`}
                    >  
                        <Wrapper
                            width={`100%`}
                            padding={`27px 15px 5px 15px`}
                            bgc={`${colors.White}`}
                            radius={`8px`}
                            style={{
                                minHeight : 200
                            }}
                            position={`relative`}
                            wrap={`wrap`}
                        >
                            {
                                selectUser?.ticket?.map((x2, idx2) => (
                                    <Wrapper
                                        key={`${idx2}_ticket`}
                                        width={`100%`}
                                        jc={`space-between`}
                                        ai={`center`}
                                        bb={`1px solid ${colors.Gr02}`}
                                        margin={`0 0 3px 0`}
                                        padding={`0 0 3px 0`}
                                    >
                                        <Text 
                                            size="1.6rem" 
                                            weight="600" 
                                            lh="22px" 
                                            ls="-0.28px" 
                                            color={colors.Gr05}
                                            width={`100%`}
                                        >
                                            {x2.title}
                                        </Text>

                                        <Wrapper
                                            jc={`center`}
                                            ai={`center`}
                                            bgc={`${colors.Bl07}`}
                                            radius={`8px`}
                                            padding={`3px 5px`}
                                            cursor={`pointer`}
                                        >
                                            <Text 
                                                size="1.6rem" 
                                                weight="600" 
                                                lh="22px" 
                                                ls="-0.28px" 
                                                color={colors.White}
                                                cursor={`pointer`}
                                                onClick={() => AddTicket(x2)}
                                            >
                                                {`선택`}
                                            </Text>
                                        </Wrapper>
                                    </Wrapper>
                                ))
                            }
                            <Close 
                                fill={colors.Gr06} 
                                width="2.6rem" 
                                height="2.6rem"
                                style={{ 
                                    cursor: "pointer",
                                    position: "absolute",
                                    top : 7,
                                    right: 7
                                }} 
                                onClick={() => setTicketModal(false)}
                            />

                            <Text 
                                size="1.8rem" 
                                weight="600" 
                                style={{ 
                                    position: "absolute",
                                    top : 5,
                                    left: 5,
                                    color : colors.Gr06
                                }} 
                            >
                                {`${selectUser?.name}님의 이용권`}
                            </Text>
                        </Wrapper>
                    </Wrapper>  
                : <></>
            }

        </Modal>
    );
};

export default FacilityManageModal;

const customStyles = {
    content: {
        width: "calc(100% - 24px)",
        maxWidth : "600px",
        marginRight: "-50%",
        padding: 0,
        borderRadius: "16px",
        top: "50%",
        left: "50%",
        right: "auto",
        bottom: "auto",
        transform: "translate(-50%, -50%)",
    },
};

const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`;

const AffiliateModalLayout = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    max-height: 600px;
    min-height: 300px;
    max-width: 600px;
    padding: 12px 10px;
    border-radius: 0;
`;

const DropDown = styled.div`
    width: 100%;
    padding: 3px 5px;
    border-bottom: ${({ last }) => !last && `1px solid ${colors.Gr02}`};
    color: ${({ active }) => active ? colors.Bl07 : colors.Gr04 };
    font-size: 1.4rem;
    font-weight: ${({ active }) => active ? 600 : 500};
    line-height: 142%;
    letter-spacing: -0.26px;
    text-align : center;
    cursor : pointer;
`;

const NewCrew = styled.div`
    z-index: 100;
    cursor : pointer;
    border : .2rem solid ${colors.Bl07};
    width : 2.5rem;
    height : 2.5rem;
    border-radius: 50%;
    display: inline-flex;
    align-items: center;
    justify-content: center;

    img {
        width : 2.2rem;
        display: inline-block;
    }
`;