import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import styled from "styled-components";

// components
import Header from "../components/basic/Header";
import Modal from "../components/newActivityRegister/modal";
import { GetOne } from "../tool/localstorage";
import { LoadingSpinner } from "../tool/loading";
import CardCoach from "../components/facilityCrew/CardCoach";

// styles
import colors from "../styles/colors";
import { Wrapper, Input, Text } from "../components/layouts/layoutStorage";

// img
import plusBlue from "../icon/plusBlue.png";
import { ReactComponent as Search } from "../icon/search.svg";
import { ReactComponent as Arrow } from "../resource/icon/arrow.svg";

const Crew = () => {

    const infoRef = useRef();

    const [dropdown, setDropdown] = useState(false);

    const [modalStatus, setModalStatus] = useState({
        status: false,
        type: "",
        link: "",
    });

    const [loading, setLoading] = useState(true);

    const navigate = useNavigate();

    const [crewList, setCrewList] = useState();
    const [activityList, setActivityList] = useState();
    const [newCrew, setNewCrew] = useState(0);
    const [allCrew, setAllCrew] = useState(0);
    const [closeCrew, setCloseCrew] = useState(0);
    const [thisMonth, setThisMonth] = useState(0);
    const [height, setHeight] = useState();
    const [focus, setFocus] = useState(false);
    const [search, setSearch] = useState();

    useEffect(() => {
        setTimeout(() => {
            var FirstComponent = document.getElementById("HeightCheck").clientHeight;
            setHeight(FirstComponent);
        }, 500);

        var token = GetOne("token");

        const frm = new FormData();

        frm.append("mode", "crew");
        frm.append("token", token);

        axios
        .post("https://ai.semos.kr/semos_partner/v3/activity/Facility", frm, {
            headers: { "Content-Type": "multipart/form-data" },
        })
        .then((data) => {
            var Value = data.data;
            var Status = Value.status;
            var StatusMessage = Value.status_message;
            var List = Value.list;
            var NewCrew = Value.new_crew;
            var CloseCrew = Value.close_crew;
            var AllCrew = Value.all_crew;
            var ThisMonth = Value.this_month;
            var ActivityData = Value.activity_data;

            if (Status == "success") {
                setCrewList(List);
                setNewCrew(NewCrew);
                setCloseCrew(CloseCrew);
                setAllCrew(AllCrew);
                setThisMonth(ThisMonth);
                setActivityList(ActivityData);
                setLoading(false);
            } else {
                window.alert(StatusMessage);
            }
        });
    }, []);

    const GoRegister = () => {
        navigate("/new_crew_coach");
    };

    const SearchCrew = () => {
        setLoading(true);

        var token = GetOne("token");

        const frm = new FormData();

        frm.append("mode", "search_crew");
        frm.append("token", token);
        frm.append("text", search);

        axios
        .post("https://ai.semos.kr/semos_partner/v3/activity/Facility", frm, {
            headers: { "Content-Type": "multipart/form-data" },
        })
        .then((data) => {
            var Value = data.data;
            var Status = Value.status;
            var StatusMessage = Value.status_message;
            var List = Value.list;

            if (Status == "success") {
                setCrewList(List);
                setLoading(false);
            } else {
                window.alert(StatusMessage);
                setLoading(false);
            }
        });

    }

    return (
        <Container>

            <Header title="회원 관리" back={true} handleClick={() => navigate("/home")} />

            <InnerContainer>

                <HeightCheck id="HeightCheck">
                    <Divider height={"10"} color={colors.White} />
                    <ContentContainer>
                        <BackgroundContent jc={`space-between`} ai={`center`}>
                            {crewList ? 
                                <Wrapper 
                                    ref={infoRef}
                                    wrap={`wrap`} 
                                    width="100%" 
                                    ai="flex-start"
                                >
                                    <Wrapper
                                        width="100%" 
                                        wrap={`nowrap`} 
                                        ai="center" 
                                        jc="space-between"
                                    >
                                        <TextView 
                                            color={colors.Gr05} 
                                            fw={`600`} 
                                            fs={`1.5rem`} 
                                            lh={`142%`}
                                        >
                                            {`총 ${allCrew}명 / 만료 ${closeCrew}명 / ${thisMonth}월 신규 ${newCrew}명`}
                                        </TextView> 
                                        
                                        <Arrow
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                setDropdown(!dropdown);
                                            }}
                                            width="2.5rem"
                                            height="2.5rem"
                                            fill={colors.Gr04}
                                            style={{ 
                                                transition: "all ease 0.5s", 
                                                transform: dropdown ? "rotate(-90deg)" : "rotate(90deg)" 
                                            }}
                                        />
                                    </Wrapper>
                                    
                                    {
                                        dropdown ?
                                            activityList?.map((x, idx) => (
                                                <Wrapper
                                                    width="100%" 
                                                    ai="center" 
                                                    jc="space-between"
                                                    padding={`5px 0 0 0`}
                                                    key={`${idx}_activity`}
                                                >
                                                    <TextView 
                                                        color={colors.Gr05} 
                                                        fw={`500`} 
                                                        fs={`1.4rem`} 
                                                        lh={`142%`}
                                                        width={`100%`}
                                                    >
                                                        {`- ${x.title} : ${x.count}명`}
                                                    </TextView> 
                                                </Wrapper>
                                            ))
                                        : <></>
                                    }

                                </Wrapper>
                            : 
                                ""
                            }
                        </BackgroundContent>

                    </ContentContainer>

                    <Wrapper 
                        width="calc(100% - 48px)" 
                        padding="5px"
                        margin={`7px 24px`}
                        jc="space-between"
                        ai="center"
                        border={`2px solid ${focus ? colors.Bl07 : colors.Gr04}`}
                        radius={`8px`}
                    >
                        <Input 
                            onFocus={() => setFocus(true)}
                            onBlur={() => setFocus(false)}
                            onKeyPress={(e) => {
                                if(e.key == "Enter" || e.keyCode == "13") {
                                    SearchCrew()
                                }
                            }}
                            value={search}
                            onChange={(e) => {setSearch(e.target.value)}}
                            width="calc(100% - 3.5rem)"
                            size="1.8rem"
                            weight="600"
                            lh="22px"
                            ls="-0.28px"
                            maxLength={30}
                            phColor={colors.Gr03}
                            placeholder="회원명, 전화번호로 검색할 수 있어요."
                        />
                        <Search  
                            onClick={() => SearchCrew()}
                            fill={colors.Gr03} 
                            width="3rem" 
                            height="3rem" 
                        />
                    </Wrapper>
                </HeightCheck>

                <ScrollContainer height={height && height}>
                    {
                        crewList?.map((x, idx) => (
                            <CardCoach key={`${idx}_crew`} data={x}/>
                        ))
                    }
                    {
                        (crewList?.length < 1) ?
                            <Wrapper
                                jc={'center'}
                                ai={'center'}
                                fd={'column'}
                                width={`100%`}
                                gap={`3px`}
                                margin={`5px 0 0 0`}
                            >
                                <Text 
                                    jc={'center'}
                                    ta={`center`} 
                                    width={`100%`} 
                                    size="1.8rem" 
                                    weight="600" 
                                    lh="24px" 
                                    ls="-0.32px" 
                                    color={colors.Gr04}
                                >
                                    검색된 회원이 없습니다.
                                </Text>
                                <Text 
                                    ta={`center`} 
                                    jc={'center'}
                                    width={`100%`} 
                                    size="1.8rem" 
                                    weight="600" 
                                    lh="24px" 
                                    ls="-0.32px" 
                                    color={colors.Gr04}
                                >
                                    검색어를 확인해주세요!
                                </Text>
                            </Wrapper>
                        : ""
                    }
                </ScrollContainer>
                
            </InnerContainer>

            <LoadingSpinner loading={loading} title={`정보를 확인하고 있습니다.`} content={`잠시만 기다려 주세요!`} />

            <Modal modal={modalStatus} setModal={setModalStatus} />

            <NewCrew
                onClick={() => GoRegister()}
            >

                <img src={plusBlue} />

                <Text 
                    ta={`center`} 
                    jc={'center'}
                    width={`100%`} 
                    size="1.8rem" 
                    weight="600" 
                    lh="24px" 
                    ls="-0.32px" 
                    color={colors.Gr04}
                >
                    회원 등록
                </Text>
            </NewCrew>
        </Container>
    );
};

export default Crew;

const Container = styled.section`
    /* height: calc(var(--vh, 1vh) * 100); */
    overflow-x: hidden;
`;

const Divider = styled.div`
    height: ${({ height }) => height && `${height}px`};
    background-color: ${({ color }) => color && `${color}`};
    width: 100%;
`;

const InnerContainer = styled.div`
    width: 100%;
    /* height: calc((var(--vh, 1vh) * 100) - 50.55px); */
    overflow: hidden;
    padding-bottom : 7rem;
`;

const NewCrew = styled.div`
    position : fixed;
    bottom : 1.5rem;
    right : 1.5rem;
    z-index: 100;
    cursor : pointer;
    border : .3rem solid ${colors.Bl07};
    //width : 5rem;
    height : 5rem;
    border-radius: .8rem;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 0 1rem;
    background-color: ${colors.White};

    img {
        width : 4rem;
        display: inline-block;
        margin-right: .5rem;
    }
`;

const HeightCheck = styled.div`
    width: 100%;
    height: auto;
    display: inline-block;
    border-bottom : 1px solid ${colors.Gr01};
`;

const ContentContainer = styled.div`
    width: 100%;
    padding: 0 24px;
    display: inline-flex;
    position: relative;
`;

const BackgroundContent = styled.div`
    width: 100%;
    border: ${({ border }) => border && `${border}`};
    background-color: ${({ bgc }) => bgc && `${bgc}`};
    padding: ${({ padding }) => padding && `${padding}`};
    display: inline-flex;
    border-radius: ${({ br }) => br && `${br}px`};
    justify-content: ${({ jc }) => jc && `${jc}`};
    align-items: ${({ ai }) => ai && `${ai}`};
    position: relative;
`;

const TextView = styled.span`
    width: ${({ width }) => width && `${width}`};
    text-align: ${({ ta }) => ta && `${ta}`};
    color: ${({ color }) => color && `${color}`};
    font-weight: ${({ fw }) => fw && `${fw}`};
    font-size: ${({ fs }) => fs && `${fs}`};
    line-height: ${({ lh }) => lh && `${lh}`};
    display: ${({ display }) => display ? `${display}` : "inline-flex"};
`;

const ScrollContainer = styled.div`
    height: ${({ height }) => height && `calc(100vh - ${height}px)`};
    /* max-height: ${({ height }) => height && `calc( (var(--vh, 1vh) * 100) - (${height}px + 133.55px) )`}; */
    /* min-height: ${({ height }) => height && `calc((var(--vh, 1vh) * 100) - (${height}px + 133.55px) )`}; */
    /* height: ${({ height }) => height && `calc(100vh - (${height}px + 133.55px) )`}; */
    width: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    display: inline-block;
    flex-wrap: wrap;
    padding-bottom: 85px;
`;