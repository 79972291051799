import React from "react";
import styled from "styled-components";
import { useLocation, useNavigate } from "react-router-dom";

// style
import { PC, WIDTH } from "../../styles/globalStyles";
import colors from "../../styles/colors";

// img
import HomeFocus from "../../icon/HomeFocus.png";
import HomeNoFocus from "../../icon/HomeNoFocus.png";
import CrewManageFocus from "../../icon/CrewManageFocus.png";
import CrewManageNoFocus from "../../icon/CrewManageNoFocus.png";
import ReservationManageFocus from "../../icon/ReservationManageFocus.png";
import ReservationManageNoFocus from "../../icon/ReservationManageNoFocus.png";
import ActivityManageFocus from "../../icon/ActivityManageFocus.png";
import ActivityManageNoFocus from "../../icon/ActivityManangeNoFocus.png";

function Footer() {
  const location = useLocation();
  const navigate = useNavigate();
  const path = location.pathname;

  const home =
    path == "/home" ||
    path == "/alarm" ||
    path == "/review_list" ||
    path == "/profile" ||
    path.includes("announcement") ||
    path == "/faq" ||
    path == "/account_info_revise";

  const crew = path.includes("chat");

  const schedule = path == "/reservation_manage" || path == "/my_calender";

  const activity = path.includes("activity");

  return (
    <Container>
      <FooterContainer onClick={() => navigate("/home")}>
        <FooterIcon src={home ? HomeFocus : HomeNoFocus} />
        <FooterText focus={home}>홈</FooterText>
      </FooterContainer>
      <FooterContainer onClick={() => navigate("/chat_list")}>
        <FooterIcon src={crew ? CrewManageFocus : CrewManageNoFocus} />
        <FooterText focus={crew}>크루 관리</FooterText>
      </FooterContainer>
      <FooterContainer onClick={() => navigate("/reservation_manage")}>
        <FooterIcon
          src={schedule ? ReservationManageFocus : ReservationManageNoFocus}
        />
        <FooterText focus={schedule}>예약 관리</FooterText>
      </FooterContainer>
      <FooterContainer onClick={() => navigate("/activity_manage")}>
        <FooterIcon
          src={activity ? ActivityManageFocus : ActivityManageNoFocus}
        />
        <FooterText focus={activity}>액티비티 관리</FooterText>
      </FooterContainer>
    </Container>
  );
}

export default Footer;

const Container = styled.footer`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 14px 24px;
  /* padding-bottom: 26px; */
  background-color: ${colors.White};
  border-top: 1px solid ${colors.Gr01};
  /* box-shadow: 0px -1px 6px rgba(0, 0, 0, 0.15); */
  position: fixed;
  z-index: 48;
  bottom: 0;

  @media screen and (min-width: ${PC}px) {
    width: ${WIDTH}px;
  }
`;

const FooterContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 18%;
  cursor: pointer;
`;

const FooterIcon = styled.img`
  width: 24px;
  height: 24px;
  margin-bottom: 5px;
`;

const FooterText = styled.div`
  color: ${({ focus }) => (focus ? `${colors.Bl07}` : `${colors.Gr04}`)};
  line-height: 140%;
  font-size: 10px;
  font-weight: 600;
`;
