import React, { useState, useRef, useEffect } from "react";
import styled, { css } from "styled-components";
import axios from "axios";
import { useNavigate, Link } from "react-router-dom";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

// component
import Header from "../components/basic/Header";
import { GetOne } from "../tool/localstorage";
import { LoadingSpinner } from "../tool/loading";
import colors from "../styles/colors";
import { PC, W } from "../styles/globalStyles";
import { Wrapper, Text, Icon, AbsoluteWrapper, WrapperColumn } from "../components/layouts/layoutStorage";
import { fbevent } from "../tool/firebase";
import { IPad, Android } from "../tool/device_check";

// img
import Arrow from "../designedIcons/carousel_arrow.png";
import Check from "../designedIcons/check.svg";
import { ReactComponent as Share } from "../designedIcons/link_share.svg";
import Star from "../icon/fullStar.png";

const IMAGE_RATIO = 249 / 375;
const WC = (W > 800) ? '800' : W; 

const Profile = () => {
  const carouselSettings = {
    draggable: false,
    className: "slider variable-width",
    dots: false,
    arrows: false,
    infinite: true,
    // centerMode: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    variableWidth: false,
  };
  const AC = Android();

  const navigate = useNavigate();

  const sliderRef = useRef();
  const copyLinkRef = useRef();

  const [copyPop, setCopyPop] = useState(false);
  const [loading, setLoading] = useState(true);

  const [profile, setProfile] = useState({});

  useEffect(() => {
    fbevent("B2B_Profile_Page_View", {
      email : GetOne("email")
    });

    getData();
  }, []);

  const getData = () => {
    setLoading(true);

    const token = GetOne("token");
    const frm = new FormData();

    frm.append("mode", "profile");
    frm.append("token", token);

    axios
      .post("https://ai.semos.kr/semos_partner/auth/profile", frm, {
        headers: { "Content-Type": "multipart/form-data" },
      })
      .then((data) => {
        const Value = data.data;
        const Status = Value.status;
        const StatusMessage = Value.status_message;
        const Info = Value.partner_data;

        if (Status === "success") {
          setProfile(Info);
          setLoading(false);
        } else {
          window.alert(StatusMessage);
        }
      });
  };
  // const copyToClipboard  =(text) =>{
  //   const input = document.createElement('input');
  //   input.style.position = 'fixed';
  //   input.style.opacity = 0;
  //   input.value = text;
  //   document.body.appendChild(input);
  //   input.select();
  //   document.execCommand('copy');
  //   document.body.removeChild(input);
  // }
  return (
    <>
      <Container>
        <Header title="프로필" back={true}>
          {/*<Text cursor="pointer" lh="22px" color={colors.Bl07} onClick={() => navigate("/profile_revise")} size="14px" weight="600">
            편집하기
          </Text>*/}
        </Header>

        <Wrapper style={{margin : `0 ${((W - WC) > 0) ? `${(W - WC) / 2}px` : `0` }`}} position="relative" width={`${WC}px`} height={`${WC * IMAGE_RATIO}px`}>
          {profile.image?.length > 0 ? (
            <Slider {...carouselSettings} ref={sliderRef}>
              {profile.image?.map((item, idx) => (
                <div key={idx}>
                  <div style={{ position: "relative" }}>
                    <Icon of="cover" src={item.image} width={`100%`} height={`${WC * IMAGE_RATIO}px`} />

                    <AbsoluteWrapper bottom="24px" right="24px" bgc={colors.B40} radius="16px" padding="2px 12px">
                      <Text size="12px" color={colors.White} weight="600">
                        {idx + 1}&nbsp;
                      </Text>
                      <Text size="12px" color={colors.Gr04} weight="600">
                        {`/ ${profile.image.length}`}
                      </Text>
                    </AbsoluteWrapper>
                  </div>
                </div>
              ))}
            </Slider>
          ) : (
            <div>
              <div style={{ position: "relative" }}>
                <Wrapper jc="center" ai="center" bgc={colors.Gr01} width={`100%`} height={`${WC * IMAGE_RATIO}px`}></Wrapper>
              </div>
            </div>
          )}

          <ArrowIcon onClick={() => sliderRef.current.slickPrev()} src={Arrow} width="32px" height="32px" />

          <ArrowIcon onClick={() => sliderRef.current.slickNext()} src={Arrow} right={true} width="32px" height="32px" />

          <AbsoluteWrapper left="24px" bottom="-13px" z={9}>
            <Icon style={{border : `1px solid ${colors.Gr02}`}} radius="50%" width="96px" height="96px" src={profile.profile} />
          </AbsoluteWrapper>
        </Wrapper>

        <Wrapper padding="32px 24px 16px 24px" width="100%">
          <Text size="20px" weight="700" ls="-0.02em" color={colors.Gr05}>
            {profile.name}
          </Text>
        </Wrapper>

        <Wrapper padding="0 24px 24px 24px" width="100%">
          <Wrapper border={`1px solid ${colors.Gr02}`} padding="18px 32px" jc="center" ai="center" width="100%" radius="15px">
            <Wrapper width="100%" jc="space-between" ai="center" padding="0 22px">
              <WrapperColumn ai="center">
                <Text size="14px" weight="500" ls="-0.02em" color={colors.Gr05}>
                  확정률
                </Text>

                <Wrapper height="8px" />

                <Text size="16px" weight="600" ls="-0.02em" color={colors.Bl07}>
                  {`${profile.confirm ? profile.confirm : 100}%`}
                </Text>
              </WrapperColumn>

              <WrapperColumn ai="center">
                <Text size="14px" weight="500" ls="-0.02em" color={colors.Gr05}>
                  리뷰&nbsp;
                  <Text size="13px" weight="500" ls="-0.02em" color={colors.Gr03}>
                    {profile.review_count}
                  </Text>
                </Text>

                <Wrapper height="8px" />

                <Wrapper ai="center">
                  <Icon src={Star} width="16px" height="16px" margin="0 5px 0 0" />
                  <Text size="16px" weight="600" ls="-0.02em" lh="24px" color={colors.Gr05}>
                    {`${profile.review_rating ? (profile.review_rating === "5.0" ? "5" : profile.review_rating) : 0}`}
                  </Text>
                </Wrapper>
              </WrapperColumn>

              <WrapperColumn ai="center">
                <Text size="14px" weight="500" ls="-0.02em" color={colors.Gr05}>
                  액티비티
                </Text>

                <Wrapper height="8px" />

                <Text size="16px" weight="600" ls="-0.02em" color={colors.Gr05}>
                  {`${profile.activity}`}
                </Text>
              </WrapperColumn>
            </Wrapper>
          </Wrapper>
        </Wrapper>

        <Wrapper fd="column" ai="flex-start" padding="0 24px 24px 24px" width="100%">
          <Text margin="0 0 12px 0" size="16px" ls="-0.02em" weight="600" color={colors.Gr05}>
            파트너 소개
          </Text>

          <Wrapper radius="14px" bgc={colors.Gr01} border={`1px solid ${colors.Gr02}`} padding="14px 18px" width="100%">
            <Text ws="pre-wrap" size="14px" weight="500" ls="-0.02em" color={colors.Gr05}>
              {profile.intro}
            </Text>
          </Wrapper>
        </Wrapper>

        <Wrapper fd="column" ai="flex-start" padding="0 24px 24px 24px" width="100%">
          <Text margin="0 0 12px 0" size="16px" ls="-0.02em" weight="600" color={colors.Gr05}>
            이력 및 경력
          </Text>
          <Wrapper gap="8px" ai="flex-start" width="100%" fd="column" radius="14px" bgc={colors.White} border={`1px solid ${colors.Gr02}`} padding="12px 16px">
            {profile.career?.map((item, index) => (
              <Wrapper key={item.id}>
                <Icon margin="0 8px 0 0" width="16px" height="16px" src={Check} />
                <Text size="14px" weight="500" ls="-0.02em" color={colors.Gr05}>
                  {item.text}
                </Text>
              </Wrapper>
            ))}
          </Wrapper>
        </Wrapper>

        <Wrapper style={{
            position : "fixed",
            bottom : 0,
            left : '50%',
            transform : "translate(-50%, 0)", 
            maxWidth : '1300px',
            zIndex : '100',
            gap : "12px"
          }} padding="8px 24px 8px 24px" width="100%" bgc="#ffffff" ai="center" jc="center" wrap="wrap"
        >

          <Wrapper
            cursor="pointer"
            jc="center"
            ai="center"
            width="calc(40% - 6px)"
            radius="14px"
            bgc={colors.Red03}
            height="52px"
            style={{
              maxWidth : "calc(320px - 6px)"
            }}
            onClick={() => {
              navigate("/profile_revise")
            }}
          >
            <Text ws="pre-wrap" size="16px" weight="600" ls="-0.02em" lh="24px" color={colors.White}>
              수정하기
            </Text>
          </Wrapper>

          <Wrapper
            ref={copyLinkRef}
            cursor="pointer"
            gap="8px"
            jc="center"
            ai="center"
            width="calc(60% - 6px)"
            radius="14px"
            bgc={colors.Bl07}
            height="52px"
            style={{
              maxWidth : "calc(480px - 6px)"
            }}
            onClick={() => {
              if (AC) {
                window.ReactNativeWebView.postMessage(profile.share ? profile.share : "");
              } else {
                navigator.clipboard.writeText(profile.share ? profile.share : "");
              }

              fbevent("B2B_Profile_LinkCopy_Click", {
                email : GetOne("email")
              });

              setCopyPop(true);
              setTimeout(() => {
                setCopyPop(false);
              }, 2000);
            }}
          >
            <Share width="24px" height="24px" fill={colors.White} />
            <Text ws="pre-wrap" size="16px" weight="600" ls="-0.02em" lh="24px" color={colors.White}>
              프로필 링크 복사
            </Text>
          </Wrapper>
        </Wrapper>
      </Container>

      {copyPop && (
        <Popup>
          <Text size="12px" weight="600" color={colors.White}>
            프로필 링크가 복사되었습니다.
          </Text>
        </Popup>
      )}
      {loading ? <LoadingSpinner loading={loading} /> : <></>}
    </>
  );
};

export default Profile;

const Container = styled.div`
  height: calc(var(--vh, 1vh) * 100);
  overflow-x: hidden;
  overflow-y: auto;
  padding-bottom : 100px; 

  .slick-slider {
    width: 100%;
    height: ${WC * IMAGE_RATIO}px;
    bottom: 10px;
  }
`;

const ArrowIcon = styled.img`
  width: 32px;
  height: 32px;
  position: absolute;
  top: 50%;
  cursor: pointer;

  ${({ right }) =>
    right
      ? css`
          transform: rotate(180deg);
          right: 18px;
        `
      : css`
          left: 18px;
        `};
`;

const Popup = styled.div`
  position: fixed;
  display: flex;
  justify-content: center;
  text-align: center;
  z-index: 99;
  bottom: 70px;
  left: 50%;
  transform: translate(-50%, 0);
  width: 320px;
  height: 44px;
  background-color: rgba(0, 0, 0, 0.7);
  border-radius: 8px;
`;
