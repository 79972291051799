import React, { useState, useRef, useEffect } from "react";
import styled from "styled-components";
import axios from "axios";
import { useNavigate, Link } from "react-router-dom";
import Sheet from "react-modal-sheet";

import Header from "../components/basic/Header";
import PhoneNumberConfirm from "../components/profileRevise/PhoneNumberConfirm";
import { setImageFromFile, CropImage } from "../tool/ImageCrop";
import { GetOne } from "../tool/localstorage";
import { LoadingSpinner } from "../tool/loading";
import { fbevent } from "../tool/firebase";

// style
import { PC, W } from "../styles/globalStyles";
import colors from "../styles/colors";

// img
import DefaultImageIcon from "../icon/profileReviseImg.png";
import { ReactComponent as Camera } from "../resource/icon/camera.svg";
import { ReactComponent as CameraEdit } from "../resource/icon/camera_edit.svg";
import { ReactComponent as Close } from "../resource/icon/close.svg";
import { ReactComponent as CloseCircleFilled } from "../resource/icon/close_circle_filled.svg";
import { ReactComponent as BangMark } from "../resource/icon/bang_mark.svg";

const SPECIAL_CHARACTERS = /[0-9~!@#$%^&*()_+={}[\]|\\:;"'<>,.?/`-]/g;
const NAME_PATTERN = /^[가-힣a-zA-Z0-9-\s]{2,15}$/i;
const NAME_STATUS = {
  default: "default",
  ok: "ok",
  denied: "denied",
  duplicated: "duplicated",
};

const MAX_NAME_LENGTH = 20;
const MIN_INTRO_LENGTH = 20;
const MAX_INTRO_LENGTH = 1000;
const MAX_CAREER_LENGTH = 20;
const MAX_BACKGROUND_LENGTH = 3;

const ProfileRevise = () => {
  const navigate = useNavigate();

  const nicknameRef = useRef();

  const [loading, setLoading] = useState(true);
  const [defaultProfile, setDefaultProfile] = useState({
    profile: "",
    name: "",
    type: "",
    login: "",
    intro: "",
    phone: "",
    background: [],
    career: [],
  });
  const [profile, setProfile] = useState({
    profile: "",
    name: "",
    type: "",
    login: "",
    intro: "",
    phone: "",
    background: [],
    career: [],
  });
  const [deleteProfile, setDeleteProfile] = useState({
    background: [],
    career: [],
  });
  const [valid, setValid] = useState({
    name: true,
    intro: true,
    phone: true,
    career: true,
    background: true,
  });
  const [updated, setUpdated] = useState(false);
  const [goBackModal, setGoBackModal] = useState(false);
  const [focus, setFocus] = useState({
    name: false,
    intro: false,
    career: false,
  });
  const [nicknameStatus, setNameStatus] = useState(NAME_STATUS.default);
  const [phoneNumStatus, setPhoneNumStatus] = useState({ auth: "", phone: "" });
  const [edit, setEdit] = useState(false);
  const [careerText, setCareerText] = useState("");
  const [saveModal, setSaveModal] = useState(false);
  const [profileCropModal, setProfileCropModal] = useState(false);
  const [cropModal, setCropModal] = useState(false);
  const [cropProfileImageSetting, setProfileCropImageSetting] = useState({
    image: "",
    type: "",
    width: 0,
    height: 0,
  });
  const [cropImageSetting, setCropImageSetting] = useState({
    image: "",
    type: "",
    width: 0,
    height: 0,
  });

  useEffect(() => {
    getData();

    fbevent("B2B_ProfileEdit_Page_View", {
      email : GetOne("email")
    });
  }, []);



  // PROFILE UPDATE CHECK
  useEffect(() => {
    if (!loading) {
      const backgroundUpdated = checkArrUpdated(
        defaultProfile.background.map((val) => val.image),
        profile.background.map((val) => val.image)
      );
      const careerUpdated = checkArrUpdated(
        defaultProfile.career.map((val) => val.career),
        profile.career.map((val) => val.career)
      );

      defaultProfile.profile === profile.profile &&
      defaultProfile.name === profile.name &&
      defaultProfile.type === profile.type &&
      defaultProfile.login === profile.login &&
      defaultProfile.intro === profile.intro &&
      defaultProfile.phone === profile.phone &&
      !backgroundUpdated &&
      !careerUpdated
        ? setUpdated(false)
        : setUpdated(true);
    }
  }, [profile]);

  // GET DATA
  const getData = () => {
    setLoading(true);
    const token = GetOne("token");

    const frm = new FormData();

    frm.append("mode", "main");
    frm.append("token", token);

    axios
      .post("https://ai.semos.kr/semos_partner/v3/profile/ProfileRevise", frm, {
        headers: { "Content-Type": "multipart/form-data" },
      })
      .then((res) => {
        const value = res.data;
        const status = value.status;
        const statusMessage = value.status_message;
        const data = value.data;

        if (status === "success") {
          setDefaultProfile({
            profile: data.profile,
            name: data.name,
            type: data.type,
            login: data.login,
            intro: data.intro,
            phone: data.phone,
            background: data.background,
            career: data.career,
          });

          setProfile({
            profile: data.profile,
            name: data.name,
            type: data.type,
            login: data.login,
            intro: data.intro,
            phone: data.phone,
            background: data.background,
            career: data.career,
          });

          setLoading(false);
        } else {
          window.alert(statusMessage);
        }
      });
  };

  // SAVE DATA
  const saveData = () => {
    setLoading(true);
    const token = GetOne("token");
    const { auth, ...restProfile } = profile;

    axios
      .post(
        "https://ai.semos.kr/semos_partner/v3/profile/ProfileRevise",
        {
          mode: "save",
          // data: restProfile,
          data: profile,
          delete: deleteProfile,
          token: token,
        },
        { headers: { "Content-Type": "application/x-www-form-urlencoded" } }
      )
      .then((res) => {
        const Value = res.data;
        const Status = Value.status;
        const Message = Value.status_message;

        if(Status == 'success') {
          setLoading(false);
          setSaveModal(true);

          fbevent("B2B_ProfileEdit_Save_Click", {
            email : GetOne("email")
          });
        } else {
          window.alert(Message);
          window.location.reload();
        }
      });
  };

  // 배열 업데이트 함수 : background, career
  const checkArrUpdated = (defaultArr, targetArr) => {
    let isUpdated = false;

    if (defaultArr.length === targetArr.length) {
      for (let i = 0; i < defaultArr.length; i++) {
        isUpdated = !defaultArr.includes(targetArr[i]);
      }
    } else {
      isUpdated = true;
    }
    return isUpdated;
  };

  const changeValue = (type, value) => {
    switch (type) {
      case "name":
        value = value.substr(0, MAX_NAME_LENGTH).replace(SPECIAL_CHARACTERS, "");
        value.length && value.length <= MAX_NAME_LENGTH ? setValid({ ...valid, name: true }) : setValid({ ...valid, name: false });
        setProfile({ ...profile, name: value });
        checkPartnerDuplicate(value);
        break;
      case "background":
        if (value) {
          const backgroundArr = [...profile.background];
          backgroundArr[backgroundArr.length] = value;

          setProfile({ ...profile, background: backgroundArr });
          setValid({ ...valid, background: true });
        }
        break;
      case "career":
        if (value) {
          const careerArr = [...profile.career];
          careerArr[careerArr.length] = value;

          setProfile({ ...profile, career: careerArr });
          setValid({ ...valid, career: true });
          setCareerText("");
        }
        break;
      case "profile":
        setProfile({ ...profile, profile: value });
        break;
      case "intro":
        // 파트너 소개
        value = value.substr(0, MAX_INTRO_LENGTH);
        const targetLength = value.trim().length && value.trim().length;
        targetLength <= MAX_INTRO_LENGTH && targetLength >= MIN_INTRO_LENGTH ? setValid({ ...valid, intro: true }) : setValid({ ...valid, intro: false });
        setProfile({ ...profile, intro: value });
        break;
      default:
        break;
    }
  };

  // 이력 및 경력 입력
  const handleCareerText = (target) => {
    const text = target.substr(0, MAX_CAREER_LENGTH);
    setCareerText(text);
  };

  // 파트너명 중복 체크
  const checkPartnerDuplicate = (target = "") => {
    const token = GetOne("token");
    const targetName = target ? target : profile.name;

    if (targetName.length > 1) {
      const frm = new FormData();
      frm.append("mode", "partner_name_dulicate");
      frm.append("partner_name", targetName);
      frm.append("token", token);
      axios
        .post("https://ai.semos.kr/semos_partner/auth/profile", frm, {
          headers: {
            "Content-Type": "multipart/form-data",
            Accept: "application/json",
          },
        })
        .then((res) => {
          const data = res.data;
          const duplicateStatus = data.status;
          const defaultName = data.name;

          if (duplicateStatus === "success") {
            if (defaultName === profile.name) {
              setNameStatus(NAME_STATUS.default); // 현재 내 이름 (변경 안됨)
              setValid({ ...valid, name: true });
            } else {
              if (NAME_PATTERN.test(targetName)) {
                setNameStatus(NAME_STATUS.ok); // 닉네임 양식 valid
                setValid({ ...valid, name: true });
              } else {
                setNameStatus(NAME_STATUS.denied); // 닉네임 양식 invalid
                setValid({ ...valid, name: false });

              }
            }
          } else {
            setNameStatus(NAME_STATUS.duplicated); // 닉네임 중복
            setValid({ ...valid, name: false });

          }
        });
    } else {
      setNameStatus(NAME_STATUS.default);
      setValid({ ...valid, name: false });
    }
  };

  // 프로필 이미지 변경
  const addProfileImage = (file) => {
    setProfile({ ...profile, profile: file });
  };

  // 배경 이미지 변경
  const addImage = (file) => {
    const copy = [...profile.background];
    copy.push({
      id: "new",
      image: file,
    });
    setProfile({ ...profile, background: copy });
    setValid({ ...valid, background: copy.length !== 0 });
  };

  // 배경 이미지 삭제
  const deleteImage = (type, index) => {
    const deleteArr = deleteProfile[type];
    const dataArr = [...profile.background];
    const target = dataArr[index];

    deleteArr.push(target);
    dataArr.splice(index, 1);

    setProfile((prev) => ({ ...prev, [type]: dataArr }));
    setDeleteProfile((prev) => ({ ...prev, [type]: deleteArr }));
    setValid({ ...valid, background: dataArr.length !== 0 });
  };

  // 이력 및 경력 삭제
  const deleteCareer = (index) => {
    const deleteArr = deleteProfile.career;
    const dataArr = [...profile.career];
    const target = dataArr[index];

    deleteArr.push(target);
    dataArr.splice(index, 1);

    setProfile({ ...profile, career: dataArr });
    setDeleteProfile({ ...deleteProfile, career: deleteArr });
    setValid({ ...valid, career: dataArr.length !== 0 });
  };

  const allValid = valid.name && valid.intro && valid.phone && valid.career && valid.background;

  return (
    <>
      {loading ? (
        <LoadingSpinner loading={loading} title={`정보를 확인하고 있습니다.`} content={`잠시만 기다려 주세요!`} />
      ) : (
        <>
          <Container>
            <Header title="파트너 정보" back={true} handleClick={() => (updated ? setGoBackModal(true) : navigate(-1))} />
            <Styler pt={34} pr={24} pb={4} pl={24} flex={true} direction={"column"} align={"center"}>
              <ProfileReviseBox htmlFor="profile">
                <ProfileReviseImg src={profile.profile ? profile.profile : DefaultImageIcon} />
                <ProfileEdit>
                  <CameraEdit fill={colors.White} />
                </ProfileEdit>
              </ProfileReviseBox>
              <PostImage
                type="file"
                id="profile"
                accept="image/*"
                onChange={({ target: { files } }) => {
                  if (files.length) {
                    setImageFromFile({
                      file: files[0],
                      setImageUrl: ({ result }) => {
                        setProfileCropImageSetting({
                          image: result,
                          width: 5,
                          height: 5,
                          type: "profile",
                        });
                        setProfileCropModal(true);
                      },
                    });
                  }
                }}
              />
              <TypeContainer>
                <span>{profile.login} 로그인</span>
                {/*<PartnerTypeBadge>
                  <span>{profile.type}</span>
                </PartnerTypeBadge>*/}
              </TypeContainer>
            </Styler>
            <Styler>
              {/* 활동명 수정하기 */}
              <ProfileItem>
                <ProfileReviseTitle>활동명</ProfileReviseTitle>
                <InputContainer h={52} nicknameStatus={nicknameStatus} focus={focus.name}>
                  <NicknameInput
                    ref={nicknameRef}
                    maxLength={MAX_NAME_LENGTH}
                    onChange={(e) => changeValue("name", e.target.value)}
                    value={profile.name ? profile.name : ""}
                    onFocus={() => {
                      setFocus({ ...focus, name: true });
                      checkPartnerDuplicate();
                    }}
                    onBlur={() => {
                      setFocus({ ...focus, name: false });
                      checkPartnerDuplicate();
                    }}
                    type="text"
                    placeholder="파트너 이름을 입력해 주세요."
                  />
                </InputContainer>
                {nicknameStatus === NAME_STATUS.duplicated ? (
                  <SubText status="warning" position="absolute">
                    <BangMark fill={colors.Red03} />
                    <span>중복된 활동명이에요</span>
                  </SubText>
                ) : (
                  <></>
                )}
              </ProfileItem>

              {/* 전화번호 수정하기 */}
              <ProfileItem>
                <ProfileReviseTitle>전화번호</ProfileReviseTitle>
                <BackgroundContent jc={`space-between`} padding={"0px"} ai={`center`}>
                  {edit ? (
                    <PhoneNumberConfirm
                      defaultPhoneNumber={defaultProfile.phone}
                      userInfo={profile}
                      setUserInfo={setProfile}
                      valid={valid}
                      setValid={setValid}
                      status={phoneNumStatus}
                      setStatus={setPhoneNumStatus}
                    />
                  ) : (
                    <PhoneTextContainer>
                      <span>{profile.phone}</span>
                      <PhoneButton onClick={() => setEdit(true)} open={false}>
                        수정하기
                      </PhoneButton>
                    </PhoneTextContainer>
                  )}
                </BackgroundContent>
                <SubText status="default" position="relative">
                  <span className="blue-text">Tip!</span>
                  <span>액티비티 진행 시 크루에게 전달될 번호예요</span>
                </SubText>
              </ProfileItem>

              {/* 파트너 소개 */}
              <ProfileItem>
                <ProfileReviseTitle>소개</ProfileReviseTitle>
                <TextareaContainer w={100} focus={focus.intro}>
                  <TextArea
                    maxLength={MAX_INTRO_LENGTH}
                    type="text"
                    placeholder={`파트너님을 소개해주세요! (${MAX_INTRO_LENGTH}자 이내)`}
                    value={profile.intro}
                    onChange={(e) => changeValue("intro", e.target.value)}
                    onFocus={() => setFocus({ ...focus, intro: true })}
                    onBlur={() => setFocus({ ...focus, intro: false })}
                  />
                  <IntroText>
                    <span>{profile.intro.length}</span> / {MAX_INTRO_LENGTH} {`(최소 ${MIN_INTRO_LENGTH}자)`}
                  </IntroText>
                </TextareaContainer>
              </ProfileItem>

              {/* 배경 사진 등록하기 */}
              <ProfileItem>
                <ProfileReviseTitle>배경 사진 등록하기</ProfileReviseTitle>
                <BackgroundImageContainer>
                  <BackgroundImageAdd>
                    <label htmlFor="background">
                      <ImageAddView>
                        <div>
                          <Camera fill={colors.Gr03} />
                          <ImageAddText>{`${profile.background ? profile.background.length : 0} / ${MAX_BACKGROUND_LENGTH}`}</ImageAddText>
                        </div>
                      </ImageAddView>
                    </label>
                    <PostImage
                      type="file"
                      id="background"
                      accept="image/*"
                      onChange={({ target: { files } }) => {
                        if (files.length && (profile.background.length < MAX_BACKGROUND_LENGTH)) {
                          setImageFromFile({
                            file: files[0],
                            setImageUrl: ({ result }) => {
                              setCropImageSetting({
                                image: result,
                                width: 8,
                                height: 5,
                                type: "background",
                              });
                              setCropModal(true);
                            },
                          });
                        } else {
                          window.alert(`이미지는 최대 ${MAX_BACKGROUND_LENGTH}개까지 등록하실 수 있습니다.`);
                        }
                      }}
                    />
                  </BackgroundImageAdd>
                  <BackgroundImageSlider>
                    {profile &&
                      profile.background.map((item, index) => (
                        <BackgroundImage key={item.image}>
                          <img className="BI" src={item.image} />
                          <CloseIcon
                            onClick={() => {
                              deleteImage("background", index);
                            }}
                          >
                            {/* <img className="CI" src={CloseBlack} /> */}
                            <CloseCircleFilled fill={colors.Gr05} />
                          </CloseIcon>
                        </BackgroundImage>
                      ))}
                  </BackgroundImageSlider>
                </BackgroundImageContainer>
              </ProfileItem>

              {/* 이력 및 경력 */}
              <ProfileItem>
                <ProfileReviseTitle>이력 및 경력</ProfileReviseTitle>
                <CareerContainer>
                  <CareerInputContainer focus={focus.career}>
                    <input
                      type="text"
                      placeholder={"이력 및 경력을 입력해 주세요"}
                      value={careerText}
                      onChange={(e) => handleCareerText(e.target.value)}
                      maxLength={MAX_CAREER_LENGTH}
                      onFocus={() => setFocus({ ...focus, career: true })}
                      onBlur={() => setFocus({ ...focus, career: false })}
                    />
                    <LengthText>
                      <span>{careerText.length}</span> / {MAX_CAREER_LENGTH}
                    </LengthText>
                  </CareerInputContainer>
                  <CareerButton
                    onClick={() =>
                      careerText.trim().length &&
                      changeValue("career", {
                        id: "new",
                        career: careerText,
                      })
                    }
                    focus={careerText.trim().length}
                  >
                    추가
                  </CareerButton>
                </CareerContainer>

                <Styler>
                  <CareerList>
                    {profile.career.map((val, index) => {
                      const { id, career } = val;
                      return (
                        <CareerItem key={`${career}-${id}-${index}`} isFirst={index === 0}>
                          <p>{career}</p>
                          <button onClick={() => deleteCareer(index)}>
                            <Close fill={colors.Gr03} />
                          </button>
                        </CareerItem>
                      );
                    })}
                  </CareerList>
                </Styler>
              </ProfileItem>

              <Divider h={80} bgc={colors.White} />

              <NextButtonContainer>
                <NextButton active={updated && allValid} onClick={() => {if(updated && allValid) {
                  saveData()
                }}}>
                  저장하기
                </NextButton>
              </NextButtonContainer>
            </Styler>
          </Container>

          <ReportModal
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              left: "50%",
              transform: "translate(-50%,0)",
            }}
            isOpen={goBackModal}
            snapPoints={[0.25]}
          >
            <ReportModal.Container style={{ width: `${W}px`, left: "none" }}>
              <BackModalLayout>
                <GoBackModalHeader>
                  지금 뒤로 가면 입력한 내용이 저장되지 않아요!
                  <br /> 정말 뒤로 가시겠어요?
                </GoBackModalHeader>
                <Divider h={36} bgc={colors.White} />
                <BtnConatiner>
                  <ModalButton bgc={colors.White} color={colors.Bl07} onClick={() => navigate(-1)}>
                    뒤로가기
                  </ModalButton>
                  <ModalButton bgc={colors.Bl07} color={colors.White} onClick={() => setGoBackModal(false)}>
                    계속 수정하기
                  </ModalButton>
                </BtnConatiner>
              </BackModalLayout>
            </ReportModal.Container>
            <ReportModal.Backdrop />
          </ReportModal>

          <ReportModal
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              left: "50%",
              transform: "translate(-50%,0)",
            }}
            isOpen={saveModal}
            snapPoints={[0.25]}
          >
            <ReportModal.Container style={{ width: `${W}px`, left: "none" }}>
              <BackModalLayout>
                <ModalHeader>프로필 수정이 완료되었어요.</ModalHeader>
                <Divider h={54} bgc={colors.White} />
                <ModalButton
                  bgc={colors.Bl07}
                  color={colors.White}
                  onClick={() => {
                    setSaveModal(false);
                    window.location.reload();
                  }}
                >
                  확인
                </ModalButton>
              </BackModalLayout>
            </ReportModal.Container>
            <ReportModal.Backdrop />
          </ReportModal>

          <CropImage open={cropModal} setOpen={setCropModal} setting={cropImageSetting} setSetting={setCropImageSetting} setImageFunc={addImage} />

          <CropImage
            open={profileCropModal}
            setOpen={setProfileCropModal}
            setting={cropProfileImageSetting}
            setSetting={setProfileCropImageSetting}
            setImageFunc={addProfileImage}
          />
        </>
      )}
    </>
  );
};

export default ProfileRevise;

const Container = styled.div`
  height: calc(var(--vh, 1vh) * 100);
  overflow-x: hidden;
  overflow-y: auto;
  /* @media screen and (min-width: 1200px) {
    height: auto;
  }

  @media screen and (max-width: 1200px) {
    height: calc(var(--vh, 1vh) * 100);
    overflow-x: hidden;
    overflow-y: auto;
  } */
`;

const Styler = styled.div`
  position: relative;
  display: ${({ flex }) => (flex ? "flex" : "")};
  flex-direction: ${({ direction }) => direction};
  justify-content: ${({ justify }) => justify};
  align-items: ${({ align }) => align};
  padding-top: ${({ pt }) => pt}px;
  padding-bottom: ${({ pb }) => pb}px;
  padding-left: ${({ pl }) => pl}px;
  padding-right: ${({ pr }) => pr}px;
  flex-wrap: ${({ wrap }) => wrap};
`;

const ProfileReviseBox = styled.label`
  cursor: pointer;
  position: relative;
  width: 160px;
  height: 160px;
  border-radius: 50%;
`;

const ProfileReviseImg = styled.img`
  width: 160px;
  height: 160px;
  border-radius: 50%;
`;

const ProfileEdit = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 38px;
  height: 38px;
  background-color: ${colors.Gr02};
  border-radius: 50%;
  position: absolute;
  right: 0;
  bottom: 0;

  svg {
    width: 24px;
    height: 24px;
  }
`;

const PostImage = styled.input`
  display: none;
`;

const TypeContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  margin-top: 16px;

  span {
    color: ${colors.Gr03};
    line-height: 142%;
    letter-spacing: -0.24px;
    font-size: 12px;
    font-weight: 600;
  }
`;

const PartnerTypeBadge = styled.div`
  padding: 2px 12px;
  border: 1px solid ${colors.Gr03};
  border-radius: 10px;
  text-align: center;
`;

const ProfileItem = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 8px;
  padding: 24px 24px 8px 24px;
  position: relative;
`;

const ProfileReviseTitle = styled.div`
  color: ${colors.Gr05};
  line-height: 24px;
  letter-spacing: -0.02em;
  font-size: 16px;
  font-weight: 600;
`;

const InputContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: ${({ h }) => `${h}px`};
  position: relative;
  border: 1px solid;
  border-radius: 8px;
  border-color: ${({ focus, nicknameStatus }) => {
    if (nicknameStatus) {
      switch (nicknameStatus) {
        case NAME_STATUS.default:
          return focus ? `${colors.Bl07}` : `${colors.Gr02}`;
        case NAME_STATUS.ok:
          return focus ? `${colors.Bl07}` : `${colors.Gr02}`;
        case NAME_STATUS.duplicated:
          return `${colors.Red03}`;
        case NAME_STATUS.denied:
          return `${colors.Red03}`;
        default:
          return `${colors.Gr02}`;
      }
    } else {
      return focus ? `${colors.Bl07}` : `${colors.Gr02}`;
    }
  }};
`;

const TextareaContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 200px;
  border: 1px solid ${({ focus }) => (focus ? colors.Bl07 : colors.Gr02)};
  border-radius: 8px;
`;

const PhoneTextContainer = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  gap: 4px;
  background-color: ${colors.White};
  width: 100%;
  height: 52px;
  padding: 8px 8px 8px 16px;
  border: 1px solid ${({ focus }) => (focus ? colors.Bl07 : colors.Gr02)};
  border-radius: 8px;

  span {
    flex: 1;
    display: inline-block;
    height: 100%;
    background-color: ${colors.White};
    line-height: 36px;
    letter-spacing: -0.28px;
    color: ${colors.Gr06};
    font-size: 14px;
    font-weight: 500;
  }
`;

const InputBox = styled.input`
  width: 100%;
  border: none;
  border-radius: 8px;
  outline: none;
  color: ${colors.Gr06};
  line-height: 142%;
  font-size: 14px;
  font-weight: 500;
`;

const NicknameInput = styled(InputBox)`
  padding: 16px;
  cursor: text;
`;

const ImageAddView = styled.div`
  width: 78px;
  height: 78px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  border: 1px solid ${colors.Gr02};
  border-radius: 8px;
  cursor: pointer;

  div {
    display: flex;
    flex-direction: column;
    align-items: center;
    svg {
      width: 32px;
      height: 32px;
    }
  }
`;

const ImageAddText = styled.span`
  display: inline-block;
  text-align: center;
  font-weight: 500;
  font-size: 10px;
  line-height: 132%;
  width: 100%;
  color: ${colors.Gr03};
`;

const ImageAddIcon = styled.img`
  width: 32px;
  height: 32px;
  display: block;
  margin-bottom: 2px;
  margin: 0 auto;
`;

const BackgroundImageContainer = styled.div`
  width: 100%;
  justify-content: space-between;
  align-items: flex-end;
  display: inline-flex;
`;

const BackgroundImageAdd = styled.div`
  display: inline-block;
  width: 78px;
  height: 78px;
  margin-bottom: 3px;
  margin-top: 12px;
`;

const BackgroundImageSlider = styled.div`
  display: inline-block;
  width: calc(100% - 91px);
  overflow-x: auto;
  overflow-y: hidden;
  white-space: nowrap;
  padding-top: 12px;
`;

const BackgroundImage = styled.div`
  width: calc(78px * (8 / 5));
  height: 78px;
  position: relative;
  border-radius: 8px;
  display: inline-block;
  margin-right: 13px;

  .BI {
    width: 100%;
    display: inline-block;
    border-radius: 8px;
  }
`;

const CloseIcon = styled.div`
  position: absolute;
  top: -12px;
  right: -12px;
  width: 24px;
  height: 24px;
  cursor: pointer;
`;

const NextButtonContainer = styled.div`
  width: 100%;
  padding: 10px 24px;
  border-top: 2px solid ${colors.Gr01};
  position : fixed;
  bottom : 0;
  left : 0;
  background-color : #ffffff;
`;

const NextButton = styled.div`
  padding: 16px;
  border: 1px solid;
  border-color: ${({ active }) => (active ? `${colors.Bl07}` : `${colors.Gr02}`)};
  border-radius: 8px;
  background-color: ${({ active }) => (active ? `${colors.Bl07}` : `${colors.Gr01}`)};
  color: ${({ active }) => (active ? `${colors.White}` : `${colors.Gr03}`)};
  text-align: center;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
`;

const TextArea = styled.textarea`
  display: inline-flex;
  width: 100%;
  height: 100%;
  padding: 16px 16px 0px 16px;
  border: none;
  border-radius: 8px;
  resize: none;
  line-height: 22px;
  letter-spacing: -0.02em;
  font-size: 14px;
  font-weight: 500;

  overflow: auto;
  outline: none;

  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;

  &::placeholder {
    color: ${colors.Gr03};
  }
`;

const IntroText = styled.div`
  flex: 1;
  align-self: flex-end;
  width: 100%;
  padding: 8px 12px;
  color: ${colors.Gr03};
  line-height: 20px;
  letter-spacing: -0.02em;
  font-size: 12px;
  font-weight: 500;

  span {
    color: ${colors.Gr04};
  }
`;

const Divider = styled.div`
  width: 100%;
  height: ${({ h }) => h}px;
  margin-top: 24px;
  background-color: ${({ bgc }) => (bgc ? bgc : colors.Gr01)};
`;

const BackgroundContent = styled.div`
  width: 100%;
  border: ${({ border }) => border && `${border}`};
  background-color: ${({ bgc }) => bgc && `${bgc}`};
  padding: ${({ padding }) => padding && `${padding}`};
  display: inline-flex;
  border-radius: ${({ br }) => br && `${br}px`};
  justify-content: ${({ jc }) => jc && `${jc}`};
  align-items: ${({ ai }) => ai && `${ai}`};
  flex-wrap: ${({ wrap }) => wrap && `${wrap}`};
  position: relative;
`;

const PhoneButton = styled.div`
  cursor: pointer;
  padding: 9px 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 700;
  font-size: 14px;
  line-height: 142%;
  background-color: ${({ open }) => (open ? colors.Gr02 : colors.Bl07)};
  letter-spacing: -0.02em;
  color: ${colors.White};
  border-radius: 8px;
  white-space: nowrap;
`;

const SubText = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 6px;
  position: ${({ position }) => position};
  bottom: ${({ position }) => (position === "absolute" ? "-16px" : "auto")};
  left: ${({ position }) => (position === "absolute" ? "24px" : "auto")};

  span {
    color: ${({ status }) => {
      if (status === "warning") {
        return colors.Red03;
      } else if (status === "default") {
        return colors.Gr03;
      }
    }};
    line-height: 22px;
    letter-spacing: -0.28px;
    font-size: 14px;
    font-weight: 500;
  }

  .blue-text {
    color: ${colors.Bl06};
  }
`;

const CareerContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
`;

const CareerButton = styled.button`
  flex-grow: 1;
  white-space: nowrap;
  width: 60px;
  height: 52px;
  padding: 16px;
  background-color: ${({ focus }) => (focus ? colors.Bl07 : colors.Gr01)};
  border-radius: 8px;
  color: ${({ focus }) => (focus ? colors.White : colors.Gr03)};
  text-align: center;
  line-height: 24px;
  letter-spacing: -0.32px;
  font-size: 16px;
  font-weight: 600;
`;

const CareerInputContainer = styled.div`
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 4px;
  background-color: ${colors.White};
  width: 100%;
  height: 52px;
  padding: 8px 16px;
  border: 1px solid ${({ focus }) => (focus ? colors.Bl07 : colors.Gr02)};
  border-radius: 8px;

  input {
    width: 100%;
    height: 100%;
    background-color: ${colors.White};
    font-size: 14px;
  }
`;

const LengthText = styled.div`
  color: ${colors.Gr03};
  white-space: nowrap;
  line-height: 22px;
  letter-spacing: -0.28px;
  font-size: 14px;
  font-weight: 500;
`;

const CareerList = styled.ul`
  padding-top: 4px;
`;

const CareerItem = styled.li`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
  padding: 8px 0px;
  border-top: 1px solid ${({ isFirst }) => (isFirst ? "transparent" : colors.Gr01)};

  p {
    color: ${colors.Gr05};
    line-height: 22px;
    letter-spacing: -0.28px;
    font-size: 14px;
    font-weight: 500;
  }

  svg {
    width: 20px;
    height: 20px;
  }
`;

const ReportModal = styled(Sheet)``;

const BackModalLayout = styled.div`
  padding: 24px 24px 20px 24px;
`;

const ModalHeader = styled.div`
  color: ${colors.Gr06};
  line-height: 142%;
  letter-spacing: -0.02em;
  font-size: 18px;
  font-weight: 600;
`;

const GoBackModalHeader = styled.div`
  color: ${colors.Gr04};
  line-height: 142%;
  letter-spacing: -0.02em;
  font-size: 14px;
  font-weight: 700;
`;

const BtnConatiner = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
  width: 100%;
`;

const ModalButton = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 18px 0;
  background-color: ${({ bgc }) => bgc};
  border-radius: 8px;
  color: ${({ color }) => color};
  text-align: center;
  line-height: 142%;
  letter-spacing: -0.02em;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
`;
