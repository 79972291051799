import React from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

import ModalLayout from "../../components/elements/ModalLayout";

// style
import colors from "../../styles/colors";

// img
import { ReactComponent as CautionTriangle } from "../../icon/caution_triangle.svg";

const CancelModal = ({ openCancelModal, setOpenCancelModal, id }) => {
  const navigate = useNavigate();

  return (
    <ModalLayout
      modal={{ status: openCancelModal }}
      setModal={() => setOpenCancelModal(false)}
    >
      <CancelContentContainer>
        <h3>
          예약 상세 내역을 확인하지 않으셨어요! <br />
          그래도 거절하실건가요?
        </h3>
        <CautionTriangle fill={colors.Bl07} />
      </CancelContentContainer>
      <CancelButtonContainer>
        <ModalButton
          onClick={() => {
            navigate(`/reservation_cancel_detail/${id}`);
          }}
          isCancel={true}
        >
          거절하기
        </ModalButton>
        <ModalButton
          onClick={() => {
            setOpenCancelModal(false);
          }}
          isCancel={false}
        >
          뒤로가기
        </ModalButton>
      </CancelButtonContainer>
    </ModalLayout>
  );
};

export default CancelModal;

const CancelContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  gap: 24px;
  margin-bottom: 36px;

  h3 {
    text-align: center;
    color: ${colors.Gr05};
    line-height: 26px;
    letter-spacing: -0.02em;
    font-size: 18px;
    font-weight: 700;
  }

  svg {
    width: 76px;
    height: 76px;
  }
`;

const CancelButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 12px;
`;

const ModalButton = styled.button`
  flex: 1;
  padding: 12px;
  background-color: ${({ isCancel }) => (isCancel ? colors.Bl02 : colors.Bl07)};
  border-radius: 8px;
  color: ${({ isCancel }) => (isCancel ? colors.Bl07 : colors.White)};
  line-height: 22px;
  font-size: 14px;
  font-weight: 600;
`;
