import { BrowserRouter, Routes, Route } from "react-router-dom";
import { GoogleOAuthProvider } from "@react-oauth/google";

// components
import Login from "./pages/login";
//import NewLogin from "./pages/NewLogin";
import NaverLogin from "./pages/naverLogin";
import LoginCheck from "./pages/login_check";
import LoginCheck2 from "./pages/login_check2";
import SignUp from "./pages/signup";
import PartnerInfoPolicy from "./pages/파트너_개인정보_처리방침";
import PartnerPayInfo from "./pages/파트너_결제약관";
import PartnerUseInfo from "./pages/파트너_이용약관";
import PartnerRegisterAccount from "./pages/partner_register_account";
import PartnerRegisterProfile from "./pages/partner_register_profile";
import PartnerSchedule from "./pages/partner_schedule";
import PartnerTip from "./pages/PartnerTip";
import PartnerRegister from "./pages/PartnerRegister";
import PartnerRegisterNew from "./pages/PartnerRegisterNew";
import PartnerInvite from "./pages/PartnerInvite";

import Home from "./pages/Home";
import Profile from "./pages/Profile";
import ProfileRevise from "./pages/ProfileRevise";
import AnnouncementDetail from "./pages/announcement_detail";
import AnnouncementList from "./pages/announcement_list";
import AlarmList from "./pages/alarm_list";
import FaqList from "./pages/faq_list";
import ReviewList from "./pages/partner_review_list";
import ReviewReport from "./components/review/review_report";
import DayOff from "./pages/DayOff";
import Settings from "./pages/Settings";
import MyInfo from "./pages/MyInfo";
import AccountRevise from "./pages/AccountRevise";
import AffiliateInfo from "./pages/AffiliateInfo";

import Chat from "./pages/chat";
import ChatList from "./pages/chat_list";

import ReservationManage from "./pages/reservation_manage";
import MyCalender from "./pages/my_calender";
import AddSchedule from "./pages/add_schedule";
import ReservationDetail from "./pages/reservation_detail";
import CancelActivityDetail from "./pages/CancelActivityDetail";

import ActivityManage from "./pages/activity_manage";
import CenterFaq from "./pages/CenterFaq";
import SemosScheduleCalender from "./pages/semos_schedule_calender";
import SemosScheduleAdd from "./pages/semos_schedule_add";
import SemosScheduleRevise from "./pages/semos_schedule_revise";
//import PartnerPdf from "./pages/partner_using";
import ActivityRegister from "./pages/ActivityRegister";
import NewActivity from "./pages/NewActivity";

// facility
import Facility from "./pages/Facility";
import NewFacility from "./pages/NewFacility";
import FacilityDetail from "./pages/FacilityDetail";
import FacilityReservation from "./pages/FacilityReservation";
import FacilityReservationU from "./pages/FacilityReservationU";
import FacilityEntrance from "./pages/FacilityEntrance";
import ClassReservation from "./pages/ClassReservation";
import Present from "./pages/Present";

import Crew from "./pages/Crew";
import CrewDetail from "./pages/CrewDetail";
import NewCrew from "./pages/NewCrew";

import CrewCoach from "./pages/CrewCoach";
import NewCrewCoach from "./pages/NewCrewCoach";
import CrewDetailCoach from "./pages/CrewDetailCoach";

import Notice from "./pages/Notice";
import NewNotice from "./pages/NewNotice";
import NoticeDetail from "./pages/NoticeDetail";

import Consult from "./pages/Consult";
import ConsultDetail from "./pages/ConsultDetail";

import SubscribeManage from "./pages/SubscribeManage";


// style
// import "./styles/Reset.css";
import "./styles/App.css";
import GlobalStyle from "./styles/globalStyles";
import GlobalLayout from "./components/layouts/globalLayout";

const { Kakao } = window;
Kakao.init(process.env.REACT_APP_kakao);

const google_client_id = process.env.REACT_APP_google_client_id;

const App = () => {
  return (
    <GoogleOAuthProvider clientId={google_client_id}>
      <GlobalStyle />
      <BrowserRouter>
        <GlobalLayout>
          <Routes>

            <Route path="/" element={<Login />} />
            <Route path="/signup" element={<SignUp />} />
            <Route path="/partner_info_policy" element={<PartnerInfoPolicy />} />
            <Route path="/partner_use_info" element={<PartnerUseInfo />} />
            <Route path="/partner_pay_info" element={<PartnerPayInfo />} />
            <Route path="/partner_register" element={<PartnerRegister />} />
            <Route path="/home" element={<Home />} />
            <Route path="/partner" element={<Home />} />
            <Route path="/profile" element={<Profile />} />
            <Route path="/profile_revise" element={<ProfileRevise />} />
            <Route path="/announcement" element={<AnnouncementList />} />
            <Route path="/announcement_detail/:id" element={<AnnouncementDetail />} />
            <Route path="/faq" element={<FaqList />} />
            <Route path="/center_faq" element={<CenterFaq />} />
            <Route path="/review_list" element={<ReviewList />} />
            <Route path="/review_report/:r" element={<ReviewReport />} />
            <Route path="/account_info_revise" element={<AccountRevise />} />
            <Route path="/reservation_manage" element={<ReservationManage />} />
            <Route path="/activity_manage" element={<ActivityManage />} />
            <Route path="/new_activity" element={<NewActivity />} />
            <Route path="/activity_revise/:id/:type" element={<ActivityRegister />} />
            <Route path="/reservation_detail/:id" element={<ReservationDetail />} />
            <Route path="/reservation_cancel_detail/:id" element={<CancelActivityDetail />} />
            <Route path="/day_off" element={<DayOff />} />
            <Route path="/my_info" element={<MyInfo />} />
            <Route path="/settings" element={<Settings />} />

            <Route path="/consult" element={<Consult />} />
            <Route path="/consult_detail/:id" element={<ConsultDetail />} />
            
            {/* 시설 관리 */}
            <Route path="/facility" element={<Facility />} />
            <Route path="/new_facility" element={<NewFacility />} />
            <Route path="/facility_detail/:id" element={<FacilityDetail />} />
            <Route path="/facility_revise/:id" element={<NewFacility />} />
            <Route path="/facility_reservation" element={<FacilityReservation />} />
            <Route path="/facility_reservation_u/:id" element={<FacilityReservationU />} />
            <Route path="/facility_entrance" element={<FacilityEntrance />} />
            <Route path="/class_reservation" element={<ClassReservation />} />
            <Route path="/present/:id" element={<Present />} />

            {/* 시설 회원 */}
            <Route path="/crew" element={<Crew />} />
            <Route path="/new_crew" element={<NewCrew />} />
            <Route path="/crew_detail/:id" element={<CrewDetail />} />
            <Route path="/crew_revise/:id" element={<NewCrew />} />

            <Route path="/crew_coach" element={<CrewCoach />} />
            <Route path="/new_crew_coach" element={<NewCrewCoach />} />
            <Route path="/crew_detail_coach/:id" element={<CrewDetailCoach />} />
            <Route path="/crew_revise_coach/:id" element={<NewCrewCoach />} />
            
            <Route path="/notice" element={<Notice />} />
            <Route path="/new_notice" element={<NewNotice />} />
            <Route path="/notice_revise/:id" element={<NewNotice />} />
            <Route path="/notice_detail/:id" element={<NoticeDetail />} />


            { /* GA 이벤트 미입력 페이지 */}
            <Route path="/login_check" element={<LoginCheck />} />
            <Route path="/login_check2" element={<LoginCheck2 />} />
            <Route path="/naver" element={<NaverLogin />} />
            <Route path="/subscribe_manage" element={<SubscribeManage />} />

            { /* 안쓰는 페이지 */}
            {/*<Route path="/new_login" element={<NewLogin />} />
            <Route path="/" element={<NewLogin />} />*/}
            <Route path="/partner_invite" element={<PartnerInvite />} />
            <Route path="/partner_tip" element={<PartnerTip />} />
            <Route path="/partner_register_new/:pages" element={<PartnerRegisterNew />} />
            <Route path="/partner_register_account" element={<PartnerRegisterAccount />} />
            <Route path="/partner_register_profile" element={<PartnerRegisterProfile />} />
            <Route path="/partner_register_schedule" element={<PartnerSchedule />} />
            <Route path="/alarm" element={<AlarmList />} />
            <Route path="/chat/:n" element={<Chat />} />
            <Route path="/chat_list" element={<ChatList />} />
            <Route path="/my_calender" element={<MyCalender />} />
            <Route path="/add_schedule/:y/:m/:d" element={<AddSchedule />} />
            <Route path="/semos_activity_calender" element={<SemosScheduleCalender />}/>
            <Route path="/semos_schedule_add" element={<SemosScheduleAdd />} />
            <Route path="/semos_schedule_revise/:id" element={<SemosScheduleRevise />} />
            <Route path="/affiliate_info" element={<AffiliateInfo />} />

          </Routes>
        </GlobalLayout>
      </BrowserRouter>
    </GoogleOAuthProvider>
  );
};

export default App;
