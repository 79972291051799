import React from "react";
import styled from "styled-components";
import Modal from "react-modal";

// style
import colors from "../../styles/colors";

//img
import PartnerTipSkipAlert from "../../icon/partner_tip_skip_alert.png";
import { W } from "../../styles/globalStyles";

const SkipModal = ({ modal, setModal }) => {
  return (
    <Modal
      isOpen={modal}
      onRequestClose={() => setModal()}
      contentLabel="Alert Modal"
      style={customStyles}
    >
      <Container>
        <Title>이미 세모스 코디를 신청하셨어요</Title>
        <Space h={8} />
        <SmallTitle>
          <span>세모스가 열심히 입점 진행중이니</span>
          <span>영업일 기준 최대 3일 이내로 연락드릴게요</span>
        </SmallTitle>
        <Space h={56} />
        <Icon src={PartnerTipSkipAlert} />
        <Space h={56} />

        <Button c={colors.White} bg={colors.Bl07} onClick={() => setModal()}>
          확인
        </Button>
      </Container>
    </Modal>
  );
};

export default SkipModal;

const customStyles = {
  content: {
    width: `${W - 48}px`,
    marginRight: "-50%",
    padding: "48px 24px 20px 24px",
    borderRadius: "16px",
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    transform: "translate(-50%, -50%)",
  },
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Icon = styled.img`
  width: 100%;
`;

const Title = styled.div`
  color: ${colors.Gr05};
  text-align: center;
  line-height: 142%;
  letter-spacing: -0.02em;
  font-size: 18px;
  font-weight: 700;
`;

const SmallTitle = styled.div`
  display: inline-flex;
  flex-wrap: wrap;
  text-align: center;
  line-height: 142%;
  letter-spacing: -0.02em;
  font-size: 12px;
  font-weight: 700;

  span {
    width: 100%;
    color: ${colors.Gr03};
  }

  .blue-text {
    color: ${colors.Bl05};
  }
`;

const Space = styled.div`
  height: ${({ h }) => h}px;
`;

const Button = styled.div`
  display: flex;
  justify-content: center;
  width: ${({ width }) => (width ? width : "100%")};
  padding: 14px 0;
  background-color: ${({ bg }) => bg};
  border-radius: 8px;
  cursor: pointer;
  color: ${({ c }) => c};
  line-height: 142%;
  font-size: 14px;
  font-weight: 700;
`;
