import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";

// components
import { H, W, WIDTH } from "../../styles/globalStyles";
import colors from "../../styles/colors";
import Modal from "../../components/review/Modal";

// img
import ArrowIcon from "../../icon/reviewArrow.png";
import FullStar from "../../icon/fullStar.png";
import EmptyStar from "../../icon/emptyStar.png";
import DefaultProfilre from "../../icon/semoring_tiger.jpg";



const ReviewItem = ({ data, index }) => {
  const [open, setOpen] = useState(false);
  const [feedback, setFeedback] = useState(false);
  const [reviewImageWH, setReviewImageWH] = useState([]);

  const rate = Number(data.rating);
  const fullstar = new Array(rate).fill(1);
  const emptystar = new Array(5 - rate).fill(1);
  const navigate = useNavigate();


  /*useEffect(() => {
    const content = document.getElementsByClassName("revcont");
    if (content[index].clientHeight > 46) {
      setShow(true);
    } else {
      setShow(false);
    }
  }, []);*/

  return (
    <>
      <ReviewItemContainer id={`rev_${index}`}>
        <ReviewTitleContainer
          onClick={() =>
            navigate(`/reservation_detail/${data.payment_number}`, {
              state: {
                from: "",
              },
            })
          }
        >
          <ReviewTitle>{data.service_name}</ReviewTitle>
          <ReviewArrow src={ArrowIcon} />
        </ReviewTitleContainer>
        <ReviewContentContainer>
          <ReviewerInfo>
            <ReviewerImage
              src={
                data.user_profile && data.user_profile !== "가입미완"
                  ? data.user_profile
                  : DefaultProfilre
              }
            />
            <InfoWrap>
              <JustBetween>
                <ReviewerName>
                  {data.user_name}
                  <Nim>&nbsp;님</Nim>
                </ReviewerName>
                <Report
                  style={{ cursor: "pointer" }}
                  onClick={() => navigate(`/review_report/${data.number}`)}
                >
                  신고
                </Report>
              </JustBetween>

              <Divider h={4} />

              <JustBetween>
                <StarContainer>
                  {fullstar &&
                    fullstar.map((_, index) => (
                      <StarIcon
                        src={FullStar}
                        key={index.toString()}
                        last={emptystar.length ? false : index === 4}
                      />
                    ))}
                  {emptystar &&
                    emptystar.map((_, index) => (
                      <StarIcon
                        src={EmptyStar}
                        key={index.toString()}
                        last={
                          emptystar.length ? emptystar.length - 1 == index : false
                        }
                      />
                    ))}
                  <RateText>{rate}점</RateText>
                </StarContainer>
                <Report>{data.user_review_date}</Report>
              </JustBetween>
            </InfoWrap>
          </ReviewerInfo>
          <Divider h={10} />
          <ReviewImageContainer>
            {data &&
              data?.img_list.map((item, idx) => (
                <ReviewImgContent index={idx} key={`${idx}_reviewImage`}>
                  <ReviewImg
                    WH={reviewImageWH && reviewImageWH[idx]}
                    src={item}
                  />
                </ReviewImgContent>
              ))}
          </ReviewImageContainer>
          {/* {open ? ( */}
          <ReviewContentOpen className="revcont" open={open}>
            {data.user_review}
          </ReviewContentOpen>

          <Divider h={20} />

          {
            (data.partner_feedback ?
              <>
                <ReviewPartner>{data.partner_name}</ReviewPartner>
                <ReviewContentOpen className="revcont" open={open}>
                  {data.partner_feedback}
                </ReviewContentOpen>
              </> 
            :
              <MoreButton onClick={() => setFeedback(true)}>
                답글 달기
              </MoreButton>
            )
          }


        </ReviewContentContainer>
      </ReviewItemContainer>

      <Modal 
        modal={feedback}
        setModal={setFeedback}
        RI={data.number} 
        RU={data.user_name} 
        />
    </>
  );
};

export default ReviewItem;

const ReviewItemContainer = styled.div`
  border: 1px solid ${colors.Gr02};
  border-radius: 14px;
  margin-bottom: 16px;
`;

const ReviewTitleContainer = styled.div`
  padding: 14px 13px 12px 16px;

  border-bottom: 1px solid ${colors.Gr02};
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
`;

const ReviewTitle = styled.div`
  font-weight: 600;
  font-size: 14px;
  line-height: 142%;
  color: ${colors.Gr05};
  max-width: ${W - 101}px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;

const ReviewArrow = styled.img`
  width: 24px;
  height: 24px;
`;

const ReviewContentContainer = styled.div`
  padding: 16px 16px 26px 16px;
  position: relative;
`;

const ReviewerInfo = styled.div`
  display: flex;
  align-items: center;
`;

const InfoWrap = styled.div`
  width: 100%;
`;

const JustBetween = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* width: ${W - (48 + 90)}px; */
  width: 100%;
`;

const ReviewerImage = styled.img`
  width: 44px;
  height: 44px;
  margin-right: 14px;
  border-radius: 50%;
`;

const ReviewerName = styled.div`
  font-weight: 600;
  font-size: 14px;
  line-height: 142%;
  color: ${colors.Gr05};
`;

const Nim = styled.span`
  font-weight: 500;
  font-size: 14px;
  line-height: 142%;
  letter-spacing: -0.02em;
  color: ${colors.Gr04};
`;

const Report = styled.div`
  font-weight: 500;
  font-size: 12px;
  line-height: 142%;
  display: flex;
  align-items: center;
  text-align: right;
  letter-spacing: -0.02em;
  color: ${colors.Gr03};
`;

const StarContainer = styled.div`
  display: flex;
  /* align-items: center; */
`;

const StarIcon = styled.img`
  width: 16px;
  height: 16px;
  margin-right: ${({ last }) => (last ? 4 : 2)}px;
`;

const RateText = styled.div`
  display: inline-block;
  font-weight: 500;
  font-size: 12px;
  line-height: 142%;
  letter-spacing: -0.02em;
  color: ${colors.Bl07};
  /* vertical-align: bottom; */
`;

const Divider = styled.div`
  height: ${({ h }) => h}px;
`;

const ReviewImageContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-bottom: 16px;
  width: 100%;
`;

const ReviewImgContent = styled.div`
  /* width: 23%;
  height: ${(W - 80) * 0.23}px; */
  background-color: ${colors.White};
  ${({ index }) => index !== 3 && `margin-right : ${((W - 80) * 0.078) / 3}px;`}
  display : inline-block;
  position: relative;
  overflow: hidden;
  @media screen and (min-width: 1200px) {
    width: 27%;
    height: ${(WIDTH - 80) * 0.21}px;
  }
  @media screen and (max-width: 1200px) {
    width: 23%;
    height: ${(W - 80) * 0.23}px;
  }
`;

const ReviewImg = styled.img`
  width: 100%;
  object-fit: cover;
  background-color: aliceblue;
  position: absolute;
  top: 50%;
  right: 50%;
  transform: translate(50%, -50%);
`;

const ReviewPartner = styled.div`
  font-weight: 600;
  font-size: 14px;
  line-height: 142%;
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-block;
  color: ${colors.Gr04};
`;

const ReviewContentOpen = styled.div`
  font-weight: 500;
  font-size: 12px;
  line-height: 142%;

  display: flex;

  letter-spacing: -0.02em;
  color: ${colors.Gr03};
`;

const MoreButton = styled.div`
  position: absolute;
  cursor: pointer;
  bottom: 16px;
  right: 16px;
  font-weight: 500;
  font-size: 14px;
  line-height: 142%;
  /* identical to box height, or 20px */

  text-align: right;
  letter-spacing: -0.02em;

  /* Greyscale/Gr 01 */

  color: ${colors.Gr03};
`;
