import { useEffect, useState } from "react";
import styled, { css } from "styled-components";

// import useWidth from "../../hooks/useWidth";

// style
import { W, PC, WIDTH, useWindowSize, useReactPath } from "../../styles/globalStyles";

const GlobalLayout = ({ children }) => {

  const [size, setSize] = useState(useReactPath());
  const [width, setWidth] = useState(useWindowSize());

  let SIZ = useReactPath();
  let WID = useWindowSize();

  useEffect(() => {
    setSize(SIZ);
  }, [SIZ]);
  
  useEffect(() => {
    setWidth(WID);
  }, [WID]);

  return (
    <Container size={size}>
      <Wrap size={size} width={width}>{children}</Wrap>
    </Container>
  );
};

export default GlobalLayout;

const Container = styled.div`
  /* padding: 0 50px; */

  @media screen and (min-width: ${({ size }) => (size ? `${size}px` : "0px")}) {
    /* overflow: auto;
    height: 100vh; */
    justify-content: center;
    align-items: center;
    display: flex;
  }
`;
const Wrap = styled.div`
  /* width: 100%; */
  /* height: 100vh; */
  @media screen and (min-width: ${({ size }) => (size ? `${size}px` : "0px")}) {
    width: ${({ width }) => (width ? `${width}px` : "0px")};
    position: relative;
  }
`;
