import React, { useState, useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import styled from "styled-components";

//tool
import { localeStringMaker, localeToNumber } from "../../tool/regex";

//style
import { Wrapper, Text, Input, AbsoluteWrapper } from "../layouts/layoutStorage";
import colors from "../../styles/colors";

//component
import ActivityModal from "./NewActivityModal";
import ClassPrice from "./ClassPrice";
import CountPrice from "./CountPrice";

//icon
import { ReactComponent as Arrow } from "../../resource/icon/arrow.svg";
import { ReactComponent as Plus } from "../../resource/icon/plus.svg";

// price = 0,000,000,000
const MAX_PRICE_VIEW_LENGTH = 13;
const MAX_PRICE_LENGTH = 10;
const MIN_PRICE_LENGTH = 5;
const TypeEng = {
  "정기 레슨" : "month",
  "이용권(횟수)" : "count",
  "이용권(기간)" : "month",
  "단기 레슨" : "people",
  "국내/해외 투어" : "people",
  "자격증" : "people",
  "장비 대여" : "people"
}
const MonthCount = [1, 3, 5, 7, 10, 11, 20, 22, 30, 33, 40, 50, 100];

const ActivityPriceModal = ({ data, setData, open, setOpen, deleteD, setDelete, MCCount, setMCCount, setChange }) => {
  const minRef = useRef();
  const maxRef = useRef();

  const [dropdown, setDropdown] = useState({
    min: false,
    max: false,
  });
  const minHandleClose = (e) => {
    if (dropdown.min && (!minRef.current || !minRef.current.contains(e.target))) {
      setDropdown({
        min: false,
        max: false,
      });
    }
  };
  const maxHandleClose = (e) => {
    if (dropdown.max && (!maxRef.current || !maxRef.current.contains(e.target))) {
      setDropdown({
        min: false,
        max: false,
      });
    }
  };

  const [focusPriceIndex, setFocusPriceIndex] = useState("");
  const [currentPrice, setCurrentPrice] = useState(0);

  const PriceTypeReturn = () => {
    var Type = data.activity_type;
    var PriceType = TypeEng[Type];

    if(PriceType) {
      return PriceType
    } else {
      return ""
    }
  };


  useEffect(() => {
    window.addEventListener("click", minHandleClose);

    return () => {
      window.removeEventListener("click", minHandleClose);
    };
  }, [dropdown.min]);

  useEffect(() => {
    window.addEventListener("click", maxHandleClose);

    return () => {
      window.removeEventListener("click", maxHandleClose);
    };
  }, [dropdown.max]);


  const [priceData, setPriceData] = useState({
    min_capacity: 1,
    max_capacity: 1,
    min_age: "",
    price: {
      1: { id: "new", price: "", weekend_price : "" },
    }
  });

  useEffect(() => {
    if (open) {
      setPriceData({
        min_capacity: data.min_capacity,
        max_capacity: data.max_capacity,
        min_age: data.min_age,
        price: { ...data.price },
      });
    }
  }, [open]);

  useEffect(() => {
    if (open && (PriceTypeReturn() == 'people')) {
      let PriceList = { ...data.price };

      for (let i = Number(priceData.min_capacity); i < Number(priceData.max_capacity) + 1; i++) {
        let PriceValue = PriceList[String(i)];

        if (!PriceValue) {
          PriceList[String(i)] = {
            id: "new",
            price: "",
            weekend_price: "",
          };
        } else {
          PriceList[String(i)] = {
            id: PriceList[String(i)].id,
            price: PriceList[String(i)].price,
            weekend_price: PriceList[String(i)].weekend_price,
          };
        }
      }
      setPriceData((prev) => ({ ...prev, price: PriceList }));
    }
  }, [priceData.min_capacity, priceData.max_capacity]);

  const PriceHandler = (e, priceData, idx, type) => {

    if(type == 'price') { 
      let PriceList = priceData.price;

      priceData.price[`${priceData.min_capacity + idx}`] = {
        id: priceData.price[`${priceData.min_capacity + idx}`].id,
        price: localeStringMaker(e.target.value).substring(0, MAX_PRICE_VIEW_LENGTH),
        weekend_price: priceData.price[`${priceData.min_capacity + idx}`].weekend_price
      };
  
      setPriceData((prev) => ({ ...prev, price: PriceList }));
    } else {
      let PriceList = priceData.price;

      priceData.price[`${priceData.min_capacity + idx}`] = {
        id: priceData.price[`${priceData.min_capacity + idx}`].id,
        price: priceData.price[`${priceData.min_capacity + idx}`].price,
        weekend_price: localeStringMaker(e.target.value).substring(0, MAX_PRICE_VIEW_LENGTH),
      };
  
      setPriceData((prev) => ({ ...prev, price: PriceList }));
    }

  }

  const AutoPrice = async (idx, type) => {
    setFocusPriceIndex("")

    if(type == 'price') {
      let OnePrice = localeToNumber(priceData.price[1].price);
      let OnePriceChange = OnePrice != currentPrice;
      let PriceList = priceData.price;
      if(idx == 0) {
        setCurrentPrice(OnePrice);
  
        if(OnePriceChange) {
          for (let i=1; i < Object.keys(PriceList).length; i++) {
            let PR = localeStringMaker(OnePrice * (i+1)).substring(0, MAX_PRICE_VIEW_LENGTH)
            PriceList[i+1].price = PR;
          }
          setPriceData((prev) => ({ ...prev, price: PriceList }));
        }
      }
    } else {
      let OnePrice = localeToNumber(priceData.price[1].weekend_price);
      let OnePriceChange = OnePrice != currentPrice;
      let PriceList = priceData.price;
      if(idx == 0) {
        setCurrentPrice(OnePrice);
  
        if(OnePriceChange) {
          for (let i=1; i < Object.keys(PriceList).length; i++) {
            let PR = localeStringMaker(OnePrice * (i+1)).substring(0, MAX_PRICE_VIEW_LENGTH)
            PriceList[i+1].weekend_price = PR;
          }
          setPriceData((prev) => ({ ...prev, price: PriceList }));
        }
      }
    }
  }

  const AddMonthsPrice = () => {
    if(MCCount < 12) {
      let PriceList = priceData?.price;

      const MonthList =  Object.keys(PriceList);
      const MonthLength = MonthList.length;
      let MaxMonth = Number(MonthList[(MonthLength - 1)]) + 1;

      if(MaxMonth > 12) {
        for(let i=1; i < 12; i++) {
          let ChangeMaxMonth = MaxMonth - (i + 1);

          if(!PriceList[`${ChangeMaxMonth}`]) {
            MaxMonth = ChangeMaxMonth;
            break;
          }
        }
      }

      PriceList[`${MaxMonth}`] = { id: "new", price: "", weekend_price : "" };

      setPriceData((prev) => ({ ...prev, price: PriceList }));
      setMCCount(Number(MCCount) + 1);
    } else {
      window.alert('더이상 판매금액을 추가할 수 없습니다! (최대 12개)');
    }
  }

  const AddCountsPrice = () => {
    if(MCCount < 13) {
      let PriceList = priceData?.price;

      const MonthList =  Object.keys(PriceList);

      let ValidList = MonthCount.filter((item, index) => 
        MonthList.indexOf(String(item)) == -1
      )

      const MaxCount = ValidList[0];

      PriceList[`${MaxCount}`] = { id: "new", price: "", weekend_price : "" };

      setPriceData((prev) => ({ ...prev, price: PriceList }));
      setMCCount(Number(MCCount) + 1);
    } else {
      window.alert('더이상 판매금액을 추가할 수 없습니다! (최대 13개)');
    }
  }

  const LoadPriceSelector = () => {

    const Type = PriceTypeReturn();

    switch(Type) {
      case 'people': 
        return (
          <Wrapper padding="16px 0 8px 0" width="100%" fd="column" ai="flex-start">
            <ModalSubTitle>인당 판매금액</ModalSubTitle>
            <TipWrap>
              <span className="tip">Tip! </span>
              <span>예약 인원 별로 할인될 총액을 적어주세요. 예시) 1인 150,000 2인 250,000</span>
            </TipWrap>
            <Wrapper fd="column" margin="16px 0 8px 0" width="100%" gap="8px">
                <Wrapper  width="100%" gap="12px">
                  <Text ws="pre" size="16px" weight="500" lh="24px" ls="-0.32px" color={colors.White}>
                    1인
                  </Text>
                  <Wrapper
                    height="auto"
                    width="100%"
                    radius="8px"
                    padding="0 4px"
                    jc="center"
                    ai="center"
                  >
                    <Input
                      type="tel"
                      ta='center'
                      disabled={true}
                      width="100%"
                      value={"평일"}
                      maxLength={13}
                      size="16px"
                      weight="600"
                      lh="22px"
                      ls="-0.28px"
                      phColor={colors.Gr03}
                      placeholder="평일"
                    />
                  </Wrapper>
                  <Text ws="pre" size="16px" weight="500" lh="24px" ls="-0.32px" color={colors.White}>
                    원
                  </Text>

                  <Wrapper
                    height="auto"
                    width="100%"
                    radius="8px"
                    padding="0 4px"
                    jc="center"
                    ai="center"
                  >
                    <Input
                      type="tel"
                      ta='center'
                      disabled={true}
                      width="100%"
                      value={"주말"}
                      maxLength={13}
                      size="16px"
                      weight="600"
                      lh="22px"
                      ls="-0.28px"
                      phColor={colors.Gr03}
                      placeholder="주말"
                    />
                  </Wrapper>
                  <Text ws="pre" size="16px" weight="500" lh="24px" ls="-0.32px" color={colors.White}>
                    원
                  </Text>
                </Wrapper>

              {[...Array(1 + priceData.max_capacity - priceData.min_capacity)].map((_, idx) => (
                <Wrapper key={`${idx}_price`} width="100%" gap="12px">
                  <Text ws="pre" size="16px" weight="500" lh="24px" ls="-0.32px" color={colors.Gr04}>
                    {priceData.min_capacity + idx}인
                  </Text>
                  <Wrapper
                    height="44px"
                    width="100%"
                    border={`1px solid ${focusPriceIndex === idx ? colors.Bl07 : colors.Gr02}`}
                    radius="8px"
                    padding="0 16px"
                    jc="center"
                    ai="center"
                  >
                    <Input
                      type="tel"
                      onFocus={() => setFocusPriceIndex(idx) }
                      onBlur={() => AutoPrice(idx, 'price')}
                      width="100%"
                      value={priceData.price[[`${priceData.min_capacity + idx}`]] ? priceData.price[`${priceData.min_capacity + idx}`].price : ""}
                      onChange={(e) => PriceHandler(e, priceData, idx, 'price')}
                      maxLength={13}
                      size="14px"
                      weight="500"
                      lh="22px"
                      ls="-0.28px"
                      phColor={colors.Gr03}
                      placeholder="판매 금액을 적어주세요"
                    />
                  </Wrapper>
                  <Text ws="pre" size="16px" weight="500" lh="24px" ls="-0.32px" color={colors.Gr04}>
                    원
                  </Text>

                  <Wrapper
                    height="44px"
                    width="100%"
                    border={`1px solid ${focusPriceIndex === `week_${idx}` ? colors.Bl07 : colors.Gr02}`}
                    radius="8px"
                    padding="0 16px"
                    jc="center"
                    ai="center"
                  >
                    <Input
                      type="tel"
                      onFocus={() => setFocusPriceIndex(`week_${idx}`) }
                      onBlur={() => AutoPrice(idx, 'weekend_price')}
                      width="100%"
                      value={priceData.price[[`${priceData.min_capacity + idx}`]] ? priceData.price[`${priceData.min_capacity + idx}`].weekend_price : ""}
                      onChange={(e) => PriceHandler(e, priceData, idx, 'weekend_price')}
                      maxLength={13}
                      size="14px"
                      weight="500"
                      lh="22px"
                      ls="-0.28px"
                      phColor={colors.Gr03}
                      placeholder="판매 금액을 적어주세요"
                    />
                  </Wrapper>
                  <Text ws="pre" size="16px" weight="500" lh="24px" ls="-0.32px" color={colors.Gr04}>
                    원
                  </Text>
                </Wrapper>
              ))}
            </Wrapper>
          </Wrapper>
        );

      case 'month': 
        return (
          <Wrapper padding="16px 0 8px 0" width="100%" fd="column" ai="flex-start">
            <ModalSubTitle>기간별 판매금액</ModalSubTitle>

            <TipWrap>
              <span className="tip">Tip! </span>
              <span>기간별로 할인될 총액을 적어주세요. 예시) 1개월 150,000 2개월 250,000</span>
            </TipWrap>

            <Wrapper fd="column" margin="12px 0 8px 0" width="100%" gap={`5px`}>
              {priceData?.price ? (
                  [...Array(MCCount)].map((_, idx) => {

                    const MonthsKey = String(Object.keys(priceData?.price)[idx]);

                    return (
                      <ClassPrice
                        key={`${idx}_month_price`}
                        index={idx}
                        month={MonthsKey}
                        data={priceData}
                        setData={setPriceData}
                        MCCount={MCCount}
                        setMCCount={setMCCount}
                        deleteD={deleteD}
                        setDelete={setDelete}
                      />
                    )
                  })
                )
                : ""
              }
            </Wrapper>

            <Wrapper width="100%" jc="flex-end" ai={`center`} gap="8px">

              <Text size="16px" weight="500" lh="24px" ls="-0.32px" color={colors.Gr04}>
                기간별 판매금액 추가하기
              </Text>

              <Wrapper
                        border={`2px solid ${colors.Bl07}`}
                        radius="8px"
                        jc="center"
                        ai="center"
                        height="32px"
                        width="32px"
                        cursor={`pointer`}
                        margin={`0 0 0 5px`}
                        onClick={() => AddMonthsPrice()}
                    >
                <Plus width="2.6rem" height="2.6rem" fill={colors.Bl07} />
              </Wrapper>
            </Wrapper>
            
          </Wrapper>
        );

      case 'count': 
        return (
          <Wrapper padding="16px 0 8px 0" width="100%" fd="column" ai="flex-start">
            <ModalSubTitle>횟수별 판매금액</ModalSubTitle>

            <TipWrap>
              <span className="tip">Tip! </span>
              <span>횟수를 설정하고 가격을 적어주세요!</span>
            </TipWrap>

            <Wrapper fd="column" margin="12px 0 8px 0" width="100%" gap={`5px`}>
              {priceData?.price ? (
                  [...Array(MCCount)].map((_, idx) => {

                    const MonthsKey = String(Object.keys(priceData?.price)[idx]);

                    return (
                      <CountPrice
                        key={`${idx}_count_price`}
                        index={idx}
                        month={MonthsKey}
                        data={priceData}
                        setData={setPriceData}
                        MCCount={MCCount}
                        setMCCount={setMCCount}
                        deleteD={deleteD}
                        setDelete={setDelete}
                        MonthCount={MonthCount}
                      />
                    )
                  })
                )
                : ""
              }
            </Wrapper>

            <Wrapper width="100%" jc="flex-end" ai={`center`} gap="8px">

              <Text size="16px" weight="500" lh="24px" ls="-0.32px" color={colors.Gr04}>
                횟수별 판매금액 추가하기
              </Text>

              <Wrapper
                        border={`2px solid ${colors.Bl07}`}
                        radius="8px"
                        jc="center"
                        ai="center"
                        height="32px"
                        width="32px"
                        cursor={`pointer`}
                        margin={`0 0 0 5px`}
                        onClick={() => AddCountsPrice()}
                    >
                <Plus width="2.6rem" height="2.6rem" fill={colors.Bl07} />
              </Wrapper>
            </Wrapper>
            
          </Wrapper>
        );
      
      default :
        return (
          <Wrapper padding="16px 0 8px 0" width="100%" fd="column" ai="flex-start">
            <ModalSubTitle>액티비티 타입을 선택해주세요!</ModalSubTitle>
          </Wrapper>
        );
    }
  }

  const PriceCheck = () => {

    if(priceData?.price) {
      const Type = PriceTypeReturn();

      switch(Type) {
        case 'people': 
          return (
            priceData.min_capacity &&
            priceData.max_capacity &&
            Number(priceData?.price[`${priceData.min_capacity}`].price.replace(/,/g, "")).toString().length <= MAX_PRICE_LENGTH &&
            Number(priceData?.price[`${priceData.min_capacity}`].price.replace(/,/g, "")).toString().length >= MIN_PRICE_LENGTH
          ) 
        case 'month': 
          return (
            priceData.min_capacity &&
            priceData.max_capacity &&
            priceData?.price
          ) 
        case 'count': 
          return (
            priceData.min_capacity &&
            priceData.max_capacity &&
            priceData?.price
          ) 
        default :
          return (
            priceData.min_capacity &&
            priceData.max_capacity &&
            Number(priceData?.price[`${priceData.min_capacity}`].price.replace(/,/g, "")).toString().length <= MAX_PRICE_LENGTH &&
            Number(priceData?.price[`${priceData.min_capacity}`].price.replace(/,/g, "")).toString().length >= MIN_PRICE_LENGTH
          ) 
      }
    }

  }

  return (
    <ActivityModal
      buttonText="저장하기"
      buttonActive={
        PriceCheck()
      }
      title="액티비티 가격"
      open={open}
      setOpen={setOpen}
      saveData={() => {
        setData((prev) => ({
          ...prev,
          min_capacity: priceData.min_capacity,
          max_capacity: priceData.max_capacity,
          price: priceData.price,
        }));
        setOpen(false);
        setChange(true);
      }}
    >
      <Wrapper fd="column" height="100%" width="100%" ai="flex-start" padding="0 0 130px 0">

        <Wrapper padding={'0 24px'} margin="8px 0 12px 0" wrap="wrap">
          <TextP color={colors.Gr04}>
            세모스 내 판매 가격은 타 판매 채널(네이*, 프* 등)보다 낮거나 같아야 합니다. 가격 정책 위반이 확인될 경우
            <span> 예고 없이 액티비티가 비활성화 될 수 있습니다.</span>
          </TextP>
        </Wrapper>

        <Wrapper padding={'0 24px 24px 24px'} height="100%" fd="column" width="100%" ai="flex-start" of="auto">
          <Wrapper padding="16px 0 8px 0" width="100%" fd="column" ai="flex-start">

            <ModalSubTitle>액티비티 최소/최대 진행 인원</ModalSubTitle>
            <TipWrap>
              <span className="tip">Tip! </span>
              <span>진행이 가능한 최소/최대인원을 입력하세요.</span>
            </TipWrap>

            <Wrapper fd="column" margin="12px 0 8px 0" width="100%">
              <Wrapper width="100%" jc="space-between" gap="8px">
                <Wrapper width="100%">
                  <Text ws="pre" margin="0 12px 0 0" size="14px" weight="500" lh="22px" ls="-0.28px" color={colors.Gr04}>
                    최소
                  </Text>
                  <Wrapper
                    ref={minRef}
                    padding="12px"
                    cursor="pointer"
                    position="relative"
                    onClick={(e) => {
                      e.stopPropagation();
                      setDropdown({
                        min: !dropdown.min,
                        max: false,
                      });
                    }}
                    height="44px"
                    radius="8px"
                    width="100%"
                    bgc={colors.White}
                    border={`1px solid ${colors.Gr02}`}
                    jc="space-between"
                  >
                    <Text size="14px" weight="500" lh="22px" ls="-0.28px" color={colors.Gr05}>
                      {priceData.min_capacity}인
                    </Text>
                    <Arrow
                      width="16px"
                      height="16px"
                      fill={colors.Gr04}
                      style={{ transition: "all ease 0.5s", transform: dropdown.min ? "rotate(-90deg)" : "rotate(90deg)" }}
                    />

                    {dropdown.min && (
                      <AbsoluteWrapper
                        maxHeight="250px"
                        z={1}
                        fd="column"
                        width="100%"
                        top="54px"
                        left="0"
                        bgc={colors.White}
                        border={`1px solid ${colors.Gr02}`}
                        radius="8px"
                        of="auto"
                      >
                        {[...Array(20)].map((x, idx) => (
                          <DropDown
                          key={`${idx}_min_capacity`}
                            last={idx + 1 === 20}
                            onClick={() => {
                              setPriceData((prev) => ({
                                ...prev,
                                min_capacity: idx + 1,
                                max_capacity: idx + 1 > priceData.max_capacity ? idx + 1 : priceData.max_capacity,
                              }));
                            }}
                          >
                            {idx + 1}인
                          </DropDown>
                        ))}
                      </AbsoluteWrapper>
                    )}
                  </Wrapper>
                </Wrapper>

                <Wrapper width="100%">
                  <Text ws="pre" margin="0 12px 0 0" size="14px" weight="500" lh="22px" ls="-0.28px" color={colors.Gr04}>
                    최대
                  </Text>
                  <Wrapper
                    ref={maxRef}
                    padding="12px"
                    cursor="pointer"
                    position="relative"
                    onClick={(e) => {
                      e.stopPropagation();
                      setDropdown({
                        min: false,
                        max: !dropdown.max,
                      });
                    }}
                    height="44px"
                    radius="8px"
                    width="100%"
                    bgc={colors.White}
                    border={`1px solid ${colors.Gr02}`}
                    jc="space-between"
                  >
                    <Text size="14px" weight="500" lh="22px" ls="-0.28px" color={colors.Gr05}>
                      {priceData.max_capacity}인
                    </Text>

                    <Arrow
                      width="16px"
                      height="16px"
                      fill={colors.Gr04}
                      style={{ transition: "all ease 0.5s", transform: dropdown.max ? "rotate(-90deg)" : "rotate(90deg)" }}
                    />

                    {dropdown.max && (
                      <AbsoluteWrapper
                        maxHeight="250px"
                        z={1}
                        fd="column"
                        width="100%"
                        top="54px"
                        left="0"
                        bgc={colors.White}
                        border={`1px solid ${colors.Gr02}`}
                        radius="8px"
                        of="auto"
                      >
                        {[...Array(21 - priceData.min_capacity)].map((_, idx) => (
                          <DropDown
                            key={`${idx}_max_capacity`}
                            onClick={() => {
                              setPriceData((prev) => ({ ...prev, max_capacity: priceData.min_capacity + idx }));
                            }}
                            last={priceData.min_capacity + idx === 20}
                          >
                            {priceData.min_capacity + idx}인
                          </DropDown>
                        ))}
                      </AbsoluteWrapper>
                    )}
                  </Wrapper>
                </Wrapper>

              </Wrapper>

            </Wrapper>
          </Wrapper>

          {
            LoadPriceSelector()
          }
          
        </Wrapper>
      </Wrapper>
    </ActivityModal>
  );
};

export default ActivityPriceModal;

const ModalSubTitle = styled.span`
  word-break: keep-all;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: -0.32px;
  color: ${colors.Gr05};
`;

const TipWrap = styled.p`
  word-break: keep-all;
  font-size: 14px;
  font-weight: 500;
  line-height: 22px;
  letter-spacing: -0.28px;
  color: ${colors.Gr03};
  .tip {
    color: ${colors.Bl05};
  }
`;

const TextP = styled.p`
  font-size: 14px;
  font-weight: 500;
  line-height: 22px;
  letter-spacing: -0.28px;
  color: ${colors.Gr04};
  word-break: keep-all;
  span {
    color: ${colors.Red03};
    word-break: keep-all;
  }
`;

const DropDown = styled.div`
  width: 100%;
  height: 52px;
  padding: 12px;
  border-bottom: ${({ last }) => !last && `1px solid ${colors.Gr02}`};
  color: ${colors.Gr04};
  font-size: 13px;
  font-weight: 500;
  line-height: 142%;
  letter-spacing: -0.26px;
`;
