import React, { useEffect, useState } from "react";
import styled from "styled-components";

import { Text } from "../../components/layouts/layoutStorage";
import { setImageFromFile, CropImage } from "../../tool/ImageCrop";

import { W } from "../../styles/globalStyles";
import colors from "../../styles/colors";

import { ReactComponent as Camera } from "../../resource/icon/camera.svg";
import { ReactComponent as CameraEdit } from "../../resource/icon/camera_edit.svg";

const WC = (W > 600) ? 600 : W; 

const AccountImageUploader = ({ type, data, setData, loading, setLoading, loader }) => {
  // const AccountImageUploader = ({ type, data, setData, valid, setValid }) => {
  const [cropModal, setCropModal] = useState(false);
  const [cropImageSetting, setCropImageSetting] = useState({
    image: "",
    type: "",
    width: 0,
    height: 0,
  });
  const [imageName, setImageName] = useState("");

  // useEffect(() => {
  //   if (data[type]) {
  //     setValid({ ...valid, [type]: true });
  //   } else {
  //     setValid({ ...valid, [type]: false });
  //   }
  // }, []);

  const addImage = (file) => {
    const copy = file;
    setData({ ...data, [type]: copy });
    // setValid({ ...valid, [type]: true });
  };

  return (
    <Container>
      <ImageLabelBox htmlFor={type}>
        {data[type] !== "" ? <BackGroundImg src={data[type]} /> : <></>}
        <AddButton>
          {data[type] ? (
            <CameraEdit width="32px" height="32px" fill={colors.White} />
          ) : (
            <Camera width="32px" height="32px" fill={colors.Gr04} />
          )}
          <Text
            size="12px"
            weight="500"
            lh="20px"
            ls="-0.24px"
            color={data[type] ? colors.White : colors.Gr04}
          >
            {data[type] ? imageName : "이미지 불러오기"}
          </Text>
          <ImageInput
            type="file"
            id={type}
            accept="image/*"
            onChange={({ target: { files } }) => {
              if(loader) {
                setLoading(true)
              }

              setImageName(files[0].name);
              if (files.length) {
                setImageFromFile({
                  file: files[0],
                  setImageUrl: ({ result }) => {
                    setCropImageSetting({
                      image: result,
                      /*width: 2,
                      height: 1,*/
                      type: type,
                    });
                    setCropModal(true);
                    
                    if(loader) {
                      setLoading(false)
                    }
                  },
                });
              } else {
                alert("사진은 1장만 업로드 할 수 있습니다.");
              }
            }}
          />
        </AddButton>
      </ImageLabelBox>

      <CropImage
        open={cropModal}
        setOpen={setCropModal}
        setting={cropImageSetting}
        setSetting={setCropImageSetting}
        setImageFunc={addImage}
      />
    </Container>
  );
};

export default AccountImageUploader;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 16px;
  padding: 8px 0px;
`;

const ImageLabelBox = styled.label`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: calc((${WC}px - 48px));
  height: calc((${WC}px - 48px) / 2);
  background-color: ${colors.White};
  border: 1px solid ${colors.Gr02};
  border-radius: 8px;
  position: relative;
  cursor: pointer;
`;

const AddButton = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 11;
`;

const ImageInput = styled.input`
  display: none;
`;

const BackGroundImg = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 8px;
  position: absolute;
  top: 0;
  left: 0;
`;
