import React, { useState } from "react";
import styled from "styled-components";

import RegisterInput from "./RegisterInput";

import colors from "../../styles/colors";
import ImageUploader from "./ImageUploader";

const PartnerForm = ({ STEP_LIST, step, setStep, data, setData, setNext }) => {
  const [valid, setValid] = useState({
    idNumber: false,
    idCard: false,
  });

  console.log("VALID >> ", valid);

  return (
    <Container>
      <RegisterTitle>{STEP_LIST[step.name].title}</RegisterTitle>

      {step.index === 5 ? (
        <ImageUploader
          type="idCard"
          STEP_LIST={STEP_LIST}
          step={step}
          setStep={setStep}
          data={data}
          setData={setData}
          setNext={setNext}
          valid={valid}
          setValid={setValid}
        />
      ) : (
        <></>
      )}

      {step.index === 4 ? (
        <RegisterInput
          type="idNumber"
          placeholder="주민등록번호를 입력해주세요"
          STEP_LIST={STEP_LIST}
          step={step}
          data={data}
          setData={setData}
          setNext={setNext}
          valid={valid}
          setValid={setValid}
        />
      ) : (
        <></>
      )}
    </Container>
  );
};

export default PartnerForm;

const Container = styled.div``;

const RegisterTitle = styled.h2`
  padding: 32px 0 8px 0;
  color: ${colors.Gr06};
  white-space: pre-line;
  line-height: 26px;
  letter-spacing: -0.36px;
  font-size: 18px;
  font-weight: 700;
`;

const UserTypeButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  gap: 16px;
  padding: 12px 0 16px 0;
  background-color: ${colors.White};
`;

const UserTypeButton = styled.button`
  width: 100%;
  height: 52px;
  padding: 8px 16px;
  background-color: ${colors.White};
  border: 1px solid ${colors.Gr02};
  border-radius: 8px;
  color: ${colors.Gr05};
  line-height: 24px;
  letter-spacing: -0.32px;
  font-size: 16px;
  font-weight: 500;
`;
