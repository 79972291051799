import React from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

import { W } from "../../styles/globalStyles";
import colors from "../../styles/colors";

import { ReactComponent as Caution } from "../../resource/icon/caution.svg";

const ActivityRegisterAlertModal = ({ modal, setModal }) => {
  const navigate = useNavigate();

  return (
    <ModalContainer width={W} modal={modal} setModal={setModal}>
      <ModalTitle>
        <h3>
          추가 정보를 입력해야만 <br />
          액티비티 등록이 가능해요!
        </h3>
        <span>
          세모스 유저 앱 내에서 보여질 소개 정보와 <br />
          정산정보 등록이 필요해요
        </span>
      </ModalTitle>

      <ModalContent>
        <Caution fill={colors.Bl07} />
      </ModalContent>

      <ButtonContainer>
        <>
          <Button
            bg={colors.Bl02}
            color={colors.Bl07}
            onClick={() => setModal(false)}
          >
            더 둘러보기
          </Button>
          <Button
            bg={colors.Bl07}
            color={colors.White}
            onClick={() => navigate(`/my_info`)}
          >
            입력하러 가기
          </Button>
        </>
      </ButtonContainer>
    </ModalContainer>
  );
};

export default ActivityRegisterAlertModal;

const ModalContainer = styled.div`
  width: ${({ width }) => `calc(${width}px - 48px)`};
  background-color: ${colors.White};
  padding: 24px 16px 16px 16px;
  border-radius: 12px;
  box-shadow: 0px 1px 7px 0px ${colors.B10}, 0px 7px 23px 0px ${colors.B10};
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const ModalTitle = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;

  h3 {
    color: ${colors.Gr06};
    text-align: center;
    line-height: 26px;
    letter-spacing: -0.36px;
    font-size: 18px;
    font-weight: 700;
  }
  span {
    color: ${colors.Gr04};
    text-align: center;
    line-height: 20px;
    letter-spacing: -0.24px;
    font-size: 12px;
    font-weight: 500;
  }
`;

const ModalContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 24px 0px;

  svg {
    width: 76px;
    height: 76px;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 12px;
  padding-top: 20px;
`;

const Button = styled.button`
  flex: 1;
  padding: 16px;
  background-color: ${({ bg }) => bg};
  border-radius: 8px;
  color: ${({ color }) => color};
  line-height: 22px;
  letter-spacing: -0.32px;
  font-size: 14px;
  font-weight: 600;
`;
