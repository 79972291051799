import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import { loadTossPayments } from '@tosspayments/payment-sdk';

//style
import ActivityModal from "./ModalBase";
import { PC } from "../../styles/globalStyles";

//component
import { Wrapper, Text, Input, WrapperColumn } from "../layouts/layoutStorage";
import colors from "../../styles/colors";
import { GetOne } from "../../tool/localstorage";

//icon

//default data
const Price = {
    s :  11000,
    m :  22000,
    l :  33000,
    xl :  110000
};
const Count = {
    s :  [1, 2, 3, 4],
    m :  [5, 6, 7, 8, 9],
    l :  [10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20],
    xl :  [1],
};

const ActivityLocationModal = ({ open, setOpen }) => {

    const containerRef = useRef(null);

    const [category, setCategory] = useState('m');
    const [people, setPeople] = useState(5);

    const PriceChange = (price) => {
        return price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","); 
    }

    const ClientKey = 'test_ck_DLJOpm5QrlPEAqlnMj1LrPNdxbWn';

    const CardRegister = async () => {

        await loadTossPayments(ClientKey).then(tossPayments => {
          // ------ 카드 등록창 호출 ------
          tossPayments.requestBillingAuth('카드', { // 결제수단 파라미터 (자동결제는 카드만 지원합니다.)
                // 결제 정보 파라미터
                // 더 많은 결제 정보 파라미터는 결제창 Javascript SDK에서 확인하세요.
                // https://docs.tosspayments.com/reference/js-sdk#requestbillingauth카드-결제-정보
                customerKey: 'hGuyGCH6QO8ISxZdrDLry', // 고객 ID로 상점에서 만들어야 합니다. 빌링키와 매핑됩니다. 자세한 파라미터 설명은 결제 정보 파라미터 설명을 참고하세요.
                successUrl: "https://my-store.com/success", // 카드 등록에 성공하면 이동하는 페이지(직접 만들어주세요)
                failUrl: "https://my-store.com/fail",       // 카드 등록에 실패하면 이동하는 페이지(직접 만들어주세요)
                customerName : `${GetOne("nickname")}`,
                customerEmail : `${GetOne("email")}`,
            })
            // ------ 결제창을 띄울 수 없는 에러 처리 ------
            // 메서드 실행에 실패해서 reject 된 에러를 처리하는 블록입니다.
            // 결제창에서 발생할 수 있는 에러를 확인하세요. 
            // https://docs.tosspayments.com/reference/error-codes#결제창공통-sdk-에러
            .catch(function (error) {
                if (error.code === 'USER_CANCEL') {
                // 결제 고객이 결제창을 닫았을 때 에러 처리
                }
            })
        })
    }


    const saveData = () => {
        CardRegister();
    };

    return (
        <ActivityModal
            buttonText="결제수단(카드) 등록하기"
            buttonActive={true}
            title="인원별 월간 결제 금액"
            open={open}
            setOpen={setOpen}
            saveData={saveData}
        >
            <Wrapper fd="column" height="100%" width="100%" ai="flex-start" padding="0 0 130px 0">

                <Text margin="8px 24px 12px 24px" size="1.6rem" weight="600" lh="22px" ls="-0.28px" color={colors.Gr04}>
                    가격표
                </Text>

                <Wrapper ref={containerRef} fd="column" width="100%" height="100%" ai="flex-start" of="auto">

                    <Wrapper 
                        width={`100%`}
                        gap={`.7rem`} 
                        padding={`0 24px`}   
                        ai={`flex-start`}
                        jc={`flex-start`}
                    >
                        <Wrapper 
                            width={`calc((100% - (0.7rem * 1)) / 2)`} 
                            border={`2px solid ${category == 's' ? colors.Bl07 : colors.Gr03}`}
                            radius={`8px`}
                            padding={`.5rem .8rem`}
                            fd={`column`}
                            cursor={`pointer`}
                            position={`relative`}
                            onClick={() => {
                                setCategory('s')
                                setPeople(1)
                            }}
                        >
                            <Text 
                                ta="left"
                                size="1.6rem" 
                                weight="600" 
                                lh="22px" 
                                ls="-0.28px" 
                                color={colors.Gr05}
                                width={`100%`}
                                margin={`0 0 5px 0`}
                            >
                                {`강사 1~4인`}
                            </Text>

                            <Text 
                                ta="left"
                                size="1.4rem" 
                                weight="500" 
                                lh="22px" 
                                ls="-0.28px" 
                                color={colors.Gr04}
                                width={`100%`}
                                margin={`0 0 3px 0`}
                                style={{
                                    textDecoration : "line-through"
                                }}
                            >
                                {`정상가 : 월 3.3만원 (1인)`}
                            </Text>

                            <Text 
                                ta="left"
                                size="1.5rem" 
                                weight="600" 
                                lh="22px" 
                                ls="-0.28px" 
                                color={colors.Bl07}
                                width={`100%`}
                                margin={`0 0 5px 0`}
                            >
                                {`할인가 : 월 1.1만원 (1인)`}
                            </Text>

                            <Text 
                                ta="left"
                                size="1.4rem" 
                                weight="600" 
                                lh="22px" 
                                ls="-0.28px" 
                                color={colors.Gr05}
                                width={`100%`}
                                margin={`0 0 3px 0`}
                            >
                                {`✓ 액티비티 등록`}
                            </Text>
                            <Text 
                                ta="left"
                                size="1.4rem" 
                                weight="600" 
                                lh="22px" 
                                ls="-0.28px" 
                                color={colors.Gr05}
                                width={`100%`}
                                margin={`0 0 3px 0`}
                            >
                                {`✓ 예약 판매 및 관리`}
                            </Text>
                            <Text 
                                ta="left"
                                size="1.4rem" 
                                weight="600" 
                                lh="22px" 
                                ls="-0.28px" 
                                color={colors.Gr05}
                                width={`100%`}
                                margin={`0 0 3px 0`}
                            >
                                {`✓ 자동 리마인더 알림 전송`}
                            </Text>
                            <Text 
                                ta="left"
                                size="1.4rem" 
                                weight="600" 
                                lh="22px" 
                                ls="-0.28px" 
                                color={colors.Gr05}
                                width={`100%`}
                                margin={`0 0 3px 0`}
                            >
                                {`✓ 프로필 홍보 페이지 제공`}
                            </Text>

                            <Wrapper 
                                border={`1px solid ${colors.Gr03}`}
                                bgc={`${category == 's' ? colors.Bl07 : colors.Gr01}`}
                                position={`absolute`}
                                radius={`50%`}
                                width={`1.8rem`}
                                height={`1.8rem`}
                                style={{
                                    top : '.5rem',
                                    right : '.5rem'
                                }}
                            />
                        </Wrapper>

                        <Wrapper 
                            width={`calc((100% - (0.7rem * 1)) / 2)`} 
                            border={`2px solid ${category == 'm' ? colors.Bl07 : colors.Gr03}`}
                            radius={`8px`}
                            padding={`.5rem .8rem`}
                            fd={`column`}
                            cursor={`pointer`}
                            position={`relative`}
                            onClick={() => {
                                setCategory('m')
                                setPeople(5)
                            }}
                        >
                            <Text 
                                ta="left"
                                size="1.6rem" 
                                weight="600" 
                                lh="22px" 
                                ls="-0.28px" 
                                color={colors.Gr05}
                                width={`100%`}
                                margin={`0 0 5px 0`}
                            >
                                {`강사 5~9인`}
                            </Text>

                            <Text 
                                ta="left"
                                size="1.4rem" 
                                weight="500" 
                                lh="22px" 
                                ls="-0.28px" 
                                color={colors.Gr04}
                                width={`100%`}
                                margin={`0 0 5px 0`}
                                style={{
                                    textDecoration : "line-through"
                                }}
                            >
                                {`정상가 : 월 6.6만원 (1인)`}
                            </Text>

                            <Text 
                                ta="left"
                                size="1.4rem" 
                                weight="600" 
                                lh="22px" 
                                ls="-0.28px" 
                                color={colors.Bl07}
                                width={`100%`}
                                margin={`0 0 3px 0`}
                            >
                                {`할인가 : 월 2.2만원 (1인)`}
                            </Text>

                            <Text 
                                ta="left"
                                size="1.4rem" 
                                weight="600" 
                                lh="22px" 
                                ls="-0.28px" 
                                color={colors.Gr05}
                                width={`100%`}
                                margin={`0 0 3px 0`}
                            >
                                {`+ 추가 관리 계정 지원`}
                            </Text>
                            <Text 
                                ta="left"
                                size="1.4rem" 
                                weight="600" 
                                lh="22px" 
                                ls="-0.28px" 
                                color={colors.Gr05}
                                width={`100%`}
                                margin={`0 0 3px 0`}
                            >
                                {`+ 액티비티 등록 지원`}
                            </Text>

                            <Wrapper 
                                border={`1px solid ${colors.Gr03}`}
                                bgc={`${category == 'm' ? colors.Bl07 : colors.Gr01}`}
                                position={`absolute`}
                                radius={`50%`}
                                width={`1.8rem`}
                                height={`1.8rem`}
                                style={{
                                    top : '.5rem',
                                    right : '.5rem'
                                }}
                            />
                        </Wrapper>

                    </Wrapper>

                    <Wrapper 
                        width={`100%`}
                        gap={`.7rem`} 
                        padding={`0 24px`}
                        margin={`.7rem 0 0 0`}
                        ai={`flex-start`}
                        jc={`flex-start`}
                    >
                        <Wrapper 
                            width={`calc((100% - (0.7rem * 1)) / 2)`} 
                            border={`2px solid ${category == 'l' ? colors.Bl07 : colors.Gr03}`}
                            radius={`8px`}
                            padding={`.5rem .8rem`}
                            fd={`column`}
                            cursor={`pointer`}
                            position={`relative`}
                            onClick={() => {
                                setCategory('l')
                                setPeople(10)
                            }}
                        >
                            <Text 
                                ta="left"
                                size="1.6rem" 
                                weight="600" 
                                lh="22px" 
                                ls="-0.28px" 
                                color={colors.Gr05}
                                width={`100%`}
                                margin={`0 0 5px 0`}
                            >
                                {`강사 10인 이상`}
                            </Text>

                            <Text 
                                ta="left"
                                size="1.4rem" 
                                weight="500" 
                                lh="22px" 
                                ls="-0.28px" 
                                color={colors.Gr04}
                                width={`100%`}
                                margin={`0 0 3px 0`}
                                style={{
                                    textDecoration : "line-through"
                                }}
                            >
                                {`정상가 : 월 9.9만원 (1인)`}
                            </Text>

                            <Text 
                                ta="left"
                                size="1.4rem" 
                                weight="600" 
                                lh="22px" 
                                ls="-0.28px" 
                                color={colors.Bl07}
                                width={`100%`}
                                margin={`0 0 5px 0`}
                            >
                                {`할인가 : 월 3.3만원 (1인)`}
                            </Text>

                            <Text 
                                ta="left"
                                size="1.4rem" 
                                weight="600" 
                                lh="22px" 
                                ls="-0.28px" 
                                color={colors.Gr05}
                                width={`100%`}
                                margin={`0 0 3px 0`}
                            >
                                {`+ 제휴 상품 제작`}
                            </Text>
                            <Text 
                                ta="left"
                                size="1.4rem" 
                                weight="600" 
                                lh="22px" 
                                ls="-0.28px" 
                                color={colors.Gr05}
                                width={`100%`}
                                margin={`0 0 3px 0`}
                            >
                                {`+ 세모스 공식 채널 홍보`}
                            </Text>

                            <Wrapper 
                                border={`1px solid ${colors.Gr03}`}
                                bgc={`${category == 'l' ? colors.Bl07 : colors.Gr01}`}
                                position={`absolute`}
                                radius={`50%`}
                                width={`1.8rem`}
                                height={`1.8rem`}
                                style={{
                                    top : '.5rem',
                                    right : '.5rem'
                                }}
                            />
                        </Wrapper>

                        <Wrapper 
                            width={`calc((100% - (0.7rem * 1)) / 2)`} 
                            border={`2px solid ${category == 'xl' ? colors.Bl07 : colors.Gr03}`}
                            radius={`8px`}
                            padding={`.5rem .8rem`}
                            fd={`column`}
                            cursor={`pointer`}
                            position={`relative`}
                            onClick={() => {
                                setCategory('xl')
                                setPeople(1)
                            }}
                        >
                            <Text 
                                ta="left"
                                size="1.6rem" 
                                weight="600" 
                                lh="22px" 
                                ls="-0.28px" 
                                color={colors.Gr05}
                                width={`100%`}
                                margin={`0 0 5px 0`}
                            >
                                {`스포츠 컴플렉스`}
                            </Text>

                            <Text 
                                ta="left"
                                size="1.4rem" 
                                weight="500" 
                                lh="22px" 
                                ls="-0.28px" 
                                color={colors.Gr04}
                                width={`100%`}
                                margin={`0 0 3px 0`}
                                style={{
                                    textDecoration : "line-through"
                                }}
                            >
                                {`정상가 : 월 33만원`}
                            </Text>

                            <Text 
                                ta="left"
                                size="1.4rem" 
                                weight="600" 
                                lh="22px" 
                                ls="-0.28px" 
                                color={colors.Bl07}
                                width={`100%`}
                                margin={`0 0 5px 0`}
                            >
                                {`할인가 : 월 11만원`}
                            </Text>


                            <Text 
                                ta="left"
                                size="1.4rem" 
                                weight="600" 
                                lh="22px" 
                                ls="-0.28px" 
                                color={colors.Gr05}
                                width={`100%`}
                                margin={`0 0 3px 0`}
                            >
                                {`+ 시설 등록 및 관리`}
                            </Text>
                            <Text 
                                ta="left"
                                size="1.4rem" 
                                weight="600" 
                                lh="22px" 
                                ls="-0.28px" 
                                color={colors.Gr05}
                                width={`100%`}
                                margin={`0 0 3px 0`}
                            >
                                {`+ 시설 예약 현황`}
                            </Text>
                            <Text 
                                ta="left"
                                size="1.4rem" 
                                weight="600" 
                                lh="22px" 
                                ls="-0.28px" 
                                color={colors.Gr05}
                                width={`100%`}
                                margin={`0 0 3px 0`}
                            >
                                {`+ 회원 등록 및 관리`}
                            </Text>

                            <Wrapper 
                                border={`1px solid ${colors.Gr03}`}
                                bgc={`${category == 'xl' ? colors.Bl07 : colors.Gr01}`}
                                position={`absolute`}
                                radius={`50%`}
                                width={`1.8rem`}
                                height={`1.8rem`}
                                style={{
                                    top : '.5rem',
                                    right : '.5rem'
                                }}
                            />
                        </Wrapper>
                    </Wrapper>

                    {
                        category != 'xl' ?
                            <>
                                <Text margin="8px 24px 12px 24px" size="1.6rem" weight="600" lh="22px" ls="-0.28px" color={colors.Gr04}>
                                    인원/시설
                                </Text>

                                <Wrapper 
                                    width={`100%`}
                                    gap={`.5rem`} 
                                    padding={`0 24px`}
                                    ai={`flex-start`}
                                    jc={`flex-start`}
                                    wrap={`wrap`}
                                >
                                    {Count[category].map((item, idx) => (
                                        <Wrapper
                                            key={`${idx}_people`}
                                            padding={`.3rem .6rem`}
                                            border={`2px solid ${colors.Bl07}`}
                                            radius={`8px`}

                                        >
                                            <Text 
                                                size="1.7rem" 
                                                weight={(people == item) ? '600' : '500'}
                                                lh="22px" 
                                                ls="-0.28px" 
                                                color={(people == item) ? colors.Bl07 : colors.Gr04}
                                                onClick={() => setPeople(item)}
                                            >
                                                {item}{category != 'xl' ? "인" : ""}
                                            </Text>
                                        </Wrapper>
                                    ))}
                                </Wrapper>
                            </>
                        : ""
                    }

                    <Text margin="8px 24px 12px 24px" size="1.6rem" weight="600" lh="22px" ls="-0.28px" color={colors.Gr04}>
                        결제 가격
                    </Text>

                    <Wrapper 
                        width={`100%`}
                        padding={`0 24px`}
                        ai={`flex-end`}
                        jc={`flex-end`}
                    >
                        <Text 
                            size="1.8rem" 
                            weight={'600'}
                            lh="22px" 
                            ls="-0.28px" 
                            color={colors.Gr05}
                            style={{
                                textDecoration : "underline"
                            }}
                        >
                            가격 : {`(월) ${PriceChange(Price[category] * people)}원`}
                        </Text>
                    </Wrapper>

                </Wrapper>

                <Wrapper
                    width="100%"
                    padding="12px 24px"
                    wrap={`wrap`}
                    bt={`1px solid ${colors.Gr01}`}
                >
                    <Text width={`100%`} margin={`0 0 20px 0`} size="16px" weight="600" color={colors.Gr05}>
                        {`취소/환불 규정`}
                    </Text>

                    <Text width={`100%`} margin={`0 0 2px 0`} size="13px" weight="600" color={colors.Gr05}>
                        {`취소) 이용계약 신청 후 액티비티 판매가 이루어지지 않은 경우, 결제취소를 요청할 수 있습니다.`}
                    </Text>
                    <Text width={`100%`} margin={`0 0 10px 0`} size="12px" weight="500" color={colors.Gr05}>
                        {`(이용계약 신청 후 2시간이 지나지 않은 경우 결제취소 요청 가능)`}
                    </Text>

                    <Text width={`100%`} margin={`0 0 2px 0`} size="13px" weight="600" color={colors.Gr05}>
                        {`환불) 환불은 서비스를 이용한 일수를 제외하고 일할 계산으로 진행됩니다. (월 기준 30일)`}
                    </Text>
                    <Text width={`100%`} margin={`0 0 15px 0`} size="12px" weight="500" color={colors.Gr05}>
                        {`(진행 예정인 액티비티가 있을 경우 마지막 진행일을 기준으로 계산됨)`}
                    </Text>
                </Wrapper>

            </Wrapper>
        </ActivityModal>
    );
};

export default ActivityLocationModal;