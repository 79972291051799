import React, { useState } from "react";
import Modal from "react-modal";

// style
import colors from "../../styles/colors";
import { W } from "../../styles/globalStyles";

/**
 * modal
 */
const ModalLayout = ({ modal, setModal, children }) => {
  return (
    <Modal
      isOpen={modal.status}
      onRequestClose={() => setModal()}
      contentLabel="Alert Modal"
      style={customStyles}
    >
      {children}
    </Modal>
  );
};

export default ModalLayout;

const customStyles = {
  content: {
    width: `${W - 48}px`,
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    borderRadius: "16px",
    padding: "48px 24px 20px 24px",
    // zIndex: 20,
  },
};
