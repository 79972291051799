import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import styled from "styled-components";

import Header from "../components/basic/Header";
import CommonForm from "../components/partnerRegister/CommonForm";
import PartnerForm from "../components/partnerRegister/PartnerForm";
import CenterForm from "../components/partnerRegister/CenterForm";
import TermsForm from "../components/partnerRegister/TermsForm";
import { GetOne, SaveMany } from "../tool/localstorage";
import { LoadingSpinner } from "../tool/loading";
import { MAX_ID_NUMBER_LENGTH, MAX_PHONE_NUMBER_LENGTH, MAX_REGISTER_NUMBER_LENGTH } from "../data/config";

import colors from "../styles/colors";
import { W, H } from "../styles/globalStyles";

import { ReactComponent as Arrow } from "../resource/icon/arrow.svg";
import { ReactComponent as Select } from "../resource/icon/select.svg";
import { ReactComponent as SelectFilled } from "../resource/icon/select_filled.svg";

const STEP_LIST = {
  userName: {
    name: "userName",
    title: `세모스에서 활동할 \n파트너 활동명을 입력해주세요`,
    label: "활동명",
    progress: "25%",
  }, // 0
  phoneNumber: {
    name: "phoneNumber",
    title: `본인 확인을 위한 \n전화번호를 입력해주세요`,
    label: "전화번호",
    progress: "50%",
  }, // 1
  phoneNumberConfirm: {
    name: "phoneNumberConfirm",
    title: `전송된 인증번호를 \n입력해주세요`,
    label: "인증번호",
    progress: "75%",
  }, // 2
  userType: {
    name: "userType",
    title: `개인/센터 여부를 알려주세요`,
    label: "",
    progress: "75%",
  }, // 3
  idNumber: {
    name: "idNumber",
    title: `주민등록번호를 알려주세요`,
    label: "주민등록번호",
    progress: "75%",
  }, // 4
  idCard: {
    name: "idCard",
    title: `신분증 사본을 등록해주세요`,
    label: "",
    progress: "75%",
  }, // 5
  registerNumber: {
    name: "registerNumber",
    title: `사업자등록번호를 알려주세요`,
    label: "사업자등록번호",
    progress: "75%",
  }, // 6
  registerCard: {
    name: "registerCard",
    title: `사업자등록증 사본을 등록해주세요`,
    label: "",
    progress: "75%",
  }, // 7
  terms: {
    name: "terms",
    title: `서비스 이용을 위한 \n약관에 동의해주세요`,
    label: "",
    progress: "100%",
  }, // 8
};

const PartnerRegister = () => {
  const navigate = useNavigate();

  const [data, setData] = useState({
    userName: "",
    phoneNumber: "010-",
    phoneNumberConfirm: "",
    userType: "",
    idNumber: "",
    idCard: "",
    registerNumber: "",
    registerCard: "",
    partnerPush: 0,
    marketing: 0,
    invite: ""
  });

  const [verifyToken, setVerifyToken] = useState("");
  const [confirmStatus, setConfirmStatus] = useState("default");
  /**
   * status
   * timeout : 인증시간 만료
   * invalid : 인증번호 불일치
   * resend : 인증번호 재전송
   * default : default
   */
  const [step, setStep] = useState({ name: "userName", index: 0 });
  // const [step, setStep] = useState({ name: "phoneNumberConfirm", index: 2 });
  const [next, setNext] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    const target = data[step.name];
    setData({ ...data, phoneNumberConfirm: "" });
    switch (step.name) {
      case "userName":
        target.length > 0 ? setNext(true) : setNext(false);
        break;
      case "phoneNumber":
        target.length === MAX_PHONE_NUMBER_LENGTH ? setNext(true) : setNext(false);
        break;
      case "phoneNumberConfirm":
        setNext(false);
        break;
      case "idNumber":
        target.length === MAX_ID_NUMBER_LENGTH ? setNext(true) : setNext(false);
        break;
      case "idCard":
        target.length ? setNext(true) : setNext(false);
        break;
      case "registerNumber":
        target.length === MAX_REGISTER_NUMBER_LENGTH ? setNext(true) : setNext(false);
        break;
      case "registerCard":
        target.length ? setNext(true) : setNext(false);
        break;
      case "terms":
        break;
      default:
        break;
    }
  }, [step]);

  const getData = () => {
    setLoading(true);

    const token = GetOne("token");
    const frm = new FormData();

    frm.append("mode", "main");
    frm.append("token", token);

    axios
      .post("https://ai.semos.kr/semos_partner/v3/profile/ApplicationForm", frm, {
        headers: {
          "Content-Type": "multipart/form-data",
          Accept: "application/json",
        },
      })
      .then((res) => {
        const data = res.data;
        const value = data.data;
        const status = data.status;
        if (status === "success") {

          setData({
            ...value,
            phoneNumber: value.phoneNumber ? value.phoneNumber : "010-",
            phoneNumberConfirm: "",
          });
          setLoading(false);
        } else {
        }
      })
      .catch((err) => console.log(err));
  };

  const checkVerify = () => {
    const token = GetOne("token");

    if (data.phoneNumberConfirm.length !== 6) {
      alert("인증번호 6자리를 입력해주세요.");
    } else {
      const frm = new FormData();
      frm.append("mode", "verification_confirm");
      frm.append("verification_token", verifyToken);
      frm.append("verification_number", data.phoneNumberConfirm); // 인증번호
      frm.append("phone_number", data.phoneNumber); // 전화번호
      frm.append("token", token);

      axios
        .post("https://ai.semos.kr/semos_partner/v3/profile/Login", frm, {
          headers: {
            "Content-Type": "multipart/form-data",
            Accept: "application/json",
          },
        })
        .then((res) => {
          const Value = res.data;
          const Invite = Value.invite_partner;

          if (Value.status === "success") {
            setConfirmStatus("default");
            alert("인증성공");
            const arr = Object.keys(STEP_LIST);
            const index = arr.indexOf(arr.find((val) => val === step.name));
            setStep({ name: arr[8], index: 8 });
            setNext(false);
            setData({ ...data, invite: Invite });
          } else if (Value.status === "duplicate") {
            setConfirmStatus("duplicate");
            alert(Value.status_message);
            setNext(false);
          } else {
            setConfirmStatus("invalid");
            alert("인증번호가 올바르지 않습니다.");
            setNext(false);
          }
        })
        .catch((err) => console.log(err));
    }
  };

  // SAVE DATA
  const submitData = () => {
    setLoading(true);
    const token = GetOne("token");
    const loginType = GetOne("login_type");
    const age = GetOne("age");
    const gender = GetOne("gender");
    const nickname = GetOne("nickname");
    const email = GetOne("email");
    const name = GetOne("name");
    const phone = GetOne("phone");
    const { phoneNumberConfirm, ...restData } = data;

    axios
      .post(
        "https://ai.semos.kr/semos_partner/v3/profile/ApplicationForm",
        {
          mode: "save",
          token: token,
          data: restData,
          user_data: {
            login_type: loginType,
            age: age,
            gender: gender,
            nickname: nickname,
            email: email,
            name: name,
            phone: phone,
            invite: data.invite
          }
        },
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      )
      .then((res) => {
        const Value = res.data;
        const status = Value.status;
        const statusMessage = Value.status_message;

        if (status === "success") {
          const localStorageData = [{ type: "nickname", value: data.userName }];
          SaveMany(localStorageData);
          // setLoading(true);
          navigate("/home");
        } else {
          alert(statusMessage);
        }
      });
  };

  const handleNext = (userType = "") => {
    const arr = Object.keys(STEP_LIST);
    const index = arr.indexOf(arr.find((val) => val === step.name));

    switch (step.name) {
      case "phoneNumberConfirm":
        checkVerify();
        break;
      case "idCard":
        setStep({ name: arr[index + 3], index: index + 3 });
        setNext(false);
        break;
      case "terms":
        submitData();
        break;
      default:
        ((userType === "center") || (userType === "partner")) ? setStep({ name: arr[8], index: 8 }) : setStep({ name: arr[index + 1], index: index + 1 });
        setNext(false);
        break;
    }
  };

  const handlePrev = () => {
    const arr = Object.keys(STEP_LIST);
    const index = arr.indexOf(arr.find((val) => val === step.name));

    switch (step.name) {
      case "userName":
        navigate("/", { replace: true });
        break;
      case "userType":
        setStep({ name: arr[index - 2], index: index - 2 });
        break;
      case "registerNumber":
        setStep({ name: arr[index - 3], index: index - 3 });
        break;
      case "terms":
        if (data.userType === "partner") {
          //setStep({ name: arr[index - 3], index: index - 3 });
          setStep({ name: arr[3], index: 3 });
        } else {
          //setStep({ name: arr[index - 1], index: index - 1 });
          setStep({ name: arr[3], index: 3 });
        }
        break;
      default:
        setStep({ name: arr[index - 1], index: index - 1 });
        break;
    }
  };

  return (
    <Container>
      <Header title="입점신청" back={false} jc="flex-start">
        <BackButton onClick={() => handlePrev()}>
          <Arrow fill={colors.Gr05} />
        </BackButton>
      </Header>

      {!loading ? (
        <Content>
          <ProgressBar>
            <ProgressBarFilled progress={STEP_LIST[step.name].progress} />
          </ProgressBar>

          {step.index === 8 ? <TermsForm STEP_LIST={STEP_LIST} step={step} setStep={setStep} data={data} setData={setData} setNext={setNext} /> : <></>}

          {step.index > 5 && step.index < 8 ? (
            <CenterForm STEP_LIST={STEP_LIST} step={step} setStep={setStep} data={data} setData={setData} setNext={setNext} />
          ) : (
            <></>
          )}

          {step.index > 3 && step.index < 6 ? (
            <PartnerForm STEP_LIST={STEP_LIST} step={step} setStep={setStep} data={data} setData={setData} setNext={setNext} />
          ) : (
            <></>
          )}

          {step.index < 4 ? (
            <CommonForm
              STEP_LIST={STEP_LIST}
              step={step}
              setStep={setStep}
              data={data}
              setData={setData}
              setNext={setNext}
              setVerifyToken={setVerifyToken}
              confirmStatus={confirmStatus}
              setConfirmStatus={setConfirmStatus}
              handleNext={handleNext}
            />
          ) : (
            <></>
          )}

          {step.index === 3 ? (
            <></>
          ) : (
            <Footer>
              <NextButton isValid={next} onClick={() => (next ? handleNext() : null)}>
                {step.name === "phoneNumber" ? "인증번호 전송" : step.name === "terms" ? "약관 동의 및 시작" : "다음"}
              </NextButton>
            </Footer>
          )}
        </Content>
      ) : (
        <LoadingSpinner loading={loading} title={`정보를 확인하고 있습니다.`} content={`잠시만 기다려 주세요!`} />
      )}

      {step.index === 8 ? (
        <>
          <MarketingContainer>
            <MarketingTitle>
              액티비티 관련 사진 사용에 동의하면 <br />
              비즈니스 홍보에 도움을 드리고 있어요
            </MarketingTitle>
            <MarketingContent>
              마케팅 홍보에 활용되거나 홈 배너에 노출 되는 등 <br />
              동의를 한 파트너분들께 우선적 혜택을 드리고 있어요
            </MarketingContent>
            <MarketingCheckContainer onClick={() => setData({ ...data, marketing: data.marketing === 0 ? 1 : 0 })}>
              <MarketingLabel>사진 사용에 동의할게요</MarketingLabel>
              <MarketingCheckbox>{data.marketing ? <SelectFilled fill={colors.Bl07} /> : <Select fill={colors.Gr03} />}</MarketingCheckbox>
            </MarketingCheckContainer>
          </MarketingContainer>

          {
            data.invite ?
              <InviteNickname>
                {`추천인 : ${data.invite}`}
              </InviteNickname>
            : ""
          }
        </>
      ) : (
        <></>
      )}
    </Container>
  );
};

export default PartnerRegister;

const Container = styled.div`
  position: relative;
  height: ${H}px;
`;

const Content = styled.div`
  padding: 24px 24px 12px 24px;
  background-color: ${colors.White};
`;

const InviteNickname = styled.div`
  display : inline-block;
  padding : 24px;
  text-align: right;
  width: 100%;
  font-size: 16px;
  font-weight: 600;
  border-bottom: 1px solid ${colors.Bl07};
  color : ${colors.Bl07};
`;

const BackButton = styled.button`
  svg {
    width: 24px;
    height: 24px;
    transform: rotate(180deg);
  }
`;

const ProgressBar = styled.div`
  height: 4px;
  width: 100%;
  background-color: ${colors.Gr01};
  border-radius: 8px;
  position: relative;
`;

const ProgressBarFilled = styled.div`
  height: 4px;
  width: ${({ progress }) => progress};
  background-color: ${colors.Bl07};
  border-radius: 8px;
  position: absolute;
  top: 0;
  left: 0;
  transition: width 2s ease-in-out;
  animation: progress 2s ease-in-out;
  @keyframes progress {
    0% {
      width: 0%;
    }
    100% {
      width: 25%;
    }
  }
`;

const MarketingContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;
  padding: 24px 0;
  background-color: ${colors.Gr01};
`;

const MarketingTitle = styled.span`
  color: ${colors.Gr06};
  text-align: center;
  line-height: 22px;
  letter-spacing: -0.28px;
  font-size: 14px;
  font-weight: 500;
`;

const MarketingContent = styled.span`
  color: ${colors.Gr04};
  text-align: center;
  line-height: 20px;
  letter-spacing: -0.24px;
  font-size: 12px;
  font-weight: 500;
`;

const MarketingCheckContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  margin-top: 12px;
`;

const MarketingLabel = styled.span`
  color: ${colors.Gr03};
  line-height: 22px;
  font-size: 14px;
  font-weight: 600;
`;

const MarketingCheckbox = styled.div`
  svg {
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

const Footer = styled.div`
  display: flex;
  align-items: center;
  width: ${W}px;
  padding: 12px 24px;
  background-color: ${colors.White};
  border-top: 2px solid ${colors.Gr01};
  position: absolute;
  bottom: 0px;
  left: 0px;
`;

const NextButton = styled.button`
  width: 100%;
  height: 52px;
  padding: 8px 16px;
  background-color: ${({ isValid }) => (isValid ? `${colors.Bl07}` : `${colors.Gr01}`)};
  border-radius: 8px;
  color: ${({ isValid }) => (isValid ? `${colors.White}` : `${colors.Gr03}`)};
  line-height: 24px;
  letter-spacing: -0.32px;
  font-size: 16px;
  font-weight: 600;
`;
