import React, { useEffect, useState, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";
import axios from "axios";

//tool
import { GetOne } from "../tool/localstorage";

//style
import { Wrapper, Text, Input, AbsoluteWrapper } from "../components/layouts/layoutStorage";
import colors from "../styles/colors";

//component
import { Loading } from "../tool/Loading";
import FacilityHeader from "../components/facilityRegister/FacilityHeader";

//icon
import { ReactComponent as Arrow } from "../resource/icon/arrow.svg";

// Default Data
const Gender = ['남', '여', '법인'];
const Month = ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'];
const Day = [
                '01', '02', '03', '04', '05', '06', '07', '08', '09', '10', 
                '11', '12', '13', '14', '15', '16', '17', '18', '19', '20',
                '21', '22', '23', '24', '25', '26', '27', '28', '29', '30', '31'
            ];

const CrewRegister = () => {

    const navigate = useNavigate();

    const params = useParams();

    const genderRef = useRef();
    const MRef = useRef();
    const DRef = useRef();

    const [loading, setLoading] = useState(false);
    const [focusName, setFocusName] = useState(false);

    const [focus, setFocus] = useState({
        gender : false,
        phone : false,
        car_number : false,
        car_type : false,
        birth_y : false,
    });
    
    const [data, setData] = useState({
        name : "",
        gender : "남",
        phone : "010",
        car_number : "",
        car_type : "",
        birth_y : "",
        birth_m : "01",
        birth_d : "01"
    });

    const [valid, setValid] = useState({
        name : false,
        gender : false,
        phone : false,
        car_number : false,
        car_type : false,
        birth_y : false,
        birth_m : false,
        birth_d : false
    });

    const [dropdown, setDropdown] = useState({
        gender: false,
        birth_m : false,
        birth_d : false,
    });

    const genderHandleClose = (e) => {
        if (dropdown.gender && (!genderRef.current || !genderRef.current.contains(e.target))) {
            setDropdown({
                gender: false,
                birth_m : dropdown.birth_m,
                birth_d : dropdown.birth_d,
            });
        }
    };

    const MHandleClose = (e) => {
        if (dropdown.birth_m && (!MRef.current || !MRef.current.contains(e.target))) {
            setDropdown({
                gender: dropdown.gender,
                birth_m : false,
                birth_d : dropdown.birth_d,
            });
        }
    };

    const DHandleClose = (e) => {
        if (dropdown.birth_d && (!DRef.current || !DRef.current.contains(e.target))) {
            setDropdown({
                gender: dropdown.gender,
                birth_m : dropdown.birth_m,
                birth_d : false,
            });
        }
    };

    const W = window.document.documentElement.clientWidth;
    const MAX_NAME_LENGTH = 10;
    const MAX_YEAR_LENGTH = 4;
    const MAX_PHONE_LENGTH = 13;
    const MAX_CAR_LENGTH = 8;
    const MAX_CAR_TYPE_LENGTH = 5;
    const Regex = /(?:[\u2700-\u27bf]|(?:\ud83c[\udde6-\uddff]){2}|[\ud800-\udbff][\udc00-\udfff]|[\u0023-\u0039]\ufe0f?\u20e3|\u3299|\u3297|\u303d|\u3030|\u24c2|\ud83c[\udd70-\udd71]|\ud83c[\udd7e-\udd7f]|\ud83c\udd8e|\ud83c[\udd91-\udd9a]|\ud83c[\udde6-\uddff]|\ud83c[\ude01-\ude02]|\ud83c\ude1a|\ud83c\ude2f|\ud83c[\ude32-\ude3a]|\ud83c[\ude50-\ude51]|\u203c|\u2049|[\u25aa-\u25ab]|\u25b6|\u25c0|[\u25fb-\u25fe]|\u00a9|\u00ae|\u2122|\u2139|\ud83c\udc04|[\u2600-\u26FF]|\u2b05|\u2b06|\u2b07|\u2b1b|\u2b1c|\u2b50|\u2b55|\u231a|\u231b|\u2328|\u23cf|[\u23e9-\u23f3]|[\u23f8-\u23fa]|\ud83c\udccf|\u2934|\u2935|[\u2190-\u21ff])/g;
    const RegexInt = /[^0-9]/g;	
    
    useEffect(() => {
        window.addEventListener("click", genderHandleClose);
    
        return () => {
            window.removeEventListener("click", genderHandleClose);
        };
    }, [dropdown.gender]);

    useEffect(() => {
        window.addEventListener("click", MHandleClose);
    
        return () => {
            window.removeEventListener("click", MHandleClose);
        };
    }, [dropdown.birth_m]);

    useEffect(() => {
        window.addEventListener("click", DHandleClose);
    
        return () => {
            window.removeEventListener("click", DHandleClose);
        };
    }, [dropdown.birth_d]);

    useEffect(() => {
        if(params?.id) {
            GetData()
        }
    }, []);

    const SaveCrew = () => {

        const NameStatus = data.name.length > 1;
        const GenderStatus = data.gender.length > 0;
        const YStatus = data.birth_y.length == 4;
        const MStatus = data.birth_m.length == 2;
        const DStatus = data.birth_d.length == 2;
        const PhoneStatus = data.phone.length > 11;
        /*const CarStatus = data.car_number.length > 6;
        const CarTypeStatus = data.car_type.length > 0;*/

        if(
            NameStatus && 
            GenderStatus && 
            YStatus && 
            MStatus && 
            DStatus &&
            PhoneStatus /*&&
            CarStatus &&
            CarTypeStatus*/
        ) {

            setLoading(true);

            const token = GetOne("token");
    
            axios
                .post(
                    "https://ai.semos.kr/semos_partner/v3/activity/Facility",
                    {
                        mode: "crew_save",
                        token: token,
                        data: data,
                        crew_id: params?.id ? params?.id : 'new'
                    },
                    {
                        headers: {
                            "Content-Type": "application/x-www-form-urlencoded",
                        },
                    }
                )
                .then((data) => {
                    let Value = data.data;
                    let Status = Value.status;
                    let StatusMessage = Value.status_message;
        
                    if (Status == "success") {
                        setLoading(false);
                        window.alert(StatusMessage);
                        if(params?.id) {
                            navigate(-1)
                        } else {
                            window.location.replace('/crew')
                        }

                    } else {
                        setLoading(false);
                        window.alert(StatusMessage);
                    }
                });
        } else {
            window.alert("올바르지 않는 정보가 있습니다.\n입력한 정보를 확인해주세요!");

            setValid({
                name: NameStatus,
                gender: GenderStatus,
                birth_y: YStatus,
                birth_m: MStatus,
                birth_d: DStatus,
                phone: PhoneStatus,
                car_number: true, //CarStatus,
                car_type: true //CarTypeStatus
            })
        }

    }

    const GetData = () => {

        setLoading(true);

        const token = GetOne("token");

        axios
            .post(
                "https://ai.semos.kr/semos_partner/v3/activity/Crew",
                {
                    mode: "crew_revise",
                    token: token,
                    crew_id: params?.id
                },
                {
                    headers: {
                        "Content-Type": "application/x-www-form-urlencoded",
                    },
                }
            )
            .then((data) => {
                let Value = data.data;
                let Status = Value.status;
                let StatusMessage = Value.status_message;
                let CrewInfo = Value.crew_info;
    
                if (Status == "success") {
                    setLoading(false);
                    setData(CrewInfo)
                } else {
                    setLoading(false);
                    window.alert(StatusMessage);
                }
            });

    }

    const SetConfirm = () => {

        const NameStatus = data.name.length > 1;
        const GenderStatus = data.gender.length > 0;
        const YStatus = data.birth_y.length == 4;
        const MStatus = data.birth_m.length == 2;
        const DStatus = data.birth_d.length == 2;
        const PhoneStatus = data.phone.length > 11;
        /*const CarStatus = data.car_number.length > 6;
        const CarTypeStatus = data.car_type.length > 0;*/

        if(
            NameStatus && 
            YStatus && 
            GenderStatus && 
            MStatus && 
            DStatus && 
            PhoneStatus 
            /*&& CarStatus &&
            CarTypeStatus*/
        ) { 
            return true;
        } else {
            return false;
        }

    }

    return (
        <Container id="description_container">

            <FacilityHeader
                status={true}
                title={params?.id ? '회원 정보 수정' : '신규 회원 등록'}
                available={false}
                statusHandler={() => console.log("신규 회원 등록")}
            />

            {/* 액티비티 이름 */}
            <Wrapper 
                width="100%" 
                ai="flex-start" 
                gap="8px" 
                padding="24px 24px 8px 24px"
                jc="space-between"
                wrap="wrap"
            >
                <Text 
                    width='100%'
                    size="16px" 
                    weight="600" 
                    lh="24px" 
                    ls="-0.32px" 
                    color={colors.Gr05}
                >
                    유저명 / 성별
                </Text>

                <Wrapper 
                    width="calc(100% - 90px)" 
                    height="56px" 
                    padding="0 16px" 
                    border={`1px solid ${focusName ? colors.Bl07 : colors.Gr02}`} 
                    radius="8px"
                >
                    <Input
                        onFocus={() => setFocusName(true)}
                        onBlur={() => setFocusName(false)}
                        value={data?.name}
                        onChange={(e) => {
                            let value = e.target.value;
                            if (Regex.test(value)) {
                                let text = value.replace(Regex, "");
                                setData((prev) => ({ ...prev, name: text.substring(0, MAX_NAME_LENGTH) }));
                            } else {
                                setData((prev) => ({ ...prev, name: value.substring(0, MAX_NAME_LENGTH) }));
                            }
                        }}
                        maxLength={MAX_NAME_LENGTH}
                        size="14px"
                        weight="500"
                        lh="22px"
                        ls="-0.28px"
                        width="100%"
                        phColor={colors.Gr03}
                        placeholder="예시) 이무진"
                        style={{padding : '8px 0'}}
                    />
                    <Text margin="0 0 0 4px" size="14px" weight="500" lh="22px" ls="-0.28px" color={colors.Gr03}>
                        {data?.name.length}/{MAX_NAME_LENGTH}
                    </Text>
                </Wrapper>

                <Wrapper
                    ref={genderRef}
                    padding="12px"
                    cursor="pointer"
                    position="relative"
                    onClick={(e) => {
                        e.stopPropagation();
                        setDropdown({
                            gender: !dropdown.gender
                        });
                    }}
                    height="56px"
                    radius="8px"
                    width="80px"
                    bgc={colors.White}
                    border={`1px solid ${colors.Gr02}`}
                    jc="space-between"
                >
                    <Text size="14px" weight="500" lh="22px" ls="-0.28px" color={colors.Gr05}>
                        {data?.gender}
                    </Text>

                    <Arrow
                        width="16px"
                        height="16px"
                        fill={colors.Gr04}
                        style={{ 
                            transition: "all ease 0.5s", 
                            transform: dropdown.gender ? "rotate(-90deg)" : "rotate(90deg)" 
                        }}
                    />

                        {dropdown.gender && (
                            <AbsoluteWrapper
                                maxHeight="15rem"
                                z={1}
                                fd="column"
                                width="100%"
                                top="61px"
                                left="0"
                                bgc={colors.White}
                                border={`1px solid ${colors.Gr02}`}
                                radius="8px"
                                of="auto"
                            >
                                {Gender.map((x, idx) => (
                                    <DropDown
                                        active={x == data?.gender}
                                        key={`${idx}_genders`}
                                        last={idx + 1 === Gender.length}
                                        onClick={() => {
                                            setData((prev) => ({
                                                ...prev,
                                                gender: x
                                            }));
                                        }}
                                    >
                                        {x}
                                    </DropDown>
                                ))}
                            </AbsoluteWrapper>
                        )}
                    </Wrapper>

                <TipWrap>
                    <span className="tip">Tip!</span>
                    <span>{`본명과 성별을 알려주세요!`}</span>
                </TipWrap>
            </Wrapper>

            {/* 생년월일*/}
            <Wrapper 
                width="100%" 
                ai="flex-start" 
                gap="8px" 
                padding="24px 24px 8px 24px"
                jc="space-between"
                wrap="wrap"
            >
                <Text 
                    width='100%'
                    size="16px" 
                    weight="600" 
                    lh="24px" 
                    ls="-0.32px" 
                    color={colors.Gr05}
                >
                    생년월일
                </Text>

                {/* years */}
                <Wrapper 
                    width="calc(100% - 18rem)" 
                    height="56px" 
                    padding="0 16px" 
                    border={`1px solid ${focus.birth_y ? colors.Bl07 : colors.Gr02}`} 
                    radius="8px"
                >
                    <Input
                        onFocus={() => setFocus({ ...focus, birth_y: true })}
                        onBlur={() => setFocus({ ...focus, birth_y: false })}
                        value={data?.birth_y}
                        onChange={(e) => {
                            let value = e.target.value;
                            let ReVal = value.replace(RegexInt, "");
                            setData((prev) => ({ ...prev, birth_y: ReVal.substring(0, MAX_YEAR_LENGTH) }));
                        }}
                        maxLength={MAX_YEAR_LENGTH}
                        size="1.4rem"
                        weight="500"
                        lh="22px"
                        ls="-0.28px"
                        width="100%"
                        phColor={colors.Gr03}
                        placeholder="YYYY"
                        style={{padding : '8px 0'}}
                        type={'tel'}
                    />
                    <Text 
                        margin="0 0 0 4px" 
                        size="1.4rem" 
                        weight="500" 
                        lh="22px" 
                        ls="-0.28px" 
                        color={colors.Gr03}
                    >
                        {data?.birth_y.length}/{MAX_YEAR_LENGTH}
                    </Text>
                </Wrapper>

                {/* months */}
                <Wrapper
                    ref={MRef}
                    padding="12px"
                    cursor="pointer"
                    position="relative"
                    onClick={(e) => {
                        e.stopPropagation();
                        setDropdown({
                            birth_m: !dropdown.birth_m
                        });
                    }}
                    height="56px"
                    radius="8px"
                    width="8rem"
                    bgc={colors.White}
                    border={`1px solid ${colors.Gr02}`}
                    jc="space-between"
                >
                    <Text size="14px" weight="500" lh="22px" ls="-0.28px" color={colors.Gr05}>
                        {data?.birth_m}
                    </Text>

                    <Arrow
                        width="16px"
                        height="16px"
                        fill={colors.Gr04}
                        style={{ 
                            transition: "all ease 0.5s", 
                            transform: dropdown.birth_m ? "rotate(-90deg)" : "rotate(90deg)" 
                        }}
                    />

                    {dropdown.birth_m && (
                        <AbsoluteWrapper
                            maxHeight="15rem"
                            z={1}
                            fd="column"
                            width="100%"
                            top="61px"
                            left="0"
                            bgc={colors.White}
                            border={`1px solid ${colors.Gr02}`}
                            radius="8px"
                            of="auto"
                        >
                            {Month.map((x, idx) => (
                                <DropDown
                                    active={x == data?.birth_m}
                                    key={`${idx}_months`}
                                    last={idx + 1 === Month.length}
                                    onClick={() => {
                                        setData((prev) => ({
                                            ...prev,
                                            birth_m : x
                                        }));
                                    }}
                                >
                                    {x}
                                </DropDown>
                            ))}
                        </AbsoluteWrapper>
                    )}
                </Wrapper>
                
                {/* days */}
                <Wrapper
                    ref={DRef}
                    padding="12px"
                    cursor="pointer"
                    position="relative"
                    onClick={(e) => {
                        e.stopPropagation();
                        setDropdown({
                            birth_d: !dropdown.birth_d
                        });
                    }}
                    height="56px"
                    radius="8px"
                    width="8rem"
                    bgc={colors.White}
                    border={`1px solid ${colors.Gr02}`}
                    jc="space-between"
                >
                    <Text 
                        size="14px" 
                        weight="500" 
                        lh="22px" 
                        ls="-0.28px" 
                        color={colors.Gr05}
                    >
                        {data?.birth_d}
                    </Text>

                    <Arrow
                        width="16px"
                        height="16px"
                        fill={colors.Gr04}
                        style={{ 
                            transition: "all ease 0.5s", 
                            transform: dropdown.birth_d ? "rotate(-90deg)" : "rotate(90deg)" 
                        }}
                    />

                    {dropdown.birth_d && (
                        <AbsoluteWrapper
                            maxHeight="15rem"
                            z={1}
                            fd="column"
                            width="100%"
                            top="61px"
                            left="0"
                            bgc={colors.White}
                            border={`1px solid ${colors.Gr02}`}
                            radius="8px"
                            of="auto"
                        >
                            {Day.map((x, idx) => (
                                <DropDown
                                    active={x == data?.birth_d}
                                    key={`${idx}_days`}
                                    last={idx + 1 === Day.length}
                                    onClick={() => {
                                        setData((prev) => ({
                                            ...prev,
                                            birth_d : x
                                        }));
                                    }}
                                >
                                    {x}
                                </DropDown>
                            ))}
                        </AbsoluteWrapper>
                    )}
                </Wrapper>

                <TipWrap>
                    <span className="tip">Tip!</span>
                    <span>{`생년월일을 알려주세요! (YYYY.MM.DD)`}</span>
                </TipWrap>

            </Wrapper>

            {/* 전화번호 */}
            <Wrapper 
                width="100%" 
                ai="flex-start" 
                gap="8px" 
                padding="24px 24px 8px 24px"
                jc="space-between"
                wrap="wrap"
            >
                <Text 
                    width='100%'
                    size="16px" 
                    weight="600" 
                    lh="24px" 
                    ls="-0.32px" 
                    color={colors.Gr05}
                >
                    전화번호
                </Text>

                {/* years */}
                <Wrapper 
                    width="100% " 
                    height="56px" 
                    padding="0 16px" 
                    border={`1px solid ${focus.phone ? colors.Bl07 : colors.Gr02}`} 
                    radius="8px"
                >
                    <Input
                        onFocus={() => setFocus({ ...focus, phone: true })}
                        onBlur={() => setFocus({ ...focus, phone: false })}
                        value={data?.phone}
                        onChange={(e) => {
                            let value = e.target.value;
                            let ReVal = value.replace(/[^0-9]/g, '')
                            .replace(/^(\d{0,3})(\d{0,4})(\d{0,4})$/g, "$1-$2-$3")
                            .replace(/(\-{1,2})$/g, "");
                            setData((prev) => ({ ...prev, phone: ReVal.substring(0, MAX_PHONE_LENGTH) }));
                        }}
                        maxLength={MAX_PHONE_LENGTH}
                        size="1.4rem"
                        weight="500"
                        lh="22px"
                        ls="-0.28px"
                        width="100%"
                        phColor={colors.Gr03}
                        placeholder="01xxxxxxxxx"
                        style={{padding : '8px 0'}}
                        type={'tel'}
                    />
                    <Text 
                        margin="0 0 0 4px" 
                        size="1.4rem" 
                        weight="500" 
                        lh="22px" 
                        ls="-0.28px" 
                        color={colors.Gr03}
                    >
                        {data?.phone.length}/{MAX_PHONE_LENGTH}
                    </Text>
                </Wrapper>

                <TipWrap>
                    <span className="tip">Tip!</span>
                    <span>{`- 을 제외한 숫자만 입력해주세요! (01012341234)`}</span>
                </TipWrap>
                
            </Wrapper>

            {/* 차량 번호 / 차종 */}
            <Wrapper 
                width="100%" 
                ai="flex-start" 
                gap="8px" 
                padding="24px 24px 8px 24px"
                jc="space-between"
                wrap="wrap"
            >
                <Text 
                    width='100%'
                    size="16px" 
                    weight="600" 
                    lh="24px" 
                    ls="-0.32px" 
                    color={colors.Gr05}
                >
                    차량 번호 / 차종
                </Text>

                {/* car number */}
                <Wrapper 
                    width="calc(50% - .5rem)" 
                    height="56px" 
                    padding="0 16px" 
                    border={`1px solid ${focus.car_number ? colors.Bl07 : colors.Gr02}`} 
                    radius="8px"
                >
                    <Input
                        onFocus={() => setFocus({ ...focus, car_number: true })}
                        onBlur={() => setFocus({ ...focus, car_number: false })}
                        value={data?.car_number}
                        onChange={(e) => {
                            let value = e.target.value;

                            setData((prev) => ({ ...prev, car_number: value.substring(0, MAX_PHONE_LENGTH) }));
                        }}
                        maxLength={MAX_PHONE_LENGTH}
                        size="1.4rem"
                        weight="500"
                        lh="22px"
                        ls="-0.28px"
                        width="100%"
                        phColor={colors.Gr03}
                        placeholder="123가5678"
                        style={{padding : '8px 0'}}
                        type={'text'}
                    />
                    <Text 
                        margin="0 0 0 4px" 
                        size="1.4rem" 
                        weight="500" 
                        lh="22px" 
                        ls="-0.28px" 
                        color={colors.Gr03}
                    >
                        {data?.car_number.length}/{MAX_CAR_LENGTH}
                    </Text>
                </Wrapper>

                {/* 차종 */}
                <Wrapper 
                    width="calc(50% - .5rem)" 
                    height="56px" 
                    padding="0 16px" 
                    border={`1px solid ${focus.car_type ? colors.Bl07 : colors.Gr02}`} 
                    radius="8px"
                >
                    <Input
                        onFocus={() => setFocus({ ...focus, car_type: true })}
                        onBlur={() => setFocus({ ...focus, car_type: false })}
                        value={data?.car_type}
                        onChange={(e) => {
                            let value = e.target.value;

                            setData((prev) => ({ ...prev, car_type: value.substring(0, MAX_CAR_TYPE_LENGTH) }));
                        }}
                        maxLength={MAX_CAR_TYPE_LENGTH}
                        size="1.4rem"
                        weight="500"
                        lh="22px"
                        ls="-0.28px"
                        width="100%"
                        phColor={colors.Gr03}
                        placeholder="쏘렌토"
                        style={{padding : '8px 0'}}
                        type={'text'}
                    />
                    <Text 
                        margin="0 0 0 4px" 
                        size="1.4rem" 
                        weight="500" 
                        lh="22px" 
                        ls="-0.28px" 
                        color={colors.Gr03}
                    >
                        {data?.car_type.length}/{MAX_CAR_TYPE_LENGTH}
                    </Text>
                </Wrapper>

                <TipWrap>
                    <span className="tip">Tip!</span>
                    <span>{`차량 번호와 차종을 알려주세요!`}</span>
                </TipWrap>
                
            </Wrapper>

            <BottomWrap>
                <Wrapper
                    onClick={() => SaveCrew()}
                    jc="center"
                    ai="center"
                    width="100%"
                    height="52px"
                    cursor={SetConfirm() ? "pointer" : "default"}
                    radius="8px"
                    bgc={SetConfirm() ? colors.Bl07 : colors.Gr01}
                >
                    <Text 
                        ta="center" 
                        size="1.6rem" 
                        weight="500" 
                        lh="22px" 
                        ls="-0.28px" 
                        color={SetConfirm() ? colors.White : colors.Gr03}
                    >
                        {params?.id ? `회원 정보 수정하기` : `신규 회원 등록하기`}
                    </Text>
                </Wrapper>
            </BottomWrap>

            {loading && <Loading />}
        </Container>
    );
};

export default CrewRegister;

const Container = styled.section`
    height: calc(var(--vh, 1vh) * 100);
    overflow-x: hidden;
    width: 100%;
    padding-bottom : 150px;
`;

const ImageLabelBox = styled.label`
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-top: 20px;
    width: 100%;
    max-width: 800px;
    ${({ MD }) => (MD ? 
        `height: calc((800px - 48px) * (5/8));`
        :
        `height: calc((100vw - 48px) * (5/8));`
    )};
    border: 1px solid ${colors.Gr02};
    border-radius: 8px;
    align-self : center;
`;

const ImageInput = styled.input`
    display: none;
`;

const TipWrap = styled.div`
    display: flex;
    gap: 2px;
    font-size: 14px;
    font-weight: 500;
    line-height: 22px;
    letter-spacing: -0.28px;
    color: ${colors.Gr03};
    .tip {
        color: ${colors.Bl05};
    }
    .notice {
        color: ${colors.Red03};
    }
`;

const DropDown = styled.div`
    width: 100%;
    padding: 10px 8px;
    border-bottom: ${({ last }) => !last && `1px solid ${colors.Gr02}`};
    color: ${({ active }) => active ? colors.Bl07 : colors.Gr04 };
    font-size: 1.4rem;
    font-weight: ${({ active }) => active ? 600 : 500};
    line-height: 142%;
    letter-spacing: -0.26px;
    text-align : center;
    cursor : pointer;
`;

const Yoil = styled.div`
    width: calc(((100% - 21px) / 7));
    display : inline-flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    border : ${({ active }) => `1px solid ${active ? colors.Bl07 : colors.Gr02}`};
    background-color : ${({ active }) => `${active ? colors.Bl07 : colors.Gr01}`};
    color : ${({ active }) => `${active ? colors.White : colors.Gr04}`};
    cursor : pointer;
    font-size: 1.3rem;
    font-weight: 600;
    padding : .7rem 0;
`;


const YoilHoliday = styled.div`
    width: calc(((100% - 28px) / 7));
    display : inline-flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    border : ${({ active }) => `1px solid ${active ? colors.Red03 : colors.Gr02}`};
    background-color : ${({ active }) => `${active ? colors.Red03 : colors.Gr01}`};
    color : ${({ active }) => `${active ? colors.White : colors.Gr04}`};
    cursor : pointer;
    font-size: 1.3rem;
    font-weight: 600;
    padding : .7rem 0;
`;

const BottomWrap = styled.div`
    width: 100%;
    max-width: 1300px;
    transform : translate(-50%, 0);
    display: flex;
    padding: 12px 24px;
    border-top: 1px solid ${colors.Gr01};
    background-color: ${colors.White};
    position: fixed;
    bottom : 0;
    left : 50%;
    z-index: 10;
`;