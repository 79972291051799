import React, { useState, useEffect } from "react";
import styled from "styled-components";
// import { TimePicker } from "react-ios-time-picker";
import TimePicker from "rc-time-picker";
import "rc-time-picker/assets/index.css";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import Modal from "react-modal";
import axios from "axios";
import Sheet from "react-modal-sheet";

// components
import { GetOne } from "../../tool/localstorage";
import colors from "../../styles/colors";
import { H, W } from "../../styles/globalStyles";

//img
import ReservationSchedule from "../../icon/reservationSchedule.png";
import ReservationPeople from "../../icon/reservationPeople.png";
import DefaultConfirm from "../../icon/activityConfirmDefault.png";
import CalenderOpener from "../../icon/calenderOpener.png";
import Close from "../../icon/modalCloseButton.png";
import BlueWarn from "../../icon/blueWarning.png";
import Calender from "../../icon/reservationTime.png";
import Clock from "../../icon/reservationClock.png";
import { ReactComponent as Dashboard } from "../../icon/dashboard.svg";


const snap = H < 740 ? 0.3 : 0.25;

function ActivityConfirm({ data }) {
  const navigate = useNavigate();

  const [modalOpen, setModalOpen] = useState(false);

  const paynum = data.number;

  var date = data.date_short.split(".");

  const [open, setOpen] = useState(false);

  const [bottom, setBottom] = useState(false);

  const [calender, setCalender] = useState(false);

  const [time, setTime] = useState({
    year: date[0],
    month: date[1],
    date: date[2],
    clock: data.time,
  });


  const closeModal = () => {
    setModalOpen(false);
  };

  const sendData = () => {
    var token = GetOne("token");

    axios
      .post(
        "https://ai.semos.kr/semos_partner/schedule/data",
        {
          mode: "reservation_change",
          token: token,
          pay_id: data.number,
          date: time,
        },
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      )
      .then((data) => {
        var Value = data.data;
        var Status = Value.status;
        var StatusMessage = Value.status_message;

        if (Status == "success") {
          window.alert(StatusMessage);
          closeModal();
          setOpen(false);
        } else {
          window.alert(StatusMessage);
        }
      });
  };

  const activityCancel = () => {
    var token = GetOne("token");

    const frm = new FormData();

    frm.append("mode", "sold_status");
    frm.append("number", data.number);
    frm.append("type", "cancel");
    frm.append("token", token);

    axios
      .post("https://ai.semos.kr/semos_partner/auth/profile", frm, {
        headers: { "Content-Type": "multipart/form-data" },
      })
      .then((data) => {
        var Value = data.data;

        if (Value.status == "success") {
          alert(Value.status_message);
          window.location.reload();
        } else {
          alert(Value.status_message);
        }
      });
  };

  const preventDefault = (e) => {
    e.preventDefault();
  };

  const preventDefaultForScrollKeys = (e) => {
    var keys = { 37: 1, 38: 1, 39: 1, 40: 1 };
    if (keys[e.keyCode]) {
      preventDefault(e);
      return false;
    }
  };

  var supportsPassive = false;
  try {
    window.addEventListener(
      "test",
      null,
      Object.defineProperty({}, "passive", {
        get: function () {
          supportsPassive = true;
        },
      })
    );
  } catch (e) {}

  var wheelOpt = supportsPassive ? { passive: false } : false;
  var wheelEvent =
    "onwheel" in document.createElement("div") ? "wheel" : "mousewheel";

  useEffect(() => {
    if (bottom) {
      window.addEventListener("DOMMouseScroll", preventDefault, false); // older FF
      window.addEventListener(wheelEvent, preventDefault, wheelOpt); // modern desktop
      window.addEventListener("touchmove", preventDefault, wheelOpt); // mobile
      window.addEventListener("keydown", preventDefaultForScrollKeys, false);
    } else {
      window.removeEventListener("DOMMouseScroll", preventDefault, false);
      window.removeEventListener(wheelEvent, preventDefault, wheelOpt);
      window.removeEventListener("touchmove", preventDefault, wheelOpt);
      window.removeEventListener("keydown", preventDefaultForScrollKeys, false);
    }
  }, [bottom]);

  const handleChange = (value, type) => {
    setTime((prev) => ({ ...prev, clock: value.format("HH:mm") }));
  };

  const defaultTime = (h, m) => {
    return moment().set("hour", h).set("minute", m);
  };

  let TypeCheck = (data?.type != '정기 레슨' && data?.type != '이용권(기간)' && data?.type != '이용권(횟수)');

  const UseCheck = {
    '정기 레슨' : "개월",
    '이용권(기간)' : "개월",
    '이용권(횟수)' : "회"
  };
  const CategoryCheck = {
    '정기 레슨' : "이용 기간",
    '이용권(기간)' : "이용 기간",
    '이용권(횟수)' : "이용 횟수"
  };

  return (
    <Wrap>
      <PaymentItem            
        onClick={() =>
          navigate(`/reservation_detail/${data?.number}`, {
            state: {
              from: "",
            },
          })
        }
      >
        <Styler pl={24} pr={24} pt={20}>

          <ActivityName

          >
            {data?.title}
          </ActivityName>
          <Styler pt={8} pb={8}>
            <InfoBox>
              <Styler flex={true} pb={6}>
                <InfoTypeContainer>
                  <SmallIcon src={DefaultConfirm} />
                  <GreyText>
                    <Dye color={colors.Bl07}>스포츠</Dye>&nbsp;
                    <Seperator>|</Seperator>&nbsp;유형
                  </GreyText>
                </InfoTypeContainer>
                <InfoDataContainer>
                  <Dye color={colors.Bl07}>{data.sport && data.sport}</Dye>
                  {
                    TypeCheck ?
                      <Dye color={colors.Gr03}>
                        {data.duration && (
                          <>
                            <Seperator>&nbsp;&nbsp;|&nbsp;&nbsp;</Seperator>
                            {data.duration}
                          </>
                        )}
                        <Seperator>&nbsp;&nbsp;|&nbsp;&nbsp;</Seperator>
                        {data?.type}
                      </Dye>
                    :
                    <Dye color={colors.Gr03}>
                      <Seperator>&nbsp;&nbsp;|&nbsp;&nbsp;</Seperator>
                      {data?.type}
                    </Dye>
                  }
                </InfoDataContainer>
              </Styler>
              <Styler flex={true} pb={6}>
                <InfoTypeContainer>
                  <SmallIcon src={ReservationSchedule} />
                  <GreyText>{TypeCheck ? '예약 일정' : '유효 기간'}</GreyText>
                </InfoTypeContainer>

                  <InfoDataContainer>
                    {TypeCheck ? data?.date : data?.schedule}
                  </InfoDataContainer>
              </Styler>

              {
                data?.type == '정기 레슨' ?
                  <Styler flex={true} pb={6}>
                    <InfoTypeContainer>
                      <SmallIcon src={ReservationSchedule} />
                      <GreyText>{`이용 시간대`}</GreyText>
                    </InfoTypeContainer>
                    <InfoDataContainer>{data?.schedule_time}</InfoDataContainer>
                  </Styler>
                : <></>
              }

              <Styler flex={true} pb={6}>
                <InfoTypeContainer>
                  {TypeCheck ?
                    <SmallIcon src={ReservationPeople} />
                  : 
                    <Dashboard 
                      width={`16px`} 
                      height={`16px`} 
                      style={{
                        marginRight: 4
                    }} fill={colors.Gr04} />
                  }
                  <GreyText>{TypeCheck ? '예약 인원' : CategoryCheck[data?.type]}</GreyText>
                </InfoTypeContainer>
                <InfoDataContainer>
                  {TypeCheck ? `${data?.people}인` : `${data?.people}${UseCheck[data?.type]}`}
                </InfoDataContainer>
              </Styler>

              <Styler flex={true}>
                <InfoTypeContainer>
                  <SmallIcon src={ReservationPeople} />
                  <GreyText>{`회원명`}</GreyText>
                  </InfoTypeContainer>
                <InfoDataContainer>{`${data?.user_name} (${data?.user_real_name})`}</InfoDataContainer>
              </Styler>

            </InfoBox>
          </Styler>
        </Styler>
      </PaymentItem>
      {/* <Styler flex={true} justify="flex-end" pt={7.5} pl={24} pr={24}>
        <RejectText onClick={() => setBottom(true)}>예약취소</RejectText>
      </Styler> */}

      <Modal
        isOpen={modalOpen}
        // onAfterOpen={afterOpenModal}
        onRequestClose={closeModal}
        contentLabel="Example Modal"
        style={customStyles}
      >
        <Styler
          width={W}
          flex={true}
          justify="space-between"
          direction="column"
          height={100}
        >
          <Styler>
            <Styler flex={true} justify="flex-end">
              <CloseButton src={Close} onClick={() => closeModal()} />
            </Styler>
            <Styler pb={8}>
              <ModalTitle>예약 일정 변경 전</ModalTitle>
              <ModalTitle>크루님과 일정 조율이 완료 되었나요?</ModalTitle>
            </Styler>
            <Styler pb={20}>
              <SmallTitle>
                크루님과 협의되지 않고 일정이 변경되는 경우
              </SmallTitle>
              <SmallTitle>패널티가 발생할 수 있어요</SmallTitle>
            </Styler>
            <ModalBox>
              <Styler
                pt={16}
                pb={16}
                flex={true}
                direction="column"
                align="center"
              >
                <Styler flex={true} align="center" pb={12}>
                  <Warning src={BlueWarn} />
                  <WarningText>
                    변경 된 일정을 마지막으로 확인해 주세요
                  </WarningText>
                </Styler>
                <Styler flex={true} justify="center">
                  <Styler
                    flex={true}
                    direction="column"
                    justify="center"
                    align="center"
                    pr={26}
                  >
                    <HighlightIcon src={Calender} />
                    <HighlightText>
                      {time.year}.{time.month}.{time.date}
                    </HighlightText>
                  </Styler>
                  <Styler
                    flex={true}
                    direction="column"
                    justify="center"
                    align="center"
                    pl={26}
                  >
                    <HighlightIcon src={Clock} />
                    <HighlightText>{time.clock}</HighlightText>
                  </Styler>
                </Styler>
              </Styler>
            </ModalBox>
          </Styler>
          <Styler flex={true} justify="space-between">
            <LeftButton
              onClick={() => {
                navigate(`/chat/${data.number}`);
              }}
            >
              크루에게 연락하기
            </LeftButton>
            <RightButton onClick={() => sendData()}>일정 조율 신청</RightButton>
          </Styler>
        </Styler>
      </Modal>

      <BackButtonModal isOpen={bottom} snapPoints={[snap]}>
        <BackButtonModal.Container>
          <BackModalLayout>
            <ModalHeader>
              이미 확정된 예약으로 취소 수수료가 발생해요.
            </ModalHeader>
            <Divider h={9} />
            <ModalText>정말 예약을 취소하시겠어요?</ModalText>
            <Divider h={36} />
            <ButtonContainer>
              <BackButton onClick={() => activityCancel()}>예약취소</BackButton>
              <StayButton onClick={() => setBottom(false)}>돌아가기</StayButton>
            </ButtonContainer>
          </BackModalLayout>
        </BackButtonModal.Container>
        <BackButtonModal.Backdrop />
      </BackButtonModal>
    </Wrap>
  );
}

export default ActivityConfirm;

const customStyles = {
  content: {
    width: `${W - 48}px`,
    top: "50%",
    height: "55%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    borderRadius: "16px",
    padding: "24px 24px 20px 24px",
    zIndex: 100,
  },
};

const Wrap = styled.div`
  padding-bottom: 16.5px;
  border-bottom: 1px solid ${colors.Gr01};
`;

const PaymentItem = styled.div`
  cursor : pointer;
`;

const Styler = styled.div`
  position: relative;
  display: ${({ flex }) => (flex ? "flex" : "")};
  flex-direction: ${({ direction }) => direction};
  justify-content: ${({ justify }) => justify};
  align-items: ${({ align }) => align};
  padding-top: ${({ pt }) => pt}px;
  padding-bottom: ${({ pb }) => pb}px;
  padding-left: ${({ pl }) => pl}px;
  padding-right: ${({ pr }) => pr}px;
  height: ${({ h }) => h}px;
  height: ${({ height }) => height}%;
  width: ${({ w }) => w}%;
`;

const ActivityScheduleContainer = styled.div`
  margin-bottom: 6px;
  cursor: pointer;
`;

const SmallIcon = styled.img`
  width: 16px;
  height: 16px;
  margin-right: 4px;
`;

const GreyText = styled.div`
  font-weight: 500;
  font-size: 12px;
  line-height: 142%;
  display: flex;
  align-items: center;
  letter-spacing: -0.02em;
  color: ${colors.Gr04};
`;

const ActivityName = styled.div`
  cursor: pointer;
  font-weight: 600;
  font-size: 16px;
  line-height: 142%;
  letter-spacing: -0.02em;
  color: ${colors.Gr05};
`;

const MenuIcon = styled.img`
  width: 28px;
  height: 28px;
  margin-bottom: 5px;
`;

const InfoBox = styled.div`
  border: 1px solid ${colors.Gr02};
  padding: 12px;
  border-radius: 8px;
`;

const InfoTypeContainer = styled.div`
  width: 31%;
  display: flex;
  align-items: center;
`;

const InfoDataContainer = styled.div`
  font-weight: 500;
  font-size: 12px;
  line-height: 142%;
  display: flex;
  align-items: center;
  letter-spacing: -0.02em;
  color: ${colors.Gr05};
`;

const Dye = styled.span`
  color: ${({ color }) => color};
`;

const Seperator = styled.span`
  font-size: 10px;
`;

const RejectText = styled.div`
  cursor: pointer;
  font-weight: 600;
  font-size: 12px;
  line-height: 142%;
  display: flex;
  align-items: center;
  letter-spacing: -0.02em;
  color: ${colors.Red03};
`;

const ConfirmButton = styled.div`
  padding: 10.5px 25.5px;
  font-weight: 600;
  font-size: 12px;
  line-height: 142%;
  display: flex;
  align-items: center;
  text-align: right;
  letter-spacing: -0.02em;
  color: ${colors.White};
  background-color: ${colors.Bl07};
  border-radius: 8px;
`;

const ScheduleChangeButton = styled.div`
  cursor: pointer;
  font-weight: 600;
  font-size: 12px;
  line-height: 142%;
  display: flex;
  justify-content: center;
  align-items: center;
  letter-spacing: -0.02em;
  color: ${colors.White};
  background-color: ${colors.Gr04};
  padding: 10.5px 0;
  margin-bottom: 6px;
  border-radius: 8px;
  cursor: pointer;
`;

const CancelButton = styled(ScheduleChangeButton)`
  width: 40%;
  background-color: ${colors.Red03};
  margin-right: 12px;
`;

const SaveButton = styled(ScheduleChangeButton)`
  width: 60%;
`;

const CalenderContainer = styled.div`
  border: 1px solid ${colors.Gr02};
  padding: 5px 8px;
  display: flex;
  align-items: center;
  border-radius: 5px;
`;

const CalenderIcon = styled.img`
  width: 18px;
  height: 18px;
  margin-right: 4px;
`;

const DateContainer = styled.div`
  font-weight: 500;
  font-size: 14px;
  /* line-height: 142%; */
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: -0.02em;
  color: ${colors.Gr05};
`;

const CloseButton = styled.img`
  width: 28px;
  height: 28px;
  cursor: pointer;
`;

const ModalTitle = styled.div`
  font-weight: 600;
  font-size: 18px;
  line-height: 142%;
  text-align: center;
  letter-spacing: -0.02em;
  color: ${colors.Gr05};
`;

const SmallTitle = styled.div`
  font-weight: 500;
  font-size: 12px;
  line-height: 142%;
  text-align: center;
  letter-spacing: -0.02em;
  color: ${colors.Gr03};
`;

const ModalBox = styled.div`
  border: 1px solid ${colors.Gr02};
  border-radius: 8px;
`;

const Warning = styled.img`
  width: 20px;
  height: 20px;
  margin-right: 4px;
`;

const WarningText = styled.div`
  font-weight: 500;
  font-size: 12px;
  line-height: 142%;
  letter-spacing: -0.02em;
  color: ${colors.Bl06};
`;

const HighlightIcon = styled.img`
  width: 32px;
  height: 32px;
  margin-bottom: 8px;
`;

const HighlightText = styled.div`
  font-weight: 500;
  font-size: 12px;
  line-height: 142%;
  letter-spacing: -0.02em;
  color: ${colors.Gr05};
`;

const ModalButton = styled.div`
  border: 1px solid;
  border-radius: 37px;
  width: 48%;
  padding: 10px 0;
  font-weight: 500;
  font-size: 14px;
  line-height: 142%;
  letter-spacing: -0.02em;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

const LeftButton = styled(ModalButton)`
  border-color: ${colors.Gr04};
  color: ${colors.Gr04};
`;

const RightButton = styled(ModalButton)`
  background-color: ${colors.Gr04};
  color: ${colors.White};
`;

const BackButtonModal = styled(Sheet)``;

const BackModalLayout = styled.div`
  padding: 36px 24px 20px 24px;
`;

const ModalHeader = styled.div`
  font-weight: 600;
  font-size: 18px;
  line-height: 142%;
  letter-spacing: -0.02em;
  color: ${colors.Gr06};
`;

const ModalText = styled.div`
  font-weight: 500;
  font-size: 14px;
  line-height: 142%;
  /* or 20px */

  letter-spacing: -0.02em;

  /* Greyscale/Gr 02 */

  color: ${colors.Gr04};
`;

const Divider = styled.div`
  height: ${({ h }) => h}px;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Button = styled.div`
  font-weight: 500;
  font-size: 14px;
  line-height: 142%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  letter-spacing: -0.02em;
  width: 48%;
  padding: 18px 0;
  border-radius: 8px;
`;

const BackButton = styled(Button)`
  color: ${colors.Gr05};
  width: 40%;
  background-color: ${colors.Bl02};
`;

const StayButton = styled(Button)`
  color: ${colors.White};
  width: 55%;
  background-color: ${colors.Gr04};
`;

const TimePick = styled(TimePicker)`
  width: 64px;
  margin-left: 9px;
  color: ${colors.Gr05};

  &::placeholder {
    /* color: ${colors.Gr03}; */
  }
`;
