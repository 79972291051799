import React, { useEffect, useState } from "react";
import styled from "styled-components";
// import { TimePicker } from "react-ios-time-picker";
import TimePicker from "rc-time-picker";
import "rc-time-picker/assets/index.css";
import moment from "moment";
import colors from "../../styles/colors";

function PersonalActivity({ data, save, close, index, date }) {
  const [open, setOpen] = useState(false);
  const [change, setChange] = useState(false);

  const [info, setInfo] = useState(data);

  const [pickTime, setPickTime] = useState({
    start: moment(),
    end: moment(),
  });

  const handleChange = (value, type) => {
    setPickTime((prev) => ({ ...prev, [type]: value }));
  };

  useEffect(() => {
    var start = document.getElementById("start")?.value;
    var end = document.getElementById("end")?.value;

    if (start) {
      timePick(start, "start");
    }

    if (end) {
      timePick(end, "end");
    }

    // setInfo((prev) => ({ ...prev, start_time: start, end_time: end }));
  }, [pickTime]);

  const timePick = (value, type) => {
    var time = value.split(":");

    if (type == "start") {
      setInfo((prev) => ({ ...prev, start_time: time[0], start_min: time[1] }));
    } else {
      setInfo((prev) => ({
        ...prev,
        finish_time: time[0],
        finish_min: time[1],
      }));
    }

    setChange(true);
  };

  const ScheduleSave = () => {
    save(info);
    setChange(false);
    setOpen(false);
  };

  const ScheduleDelete = () => {
    close(info, index, date);
  };

  const defaultTime = (h, m) => {
    return moment().set("hour", h).set("minute", m);
  };

  //   useEffect(() => {}, [minutearr.length]);

  if (open) {
    return (
      <ScheduleContainerRevise>
        <FirstLine>
          <DotContainer>
            <BlueDot />
          </DotContainer>
          <SemosActivityTitleInput
            value={info.activity_name}
            placeholder="일정 제목을 입력해 주세요."
            onChange={(e) => {
              setInfo((prev) => ({ ...prev, activity_name: e.target.value }));
              setChange(true);
            }}
          />
        </FirstLine>
        <Divider h={10} />
        <OpenedContainer>
          <TimeSelection>
            <StartTimePicker>
              <StartText>시작</StartText>
              <StartTimePick
                id="start"
                inputReadOnly={true}
                onChange={(time) => handleChange(time, "start")}
                defaultValue={defaultTime(data.start_time, data.start_min)}
                showSecond={false}
                minuteStep={15}
              />
            </StartTimePicker>
            <DividingBar>|</DividingBar>
            <EndTimePicker>
              <EndText>종료</EndText>
              <StartTimePick
                id="end"
                defaultValue={defaultTime(data.finish_time, data.finish_min)}
                inputReadOnly={true}
                onChange={(time) => handleChange(time, "end")}
                showSecond={false}
                minuteStep={15}
              />
            </EndTimePicker>
          </TimeSelection>
          <Divider h={16} />
          <MemoContainer
            placeholder="일정 상세 내용을 작성해 주세요!"
            value={info.memo}
            onChange={(e) => {
              setInfo((prev) => ({ ...prev, memo: e.target.value }));
              setChange(true);
            }}
          />
          <Divider h={16} />
          <ButtonContainer>
            <Delete onClick={() => ScheduleDelete()}>삭제하기</Delete>
            {change ? <SaveButton onClick={() => ScheduleSave()}>저장하기</SaveButton> : <SaveButton onClick={() => setOpen(false)}>확인</SaveButton>}
          </ButtonContainer>
        </OpenedContainer>
      </ScheduleContainerRevise>
    );
  } else {
    return (
      <ScheduleContainer onClick={() => setOpen(true)}>
        <DotContainer>
          <BlueDot />
        </DotContainer>
        <ScheduleInfoContainer>
          <SemosActivityTitle>{info.activity_name}</SemosActivityTitle>
          <ScheduleTime>{`${info.start_time}:${info.start_min} ~ ${info.finish_time}:${info.finish_min}`}</ScheduleTime>
        </ScheduleInfoContainer>
      </ScheduleContainer>
    );
  }
}

export default PersonalActivity;

const ScheduleContainer = styled.section`
  display: flex;
  border-bottom: 2px solid ${colors.Gr01};
  padding-bottom: 24px;
  margin-bottom: 32px;
`;

const DotContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 7px;
  height: 20px;
  margin-right: 4px;
`;

const BlueDot = styled.div`
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background-color: ${colors.Bl07};
`;

const ScheduleInfoContainer = styled.div``;

const SemosActivityTitle = styled.div`
  font-weight: 600;
  font-size: 14px;
  line-height: 142%;
  color: ${colors.Gr05};
  margin-bottom: 4px;
`;

const ScheduleTime = styled.div`
  color: ${colors.Gr03};
  display: flex;
  align-items: center;
  font-weight: 500;
  font-size: 12px;
  line-height: 142%;
  letter-spacing: -0.02em;
`;

const ScheduleContainerRevise = styled.section`
  border-bottom: 2px solid #f5f5f5;
  padding-bottom: 24px;
  margin-bottom: 32px;
`;

const FirstLine = styled.div`
  display: flex;
  align-items: center;
`;

const SemosActivityTitleInput = styled.input`
  border: 1px solid #dcdcdc;
  padding: 5px 8px;
  width: 100%;
  border-radius: 4px;

  &::placeholder {
    color: ${colors.Gr03};
  }
`;

const OpenedContainer = styled.div`
  margin-left: 24px;
`;

const Divider = styled.div`
  height: ${({ h }) => h}px;
`;

const TimeSelection = styled.div`
  display: flex;
  align-items: center;
`;

const StartTimePicker = styled.div`
  display: flex;
`;

const StartText = styled.div`
  font-weight: 500;
  font-size: 12px;
  line-height: 142%;
  display: flex;
  align-items: center;
  letter-spacing: -0.02em;
  color: ${colors.Gr03};
  margin-top: 1px;
`;

const DividingBar = styled.div`
  font-size: 9px;
  margin: 0 12px;
  color: ${colors.Gr03};
`;

const EndTimePicker = styled(StartTimePicker)``;

const EndText = styled(StartText)``;

const StartTimePick = styled(TimePicker)`
  width: 64px;
  margin-left: 9px;

  &::placeholder {
    color: ${colors.Gr03};
  }
`;

const MemoContainer = styled.textarea`
  background-color: ${colors.Gr01};
  outline: none;
  resize: none;
  border: none;
  border-radius: 8px;
  width: 100%;
  height: 180px;
  padding: 14px 18px;
  font-weight: 500;
  font-size: 12px;
  line-height: 142%;
  letter-spacing: -0.02em;
  color: ${colors.Gr05};

  &::placeholder {
    color: ${colors.Gr03};
  }
`;

const ButtonContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Delete = styled.div`
  color: ${colors.Red02};
  font-weight: 600;
  font-size: 12px;
  line-height: 142%;
  display: flex;
  align-items: center;
  letter-spacing: -0.02em;
`;

const SaveButton = styled.div`
  padding: 7.5px 20px;
  gap: 10px;
  width: auto;
  height: auto;
  background-color: ${colors.Bl07};
  border-radius: 8px;
  font-weight: 600;
  font-size: 12px;
  line-height: 142%;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: -0.02em;
  color: ${colors.White};
`;
