import React, { useState, useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";
import styled from "styled-components";
import axios from "axios";

import Header from "../components/basic/Header";
import ReviewItem from "../components/review/review_item";
import { GetOne } from "../tool/localstorage";
import { LoadingSpinner } from "../tool/loading";
import { fbevent } from "../tool/firebase";

// style
import colors from "../styles/colors";

// icon
import { ReactComponent as Arrow } from "../resource/icon/arrow.svg";
import NoReviewAlert from "../icon/no_review_alert.png";

const ReviewList = () => {
  const location = useLocation();
  const ref = useRef();

  const [review, setReview] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fbevent("B2B_Review_Page_View", {
      email : GetOne("email")
    });

    var token = GetOne("token");
    setLoading(true);

    const frm = new FormData();
    frm.append("mode", "main");
    frm.append("token", token);

    axios
      .post("https://ai.semos.kr/semos_partner/v2/home/ReviewList", frm, {
        headers: { "Content-Type": "multipart/form-data" },
      })
      .then((data) => {
        const Value = data.data;
        const Status = Value.status;
        const StatusMessage = Value.status_message;
        const ReviewList = Value.review_list;

        if (Status == "success") {
          setReview(ReviewList);
          setTimeout(() => {
            setLoading(false);
          }, 500);
        } else {
          window.alert(StatusMessage);
        }
      });
  }, []);

  useEffect(() => {
    var review = document.getElementById("reviewScroll");

    if (location.state && !loading) {
      var index = location.state.index;
      var idName = `rev_${index}`;
      const item = document.getElementById(idName)?.offsetTop;
      var height = 47.88;

      review.scroll({
        top: item + height - 15,
        left: 0,
        behavior: "smooth",
      });
    }
  }, [loading]);

  return (
    <Container ref={ref} id="reviewScroll">
      {!loading ? (
        <>
          <Header
            title="리뷰"
            back={true}
            type="review"
            reviewCount={review.length}
          />

          {review && review.length === 0 ? (
            <NoReviewContainer>
              <NoReviewImg src={NoReviewAlert} alt="no review" />
              <NoReviewText>
                <span class="blue-text">Tip!</span> <br />
                리뷰는 평균 약 <span class="blue-text">4배</span>의 예약률을
                높여요
                <br />
                <span class="light-text">
                  액티비티를 이용한 크루님들께 리뷰를 요청해보세요
                </span>
              </NoReviewText>
            </NoReviewContainer>
          ) : (
            <Styler pt={16} pl={24} pr={24}>
              {review &&
                review.map((item, index) => (
                  <ReviewItem
                    data={item}
                    index={index}
                    key={`${index}_review`}
                  />
                ))}
            </Styler>
          )}

          {/* <div style={{ height: "83px" }} /> */}
          {/* <Footer /> */}
        </>
      ) : (
        <LoadingSpinner
          loading={loading}
          title={`정보를 확인하고 있습니다.`}
          content={`잠시만 기다려 주세요!`}
        />
      )}
    </Container>
  );
};

export default ReviewList;

const Container = styled.section`
  height: calc(var(--vh, 1vh) * 100);
  overflow-x: hidden;
`;

const SubTitle = styled.div`
  border-bottom: 1px solid ${colors.Gr01};
`;

const Styler = styled.div`
  position: relative;
  display: ${({ flex }) => (flex ? "flex" : "")};
  flex-direction: ${({ direction }) => direction};
  justify-content: ${({ justify }) => justify};
  align-items: ${({ align }) => align};
  padding-top: ${({ pt }) => pt}px;
  padding-bottom: ${({ pb }) => pb}px;
  padding-left: ${({ pl }) => pl}px;
  padding-right: ${({ pr }) => pr}px;
`;

const NoReviewContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 0 24px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const NoReviewImg = styled.img`
  width: 100%;
  max-width: 500px;
`;

const NoReviewText = styled.span`
  text-align: center;
  color: ${colors.Gr05};
  line-height: 22px;
  font-size: 14px;
  font-weight: 600;

  .blue-text {
    color: ${colors.Bl07};
  }

  .light-text {
    line-height: 20px;
    letter-spacing: -0.02em;
    font-size: 12px;
    font-weight: 500;
  }
`;
