import React from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import colors from "../../styles/colors";

function PaidFinished({ data }) {
  const navigate = useNavigate();
  return (
    <PaymentItem
      onClick={() =>
        navigate(`/reservation_detail/${data.number}`, {
          state: {
            from: "",
          },
        })
      }
    >
      <ActivityName>{data?.title}</ActivityName>
      
      <CrewName>{`회원명 : ${data?.user_name} (${data?.user_real_name})`}</CrewName>

      <Styler flex={true} justify="space-between" align="center">
        <NormalText color={colors.Gr04}>{data?.schedule}</NormalText>

        <Styler flex={true} align="center">
          <NormalText color={colors.Gr03}>{`결제금액 : `}</NormalText>
          <PriceText>{data?.amount_text}{`원`}</PriceText>
        </Styler>
      </Styler>
    </PaymentItem>
  );
}

export default PaidFinished;

const PaymentItem = styled.div`
  padding: 16px 24px 18px 24px;
  border-bottom: 1px solid ${colors.Gr01};
  cursor: pointer;
`;

const ActivityName = styled.div`
  font-weight: 600;
  font-size: 16px;
  line-height: 142%;
  color: ${colors.Gr06};
  margin-bottom: 8px;
`;

const CrewName = styled.div`
  font-weight: 500;
  font-size: 14px;
  line-height: 142%;
  color: ${colors.Gr05};
  margin-bottom: 25px;
`;

const Styler = styled.div`
  position: relative;
  display: ${({ flex }) => (flex ? "flex" : "")};
  flex-direction: ${({ direction }) => direction};
  justify-content: ${({ justify }) => justify};
  padding-top: ${({ pt }) => pt}px;
  padding-bottom: ${({ pb }) => pb}px;
  padding-left: ${({ pl }) => pl}px;
  padding-right: ${({ pr }) => pr}px;
`;

const NormalText = styled.div`
  font-weight: 500;
  font-size: 13px;
  line-height: 142%;
  display: flex;
  align-items: center;
  letter-spacing: -0.02em;
  color: ${({ color }) => color};
`;

const PriceText = styled.div`
  font-weight: 600;
  font-size: 16px;
  line-height: 142%;
  display: flex;
  align-items: center;
  text-align: right;
  color: ${colors.Bl07};
  margin-left: 6px;
`;
