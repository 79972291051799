import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";

//style
import { Wrapper, Text, Input, AbsoluteWrapper } from "../layouts/layoutStorage";
import colors from "../../styles/colors";

//tool
import { localeStringMaker, localeToNumber } from "../../tool/regex";

//icon
import { ReactComponent as Arrow } from "../../resource/icon/arrow.svg";
import { ReactComponent as Minus } from "../../resource/icon/minus.svg";

//default data
const MonthCount = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
const MAX_PRICE_VIEW_LENGTH = 13;

const ClassPrice = ({ index, month, data, setData, MCCount, setMCCount, deleteD, setDelete }) => {

    const PTRef = useRef();

    const [dropdownPT, setDropdownPT] = useState(false);
    const [focus, setFocus] = useState(false);


    const PTHandleClose = (e) => {
        if (dropdownPT && (!PTRef.current || !PTRef.current.contains(e.target))) {
            setDropdownPT(false)
        }
    };

    useEffect(() => {
        window.addEventListener("click", PTHandleClose);

        return () => {
            window.removeEventListener("click", PTHandleClose);
        };
    }, [dropdownPT]);

    const ChangeMonths = (months) => {

        let PriceList = data?.price;

        if(PriceList) {
            if(month != months) {
                const CheckData = PriceList[months];

                if(!CheckData) {
                    let ThisData = PriceList[month];
                    delete PriceList[month];
                    PriceList[months] = ThisData;

                    const Results = Object.fromEntries(
                        Object.entries(PriceList).sort(([a],[b]) => a < b? -1: 1 )
                    );

                    setData((prev) => ({ ...prev, price: Results }));
                } else {
                    window.alert('중복된 기간입니다.\n정보를 확인해주세요!')
                }
            }
        }

    }

    const ChangePrice = (value) => {
        let PriceList = data?.price;

        if(PriceList) {
            PriceList[month] = {
                id: PriceList[month].id,
                price: localeStringMaker(value).substring(0, MAX_PRICE_VIEW_LENGTH),
                weekend_price: localeStringMaker(value).substring(0, MAX_PRICE_VIEW_LENGTH)
            };

            setData((prev) => ({ ...prev, price: PriceList }));
        }
    }

    const DeletePrice = () => {
        let PriceList = data?.price;

        if(PriceList) {
            let deleteList = deleteD?.price;

            let ThisData = PriceList[month];
            delete PriceList[month];

            deleteList[deleteList.length] = ThisData
            
            setMCCount(MCCount - 1);

            setData((prev) => ({ ...prev, price: PriceList }));

            setDelete((prev) => ({ ...prev, price: deleteList }));
        }
    }

    return (
        <Wrapper width="100%" jc="flex-start" gap="8px">
            <Wrapper width="calc(40% - (16px + 8px))">

                <Wrapper
                    ref={PTRef}
                    padding="12px"
                    cursor="pointer"
                    position="relative"
                    onClick={(e) => {
                    e.stopPropagation();
                        setDropdownPT(!dropdownPT);
                    }}
                    height="44px"
                    radius="8px"
                    width="100%"
                    bgc={colors.White}
                    border={`1px solid ${colors.Gr02}`}
                    jc="space-between"
                >
                    <Text size="14px" weight="500" lh="22px" ls="-0.28px" color={colors.Gr05}>
                        {month}개월
                    </Text>

                    <Arrow
                        width="16px"
                        height="16px"
                        fill={colors.Gr04}
                        style={{ transition: "all ease 0.5s", transform: dropdownPT ? "rotate(-90deg)" : "rotate(90deg)" }}
                    />

                    {dropdownPT && (
                        <AbsoluteWrapper
                            maxHeight="250px"
                            z={1}
                            fd="column"
                            width="100%"
                            top="54px"
                            left="0"
                            bgc={colors.White}
                            border={`1px solid ${colors.Gr02}`}
                            radius="8px"
                            of="auto"
                        >
                            {MonthCount.map((x, idx) => (
                                <DropDown
                                    key={`${idx}_${index}_month`}
                                    last={idx + 1 === MonthCount.length}
                                    onClick={() => {
                                        ChangeMonths(x)
                                    }}
                                >
                                    {`${x}개월`}
                                </DropDown>
                            ))}
                        </AbsoluteWrapper>
                    )}
                </Wrapper>
            </Wrapper>

            <Wrapper width="calc(60% - (16px + 8px))">
                <Wrapper
                    height="44px"
                    width="100%"
                    border={`1px solid ${focus ? colors.Bl07 : colors.Gr02}`}
                    radius="8px"
                    padding="0 16px"
                    jc="center"
                    ai="center"
                >
                    <Input
                        type="tel"
                        onFocus={() => setFocus(true) }
                        onBlur={() => setFocus(false)}
                        width="100%"
                        value={data?.price[month] ? data?.price[month].price : ""}
                        onChange={(e) => ChangePrice(e.target.value)}
                        maxLength={13}
                        size="14px"
                        weight="500"
                        lh="22px"
                        ls="-0.28px"
                        phColor={colors.Gr03}
                        placeholder="판매 금액을 적어주세요"
                    />
                </Wrapper>
                <Text ws="pre" size="16px" weight="500" lh="24px" ls="-0.32px" color={colors.Gr04}>
                    원
                </Text>
            </Wrapper>

            {
                index != 0 ?
                    <Wrapper
                        border={`2px solid ${colors.Red03}`}
                        radius="8px"
                        jc="center"
                        ai="center"
                        height="32px"
                        width="32px"
                        cursor={`pointer`}
                        onClick={() => DeletePrice()}
                    >
                        <Minus
                            width="2.6rem"
                            height="2.6rem"
                            fill={colors.Red03}
                        />
                    </Wrapper>
                : ""
            }


        </Wrapper>
    )

}

export default ClassPrice;

const DropDown = styled.div`
    width: 100%;
    height: 52px;
    padding: 12px;
    border-bottom: ${({ last }) => !last && `1px solid ${colors.Gr02}`};
    color: ${colors.Gr04};
    font-size: 13px;
    font-weight: 500;
    line-height: 142%;
    letter-spacing: -0.26px;
`;
