import React, { useState, useEffect } from "react";
import styled from "styled-components";
import axios from "axios";
import { useParams, useNavigate } from "react-router-dom";
import Sheet from "react-modal-sheet";

import SemosSchedule from "../components/signup/semos_schedule";
import Header from "../components/basic/Header";
import { GetOne } from "../tool/localstorage";
import { LoadingSpinner } from "../tool/loading";

import { PC } from "../styles/globalStyles";

const H = window.innerHeight;

const snap = H < 740 ? 0.3 : 0.25;

function SemosScheduleRevise() {
  const Params = useParams();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(true);

  const number = Params.id;

  const [addressS, setAddressS] = useState(false);
  const [scheduleName, setScheduleName] = useState("");
  const [activeTime, setActiveTime] = useState({
    start: "06:00",
    end: "22:00",
  });

  const [yoil, setYoil] = useState([]);

  const [start, setStart] = useState([]);

  const [bottom, setBottom] = useState(false);

  const [focus, setFocus] = useState({
    name: false,
    address_extra: false,
  });

  const [address, setAddress] = useState({
    address: "",
    address_extra: "",
    address_dong: "",
    address_gu: "",
    address_si: "",
  });

  const next =
    scheduleName &&
    activeTime.start &&
    activeTime.end &&
    yoil.length &&
    start.length &&
    address.address &&
    address.address_extra &&
    address.address_dong &&
    address.address_gu &&
    address.address_si;

  useEffect(() => {
    var token = GetOne("token");
    const frm = new FormData();

    frm.append("mode", "partner_schedule_info");
    frm.append("token", token);
    frm.append("number", number);

    axios
      .post("https://ai.semos.kr/semos_partner/schedule/data", frm, {
        headers: { "Content-Type": "multipart/form-data" },
      })
      .then((data) => {
        var Value = data.data;
        var Status = Value.status;
        var StatusMessage = Value.status_message;
        var ScheduleInfo = Value.schedule_info;

        if (Status == "success") {
          setScheduleName(ScheduleInfo.title);
          setActiveTime(ScheduleInfo.activeTime);
          setYoil(ScheduleInfo.yoil);
          setStart(ScheduleInfo.start);
          setAddress(ScheduleInfo.address);
          setLoading(false);
        } else {
          setLoading(false);
          window.alert(StatusMessage);
        }
      });
  }, []);

  const checkNextStep = () => {
    var token = GetOne("token");
    setLoading(true);

    axios
      .post(
        "https://ai.semos.kr/semos_partner/schedule/data",
        {
          mode: "partner_schedule_revise",
          token: token,
          id: number,
          title: scheduleName,
          address: address,
          activeTime: activeTime,
          yoil: yoil,
          start: start,
        },
        {
          headers: { "Content-Type": "application/x-www-form-urlencoded" },
        }
      )
      .then((data) => {
        var Value = data.data;
        var Success = Value.status;
        var SuccessMessage = Value.status_message;

        if (Success == "success") {
          setLoading(false);
          window.alert("세모스 스케줄 수정이 완료되었습니다.");
          navigate("/semos_activity_calender", { replace: true });
        } else {
          setLoading(false);
          window.alert(SuccessMessage);
        }
      });
  };

  const ScheduleDelete = () => {
    var token = GetOne("token");
    setLoading(true);

    axios
      .post(
        "https://ai.semos.kr/semos_partner/schedule/data",
        {
          mode: "schedule_delete_partner",
          token: token,
          number: number,
        },
        {
          headers: { "Content-Type": "application/x-www-form-urlencoded" },
        }
      )
      .then((data) => {
        var Value = data.data;
        var Success = Value.status;
        var SuccessMessage = Value.status_message;

        if (Success == "success") {
          window.alert("세모스 일정이 삭제되었습니다.");
          setLoading(false);
          navigate("/semos_activity_calender", { replace: true });
        } else {
          setLoading(false);
          window.alert(SuccessMessage);
        }
      });
  };

  return (
    <Container>
      <Header title={scheduleName} back={true} />

      <InnerContainer>
        <SemosSchedule
          scheduleName={scheduleName}
          setScheduleName={setScheduleName}
          activeTime={activeTime}
          setActiveTime={setActiveTime}
          yoil={yoil}
          setYoil={setYoil}
          start={start}
          setStart={setStart}
          focus={focus}
          setFocus={setFocus}
          address={address}
          setAddress={setAddress}
          setAddressS={setAddressS}
        />

        <BackgroundContent
          padding={`23px 24px 0 24px`}
          jc="space-between"
          ai={`center`}
        >
          <TextContent
            onClick={() => setBottom(true)}
            jc={`center`}
            width={`30%`}
            padding={`16.8px 0`}
            bgc={`#ffffff`}
            br={`8`}
          >
            <TextView
              color={`#FF9187`}
              fw={`500`}
              fs={`14px`}
              lh={`142%`}
            >{`삭제하기`}</TextView>
          </TextContent>
          <TextContent
            onClick={() => next && checkNextStep()}
            jc={`center`}
            width={`64%`}
            padding={`16.8px 0`}
            bgc={`${next ? `#0E6DFF` : "#F5F5F5"}`}
            br={`8`}
            border={`1px solid ${next ? `#0E6DFF` : "#DCDCDC"}`}
          >
            <TextView
              color={next ? `#ffffff` : `#ACAFB5`}
              fw={`600`}
              fs={`16px`}
              lh={`142%`}
            >{`저장하기`}</TextView>
          </TextContent>
        </BackgroundContent>
      </InnerContainer>

      {/* <AddressPopup view={addressS} id={"PostCodeAdd"} /> */}
      {window.document.documentElement.clientWidth < PC && (
        <AddressPopup view={addressS} id={"PostCodeAdd"} />
      )}

      <BackButtonModal isOpen={bottom} snapPoints={[snap]}>
        <BackButtonModal.Container>
          <BackModalLayout>
            <ModalHeader>이 스케줄을 삭제하시겠어요?</ModalHeader>
            <Divider h={9} />
            <ModalText>
              이 일정과 연결된 액티비티의 스케줄을 변경하셔야 해요.
            </ModalText>
            <Divider h={36} />
            <ButtonContainer>
              <BackButton onClick={() => setBottom(false)}>취소</BackButton>
              <StayButton onClick={() => ScheduleDelete()}>삭제하기</StayButton>
            </ButtonContainer>
          </BackModalLayout>
        </BackButtonModal.Container>
        <BackButtonModal.Backdrop />
      </BackButtonModal>

      <LoadingSpinner
        loading={loading}
        title={`정보를 확인하고 있습니다.`}
        content={`잠시만 기다려 주세요!`}
      />
    </Container>
  );
}

export default SemosScheduleRevise;

const Container = styled.section`
  height: calc(var(--vh, 1vh) * 100);
  /* height: 100vh; */
  overflow: hidden;
`;

const AddressPopup = styled.section`
  height: calc(var(--vh, 1vh) * 100);
  /* height: 100vh; */
  width: calc(var(--vw, 1vw) * 100);
  overflow: hidden;
  position: fixed;
  z-index: 150;
  background-color: #ffffff;
  top: 0;
  right: 0;
  display: ${({ view }) => (view ? `inline-flex` : `none`)};
`;

const InnerContainer = styled.div`
  width: 100%;
  height: calc((var(--vh, 1vh) * 100) - (51px));
  /* height: calc(100vh - (51px)); */
  overflow-y: auto;
  overflow-x: hidden;
  display: inline-block;
  position: relative;
  z-index: 51;
  padding-bottom: 10px;
`;

const BackgroundContent = styled.div`
  width: 100%;
  border: ${({ border }) => border && `${border}`};
  background-color: ${({ bgc }) => bgc && `${bgc}`};
  padding: ${({ padding }) => padding && `${padding}`};
  display: inline-flex;
  border-radius: ${({ br }) => br && `${br}px`};
  justify-content: ${({ jc }) => jc && `${jc}`};
  align-items: ${({ ai }) => ai && `${ai}`};
  flex-wrap: ${({ wrap }) => wrap && `${wrap}`};
  position: relative;
`;

const TextContent = styled.div`
  cursor: pointer;
  width: ${({ width }) => width && `${width}`};
  border: ${({ border }) => border && `${border}`};
  background-color: ${({ bgc }) => bgc && `${bgc}`};
  padding: ${({ padding }) => padding && `${padding}`};
  display: inline-flex;
  flex-wrap: ${({ wrap }) => wrap && `${wrap}`};
  border-radius: ${({ br }) => br && `${br}px`};
  justify-content: ${({ jc }) => jc && `${jc}`};
  align-items: ${({ ai }) => ai && `${ai}`};
  ${({ gmarket }) => gmarket && `font-family : "GmarketSans" !important;`}
`;

const TextView = styled.span`
  width: ${({ width }) => width && `${width}`};
  text-align: ${({ ta }) => ta && `${ta}`};
  color: ${({ color }) => color && `${color}`};
  font-weight: ${({ fw }) => fw && `${fw}`};
  font-size: ${({ fs }) => fs && `${fs}`};
  line-height: ${({ lh }) => lh && `${lh}`};
  display: inline-block;
  text-overflow: ${({ to }) => to && `${to}`};
  overflow: hidden;
  white-space: ${({ ws }) => ws && `${ws}`};
  ${({ gmarket }) => gmarket && `font-family : "GmarketSans" !important;`}
`;

const BackButtonModal = styled(Sheet)``;

const BackModalLayout = styled.div`
  padding: 36px 24px 20px 24px;
`;

const ModalHeader = styled.div`
  font-weight: 600;
  font-size: 18px;
  line-height: 142%;
  letter-spacing: -0.02em;
  color: #1a1c1f;
`;

const ModalText = styled.div`
  font-weight: 500;
  font-size: 14px;
  line-height: 142%;
  /* or 20px */

  letter-spacing: -0.02em;

  /* Greyscale/Gr 02 */

  color: #818792;
`;

const Divider = styled.div`
  height: ${({ h }) => h}px;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Button = styled.div`
  font-weight: 500;
  font-size: 14px;
  line-height: 142%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  letter-spacing: -0.02em;
  width: 48%;
  padding: 18px 0;
  border-radius: 8px;
`;

const BackButton = styled(Button)`
  color: #3c3e41;
  width: 48%;
  background-color: #e5f0ff;
`;

const StayButton = styled(Button)`
  color: #ffffff;
  width: 48%;
  background-color: #0e6dff;
`;
