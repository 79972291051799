import React, { useEffect, useState, useRef } from "react";
import styled from "styled-components";
import Modal from "react-modal";
import axios from "axios";

//component 

// style
import colors from "../../styles/colors";
import { Wrapper, Input, Text, AbsoluteWrapper } from "../../components/layouts/layoutStorage";

// img
import { ReactComponent as Search } from "../../resource/icon/search.svg";
import { ReactComponent as Arrow } from "../../resource/icon/arrow.svg";

const PresentModal = ({
    modal,
    setModal,
    title,
    ticket,
    crew,
    setLoading,
    centerId
}) => {

    const [phone, setPhone] = useState('010');
    const [user, setUser] = useState();
    const [count, setCount] = useState(1);
    const [dropdown, setDropdown] = useState(false);
    const [countList, setCountList] = useState();

    const Ref = useRef();

    const PresentTicket = () => {
        if(user?.id && (user?.name != '미가입 회원')) {
            setLoading(true);
    
            const frm = new FormData();
    
            frm.append("mode", "present_ticket_present");
            frm.append("center_id", centerId);
            frm.append("phone", user.phone);
            frm.append("count", count);
            frm.append("crew_id", user.id);
            frm.append("crew_name", user.name);
            frm.append("ticket_id", ticket.id);
            frm.append("name", user.name);
            frm.append("nickname", user.nickname);
            frm.append("send_user", crew.crew_id);
            frm.append("send_phone", crew.phone);
    
            axios
            .post("https://ai.semos.kr/semos_partner/v3/activity/Crew", frm, {
                headers: { "Content-Type": "multipart/form-data" },
            })
            .then((data) => {
                var Value = data.data;
                var Status = Value.status;
                var StatusMessage = Value.status_message;
    
                if (Status == "success") {
                    window.alert(StatusMessage);
                    setLoading(false);
                    window.location.reload();
                } else {
                    window.alert(StatusMessage);
                    setLoading(false);
                }
            });
        }
    }

    const PhoneInput = (number) => {
        let PN = number;

        let HP = PN
        .replace(/[^0-9]/, "")
        .replace(/^(\d{2,3})(\d{3,4})(\d{4})$/, `$1-$2-$3`);

        setPhone(HP);
    }

    const SearchUser = () => {
        setLoading(true);

        const frm = new FormData();

        frm.append("mode", "search_user_present");
        frm.append("phone", phone);
        frm.append("center_id", centerId);

        axios
        .post("https://ai.semos.kr/semos_partner/v3/activity/Facility", frm, {
            headers: { "Content-Type": "multipart/form-data" },
        })
        .then((data) => {
            var Value = data.data;
            var Status = Value.status;
            var StatusMessage = Value.status_message;
            var UserData = Value.user_data;

            if (Status == "success") {
                setUser(UserData);
                setLoading(false);
            } else if(Status == "empty") {
                setUser(UserData);
                setLoading(false);
            } else {
                window.alert(StatusMessage);
                setLoading(false);
            }
        });
    }

    const HandleClose = (e) => {
        if (dropdown && (!Ref.current || !Ref.current.contains(e.target))) {
            setDropdown(false);
        }
    };
    
    useEffect(() => {
        window.addEventListener("click", HandleClose);
    
        return () => {
            window.removeEventListener("click", HandleClose);
        };
    }, [dropdown]);

    useEffect(() => {
        const NotUse = ticket?.not_use;
        const Keyboard = Array.from({length:NotUse}, (v,i)=>i+1); 
        console.log(Keyboard)
        setCountList(Keyboard);
    }, [ticket?.not_use])


    return (
        <Modal
            isOpen={modal}
            onRequestClose={() => setModal(false)}
            contentLabel="Alert Modal"
            style={customStyles}
        >
            <Container>

                <AffiliateModalLayout>
                    <Wrapper
                        width={`100%`}
                        margin={`0 0 10px 0`}
                        jc={`center`}
                    >   
                        <Text 
                            width={`100%`} 
                            size="1.8rem" 
                            weight="600" 
                            lh="24px" 
                            ls="-0.32px" 
                            color={colors.Gr05}
                            ta={`center`}
                        >
                            {title}
                        </Text>
                    </Wrapper>      

                    <Wrapper
                        width={`100%`}
                        margin={`0 0 5px 0`}
                    >   
                        <Text 
                            width={`100%`} 
                            size="1.6rem" 
                            weight="600" 
                            lh="24px" 
                            ls="-0.32px" 
                            color={colors.Gr05}
                        >
                            이용권 정보
                        </Text>
                    </Wrapper>  

                    <Wrapper
                        width={`100%`}
                        margin={`0 0 10px 0`}
                        style={{
                            flexDirection : "column"
                        }}
                    >   
                        <Text 
                            width={`100%`} 
                            size="1.6rem" 
                            weight="500" 
                            lh="24px" 
                            ls="-0.32px" 
                            color={colors.Gr05}
                        >
                            {ticket?.title}
                        </Text>

                        <Text 
                            width={`100%`} 
                            size="1.6rem" 
                            weight="500" 
                            lh="24px" 
                            ls="-0.32px" 
                            color={colors.Gr05}
                        >
                            {`사용 횟수 : ${ticket?.use} / ${ticket?.count}`}
                        </Text>
                    </Wrapper>  


                    <Wrapper
                        width={`100%`}
                        margin={`0 0 5px 0`}
                    >   
                        <Text 
                            width={`100%`} 
                            size="1.6rem" 
                            weight="600" 
                            lh="24px" 
                            ls="-0.32px" 
                            color={colors.Gr05}
                        >
                            회원 검색
                        </Text>
                    </Wrapper>   

                    <Wrapper
                        width={`100%`}
                        style={{
                            flexDirection : "row"
                        }}
                        border={`1px solid ${colors.Gr02}`}
                        radius={`8px`}
                        padding={`8px 5px`}
                        wrap={`wrap`}
                        margin={`0 0 10px 0`}
                    >  
                        <Input 
                            value={phone}
                            style={{
                                flex : 1,
                                fontSize : '1.8rem'
                            }}
                            maxLength={13}
                            onChange={(e) => PhoneInput(e.target.value)}
                            placeholder="010-XXXX-XXXX"
                        />

                        <Search
                            fill={colors.Gr03} width="28px" height="28px"
                            style={{
                                cursor : "pointer",
                                marginLeft : 10
                            }}
                            onClick={() => SearchUser()}
                        />
                    </Wrapper> 

                    <Wrapper
                        width={`100%`}
                        margin={`0 0 5px 0`}
                    >   
                        <Text 
                            width={`100%`} 
                            size="1.6rem" 
                            weight="600" 
                            lh="24px" 
                            ls="-0.32px" 
                            color={colors.Gr05}
                        >
                            선물 횟수
                        </Text>
                    </Wrapper>  

                    {/* days */}
                    <Wrapper
                        width={`100%`}
                        jc={`flex-end`}
                    >
                        <Wrapper
                            ref={Ref}
                            padding="12px"
                            cursor="pointer"
                            position="relative"
                            onClick={(e) => {
                                e.stopPropagation();
                                setDropdown(!dropdown);
                            }}
                            height="45px"
                            radius="8px"
                            width="8rem"
                            bgc={colors.White}
                            border={`1px solid ${colors.Gr02}`}
                            jc="space-between"
                        >
                            <Text 
                                size="14px" 
                                weight="500" 
                                lh="22px" 
                                ls="-0.28px" 
                                color={colors.Gr05}
                            >
                                {count}
                            </Text>

                            <Arrow
                                width="16px"
                                height="16px"
                                fill={colors.Gr04}
                                style={{ 
                                    transition: "all ease 0.5s", 
                                    transform: dropdown ? "rotate(-90deg)" : "rotate(90deg)" 
                                }}
                            />

                            {dropdown && (
                                <AbsoluteWrapper
                                    maxHeight="10rem"
                                    z={1}
                                    fd="column"
                                    width="100%"
                                    top="50px"
                                    left="0"
                                    bgc={colors.White}
                                    border={`1px solid ${colors.Gr02}`}
                                    radius="8px"
                                    of="auto"
                                >
                                    {countList.map((x, idx) => (
                                        <DropDown
                                            active={x == count}
                                            key={`${idx}_count`}
                                            last={idx + 1 === countList.length}
                                            onClick={() => {
                                                setCount(x);
                                            }}
                                        >
                                            {x}
                                        </DropDown>
                                    ))}
                                </AbsoluteWrapper>
                            )}
                        </Wrapper>
                    </Wrapper>

                    {((user?.id == '미가입 회원') && user?.id) ? 
                        <>
                            <Wrapper
                                width={`100%`}
                                margin={`0 0 5px 0`}
                            >   
                                <Text 
                                    width={`100%`} 
                                    size="1.6rem" 
                                    weight="600" 
                                    lh="24px" 
                                    ls="-0.32px" 
                                    color={colors.Gr05}
                                >
                                    신규 회원명
                                </Text>
                            </Wrapper>  

                            {/* user */}
                            <Wrapper
                                width={`100%`}
                                style={{
                                    flexDirection : "row"
                                }}
                                border={`1px solid ${colors.Gr02}`}
                                radius={`8px`}
                                padding={`8px 5px`}
                                wrap={`wrap`}
                                margin={`0 0 10px 0`}
                            >  
                                <Input 
                                    value={user?.name}
                                    style={{
                                        flex : 1,
                                        fontSize : '1.8rem'
                                    }}
                                    maxLength={20}
                                    onChange={(e) => setUser({ ...user, name: e.target.value })}
                                    placeholder="신규로 추가할 회원명을 입력해주세요"
                                />
                            </Wrapper> 
                        </>
                    : <></>}
                    
                    <Wrapper
                        width={`100%`}
                        margin={`25px 0 0 0`}
                        jc={`flex-end`}
                    >   
                        <Text 
                            size="2rem" 
                            weight="600" 
                            lh="24px" 
                            ls="-0.32px" 
                            color={colors.Gr05}
                            ta={`right`}
                        >
                            {`회원명 : ${user ? user.name : `선물을 받을 회원을 검색해주세요!`}`}
                        </Text>
                    </Wrapper>   

                    <Wrapper
                        width={`100%`}
                        margin={`15px 0 0 0`}
                        jc={`flex-end`}
                    >   
                        <Text 
                            size="2rem" 
                            weight="600" 
                            lh="24px" 
                            ls="-0.32px" 
                            color={colors.Gr05}
                            ta={`right`}
                        >
                            {`횟수 : ${count ? count : `선물 횟수를 선택해주세요!`}`}
                        </Text>
                    </Wrapper>  

                    <Wrapper
                        onClick={() => PresentTicket()}
                        jc="center"
                        ai="center"
                        width="calc(100%)"
                        height="40px"
                        cursor={"pointer"}
                        radius="8px"
                        bgc={(user?.id && (user?.name?.length > 1)) ? colors.Bl07 : colors.Gr01}
                        margin={`15px 0 0 0`}
                    >
                        <Text 
                            ta="center" 
                            size="2rem" 
                            weight="600" 
                            lh="22px" 
                            ls="-0.28px" 
                            color={(user?.id && (user?.name != '미가입 회원')) ? colors.White : colors.Gr03}
                        >
                            {`선물하기`}
                        </Text>
                    </Wrapper>


                </AffiliateModalLayout>
            </Container>
        </Modal>
    );
};

export default PresentModal;

const customStyles = {
    content: {
        width: "calc(100% - 24px)",
        maxWidth: "700px",
        marginRight: "-50%",
        padding: 0,
        borderRadius: "16px",
        top: "50%",
        left: "50%",
        right: "auto",
        bottom: "auto",
        transform: "translate(-50%, -50%)",
    },
};

const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    max-height: 600px;
    max-width: 700px;
`;

const AffiliateModalLayout = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    max-height: 600px;
    max-width: 600px;
    padding: 12px 10px;
    border-radius: 0;
`;

const DropDown = styled.div`
    width: 100%;
    padding: 6px 8px;
    border-bottom: ${({ last }) => !last && `1px solid ${colors.Gr02}`};
    color: ${({ active }) => active ? colors.Bl07 : colors.Gr04 };
    font-size: 1.4rem;
    font-weight: ${({ active }) => active ? 600 : 500};
    line-height: 142%;
    letter-spacing: -0.26px;
    text-align : center;
    cursor : pointer;
`;