import React from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";

// style
import colors from "../../styles/colors";

import { ReactComponent as Arrow } from "../../resource/icon/arrow.svg";

/**
 * type
 * review ||
 * review : title 우측 review 개수 표시
 */
/**
 * back : back button 노출 여부
 * true || false (boolean)
 */
const Header = ({
  title = "",
  back = false,
  handleClick, // props로 클릭이벤트 전달 없을 경우 navigate(-1)
  border = true,
  type = "",
  reviewCount = 0,
  children,
  ai = "start",
  jc = "space-between",
}) => {
  const navigate = useNavigate();

  return (
    <HeaderContainer ai={ai} jc={jc} border={border}>
      {back ? (
        <GoBackButton onClick={() => (typeof handleClick === "function" ? handleClick() : navigate(-1))}>
          <Arrow width="24px" height="24px" fill={colors.Gr05} />
        </GoBackButton>
      ) : (
        <></>
      )}
      <TitleContainer>
        <HeaderTitle>{title}</HeaderTitle>
        {type === "review" && <ReviewCount>{reviewCount}</ReviewCount>}
      </TitleContainer>
      {children}
    </HeaderContainer>
  );
};

export default Header;

const HeaderContainer = styled.div`
  display: flex;
  align-items: ${({ ai }) => ai};
  justify-content: ${({ jc }) => jc};
  width: 100%;
  height: 40px;
  padding: 5px 20px;
  background-color: ${colors.White};
  border-bottom: ${({ border }) => (border ? `2px solid ${colors.Gr01}` : "none")};
  position: sticky;
  z-index: 10;
  top: 0;
  text-align: center;
`;

const GoBackButton = styled.button`
  svg {
    transform: rotate(180deg);
  }
`;

const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
  /* width: 100%; */
  width: calc(100% - 88px);
  position: absolute;
  z-index: -1;
  bottom: 12px;
  left: 50%;
  top : 50%;
  transform: translate(-50%, -50%);
`;

const HeaderTitle = styled.h1`
  /* width: calc(100% - 88px); */
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  color: ${colors.Gr06};
  line-height: 26px;
  letter-spacing: -0.02em;
  font-size: 18px;
  font-weight: 700;
`;

const ReviewCount = styled.span`
  color: ${colors.Bl07};
  line-height: 26px;
  letter-spacing: -0.02em;
  font-size: 18px;
  font-weight: 700;
`;
