import React, { useEffect, useState, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";
import axios from "axios";

//tool
import { GetOne } from "../tool/localstorage";
import { setImageFromFile, CropImage } from "../tool/ImageCrop";
import AddressService from "../tool/address";

//style
import { Wrapper, Text, Input, Icon, AbsoluteWrapper } from "../components/layouts/layoutStorage";
import colors from "../styles/colors";
import { PC } from "../styles/globalStyles";

//component
import { Loading } from "../tool/Loading";
import FacilityHeader from "../components/facilityRegister/FacilityHeader";
import FacilityTime from "../components/facilityRegister/FacilityTime";

//icon
import { ReactComponent as Arrow } from "../resource/icon/arrow.svg";
import { ReactComponent as Camera } from "../resource/icon/camera.svg";
import { ReactComponent as Search } from "../resource/icon/search.svg";

// Default Data
const ScheduleDay = ['mon', 'tue', 'wed', 'thu', 'fri', 'sat', 'sun', 'holiday'];
const ScheduleDayKorean = ['월', '화', '수', '목', '금', '토', '일', '공휴일'];

const FacilityRegister = () => {

    const navigate = useNavigate();

    const params = useParams();

    const minRef = useRef();
    const maxRef = useRef();

    const [loading, setLoading] = useState(false);
    const [cropModal, setCropModal] = useState(false);
    const [focusName, setFocusName] = useState(false);
    const [addressS, setAddressS] = useState(false);

    const [focus, setFocus] = useState({
        intro : false,
        time : false,
        address_etc : false,
    });
    
    const [data, setData] = useState({
        thumbnail : "",
        title : "",
        intro : "",
        address : "",
        address_etc : "",
        x : "",
        y : "",
        state : "",
        city : "",
        street : "",
        min_capacity : 1,
        max_capacity : 3,
        schedule_status : {
            mon : true,
            tue : true,
            wed : true,
            thu : true,
            fri : true,
            sat : false,
            sun  : false,
            holiday : false,
        },
        schedule : {
            mon : {
                start_hour : `08`,
                start_min : `00`,
                finish_hour : `24`,
                finish_min : `00`
            },
            tue : {
                start_hour : `08`,
                start_min : `00`,
                finish_hour : `24`,
                finish_min : `00`
            },
            wed : {
                start_hour : `08`,
                start_min : `00`,
                finish_hour : `24`,
                finish_min : `00`
            },
            thu : {
                start_hour : `08`,
                start_min : `00`,
                finish_hour : `24`,
                finish_min : `00`
            },
            fri : {
                start_hour : `08`,
                start_min : `00`,
                finish_hour : `24`,
                finish_min : `00`
            },
            sat : {
                start_hour : `08`,
                start_min : `00`,
                finish_hour : `24`,
                finish_min : `00`
            },
            sun : {
                start_hour : `08`,
                start_min : `00`,
                finish_hour : `24`,
                finish_min : `00`
            },
            holiday : {
                start_hour : `08`,
                start_min : `00`,
                finish_hour : `24`,
                finish_min : `00`
            },
        },
        background : []
    });

    const [cropImageSetting, setCropImageSetting] = useState({
        image: "",
        type: "",
        width: 0,
        height: 0,
    });

    const [valid, setValid] = useState({
        title: true,
        intro: true,
        min_capacity: true,
        max_capacity: true,
        thumbnail: true,
    });

    const [dropdown, setDropdown] = useState({
        min: false,
        max: false,
    });

    const addImage = (file) => {
        setData((prev) => ({ ...prev, thumbnail: file }));
    };

    const minHandleClose = (e) => {
        if (dropdown.min && (!minRef.current || !minRef.current.contains(e.target))) {
            setDropdown({
                min: false,
                max: false,
            });
        }
    };

    const maxHandleClose = (e) => {
        if (dropdown.max && (!maxRef.current || !maxRef.current.contains(e.target))) {
            setDropdown({
                min: false,
                max: false,
            });
        }
    };

    const W = window.document.documentElement.clientWidth;
    const MIN_INTRO_LENGTH = 10;
    const MAX_INTRO_LENGTH = 300;
    const MAX_NAME_LENGTH = 50;
    const MAX_ADDRESS_LENGTH = 50;
    const Regex = /(?:[\u2700-\u27bf]|(?:\ud83c[\udde6-\uddff]){2}|[\ud800-\udbff][\udc00-\udfff]|[\u0023-\u0039]\ufe0f?\u20e3|\u3299|\u3297|\u303d|\u3030|\u24c2|\ud83c[\udd70-\udd71]|\ud83c[\udd7e-\udd7f]|\ud83c\udd8e|\ud83c[\udd91-\udd9a]|\ud83c[\udde6-\uddff]|\ud83c[\ude01-\ude02]|\ud83c\ude1a|\ud83c\ude2f|\ud83c[\ude32-\ude3a]|\ud83c[\ude50-\ude51]|\u203c|\u2049|[\u25aa-\u25ab]|\u25b6|\u25c0|[\u25fb-\u25fe]|\u00a9|\u00ae|\u2122|\u2139|\ud83c\udc04|[\u2600-\u26FF]|\u2b05|\u2b06|\u2b07|\u2b1b|\u2b1c|\u2b50|\u2b55|\u231a|\u231b|\u2328|\u23cf|[\u23e9-\u23f3]|[\u23f8-\u23fa]|\ud83c\udccf|\u2934|\u2935|[\u2190-\u21ff])/g;

    const changeValue = (type, value) => {
        switch (type) {
            case "intro":
                // 파트너 소개
                value = value.substr(0, MAX_INTRO_LENGTH);
                const targetLength = value.trim().length && value.trim().length;

                if((targetLength <= MAX_INTRO_LENGTH) && (targetLength >= MIN_INTRO_LENGTH)) {
                    setValid({ ...valid, intro: true })
                } else {
                    setValid({ ...valid, intro: false });
                }
                setData({ ...data, intro: value });
            break;

            case "yoil":
                // 운영시간 요일
                let OgData = data;
                let OgSchedule = OgData.schedule_status;

                if(OgSchedule) {
                    let ThisDayStatus = OgSchedule[value];
                    OgSchedule[value] = !ThisDayStatus;

                    setData({ ...data, schedule_status: OgSchedule });
                }
            break;

            default:

            break;
        }
    };

    useEffect(() => {
        window.addEventListener("click", minHandleClose);
    
        return () => {
            window.removeEventListener("click", minHandleClose);
        };
    }, [dropdown.min]);
    
    useEffect(() => {
        window.addEventListener("click", maxHandleClose);
    
        return () => {
            window.removeEventListener("click", maxHandleClose);
        };
    }, [dropdown.max]);

    useEffect(() => {
        if(params?.id) {
            GetData()
        }
    }, []);

    const SaveFacility = () => {

        const TitleStatus = data.title.length > 4;
        const IntroStatus = data.intro.length > 9;
        const MinStatus = data.min_capacity > 0;
        const MaxStatus = (data.max_capacity > 0) && (data.min_capacity <= data.max_capacity);
        const ThumbnailStatus = data.thumbnail.length > 0;

        if(TitleStatus && IntroStatus && MinStatus && MaxStatus && ThumbnailStatus) {

            setLoading(true);

            const token = GetOne("token");
    
            axios
                .post(
                    "https://ai.semos.kr/semos_partner/v3/activity/Facility",
                    {
                        mode: "save",
                        token: token,
                        data: data,
                        facility_id: params?.id ? params?.id : 'new'
                    },
                    {
                        headers: {
                            "Content-Type": "application/x-www-form-urlencoded",
                        },
                    }
                )
                .then((data) => {
                    let Value = data.data;
                    let Status = Value.status;
                    let StatusMessage = Value.status_message;
        
                    if (Status == "success") {
                        setLoading(false);
                        window.alert(StatusMessage);
                        if(params?.id != 'new') {
                            window.location.replace(`/facility_detail/${params?.id}`)
                        } else {
                            window.location.replace('/facility')
                        }
                
                    } else {
                        setLoading(false);
                        window.alert(StatusMessage);
                    }
                });
        } else {
            window.alert("올바르지 않는 정보가 있습니다.\n입력한 정보를 확인해주세요!");

            setValid({
                title: TitleStatus,
                intro: IntroStatus,
                min_capacity: MinStatus,
                max_capacity: MaxStatus,
                thumbnail: ThumbnailStatus,
            })
        }

    }

    const GetData = () => {

        setLoading(true);

        const token = GetOne("token");
    
        axios
            .post(
                "https://ai.semos.kr/semos_partner/v3/activity/Facility",
                {
                    mode: "facility_revise",
                    token: token,
                    facility_id: params?.id
                },
                {
                    headers: {
                        "Content-Type": "application/x-www-form-urlencoded",
                    },
                }
            )
                .then((data) => {
                let Value = data.data;
                let Status = Value.status;
                let StatusMessage = Value.status_message;
                let FacilityInfo = Value.facility_info;
        
                if (Status == "success") {
                    setLoading(false);
                    setData(FacilityInfo);
                } else {
                    setLoading(false);
                    window.alert(StatusMessage);
                }
            });

    }

    const SetConfirm = () => {

        const TitleStatus = data.title.length > 4;
        const IntroStatus = data.intro.length > 9;
        const MinStatus = data.min_capacity > 0;
        const MaxStatus = (data.max_capacity > 0) && (data.min_capacity <= data.max_capacity);
        const ThumbnailStatus = data.thumbnail.length > 0;
        const Address = data?.address?.length > 0;
        const AddressEtc = data?.address_etc?.length > 0;

        if(
            TitleStatus && 
            IntroStatus && 
            MinStatus && 
            MaxStatus && 
            ThumbnailStatus && 
            Address && 
            AddressEtc
        ) { 
            return true;
        } else {
            return false;
        }

    }

    const AddressChange = (data) => {
        let locationSigungu = data.sigungu.split(" ");
        let loGu = "";
        let loSi = "";
        let loDong = "";
    
        if (locationSigungu.length > 1) {
            loGu = locationSigungu[1].length > 2 ? locationSigungu[1].slice(0, -1) : locationSigungu[1];
            loSi = locationSigungu[0].length > 2 ? locationSigungu[0].slice(0, -1) : locationSigungu[0];
            loDong = data.bname.length > 2 ? data.bname.slice(0, -1) : data.bname;
        } else {
            loGu = data.sigungu.length > 2 ? data.sigungu.slice(0, -1) : data.sigungu;
            loSi = data.sido;
            loDong = data.bname.length > 2 ? data.bname.slice(0, -1) : data.bname;
        }
    
        //전체 주소 변경
        setData((prev) => ({ ...prev, 
            address: data.roadAddress,
            address_etc: "",
            state: loSi,
            city: loGu,
            street: loDong
        }));
        setAddressS(false);
    };

    const mapService = new AddressService(AddressChange);

    const SearchAddress = () => {
        setAddressS(true);
        mapService.open();
    };

    return (
        <Container id="description_container">

            <FacilityHeader
                status={true}
                title={params?.id ? '시설 정보 수정' : '신규 시설 등록'}
                available={false}
                statusHandler={() => console.log("시설 정보 미등록")}
            />

            {/* 이미지 등록 */}
            <Wrapper ai="flex-start" fd="column" width="100%" padding="24px 24px 8px 24px">
                <Text size="16px" weight="600" lh="24px" ls="-0.32px" color={colors.Gr05}>
                시설 대표 이미지
                </Text>

                <ImageLabelBox MD={W > 800} htmlFor={"thumbnail"}>
                    {
                        data?.thumbnail ?
                            <Icon src={data.thumbnail} width="100%" radius="8px" />
                        : 
                        <>
                            <Camera width="32px" height="32px" fill={colors.Gr03} />
                            <Text size="10px" weight="500" lh="132%" ls="-0.22px" color={colors.Gr03}>
                                이미지 등록
                            </Text>
                        </>
                    }
                    <ImageInput
                        type="file"
                        id="thumbnail"
                        accept="image/*"
                        onChange={({ target: { files } }) => {
                            setImageFromFile({
                                file: files[0],
                                setImageUrl: ({ result }) => {
                                    setCropImageSetting({
                                        image: result,
                                        width: 8,
                                        height: 5,
                                        type: "thumbnail",
                                    });
                                    setCropModal(true);
                                },
                            });
                        }}
                    />
                </ImageLabelBox>
            </Wrapper>

            {/* 시설 이름 */}
            <Wrapper width="100%" ai="flex-start" fd="column" gap="8px" padding="24px 24px 8px 24px">
                <Text size="16px" weight="600" lh="24px" ls="-0.32px" color={colors.Gr05}>
                    시설 이름
                </Text>

                <Wrapper width="100%" height="56px" padding="8px 16px" border={`1px solid ${focusName ? colors.Bl07 : colors.Gr02}`} radius="8px">
                    <Input
                        onFocus={() => setFocusName(true)}
                        onBlur={() => setFocusName(false)}
                        value={data?.title}
                        onChange={(e) => {
                            let value = e.target.value;
                            if (Regex.test(value)) {
                                let text = value.replace(Regex, "");
                                setData((prev) => ({ ...prev, title: text.substring(0, MAX_NAME_LENGTH) }));
                            } else {
                                setData((prev) => ({ ...prev, title: value.substring(0, MAX_NAME_LENGTH) }));
                            }
                        }}
                        maxLength={MAX_NAME_LENGTH}
                        size="14px"
                        weight="500"
                        lh="22px"
                        ls="-0.28px"
                        width="100%"
                        phColor={colors.Gr03}
                        placeholder="예시) 스쿼시 1번 코트"
                    />
                    <Text margin="0 0 0 4px" size="14px" weight="500" lh="22px" ls="-0.28px" color={colors.Gr03}>
                        {data?.title.length}/{MAX_NAME_LENGTH}
                    </Text>
                </Wrapper>

                <TipWrap>
                    <span className="tip">Tip!</span>
                    <span>{`실제 표시된 이름과 같도록 적어주세요!`}</span>
                </TipWrap>
            </Wrapper>

            {/* 시설 주소 */}
            <Wrapper width="100%" ai="flex-start" fd="column" padding="24px 24px 8px 24px">
                <Text size="16px" weight="600" lh="24px" ls="-0.32px" color={colors.Gr05}>
                    시설 주소
                </Text>

                <Wrapper
                    margin={`8px 0 5px 0`}
                    height="56px"
                    width="100%"
                    border={`1px solid ${colors.Gr02}`}
                    radius="8px"
                    padding="8px 16px"
                    jc="center"
                    ai="center"
                    cursor={"pointer"}
                    bgc={colors.White}
                    onClick={() => SearchAddress()}
                >
                    <Text width="calc(100% - 24px)" size="14px" weight="500" lh="22px" ls="-0.28px" color={colors.Gr05}>
                        {data?.address ? data.address : "클릭하면 검색할 수 있어요."}
                    </Text>
                    <Search fill={colors.Gr03} width="24px" height="24px" />
                </Wrapper>

                <Wrapper margin={`0 0 8px 0`} width="100%" height="56px" padding="8px 16px" border={`1px solid ${focus.address_etc ? colors.Bl07 : colors.Gr02}`} radius="8px">
                    <Input
                        onFocus={() => setFocus({ ...focus, address_etc: true })}
                        onBlur={() => setFocus({ ...focus, address_etc: false })}
                        value={data?.address_etc}
                        onChange={(e) => {
                            let value = e.target.value;
                            if (Regex.test(value)) {
                                let text = value.replace(Regex, "");
                                setData((prev) => ({ ...prev, address_etc: text.substring(0, MAX_ADDRESS_LENGTH) }));
                            } else {
                                setData((prev) => ({ ...prev, address_etc: value.substring(0, MAX_ADDRESS_LENGTH) }));
                            }
                        }}
                        maxLength={MAX_ADDRESS_LENGTH}
                        size="14px"
                        weight="500"
                        lh="22px"
                        ls="-0.28px"
                        width="100%"
                        phColor={colors.Gr03}
                        placeholder="예시) 스포츠아일랜드 지하 1층 스쿼시장"
                    />
                    <Text size="14px" weight="500" lh="22px" ls="-0.28px" color={colors.Gr03}>
                        {data?.address_etc?.length}/{MAX_ADDRESS_LENGTH}
                    </Text>
                </Wrapper>

                <TipWrap>
                    <span className="tip">Tip!</span>
                    <span>{`시설 상세 주소를 적어주세요!`}</span>
                </TipWrap>
            </Wrapper>

            {/* 시설 소개 */}
            <Wrapper width="100%" ai="flex-start" fd="column" gap="8px" padding="24px 24px 8px 24px">

                <Text size="16px" weight="600" lh="24px" ls="-0.32px" color={colors.Gr05}>
                    시설 소개
                </Text>

                <TextareaContainer w={100} focus={focus.intro}>
                    <TextArea
                        maxLength={MAX_INTRO_LENGTH}
                        type="text"
                        placeholder={`시설을 소개해주세요! (${MAX_INTRO_LENGTH}자 이내)`}
                        value={data?.intro}
                        onChange={(e) => changeValue("intro", e.target.value)}
                        onFocus={() => setFocus({ ...focus, intro: true })}
                        onBlur={() => setFocus({ ...focus, intro: false })}
                    />

                    <IntroText>
                        <span>{data?.intro.length}</span> / {MAX_INTRO_LENGTH} {`(최소 ${MIN_INTRO_LENGTH}자)`}
                    </IntroText>
                </TextareaContainer>

            </Wrapper>

            {/* 최대 인원 */}
            <Wrapper width="100%" ai="flex-start" fd="column" gap="8px" padding="24px 24px 8px 24px">
                <Text size="16px" weight="600" lh="24px" ls="-0.32px" color={colors.Gr05}>최소/최대 인원</Text>

                <Wrapper fd="column" margin="12px 0 8px 0" width="100%">
                    <Wrapper width="100%" jc="space-between" gap="8px">

                        <Wrapper width="100%">

                            <Text ws="pre" margin="0 12px 0 0" size="14px" weight="500" lh="22px" ls="-0.28px" color={colors.Gr04}>
                                최소
                            </Text>

                            <Wrapper
                                ref={minRef}
                                padding="12px"
                                cursor="pointer"
                                position="relative"
                                onClick={(e) => {
                                    e.stopPropagation();
                                    setDropdown({
                                        min: !dropdown.min,
                                        max: false,
                                    });
                                }}
                                height="44px"
                                radius="8px"
                                width="100%"
                                bgc={colors.White}
                                border={`1px solid ${colors.Gr02}`}
                                jc="space-between"
                            >
                                <Text size="14px" weight="500" lh="22px" ls="-0.28px" color={colors.Gr05}>
                                    {data?.min_capacity}인
                                </Text>

                                <Arrow
                                    width="16px"
                                    height="16px"
                                    fill={colors.Gr04}
                                    style={{ transition: "all ease 0.5s", transform: dropdown.min ? "rotate(-90deg)" : "rotate(90deg)" }}
                                />

                                {dropdown.min && (
                                    <AbsoluteWrapper
                                        maxHeight="250px"
                                        z={1}
                                        fd="column"
                                        width="100%"
                                        top="54px"
                                        left="0"
                                        bgc={colors.White}
                                        border={`1px solid ${colors.Gr02}`}
                                        radius="8px"
                                        of="auto"
                                    >
                                        {[...Array(20)].map((x, idx) => (
                                            <DropDown
                                                key={idx}
                                                last={idx + 1 === 20}
                                                onClick={() => {
                                                setData((prev) => ({
                                                    ...prev,
                                                    min_capacity: idx + 1,
                                                    max_capacity: idx + 1 > Number(data?.max_capacity) ? idx + 1 : Number(data?.max_capacity),
                                                }));
                                                }}
                                            >
                                                {idx + 1}인
                                            </DropDown>
                                        ))}
                                    </AbsoluteWrapper>
                                )}
                            </Wrapper>
                        </Wrapper>

                        <Wrapper width="100%">

                            <Text ws="pre" margin="0 12px 0 0" size="14px" weight="500" lh="22px" ls="-0.28px" color={colors.Gr04}>
                                최대
                            </Text>

                            <Wrapper
                                ref={maxRef}
                                padding="12px"
                                cursor="pointer"
                                position="relative"
                                onClick={(e) => {
                                e.stopPropagation();
                                setDropdown({
                                    min: false,
                                    max: !dropdown.max,
                                });
                                }}
                                height="44px"
                                radius="8px"
                                width="100%"
                                bgc={colors.White}
                                border={`1px solid ${colors.Gr02}`}
                                jc="space-between"
                            >
                                <Text size="14px" weight="500" lh="22px" ls="-0.28px" color={colors.Gr05}>
                                    {data?.max_capacity}인
                                </Text>

                                <Arrow
                                    width="16px"
                                    height="16px"
                                    fill={colors.Gr04}
                                    style={{ transition: "all ease 0.5s", transform: dropdown.max ? "rotate(-90deg)" : "rotate(90deg)" }}
                                />

                                {dropdown.max && (
                                    <AbsoluteWrapper
                                        maxHeight="250px"
                                        z={1}
                                        fd="column"
                                        width="100%"
                                        top="54px"
                                        left="0"
                                        bgc={colors.White}
                                        border={`1px solid ${colors.Gr02}`}
                                        radius="8px"
                                        of="auto"
                                    >
                                        {[...Array(21 - data?.min_capacity)].map((_, idx) => (
                                            <DropDown
                                                onClick={() => {
                                                    setData((prev) => ({ ...prev, max_capacity: Number(data?.min_capacity) + idx }));
                                                }}
                                                last={Number(data?.min_capacity) + idx === 20}
                                            >
                                                {Number(data?.min_capacity) + idx}인
                                            </DropDown>
                                        ))}
                                    </AbsoluteWrapper>
                                )}
                            </Wrapper>
                        </Wrapper>

                    </Wrapper>

                </Wrapper>
            </Wrapper>

            {/* 운영 시간 */}
            <Wrapper width="100%" ai="flex-start" fd="column" gap="8px" padding="24px 24px 8px 24px">
                <Text size="16px" weight="600" lh="24px" ls="-0.32px" color={colors.Gr05}>운영 시간</Text>

                <Wrapper gap="3px" width="100%" margin="0 0 10px 0">
                    { 
                        ScheduleDay.map((item, idx) => {
                            if(item == 'sat' || item == 'sun' || item == 'holiday') {
                                return (
                                    <YoilHoliday 
                                        onClick={() => changeValue('yoil', item)}
                                        active={data?.schedule_status[item]} 
                                        key={`yoil_${idx}`}
                                    >
                                        {ScheduleDayKorean[idx]}
                                    </YoilHoliday>
                                )
                            } else {
                                return (
                                    <Yoil 
                                        onClick={() => changeValue('yoil', item)}
                                        active={data?.schedule_status[item]} 
                                        key={`yoil_${idx}`}
                                    >
                                        {ScheduleDayKorean[idx]}
                                    </Yoil>
                                )
                            }
                        })
                    }
                </Wrapper>

                <Wrapper width="100%" ai="flex-start" fd="column" gap="5px">
                    {ScheduleDay.map((item, idx) => {
                        if(data?.schedule_status[item]) {
                            return (
                                <FacilityTime 
                                    data={data}
                                    setData={setData}
                                    yoil={item}
                                    yoilKorean={ScheduleDayKorean[idx]}
                                    key={`schedule_item_${idx}`}
                                />
                            )
                        }
                    })}
                </Wrapper>
            </Wrapper>

            <BottomWrap>
                <Wrapper
                    onClick={() => SaveFacility()}
                    jc="center"
                    ai="center"
                    width="100%"
                    height="52px"
                    cursor={SetConfirm() ? "pointer" : "default"}
                    radius="8px"
                    bgc={SetConfirm() ? colors.Bl07 : colors.Gr01}
                >
                    <Text 
                        ta="center" 
                        size="1.6rem" 
                        weight="500" 
                        lh="22px" 
                        ls="-0.28px" 
                        color={SetConfirm() ? colors.White : colors.Gr03}
                    >
                        {params?.id ? `시설 정보 수정` : `신규 시설 등록하기`}
                    </Text>
                </Wrapper>
            </BottomWrap>
            
            <CropImage open={cropModal} setOpen={setCropModal} setting={cropImageSetting} setSetting={setCropImageSetting} setImageFunc={addImage} />

            {loading && <Loading />}

            {window.document.documentElement.clientWidth < PC && <AddressPopup view={addressS} id={"PostCodeAdd"} />}
        </Container>
    );
};

export default FacilityRegister;

const Container = styled.section`
    height: calc(var(--vh, 1vh) * 100);
    overflow-x: hidden;
    width: 100%;
    padding-bottom : 150px;
`;

const ImageLabelBox = styled.label`
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-top: 20px;
    width: 100%;
    max-width: 800px;
    ${({ MD }) => (MD ? 
        `height: calc((800px - 48px) * (5/8));`
        :
        `height: calc((100vw - 48px) * (5/8));`
    )};
    border: 1px solid ${colors.Gr02};
    border-radius: 8px;
    align-self : center;
`;

const ImageInput = styled.input`
    display: none;
`;

const TipWrap = styled.div`
    display: flex;
    gap: 2px;
    font-size: 14px;
    font-weight: 500;
    line-height: 22px;
    letter-spacing: -0.28px;
    color: ${colors.Gr03};
    .tip {
        color: ${colors.Bl05};
    }
    .notice {
        color: ${colors.Red03};
    }
`;

const TextareaContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 200px;
    border: 1px solid ${({ focus }) => (focus ? colors.Bl07 : colors.Gr02)};
    border-radius: 8px;
`;

const IntroText = styled.div`
    flex: 1;
    align-self: flex-end;
    width: 100%;
    padding: 8px 12px;
    color: ${colors.Gr03};
    line-height: 20px;
    letter-spacing: -0.02em;
    font-size: 12px;
    font-weight: 500;

    span {
        color: ${colors.Gr04};
    }
`;

const TextArea = styled.textarea`
    display: inline-flex;
    width: 100%;
    height: 100%;
    padding: 16px 16px 0px 16px;
    border: none;
    border-radius: 8px;
    resize: none;
    line-height: 22px;
    letter-spacing: -0.02em;
    font-size: 14px;
    font-weight: 500;

    overflow: auto;
    outline: none;

    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;

    &::placeholder {
        color: ${colors.Gr03};
    }
`;

const DropDown = styled.div`
    width: 100%;
    height: 52px;
    padding: 12px;
    border-bottom: ${({ last }) => !last && `1px solid ${colors.Gr02}`};
    color: ${colors.Gr04};
    font-size: 13px;
    font-weight: 500;
    line-height: 142%;
    letter-spacing: -0.26px;
`;

const Yoil = styled.div`
    width: calc(((100% - 21px) / 7));
    display : inline-flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    border : ${({ active }) => `1px solid ${active ? colors.Bl07 : colors.Gr02}`};
    background-color : ${({ active }) => `${active ? colors.Bl07 : colors.Gr01}`};
    color : ${({ active }) => `${active ? colors.White : colors.Gr04}`};
    cursor : pointer;
    font-size: 1.3rem;
    font-weight: 600;
    padding : .7rem 0;
`;


const YoilHoliday = styled.div`
    width: calc(((100% - 28px) / 7));
    display : inline-flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    border : ${({ active }) => `1px solid ${active ? colors.Red03 : colors.Gr02}`};
    background-color : ${({ active }) => `${active ? colors.Red03 : colors.Gr01}`};
    color : ${({ active }) => `${active ? colors.White : colors.Gr04}`};
    cursor : pointer;
    font-size: 1.3rem;
    font-weight: 600;
    padding : .7rem 0;
`;

const BottomWrap = styled.div`
    width: 100%;
    max-width: 900px;
    transform : translate(-50%, 0);
    display: flex;
    padding: 12px 24px;
    border-top: 1px solid ${colors.Gr01};
    background-color: ${colors.White};
    position: fixed;
    bottom : 0;
    left : 50%;
    z-index: 10;
`;

const AddressPopup = styled.section`
    height: 100vh;
    height: calc(var(--vh, 1vh) * 100);
    width: calc(var(--vw, 1vw) * 100);
    overflow: hidden;
    position: fixed;
    z-index: 150;
    background-color: ${colors.B30};
    top: 0;
    right: 0;
    display: ${({ view }) => (view ? `inline-flex` : `none`)};
    align-items: center;
    justify-content: center;
`;