import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { useParams, useNavigate } from "react-router-dom";
import axios from "axios";

// components
import Header from "../components/basic/Header";
import { GetOne } from "../tool/localstorage";
import { LoadingSpinner } from "../tool/loading";
import colors from "../styles/colors";

// icon
import EmptyChatSemoring from "../icon/emptyChat.png";
import { ReactComponent as Arrow } from "../resource/icon/arrow.svg";

function ChatList() {
  const navigate = useNavigate();

  const [chatList, setChatList] = useState([]);
  const [viewCount, setViewCount] = useState();
  const [height, setHeight] = useState(0);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      var FirstComponent = document.getElementById("HeightCheck").clientHeight;
      setHeight(FirstComponent);
    }, 500);

    var token = GetOne("token");

    const frm = new FormData();

    frm.append("mode", "chat");
    frm.append("token", token);

    axios
      .post("https://ai.semos.kr/semos_partner/coaching/chat", frm, {
        headers: { "Content-Type": "multipart/form-data" },
      })
      .then((data) => {
        var Value = data.data;
        var Status = Value.status;
        var StatusMessage = Value.status_message;
        var ChatList = Value.chat_list;
        var ViewList = Value.view_count;

        if (Status == "success") {
          setChatList(ChatList);
          setViewCount(ViewList);
          setLoading(false);
        } else {
          window.alert(StatusMessage);
          setLoading(false);
        }
      });
  }, []);

  return (
    <Container>
      <Header title="1:1 문의" back={true} />

      <InnerContainer id="chatContainer">
        <BackgroundContent
          id="HeightCheck"
          style={{ borderBottom: "1px solid #F5F5F5" }}
          bgc={`#FFFFFF`}
          padding={`14px 24px`}
          jc={`space-between`}
          ai={`center`}
        >
          <TextView
            color={`#818792`}
            fw={`500`}
            fs={`14px`}
          >
            {`확인이 필요한 크루 : ${`${isNaN(viewCount) ? "0" : viewCount} 명`}`}
          </TextView>

          <div style={{
            display : 'inline-flex',
            height : '32px',
            cursor : 'pointer',
            borderRadius : '4px',
            padding : '0px 4px',
            backgroundColor : colors.Bl02,
            alignItems : 'center',
            justifyContent : 'center'
          }}
          onClick={() => navigate(`/center_faq`)}
          >
            <TextView color={`${colors.Bl07}`} fw={`600`} fs={`12px`}>자주 묻는 질문</TextView>
            <Arrow width="18px" height="18px" fill={colors.Bl07} />
          </div>
        </BackgroundContent>

        {chatList.length && chatList?.length > 0
          ? chatList.map((item, idx) => (
              <BackgroundContent
                onClick={() => navigate(`/chat/${item.number}`)}
                key={`${idx}_userChat`}
                wrap={`wrap`}
                style={{ borderBottom: "1px solid #F5F5F5" }}
                bgc={`#FFFFFF`}
                padding={`12px 24px 15px 24px`}
                jc={`flex-start`}
                ai={`center`}
              >
                <TextContent
                  br={`14`}
                  padding={`4px 10px`}
                  bgc={`#F2F7FF`}
                  width={`auto`}
                >
                  <TextView
                    style={{ maxWidth: "100%" }}
                    color={`rgba(128, 178, 255, 1)`}
                    ws={`nowrap`}
                    to={`ellipsis`}
                    fw={`500`}
                    fs={`10px`}
                    lh={`142%`}
                  >{`${item.activity_name}`}</TextView>
                </TextContent>

                <Divider height={"8"} color={"#ffffff"} />

                <BackgroundContent
                  width={`auto`}
                  jc={`space-between`}
                  ai={`flex-start`}
                >
                  <TextView
                    style={{ maxWidth: "70%" }}
                    color={`#3C3E41`}
                    ws={`nowrap`}
                    to={`ellipsis`}
                    fw={`600`}
                    fs={`16px`}
                    lh={`142%`}
                  >{`${item.user_name}`}</TextView>

                  <TextView
                    style={{ maxWidth: "100%" }}
                    color={`#ACAFB5`}
                    ws={`nowrap`}
                    to={`ellipsis`}
                    fw={`500`}
                    fs={`12px`}
                    lh={`142%`}
                  >{`${item.date}`}</TextView>
                </BackgroundContent>

                <Divider height={"12"} color={"#ffffff"} />

                <BackgroundContent
                  width={`100%`}
                  jc={`space-between`}
                  ai={`flex-end`}
                >
                  <TextView
                    style={{ maxWidth: "80%" }}
                    color={`#ACAFB5`}
                    ws={`nowrap`}
                    to={`ellipsis`}
                    fw={`500`}
                    fs={`14px`}
                    lh={`142%`}
                  >{`${item.last_chat ? item.last_chat : " "}`}</TextView>

                  {item.un_view_count != 0 && (
                    <TextContent
                      brc={`#0E6DFF`}
                      style={{ height: "30px" }}
                      br={`15`}
                      bgc={`#0E6DFF`}
                      width={`30px`}
                      jc={`center`}
                      ai={`center`}
                    >
                      <TextView
                        color={`#ffffff`}
                        ws={`nowrap`}
                        to={`ellipsis`}
                        fw={`500`}
                        fs={`10px`}
                        lh={`142%`}
                      >{`${item.un_view_count}`}</TextView>
                    </TextContent>
                  )}
                </BackgroundContent>
              </BackgroundContent>
            ))
          : height > 0 && (
              <EmptyChat
                height={height}
                key={`emptyChat`}
                bgc={`#FFFFFF`}
                jc={`center`}
                ai={`center`}
              >
                <TextContent
                  jc={`center`}
                  ai={`center`}
                  width={`229px`}
                  wrap={`wrap`}
                >
                  <ImageIcon100 width={`202px`} height={`202px`}>
                    <img src={EmptyChatSemoring} />
                  </ImageIcon100>

                  <Divider height={"24"} color={"#ffffff"} />

                  <TextView
                    ta={`center`}
                    color={`#1A1C1F`}
                    fw={`600`}
                    fs={`18px`}
                    lh={`142%`}
                  >{`문의 내역이 없어요!`}</TextView>

                  <Divider height={"4"} color={"#ffffff"} />

                  <TextView
                    ta={`center`}
                    color={`#818792`}
                    fw={`500`}
                    fs={`14px`}
                    lh={`142%`}
                  >{`문의 내역이 생기면`}</TextView>
                  <TextView
                    ta={`center`}
                    color={`#818792`}
                    fw={`500`}
                    fs={`14px`}
                    lh={`142%`}
                  >{`알림으로 알려드릴게요!`}</TextView>
                </TextContent>
              </EmptyChat>
            )}
      </InnerContainer>

      <div style={{ height: "83px" }} />

      {/* <Footer /> */}

      <LoadingSpinner
        loading={loading}
        title={`정보를 확인하고 있습니다.`}
        content={`잠시만 기다려 주세요!`}
      />
    </Container>
  );
}

export default ChatList;

const Container = styled.section`
  height: calc(var(--vh, 1vh) * 100);
  height: 100vh;
  overflow: hidden;
`;

const Divider = styled.div`
  height: ${({ height }) => height && `${height}px`};
  background-color: ${({ color }) => color && `${color}`};
  width: 100%;
`;

const InnerContainer = styled.div`
  width: 100%;
  height: calc((var(--vh, 1vh) * 100) - (51px));
  height: calc(100vh - (51px));
  overflow-y: auto;
  overflow-x: hidden;
  display: inline-block;
  position: relative;
  z-index: 40;
  padding-bottom: 80px;
`;

const BackgroundContent = styled.div`
  cursor: pointer;
  width: 100%;
  border: ${({ border }) => border && `${border}`};
  background-color: ${({ bgc }) => bgc && `${bgc}`};
  padding: ${({ padding }) => padding && `${padding}`};
  display: inline-flex;
  border-radius: ${({ br }) => br && `${br}px`};
  justify-content: ${({ jc }) => jc && `${jc}`};
  align-items: ${({ ai }) => ai && `${ai}`};
  flex-wrap: ${({ wrap }) => wrap && `${wrap}`};
  position: relative;
`;

const ImageIcon100 = styled.div`
  width: ${({ width }) => width && `${width}`};
  height: ${({ height }) => height && `${height}`};
  display: inline-block;

  img {
    width: ${({ width }) => width && `${width}`};
    display: inline-block;
  }
`;

const TextContent = styled.div`
  width: ${({ width }) => width && `${width}`};
  border: ${({ border }) => border && `${border}`};
  background-color: ${({ bgc }) => bgc && `${bgc}`};
  padding: ${({ padding }) => padding && `${padding}`};
  display: inline-flex;
  flex-wrap: ${({ wrap }) => wrap && `${wrap}`};
  border-radius: ${({ br }) => br && `${br}px`};
  justify-content: ${({ jc }) => jc && `${jc}`};
  align-items: ${({ ai }) => ai && `${ai}`};
`;

const TextView = styled.span`
  width: ${({ width }) => width && `${width}`};
  text-align: ${({ ta }) => ta && `${ta}`};
  color: ${({ color }) => color && `${color}`};
  font-weight: ${({ fw }) => fw && `${fw}`};
  font-size: ${({ fs }) => fs && `${fs}`};
  line-height: ${({ lh }) => lh && `${lh}`};
  display: inline-block;
  text-overflow: ${({ to }) => to && `${to}`};
  overflow: hidden;
  white-space: ${({ ws }) => ws && `${ws}`};
`;

const EmptyChat = styled.div`
  width: 100%;
  height: ${({ height }) => height && `calc(100% - ${height + 83}px);`};
  border: ${({ border }) => border && `${border}`};
  background-color: ${({ bgc }) => bgc && `${bgc}`};
  padding: ${({ padding }) => padding && `${padding}`};
  display: inline-flex;
  border-radius: ${({ br }) => br && `${br}px`};
  justify-content: ${({ jc }) => jc && `${jc}`};
  align-items: ${({ ai }) => ai && `${ai}`};
  flex-wrap: ${({ wrap }) => wrap && `${wrap}`};
  position: relative;
`;
