import React, { useState, useEffect } from "react";
import styled from "styled-components";
import axios from "axios";
import Sheet from "react-modal-sheet";

//style
import { Wrapper, Text, Input } from "../../components/layouts/layoutStorage";
import colors from "../../styles/colors";
import { W } from "../../styles/globalStyles";

//icon
import { ReactComponent as ModalCloseIcon } from "../../resource/icon/close_circle.svg";
import { GetOne } from "../../tool/localstorage";

const ScheduleListModal = ({ open, setOpen, setData, data, newSchedule, setNewSchedule, setChange }) => {
  const [scheduleList, setScheduleList] = useState([]);

  const getData = () => {
    // setLoading(true);
    var token = GetOne("token");
    const frm = new FormData();
    frm.append("mode", "list");
    frm.append("token", token);

    axios
      .post("https://ai.semos.kr/semos_partner/v2/activity/ActivitySchedule", frm, {
        headers: { "Content-Type": "multipart/form-data" },
      })
      .then((data) => {
        var Value = data.data;
        var Status = Value.status;

        if (Status == "success") {
          setScheduleList(Value.partner_schedule);
          // setLoading(false);
        } else {
        }
      });
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <Modal
      style={{
        width: "100%",
        display: "flex",
        justifyContent: "center",
        left: "50%",
        transform: "translate(-50%,0)",
      }}
      isOpen={open}
      snapPoints={[0.63]}
    >
      <Modal.Container style={{ width: `${W}px`, left: "none" }}>
        <div>
          <ModalFirstLine>
            <ModalTitleContainer>
              <ModalTitle>등록된 일정 불러오기</ModalTitle>
            </ModalTitleContainer>
            <ModalCloseIcon onClick={() => setOpen(false)} width="28px" height="28px" fill={colors.Gr03} style={{ cursor: "pointer" }} />
          </ModalFirstLine>
        </div>
        <Wrapper fd="column" width="100%" height="100%" of="auto" padding="0 0 90px 0">
          {scheduleList.map((item, index) => (
            <Wrapper
              cursor="pointer"
              onClick={() => {
                let scheduleDataCopy = [...data.activity_location];

                //장소 && 요일 동일한 index 확인
                let sameScheduleIndex = scheduleDataCopy.findIndex(
                  (el, idx) =>
                    el.address.address === item.address.address &&
                    el.address.address_extra === item.address.address_extra &&
                    JSON.stringify(el.days) === JSON.stringify(item.days)
                );
                if (sameScheduleIndex !== -1) {
                  let excludeTimeArray = item.start.filter((it) => !scheduleDataCopy[sameScheduleIndex].start.includes(it));
                  if (excludeTimeArray.length === 0) {
                    return window.alert("중복된 일정이 있습니다.");
                  }
                  scheduleDataCopy[sameScheduleIndex].start = [...scheduleDataCopy[sameScheduleIndex].start, ...excludeTimeArray];
                } else {
                  scheduleDataCopy.push({
                    id: "new",
                    days: [...item.days],
                    start: [...item.start],
                    address: { ...item.address },
                  });
                }
                setNewSchedule({
                  address: item.address.address,
                  address_extra: item.address.address_extra,
                  boxColor: true,
                  toast: true,
                });
                setTimeout(() => {
                  setNewSchedule((prev) => ({ ...prev, toast: false }));
                }, 3000);
                setData((prev) => ({ ...prev, activity_location: scheduleDataCopy }));
                setOpen(false);
                setChange(true);
              }}
              key={`${item.id}_${index}`}
              ai="flex-start"
              fd="column"
              width="100%"
              padding="12px 24px"
              style={{ borderBottom: `1px solid ${colors.Gr01}` }}
            >
              <Text size="16px" weight="600" lh="24px" ls="-0.32px" color={colors.Gr06}>
                {item.address.address} {item.address.address_extra && item.address.address_extra}
              </Text>
              <Text size="14px" weight="500" lh="22px" ls="-0.28px" color={colors.Gr03}>
                {item.days.map((el, idx) => (item.days.length === idx + 1 ? el : `${el}, `))}
              </Text>
              <Text size="14px" weight="500" lh="22px" ls="-0.28px" color={colors.Gr03}>
                {item.start.map((el, idx) => (item.start.length === idx + 1 ? el : `${el}, `))}
              </Text>
            </Wrapper>
          ))}
        </Wrapper>
      </Modal.Container>
      <Modal.Backdrop />
    </Modal>
  );
};

export default ScheduleListModal;

const Modal = styled(Sheet)``;

const ModalFirstLine = styled.div`
  display: flex;
  padding: 24px 24px 12px 24px;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid ${colors.Gr01};
`;

const ModalTitleContainer = styled.div`
  display: flex;
  /* align-items: flex-end; */
  align-items: center;
`;

const ModalTitle = styled.div`
  font-weight: 600;
  font-size: 18px;
  line-height: 142%;
  letter-spacing: -0.02em;
  color: #1a1c1f;
  margin-right: 12px;
`;
