import React, { useState, useEffect } from "react";
import styled from "styled-components";
import axios from "axios";

// component
import Header from "../components/basic/Header";
import FaqItem from "../components/faq/faq_item";
import ClientCenterIcon from "../icon/clientCenterIcon.png";
import { LoadingSpinner } from "../tool/loading";
import { kakaoChannel } from "../tool/kakao";
import { fbevent } from "../tool/firebase";
import { GetOne } from "../tool/localstorage";

// style
import { PC } from "../styles/globalStyles";

function FaqList() {
  const [index, setIndex] = useState(0);
  const menuArray = ["전체", "이용 안내", "입점 안내", "결제 및 환불"];
  const menuArrayWithout = ["이용 안내", "입점 안내", "결제 및 환불"];
  const [loading, setLoading] = useState(true);

  const [list, setList] = useState([]);

  useEffect(() => {
    fbevent("B2B_FAQ_Page_View", {
      email : GetOne("email")
    });

    var token = localStorage.getItem("token");

    const frm = new FormData();

    frm.append("token", token);
    frm.append("mode", "faq_list");

    axios
      .post("https://ai.semos.kr/semos_partner/notice/data", frm, {
        headers: {
          "Content-Type": "multipart/form-data",
          Accept: "application/json",
        },
      })
      .then((data) => {
        var result = data.data;

        if (result.status == "status") {
          setList(result.faq_list);
          setLoading(false);
        } else {
          window.alert("잘못된 접근입니다.");
          setLoading(false);
        }
      });
  }, []);

  useEffect(() => {
    fbevent("B2B_FAQ_Menu_Click", {
      category : menuArray[index]
    });
  }, [index]);

  

  return (
    <Container>
      <Header title="자주 묻는 질문" back={true} />
      <MenuBar>
        <MenuTab selected={index === 0} onClick={() => setIndex(0)}>
          전체
        </MenuTab>
        <MenuTab selected={index === 1} onClick={() => setIndex(1)}>
          이용 안내
        </MenuTab>
        <MenuTab selected={index === 2} onClick={() => setIndex(2)}>
          입점 안내
        </MenuTab>
        <MenuTab selected={index === 3} onClick={() => setIndex(3)}>
          결제 및 환불
        </MenuTab>
      </MenuBar>
      {index ? (
        <>
          <Styler pt={24} pl={24} pb={12}>
            <MenuTitle>{menuArray[index]}</MenuTitle>
          </Styler>
          {list[menuArray[index]] &&
            list[menuArray[index]].map((x, idx) => (
              <FaqItem item={x} key={`${idx.toString()} ${menuArray[index]}`} />
            ))}
        </>
      ) : (
        menuArrayWithout.map((el, i) => (
          <div key={`${el} ${i.toString()}`}>
            <Styler pt={24} pl={24} pb={12}>
              <MenuTitle>{el}</MenuTitle>
            </Styler>
            {list[el] &&
              list[el].map((x, idx) => (
                <FaqItem item={x} key={`${idx.toString()} ${el}`} />
              ))}
          </div>
        ))
      )}

      <Divider h={44} />

      <ClientCenterBox
        onClick={() => {
          if (window.document.documentElement.clientWidth > PC) {
            window.open("http://pf.kakao.com/_CBUHxj", "_blank");
          } else {
            kakaoChannel();
          }
        }}
      >
        <ClientIcon src={ClientCenterIcon} />
        고객센터 연결
      </ClientCenterBox>

      {/* <div style={{ height: "83px" }} /> */}

      {/* <Footer /> */}

      <LoadingSpinner
        loading={loading}
        title={`정보를 확인하고 있습니다.`}
        content={`잠시만 기다려 주세요!`}
      />
    </Container>
  );
}

export default FaqList;

const Container = styled.section`
  height: calc(var(--vh, 1vh) * 100);
  overflow-x: hidden;
`;

const Styler = styled.div`
  position: relative;
  display: ${({ flex }) => (flex ? "flex" : "")};
  flex-direction: ${({ direction }) => direction};
  justify-content: ${({ justify }) => justify};
  align-items: ${({ align }) => align};
  padding-top: ${({ pt }) => pt}px;
  padding-bottom: ${({ pb }) => pb}px;
  padding-left: ${({ pl }) => pl}px;
  padding-right: ${({ pr }) => pr}px;
`;

const MenuBar = styled.div`
  padding: 22px 24px;
  padding-bottom: 0px;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: space-between;
  position: sticky;
  top: 40px;
  background-color: #ffffff;
  z-index: 10;
`;

const MenuTab = styled.div`
  cursor: pointer;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  color: ${({ selected }) => (selected ? "#0e6dff" : "#818792")};
  padding: 0 10px 18px 10px;
  border-bottom: 2px solid;
  border-color: ${({ selected }) => (selected ? "#0e6dff" : "transparent")};
`;

const MenuTitle = styled.div`
  font-weight: 500;
  font-size: 14px;
  line-height: 142%;
  display: flex;
  align-items: center;
  letter-spacing: -0.02em;
  color: #818792;
`;

const ClientCenterBox = styled.div`
  cursor: pointer;
  padding: 8px 16px;
  font-weight: 600;
  font-size: 14px;
  line-height: 142%;
  display: flex;
  align-items: center;
  color: #0e6dff;
  float: right;
  background-color: #f2f7ff;
  border-radius: 27px;
  position: absolute;
  bottom: 24px;
  right: 24px;
`;

const ClientIcon = styled.img`
  width: 20px;
  height: 20px;
  margin-right: 4px;
`;

const Divider = styled.div`
  height: ${({ h }) => h}px;
`;
