import React, { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import Modal from "react-modal";

//component 
import SemosPhoneAuth from "./SemosPhoneAuth";

// style
import colors from "../../styles/colors";
import { Wrapper, Input, Text } from "../../components/layouts/layoutStorage";

// img

const SemosLoginModal = ({
  modal,
  setModal,
}) => {
  const navigate = useNavigate();

  const [status, setStatus] = useState({
    phone: "default",
    auth: "default",
  });

  const [userInfo, setUserInfo] = useState({
    phone: "",
    auth: "",
  });


  return (
    <Modal
      isOpen={modal}
      onRequestClose={() => setModal(false)}
      contentLabel="Alert Modal"
      style={customStyles}
    >
      <Container>

        <AffiliateModalLayout>
          <Wrapper
            width={`100%`}
            margin={`0 0 10px 0`}
            jc={`center`}
          >   
            <Text 
              width={`100%`} 
              size="2rem" 
              weight="600" 
              lh="24px" 
              ls="-0.32px" 
              color={colors.Gr05}
            >
              스포츠 시설 파트너 전용 로그인
            </Text>
          </Wrapper>      

          <SemosPhoneAuth
            userInfo={userInfo}
            setUserInfo={setUserInfo}
            status={status}
            setStatus={setStatus}
          />
        </AffiliateModalLayout>
      </Container>
    </Modal>
  );
};

export default SemosLoginModal;

const customStyles = {
    content: {
        width: "calc(100% - 24px)",
        maxWidth : '600px',
        marginRight: "-50%",
        padding: 0,
        borderRadius: "8px",
        top: "50%",
        left: "50%",
        right: "auto",
        bottom: "auto",
        transform: "translate(-50%, -50%)",
    },
};

const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
`;

const AffiliateModalLayout = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 16px;
    width: 100%;
    max-height: 600px;
    max-width: 600px;
    height: 100%;
    padding: 12px;
    border-radius: 0;
`;
