import React, { useState } from "react";
import styled from "styled-components";
import Modal from "react-modal";
import { useParams, useNavigate } from "react-router-dom";

//img
import AlertCaution from "../../icon/blueCaution.png";
import Caution from "../../icon/caution.svg";
import { W } from "../../styles/globalStyles";
import colors from "../../styles/colors";

/**
 * modal
 * status : modal open boolean
 * type : 'revise' | 'temporal' | 'save'
 * 'revise' : 수정 페이지 이동
 * 'temporal' : 임시 저장
 * 'save' : 저장하기
 * link : navigate url
 */

const BasicModal = ({ modal, setModal, save, setPageType }) => {

  const navigate = useNavigate();

  const GoLink = () => {
    if (modal.link) {
      if (modal.type === "revise") {
        //window.location.replace(modal.link);
        setModal({ ...modal, status: false, function : "", pageType : "" })
      } else if (modal.type === "temporal") {
        window.location.replace(modal.link);
      } else if (modal.type === "new") {
        window.location.replace(modal.link);
      } else if (modal.type === "confirm") {
        window.location.replace(modal.link);
      } else if (modal.type === "just_save") {
        window.location.reload();
      } else if (modal.type === "just_save2") {
        window.location.replace(modal.link);
      } 
    }
  };

  return (
    <Modal
      isOpen={modal.status}
      onRequestClose={() =>
        setModal({
          status: false,
          type: "",
          link: "",
        })
      }
      contentLabel="Alert Modal"
      style={customStyles}
    >
      {modal.type === "revise" ? (
        <Container>
          <Icon src={AlertCaution} />
          <Space h={32} />

          <Title>정보 저장이 완료되었어요</Title>
          <Space h={8} />
          
          <SmallTitle>
            <span>이미 이 액티비티를 예약한 유저가 있다면 액티비티 변경사항을 알려주세요</span>
          </SmallTitle>
          <Space h={48} />

          <Button c={colors.White} bg={colors.Bl07} onClick={() => GoLink()}>
            {"확인"}
          </Button>
        </Container>
      ) : (
        <></>
      )}

      {modal.type === "confirm" ? (
        <Container>
          <Icon src={AlertCaution} />
          <Space h={32} />

          <Title>승인 신청이 완료되었어요</Title>
          <Space h={8} />

          <SmallTitle>
            <span>세모스팀 심사 후 변경한 정보가 반영되요</span>
            <span>추가 수정은 심사 후 가능해요</span>
            <span className="blue-text">심사 결과는 카카오톡 채널로 안내드릴게요!</span>
          </SmallTitle>
          
          <Space h={48} />

          <Button c={colors.White} bg={colors.Bl07} onClick={() => GoLink()}>
            {"확인"}
          </Button>
        </Container>
      ) : (
        <></>
      )}

      {modal.type === "just_save" ? (
        <Container>
          <Icon src={AlertCaution} />
          <Space h={32} />

          <Title>정보 저장이 완료되었어요</Title>
          <Space h={8} />

          <SmallTitle>
            <span>
              <span className="blue-text">'승인 신청하기'</span>
              를 눌러야 세모스팀 심사 후
            </span>
            <span>
              액티비티 수정내역이 반영됩니다.
            </span>
          </SmallTitle>
          
          <Space h={48} />

          <Button c={colors.White} bg={colors.Bl07} onClick={() => GoLink()}>
            {"확인"}
          </Button>
        </Container>
      ) : (
        <></>
      )}

      {modal.type === "just_save2" ? (
        <Container>
          <Icon src={AlertCaution} />
          <Space h={32} />

          <Title>정보 저장이 완료되었어요</Title>
          <Space h={8} />

          <SmallTitle>
            <span>이미 이 액티비티를 예약한 유저가 있다면 액티비티 변경사항을 알려주세요</span>
          </SmallTitle>
          
          <Space h={48} />

          <Button c={colors.White} bg={colors.Bl07} onClick={() => setModal({ ...modal, status: false, function : "", pageType : "" })} >
            {"확인"}
          </Button>
        </Container>
      ) : (
        <></>
      )}

      {modal.type === "just_save3" ? (
        <Container>
          <Icon src={AlertCaution} />
          <Space h={32} />

          <Title>정보 저장이 완료되었어요</Title>
          <Space h={8} />
          
          <SmallTitle>
            <span>
              <span className="blue-text">'승인 신청하기'</span>
              를 눌러야 세모스팀 심사 후
            </span>
            <span>
              액티비티 수정내역이 반영됩니다.
            </span>
          </SmallTitle>

          <Space h={48} />

          <Button c={colors.White} bg={colors.Bl07} onClick={() => setModal({ ...modal, status: false, function : "", pageType : "" })} >
            {"확인"}
          </Button>
        </Container>
      ) : (
        <></>
      )}

      {modal.type === "new" ? (
        <Container>
          <Icon src={Caution} />
          <Space h={32} />

          <Title>신규 액티비티 등록이 완료되었어요</Title>
          <Space h={8} />

          <SmallTitle>
            <span>신규로 등록해주신 액티비티는</span>
            <span>세모스팀이 심사 후 카카오톡 채널로 결과를 안내드릴게요!</span>
          </SmallTitle>

          <Space h={48} />

          <Button c={colors.White} bg={colors.Bl07} onClick={() => GoLink()}>
            {"확인"}
          </Button>
        </Container>
      ) : (
        <></>
      )}

      {modal.type === "save" ? (
        <Container>
          <Icon src={AlertCaution} />
          <Space h={32} />
          <Title>승인 심사</Title>
          <Space h={8} />
          <SmallTitle>
            <span>
              ‘신청하기’를 누르시면&nbsp;
              <span className="blue-text">액티비티 승인 심사가 진행되어</span>&nbsp;
              액티비티 수정이 불가합니다
            </span>

            <span>
              <span className="blue-text">‘일정 정보’, ‘추가 정보’는</span>&nbsp;
              승인 심사 없이 수정이 가능합니다.
            </span>
          </SmallTitle>
          <Space h={48} />

          <ButtonContainer>
            <Button c={colors.White} bg={colors.Red03} onClick={() => setModal({ ...modal, status: false })}>
              취소
            </Button>
            <Button
              c={colors.White}
              bg={colors.Bl07}
              onClick={() => {
                save()
              }}
            >
              신청하기
            </Button>
          </ButtonContainer>
        </Container>
      ) : (
        <></>
      )}

      {modal.type === "navigation" ? (
        <Container>
          <Icon src={AlertCaution} />
          <Space h={32} />
          <Title>페이지 변경 안내</Title>
          <Space h={8} />
          <SmallTitle>
            <span>
              수정한 정보가 있습니다.
            </span>

            <span className="blue-text">정보 저장 후 이동하시겠습니까?</span>

            <span>
              바로 이동 시 수정한 정보는 저장되지 않습니다.
            </span>
          </SmallTitle>
          <Space h={48} />

          <ButtonContainer>
            <Button c={colors.White} bg={colors.Red03} 
              onClick={() => {
                const URL = modal.link;
                const PAGETYPE = modal.pageType;
                const RESET = modal.reset;
                setModal({ ...modal, status: false, function : "", pageType : "" });
                setPageType(PAGETYPE);
                RESET();
                navigate(URL);
              }}
            >
              바로 이동하기
            </Button>
            <Button
              c={colors.White}
              bg={colors.Bl07}
              onClick={() => {
                const SAVE = modal.function;
                const URL = modal.link;
                const PAGETYPE = modal.pageType;
                setModal({ ...modal, status: false, function : "", pageType : "" });
                setPageType(PAGETYPE);
                SAVE(URL);
              }}
            >
              저장 후 이동하기
            </Button>
          </ButtonContainer>
        </Container>
      ) : (
        <></>
      )}  
    </Modal>
  );
};

export default BasicModal;

const customStyles = {
  content: {
    width: `${W - 32}px`,
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    borderRadius: "16px",
    padding: "12px 12px 12px 12px",
    // zIndex: 20,
  },
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  /* justify-content: space-between; */
  align-items: center;
`;

const Icon = styled.img`
  width: 76px;
  height: 76px;
`;

const Title = styled.div`
  font-weight: 700;
  font-size: 20px;
  line-height: 142%;
  text-align: center;
  letter-spacing: -0.02em;
  color: ${colors.Gr05};
`;

const SmallTitle = styled.div`
  display: inline-flex;
  flex-wrap: wrap;
  text-align: center;
  line-height: 142%;
  letter-spacing: -0.02em;
  font-size: 15px;
  font-weight: 700;

  span {
    width: 100%;
    color: ${colors.Gr03};
  }

  .blue-text {
    color: ${colors.Bl05};
  }
`;

const Space = styled.div`
  height: ${({ h }) => h}px;
`;

const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 12px;
  width: 100%;
`;

const Button = styled.div`
  cursor: pointer;
  display: flex;
  justify-content: center;
  width: ${({ width }) => (width ? width : "100%")};
  padding: 14px 0;
  background-color: ${({ bg }) => bg};
  border-radius: 8px;
  color: ${({ c }) => c};
  line-height: 142%;
  font-size: 16px;
  font-weight: 700;
`;
