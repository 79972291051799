import styled from "styled-components";
import { useState, useEffect } from "react";
import Cropper from "cropperjs";
import imageCompression from "browser-image-compression";
import "cropperjs/dist/cropper.css";

// components
import { W } from "../styles/globalStyles";

export const setImageFromFile = async ({ file, setImageUrl }) => {

  const Options = {
    maxSizeMB: 0.3,
    maxWidthOrHeight: 1920,
    useWebWorker: true,
  };

  try {
    // 압축 결과
    const CompressedFile = await imageCompression(file, Options);

    let reader = new FileReader();
    reader.readAsDataURL(CompressedFile);
    reader.onloadend = () => {
      setImageUrl({ result: reader.result });

      console.log(file);
      console.log(reader.result);
    };
  } catch (error) {
    console.log(error);
  }
};

export const CropImage = ({
  open,
  setOpen,
  setting,
  setSetting,
  setImageFunc,
}) => {
  let cropper = "";
  // console.log(setting);

  useEffect(() => {
    if (cropper) {
      cropper.reset();
      cropper.destroy();
    }

    if (setting.image) {
      const Img = document.getElementById(`CropContent-${setting.type}`);
      if (setting.width && setting.height) {
        cropper = new Cropper(Img, {
          aspectRatio: setting.width / setting.height,
        });
      } else {
        cropper = new Cropper(Img);
      }
    }
  }, [setting.image, setting.type]);

  const inputClear = (type) => {
    let input = document.getElementById(type);
    input.value = null;
  };

  const setStatus = () => {
    setOpen(!open);
    if (cropper) {
      cropper.reset();
      cropper.destroy();
    }
    inputClear(setting.type);
    setSetting({
      image: "",
      type: "",
      width: 0,
      height: 0,
    });
  };

  const cropImage = () => {
    //if (window.confirm("이미지를 추가하시겠습니까?")) {
      cropper.getCroppedCanvas().toBlob((blob) => {
        let reader = new FileReader();
        reader.readAsDataURL(blob);
        reader.onloadend = () => {
          let base64data = reader.result;

          setImageFunc(base64data);
          setStatus();

          let InputFile = document.getElementById(setting.type);
          InputFile.value = "";
        };
      });
    //}
  };

  return (
    <CropContainer aria-hidden="true" status={open}>
      <CropBackground onClick={() => setStatus()} />

      <CropContentView>
        <CropContent>
          <img
            id={`CropContent-${setting.type}`}
            src={setting && setting.image}
          />
        </CropContent>
      </CropContentView>

      <CropGoView>
        <CropCancel onClick={() => setStatus()}>취소</CropCancel>
        <CropGo onClick={() => cropImage()}>이미지 추가</CropGo>
      </CropGoView>
    </CropContainer>
  );
};

const CropContainer = styled.div`
  width: 100%;
  height: calc(var(--vh, 1vh) * 100);
  height: 100vh;
  overflow-x: hidden;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 600;
  display: ${({ status }) => (status ? "inline-flex" : "none")};
  pointer-events: ${({ status }) => (status ? "unset" : "none")};
  align-items: center;
  justify-content: center;
  padding: 0 24px;
`;

const CropBackground = styled.div`
  width: 100%;
  height: calc(var(--vh, 1vh) * 100);
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  display: inline-block;
  overflow: hidden;
  z-index: 601;
  background-color: rgba(0, 0, 0, 0.2);
  backdrop-filter: blur(2px);
`;

const CropContentView = styled.div`
  padding: 0 24px 10px 24px;
  width: ${W}px;
  /* min-height: calc(var(--vh, 1vh) * 75); */
  min-height: 75vh;
  position: fixed;
  transform: translate(-50%, -50%);
  top: calc(50% - 76px);
  left: 50%;
  display: inline-block;
  overflow: hidden;
  z-index: 602;
`;

const CropContent = styled.div`
  width: 100%;
  height: calc(var(--vh, 1vh) * 75);
  height: 75vh;
  display: inline-block;
  overflow: hidden;
  z-index: 603;

  img {
    display: block;
    max-width: calc(100% - 48px);
  }
`;

const CropGoView = styled.div`
  cursor: pointer;
  padding: 10px 24px;
  width: ${W}px;
  position: fixed;
  bottom: 0;
  left: 0;
  display: inline-flex;
  overflow: hidden;
  z-index: 602;
  left: 50%;
  transform: translate(-50%, 0);
  gap : 10px;
`;

const CropGo = styled.div`
  width: 50%;
  height: 56px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  background-color: #0e6dff;
  cursor: pointer;
  z-index: 603;
  font-weight: 600;
  font-size: 16px;
  line-height: 142%;
  color: #ffffff;
`;

const CropCancel = styled.div`
  width: 50%;
  height: 56px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  background-color: #FF6052;
  cursor: pointer;
  z-index: 603;
  font-weight: 600;
  font-size: 16px;
  line-height: 142%;
  color: #ffffff;
`;