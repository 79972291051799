import React, { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

// style
import colors from "../../styles/colors";

// component
import { Wrapper, Text } from "../../components/layouts/layoutStorage";
import { PC } from "../../styles/globalStyles";
import { kakaoChannel } from "../../tool/kakao";

const Type = ['베이직 플랜', '프로 플랜'];
const PlanText = {
    "베이직 플랜" : {
        'price' : "• 월간 결제 16,500원/월\n• 연간 결제 178,200원(10% OFF)/연",
        'service' : "• 예약관리\n1. 전용 홈페이지 제작\n2. 다양한 온라인 결제수단(카드/간편결제, 현장결제)\n3. 상담 예약 신청 받기\n4. 예약 단계별 고객 알림톡/푸시 자동 발송\n\n• 판매관리\n1. 레슨, 이용권, 자격증, 국내/해외 투어 카테고리별 상품 등록\n2. 예약 받을 일정 설정\n3. 휴무 설정\n\n회원관리(Coming Soon)\n\n소속강사관리(Coming Soon)"
    },

    "프로 플랜" : {
        'price' : "• 월간 결제 231,000원/월\n• 연간 결제 2,494,800원(10% OFF)/연",
        'service' : "• 예약관리\n1. 전용 홈페이지 제작\n2. 다양한 온라인 결제수단(카드/간편결제, 현장결제)\n3. 상담 예약 신청 받기\n4. 예약 단계별 고객 알림톡/푸시 자동 발송\n\n• 판매관리\n1. 레슨, 이용권, 자격증, 국내/해외 투어 카테고리별 상품 등록\n2. 예약 받을 일정 설정\n3. 휴무 설정\n\n• 시설관리\n1. 출입관리\n2. 실시간 이용현황 대시보드\n3. 회원권 판매/등록\n\n회원관리(Coming Soon)\n\n소속강사관리(Coming Soon)\n\n• 안내 : 프로 플랜 구독은 문의가 필요합니다."
    },
}


const PartnerPlan = ({setPage, page, setData, data}) => {

    const navigate = useNavigate();

    const ChangePlan = (plan) => {

        if(plan != data?.plan) {
            setData((prev) => ({ ...prev, 'plan' : plan }));
        }

    }

    const NextPage = () => {
        if(data?.plan == '베이직 플랜') {
            navigate("/partner_register_new/1", {replace: true});
            setPage(1);
        } else {
            if (window.document.documentElement.clientWidth > PC) {
                window.open("http://pf.kakao.com/_CBUHxj", "_blank");
            } else {
                kakaoChannel();
            }
        }
    }

    return (

        <Content>
            <Wrapper
                width="100%"
                margin="0 0 10px 0"
            >
                {Type.map((val, idx) => (
                    <ControlButton onClick={() => ChangePlan(val)} active={(data?.plan == val) ? "active" : "disabled"} key={`${idx}_plan`}>
                        <ControlText active={(data?.plan == val) ? "active" : "disabled"}>{val}</ControlText>
                    </ControlButton>
                ))}
            </Wrapper>

            <Wrapper
                width="100%"
                border={`1px solid ${colors.Gr03}`}
                bgc={colors.Gr01}
                padding={`15px 10px`}
                radius={"8px"}
                wrap={`wrap`}
            >
                <Text
                    size="1.8rem"
                    weight="600"
                    width={`100%`}
                    style={{
                        whiteSpace : "pre-wrap",
                        marginBottom : '20px'
                    }}
                    color={colors.Bl07}
                >
                    {`[세모스 파트너 구독 안내]`}
                </Text>

                <Text
                    size="1.6rem"
                    weight="600"
                    width={`100%`}
                    style={{
                        whiteSpace : "pre-wrap",
                        marginBottom : '8px'
                    }}
                >
                    가격 💰
                </Text>

                <Text
                    size="1.4rem"
                    weight="500"
                    padding="0 0 0 1rem"
                    width={`100%`}
                    style={{
                        whiteSpace : "pre-wrap",
                        marginBottom : '12px'
                    }}
                >
                    {PlanText[data?.plan]['price']}
                </Text>

                <Text
                    size="1.6rem"
                    weight="600"
                    width={`100%`}
                    style={{
                        whiteSpace : "pre-wrap",
                        marginBottom : '8px'
                    }}
                >
                    포함 기능 ✅
                </Text>

                <Text
                    size="1.4rem"
                    weight="500"
                    padding="0 0 0 1rem"
                    width={`100%`}
                    style={{
                        whiteSpace : "pre-wrap"
                    }}
                >
                    {PlanText[data?.plan]['service']}
                </Text>
            </Wrapper>

            <Wrapper
                width="100%"
                bgc={colors.White}
                padding={`10px 24px`}
                style={{
                    position : "fixed",
                    bottom : 0,
                    left : '50%',
                    transform : "translate(-50%, 0%)",
                    zIndex : 10,
                    borderTop : `1px solid ${colors.Gr01}`,
                    maxWidth : '800px'
                }}
            >
                <Wrapper
                    width="100%"
                    bgc={colors.Bl07}
                    radius={`8px`}
                    height={`52px`}
                    cursor={`pointer`}
                    ai={`center`}
                    jc={`center`}
                    onClick={() => NextPage()}
                >
                    <Text
                        size="1.6rem"
                        weight="600"
                        color={colors.White}
                    >
                        {(data?.plan == '베이직 플랜') ? "베이직 플랜 선택하기" : "프로 플랜 구독 문의하기"}
                    </Text>
                </Wrapper>
            </Wrapper> 

        </Content>

    )
}

export default PartnerPlan;

const Content = styled.div`
    padding: 24px 24px 70px 24px;
    background-color: ${colors.White};
    width : 100%;
    max-width : 800px;
    margin : 0 auto;
`;

const ControlButton = styled.div`
    width: ${({ width }) => `${width}`};
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    padding: 8px 12px;
    background-color: ${({ active }) => (active === "active" ? colors.Bl02 : colors.White)};
    border: 1px solid ${({ active }) => (active === "active" ? colors.Bl07 : colors.Bl04)};
    margin : 0 8px 0 0;
`;

const ControlText = styled.div`
    white-space: pre-wrap;
    text-align: center;
    color: ${({ active }) => (active === "active" ? colors.Bl07 : colors.Bl04)};
    font-size: 13px;
    font-weight: 600;
`;
