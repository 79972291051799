import React, { useState } from "react";
import styled from "styled-components";
import Modal from "react-modal";
import axios from "axios";

//img
import { W } from "../../styles/globalStyles";
import colors from "../../styles/colors";

// tool
import { GetOne } from "../../tool/localstorage";

const TextList = [
    `액티비티 신규 등록 시 기존 내용 복사하기`,
    `액티비티에 옵션과 옵션별 추가금액 설정하기`
];

const BasicModal = ({ modal, setModal }) => {

    const [select, setSelect] = useState('');

    const PopupFunction = async () => {
        const token = GetOne("token");

            if(select) {
                const frm = new FormData();
                frm.append('token', token);
                frm.append('mode', 'semos_review');
                frm.append('title', '세모스 파트너에 보완/추가되었으면 하는 기능은 무엇인가요?');
                frm.append('review', select);
    
                axios
                    .post('https://ai.semos.kr/semos_partner/v3/profile/MyInfo', frm, {
                    headers: {'Content-Type': 'multipart/form-data'},
                    })
                    .then(data => {
                        const Value = data.data;
        
                    if (Value.status === 'success') {
                        window.alert("개선에 도움을 주셔서 감사합니다!")
                        setModal(false)
                    } else {
                        window.alert("개선에 도움을 주셔서 감사합니다!")
                        setModal(false)
                    }
                    })
                    .catch(err => console.log('PROFILE ERROR', err));
            } else {
                window.alert('의견을 선택해주세요!')
            } 
    };

    return (
        <Modal
            isOpen={modal}
            onRequestClose={() => setModal(false)}
            contentLabel="Alert Modal"
            style={customStyles}
        >

            <Container>
                <Title>{`세모스 파트너에 보완/추가되었으면\n하는 기능은 무엇인가요?`}</Title>
                <Space h={24} />

                {
                    TextList.map((el, index) => (
                        <Select onClick={() => setSelect(el)} key={`select_${index}`}>
                            <ActiveRound >
                                <ActiveRoundInner bg={select == el} />
                            </ActiveRound>
            
                            <SelectText>{el}</SelectText>
                        </Select>
                    ))
                }

                <Space h={24} />

                <ButtonContainer>
                    <Button c={colors.Bl07} bg={colors.Bl02} onClick={() => setModal(false)}>
                    취소
                    </Button>
                    <Button
                        c={colors.White}
                        bg={colors.Bl07}
                        onClick={() => {
                            PopupFunction();
                        }}
                    >
                    전송하기
                    </Button>
                </ButtonContainer>
            </Container>
        </Modal>
    );
};

export default BasicModal;

const customStyles = {
  content: {
    width: `${W - 48}px`,
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    borderRadius: "16px",
    padding: "24px",
    // zIndex: 20,
  },
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  /* justify-content: space-between; */
  align-items: center;
`;

const Icon = styled.img`
  width: 76px;
  height: 76px;
`;

const Title = styled.div`
  font-weight: 700;
  font-size: 18px;
  line-height: 142%;
  text-align: center;
  letter-spacing: -0.02em;
  color: ${colors.Gr05};
`;

const SmallTitle = styled.div`
  display: inline-flex;
  flex-wrap: wrap;
  text-align: center;
  line-height: 142%;
  letter-spacing: -0.02em;
  font-size: 12px;
  font-weight: 700;

  span {
    width: 100%;
    color: ${colors.Gr03};
  }

  .blue-text {
    color: ${colors.Bl05};
  }
`;

const Space = styled.div`
  height: ${({ h }) => h}px;
`;

const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 12px;
  width: 100%;
`;

const Button = styled.div`
  cursor: pointer;
  display: flex;
  justify-content: center;
  width: ${({ width }) => (width ? width : "100%")};
  padding: 14px 0;
  background-color: ${({ bg }) => bg};
  border-radius: 8px;
  color: ${({ c }) => c};
  line-height: 142%;
  font-size: 14px;
  font-weight: 700;
`;

const Select = styled.div`
    width: 100%;
    background-color : ${colors.White};
    align-items : center;
    justify-content : space-between;
    margin-bottom : 15px;
    display : inline-flex;
`;

const ActiveRound = styled.div`
    width : 18px;
    height : 18px;
    border-radius : 9px;
    border : 1px solid ${colors.Gr03};
    align-items : center;
    justify-content : center;
    display : inline-flex;
`;

const ActiveRoundInner = styled.div`
    width : 12px;
    height : 12px;
    border-radius : 6px;
    background-color : ${({ bg }) => (bg ? colors.Bl07 : colors.Gr03)};
    display : inline-flex;
`;

const SelectText = styled.span`
    width : calc(100% - 30px);
    font-size: 14px;
    font-weight: 600;
    color : ${colors.Gr06};
    display : inline-flex;
    justify-content : flex-end;
`;