import React, { useState, useEffect } from "react";
import styled from "styled-components";
import Sheet from "react-modal-sheet";

//component
import { BankList } from "../../tool/random_text";

//img
import ModalCloseIcon from "../../icon/modalCloseButton.png";
import { W, WIDTH } from "../../styles/globalStyles";
import colors from "../../styles/colors";

function BankModal({ bank, setBank, info, setInfo }) {
  const [selected, setSelected] = useState(info);

  useEffect(() => {
    setSelected(info);
  }, [info]);

  const BankIconMaker = (data, i) => {
    if (data.src) {
      return (
        <BankItem
          key={`${data.src}-${i}`}
          mr={i % 4 !== 3}
          selected={selected == data.name}
          onClick={() => setSelected(data.name)}
        >
          <Img src={data.src} />
          <TextView
            color={selected == data.name ? colors.Bl07 : colors.Gr03}
            fw={`500`}
            fs={`12px`}
            lh={`142%`}
          >
            {data.name}
          </TextView>
        </BankItem>
      );
    } else {
      return (
        <BankAlone
          key={`${data.src}-${i}`}
          mr={i % 4 !== 3}
          selected={selected == data.name}
          onClick={() => setSelected(data.name)}
        >
          <TextView
            color={selected == data.name ? colors.Bl07 : colors.Gr03}
            fw={`500`}
            fs={`12px`}
            lh={`142%`}
          >
            {data.name}
          </TextView>
        </BankAlone>
      );
    }
  };

  return (
    <BackButtonModal
      style={{
        width: "100%",
        display: "flex",
        justifyContent: "center",
        left: "50%",
        transform: "translate(-50%,0)",
      }}
      isOpen={bank}
      snapPoints={[0.9]}
    >
      <BackButtonModal.Container style={{ width: `${W}px`, left: "none" }}>
        <ModalLayout>
          <div>
            <ModalFirstLine>
              <ModalTitleContainer>
                <ModalTitle>은행 선택</ModalTitle>
              </ModalTitleContainer>
              <CloseIcon
                src={ModalCloseIcon}
                onClick={() => {
                  setBank((prev) => !prev);
                  setSelected(info ? info : "");
                }}
              />
            </ModalFirstLine>
            <ModalContentContainer>
              {BankList.map((el, i) => BankIconMaker(el, i))}
            </ModalContentContainer>
          </div>
          <SubmitButton
            active={selected}
            onClick={() => {
              setInfo((prev) => ({ ...prev, bank: selected }));
              setBank(false);
            }}
          >
            {selected
              ? "선택한 은행의 계좌번호 입력하기"
              : "은행을 골라 주세요!"}
          </SubmitButton>
        </ModalLayout>
      </BackButtonModal.Container>
      <BackButtonModal.Backdrop />
    </BackButtonModal>
  );
}

export default BankModal;

const BackButtonModal = styled(Sheet)``;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Button = styled.div`
  font-weight: 500;
  font-size: 14px;
  line-height: 142%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  letter-spacing: -0.02em;
  width: 48%;
  padding: 18px 0;
  border-radius: 8px;
`;

const ModalLayout = styled.div`
  height: 100%;
  padding: 24px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const ModalFirstLine = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
`;

const ModalTitleContainer = styled.div`
  display: flex;
  /* align-items: flex-end; */
  align-items: center;
`;

const ModalTitle = styled.div`
  font-weight: 600;
  font-size: 18px;
  line-height: 142%;
  letter-spacing: -0.02em;
  color: #1a1c1f;
  margin-right: 12px;
`;

const CloseIcon = styled.img`
  width: 24px;
  height: 24px;
  cursor: pointer;
`;

const ModalContentContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const TextView = styled.span`
  width: ${({ width }) => width && `${width}`};
  text-align: ${({ ta }) => ta && `${ta}`};
  color: ${({ color }) => color && `${color}`};
  font-weight: ${({ fw }) => fw && `${fw}`};
  font-size: ${({ fs }) => fs && `${fs}`};
  line-height: ${({ lh }) => lh && `${lh}`};
  display: inline-block;
  text-overflow: ${({ to }) => to && `${to}`};
  overflow: hidden;
  white-space: ${({ ws }) => ws && `${ws}`};
  ${({ gmarket }) => gmarket && `font-family : "GmarketSans" !important;`}
`;

const BankItem = styled.div`
  cursor: pointer !important;
  width: ${(W - 48 - 36) / 4}px;
  border: 1px solid;
  border-color: ${({ selected }) => (selected ? colors.Bl07 : colors.Gr02)};
  margin-right: ${({ mr }) => (mr ? 12 : 0)}px;
  padding: 13px 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  margin-bottom: 12px;
`;

const BankAlone = styled.div`
  cursor: pointer !important;
  border: 1px solid;
  width: ${(W - 48 - 36) / 4}px;
  border-color: ${({ selected }) => (selected ? colors.Bl07 : colors.Gr02)};
  margin-right: ${({ mr }) => (mr ? 12 : 0)}px;
  padding: 10px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  margin-bottom: 12px;
`;

const Img = styled.img`
  width: 37px;
  height: 37px;
  margin-bottom: 10px;
`;

const SubmitButton = styled.div`
  border-radius: 8px;
  background-color: ${({ active }) => (active ? colors.Bl07 : colors.Gr01)};
  color: ${({ active }) => (active ? colors.White : colors.Gr03)};
  padding: 16px;
  text-align: center;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
`;
