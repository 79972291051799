import React, { useState } from "react";
import styled from "styled-components";
import Modal from "react-modal";
import axios from "axios";

//img
import { W } from "../../styles/globalStyles";
import colors from "../../styles/colors";

// tool
import { GetOne } from "../../tool/localstorage";

const InviteModal = ({ modal, setModal, GetData }) => {

    const [phone, setPhone] = useState('');

    const PopupFunction = async () => {
        const token = GetOne("token");

            if(phone.length > 12) {
                const frm = new FormData();

                frm.append('token', token);
                frm.append('mode', 'invite');
                frm.append('phone', phone);
    
                axios
                    .post('https://ai.semos.kr/semos_partner/v3/profile/PartnerInvite', frm, {
                    headers: {'Content-Type': 'multipart/form-data'},
                    })
                    .then(data => {
                        const Value = data.data;
                        const StatusMessage = Value.status_message;
        
                    if (Value.status === 'success') {
                        window.alert(StatusMessage)
                        setModal(false)
                        setPhone('')
                        GetData();
                    } else {
                        window.alert(StatusMessage)
                        setModal(false)
                        setPhone('')
                        window.location.reload();
                    }
                    })
                    .catch(err => console.log('PROFILE ERROR', err));
            } else {
                window.alert('전화번호를 확인해주세요!')
            } 
    };

    const HandlePhone = (num) => {
        let PhoneNumber = num.replace(/[^0-9]/g, '') // 숫자를 제외한 모든 문자 제거
        .replace(/^(\d{2,3})(\d{3,4})(\d{4})$/, `$1-$2-$3`);

        setPhone(PhoneNumber); 
    }


    return (
        <Modal
            isOpen={modal}
            onRequestClose={() => setModal(false)}
            contentLabel="Alert Modal"
            style={customStyles}
        >

            <Container>
                <Title>{`파트너 초대하기`}</Title>
                <Space h={5} />
                <SmallTitle>
                    <span>{`지인 파트너 초대하고 메인 홈 노출 혜택을 받아요!`}</span>
                </SmallTitle>
                <SmallTitle>
                    <span>{`* 신규 입점시 메인 홈 1달 노출 혜택`}</span>
                </SmallTitle>
                <Space h={24} />

                <ContentTitle>
                    전화번호로 초대하기
                </ContentTitle>

                <PhoneInput>
                    <input onChange={(e) => HandlePhone(e.target.value)} value={phone} placeholder="0**-****-****" maxLength={13} />
                </PhoneInput>

                <Space h={24} />

                <ButtonContainer>
                    <Button
                        c={colors.White}
                        bg={colors.Bl07}
                        onClick={() => {
                            PopupFunction();
                        }}
                    >
                        초대하기
                    </Button>
                </ButtonContainer>
            </Container>
        </Modal>
    );
};

export default InviteModal;

const customStyles = {
  content: {
    width: `${W - 48}px`,
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    borderRadius: "16px",
    padding: "24px",
    // zIndex: 20,
  },
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  /* justify-content: space-between; */
  align-items: center;
`;

const Icon = styled.img`
  width: 76px;
  height: 76px;
`;

const Title = styled.div`
  font-weight: 700;
  font-size: 18px;
  line-height: 142%;
  text-align: center;
  letter-spacing: -0.02em;
  color: ${colors.Gr05};
`;

const SmallTitle = styled.div`
  display: inline-flex;
  flex-wrap: wrap;
  text-align: center;
  line-height: 142%;
  letter-spacing: -0.02em;
  font-size: 12px;
  font-weight: 700;

  span {
    width: 100%;
    color: ${colors.Gr03};
  }

  .blue-text {
    color: ${colors.Bl05};
  }
`;

const Space = styled.div`
  height: ${({ h }) => h}px;
`;

const ContentTitle = styled.div`
    display : inline-block;
    font-size: 16px;
    font-weight: 500;
    margin-bottom : 10px;
    width : 100%;
`;

const PhoneInput = styled.div`
    width : 100%;
    border : 1px solid ${colors.Bl07};
    border-radius : 8px;
    padding : 8px; 
    font-size: 16px;
    font-weight: 500;

    input {
        width : 100%;
        outline: none;
    }
`;

const ButtonContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 12px;
    width: 100%;
`;

const Button = styled.div`
    cursor: pointer;
    display: flex;
    justify-content: center;
    width: ${({ width }) => (width ? width : "100%")};
    padding: 14px 0;
    background-color: ${({ bg }) => bg};
    border-radius: 8px;
    color: ${({ c }) => c};
    line-height: 142%;
    font-size: 14px;
    font-weight: 700;
`;
