import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";

//tool

//style
import ActivityModal from "./ActivityModal";

//component
import { Wrapper, Text, Input, WrapperColumn, AbsoluteWrapper } from "../layouts/layoutStorage";
import colors from "../../styles/colors";

//icon
import { ReactComponent as Plus } from "../../resource/icon/plus.svg";
import { ReactComponent as Minus } from "../../resource/icon/minus.svg";
import { ReactComponent as Close } from "../../resource/icon/close.svg";
import { ReactComponent as Arrow } from "../../resource/icon/arrow.svg";

const ScheduleDayKorean = {
    mon : "월", 
    tue : '화', 
    wed : '수', 
    thu : '목', 
    fri : '금', 
    sat : '토', 
    sun : '일', 
    holiday : '공휴일'
};

const FacilityLocationModal = ({ data, setData, open, setOpen, showAddress, showAddressIndex, facilityList, facilityLocation, setChange }) => {
    const FacilityRef = useRef();

    const [FTitle, setFTitle] = useState();
    const [FLocation, setFLocation] = useState();
    const [timeList, setTimeList] = useState();

    const [dropdown, setDropdown] = useState(false);

    useEffect(() => {
        let yoil = [];

        if (showAddress.title && facilityLocation) {

            for(let i=0; i < facilityList?.length; i++) {
                let ThisTitle = facilityList[i].title;

                if(ThisTitle == showAddress.title) {
                    setFTitle(facilityList[i]);
                    break;
                }
            }
            
            let dataCopy = [...data.activity_location];

            let array = facilityLocation[showAddress.title];

            dataCopy.filter((val) => {
                if (val.title === showAddress.title) {
                    let dayCheck = val.days[0];

                    if(dayCheck) {
                        array[dayCheck] = val.start;
                        yoil.push(dayCheck)
                    }
                }
            });

            setFLocation(array);
        }
        return () => {
            setFTitle();
            setFLocation();
        }
    }, [open, showAddressIndex]);

    const activeCheck = () => {
        if(FLocation?.title && FLocation?.address) {
            return true;
        } else {
            return false;
        }
    };

    const scrollRef = useRef(null);

    const containerRef = useRef(null);

    const saveData = () => {
        let dataCopy = [...data.activity_location];

        let Title = FTitle?.title;
        let Yoil = Object.keys(FTitle?.time);

        for(let i=0; i < Yoil.length; i++) {
            const YoilText = Yoil[i];

            const KoreanYoil = ScheduleDayKorean[YoilText];

            let ThisYoilTime = FLocation[KoreanYoil];

            if(ThisYoilTime.length > 1) {
                let AddTime = {
                    address : FTitle?.address,
                    id : FLocation?.id,
                    title : Title,
                    days : [KoreanYoil],
                    start : ThisYoilTime
                }

                dataCopy.push(AddTime);
            }
        }

        setData((prev) => ({ ...prev, activity_location: dataCopy }));
        setFTitle();
        setFLocation();
        setOpen(false);
        setChange(true);
    };

    const FacilityChange = (addressData) => {

        setFTitle(addressData);
        if(addressData.title) {
            setFLocation(facilityLocation[addressData.title]);
        }

    }

    const FacilityTimeSelect = async () => {

        const FacilityYoilList = FTitle?.time;

        if(FacilityYoilList) {

            let TimeData = [];

            const TimeKey = Object.keys(FacilityYoilList);
            
            for await (const key of TimeKey) { 

                const YoilData = FacilityYoilList[key];

                if(YoilData) {
                    const StartHour = YoilData['start_hour'];
                    const StartMin = YoilData['start_min'];
                    const FinishHour = YoilData['finish_hour'];
                    const FinisMin = Number(YoilData['finish_min']);
        
                    let StartMinAll = (Number(StartHour) * 60) + Number(StartMin);
                    let FinishMinAll = (Number(FinishHour) * 60) + Number(FinisMin);
        
                    let BetweenTime = Math.ceil((FinishMinAll - StartMinAll) / 60);
        
                    let TimeList = [];

                    let BetweenCount = Array(BetweenTime).fill().map((v,i)=>i);
        
                    for await (const num of BetweenCount) {
                        let ThisTime = StartMinAll + Number(num * 60);
        
                        let Hour = Math.floor(ThisTime / 60);
                        let Min = ThisTime % 60;
        
                        if(Hour < 10) {
                            Hour = `0${Hour}`
                        }
        
                        if(Min < 10) {
                            Min = `0${Min}`
                        }
        
                        let TimeText = `${Hour}:${Min}`;
        
                        TimeList.push(TimeText);
                    }
        
                    TimeData[key] = await TimeList;
                } 
            }

            setTimeList(TimeData);

        } else {
            setTimeList();
        }
    }

    const SelectFacilityTime = (yoil, time) => {
        if(FLocation) {
            let FacilityYoilTime = FLocation[yoil];

            if(FacilityYoilTime) {
                if(FacilityYoilTime.indexOf(time) == -1) {
                    FacilityYoilTime[FacilityYoilTime.length] = time;
                } else {
                    FacilityYoilTime = FacilityYoilTime.filter((element) => element != time);
                }

                setFLocation((prev) => ({ ...prev, [yoil]: FacilityYoilTime }));
            }
        }
    }

    useEffect(() => {
        FacilityTimeSelect();
    }, [FTitle]);

    return (
        <ActivityModal
            // twoButton={changeAddressCheck()}
            // buttonLeftFuc={}
            // buttonLeftText="삭제하기"
            buttonText="등록하기"
            buttonActive={activeCheck()}
            title="진행 시설 및 예약 시간"
            open={open}
            setOpen={setOpen}
            saveData={saveData}
        >
            <Wrapper fd="column" height="100%" width="100%" ai="flex-start" padding="0 0 130px 0">
                <Text margin="8px 24px 12px 24px" size="14px" weight="500" lh="22px" ls="-0.28px" color={colors.Gr04}>
                액티비티를 진행할 시설과 일정을 입력해주세요
                </Text>

                <Wrapper ref={containerRef} fd="column" width="100%" height="100%" ai="flex-start" of="auto">
                    <Wrapper padding="16px 0px 8px 0" width="100%" fd="column" ai="flex-start">
                        <ModalSubTitle>진행 시설</ModalSubTitle>

                        <TipWrap>
                            <span className="tip">Tip! </span>
                            <span>등록하기 버튼 클릭시 시설 변경이 불가해요.</span>
                        </TipWrap>

                        <Wrapper
                                ref={FacilityRef}
                                height="54px"
                                cursor="pointer"
                                position="relative"
                                onClick={(e) => {
                                    e.stopPropagation();
                                    setDropdown(!dropdown);
                                }}
                                radius="8px"
                                width="calc(100% - 48px)"
                                bgc={colors.White}
                                border={`1px solid ${colors.Gr02}`}
                                jc="space-between"
                                margin={`8px 24px 0 24px`}
                                padding={`0 8px`}
                        >
                                {
                                    FTitle ?
                                        <Wrapper width={`100% - 2.5rem`} fd={`column`}>
                                            <Text width={`100%`} size="1.5rem" weight="600" color={colors.Gr05}>
                                                {FTitle.title}
                                            </Text>

                                            <Text width={`100%`} size="1.3rem" weight="500" color={colors.Gr04}>
                                                {FTitle.address.address}
                                            </Text>
                                        </Wrapper>
                                    : 
                                        <Wrapper width={`100% - 2.5rem`} fd={`column`}>
                                            <Text width={`100%`} size="1.5rem" weight="600" color={colors.Gr05}>
                                                진행 시설을 선택해주세요!
                                            </Text>
                                        </Wrapper>
                                }

                                <Arrow
                                    width="2rem"
                                    height="2rem"
                                    fill={colors.Gr04}
                                    style={{ transition: "all ease 0.5s", transform: dropdown ? "rotate(-90deg)" : "rotate(90deg)" }}
                                />

                                {dropdown && (
                                    <AbsoluteWrapper
                                        maxHeight="150px"
                                        z={1}
                                        fd="column"
                                        width="100%"
                                        top="60px"
                                        left="0"
                                        bgc={colors.White}
                                        border={`1px solid ${colors.Gr02}`}
                                        radius="8px"
                                        of="auto"
                                    >
                                        {facilityList.map((x, idx) => (
                                            <DropDown
                                                key={`${idx}_facility_address`}
                                                last={(idx + 1) === facilityList?.length}
                                                onClick={() => {
                                                    FacilityChange(x)
                                                }}
                                            >
                                                <Text width={`100%`} size="1.5rem" weight="600" color={colors.Gr05}>
                                                    {x.title}
                                                </Text>

                                                <Text width={`100%`} size="1.3rem" weight="500" color={colors.Gr04}>
                                                    {x.address?.address}
                                                </Text>
                                            </DropDown>
                                        ))}
                                    </AbsoluteWrapper>
                                )}
                        </Wrapper>
                    </Wrapper>

                    <Wrapper padding="16px 0 8px 0" width="100%" fd="column" ai="flex-start">
                        <ModalSubTitle>예약 가능 시간</ModalSubTitle>

                        <TipWrap>
                            <span className="tip">Tip! </span>
                            <span>시작 시간 기준으로, 요일별 예약 받을 시간대를 추가해요.</span>
                        </TipWrap>

                        <Wrapper margin="8px 0" fd="column" width="100%" padding="8px 24px" gap="8px">
                                {
                                    FTitle?.time ?
                                        Object.keys(FTitle.time).map((x, idx) => {

                                            return (
                                                <Wrapper
                                                    key={`${idx}_facility_${x}_time`}
                                                    width={`100%`}
                                                    gap={`6px`}
                                                    radius={`8px`}
                                                    border={`1px solid ${colors.Gr03}`}
                                                >

                                                    <Wrapper
                                                        width={`20%`}
                                                        br={`1px solid ${colors.Gr03}`}
                                                        padding={`5px`}
                                                        ai={`flex-start`}
                                                        jc={`flex-start`}
                                                        height={`100%`}
                                                    >
                                                        <Text size="1.8rem" weight="600" color={colors.Gr05}>
                                                            {ScheduleDayKorean[x]}
                                                        </Text>
                                                    </Wrapper>

                                                    <Wrapper
                                                        width={`80%`}
                                                        padding={`5px 3px`}
                                                        ai={`flex-start`}
                                                        jc={`flex-start`}
                                                        wrap={`wrap`}
                                                        gap={'8px'}
                                                    >
                                                        {(timeList && FLocation) ?
                                                            timeList[x]?.map((x2, idx2) => {

                                                                return (
                                                                    <Wrapper
                                                                            key={`${idx}_${idx2}_${x}`}
                                                                            border={`2px solid ${
                                                                                (FLocation[ScheduleDayKorean[x]]?.indexOf(x2) != -1) ? 
                                                                                colors.Bl07 : colors.Gr03}`
                                                                            }
                                                                            padding={`5px 0`}
                                                                            ai={`center`}
                                                                            jc={`center`}
                                                                            radius={`6px`}
                                                                            cursor={`pointer`}
                                                                            onClick={() => SelectFacilityTime(ScheduleDayKorean[x], x2)}
                                                                            width={`5.1rem`}
                                                                        >
                                                                            <Text size="1.3rem" weight="600" lh={`100%`} color={`${
                                                                                (FLocation[ScheduleDayKorean[x]]?.indexOf(x2) != -1) ? 
                                                                                colors.Bl07 : colors.Gr05}`}
                                                                            >
                                                                                {x2}
                                                                            </Text>
                                                                    </Wrapper> 
                                                                )
                                                            }) 
                                                        : ""}
                                                    </Wrapper>

                                                </Wrapper>
                                            )
                                        })
                                    : ""
                                }
                        </Wrapper>

                        <Wrapper gap="8px" ref={scrollRef} width="100%" of="auto" padding="0 24px 8px 24px">

                        </Wrapper>

                    </Wrapper>
                </Wrapper>
            </Wrapper>

        </ActivityModal>
    );
};

export default FacilityLocationModal;

const ModalSubTitle = styled.span`
    word-break: keep-all;
    padding: 0 24px;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: -0.32px;
    color: ${colors.Gr05};
`;

const TipWrap = styled.p`
    word-break: keep-all;
    padding: 0 24px;
    font-size: 14px;
    font-weight: 500;
    line-height: 22px;
    letter-spacing: -0.28px;
    color: ${colors.Gr03};
    .tip {
        color: ${colors.Bl05};
    }
`;


const AddressPopup = styled.section`
    height: calc(var(--vh, 1vh) * 100);
    /* height: 100vh; */
    width: calc(var(--vw, 1vw) * 100);
    overflow: hidden;
    position: fixed;
    z-index: 150;
    background-color: #ffffff;
    top: 0;
    right: 0;
    display: ${({ view }) => (view ? `inline-flex` : `none`)};
`;

const DropDown = styled.div`
    width: 100%;
    padding: 8px;
    border-bottom: ${({ last }) => !last && `1px solid ${colors.Gr02}`};
    color: ${colors.Gr04};
    font-size: 13px;
    font-weight: 500;
    display : inline-flex;
    flex-wrap : wrap;
`;

