import React, { useState, useEffect } from "react";
import styled from "styled-components";
import Sheet from "react-modal-sheet";

//style
import { W, WIDTH } from "../../styles/globalStyles";
import colors from "../../styles/colors";

//component
import { BankList } from "../../tool/random_text";

//img
import { ReactComponent as ModalCloseIcon } from "../../resource/icon/close_circle.svg";

const ActivityModal = ({ title, buttonText, twoButton, buttonActive, saveData, open, setOpen, children }) => {
  return (
    <BackButtonModal
      style={{ width: "100%", display: "flex", justifyContent: "center", left: "50%", transform: "translate(-50%,0)" }}
      isOpen={open}
      snapPoints={[0.9]}
    >
      <BackButtonModal.Container style={{ width: `${W}px`, left: "none" }}>
        <ModalLayout>
          <div>
            <ModalFirstLine>
              <ModalTitleContainer>
                <ModalTitle>{title}</ModalTitle>
              </ModalTitleContainer>
              <ModalCloseIcon onClick={() => setOpen(false)} width="28px" height="28px" fill={colors.Gr03} style={{ cursor: "pointer" }} />
            </ModalFirstLine>
          </div>
          {children}

          <SubmitWrap twoButton={twoButton}>
            {/* {twoButton && (
              <SubmitLeftButton
                twoButton={twoButton}
                onClick={() => {
                  buttonLeftFuc();
                }}
              >
                {buttonLeftText}
              </SubmitLeftButton>
            )} */}
            <SubmitButton
              active={buttonActive}
              onClick={() => {
                buttonActive && saveData();
              }}
            >
              {buttonText}
            </SubmitButton>
          </SubmitWrap>
        </ModalLayout>
      </BackButtonModal.Container>
      <BackButtonModal.Backdrop />
    </BackButtonModal>
  );
};

export default ActivityModal;

const BackButtonModal = styled(Sheet)``;

const ModalLayout = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const ModalFirstLine = styled.div`
  display: flex;
  padding: 24px 24px 0 24px;
  justify-content: space-between;
  align-items: center;
`;

const ModalTitleContainer = styled.div`
  display: flex;
  /* align-items: flex-end; */
  align-items: center;
`;

const ModalTitle = styled.div`
  font-weight: 600;
  font-size: 18px;
  line-height: 142%;
  letter-spacing: -0.02em;
  color: #1a1c1f;
  margin-right: 12px;
`;

const SubmitWrap = styled.div`
  padding: 12px 24px;
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  background-color: ${colors.White};
  display: flex;
  justify-content: ${({ twoButton }) => (twoButton ? "space-between" : "center")};
  gap: ${({ twoButton }) => (twoButton ? "8px" : "0")};
  align-items: center;
`;

const SubmitButton = styled.div`
  height: 56px;
  width: 100%;
  border-radius: 8px;
  background-color: ${({ active }) => (active ? colors.Bl07 : colors.Gr01)};
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: ${({ active }) => active && "pointer"};
  color: ${({ active }) => (active ? colors.White : colors.Gr03)};
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: -0.32px;
`;

const SubmitLeftButton = styled.div`
  height: 56px;
  width: 100%;
  border-radius: 8px;
  background-color: ${colors.Gr01};
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  color: ${colors.Gr05};
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: -0.32px;
`;
