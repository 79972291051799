import React from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";

//component
import colors from "../../styles/colors";

//icon
import { ReactComponent as Close } from "../../resource/icon/close.svg";
import Header from "../basic/Header";

const FacilityHeader = ({ status, title, available, statusHandler, backHandler, setModal, modal, modalOff }) => {
    const navigate = useNavigate();
    
    const CheckNavigate = () => {
        let TC = title
        
        switch(TC) {
            case '신규 시설 등록': 
                navigate(`/facility`)
            break;

            case '시설 정보 수정': 
                navigate(-1)
            break;

            case '상담내역': 
                navigate(-1)
            break;

            case '시설 정보': 
                navigate(`/facility`)
            break;

            case '신규 회원 등록': 
                if(modalOff === true) {
                    if(setModal) {
                        setModal(!modal)
                    }
                } else {
                    navigate(`/crew`)
                }
            break;

            case '회원 정보 수정': 
                navigate(-1)
            break;

            case '회원 정보': 
                navigate(`/crew`)
            break;

            case '시설 예약 현황': 
                navigate(`/home`)
            break;

            case '강습반 예약 현황': 
                navigate(`/home`)
            break;

            default :
                navigate(-1)
        }
    }

    return (
        <StNewHeader padding="8px 16px 10px 16px" gap="4px" jc="space-between" width="100%">
            <Header title={title ? title : "시설 수정"} border={false}>
                {   (!backHandler) ?
                        <Close onClick={() => CheckNavigate()} fill={colors.Gr06} width="24px" height="24px" style={{ cursor: "pointer" }} />
                    : <></>
                }

                {
                    available ?
                        <OnOff 
                            onClick={() => statusHandler()}
                            bgc={status == 1 ? `${colors.Bl07}` : `${colors.Red03}`}  
                        >
                            {status == 1 ? `활성` : `비활성`}
                        </OnOff>
                    : ""
                }

            </Header>
        </StNewHeader>
    );
};

export default FacilityHeader;

const StNewHeader = styled.div`
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 100%;
    background-color: ${colors.White};
    position: sticky;
    z-index: 10;
    top: 0;
`;

const OnOff = styled.div`
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    padding: 5px 8px;
    background-color: ${({ bgc }) => (bgc ? `${bgc}` : "#ffffff")};
    border: ${({ border }) => (border ? `${border}` : "none")};
    color : ${colors.White};
    font-size: 14px;
    font-weight: 600;
    cursor : pointer;
    align-self : center;
`;