import React, { useState } from "react";
import styled from "styled-components";

import RegisterInput from "./RegisterInput";

import colors from "../../styles/colors";
import ImageUploader from "./ImageUploader";

const CenterForm = ({ STEP_LIST, step, setStep, data, setData, setNext }) => {
  const [valid, setValid] = useState({
    registerNumber: false,
    registerCard: false,
  });

  return (
    <div>
      <RegisterTitle>{STEP_LIST[step.name].title}</RegisterTitle>

      {step.index === 7 ? (
        <ImageUploader
          type="registerCard"
          STEP_LIST={STEP_LIST}
          step={step}
          setStep={setStep}
          data={data}
          setData={setData}
          setNext={setNext}
          valid={valid}
          setValid={setValid}
        />
      ) : (
        <></>
      )}

      {step.index === 6 ? (
        <RegisterInput
          type="registerNumber"
          placeholder="사업자등록번호를 입력해주세요"
          STEP_LIST={STEP_LIST}
          step={step}
          data={data}
          setData={setData}
          setNext={setNext}
          valid={valid}
          setValid={setValid}
        />
      ) : (
        <></>
      )}
    </div>
  );
};

export default CenterForm;

const RegisterTitle = styled.h2`
  padding: 32px 0 8px 0;
  color: ${colors.Gr06};
  white-space: pre-line;
  line-height: 26px;
  letter-spacing: -0.36px;
  font-size: 18px;
  font-weight: 700;
`;
