import React, { useState } from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";

import { Wrapper } from "../layouts/layoutStorage";

// style
import colors from "../../styles/colors";

// img
import BlueIcon from "../../icon/semosBlueLogo.png";
import { ReactComponent as Gear } from "../../icon/gear.svg";
import { ReactComponent as Alarm } from "../../icon/alarm.svg";

const HomeHeader = ({ newAlarm }) => {
  const navigate = useNavigate();

  const [click, setClick] = useState(false);

  const eventHandle = () => {
    setClick(true);
    setTimeout(() => {
      navigate("/alarm");
    }, 100);
  };

  return (
    <HeaderContainer>
      <HeaderPartner>
        <SemosIcon src={BlueIcon} />
        <HeaderTitle>세모스 파트너</HeaderTitle>
      </HeaderPartner>
      <Wrapper gap="16px">
        {/*<BellContainer id="bell" onClick={() => eventHandle()}>
          <Alarm fill={click ? colors.Bl07 : colors.Gr05} />
          {newAlarm && <BlueDot />}
        </BellContainer>*/}
        <Gear onClick={() => navigate("/settings")} />
      </Wrapper>
    </HeaderContainer>
  );
};

export default HomeHeader;

const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-right: 24px;
  padding-left: 13px;
  background-color: ${colors.Gr01};
  border-bottom: 1px solid ${colors.Gr01};
  position: sticky;
  z-index: 10;
  top: 0;
`;

const HeaderPartner = styled.div`
  display: flex;
  align-items: center;
`;

const SemosIcon = styled.img`
  width: 60px;
  height: 60px;
  margin-right: 5px;
`;

const HeaderTitle = styled.div`
  color: ${colors.Bl07};
  line-height: 24.5px;
  font-family: "GmarketSans" !important;
  font-size: 18.56px;
  font-weight: 700;
`;

const BellContainer = styled.div`
  position: relative;
  cursor: pointer;
`;

const BlueDot = styled.div`
  width: 4px;
  height: 4px;
  background-color: ${colors.Bl07};
  border-radius: 50%;
  position: absolute;
  top: 0;
  right: 0;
`;
