import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import styled from "styled-components";

// components
import Header from "../components/basic/Header";
import Modal from "../components/newActivityRegister/modal";
import { GetOne } from "../tool/localstorage";
import { LoadingSpinner } from "../tool/loading";
import Card from "../components/notice/Card";

// styles
import colors from "../styles/colors";
import { Wrapper, Input, Text } from "../components/layouts/layoutStorage";

// img
import plusBlue from "../icon/plusBlue.png";

const Notice = () => {

    const infoRef = useRef();

    const [dropdown, setDropdown] = useState(false);

    const [modalStatus, setModalStatus] = useState({
        status: false,
        type: "",
        link: "",
    });

    const [loading, setLoading] = useState(true);

    const navigate = useNavigate();

    const [noticeList, setNoticeList] = useState();
    const [height, setHeight] = useState(0);

    useEffect(() => {
        setTimeout(() => {
            var FirstComponent = document.getElementById("HeightCheck").clientHeight;
            setHeight(FirstComponent);
        }, 500);

        var token = GetOne("token");

        const frm = new FormData();

        frm.append("mode", "main");
        frm.append("token", token);

        axios
        .post("https://ai.semos.kr/semos_partner/v3/profile/Notice", frm, {
            headers: { "Content-Type": "multipart/form-data" },
        })
        .then((data) => {
            var Value = data.data;
            var Status = Value.status;
            var StatusMessage = Value.status_message;
            var List = Value.list;

            if (Status == "success") {
                setNoticeList(List);
                setLoading(false);
            } else {
                window.alert(StatusMessage);
            }
        });
    }, []);

    const GoRegister = () => {
        navigate("/new_notice");
    };

    return (
        <Container>

            <Header title="공지사항 관리" back={true} handleClick={() => navigate("/home")} />

            <InnerContainer>

                <HeightCheck id="HeightCheck">
                    {/*<Wrapper 
                        width="calc(100% - 48px)" 
                        padding="5px"
                        margin={`7px 24px`}
                        jc="space-between"
                        ai="center"
                        border={`2px solid ${focus ? colors.Bl07 : colors.Gr04}`}
                        radius={`8px`}
                    >
                        <Input 
                            onFocus={() => setFocus(true)}
                            onBlur={() => setFocus(false)}
                            onKeyPress={(e) => {
                                if(e.key == "Enter" || e.keyCode == "13") {
                                    SearchCrew()
                                }
                            }}
                            value={search}
                            onChange={(e) => {setSearch(e.target.value)}}
                            width="calc(100% - 3.5rem)"
                            size="1.8rem"
                            weight="600"
                            lh="22px"
                            ls="-0.28px"
                            maxLength={30}
                            phColor={colors.Gr03}
                            placeholder="회원명, 전화번호로 검색할 수 있어요."
                        />
                        <Search  
                            onClick={() => SearchCrew()}
                            fill={colors.Gr03} 
                            width="3rem" 
                            height="3rem" 
                        />
                    </Wrapper>*/}
                </HeightCheck>

                <ScrollContainer height={height && height}>
                    {
                        noticeList?.map((x, idx) => (
                            <Card key={`${idx}_crew`} data={x}/>
                        ))
                    }
                    {
                        (noticeList?.length < 1) ?
                            <Wrapper
                                jc={'center'}
                                ai={'center'}
                                fd={'column'}
                                width={`100%`}
                                gap={`3px`}
                                margin={`5px 0 0 0`}
                            >
                                <Text 
                                    jc={'center'}
                                    ta={`center`} 
                                    width={`100%`} 
                                    size="1.8rem" 
                                    weight="600" 
                                    lh="24px" 
                                    ls="-0.32px" 
                                    color={colors.Gr04}
                                >
                                    등록된 공지사항이 없습니다.
                                </Text>
                                <Text 
                                    ta={`center`} 
                                    jc={'center'}
                                    width={`100%`} 
                                    size="1.8rem" 
                                    weight="600" 
                                    lh="24px" 
                                    ls="-0.32px" 
                                    color={colors.Gr04}
                                >
                                    공지사항을 등록해주세요!
                                </Text>
                            </Wrapper>
                        : ""
                    }
                </ScrollContainer>
                
            </InnerContainer>

            <LoadingSpinner loading={loading} title={`정보를 확인하고 있습니다.`} content={`잠시만 기다려 주세요!`} />

            <Modal modal={modalStatus} setModal={setModalStatus} />

            <NewCrew
                onClick={() => GoRegister()}
            >

                <img src={plusBlue} />

                <Text 
                    ta={`center`} 
                    jc={'center'}
                    width={`100%`} 
                    size="1.8rem" 
                    weight="600" 
                    lh="24px" 
                    ls="-0.32px" 
                    color={colors.Gr04}
                >
                    공지사항 등록
                </Text>
            </NewCrew>
        </Container>
    );
};

export default Notice;

const Container = styled.section`
    /* height: calc(var(--vh, 1vh) * 100); */
    overflow-x: hidden;
`;

const Divider = styled.div`
    height: ${({ height }) => height && `${height}px`};
    background-color: ${({ color }) => color && `${color}`};
    width: 100%;
`;

const InnerContainer = styled.div`
    width: 100%;
    /* height: calc((var(--vh, 1vh) * 100) - 50.55px); */
    overflow: hidden;
    padding-bottom : 7rem;
`;

const NewCrew = styled.div`
    position : fixed;
    bottom : 1.5rem;
    right : 1.5rem;
    z-index: 100;
    cursor : pointer;
    border : .3rem solid ${colors.Bl07};
    //width : 5rem;
    height : 5rem;
    border-radius: .8rem;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 0 1rem;
    background-color: ${colors.White};

    img {
        width : 4rem;
        display: inline-block;
        margin-right: .5rem;
    }
`;

const HeightCheck = styled.div`
    width: 100%;
    height: auto;
    display: inline-block;
    //border-bottom : 1px solid ${colors.Gr01};
`;

const ContentContainer = styled.div`
    width: 100%;
    padding: 0 24px;
    display: inline-flex;
    position: relative;
`;

const BackgroundContent = styled.div`
    width: 100%;
    border: ${({ border }) => border && `${border}`};
    background-color: ${({ bgc }) => bgc && `${bgc}`};
    padding: ${({ padding }) => padding && `${padding}`};
    display: inline-flex;
    border-radius: ${({ br }) => br && `${br}px`};
    justify-content: ${({ jc }) => jc && `${jc}`};
    align-items: ${({ ai }) => ai && `${ai}`};
    position: relative;
`;

const TextView = styled.span`
    width: ${({ width }) => width && `${width}`};
    text-align: ${({ ta }) => ta && `${ta}`};
    color: ${({ color }) => color && `${color}`};
    font-weight: ${({ fw }) => fw && `${fw}`};
    font-size: ${({ fs }) => fs && `${fs}`};
    line-height: ${({ lh }) => lh && `${lh}`};
    display: ${({ display }) => display ? `${display}` : "inline-flex"};
`;

const ScrollContainer = styled.div`
    height: ${({ height }) => (height > -1) && `calc(100vh - ${height}px)`};
    /* max-height: ${({ height }) => height && `calc( (var(--vh, 1vh) * 100) - (${height}px + 133.55px) )`}; */
    /* min-height: ${({ height }) => height && `calc((var(--vh, 1vh) * 100) - (${height}px + 133.55px) )`}; */
    /* height: ${({ height }) => height && `calc(100vh - (${height}px + 133.55px) )`}; */
    width: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    display: inline-block;
    flex-wrap: wrap;
    padding-bottom: 85px;
`;