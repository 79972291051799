import React, { useEffect, useState, useRef } from "react";
import styled from "styled-components";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";

//tool
import { GetOne } from "../tool/localstorage";

//style
import { Wrapper, Text } from "../components/layouts/layoutStorage";
import colors from "../styles/colors";

//component
import { Loading } from "../tool/Loading";
import FacilityHeader from "../components/facilityRegister/FacilityHeader";
import MakeReservation from "../components/subscribe/MakeReservation";

//icon

const ConsultDetail = () => {

    const navigate = useNavigate();

    const params = useParams();

    const [loading, setLoading] = useState(false);
    const [manageT, setManageT] = useState(false);
    const [dataT, setDataT] = useState({});

    const [data, setData] = useState({
        name : "",
        description : "",
        image : "",
        view : "",
        like : "",
        id : "",
        status : "",
        upload : "",
        updated : ""
    });

    const [activityList, setActivityList] = useState();

    const W = window.document.documentElement.clientWidth;

    const Type = {
        '정기 레슨' : "개월",
        '이용권(기간)' : "개월",
        '이용권(횟수)' : "회",
    };

    const GetData = () => {
        setLoading(true);

        var token = GetOne("token");

        const frm = new FormData();

        frm.append("mode", "consult_info");
        frm.append("token", token);
        frm.append("consult_id", params.id);

        axios
        .post("https://ai.semos.kr/semos_partner/v3/profile/Consult", frm, {
            headers: { "Content-Type": "multipart/form-data" },
        })
        .then((data) => {
            var Value = data.data;
            var Status = Value.status;
            var StatusMessage = Value.status_message;
            var ConsultInfo = Value.consult_info;
            var ActivityInfo = Value.activity_list;

            if (Status == "success") {
                setData(ConsultInfo);
                setActivityList(ActivityInfo)
                setLoading(false);
            } else {
                window.alert(StatusMessage);
                setLoading(false);
            }
        });
    }

    const ConsultStatus = (type) => {
        setLoading(true);

        var token = GetOne("token");

        const frm = new FormData();

        frm.append("mode", "consult_status");
        frm.append("token", token);
        frm.append("consult_id", params.id);
        frm.append("status", type);

        axios
        .post("https://ai.semos.kr/semos_partner/v3/profile/Consult", frm, {
            headers: { "Content-Type": "multipart/form-data" },
        })
        .then((data) => {
            var Value = data.data;
            var Status = Value.status;
            var StatusMessage = Value.status_message;

            if (Status == "success") {
                window.alert(StatusMessage);
                setLoading(false);
                window.location.reload();
            } else {
                window.alert(StatusMessage);
                setLoading(false);
            }
        });
    }


    useEffect(() => {
        GetData()
    }, []);

    const StatusReturn = (status) => {

        switch(Number(status)) {
            case 1 :
                return {
                    text : "진행 중",
                    colors : colors.Bl07
                }
            case 2 :
                return {
                    text : "완료",
                    colors : colors.Gr04
                }

            case 3 :
                return {
                    text : "취소",
                    colors : colors.Red03
                }

            default :
                return ""
        }

    } 

    return (
        <Container id="description_container">

            <FacilityHeader
                status={true}
                title={`상담내역`}
                available={false}
                statusHandler={() => console.log("신규 회원 등록")}
            />

            <Wrapper
                jc="space-between"
                ai="flex-end"
                width="100%"
                padding={`20px 24px 10px 24px`}
                margin={`0 0 20px 0`}
                bb={`1px solid ${colors.Gr02}`}
            >

                <Text 
                    ta="center" 
                    size="1.6rem" 
                    weight="600" 
                    lh="22px" 
                    ls="-0.28px" 
                    color={StatusReturn(data?.status)?.colors}
                >
                    {`[${StatusReturn(data?.status)?.text}]`}
                </Text>

                <Text 
                    ta="center" 
                    size="1.6rem" 
                    weight="600" 
                    lh="22px" 
                    ls="-0.28px" 
                    color={colors.Gr03}
                >
                    {`${data?.date ? data?.date.split(' ')[0] : ""}`}
                </Text>

            </Wrapper>

            <Wrapper
                jc="flex-start"
                ai="center"
                fd={`column`}
                width="100%"
                padding={`0 24px 10px 24px`}
                margin={`0 0 20px 0`}
            >
                <Wrapper
                    width={`100%`}
                    jc={'space-between'}
                >
                    <Text 
                        ta="center" 
                        size="2.2rem" 
                        weight="600" 
                        lh="22px" 
                        ls="-0.28px" 
                        color={colors.Gr05}
                        width={`calc(100%)`}
                    >
                        {`상담 정보`}
                    </Text>
                </Wrapper>


                <TipWrap style={{marginTop : '5px'}}>
                    <span className="tip">Tip! </span>
                    <span>상담이 끝난 후 꼭! '상담 완료'를 눌러주세요!</span>
                </TipWrap>

            </Wrapper>

            <Wrapper
                jc="flex-start"
                ai="center"
                fd={`column`}
                width="100%"
                padding={`0 24px 0 24px`}
            >
                <Wrapper
                    width={`100%`}
                    jc={'space-between'}
                >
                    <Text 
                        ta="left" 
                        size="1.8rem" 
                        weight="600" 
                        lh="22px" 
                        ls="-0.28px" 
                        color={colors.Gr05}
                    >
                        {`이름 : `}
                    </Text>

                    <Text 
                        ta="right" 
                        size="1.8rem" 
                        weight="500" 
                        lh="22px" 
                        ls="-0.28px" 
                        color={colors.Gr05}
                        margin={`0 0 0 10px`}
                        wrap={`wrap`}
                    >
                        {`${data?.user_name}`}
                    </Text>
                </Wrapper>

                <Wrapper style={{margin : '15px 0'}} height={`2px`} width={`100%`} bgc={colors.Gr01}/>
            </Wrapper>

            <Wrapper
                jc="flex-start"
                ai="center"
                fd={`column`}
                width="100%"
                padding={`0 24px 0 24px`}
            >
                <Wrapper
                    width={`100%`}
                    jc={'space-between'}
                >
                    <Text 
                        ta="left" 
                        size="1.8rem" 
                        weight="600" 
                        lh="22px" 
                        ls="-0.28px" 
                        color={colors.Gr05}
                    >
                        {`전화번호 : `}
                    </Text>

                    <Text 
                        ta="right" 
                        size="1.8rem" 
                        weight="500" 
                        lh="22px" 
                        ls="-0.28px" 
                        color={colors.Gr05}
                        margin={`0 0 0 10px`}
                        wrap={`wrap`}
                    >
                        {`${data?.user_phone}`}
                    </Text>
                </Wrapper>

                <Wrapper style={{margin : '15px 0'}} height={`2px`} width={`100%`} bgc={colors.Gr01}/>
            </Wrapper>
            
            <Wrapper
                jc="flex-start"
                ai="center"
                fd={`column`}
                width="100%"
                padding={`0 24px 0 24px`}
            >
                <Wrapper
                    width={`100%`}
                    jc={'space-between'}
                >
                    <Text 
                        ta="left" 
                        size="1.8rem" 
                        weight="600" 
                        lh="22px" 
                        ls="-0.28px" 
                        color={colors.Gr05}
                    >
                        {`액티비티 : `}
                    </Text>

                    <Text 
                        ta="right" 
                        size="1.8rem" 
                        weight="500" 
                        lh="22px" 
                        ls="-0.28px" 
                        color={colors.Gr05}
                        margin={`0 0 0 10px`}
                        wrap={`wrap`}
                    >
                        {`${data?.activity}`}
                    </Text>
                </Wrapper>

                <Wrapper style={{margin : '15px 0'}} height={`2px`} width={`100%`} bgc={colors.Gr01}/>
            </Wrapper>

            <Wrapper
                jc="flex-start"
                ai="center"
                width="100%"
                wrap={'wrap'}
                padding={`0 24px 0 24px`}
            >
                <Text style={{marginBottom : '10px'}} width="100%" size="1.8rem" weight="600" ls="-0.02em" color={colors.Gr05}>
                    {`상담 내용`}
                </Text>
    
                <Wrapper style={{border : `1px solid ${colors.Gr02}`}} padding={`12px`} radius={'8px'} width="100%">
                    <Text ws="pre-wrap" size="1.6rem" weight="500" ls="-0.02em" color={colors.Gr05}>
                        {data?.content}
                    </Text>
                </Wrapper>

                <Wrapper style={{margin : '15px 0'}} height={`2px`} width={`100%`} bgc={colors.Gr01}/>

            </Wrapper>

            {
                (data?.status == 1) ?
                    <Wrapper
                        jc="flex-start"
                        ai="center"
                        fd={`column`}
                        width="100%"
                        padding={`0 24px 0 24px`}
                    >
                        <Wrapper
                            width={`100%`}
                            jc={'flex-end'}
                            gap={`12px`}
                        >
                            <Wrapper
                                onClick={() => ConsultStatus(3)}
                                jc="center"
                                ai="center"
                                width="auto"
                                cursor={"pointer"}
                                radius="8px"
                                bgc={colors.Red03}
                                padding={`8px 8px`}
                            >
                                <Text 
                                    ta="center" 
                                    size="1.4rem" 
                                    weight="600" 
                                    lh="22px" 
                                    ls="-0.28px" 
                                    color={colors.White}
                                >
                                    {`상담 취소`}
                                </Text>
                            </Wrapper>

                            <Wrapper
                                onClick={() => ConsultStatus(2)}
                                jc="center"
                                ai="center"
                                width="auto"
                                cursor={"pointer"}
                                radius="8px"
                                bgc={colors.Bl07}
                                padding={`8px 8px`}
                            >
                                <Text 
                                    ta="center" 
                                    size="1.4rem" 
                                    weight="600" 
                                    lh="22px" 
                                    ls="-0.28px" 
                                    color={colors.White}
                                >
                                    {`상담 완료`}
                                </Text>
                            </Wrapper>
                        </Wrapper>

                        <Wrapper style={{margin : '15px 0'}} height={`1px`} width={`100%`} bgc={colors.Gr02}/>
                    </Wrapper>
                : 
                    <></>

                }

            <Wrapper
                jc="flex-start"
                ai="center"
                fd={`column`}
                width="100%"
                padding={`0 24px 10px 24px`}
                margin={`0 0 20px 0`}
            >
                <Wrapper
                    width={`100%`}
                    jc={'space-between'}
                >
                    <Text 
                        ta="center" 
                        size="2.2rem" 
                        weight="600" 
                        lh="22px" 
                        ls="-0.28px" 
                        color={colors.Gr05}
                        width={`calc(100%)`}
                    >
                        {`예약 정보`}
                    </Text>
                </Wrapper>

                <TipWrap style={{marginTop : '5px'}}>
                    <span className="tip">Tip! </span>
                    <span>예약을 생성해, 크루님에게 예약 정보를 전달해주세요.</span>
                </TipWrap>

            </Wrapper>

            {
                data?.payment ?
                    <Wrapper
                        jc="flex-start"
                        ai="center"
                        fd={`column`}
                        width="100%"
                        padding={`0 24px 0 24px`}
                    >
                        <Wrapper
                            width={`100%`}
                            jc={'space-between'}
                        >
                            <Text 
                                ta="left" 
                                size="1.8rem" 
                                weight="600" 
                                lh="22px" 
                                ls="-0.28px" 
                                color={colors.Gr05}
                            >
                                {`액티비티 : `}
                            </Text>

                            <Text 
                                ta="right" 
                                size="1.8rem" 
                                weight="500" 
                                lh="22px" 
                                ls="-0.28px" 
                                color={colors.Gr05}
                                margin={`0 0 0 10px`}
                                wrap={`wrap`}
                            >
                                {`${data?.payment?.activity}`}
                            </Text>
                        </Wrapper>

                        <Wrapper style={{margin : '15px 0'}} height={`2px`} width={`100%`} bgc={colors.Gr01}/>

                        {
                            (data?.payment?.type == '정기 레슨' || data?.payment?.type == '이용권(기간)' || data?.payment?.type == '이용권(횟수)') ?
                                <>
                                    <Wrapper
                                        width={`100%`}
                                        jc={'space-between'}
                                    >
                                        <Text 
                                            ta="left" 
                                            size="1.8rem" 
                                            weight="600" 
                                            lh="22px" 
                                            ls="-0.28px" 
                                            color={colors.Gr05}
                                        >
                                            {`개월/횟수 : `}
                                        </Text>

                                        <Text 
                                            ta="right" 
                                            size="1.8rem" 
                                            weight="500" 
                                            lh="22px" 
                                            ls="-0.28px" 
                                            color={colors.Gr05}
                                            margin={`0 0 0 10px`}
                                            wrap={`wrap`}
                                        >
                                            {`${data?.payment?.people}${Type[data?.payment?.type]}`}
                                        </Text>
                                    </Wrapper>

                                    <Wrapper style={{margin : '15px 0'}} height={`2px`} width={`100%`} bgc={colors.Gr01}/>
                                </>
                            : 
                                <></>
                        }

                        {   
                            (data?.payment?.type !== '정기 레슨' && data?.payment?.type !== '이용권(기간)' && data?.payment?.type !== '이용권(횟수)') ?
                                <>
                                    <Wrapper
                                        width={`100%`}
                                        jc={'space-between'}
                                    >
                                        <Text 
                                            ta="left" 
                                            size="1.8rem" 
                                            weight="600" 
                                            lh="22px" 
                                            ls="-0.28px" 
                                            color={colors.Gr05}
                                        >
                                            {`인원 : `}
                                        </Text>

                                        <Text 
                                            ta="right" 
                                            size="1.8rem" 
                                            weight="500" 
                                            lh="22px" 
                                            ls="-0.28px" 
                                            color={colors.Gr05}
                                            margin={`0 0 0 10px`}
                                            wrap={`wrap`}
                                        >
                                            {`${data?.payment?.people}인`}
                                        </Text>
                                    </Wrapper>

                                    <Wrapper style={{margin : '15px 0'}} height={`2px`} width={`100%`} bgc={colors.Gr01}/>
                                </>
                            : 
                                <></>
                        }

                        <Wrapper
                            width={`100%`}
                            jc={'space-between'}
                        >
                            <Text 
                                ta="left" 
                                size="1.8rem" 
                                weight="600" 
                                lh="22px" 
                                ls="-0.28px" 
                                color={colors.Gr05}
                            >
                                {`장소 : `}
                            </Text>

                            <Text 
                                ta="right" 
                                size="1.8rem" 
                                weight="500" 
                                lh="22px" 
                                ls="-0.28px" 
                                color={colors.Gr05}
                                margin={`0 0 0 10px`}
                                wrap={`wrap`}
                            >
                                {`${data?.payment?.address} ${data?.payment?.ect_address}`}
                            </Text>
                        </Wrapper>

                        <Wrapper style={{margin : '15px 0'}} height={`2px`} width={`100%`} bgc={colors.Gr01}/>

                        <Wrapper
                            width={`100%`}
                            jc={'space-between'}
                        >
                            <Text 
                                ta="left" 
                                size="1.8rem" 
                                weight="600" 
                                lh="22px" 
                                ls="-0.28px" 
                                color={colors.Gr05}
                            >
                                {`시작일 : `}
                            </Text>

                            <Text 
                                ta="right" 
                                size="1.8rem" 
                                weight="500" 
                                lh="22px" 
                                ls="-0.28px" 
                                color={colors.Gr05}
                                margin={`0 0 0 10px`}
                                wrap={`wrap`}
                            >
                                {`${data?.payment?.start_date}`}
                            </Text>
                        </Wrapper>

                        <Wrapper style={{margin : '15px 0'}} height={`2px`} width={`100%`} bgc={colors.Gr01}/>

                        {
                            (data?.payment?.type == '정기 레슨' || data?.payment?.type == '이용권(기간)' || data?.payment?.type == '이용권(횟수)') ?
                                <>
                                    <Wrapper
                                        width={`100%`}
                                        jc={'space-between'}
                                    >
                                        <Text 
                                            ta="left" 
                                            size="1.8rem" 
                                            weight="600" 
                                            lh="22px" 
                                            ls="-0.28px" 
                                            color={colors.Gr05}
                                        >
                                            {`종료일 : `}
                                        </Text>

                                        <Text 
                                            ta="right" 
                                            size="1.8rem" 
                                            weight="500" 
                                            lh="22px" 
                                            ls="-0.28px" 
                                            color={colors.Gr05}
                                            margin={`0 0 0 10px`}
                                            wrap={`wrap`}
                                        >
                                            {`${data?.payment?.end_date}`}
                                        </Text>
                                    </Wrapper>

                                    <Wrapper style={{margin : '15px 0'}} height={`2px`} width={`100%`} bgc={colors.Gr01}/>
                                </>
                            : 
                                <></>
                        }

                        <Wrapper
                            width={`100%`}
                            jc={'flex-end'}
                            gap={`12px`}
                        >
                            <Wrapper
                                onClick={() => {
                                    navigate(`/reservation_detail/${data?.payment?.id}`, {
                                        state: {
                                            from: "",
                                        },
                                    })
                                }}
                                jc="center"
                                ai="center"
                                width="auto"
                                cursor={"pointer"}
                                radius="8px"
                                bgc={colors.Bl07}
                                padding={`8px 8px`}
                            >
                                <Text 
                                    ta="center" 
                                    size="1.4rem" 
                                    weight="600" 
                                    lh="22px" 
                                    ls="-0.28px" 
                                    color={colors.White}
                                >
                                    {`예약 정보 더보기`}
                                </Text>
                            </Wrapper>
                        </Wrapper>
                        
                    </Wrapper>
                : 
                    <Wrapper
                        jc="flex-start"
                        ai="center"
                        fd={`column`}
                        width="100%"
                        padding={`0 24px 0 24px`}
                    >
                        <Wrapper
                            width={`100%`}
                            jc={'space-between'}
                        >
                            <Text 
                                ta="left" 
                                size="1.8rem" 
                                weight="600" 
                                lh="22px" 
                                ls="-0.28px" 
                                color={colors.Gr05}
                            >
                                {`등록된 예약이 없습니다.`}
                            </Text>
                        </Wrapper>

                        <Wrapper
                            width={`100%`}
                            jc={'flex-end'}
                        >
                            <Wrapper
                                onClick={() => {
                                    setManageT(true)
                                    setDataT({
                                        crew_id : data?.crew_id
                                    })
                                }}
                                jc="center"
                                ai="center"
                                width="auto"
                                cursor={"pointer"}
                                radius="8px"
                                bgc={colors.Bl07}
                                padding={`8px 8px`}
                            >
                                <Text 
                                    ta="center" 
                                    size="1.4rem" 
                                    weight="600" 
                                    lh="22px" 
                                    ls="-0.28px" 
                                    color={colors.White}
                                >
                                    {`예약 생성하기`}
                                </Text>
                            </Wrapper>
                        </Wrapper>

                        <Wrapper style={{margin : '15px 0'}} height={`2px`} width={`100%`} bgc={colors.Gr01}/>
                    </Wrapper>
            }

            <BottomWrap>
                <Wrapper
                    onClick={() => console.log()}
                    jc="center"
                    ai="center"
                    width="calc(50% - 6px)"
                    height="52px"
                    cursor={"pointer"}
                    radius="8px"
                    bgc={colors.Gr03}
                >
                    <Text 
                        ta="center" 
                        size="1.4rem" 
                        weight="600" 
                        lh="22px" 
                        ls="-0.28px" 
                        color={colors.White}
                    >
                        {`문자하기`}
                    </Text>
                </Wrapper>

                <Wrapper
                    onClick={() => console.log()}
                    jc="center"
                    ai="center"
                    width="calc(50% - 6px)"
                    height="52px"
                    cursor={"pointer"}
                    radius="8px"
                    bgc={colors.Bl07}
                >
                    <Text 
                        ta="center" 
                        size="1.4rem" 
                        weight="600" 
                        lh="22px" 
                        ls="-0.28px" 
                        color={colors.White}
                    >
                        {`전화하기`}
                    </Text>
                </Wrapper>
            </BottomWrap>

            {
                manageT ?
                    <MakeReservation
                        modal={manageT}
                        setModal={setManageT}
                        title={`예약 생성`}
                        data={dataT}
                        GetData={GetData}
                        setLoading={setLoading}
                        activityList={activityList}
                        consult_id={params.id}
                    />
                :
                <></>
            }

            {loading && <Loading />}

        </Container>
    );
};

export default ConsultDetail;

const Container = styled.section`
    height: calc(var(--vh, 1vh) * 100);
    overflow-x: hidden;
    width: 100%;
    padding-bottom : 150px;
`;

const BottomWrap = styled.div`
    width: 100%;
    max-width: 900px;
    transform : translate(-50%, 0);
    display: flex;
    justify-content: space-between;
    padding: 12px 24px;
    border-top: 1px solid ${colors.Gr01};
    background-color: ${colors.White};
    position: fixed;
    bottom : 0;
    left : 50%;
    z-index: 10;
`;

const TipWrap = styled.p`
    word-break: keep-all;
    font-size: 14px;
    font-weight: 500;
    line-height: 22px;
    letter-spacing: -0.28px;
    color: ${colors.Gr03};
    width : 100%;
    .tip {
        color: ${colors.Bl05};
    }
    .red {
        color: ${colors.Red03};
    }
`;