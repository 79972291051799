import React, { useState, useEffect } from "react";
import styled from "styled-components";
import Sheet from "react-modal-sheet";

//style
import { W, WIDTH } from "../../styles/globalStyles";
import colors from "../../styles/colors";

//img
import { ReactComponent as ModalCloseIcon } from "../../resource/icon/close_circle.svg";
import { ReactComponent as Onsite } from "../../resource/icon/moneysign_onSite.svg";
import { ReactComponent as PG } from "../../resource/icon/moneysign_pg.svg";
import { ReactComponent as BangMark } from "../../resource/icon/bang_mark.svg";

const TITLE = "결제방법";
const BUTTON = "확인";

const PAYMENT_TYPE = [
  {
    type: "바로결제",
    typeColor: colors.Bl07,
    icon: "PG",
    content: [
      {
        subTitle: "앱 내 바로 결제 (기존의 결제 방식)",
        subContent: "세모스 플랫폼에서 결제하는 방식이에요. 세모스의 정산 정책에 따라 정산일에 바로결제 금액을 정산받을 수 있어요.",
      },
    ],
  },
  {
    type: "현장결제",
    typeColor: colors.Green03,
    icon: "Onsite",
    content: [
      {
        subTitle: "수수료 및 예약금 없음",
        subContent: "서비스 이용 수수료를 받지 않는 결제 방식이나, 크루님께 받는 예약금이 없으니 예약 확정시 주의해주세요.",
      },
      { subTitle: "예약 후 개별 안내", subContent: "액티비티 진행 전 파트너님이 직접 결제방법에 대해 안내해주셔야 해요." },
    ],
  },
];

const CAUTION = {
  title: "현장결제 진행시 주의해주세요!",
  content: [
    "세모스에 기재한 가격 외 추가 결제 요청 시 리뷰 평점이 낮아질 수 있어요.",
    "결제 후 액티비티를 진행해주세요.",
    "크루님에게 결제에 대한 안내를 해주세요.",
    "사전에 고지되지 않은 불포함사항은 크루님께 요구하실 수 없어요.",
  ],
};

const PaymentTypeInfoModal = ({ open, setOpen }) => {
  return (
    <BackButtonModal
      style={{ width: "100%", display: "flex", justifyContent: "center", left: "50%", transform: "translate(-50%,0)" }}
      isOpen={open}
      snapPoints={[0.85]}
    >
      <BackButtonModal.Container style={{ width: `${W}px`, left: "none" }}>
        <ModalLayout>
          <ModalFirstLine>
            <ModalTitleContainer>
              <ModalTitle>{TITLE}</ModalTitle>
            </ModalTitleContainer>
            <ModalCloseIcon onClick={() => setOpen(false)} width="28px" height="28px" fill={colors.Gr03} style={{ cursor: "pointer" }} />
          </ModalFirstLine>
          <Wrap>
            {PAYMENT_TYPE.map((item, index) => (
              <PaymentTypeWrap key={item.type}>
                <TypeWrap>
                  {item.icon === "PG" ? <PG width="24px" height="24px" fill={item.typeColor} /> : <Onsite width="24px" height="24px" fill={item.typeColor} />}
                  <TypeText color={item.typeColor}>{item.type}</TypeText>
                </TypeWrap>
                {item.content.map((el, idx) => (
                  <SubWrap key={el.subTitle}>
                    <SubTitle>{el.subTitle}</SubTitle>
                    <SubContent>{el.subContent}</SubContent>
                  </SubWrap>
                ))}
              </PaymentTypeWrap>
            ))}
            <CautionBox>
              <CautionTitleWrap>
                <BangMark width="20px" height="20px" fill={colors.Red03} />
                <span>{CAUTION.title}</span>
              </CautionTitleWrap>
              <CautionContentWrap>
                {CAUTION.content.map((item, index) => (
                  <div key={`caution_${index}`}>
                    <span className="dot">·</span>
                    <span>{item}</span>
                  </div>
                ))}
              </CautionContentWrap>
            </CautionBox>
          </Wrap>

          <SubmitWrap>
            <SubmitButton
              onClick={() => {
                setOpen(false);
              }}
            >
              {BUTTON}
            </SubmitButton>
          </SubmitWrap>
        </ModalLayout>
      </BackButtonModal.Container>
      <BackButtonModal.Backdrop />
    </BackButtonModal>
  );
};

export default PaymentTypeInfoModal;

const BackButtonModal = styled(Sheet)``;

const ModalLayout = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  /* justify-content: space-between; */
`;

const ModalFirstLine = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 24px 24px 12px 24px;
`;

const ModalTitleContainer = styled.div`
  display: flex;
  /* align-items: flex-end; */
  align-items: center;
`;

const ModalTitle = styled.div`
  font-weight: 600;
  font-size: 18px;
  line-height: 142%;
  letter-spacing: -0.02em;
  color: #1a1c1f;
  margin-right: 12px;
`;

const Wrap = styled.div`
  padding: 12px 24px 100px 24px;
  overflow: auto;
  gap: 24px;
  display: flex;
  flex-direction: column;
`;
const SubWrap = styled.div`
  display: flex;
  flex-direction: column;
`;

const SubmitWrap = styled.div`
  padding: 12px 24px;
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  background-color: ${colors.White};
  display: flex;
  justify-content: center;
  align-items: center;
`;

const SubmitButton = styled.div`
  height: 56px;
  width: 100%;
  border-radius: 8px;
  background-color: ${colors.Bl07};
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  color: ${colors.White};
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: -0.32px;
`;

const PaymentTypeWrap = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const TypeWrap = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;
`;

const TypeText = styled.span`
  word-break: keep-all;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px; /* 150% */
  letter-spacing: -0.32px;
  color: ${({ color }) => (color ? color : `${colors.Gr05}`)};
`;

const SubTitle = styled.span`
  word-break: keep-all;
  font-size: 14px;
  font-weight: 600;
  line-height: 22px;
  color: ${colors.Gr05};
`;

const SubContent = styled.span`
  word-break: keep-all;
  font-size: 14px;
  font-weight: 500;
  line-height: 22px;
  letter-spacing: -0.28px;
  color: ${colors.Gr04};
`;

const CautionBox = styled.div`
  background-color: ${colors.Gr01};
  padding: 16px;
  border-radius: 8px;
`;

const CautionTitleWrap = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  span {
    word-break: keep-all;
    font-size: 14px;
    font-weight: 600;
    line-height: 22px;
    color: ${colors.Gr05};
  }
`;

const CautionContentWrap = styled.div`
  display: flex;
  flex-direction: column;
  div {
    display: flex;
    flex-direction: row;
  }
  span {
    word-break: keep-all;
    font-size: 14px;
    font-weight: 500;
    line-height: 22px;
    letter-spacing: -0.28px;
    color: ${colors.Gr04};
  }
  .dot {
    font-size: 25px;
    margin-right: 7px;
    margin-left: 7px;
  }
`;
