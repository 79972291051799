import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
//import axios from "axios";

// tool
import { KakaoLoginAxios } from "../tool/login";

function LoginCheck() {
  const [popup, setPopup] = useState({
    confirmPopup: "hide",
    confirmText: "",
    confirmLink: "",
    confirmType: "",
  });

  const navigate = useNavigate();

  useEffect(() => {
    const current = decodeURI(window.location.href);
    KakaoLoginAxios(current, navigate)
  }, []);

  if (popup.confirmType == "confirm") {
    return (
      <BackgroundViewWhiteNotice>
        <BackgroundViewWhiteContent>
          <PayPopUpContainer>
            <PayPopUpContent className="semos_color_black_real semos_font_weight_700 semos_big_content">
              알림
            </PayPopUpContent>

            <PayPopUpContent
              style={{ margin: "0", lineHeight: "1.4" }}
              className="semos_color_black_real semos_font_weight_400 semos_small_content"
            >
              <PayPopUpContentText>{popup.confirmText}</PayPopUpContentText>
            </PayPopUpContent>
          </PayPopUpContainer>

          <PayPopUpYes
            onClick={this.confirmHide}
            style={{ borderRight: ".1rem solid #ffffff" }}
            className="semos_color_white semos_background_color_blue semos_small_content semos_font_weight_400"
          >
            취소
          </PayPopUpYes>

          <PayPopUpNO
            onClick={this.reportUpdate}
            className="semos_color_white semos_background_color_blue semos_small_content semos_font_weight_400"
          >
            확인
          </PayPopUpNO>
        </BackgroundViewWhiteContent>
      </BackgroundViewWhiteNotice>
    );
  }
}

export default LoginCheck;

// styled-component
const BackgroundViewWhiteNotice = styled.section`
  width: 100%;
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  background-color: #00000085;
  display: inline-flex;
  align-items: flex-end;
`;

const BackgroundViewWhiteContent = styled.div`
  width: 90%;
  max-width: 38rem;
  position: absolute;
  display: inline-flex;
  flex-wrap: wrap;
  top: 50%;
  left: 50%;
  z-index: 102;
  transform: translate(-50%, -50%);
  background-color: #ffffff;
  border-radius: 8px;
  overflow: hidden;
`;

const PayPopUpContainer = styled.div`
  width: 100%;
  padding: 4rem;
  display: inline-block;
`;

const PayPopUpClose = styled.span`
width : 100%;
padding : 2rem 0;
display : inline-flex;
align-items : center;
justify-content : center;
cursor : pointer;
border-radius : 0 0 8px 8px;

@media screen and (max-width: 800px) {
  padding : 1.5rem 0;
}
`;

const PayPopUpYes = styled.span`
width : 50%;
padding : 2rem 0;
display : inline-flex;
align-items : center;
justify-content : center;
cursor : pointer;
border-radius : 0 0 0 8px;

@media screen and (max-width: 800px) {
  padding : 1.5rem 0;
}
`;

const PayPopUpNO = styled.span`
width : 50%;
padding : 2rem 0;
display : inline-flex;
align-items : center;
justify-content : center;
cursor : pointer;
border-radius : 0 0 8px 0;

@media screen and (max-width: 800px) {
  padding : 1.5rem 0;
}
`;

const PayPopUpContent = styled.span`
  width: 100%;
  margin-bottom: 2rem;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
`;

const PayPopUpCheckIcon = styled.img`
  width: 5rem;
  display: inline-block;
`;

const PayPopUpContentText = styled.span`
  width: 100%;
  display: inline-flex;
  align-items: center;
  justify-content: center;
`;
