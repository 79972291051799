import React from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";

import BackArrow from "../../icon/backArrow.png";
import colors from "../../styles/colors";

function BackHeader({ title, page, setPage }) {
  const navigate = useNavigate();

  const backFunc = () => {
    if (page == 2) {
      setPage(1);
    }
  };

  return (
    <Container>
      <Arrow hiddenO={page == 1} src={BackArrow} onClick={() => backFunc()} />
      <HeaderTitle>{title}</HeaderTitle>
      <Box />
    </Container>
  );
}

export default BackHeader;

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px 22px;
  box-shadow: 0px 3px 8px ${colors.B10};
`;

const Arrow = styled.img`
  width: 24px;
  height: 24px;
  opacity: ${({ hiddenO }) => (hiddenO ? "0" : "1")};
`;

const HeaderTitle = styled.div`
  font-size: 18px;
  font-weight: 600;
  line-height: 25.56px;
  color: #1a1c1f;
`;

const Box = styled.div`
  width: 24px;
  height: 24px;
`;
