import React, { useEffect, useState } from "react";
import styled from "styled-components";
import axios from "axios";

//components
import Header from "../components/basic/Header";
import AlarmItem from "../components/alarm/alarm_item";
import { AlarmCategory } from "../tool/random_text";
import { GetOne } from "../tool/localstorage";
// import Footer from "../components/basic/footer";
import { LoadingSpinner } from "../tool/loading";

function AlarmList() {
  const [loading, setLoading] = useState(true);
  const [alarm, setAlarm] = useState();
  const [alarmCount, setAlarmCount] = useState(0);

  useEffect(() => {
    var token = GetOne("token");

    const frm = new FormData();
    frm.append("mode", "alarm");
    frm.append("token", token);

    axios
      .post("https://ai.semos.kr/semos_partner/auth/profile", frm, {
        headers: { "Content-Type": "multipart/form-data" },
      })
      .then((data) => {
        var Value = data.data;
        var Status = Value.status;
        var StatusMessage = Value.status_message;
        var AlarmList = Value.alarm_list;
        var AlarmCount = Value.alarm_count;

        if (Status == "success") {
          setAlarm(AlarmList);
          setAlarmCount(AlarmCount);
          setLoading(false);
        } else {
          window.alert(StatusMessage);
          setLoading(false);
        }
      });
  }, []);

  return (
    <Container>
      <Header title="알림" back={true} />
      <SubTitle>
        <Styler
          pt={14}
          pb={14}
          pr={24}
          pl={24}
          flex={true}
          justify="space-between"
        >
          <NeedCheckText>확인이 필요한 알림</NeedCheckText>
          <NeedCheck>{`${alarmCount}개`}</NeedCheck>
        </Styler>
      </SubTitle>

      {alarm &&
        alarm.map((item, idx) => (
          <AlarmItem
            key={`alarm_${idx}`}
            category={AlarmCategory[Number(item.category)]}
            data={item}
          />
        ))}

      <div style={{ height: "40px" }} />
      {/* <div style={{ height: "83px" }} /> */}
      {/* <Footer /> */}

      <LoadingSpinner
        loading={loading}
        title={`정보를 확인하고 있습니다.`}
        content={`잠시만 기다려 주세요!`}
      />
    </Container>
  );
}

export default AlarmList;

const Container = styled.section`
  height: calc(var(--vh, 1vh) * 100);
  overflow-x: hidden;
`;

const SubTitle = styled.div`
  border-bottom: 1px solid #f5f5f5;
`;

const Styler = styled.div`
  position: relative;
  display: ${({ flex }) => (flex ? "flex" : "")};
  flex-direction: ${({ direction }) => direction};
  justify-content: ${({ justify }) => justify};
  align-items: ${({ align }) => align};
  padding-top: ${({ pt }) => pt}px;
  padding-bottom: ${({ pb }) => pb}px;
  padding-left: ${({ pl }) => pl}px;
  padding-right: ${({ pr }) => pr}px;
`;

const NeedCheckText = styled.div`
  font-size: 14px;
  font-weight: 500;
  line-height: 142%;
  color: #818792;
  letter-spacing: -0.02em;
`;

const NeedCheck = styled.div`
  font-weight: 500;
  font-size: 14px;
  line-height: 142%;
  letter-spacing: -0.02em;
  color: #0e6dff;
`;
