import React, { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";

// style
import colors from "../../styles/colors";
import { Wrapper, Text } from "../layouts/layoutStorage";

//icon
import { ReactComponent as Arrow } from "../../resource/icon/arrow.svg";

const GenderText = {
    1 : "남",
    2 : "여",
    3 : "법인"
};

const AliveText = {
    0 : "만료",
    1 : "활성",
}

const FacilityCrew = ({ data }) => {
    
    const navigate = useNavigate();

    const crewRef = useRef();

    const [dropdown, setDropdown] = useState({
        crew: false
    });

    return (
        <Wrapper 
            ref={crewRef}
            wrap={`wrap`} 
            width="100%" 
            ai="flex-start" 
            bb={`1px solid ${colors.Gr02}`}
            padding="7px 24px"
        >
            <Wrapper
                width="100%" 
                wrap={`nowrap`} 
                ai="center" 
                jc="space-between" 
                padding={`.5rem 0`}
            >
                <Text 
                    onClick={() => {
                        navigate(`/crew_detail_coach/${data.id}`)
                    }}
                    size="1.8rem" 
                    weight="600" 
                    lh="22px" 
                    ls="-0.28px" 
                    width={`calc(100% - 4rem)`}
                    color={(data.status == 0) ? 
                        colors.Gr02 : 
                        (data.join_complete == false) ? colors.Red03 : colors.Gr05
                    }
                    style={{
                        textDecoration : "underline",
                        maxWidth : `calc(100% - 6rem)`,
                        cursor : 'pointer'
                    }}
                >   
                    {
                        (data.join_complete == false) ?
                            `[${AliveText[data.status]}] ${data.name} | 정보 입력 필요`
                        : 
                            `[${AliveText[data.status]}] ${data.name} | ${GenderText[data.gender]} | ${data.birth_y}.${data.birth_m}.${data.birth_d} (${data.korean_age}세) `
                    }
                </Text>

                <Arrow
                    onClick={(e) => {
                        e.stopPropagation();
                        setDropdown({
                            crew: !dropdown.crew
                        });
                    }}
                    width="3rem"
                    height="3rem"
                    fill={colors.Gr04}
                    style={{ 
                        transition: "all ease 0.5s", 
                        transform: dropdown.crew ? "rotate(-90deg)" : "rotate(90deg)" 
                    }}
                />
            </Wrapper>
            
            {
                dropdown.crew ?
                    <Wrapper
                        display={`inline-flex`}
                        width="100%" 
                        wrap={`wrap`} 
                        ai="flex-start"
                        padding={`.5rem 0`}
                        bt={`.1rem solid ${colors.Gr01}`}
                    >
                        <Wrapper
                            display={`inline-block`}
                            width="100%"
                            padding={`0.3rem .3rem 0.3rem 0`}
                            jc={`flex-start`}
                            ai="center"
                        >
                            <Text 
                                size="1.6rem"
                                weight="500" 
                                lh="142%" 
                                ls="-0.28px" 
                                color={colors.Gr04} 
                                to={`ellipsis`}
                            >
                                {`전화번호 : `}
                                <a  
                                    style={{
                                        marginLeft : 5, 
                                        color : colors.Gr04, 
                                        textDecoration: 'underline',
                                        lineHeight : '142%'
                                    }}
                                    href={`tel:${data.phone}`}>
                                    {`${data.phone}`}
                                </a>
                            </Text>
                        </Wrapper>

                        {/*<Wrapper
                            display={`inline-block`}
                            width="50%"
                            padding={`0.3rem .3rem 0.3rem 0`}
                            jc={`flex-start`}
                            ai="center"
                        >
                            <Text 
                                size="1.6rem"
                                weight="500" 
                                lh="142%" 
                                ls="-0.28px" 
                                color={colors.Gr04} 
                                to={`ellipsis`}
                            >
                                {`차량번호 : ${data.car_number ? data.car_number : "미입력"}`}
                            </Text>
                        </Wrapper>

                        <Wrapper
                            display={`inline-block`}
                            width="50%"
                            padding={`0.3rem .3rem 0.3rem 0`}
                            jc={`flex-start`}
                            ai="center"
                        >
                            <Text 
                                size="1.6rem"
                                weight="500" 
                                lh="142%" 
                                ls="-0.28px" 
                                color={colors.Gr04} 
                                to={`ellipsis`}
                            >
                                {`차종 : ${data.car_type ? data.car_type : "미입력"}`}
                            </Text>
                        </Wrapper>*/}

                        <Wrapper
                            display={`inline-block`}
                            width="50%"
                            padding={`0.3rem .3rem 0.3rem 0`}
                            jc={`flex-start`}
                            ai="center"
                        >
                            <Text 
                                size="1.6rem"
                                weight="500" 
                                lh="142%" 
                                ls="-0.28px" 
                                color={colors.Gr04} 
                                to={`ellipsis`}
                            >
                                {`이용 동의 : ${data.confirm == 1 ? "동의" : "미동의"}`}
                            </Text>
                        </Wrapper>

                        <Wrapper
                            display={`inline-block`}
                            width="50%"
                            padding={`0.3rem .3rem 0.3rem 0`}
                            jc={`flex-start`}
                            ai="center"
                        >
                            <Text 
                                size="1.6rem"
                                weight="500" 
                                lh="142%" 
                                ls="-0.28px" 
                                color={colors.Gr04} 
                                to={`ellipsis`}
                            >
                                {`세모스 가입 : ${data.semos_account ? "가입" : "미가입"}`}
                            </Text>
                        </Wrapper>

                    </Wrapper>
                : ""
            }

        </Wrapper>
    )
}

export default FacilityCrew;