export function IPhone() {
  var iPhone_check = navigator.userAgent.indexOf("SemosPartnerIos") > -1;
  var iOS_check = /iPhone|iPad|iPod|Mac OS/i.test(navigator.userAgent);

  if(iPhone_check && iOS_check) {
    return true;
  } else {
    return false;
  }
}

export function IPad() {
  var iOS_check = navigator.userAgent.indexOf("SemosPartnerIos") > -1;
  var pad_check = /iPhone|iPad|iPod|Android|Mac OS/i.test(navigator.userAgent);
  var touchPoints = navigator.maxTouchPoints == 5;

  if(iOS_check && pad_check && touchPoints) {
    return true;
  } else {
    return false;
  }
}

export function Android() {
  var Android_check = navigator.userAgent.indexOf("SemosPartnerAndroid") > -1;

  if(Android_check) {
    return true;
  } else {
    return false;
  }
}


export function Mobile() {
  var Mobile_check = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

  if(Mobile_check) {
    return true;
  } else {
    return false;
  }
}