import React, { useState, useEffect } from "react";
import styled from "styled-components";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

// tool
import { kakaoLoginHandler, AppleLoginHandler } from "../tool/login";
import { Android } from "../tool/device_check";
import { GetOne, SaveMany } from "../tool/localstorage";
import SemosLoginModal from "../components/signup/SemosLogin";
import { fbevent } from "../tool/firebase";

// style
import colors from "../styles/colors";
import { H } from "../styles/globalStyles";

// img
import KakaoLogo from "../icon/kakaoLogo.png";
import GoogleLogo from "../icon/googlelogin.png";
import NaverLogo from "../icon/naver.png";
import PartnerLogo from "../icon/partner_logo.png";
import LoginSplash01 from "../icon/login_splash_01.png";
import LoginSplash02 from "../icon/login_splash_02.png";
import LoginSplash03 from "../icon/login_splash_03.png";
import LoginSplash04 from "../icon/login_splash_04.png";
import SemosLogin from "../icon/login_splash_05.png";

const DesignSize = H / 812;

const SPLASH = [
  {
    title: "간단한 프로그램 등록",
    content: "내 일정 설정하고 \n프로그램 등록만 하면 끝!",
    img: LoginSplash01,
  },
  {
    title: "자동화된 고객 관리로 시간 절약",
    content:
      "내 손 들여가며 신경쓰지 마세요 \n예약부터 리뷰까지 세모스 파트너가 책임져요",
    img: LoginSplash02,
  },
  {
    title: "빠른 스케줄 관리",
    content: "일정 휴무 등록 기능으로 \n일일이 일정 조율하지 않아도 돼요",
    img: LoginSplash03,
  },
  {
    title: "파트너의 도우미",
    content:
      "수업에만 집중하고 싶다면? \n전담 코디로 입점부터 등록까지 맡겨보세요",
    img: LoginSplash04,
  },
];

function Login() {
  const params = useParams();
  const navigate = useNavigate();

  const [google, setGoogle] = useState(false);
  const [apple, setApple] = useState(false);
  const [semosLogin, setSemosLogin] = useState(false);

  const AndroidCheck = Android();
  const redirect_uri = window.location.origin;
  const client_id = process.env.REACT_APP_google_client_id;
  const googleKeyCode = window.location.search?.split("&")[1]?.split("=")[1];

  // SPLASH SLIDE SETTINGS
  const carouselSettings = {
    arrows: false,
    dots: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2500,
  };

  useEffect(() => {
    setApple(true);
    GoogleLoginCheck();

    const token = GetOne("token");
    const login_type = GetOne("login_type");
    const age = GetOne("age");
    const gender = GetOne("gender");
    const nickname = GetOne("nickname");
    const email = GetOne("email");
    const name = GetOne("name");
    const phone = GetOne("phone");

    fbevent("B2B_Login_Page_View", {
      email : email,
      name : name,
    })

    const frm = new FormData();

    frm.append("mode", "login_check");
    frm.append("token", token);

    axios
      .post("https://ai.semos.kr/semos_partner/auth/profile", frm, {
        headers: {
          "Content-Type": "multipart/form-data",
          Accept: "application/json",
        },
      })
      .then((data) => {
        const Value = data.data;
        const LoginStatus = Value.login_status;
        const DataCheck =
          login_type && age && gender && nickname && email && name && phone;

        if (LoginStatus && DataCheck) {
          window.location.replace("/home");
        }
      });
  }, []);

  useEffect(() => {
    NaverLogin();
  }, []);

  if (googleKeyCode) {
    axios
      .post(
        "https://oauth2.googleapis.com/token",
        `code=${googleKeyCode}&client_id=${client_id}&client_secret=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&redirect_uri=${redirect_uri}&grant_type=authorization_code`,
        {
          headers: { "Content-Type": "application/x-www-form-urlencoded" },
        }
      )
      .then((data) => {

        const frm = new FormData();

        frm.append("mode", "google_login_check_app");
        frm.append("google_code", data.data.id_token);

        axios
          .post("https://ai.semos.kr/semos_partner/auth/login", frm, {
            headers: { "Content-Type": "multipart/form-data" },
          })
          .then((data) => {
            const Value = data.data;
            const Status = Value.login_status;
            const Token = Value.login_token;
            const UserData = Value.user_data;

            if (Status === "success") {
              const status = UserData.partner_status
                ? UserData.partner_status
                : null;
              const nickname = UserData.user_name;
              const name = UserData.user_real_name;
              const age = UserData.user_age;
              const profile = UserData.profile_img;
              const phone = UserData.user_phone_number;
              const gender = UserData.user_gender;
              const email = UserData.user_email;

              const LocalStorageData = [
                { type: "partner_status", value: status },
                { type: "nickname", value: nickname },
                { type: "name", value: name },
                { type: "age", value: age },
                { type: "profile", value: profile },
                { type: "phone", value: phone },
                { type: "gender", value: gender },
                { type: "email", value: email },
                { type: "token", value: Token },
                { type: "login_type", value: "카카오" },
              ];

              if (SaveMany(LocalStorageData)) {
                if (!status || status === "4") {
                  navigate("/partner_tip");
                } else {
                  navigate("/home");
                }
              }
            } else if (Status === "not_complete") {
              const status = UserData.partner_status;
              const nickname = UserData.user_name;
              const name = UserData.user_real_name;
              const age = UserData.user_age;
              const profile = UserData.profile_img;
              const phone = UserData.user_phone_number;
              const gender = UserData.user_gender;
              const email = UserData.user_email;

              const LocalStorageData = [
                { type: "partner_status", value: status },
                { type: "nickname", value: nickname },
                { type: "name", value: name },
                { type: "age", value: age },
                { type: "profile", value: profile },
                { type: "phone", value: phone },
                { type: "gender", value: gender },
                { type: "email", value: email },
                { type: "token", value: Token },
                { type: "login_type", value: "카카오" },
              ];

              if (SaveMany(LocalStorageData)) {
                //navigate("/partner_register_new/0", {replace: true});
                navigate("/partner_register");
              }
              /*window.alert('미등록된 파트너입니다.\n등록 상담 신청을 통해 파트너 등록을 완료해주세요!')
              window.location.href = 'https://forms.gle/mpMXYxmcxyXetueB8'*/
            } else if (Status === "other_user") {
              window.alert(
                "동일한 이메일로 가입된 계정이 있습니다.\n해당 아이디로 로그인해주세요!"
              );
              navigate("/");
            } else {
              window.alert(
                "로그인이 실패했습니다.\n로그인 실패가 반복된다면 고객센터로 문의해주세요!"
              );
              navigate("/");
            }
          });
      });
  }

  const GoogleLoginCheck = () => {
    setGoogle(true);

    if (!google) {
      const script = document.createElement("script");
      script.async = true;
      script.src = "https://apis.google.com/js/api:client.js";
      document.head.appendChild(script);

      script.onload = () => {
        window.gapi.load("auth2", () => {
          // Retrieve the singleton for the GoogleAuth library and set up the client.
          var authCheck = window.gapi.auth2.init({
            client_id:
              "731129506416-n5o9e0moq0qce4s4i2qt5p70pvd3u5bt.apps.googleusercontent.com",
            cookiepolicy: "single_host_origin",
            uxMode: "redirect",
            redirectUri: `${window.location.origin}/login_check2`,
            // Request scopes in addition to 'profile' and 'email'
            //scope: 'additional_scope'
          });
          var element = document.getElementById("googleLogin");
          authCheck.attachClickHandler(
            element,
            {},
            (googleUser) => {
              fbevent("B2B_Login_Google_Click", {});

              var googleToken = googleUser.getAuthResponse().id_token;

              const frm = new FormData();

              frm.append("mode", "google_login_check");
              frm.append("google_code", googleToken);

              axios
                .post("https://ai.semos.kr/semos_partner/auth/login", frm, {
                  headers: { "Content-Type": "multipart/form-data" },
                })
                .then((data) => {
                  const Value = data.data;
                  const Status = Value.login_status;
                  const Token = Value.login_token;
                  const UserData = Value.user_data;

                  if (Status === "success") {
                    const status = UserData.partner_status
                      ? UserData.partner_status
                      : null;
                    const nickname = UserData.user_name;
                    const name = UserData.user_real_name;
                    const age = UserData.user_age;
                    const profile = UserData.profile_img;
                    const phone = UserData.user_phone_number;
                    const gender = UserData.user_gender;
                    const email = UserData.user_email;

                    const LocalStorageData = [
                      { type: "partner_status", value: status },
                      { type: "nickname", value: nickname },
                      { type: "name", value: name },
                      { type: "age", value: age },
                      { type: "profile", value: profile },
                      { type: "phone", value: phone },
                      { type: "gender", value: gender },
                      { type: "email", value: email },
                      { type: "token", value: Token },
                      { type: "login_type", value: "구글" },
                    ];

                    if (SaveMany(LocalStorageData)) {
                      if (!status || status === "4") {
                        navigate("/partner_tip");
                      } else {
                        navigate("/home");
                      }
                    }
                  } else if (Status === "not_complete") {
                    const status = UserData.partner_status
                      ? UserData.partner_status
                      : null;
                    const nickname = UserData.user_name;
                    const name = UserData.user_real_name;
                    const age = UserData.user_age;
                    const profile = UserData.profile_img;
                    const phone = UserData.user_phone_number;
                    const gender = UserData.user_gender;
                    const email = UserData.user_email;

                    const LocalStorageData = [
                      { type: "partner_status", value: status },
                      { type: "nickname", value: nickname },
                      { type: "name", value: name },
                      { type: "age", value: age },
                      { type: "profile", value: profile },
                      { type: "phone", value: phone },
                      { type: "gender", value: gender },
                      { type: "email", value: email },
                      { type: "token", value: Token },
                      { type: "login_type", value: "구글" },
                    ];

                    if (SaveMany(LocalStorageData)) {
                      //navigate("/partner_register_new/0", {replace: true});
                      navigate("/partner_register");
                    }
                    /*window.alert('미등록된 파트너입니다.\n등록 상담 신청을 통해 파트너 등록을 완료해주세요!')
                    window.location.href = 'https://forms.gle/mpMXYxmcxyXetueB8'*/
                  } else if (Status === "other_user") {
                    window.alert(
                      "동일한 이메일로 가입된 계정이 있습니다.\n해당 아이디로 로그인해주세요!"
                    );
                    navigate("/");
                  } else {
                    window.alert(
                      "로그인이 실패했습니다.\n로그인 실패가 반복된다면 고객센터로 문의해주세요!"
                    );
                    navigate("/");
                  }
                });
            },
            (error) => {
              console.log(JSON.stringify(error, undefined, 2));
            }
          );
        });
      };
    }
  };

  const NaverLogin = () => {
    var naverLogin = new window.naver.LoginWithNaverId({
      clientId: "D1wg21cq27Y8kl0QN6Rx", //내 애플리케이션 정보에 cliendId를 입력해줍니다.
      callbackUrl: `${window.location.origin}/naver`, // 내 애플리케이션 API설정의 Callback URL 을 입력해줍니다.
      isPopup: false,
      callbackHandle: true,
    });

    naverLogin.init();
  };

  return (
    <Container>
      <Header>
        <img src={PartnerLogo} alt="semos-partner-logo" />
      </Header>

      <div>
        <SplashContainer>
          <Slider {...carouselSettings}>
            {SPLASH.map((val) => (
              <div key={val.title}>
                <img src={val.img} />
                <SplashTitle>{val.title}</SplashTitle>
                <SplashContent>{val.content}</SplashContent>
              </div>
            ))}
          </Slider>
        </SplashContainer>

        <Divider h={67} />

        {/* 로그인 툴팁 */}
        <TooltipContainer>
          <TooltipBox>
            <TooltipText>5초만에 회원가입/로그인하고 쉽고 빠르게 고객 만나기</TooltipText>
            <Triangle />
          </TooltipBox>
        </TooltipContainer>

        <Divider h={72} />

        {/* 로그인 버튼 */}
        <ButtonWrap>
          <KakaoButton onClick={() => kakaoLoginHandler()}>
            <LoginImg src={KakaoLogo} />
          </KakaoButton>
          <NaverButton id="naverIdLogin_loginButton">
            <LoginImg src={NaverLogo} />
          </NaverButton>
          <GoogleButton id="googleLogin">
            <LoginImg src={GoogleLogo} />
          </GoogleButton>
          {!AndroidCheck && AppleLoginHandler(apple)}
          <SemosButton onClick={() => {
            fbevent("B2B_Login_Facility_Click", {});
            setSemosLogin(true)
          }}>
            <LoginImg2 src={SemosLogin} />
          </SemosButton>
        </ButtonWrap>
      </div>

      {
        semosLogin ?
          <SemosLoginModal
            modal={semosLogin}
            setModal={setSemosLogin}
          />
        :
        <></>
      }
    </Container>
  );
}

export default Login;

const Container = styled.div`
  width: 100%;
  background-color: ${colors.White};
  height: calc(var(--vh, 1vh) * 100);
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-top: 24px;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    width: auto;
    height: 36px;
  }
`;

const ButtonWrap = styled.div`
  padding-left: 24px;
  padding-right: 24px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-bottom: ${65 * DesignSize}px;

  gap: 16px;
`;

const Divider = styled.div`
  height: ${({ h }) => h * DesignSize}px;
`;

const SplashContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 0 38px;

  .slick-slider {
    width: 298px;

    img {
      width: 100%;
    }
  }

  .slick-dots {
    li {
      width: 8px;
      height: 8px;

      button {
        width: 100%;
        height: auto;
      }
      button::before {
        color: ${colors.Gr01};
      }
    }
    .slick-active {
      button::before {
        color: ${colors.Gr03};
      }
    }
  }
`;

const SplashTitle = styled.h2`
  margin: 12px 0;
  color: ${colors.Gr06};
  text-align: center;
  line-height: 26px;
  letter-spacing: 0.02em;
  font-size: 18px;
  font-weight: 700;
`;

const SplashContent = styled.p`
  margin-bottom: 20px;
  white-space: pre-wrap;
  text-align: center;
  color: ${colors.Gr06};
  line-height: 22px;
  font-size: 14px;
  font-weight: 600;
`;

const TooltipContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin: auto;
  position: absolute;
`;

const TooltipBox = styled.div`
  padding: 12px;
  background-color: ${colors.White};
  border: 1px solid ${colors.Gr02};
  border-radius: 4px;
  filter: drop-shadow(0px 3px 16px rgba(0, 0, 0, 0.05))
    drop-shadow(0px 1px 11px rgba(0, 0, 0, 0.03));
`;

const TooltipText = styled.span`
  color: ${colors.Gr06};
  text-align: center;
  line-height: 22px;
  letter-spacing: -0.02em;
  font-size: 14px;
  font-weight: 500;
`;

const Triangle = styled.div`
  width: 10px;
  height: 10px;
  transform: rotate(45deg);
  border: 1px solid transparent;
  border-right-width: 1px;
  border-right-color: ${colors.Gr02};
  border-bottom-width: 1px;
  border-bottom-color: ${colors.Gr02};
  background-color: ${colors.White};
  position: absolute;
  z-index: 2;
  bottom: -5px;
  left: calc(50% - 5px);
`;

const Button = styled.div`
  width: ${52 * DesignSize}px;
  height: ${52 * DesignSize}px;
  /* padding: 15px 0; */
  /* margin-bottom: 16px; */
  border-radius: 50%;
  font-size: 16px;
  line-height: 22.72px;
  font-weight: 500;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

const LoginImg = styled.img`
  width: ${24 * DesignSize}px;
  height: ${24 * DesignSize}px;
`;

const LoginImg2 = styled.img`
  width: ${52 * DesignSize}px;
  height: ${52 * DesignSize}px;
`;

const KakaoButton = styled(Button)`
  background-color: ${colors.Kakao};
  color: ${colors.Gr06};
`;

const SemosButton = styled(Button)`
  background-color: ${colors.Bl07};
  color: ${colors.Bl07};
`;

const GoogleButton = styled(Button)`
  background-color: ${colors.White};
  border: 1px solid ${colors.Gr02};
  color: ${colors.Gr06};
`;

const NaverButton = styled(Button)`
  background-color: ${colors.Naver};
  color: ${colors.White};
`;
