import React from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";

//component
import colors from "../../styles/colors";

//icon
import { ReactComponent as Close } from "../../resource/icon/close.svg";
import Header from "../basic/Header";

const FacilityHeaderEnter = ({ status, title, step, setStep }) => {
    const navigate = useNavigate();
    
    const CheckNavigate = () => {        
        setStep(1)
    }

    return (
        <StNewHeader padding="8px 16px 10px 16px" gap="4px" jc="space-between" width="100%">
            <Header title={title ? title : "시설 수정"} border={false}>
                {
                    (step == 2) ?
                        <Close onClick={() => CheckNavigate()} fill={colors.Gr06} width="24px" height="24px" style={{ cursor: "pointer" }} />
                    : <></>
                }


            </Header>
        </StNewHeader>
    );
};

export default FacilityHeaderEnter;

const StNewHeader = styled.div`
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 100%;
    background-color: ${colors.White};
    position: sticky;
    z-index: 10;
    top: 0;
`;

const OnOff = styled.div`
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    padding: 5px 8px;
    background-color: ${({ bgc }) => (bgc ? `${bgc}` : "#ffffff")};
    border: ${({ border }) => (border ? `${border}` : "none")};
    color : ${colors.White};
    font-size: 14px;
    font-weight: 600;
    cursor : pointer;
    align-self : center;
`;