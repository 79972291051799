import React, { useEffect, useState, useRef } from "react";
import styled, { css } from "styled-components";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import Slider from "react-slick";

//tool
import { GetOne } from "../tool/localstorage";
import { IPad, Android } from "../tool/device_check";

//style
import { Wrapper, Text, Input, Icon, AbsoluteWrapper } from "../components/layouts/layoutStorage";
import colors from "../styles/colors";
import { PC, W } from "../styles/globalStyles";

//component
import { Loading } from "../tool/Loading";
import FacilityHeader from "../components/facilityRegister/FacilityHeader";

//icon
import ArrowSlider from "../designedIcons/carousel_arrow.png";

// Default Data
const IMAGE_RATIO = 234.375 / 375;
const CarouselSettings = {
    draggable: false,
    className: "slider variable-width",
    dots: false,
    arrows: false,
    infinite: true,
    // centerMode: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    variableWidth: false,
};

const FacilityDetail = () => {

    const navigate = useNavigate();

    const params = useParams();

    const sliderRef = useRef();

    const [loading, setLoading] = useState(false);
    const [copyPop, setCopyPop] = useState(false);
    const [facility, setFacility] = useState();
    const [chartUser, setChartUser] = useState();
    const [chartCount, setChartCount] = useState();
    const [chartTime, setChartTime] = useState();
    const [now, setNow] = useState();

    const GetData = () => {
        setLoading(true);

        var token = GetOne("token");

        const frm = new FormData();

        frm.append("mode", "facility_info");
        frm.append("token", token);
        frm.append("facility_id", params.id);

        axios
        .post("https://ai.semos.kr/semos_partner/v3/activity/Facility", frm, {
            headers: { "Content-Type": "multipart/form-data" },
        })
        .then((data) => {
            var Value = data.data;
            var Status = Value.status;
            var StatusMessage = Value.status_message;
            var FacilityInfo = Value.facility_info;
            var ChartTime = Value.chart_time;
            var ChartUser = Value.chart_user;
            var ChartCount = Value.chart_count;
            var Now = Value.now;

            if (Status == "success") {
                setFacility(FacilityInfo);
                setChartTime(ChartTime);
                setChartCount(ChartCount);
                setChartUser(ChartUser);
                setNow(Now);

                setLoading(false);
            } else {
                window.alert(StatusMessage);
                setLoading(false);
            }
        });
    };

    const SetStatus = () => {
        setLoading(true);

        var token = GetOne("token");

        const frm = new FormData();

        frm.append("mode", "facility_status");
        frm.append("token", token);
        frm.append("facility_id", params.id);

        axios
        .post("https://ai.semos.kr/semos_partner/v3/activity/Facility", frm, {
            headers: { "Content-Type": "multipart/form-data" },
        })
        .then((data) => {
            var Value = data.data;
            var Status = Value.status;
            var StatusMessage = Value.status_message;
            var ChangeStatus = Value.change_status;

            if (Status == "success") {
                setFacility((prev) => ({ ...prev, status : ChangeStatus }));
                setLoading(false);
            } else {
                window.alert(StatusMessage);
                setLoading(false);
            }
        });
    }

    useEffect(() => {
        GetData()
    }, []);

    const InsertUser = () => {
            setLoading(true);
    
            var token = GetOne("token");
    
            const frm = new FormData();
    
            axios
            .post(
                "https://ai.semos.kr/semos_partner/v3/activity/Facility",
                {
                    mode: "insert_user",
                    user: ""/*UserData*/
                },
                {
                    headers: {
                    "Content-Type": "application/x-www-form-urlencoded",
                    },
                }
            ).then((data) => {
                var Value = data.data;
                var Status = Value.status;
    
                if (Status == "success") {
                    setLoading(false);
                    console.log(Value.success)
                    console.log(Value.fail)
                    console.log(Value.fail_list)
                } else {
                    setLoading(false);
                }
            });
        }

    return (
        <Container id="description_container">

            <FacilityHeader
                status={true}
                title={'시설 정보'}
                available={false}
                statusHandler={() => console.log("신규 회원 등록")}
            />

            <Wrapper margin={`0 0 20px 0`} position="relative" width={`100%`} height={`${W * IMAGE_RATIO}px`}>
                {facility?.image_list?.length > 0 ? (
                    <Slider {...CarouselSettings} ref={sliderRef}>
                        {facility?.image_list.map((item, idx) => (
                            <div key={`${idx}_facility_image`}>
                                <div style={{ position: "relative" }}>
                                    <Icon of="cover" src={item} width={`100%`} height={`${W * IMAGE_RATIO}px`} />

                                    <AbsoluteWrapper bottom="24px" right="24px" bgc={colors.B40} radius="16px" padding="2px 12px">
                                    <Text size="12px" color={colors.White} weight="600">
                                        {idx + 1}&nbsp;
                                    </Text>
                                    <Text size="12px" color={colors.Gr04} weight="600">
                                        {`/ ${facility.image_list.length}`}
                                    </Text>
                                    </AbsoluteWrapper>
                                </div>
                            </div>
                        ))}
                    </Slider>
                ) : (
                    <div>
                        <div style={{ position: "relative" }}>
                            <Wrapper jc="center" ai="center" bgc={colors.Gr01} width={`100%`} height={`${W * IMAGE_RATIO}px`}></Wrapper>
                        </div>
                    </div>
                )}

                <ArrowIcon onClick={() => sliderRef.current.slickPrev()} src={ArrowSlider} width="32px" height="32px" />

                <ArrowIcon onClick={() => sliderRef.current.slickNext()} src={ArrowSlider} right={true} width="32px" height="32px" />
            </Wrapper>

            <Wrapper
                jc="flex-start"
                ai="center"
                fd={`column`}
                width="100%"
                padding={`0 24px 10px 24px`}
                margin={`0 0 20px 0`}
                gap={`15px`}
                //bb={`1px solid ${colors.Gr02}`}
            >
                <Wrapper
                    width={`100%`}
                    jc={'space-between'}
                    //onClick={() => InsertUser()}
                >
                    <Text 
                        ta="center" 
                        size="2rem" 
                        weight="600" 
                        lh="22px" 
                        ls="-0.28px" 
                        color={colors.Gr05}
                        width={`100%`}
                    >
                        {`${facility?.title}`}
                    </Text>
                </Wrapper>

            </Wrapper>

            <Wrapper
                jc="flex-start"
                ai="center"
                fd={`column`}
                width="100%"
                padding={`0 24px 10px 24px`}
                margin={`0 0 20px 0`}
                gap={`10px`}
                bb={`1px solid ${colors.Gr02}`}
            >
                <Wrapper
                    width={`100%`}
                    jc={'space-between'}
                >
                    <Text 
                        ta="center" 
                        size="1.8rem" 
                        weight="600" 
                        lh="22px" 
                        ls="-0.28px" 
                        color={colors.Gr05}
                        width={`100%`}
                    >
                        {`소개글`}
                    </Text>
                </Wrapper>

                <Wrapper radius="14px" bgc={colors.Gr01} border={`1px solid ${colors.Gr02}`} padding="10px 14px" width="100%">
                    <Text ws="pre-wrap" size="1.6rem" weight="500" ls="-0.02em" color={colors.Gr05}>
                        {facility?.intro}
                    </Text>
                </Wrapper>

            </Wrapper>

            <Wrapper
                jc="flex-start"
                ai="center"
                fd={`column`}
                width="100%"
                padding={`0 24px 10px 24px`}
                margin={`0 0 20px 0`}
                gap={`10px`}
                bb={`1px solid ${colors.Gr02}`}
            >
                <Wrapper
                    width={`100%`}
                    jc={'space-between'}
                >
                    <Text 
                        ta="center" 
                        size="1.8rem" 
                        weight="600" 
                        lh="22px" 
                        ls="-0.28px" 
                        color={colors.Gr05}
                        width={`100%`}
                    >
                        {`최소/최대 인원`}
                    </Text>
                </Wrapper>

                <Wrapper
                    jc="flex-start"
                    ai="center"
                    fd="column"
                    width="100%"
                >
                    <Text 
                        size="1.6rem" 
                        weight="600" 
                        lh="22px" 
                        ls="-0.28px" 
                        color={colors.Gr04}
                        width={`100%`}
                    >   
                        {`최소 : ${facility?.min_capacity}인 / 최대 : ${facility?.max_capacity}인`}
                    </Text>
                </Wrapper>

            </Wrapper>

            <Wrapper
                jc="flex-start"
                ai="center"
                fd={`column`}
                width="100%"
                padding={`0 24px 10px 24px`}
                margin={`0 0 20px 0`}
                gap={`10px`}
                bb={`1px solid ${colors.Gr02}`}
            >
                <Wrapper
                    width={`100%`}
                    jc={'space-between'}
                >
                    <Text 
                        ta="center" 
                        size="1.8rem" 
                        weight="600" 
                        lh="22px" 
                        ls="-0.28px" 
                        color={colors.Gr05}
                        width={`100%`}
                    >
                        {`주소`}
                    </Text>
                </Wrapper>

                <Wrapper
                    jc="flex-start"
                    ai="center"
                    fd="column"
                    width="100%"
                    gap={`5px`}
                >
                    <Text 
                        size="1.6rem" 
                        weight="600" 
                        lh="22px" 
                        ls="-0.28px" 
                        color={colors.Gr04}
                        width={`100%`}
                    >   
                        {`${facility?.address}`}
                    </Text>
                    <Text 
                        size="1.4rem" 
                        weight="600" 
                        lh="22px" 
                        ls="-0.28px" 
                        color={colors.Gr04}
                        width={`100%`}
                    >   
                        {`${facility?.address_etc}`}
                    </Text>
                </Wrapper>

            </Wrapper>

            <Wrapper
                jc="flex-start"
                ai="center"
                width="100%"
                wrap={'wrap'}
                padding={`0 24px 10px 24px`}
                margin={`0 0 20px 0`}
                bb={`1px solid ${colors.Gr02}`}
            >

                <Text 
                    size="1.8rem" 
                    weight="600" 
                    lh="22px" 
                    ls="-0.28px" 
                    color={colors.Gr05}
                    width={`100%`}
                    margin={`0 0 10px 0`}
                >
                    {`시설 운영 시간`}
                </Text>

                <Wrapper
                    jc="flex-start"
                    ai="center"
                    fd="column"
                    width="100%"
                >
                    {
                        facility && (
                            Object.keys(facility?.time).map((item, idx) => {

                                let data = facility?.time;
                                let values = data[item];
                                let Color = colors.Gr04;
                                let Status = true;

                                if(item == '공휴일' || item == '토' || item == '일') {
                                    Color = colors.Red03;
                                }

                                if(values.status != 1) {
                                    Status = false;
                                }
    
                                return (
                                    <Wrapper
                                        key={`${idx}_times`}
                                        jc="flex-start"
                                        ai="center"
                                        fd="column"
                                        width="100%"
                                        bb={`1px solid ${colors.Gr01}`}
                                        padding={`3px 0`}
                                    >
                
                                        <Text 
                                            size="1.6rem" 
                                            weight="600" 
                                            lh="22px" 
                                            ls="-0.28px" 
                                            color={Color}
                                            width={`100%`}
                                        >   {
                                                Status ?
                                                `[${item}] ${values.start_hour}:${values.start_min} ~ ${values.finish_hour}:${values.finish_min}`
                                                :
                                                `[${item}] 휴뮤일`
                                            }
                                        </Text>
                
                                    </Wrapper>
                                )
                            })
                        )

                    }

                </Wrapper>

            </Wrapper>

            <Wrapper
                jc="flex-start"
                ai="center"
                fd={`column`}
                width="100%"
                padding={`0 24px 10px 24px`}
                margin={`0 0 20px 0`}
                gap={`10px`}
                bb={`1px solid ${colors.Gr02}`}
            >
                <Wrapper
                    width={`100%`}
                    jc={'space-between'}
                    ai={`flex-end`}
                >
                    <Text 
                        ta="center" 
                        size="1.8rem" 
                        weight="600" 
                        lh="22px" 
                        ls="-0.28px" 
                        color={colors.Gr05}
                        width={`100%`}
                    >
                        {`시설 예약 현황`}
                        <Text 
                            ta="center" 
                            ai={`flex-end`}
                            jc={`flex-end`}
                            size="1.6rem" 
                            weight="600" 
                            color={colors.Gr04}
                            margin={`0 0 0 5px`}
                        >
                            {now ? now : ""}
                        </Text>
                    </Text>
                </Wrapper>

                <Wrapper
                    jc="flex-start"
                    display="inine-block"
                    ai="center"
                    fd="column"
                    width="100%"
                    height={`300px`}
                    border={`1px solid ${colors.Gr02}`}
                    radius={`8px`}
                    of={`hidden auto`}
                    bgc={`${colors.Gr01}`}
                    wrap={`wrap`}
                >
                    {
                        chartTime?.map((item, idx) => (
                            <Wrapper
                                key={`${idx}_time_chart`}
                                bb={`solid 1px ${colors.Gr02}`}
                                width={`100%`}
                                height={`45px`}
                            >
                                <Wrapper
                                    width={`30%`}
                                    height={`45px`}
                                    br={`1px solid ${colors.Gr02}`}
                                    ai={`center`}
                                    jc={`center`}
                                >
                                    <Text 
                                        ta="center" 
                                        size="1.6rem" 
                                        weight="600" 
                                        color={colors.Gr05}
                                    >
                                        {item.split(' ')[1]}
                                    </Text>
                                </Wrapper>

                                <Wrapper
                                    width={`70%`}
                                    height={`45px`}
                                    padding={`2px 10px 2px 10px`}
                                    wrap={`nowrap`}
                                    gap={`5px`}
                                >
                                    <Wrapper
                                        width={`100%`}
                                        wrap={`wrap`}
                                        jc={`flex-end`}
                                        br={`solid 1px ${colors.Gr02}`}
                                        padding={`0 3px 0 0 `}
                                        gap={`5px`}
                                    >
                                        {
                                            chartUser[item]?.map((i, id) => (
                                                                <Text 
                                                                    ta="right" 
                                                                    size="1.4rem" 
                                                                    weight="600" 
                                                                    color={colors.Gr05}
                                                                    jc={`flex-end`}
                                                                >
                                                                    {(id == 0) ? `${i.name}` : `, ${i.name}`}
                                                                </Text>
                                                            ))
                                        }
                                    </Wrapper>

                                    <Text 
                                        ta="right" 
                                        size="1.8rem" 
                                        weight="600" 
                                        width={`50px`}
                                        height={`100%`}
                                        color={colors.Gr05}
                                        ai={`center`}
                                        jc={`center`}
                                    >
                                        {`${chartCount[item]}/${facility?.max_capacity}`}
                                    </Text>

                                </Wrapper>
                            </Wrapper>
                        ))
                    }
                </Wrapper>

            </Wrapper>

            <BottomWrap>
                <Wrapper
                    onClick={() => SetStatus()}
                    jc="center"
                    ai="center"
                    width="calc(50% - 5px)"
                    height="52px"
                    cursor={"pointer"}
                    radius="8px"
                    bgc={colors.Red03}
                >
                    <Text 
                        ta="center" 
                        size="1.6rem" 
                        weight="600" 
                        lh="22px" 
                        ls="-0.28px" 
                        color={colors.White}
                    >
                        {(facility?.status == 1) ? `예약 비활성화` : `예약 활성화`}
                    </Text>
                </Wrapper>

                <Wrapper
                    onClick={() => navigate(`/facility_revise/${params.id}`)}
                    jc="center"
                    ai="center"
                    width="calc(50% - 5px)"
                    height="52px"
                    cursor={"pointer"}
                    radius="8px"
                    bgc={colors.Bl07}
                >
                    <Text 
                        ta="center" 
                        size="1.6rem" 
                        weight="600" 
                        lh="22px" 
                        ls="-0.28px" 
                        color={colors.White}
                    >
                        {`정보 수정하기`}
                    </Text>
                </Wrapper>
            </BottomWrap>

            {loading && <Loading />}

            {copyPop && (
                <Popup>
                    <Text size="1.2rem" weight="600" color={colors.White}>
                        복사가 완료되었습니다.
                    </Text>
                </Popup>
            )}
        </Container>
    );
};

export default FacilityDetail;

const Container = styled.section`
    height: calc(var(--vh, 1vh) * 100);
    overflow-x: hidden;
    width: 100%;
    padding-bottom : 150px;


    .slick-slider {
        width: 100%;
        height: ${W * IMAGE_RATIO}px;
    }
`;

const BottomWrap = styled.div`
    width: 100%;
    max-width: 900px;
    transform : translate(-50%, 0);
    display: flex;
    justify-content: space-between;
    padding: 12px 24px;
    border-top: 1px solid ${colors.Gr01};
    background-color: ${colors.White};
    position: fixed;
    bottom : 0;
    left : 50%;
    z-index: 10;
`;

const Popup = styled.div`
    position: fixed;
    display: flex;
    justify-content: center;
    text-align: center;
    z-index: 99;
    bottom: 48px;
    left: 50%;
    transform: translate(-50%, 0);
    width: 320px;
    height: 44px;
    background-color: rgba(0, 0, 0, 0.7);
    border-radius: 8px;
`;

const TextareaContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 150px;
    border-radius: 8px;
`;

const TextArea = styled.textarea`
    display: inline-flex;
    width: 100%;
    height: 100%;
    border: none;
    border-radius: 8px;
    resize: none;
    line-height: 22px;
    letter-spacing: -0.02em;
    font-size: 1.6rem;
    font-weight: 500;

    overflow: auto;
    outline: none;

    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;

    &::placeholder {
        color: ${colors.Gr03};
    }
`;

const IntroText = styled.div`
    flex: 1;
    display : inline-flex;
    align-items : flex-end;
    justify-content : flex-end;
    width: 100%;
    padding: 8px 12px;
    color: ${colors.Gr03};
    line-height: 20px;
    letter-spacing: -0.02em;
    font-size: 1.3rem;
    font-weight: 500;
`;

const ArrowIcon = styled.img`
    width: 32px;
    height: 32px;
    position: absolute;
    top: 50%;
    cursor: pointer;

    ${({ right }) => (
        right
            ? 
                `transform: rotate(180deg) translate(0, 50%); right: 18px;`
            : 
                `transform: translate(0, -50%); left: 18px;`
    )};
`;