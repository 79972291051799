import React, { useState, useRef } from "react";
import { useEffect } from "react";
import styled from "styled-components";
import axios from "axios";

// style
import colors from "../../styles/colors";

//img
import ClearIcon from "../../icon/clearInput.png";
import DropDownIcon from "../../icon/greyDrop.png";
import RedWarning from "../../icon/redWarning.png";
import BlueOkay from "../../icon/allowedInputicon.png";

const W = window.document.documentElement.clientWidth;
const H = window.innerHeight;

const DesignSize = H / 812;

function SignUpFirst({ page, status, setStatus, userInfo, setUserInfo }) {
  const nicknameRef = useRef(null);

  const [ageText, setAgeText] = useState("연령대 선택");
  const [genderText, setGenderText] = useState("성별 선택");
  const [open, setOpen] = useState({
    age: false,
    gender: false,
  });

  const setUserData = (type, value) => {
    let text = value;
    text = text.substr(0, 15);

    setUserInfo((prev) => ({ ...prev, [type]: text }));
  };

  useEffect(() => {
    var token = window.localStorage.getItem("token");
    var nickName = userInfo.nickname;
    var pattern = /^[가-힣a-zA-Z0-9]{2,15}$/i;

    if (nickName) {
      if (nickName.length > 1) {
        const frm = new FormData();
        frm.append("mode", "duplicate");
        frm.append("nickname", nickName);
        frm.append("token", token);
        axios
          .post("https://ai.semos.kr/semos_partner/auth/login", frm, {
            headers: {
              "Content-Type": "multipart/form-data",
              Accept: "application/json",
            },
          })
          .then((data) => {
            var Value = data.data;

            var check = Value.status;
            if (check === "success") {
              if (pattern.test(nickName)) {
                setStatus({ ...status, nickname: "ok" });
              } else {
                setStatus({ ...status, nickname: "denied" });
              }
            } else if (check == "duplicate") {
              setStatus({ ...status, nickname: "duplicated" });
            } else {
              setStatus({ ...status, nickname: "denied" });
            }
          });
      } else if (nickName.length == 1) {
        setStatus({ ...status, nickname: "black" });
      }
    } else {
      setStatus({ ...status, nickname: "default" });
    }
  }, [userInfo.nickname]);

  useEffect(() => {
    if (userInfo.age) {
      var range = userInfo.age.split("~")[0];
      setAgeText(`${range}대`);
    } else {
      setAgeText("연령대 선택");
    }
    if (userInfo.gender) {
      userInfo.gender === "male" && setGenderText("남");
      userInfo.gender === "female" && setGenderText("여");
    } else {
      setGenderText("성별 선택");
    }
  }, []);

  const clearInput = (type) => {
    setUserData(type, "");
    nicknameRef.current.focus();
  };

  const setGender = (type, value) => {
    switch (value) {
      case 1:
        setGenderText("남");
        break;
      case 2:
        setGenderText("여");
        break;
      case 3:
        setGenderText("선택 안함");
        break;
      default:
        break;
    }
    setUserInfo((prev) => ({ ...prev, [type]: value }));
    setOpen({ ...open, gender: false });
  };

  const ageSelection = (value) => {
    var ageRange = value.split(" (")[0];
    var range = ageRange.includes("10")
      ? "10~19"
      : ageRange.includes("20")
      ? "20~29"
      : ageRange.includes("30")
      ? "30~39"
      : ageRange.includes("40")
      ? "40~49"
      : ageRange.includes("50")
      ? "50~59"
      : "60~";
    setUserData("age", range);
    setAgeText(ageRange);
    setOpen({ ...open, age: false });
  };

  if (page === 1) {
    return (
      <>
        <InputContainer status={status.nickname}>
          <FloatingText>닉네임</FloatingText>
          <TextInput
            ref={nicknameRef}
            type="text"
            maxLength={15}
            placeholder="2글자 이상 15글자 이하로 작성해 주세요!"
            onChange={(e) => setUserData("nickname", e.target.value)}
            value={userInfo.nickname}
          />
          {userInfo.nickname && (
            <ClearButton
              src={ClearIcon}
              onClick={() => clearInput("nickname")}
            />
          )}
        </InputContainer>
        {(status.nickname === "denied" ||
          status.nickname == "duplicated" ||
          status.nickname == "ok") && <Divider h={6} />}

        {status.nickname === "denied" && (
          <WarningTextContainer>
            <WarningIcon src={RedWarning} />
            <WarningText>
              사용할 수 없는 닉네임입니다. 다시 작성해 주세요!
            </WarningText>
          </WarningTextContainer>
        )}

        {status.nickname === "duplicated" && (
          <WarningTextContainer>
            <WarningIcon src={RedWarning} />
            <WarningText>
              닉네임이 중복되었어요. 다시 작성해 주세요!
            </WarningText>
          </WarningTextContainer>
        )}

        {status.nickname === "ok" && (
          <WarningTextContainer need={true}>
            <WarningIcon src={BlueOkay} />
            <OkayText>사용 가능한 닉네임입니다!</OkayText>
          </WarningTextContainer>
        )}

        <Divider h={20} />

        <DropAndRadio>
          <DropDownContainer>
            <DropDown>
              <SelectedItem>{ageText}</SelectedItem>
              <DropIcon
                src={DropDownIcon}
                onClick={() => setOpen((prev) => ({ ...prev, age: !prev.age }))}
                open={open.age}
              />
              {open.age && (
                <DropMenu>
                  <DropItem
                    no={true}
                    onClick={(e) => ageSelection(e.target.innerHTML)}
                  >
                    10대 (10~19)
                  </DropItem>
                  <DropItem onClick={(e) => ageSelection(e.target.innerHTML)}>
                    20대 (20~29)
                  </DropItem>
                  <DropItem onClick={(e) => ageSelection(e.target.innerHTML)}>
                    30대 (30~39)
                  </DropItem>
                  <DropItem onClick={(e) => ageSelection(e.target.innerHTML)}>
                    40대 (40~49)
                  </DropItem>
                  <DropItem onClick={(e) => ageSelection(e.target.innerHTML)}>
                    50대 (50~59)
                  </DropItem>
                  <DropItem onClick={(e) => ageSelection(e.target.innerHTML)}>
                    60대 이상 (60~)
                  </DropItem>
                </DropMenu>
              )}
            </DropDown>
          </DropDownContainer>

          <DropDownContainer>
            <DropDown>
              <SelectedItem>{genderText}</SelectedItem>
              <DropIcon
                src={DropDownIcon}
                onClick={() =>
                  setOpen((prev) => ({ ...prev, gender: !prev.gender }))
                }
                open={open.gender}
              />
              {open.gender && (
                <DropMenu>
                  <DropItem no={true} onClick={() => setGender("gender", 1)}>
                    남
                  </DropItem>
                  <DropItem onClick={() => setGender("gender", 2)}>여</DropItem>
                  <DropItem onClick={() => setGender("gender", 3)}>
                    선택 안함
                  </DropItem>
                </DropMenu>
              )}
            </DropDown>
          </DropDownContainer>

          {/* <RadioButtonContainer>
            <SingleButton>
              <ButtonText>
                남
                <RadioButton
                  type="radio"
                  value={1}
                  name="gender"
                  checked={userInfo.gender == 1}
                  onClick={() => setGender("gender", 1)}
                />
              </ButtonText>
            </SingleButton>
            <SingleButton>
              <ButtonText>
                여
                <RadioButton
                  type="radio"
                  value={2}
                  name="gender"
                  checked={userInfo.gender == 2}
                  onClick={() => setGender("gender", 2)}
                />
              </ButtonText>
            </SingleButton>
            <SingleButton>
              <ButtonText>
                선택 안함
                <RadioButton
                  type="radio"
                  value={3}
                  name="gender"
                  checked={userInfo.gender == 3}
                  onClick={() => setGender("gender", 3)}
                />
              </ButtonText>
            </SingleButton>
          </RadioButtonContainer> */}
        </DropAndRadio>
      </>
    );
  }
}

export default SignUpFirst;

const InputContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  border: 1px solid;
  border-radius: 8px;
  padding: 15px 18px;
  border-color: ${({ status }) =>
    status == "ok"
      ? "#66A2FF"
      : status == "black"
      ? "#818792"
      : status == "default"
      ? "#F5F5F5"
      : "#FF9187"};

  height: 52px;
`;

const Divider = styled.div`
  height: ${({ h }) => h * DesignSize}px;
`;

const FloatingText = styled.div`
  position: absolute;
  background-color: white;
  padding: 0 5px;
  top: -5px;
  left: 10px;
  font-size: 10px;
  font-weight: 600;
  color: #3c3e41;
`;

const TextInput = styled.input`
  border: none;
  width: 80%;
  font-size: 14px;
  outline: none;
  font-weight: 500;
  line-height: 19.88px;

  &::placeholder {
    color: #acafb5;
  }
`;

const DropAndRadio = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  /* align-items: center; */
  gap: 8px;
`;

const DropDownContainer = styled.div`
  flex: 1;
  width: 100%;
`;

const DropDown = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 22px 15px 18px;
  border: 1px solid #dcdcdc;
  border-radius: 8px;
  z-index: 3;
  background-color: ${colors.White};
  /* width: 100%; */
  position: relative;
`;

const DropMenu = styled.div`
  width: 100%;
  position: absolute;
  /* top: 52px; */
  top: 56px;
  left: 0px;
  border: 1px solid #dcdcdc;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  border-top: none;
  z-index: 1;
  background-color: ${colors.White};
`;

const DropItem = styled.div`
  border-top: 1px solid #f5f5f5;
  border: ${({ no }) => no && "none"};
  padding: 12px 18px;
  padding-top: ${({ no }) => no && "17px"};
  font-size: 14px;
  font-weight: 700;
  color: #818792;
`;

const SelectedItem = styled.div`
  font-size: 14px;
  line-height: 19.88px;
  font-weight: 700;
  color: #818792;
`;

const DropIcon = styled.img`
  width: 24px;
  height: 24px;
  transform: ${({ open }) => (open ? "rotate(-180deg)" : "")};
  transition: all 0.75s;
`;

const RadioButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 45%;
  padding-left: 24px;
  padding-right: 16px;

  @media screen and (min-width: 550px) {
    justify-content: center;
  }
`;

const SingleButton = styled.div`
  @media screen and (min-width: 550px) {
    margin-left: 40px;
  }
`;

const ButtonText = styled.label`
  display: flex;
  /* justify-content: center; */
  align-items: center;
  font-size: 14px;
  font-weight: 700;
  line-height: 19.88px;
`;

const RadioButton = styled.input`
  width: 18px;
  height: 18px;
  margin-left: 10px;
  margin-bottom: 3px;
`;

const ClearButton = styled.img`
  width: 20px;
  height: 20px;
  z-index: 100;
`;

const WarningTextContainer = styled.div`
  display: flex;
  align-items: ${({ need }) => (need ? "center" : "")}s;
`;

const WarningIcon = styled.img`
  width: 14px;
  height: 14px;
  margin-right: 6px;
`;

const WarningText = styled.div`
  font-weight: 600;
  font-size: 12px;
  line-height: 142%;
  display: flex;
  align-items: center;
  letter-spacing: -0.02em;
  color: #ff9187;
`;

const OkayText = styled.div`
  font-weight: 600;
  font-size: 12px;
  line-height: 142%;
  display: flex;
  align-items: center;
  letter-spacing: -0.02em;
  color: #66a2ff;
`;
