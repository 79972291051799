import React, { useState, useRef, useEffect } from "react";
import styled from "styled-components";
import axios from "axios";

import { GetOne } from "../../tool/localstorage";
import { WrapperColumn } from "../layouts/layoutStorage";
import { MAX_PHONE_NUMBER_LENGTH } from "../../data/config";

import colors from "../../styles/colors";

//img
import ClearIcon from "../../icon/clearInput.png";
import RedWarning from "../../icon/redWarning.png";
import OkaySign from "../../icon/allowedInputicon.png";

const W = window.document.documentElement.clientWidth;
const H = window.innerHeight;

const AUTH_TIMER = 180;

const DesignSize = H / 812;

const PhoneNumberConfirm = ({
  defaultPhoneNumber,
  userInfo,
  setUserInfo,
  valid,
  setValid,
  status,
  setStatus,
}) => {
  const phoneRef = useRef(null);
  const authRef = useRef(null);
  const [open, setOpen] = useState(false);
  const [phoneText, setPhoneText] = useState("");
  const [verifyToken, setVerifyToken] = useState("");

  const [timer, setTimer] = useState("");

  const min = `0${Math.floor(timer / 60)}`;
  const sec = timer % 60 < 10 ? "0" + (timer % 60) : timer % 60;

  const clearInput = (type) => {
    setUserData(type, "");
    if (type === "phone") {
      phoneRef.current.focus();
    } else if (type === "auth") {
      authRef.current.focus();
    }
  };

  useEffect(() => {
    let phoneCopy = userInfo.phone.replace(/[^0-9]/g, "");
    const int = Number(phoneCopy);

    if (!isNaN(int)) {
      phoneCopy.length > 9
        ? setStatus({ ...status, phone: "ok" })
        : setStatus({ ...status, phone: "default" });
    } else if (phoneCopy === undefined) {
      setStatus({ ...status, phone: "default" });
    } else {
      setStatus({ ...status, phone: "denied" });
    }

    phoneCopy = phoneCopy
      ? phoneCopy
          .replace(/[^0-9]/, "")
          .replace(/^(\d{2,3})(\d{3,4})(\d{4})$/, `$1-$2-$3`)
      : "";

    setPhoneText(phoneCopy);
  }, [userInfo.phone]);

  useEffect(() => {}, [status]);

  // TIMER
  useEffect(() => {
    if (timer !== 0 && status.auth !== "ok") {
      const time = setInterval(() => {
        setTimer((prev) => prev - 1);
      }, 1000);

      return () => clearInterval(time);
    } else if (timer == 0 && status.auth !== "ok") {
      alert("인증번호가 만료되었습니다. 다시 시도해 주세요.");
      setOpen(false);
      setUserInfo({ ...userInfo, phone: "" });
      setStatus({ ...status, phone: "default" });
    }
  }, [timer]);

  const verifyPhoneNumber = () => {
    const token = GetOne("token");
    const phoneNum = Number(userInfo.phone.replaceAll("-", ""));
    setUserInfo({ ...userInfo, auth: "" }); // 인증번호 입력 초기화

    if (phoneNum.toString().length > 9 && !isNaN(phoneNum)) {
      const frm = new FormData();
      frm.append("mode", "phone_number_confirm");
      frm.append("phone_number", phoneText);
      frm.append("token", token);

      axios
        .post("https://ai.semos.kr/semos_partner/auth/login", frm, {
          headers: {
            "Content-Type": "multipart/form-data",
            Accept: "application/json",
          },
        })
        .then((res) => {
          const data = res.data;
          const dataStatus = data.status;
          const checkToken = data.check_t;

          if (dataStatus === "success") {
            setOpen(true);
            setUserInfo((prev) => ({ ...prev, phone: phoneText }));
            // setStatus({ ...status, phone: "ok" });
            setStatus({ auth: "default", phone: "ok" });
            setTimer(AUTH_TIMER);
            setVerifyToken(checkToken);
          } else if (dataStatus === "fail") {
            alert("인증번호 전송에 실패하였습니다.");
          }
        });
    } else {
      alert(`전화번호를 다시 한번 확인해주세요.`);
    }
  };

  const authNumCheck = () => {
    const token = GetOne("token");

    if (userInfo.auth.length !== 6) {
      alert("인증번호 6자리를 입력해주세요.");
    } else {
      const frm = new FormData();
      frm.append("mode", "verification_confirm");
      frm.append("verification_token", verifyToken);
      frm.append("verification_number", userInfo.auth);
      frm.append("phone_number", phoneText);
      frm.append("token", token);

      axios
        .post("https://ai.semos.kr/semos_partner/auth/login", frm, {
          headers: {
            "Content-Type": "multipart/form-data",
            Accept: "application/json",
          },
        })
        .then((res) => {
          const data = res.data;

          if (data.status === "success") {
            setStatus({ ...status, auth: "ok" });
            setValid({ ...valid, phone: true });
            alert("인증성공");
          } else {
            setStatus({ ...status, auth: "denied" });
            setValid({ ...valid, phone: false });
            alert("인증번호가 올바르지 않습니다.");
          }
        });
    }
  };

  const setUserData = (type, target) => {
    console.log(type, target); // 'phone', ''
    if (type === "phone") {
      setValid({ ...valid, phone: false });
      // let value = target;
      let phoneStr = target;

      const phoneArr = [];
      const phoneNum = phoneStr.replaceAll("-", "").replaceAll(" ", "");

      // 번호 변경 없을 시 valid.phone = true
      const defaultPhoneNum = defaultPhoneNumber
        .replaceAll("-", "")
        .replaceAll(" ", "");
      phoneNum === defaultPhoneNum
        ? setValid({ ...valid, phone: true })
        : setValid({ ...valid, phone: false });

      phoneArr.push(phoneNum.substr(0, 3));
      if (phoneNum.length > 3) phoneArr.push(phoneNum.substr(3, 4));
      if (phoneNum.length > 7) phoneArr.push(phoneNum.substr(7, 4));

      phoneStr = phoneArr.join("-");
      const text = phoneStr.substr(0, MAX_PHONE_NUMBER_LENGTH);

      if (status.phone !== "ok" || status.auth !== "ok") {
        setUserInfo((prev) => ({ ...prev, [type]: text }));
        // setUserInfo((prev) => ({ ...prev, [type]: value }));
      }
    } else {
      // setUserInfo((prev) => ({ ...prev, [type]: value }));
      setUserInfo((prev) => ({ ...prev, [type]: target }));
    }
  };

  const phoneReset = () => {
    setOpen(false);
    setTimer("");
    // setUserInfo({ ...userInfo, phone: "" });
    setUserInfo({ ...userInfo, auth: "" });
    setValid({ ...valid, phone: false });

    setTimeout(() => {
      setStatus({ ...status, phone: "default", auth: "default" });
    }, 200);
  };

  // console.log(status);
  return (
    <WrapperColumn width="100%">
      <InputContainerWithButton phone={true} status={status.phone}>
        {open ? (
          <PhoneBox>{phoneText}</PhoneBox>
        ) : (
          <TextInput
            ref={phoneRef}
            type="text"
            maxLength={MAX_PHONE_NUMBER_LENGTH}
            placeholder="전화번호를 입력해 주세요."
            onChange={(e) => setUserData("phone", e.target.value)}
            value={userInfo.phone}
            disabled={status.auth == "ok"}
          />
        )}
        {status.phone !== "ok" || status.auth !== "ok" ? (
          <JustBetween>
            {userInfo.phone && !open && (
              <ClearButton
                src={ClearIcon}
                onClick={() => {
                  clearInput("phone");
                }}
              />
            )}
            {status.phone !== "denied" && (
              <PhoneButton open={open} onClick={() => verifyPhoneNumber()}>
                {open ? "전송완료" : "인증번호 전송"}
              </PhoneButton>
            )}
          </JustBetween>
        ) : (
          <JustBetween>
            <PhoneButton onClick={() => phoneReset()} open={false}>
              수정하기
            </PhoneButton>
          </JustBetween>
        )}
      </InputContainerWithButton>

      {open && (
        <AnimationBox>
          <Divider h={8} />

          {status.auth === "ok" ? (
            <InputContainerWithButton
              status="default"
              style={{ backgroundColor: colors.Gr01 }}
            >
              <AuthBox>{userInfo.auth}</AuthBox>
              <JustBetween>
                <Okay src={OkaySign} />
                <AuthButton status={status.auth}>인증완료</AuthButton>
              </JustBetween>
            </InputContainerWithButton>
          ) : (
            <InputContainerWithButton status={status.auth}>
              <TextInput
                ref={authRef}
                type="tel"
                maxLength={6}
                placeholder="인증번호를 입력해 주세요!"
                onChange={(e) => setUserData("auth", e.target.value)}
                value={userInfo.auth}
              />

              <JustBetween>
                {userInfo.auth && status.auth !== "ok" && (
                  <ClearButton
                    src={ClearIcon}
                    onClick={() => clearInput("auth")}
                  />
                )}
                {timer && (
                  <Timer>
                    {min}:{sec}
                  </Timer>
                )}
                <AuthButton status={status.auth} onClick={() => authNumCheck()}>
                  인증완료
                </AuthButton>
              </JustBetween>
            </InputContainerWithButton>
          )}
          {status.phone === "ok" &&
            status.auth !== "ok" &&
            status.auth !== "denied" && (
              <>
                <Divider h={6} />
                <WarningTextContainer need={true}>
                  <WarningIcon src={OkaySign} />
                  <WarningText negative={false}>
                    인증번호 전송이 완료되었습니다.
                  </WarningText>
                </WarningTextContainer>
              </>
            )}

          {status.auth === "ok" && (
            <>
              <Divider h={6} />
              <WarningTextContainer need={true}>
                <WarningIcon src={OkaySign} />
                <WarningText negative={false}>
                  인증이 완료되었습니다.
                </WarningText>
              </WarningTextContainer>
            </>
          )}

          {status.auth === "denied" && (
            <>
              <Divider h={6} />
              <WarningTextContainer>
                <WarningIcon src={RedWarning} />
                <WarningText negative={true}>
                  올바른 인증번호로 다시 입력해주세요!
                </WarningText>
              </WarningTextContainer>
            </>
          )}
        </AnimationBox>
      )}
    </WrapperColumn>
  );
};

export default PhoneNumberConfirm;

const InputContainer = styled.div`
  /* width: ${W - 48}px; */
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid;
  border-radius: 8px;
  padding: 16px;
  border-color: ${({ status }) =>
    status === "ok"
      ? colors.Bl05
      : status === "black"
      ? colors.Gr04
      : status === "default"
      ? colors.Gr01
      : colors.Red03};
  height: 52px;
  width: 100%;
`;

const InputContainerWithButton = styled(InputContainer)`
  position: relative;
  padding: 7px 0px 7px 15px;
  height: 52px;
  width: 100%;
  background-color: ${colors.White};
  z-index: ${({ phone }) => (phone ? 3 : 1)};
`;

const TextInput = styled.input`
  border: none;
  /* width: ${({ need }) => need && "65%"}; */
  width: 65%;
  font-size: 14px;
  outline: none;
  font-weight: 500;
  line-height: 19.88px;

  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  cursor: text;

  &::placeholder {
    color: ${colors.Gr03};
  }
`;

const PhoneBox = styled.div`
  width: 65%;
  font-size: 14px;
  font-weight: 500;
  line-height: 19.88px;
`;

const JustBetween = styled.div`
  display: flex;
  align-items: center;
`;

const PhoneButton = styled.div`
  cursor: pointer;
  padding: 9px 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 700;
  font-size: 14px;
  line-height: 142%;
  background-color: ${({ open }) => (open ? colors.Gr02 : colors.Bl07)};
  letter-spacing: -0.02em;
  color: ${colors.White};
  border-radius: 8px;
  margin-left: 10px;
  margin-right: 7px;
  white-space: nowrap;
`;

const ClearButton = styled.img`
  width: 20px;
  height: 20px;
  cursor: pointer;
`;

const WarningTextContainer = styled.div`
  display: flex;
  align-items: ${({ need }) => (need ? "center" : "")};
`;

const WarningIcon = styled.img`
  width: 14px;
  height: 14px;
  margin-right: 6px;
`;

const WarningText = styled.div`
  font-weight: 600;
  font-size: 12px;
  line-height: 142%;
  display: flex;
  align-items: center;
  letter-spacing: -0.02em;
  color: ${({ negative }) => (negative ? colors.Red03 : colors.Bl05)};
`;

const AuthButton = styled(PhoneButton)`
  background-color: ${({ status }) =>
    status == "ok" ? colors.Gr02 : colors.Bl07};
`;

const Okay = styled.img`
  width: 24px;
  height: 24px;
`;

const AuthBox = styled.div`
  width: 65%;
  font-size: 14px;
  font-weight: 500;
  line-height: 19.88px;
  color: ${colors.Gr03};
`;

const AnimationBox = styled.div`
  @keyframes dropdown {
    0% {
      transform: translateY(-52px);
      /* opacity: 0; */
    }
    100% {
      transform: translateY(0);
      /* opacity: 1; */
    }
  }

  animation: dropdown 0.5s ease;
`;

const Timer = styled.div`
  font-weight: 600;
  font-size: 12px;
  line-height: 142%;
  display: flex;
  align-items: center;
  letter-spacing: -0.02em;
  color: ${colors.Bl06};
  margin-left: 6px;
`;

const Divider = styled.div`
  height: ${({ h }) => h * DesignSize}px;
`;
