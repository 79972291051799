// 상수 값 관리

// COMMON
export const MAX_PHONE_NUMBER_LENGTH = 13; // 000-0000-0000
export const MAX_ID_NUMBER_LENGTH = 14; // 000000-0000000
export const MAX_REGISTER_NUMBER_LENGTH = 12; // 000-00-00000

// PARTNER REGISTER

// TEXT
