import React, { useState } from "react";
import styled from "styled-components";

import RegisterInput from "./RegisterInput";
import PhoneNumberConfirm from "./PhoneNumberConfirm";
import Tooltip from "../elements/tooltip";

import colors from "../../styles/colors";

const CommonForm = ({ STEP_LIST, step, setStep, data, setData, setNext, setVerifyToken, confirmStatus, setConfirmStatus, handleNext }) => {
  const [valid, setValid] = useState({
    userName: false,
    phoneNumber: false,
  });

  const selectUserType = (target) => {
    setData({ ...data, userType: target });
    handleNext(target);
  };

  return (
    <Container>
      <RegisterTitle>{STEP_LIST[step.name].title}</RegisterTitle>

      {step.index === 3 ? (
        <UserTypeContainer>
          <UserTypeButtonContainer>
            <UserTypeButton onClick={() => selectUserType("partner")}>개인입니다.</UserTypeButton>
            <UserTypeButton onClick={() => selectUserType("center")}>센터입니다.</UserTypeButton>
          </UserTypeButtonContainer>
          
          <SmallTitle>
            {/* 개인/센터 여부란? */}
            <Tooltip boxLeft="50%" left="50%" type="left" title="" isCenter={true}>
              <TooltipContainer>
                <TooltipTitle>개인 파트너</TooltipTitle>
                <TooltipText isLast={false}>
                  •&nbsp;&nbsp;홈택스 전자세금계산서 발행 불가능 <br />
                  •&nbsp;&nbsp;프리랜서 or 면세사업자 <br />
                  •&nbsp;&nbsp;개설한 액티비티는 본인만 진행 가능 <br />
                  •&nbsp;&nbsp;센터 소속 강사의 경우에도 개인으로 등록 <br />
                </TooltipText>

                <TooltipTitle>센터 파트너</TooltipTitle>
                <TooltipText isLast={false}>
                  •&nbsp;&nbsp;홈택스 전자세금계산서 발행 가능 <br />
                  •&nbsp;&nbsp;일반사업자 or 간이과세사업자
                  <br />
                  •&nbsp;&nbsp;개설한 액티비티는 센터 산하 강사에게 자유롭게 배정 가능
                  <br />
                </TooltipText>
                <ToolTipWarningText>
                  <span className="text-red">*주의</span>
                  <span className="text-black">
                    개인/센터 여부는 최초 선택 이후 변경 불가해요! <br />
                    추후 변경을 원하는 경우 세모스 고객센터로 문의주세요
                  </span>
                </ToolTipWarningText>
              </TooltipContainer>
            </Tooltip>
            개인/센터 여부란?
          </SmallTitle>
        </UserTypeContainer>
      ) : (
        <></>
      )}

      {/* 인증번호 */}
      {step.index > 1 && step.index < 3 ? (
        <PhoneNumberConfirm
          type="phoneNumberConfirm"
          STEP_LIST={STEP_LIST}
          step={step}
          data={data}
          setData={setData}
          setNext={setNext}
          valid={valid}
          setValid={setValid}
          setVerifyToken={setVerifyToken}
          confirmStatus={confirmStatus}
          setConfirmStatus={setConfirmStatus}
        />
      ) : (
        <></>
      )}

      {/* 전화번호 */}
      {step.index > 0 && step.index < 3 ? (
        <RegisterInput
          type="phoneNumber"
          placeholder=""
          STEP_LIST={STEP_LIST}
          step={step}
          data={data}
          setData={setData}
          setNext={setNext}
          valid={valid}
          setValid={setValid}
        />
      ) : (
        <></>
      )}

      {/* 활동명 */}
      {step.index >= 0 && step.index < 3 ? (
        <RegisterInput
          type="userName"
          placeholder="예시) 세모스프리다이빙센터"
          STEP_LIST={STEP_LIST}
          step={step}
          data={data}
          setData={setData}
          setNext={setNext}
          valid={valid}
          setValid={setValid}
        />
      ) : (
        <></>
      )}
    </Container>
  );
};

export default CommonForm;

const Container = styled.div``;

const RegisterTitle = styled.h2`
  padding: 32px 0 8px 0;
  color: ${colors.Gr06};
  white-space: pre-line;
  line-height: 26px;
  letter-spacing: -0.36px;
  font-size: 18px;
  font-weight: 700;
`;

const UserTypeContainer = styled.div``;

const UserTypeButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  gap: 16px;
  padding: 12px 0 16px 0;
  background-color: ${colors.White};
`;

const UserTypeButton = styled.button`
  width: 100%;
  height: 52px;
  padding: 8px 16px;
  background-color: ${colors.White};
  border: 1px solid ${colors.Gr02};
  border-radius: 8px;
  color: ${colors.Gr05};
  line-height: 24px;
  letter-spacing: -0.32px;
  font-size: 16px;
  font-weight: 500;
`;

const SmallTitle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 60px;
  position: relative;
  color: ${colors.Gr04};
  line-height: 142%;
  font-size: 14px;
  font-weight: 600;

  svg {
    width: 24px;
    height: 24px;
    margin-right: 6px;
  }
`;

const TooltipContainer = styled.div`
  width: 100%;
`;

const TooltipTitle = styled.span`
  display: inline-block;
  margin-bottom: 8px;
  color: ${colors.Bl07};
  letter-spacing: -0.24px;
  line-height: 20px;
  font-size: 12px;
  font-weight: 600;
`;

const TooltipText = styled.p`
  margin-bottom: ${({ isLast }) => (isLast ? "0 !important" : "10px !important")};
  color: ${colors.Gr05};
  white-space: pre-wrap;
  letter-spacing: -0.02em;
  line-height: 20px;
  font-size: 12px;
  font-weight: 500;
`;

const ToolTipWarningText = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  gap: 4px;

  span {
    line-height: 20px;
    letter-spacing: -0.24px;
    font-size: 12px;
    font-weight: 600;
  }

  .text-red {
    color: ${colors.Red03};
  }

  .text-black {
    flex: 1;
    color: ${colors.Gr05};
  }
`;
