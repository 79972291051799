import React, { useState, useRef } from "react";
import { useNavigate } from "react-router-dom";

// style
import colors from "../../styles/colors";
import { Wrapper, Text, Icon } from "../layouts/layoutStorage";

//icon
import { ReactComponent as Arrow } from "../../resource/icon/arrow.svg";

const ConsultCard = ({ data }) => {
    
    const navigate = useNavigate();
    const consultRef = useRef();

    const [open, setOpen] = useState(false);

    const StatusReturn = (status) => {

        switch(Number(status)) {
            case 1 :
                return {
                    text : "진행 중",
                    colors : colors.Bl07
                }
            case 2 :
                return {
                    text : "완료",
                    colors : colors.Gr04
                }

            case 3 :
                return {
                    text : "취소",
                    colors : colors.Red03
                }

            default :
                return ""
        }

    } 

    const SendSms = () => {
        var iOS = /iPhone|iPad|iPod|Mac OS/i.test(navigator.userAgent);
        let UserPhone = data?.user_phone?.replace(/-/g, "");

        let Message = encodeURIComponent(`[세모스 파트너]\n\n안녕하세요 ${data?.user_name} 크루님\n${data?.partner} 입니다.\n\n`);

        if(iOS) {
            window.location.href=`sms:${UserPhone}&body=${Message}`;
        } else {
            window.location.href=`sms:${UserPhone}?body=${Message}`;
        }
    }

    const SendCall = () => {
        let UserPhone = data?.user_phone?.replace(/-/g, "");

        window.location.href=`tel:${UserPhone}`;
    }

    return (
        <Wrapper 
            ref={consultRef}
            wrap={`wrap`} 
            width="100%" 
            ai="flex-start" 
            bb={`1px solid ${colors.Gr02}`}
            padding="10px 24px"
        >   
            <Wrapper 
                wrap={`wrap`} 
                width={`100%`}
                cursor={`pointer`}
                onClick={() => {
                    navigate(`/consult_detail/${data.id}`)
                }}    
            >
                <Wrapper
                    style={{
                        flexDirection : "column"
                    }}
                    width={`calc(100%)`}
                >
                    <Wrapper
                        width="100%" 
                        wrap={`nowrap`} 
                        ai="center" 
                        jc="space-between" 
                        margin={`0 0 0.7rem 0`}
                    >
                        <Text 
                            size="1.4rem" 
                            weight="600" 
                            lh="22px" 
                            ls="-0.28px" 
                            width={`auto`}
                            color={colors.Bl07}
                            to={`ellipsis`}
                            style={{
                                cursor : 'pointer',
                                display : "inline-block"
                            }}

                        >   
                            {
                                `[${(data?.reservation === true) ? '상담 예약' : '바로 싱딤'}]`
                            }
                        </Text>
                        
                        <Wrapper jc={'flex-end'}>
                            <Text 
                                size="1.4rem" 
                                weight="500" 
                                lh="22px" 
                                ls="-0.28px" 
                                width={`auto`}
                                color={colors.Gr04}
                                to={`ellipsis`}
                                style={{
                                    cursor : 'pointer',
                                    display : "inline-block"
                                }}

                            >   
                                {
                                    `${data?.date ? data?.date.split(' ')[0] : ""}`
                                }
                            </Text>

                            <Text 
                                size="1.4rem" 
                                weight="500" 
                                lh="22px" 
                                ls="-0.28px" 
                                width={`auto`}
                                color={colors.Gr03}
                                style={{
                                    cursor : 'pointer',
                                    display : "inline-block",
                                    margin : '0 0.3rem'
                                }}

                            >   
                                {
                                    ` • `
                                }
                            </Text>

                            <Text 
                                size="1.4rem" 
                                weight="500" 
                                lh="22px" 
                                ls="-0.28px" 
                                width={`auto`}
                                color={StatusReturn(data?.status)?.colors}
                                style={{
                                    cursor : 'pointer',
                                    display : "inline-block"
                                }}

                            >   
                                {
                                    `${StatusReturn(data?.status)?.text}`
                                }
                            </Text>
                        </Wrapper>

                    </Wrapper>
                </Wrapper>

                <Wrapper
                    style={{
                        flexDirection : "column"
                    }}
                    width={`calc(100%)`}
                >
                    <Wrapper
                        width="100%" 
                        wrap={`nowrap`} 
                        ai="center" 
                        jc="space-between" 
                        margin={`0 0 1rem 0`}
                    >
                        <Text 
                            size="2rem" 
                            weight="600" 
                            lh="22px" 
                            ls="-0.28px" 
                            width={`100%`}
                            color={colors.Gr05}
                            to={`ellipsis`}
                            style={{
                                cursor : 'pointer',
                                display : "inline-block"
                            }}

                        >   
                            {
                                `${data?.user_name}`
                            }
                        </Text>
                    </Wrapper>

                    <Wrapper
                        width="100%" 
                        wrap={`nowrap`} 
                        ai="center" 
                        jc="space-between" 
                        margin={`0 0 0.5rem 0`}
                    >
                        <Text 
                            size="1.6rem" 
                            weight="500" 
                            lh="22px" 
                            ls="-0.28px" 
                            width={`100%`}
                            color={colors.Gr05}
                            //ellipsis={true}
                            //clamp={3}
                            style={{
                                cursor : 'pointer',
                                display : "inline-block"
                            }}
                        >   
                            {
                                `전화번호 : ${data?.user_phone}`
                            }
                        </Text>
                    </Wrapper>
                    
                    <Wrapper
                        width="100%" 
                        wrap={`nowrap`} 
                        ai="center" 
                        jc="space-between" 
                        margin={`0 0 0.5rem 0`}
                    >
                        <Text 
                            size="1.6rem" 
                            weight="500" 
                            lh="22px" 
                            ls="-0.28px" 
                            width={`100%`}
                            color={colors.Gr05}
                        >   
                            {
                                `액티비티 : ${data?.activity}`
                            }
                        </Text>
                    </Wrapper>

                </Wrapper>
            </Wrapper>

            <Wrapper 
                wrap={`wrap`} 
                width={`100%`}
                cursor={`pointer`}
            >
                <Wrapper
                    style={{
                        flexDirection : "column"
                    }}
                    width={`calc(100%)`}
                >

                    <Wrapper
                        width="100%" 
                        wrap={`wrap`} 
                        ai="center" 
            
                    >   
                        <Wrapper 
                            width="100%" 
                            jc="space-between" 
                            margin={`0 0 0.4rem 0`}
                            onClick={() => setOpen(!open)}
                        >
                            <Text 
                                size="1.6rem" 
                                weight="500" 
                                lh="22px" 
                                ls="-0.28px" 
                                color={colors.Gr05}
                            >   
                                {
                                    `상담 내용`
                                }
                            </Text>

                            <Arrow 
                                style={{ transition: "all ease 0.5s", transform : `rotate(${open ? '-90deg' : '90deg' })`}} 
                                width="24px" 
                                height="24px" 
                                fill={colors.Bl07} 
                            />
                        </Wrapper>

                        <Wrapper 
                            width="100%" 
                            jc="center" 
                            padding="12px"
                            radius="8px"
                            style={{
                                border : `1px solid ${colors.Gr02}`
                            }}
                        >
                            <Text 
                                size="1.5rem" 
                                weight="500" 
                                lh="21px" 
                                ls="-0.28px" 
                                color={colors.Gr05}
                                width="100%"
                                ellipsis={true}
                                clamp={open ? 99 : 2}
                                style={{
                                    textAlign : "left",
                                    whiteSpace : 'pre-wrap'
                                }}
                            >   
                                {
                                    `${data?.content}`
                                }
                            </Text>
                        </Wrapper>

                    </Wrapper>

                </Wrapper>

            </Wrapper>
        
            <Wrapper 
                width={`100%`}
                jc={`space-between`}
                ai={`flex-end`}
            >
                <Wrapper>
                    { (data?.check == 0) ? 
                            <Wrapper
                                padding={`5px 0px`}
                                bgc={colors.White}
                                radius={`8px`}
                            >
                                <Text 
                                    size="1.6rem" 
                                    weight="600" 
                                    lh="22px" 
                                    ls="-0.28px" 
                                    width={`100%`}
                                    color={colors.Red03}
                                >   
                                    {
                                        `확인 필요`
                                    }
                                </Text>
                            </Wrapper>
                        : 
                            <></>
                    }
                </Wrapper>

                <Wrapper margin={`10px 0 0 0`}>
                    <Wrapper
                        padding={`5px 8px`}
                        bgc={colors.Gr03}
                        radius={`8px`}
                        onClick={() => SendSms()}
                    >
                        <Text 
                            size="1.6rem" 
                            weight="600" 
                            lh="22px" 
                            ls="-0.28px" 
                            width={`100%`}
                            color={colors.White}
                        >   
                            {
                                `문자하기`
                            }
                        </Text>
                    </Wrapper>

                    <Wrapper
                        padding={`5px 8px`}
                        bgc={colors.Bl07}
                        radius={`8px`}
                        margin={`0 0 0 10px`}
                        onClick={() => SendCall()}
                    >
                        <Text 
                            size="1.6rem" 
                            weight="600" 
                            lh="22px" 
                            ls="-0.28px" 
                            width={`100%`}
                            color={colors.White}
                        >   
                            {
                                `전화하기`
                            }
                        </Text>
                    </Wrapper>
                </Wrapper>
            </Wrapper>    

        </Wrapper>
    )
}

export default ConsultCard;