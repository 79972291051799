import React, { useEffect, useState, useRef } from "react";
import styled from "styled-components";

// style
import colors from "../../styles/colors";
import { Wrapper, Text, AbsoluteWrapper } from "../layouts/layoutStorage";

//icon
import { ReactComponent as Arrow } from "../../resource/icon/arrow.svg";

const DefaultHour = [
    "00", "01", "02", "03", "04", "05", "06", "07", "08", "09", "10",
    "11", "12", "13", "14", "15", "16", "17", "18", "19", "20", "21",
    "22", "23", "24"
];

const DefaultMinute = [
    "00", "30"
];

const FacilityTime = ({ data, setData, yoil, yoilKorean }) => {

    const SHRef = useRef();
    const SMRef = useRef();
    const FHRef = useRef();
    const FMRef = useRef();

    const [dropdown, setDropdown] = useState({
        SH: false,
        SM: false,
        FH: false,
        FM: false
    });

    const ThisSchedule = data?.schedule[yoil];

    const SetTime = (type, value) => {

        let ChangeSchedule =  data?.schedule;

        let ChangeTime =  ChangeSchedule[yoil];

        ChangeTime[type] = value;

        ChangeSchedule[yoil] = ChangeTime;

        setData((prev) => ({ ...prev, schedule : ChangeSchedule }));
    }

    return (
        <Wrapper 
            wrap={`nowrap`} 
            width="100%" 
            jc="flex-start" 
            ai="center" 
            bb={`1px solid ${colors.Gr02}`}
            gap="10px"
        >
            
            <Text 
                size={`1.5rem`}
                weight={`600`}
                color={colors.Gr05}
            >
                {yoilKorean}
            </Text>

            <Wrapper
                gap="8px"
                wrap={`nowrap`} 
                ai="center" 
                width="100%" 
                padding={`10px 0`}
            >
                {/* start_hour */}
                <Wrapper
                    ref={SHRef}
                    padding="12px"
                    cursor="pointer"
                    position="relative"
                    onClick={(e) => {
                        e.stopPropagation();
                        setDropdown({
                            SH: !dropdown.SH,
                            SM: false,
                            FH: false,
                            FM: false
                        });
                    }}
                    height="34px"
                    radius="8px"
                    width="100%"
                    bgc={colors.White}
                    border={`1px solid ${colors.Gr02}`}
                    jc="space-between"
                >
                    <Text size="1.5rem" weight="600" lh="22px" ls="-0.28px" color={colors.Gr05}>
                        {ThisSchedule?.start_hour}
                    </Text>

                    <Arrow
                        width="16px"
                        height="16px"
                        fill={colors.Gr04}
                        style={{ transition: "all ease 0.5s", transform: dropdown.SH ? "rotate(-90deg)" : "rotate(90deg)" }}
                    />

                    {dropdown.SH && (
                        <AbsoluteWrapper
                            maxHeight="130px"
                            z={100}
                            fd="column"
                            width="100%"
                            top="39px"
                            left="0"
                            bgc={colors.White}
                            border={`1px solid ${colors.Gr02}`}
                            radius="8px"
                            of="auto"
                        >
                            {DefaultHour.map((item, idx) => (
                                <DropDown
                                    key={`start_hour_${idx}_${yoilKorean}`}
                                    onClick={() => SetTime('start_hour', item) } 
                                    active={item == ThisSchedule?.start_hour}
                                >
                                    {item}
                                </DropDown>
                            ))}
                        </AbsoluteWrapper>
                    )}
                </Wrapper>

                {/* start_minute */}
                <Wrapper
                    ref={SMRef}
                    padding="12px"
                    cursor="pointer"
                    position="relative"
                    onClick={(e) => {
                        e.stopPropagation();
                        setDropdown({
                            SH: false,
                            SM: !dropdown.SM,
                            FH: false,
                            FM: false
                        });
                    }}
                    height="34px"
                    radius="8px"
                    width="100%"
                    bgc={colors.White}
                    border={`1px solid ${colors.Gr02}`}
                    jc="space-between"
                >
                    <Text size="1.5rem" weight="600" lh="22px" ls="-0.28px" color={colors.Gr05}>
                        {ThisSchedule?.start_min}
                    </Text>

                    <Arrow
                        width="16px"
                        height="16px"
                        fill={colors.Gr04}
                        style={{ transition: "all ease 0.5s", transform: dropdown.SH ? "rotate(-90deg)" : "rotate(90deg)" }}
                    />

                    {dropdown.SM && (
                        <AbsoluteWrapper
                            maxHeight="130px"
                            z={100}
                            fd="column"
                            width="100%"
                            top="39px"
                            left="0"
                            bgc={colors.White}
                            border={`1px solid ${colors.Gr02}`}
                            radius="8px"
                            of="auto"
                        >
                            {DefaultMinute.map((item, idx) => (
                                <DropDown
                                    key={`start_min_${idx}_${yoilKorean}`}
                                    onClick={() => SetTime('start_min', item) } 
                                    active={item == ThisSchedule?.start_min}
                                >
                                    {item}
                                </DropDown>
                            ))}
                        </AbsoluteWrapper>
                    )}
                </Wrapper>

                {/* finish_hour */}
                <Wrapper
                    ref={FHRef}
                    padding="12px"
                    cursor="pointer"
                    position="relative"
                    onClick={(e) => {
                        e.stopPropagation();
                        setDropdown({
                            SH: false,
                            SM: false,
                            FH: !dropdown.FH,
                            FM: false
                        });
                    }}
                    height="34px"
                    radius="8px"
                    width="100%"
                    bgc={colors.White}
                    border={`1px solid ${colors.Gr02}`}
                    jc="space-between"
                >
                    <Text size="1.5rem" weight="600" lh="22px" ls="-0.28px" color={colors.Gr05}>
                        {ThisSchedule?.finish_hour}
                    </Text>

                    <Arrow
                        width="16px"
                        height="16px"
                        fill={colors.Gr04}
                        style={{ transition: "all ease 0.5s", transform: dropdown.SH ? "rotate(-90deg)" : "rotate(90deg)" }}
                    />

                    {dropdown.FH && (
                        <AbsoluteWrapper
                            maxHeight="130px"
                            z={100}
                            fd="column"
                            width="100%"
                            top="39px"
                            left="0"
                            bgc={colors.White}
                            border={`1px solid ${colors.Gr02}`}
                            radius="8px"
                            of="auto"
                        >
                            {DefaultHour.map((item, idx) => (
                                <DropDown
                                    key={`finish_hour_${idx}_${yoilKorean}`}
                                    onClick={() => SetTime('finish_hour', item) } 
                                    active={item == ThisSchedule?.finish_hour}
                                >
                                    {item}
                                </DropDown>
                            ))}
                        </AbsoluteWrapper>
                    )}
                </Wrapper>

                {/* finish_minute */}
                <Wrapper
                    ref={FMRef}
                    padding="12px"
                    cursor="pointer"
                    position="relative"
                    onClick={(e) => {
                        e.stopPropagation();
                        setDropdown({
                            SH: false,
                            SM: false,
                            FH: false,
                            FM: !dropdown.FM
                        });
                    }}
                    height="34px"
                    radius="8px"
                    width="100%"
                    bgc={colors.White}
                    border={`1px solid ${colors.Gr02}`}
                    jc="space-between"
                >
                    <Text size="1.5rem" weight="600" lh="22px" ls="-0.28px" color={colors.Gr05}>
                        {ThisSchedule?.finish_min}
                    </Text>

                    <Arrow
                        width="16px"
                        height="16px"
                        fill={colors.Gr04}
                        style={{ transition: "all ease 0.5s", transform: dropdown.SH ? "rotate(-90deg)" : "rotate(90deg)" }}
                    />

                    {dropdown.FM && (
                        <AbsoluteWrapper
                            maxHeight="130px"
                            z={100}
                            fd="column"
                            width="100%"
                            top="39px"
                            left="0"
                            bgc={colors.White}
                            border={`1px solid ${colors.Gr02}`}
                            radius="8px"
                            of="auto"
                        >
                            {DefaultMinute.map((item, idx) => (
                                <DropDown
                                    key={`finish_min_${idx}_${yoilKorean}`}
                                    onClick={() => SetTime('finish_min', item) }
                                    active={item == ThisSchedule?.finish_min}
                                >
                                    {item}
                                </DropDown>
                            ))}
                        </AbsoluteWrapper>
                    )}
                </Wrapper>

            </Wrapper>



        </Wrapper>
    )
};


export default FacilityTime;


const DropDown = styled.div`
    width: 100%;
    padding: 6px 8px;
    border-bottom: ${({ last }) => !last && `1px solid ${colors.Gr02}`};
    color: ${({ active }) => active ? colors.Bl07 : colors.Gr04 };
    font-size: 1.4rem;
    font-weight: ${({ active }) => active ? 600 : 500};
    line-height: 142%;
    letter-spacing: -0.26px;
    text-align : center;
    cursor : pointer;
`;