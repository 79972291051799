import React, { useEffect, useState, useRef } from "react";

import { createGlobalStyle } from "styled-components";

const Path = window.location.pathname;


let Wid = 1300;


export const WIDTH = Wid;
export const PC = Wid;
export const W = (Wid < window.document.documentElement.clientWidth) ? Wid : window.document.documentElement.clientWidth;
export const H = window.innerHeight;

export function useWindowSize() {
  const [size, setSize] = useState(900);
  useEffect(() => {

    function handleResize() {
      let pathS = 1300;
      let WIW = window.document.documentElement.clientWidth
      
      if(pathS < WIW) {
        setSize(pathS);
      } else {
        setSize(WIW);
      }
    }

    window.addEventListener("resize", handleResize);

    handleResize();
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  return size;
}

export function useReactPath() {
  const [size, setSize] = useState(900);
  const listenToPopstate = () => {
      setSize(1300);
  };
  useEffect(() => {
    window.addEventListener("popstate", listenToPopstate);
    return () => {
      window.removeEventListener("popstate", listenToPopstate);
    };
  }, []);
  return size;
};

const GlobalStyle = createGlobalStyle`
  ul, ol, li {
    list-style: none;
    margin: 0;
    padding: 0;
  }

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    transition: background-color 5000s ease-in-out 0s;
    -webkit-transition: background-color 9999s ease-out;
    -webkit-box-shadow: 0 0 0px 1000px background-color inset !important;
  } 
`;

export default GlobalStyle;
