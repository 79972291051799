import React, { useState, useEffect, useRef } from "react";
import styled, { css } from "styled-components";
import { Icon, Wrapper, Text, WrapperColumn, WrapperRow } from "../layouts/layoutStorage";

// style
import { PC, W } from "../../styles/globalStyles";
import colors from "../../styles/colors";

// image
import TooltipClose from "../../icon/tooltipClose.svg";
import { ReactComponent as QuestionCircle } from "../../resource/icon/question_circle.svg";

const Tooltip = ({ boxLeft, boxRight, left, type, children, isCenter = false }) => {
  const ref = useRef();

  const [toolTip, setToolTip] = useState(true);

  useEffect(() => {
    if (toolTip) {
      window.addEventListener("click", CalenderClose);

      return () => {
        window.removeEventListener("click", CalenderClose);
      };
    }
  }, [toolTip]);

  const CalenderClose = (e) => {
    if (toolTip && (!ref.current || !ref.current.contains(e.target))) {
      setToolTip(false);
    }
  };

  return (
    <Wrapper position={isCenter ? "static" : "relative"}>
      <IconContainer
        onClick={(e) => {
          e.stopPropagation();
          setToolTip((prev) => !prev);
        }}
      >
        <QuestionCircle fill={colors.Gr04} />
      </IconContainer>
      {/* <Icon
        onClick={(e) => {
          e.stopPropagation();
          setToolTip((prev) => !prev);
        }}
        cursor="pointer"
        src={TooltipQuestion}
        margin="0 0 0 4px"
        width="24px"
        height="24px"
      /> */}
      {toolTip ? (
        <Container display={toolTip ? `inline-flex` : `none`} boxLeft={boxLeft} boxRight={boxRight} isLeft={type === "left"} isCenter={isCenter}>
          <div ref={ref}>
            <WrapperColumn
              width="100%"
              bgc={colors.White}
              padding="12px"
              border={`1px solid ${colors.Gr02}`}
              radius="3px"
              position={isCenter ? "static" : "relative"}
            >
              {/* <WrapperRow ai="center" jc="space-between" margin="0 0 5px 0"> */}
              {/* {children} */}
              <Icon
                cursor="pointer"
                onClick={() => setToolTip(false)}
                src={TooltipClose}
                width="12px"
                height="12px"
                style={{ position: "absolute", top: 12, right: 12 }}
              />
              {/* </WrapperRow> */}
              {children}

              <Triangle isLeft={type == "left"} left={left} isCenter={isCenter}></Triangle>
            </WrapperColumn>
          </div>
        </Container>
      ) : (
        ""
      )}
    </Wrapper>
  );
};

export default Tooltip;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: ${window.document.documentElement.clientWidth < PC ? W - 48 : 326}px;
  margin: ${({ isCenter }) => (isCenter ? "0px" : "0px 24px")};
  position: absolute;
  z-index: 2;
  top: 30px;
  right: ${({ isLeft, right }) => (!isLeft ? `${right}` : "")};
  left: ${({ isLeft, boxLeft }) => (isLeft ? `${boxLeft}` : "")};
  ${({ isCenter }) =>
    isCenter &&
    css`
      transform: translateX(-50%);
    `}
`;

const IconContainer = styled.div`
  width: 24px;
  height: 24px;
  margin-right: 6px;
  cursor: pointer;
`;

const Triangle = styled.div`
  width: 10px;
  height: 10px;
  background-color: ${colors.White};
  border: 1px solid transparent;
  border-left-width: 1px;
  border-left-color: ${colors.Gr02};
  border-top-width: 1px;
  border-top-color: ${colors.Gr02};
  position: absolute;
  z-index: 2;
  top: ${({ isCenter }) => (isCenter ? "0px" : "-5px")};
  right: ${({ type, left }) => (type ? "" : `${left}`)};
  left: ${({ isLeft, left }) => (isLeft ? `${left}` : "")};
  transform: rotate(45deg) ${({ isCenter }) => (isCenter ? " translateX(-50%)" : "")};
`;
