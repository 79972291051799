import React, { useEffect, useState, useRef } from "react";
import styled, { css } from "styled-components";
import axios from "axios";

//tool
import { GetOne } from "../tool/localstorage";

//style
import { Wrapper, Text, } from "../components/layouts/layoutStorage";
import colors from "../styles/colors";
import {PC, W, useWindowSize } from "../styles/globalStyles";

//component
import { Loading } from "../tool/Loading";
import FacilityHeader from "../components/facilityRegister/FacilityHeaderEnter";
import FacilityManageModal from "../components/facilityCrew/FacilityManageModal";
import CrewRegisterJoin from "../components/facilityCrew/EntranceJoin";

//icon
//import { ReactComponent as Arrow } from "../resource/icon/arrow.svg";
import BlueCheck from "../icon/bluecheck.png";

// Default Data
const IMAGE_RATIO = 234.375 / 375;

const FacilityReservation = () => {

    const [loading, setLoading] = useState(false);
    const [manage, setManage] = useState(false);
    const [facility, setFacility] = useState();
    const [titleList, setTitleList] = useState();
    const [title, setTitle] = useState(0);
    const [data, setData] = useState();
    const [entranceData, setEntranceData] = useState();
    const [entranceModal, setEntranceModal] = useState(false);
    const [joinModal, setJoinModal] = useState(false);
    const [ticketList, setTicketList] = useState();
    const [ticketModal, setTicketModal] = useState(false);
    const [selectTicket, setSelectTicket] = useState();

    const [startTime, setStartTime] = useState();
    const [endTime, setEndTime] = useState();
    const [FID, setFID] = useState(2);
    
    const [step, setStep] = useState(1);
    const [validStep, setValidStep] = useState(1);
    const [phone, setPhone] = useState();
    const [valid, setValid] = useState();
    const [validType, setValidType] = useState();
    const [entranceType, setEntranceType] = useState();
    const [verifyToken, setVerifyToken] = useState("");
    const [crew, setCrew] = useState("");

    const [selectDay, setSelectDay] = useState({
        year : new Date().getFullYear(),
        month : (new Date().getMonth() + 1),
        day : new Date().getDate()
    });

    const [width, setWidth] = useState(useWindowSize());

    let WID = useWindowSize();
    
    useEffect(() => {
        setWidth(WID);
    }, [WID]);

    const InsertNumber = (number) => {
        
        if(validStep < 2) {
            let Phone = phone;

            if(number == 'all_clear') {
                setPhone();
            } else if(number == 'back_space') {
                if(phone || phone == '0') {
                    Phone = Phone.replace('-', "");
                    Phone = Phone.slice(0, -1);
                    Phone = Phone.replace(/[^0-9]/, "").replace(/^(\d{2,3})(\d{3,4})(\d{4})$/, `$1-$2-$3`);
        
                    setPhone(`${Phone}`);
                }
            } else {
                if(phone?.length < 13 || !phone) {
                    if(phone || phone == '0') { 
                        Phone = `${Phone}${number}`;
                        Phone = Phone.replace(/[^0-9]/, "").replace(/^(\d{2,3})(\d{3,4})(\d{4})$/, `$1-$2-$3`);
            
                        setPhone(Phone);
                    } else {
                        setPhone(`${number}`);
                    }
                }
            }
        } else {
            let Valid = valid;
            
            if(number == 'all_clear') {
                setPhone();
            } else if(number == 'back_space') {
                if(valid || valid == '0') {
                    Valid = Valid.slice(0, -1);
                    Valid = Valid.replace(/[^0-9]/, "");
        
                    setValid(`${Valid}`);
                }
            } else {
                if(valid?.length < 6 || !valid) {
                    if(valid || valid == '0') { 
                        Valid = `${Valid}${number}`;
                        Valid = Valid.replace(/[^0-9]/, "");
            
                        setValid(Valid);
                    } else {
                        setValid(`${number}`);
                    }
                }
            }
        }
    }

    const ValidSend = (type) => {

        if(phone?.length > 11) {
            if(type != validType) {
                var token = GetOne("token");

                const frm = new FormData();
                frm.append("mode", "facility_entrance_check");
                frm.append("phone_number", phone);
                frm.append("type", type);
                frm.append("token", token);
        
                axios
                    .post("https://ai.semos.kr/semos_partner/v3/activity/Facility", frm, {
                    headers: {
                        "Content-Type": "multipart/form-data",
                        Accept: "application/json",
                    },
                    })
                    .then((data) => {
                    var Value = data.data;
                    var Status = Value.status;
                    var StatusMessage = Value.status_message;
                    var CheckToken = Value.check_t;
                    var CrewId = Value.crew_id;
        
                    if (Status === "success") {
                        window.alert('인증번호 전송이 완료되었습니다.')
                        setValidStep(2);
                        setValid();
                        setValidType(type);
                        setVerifyToken(CheckToken);
                        setCrew(CrewId);
                    } else if (Status === "fail") {
                        window.alert(StatusMessage);
                    }
                    });
            }
        } else {
            window.alert('전화번호가 올바르지 않습니다.\n전화번호를 확인해주세요!')
        }
    }

    const NotValidEnter = (type) => {

        if(phone?.length > 11) {
            if(type != entranceType) {
                var token = GetOne("token");

                const frm = new FormData();
                frm.append("mode", "facility_entrance_now");
                frm.append("phone_number", phone);
                frm.append("type", type);
                frm.append("token", token);
        
                axios
                    .post("https://ai.semos.kr/semos_partner/v3/activity/Facility", frm, {
                    headers: {
                        "Content-Type": "multipart/form-data",
                        Accept: "application/json",
                    },
                    })
                    .then((data) => {
                        const Value = data.data;
                        const Status = Value.status;
                        const StatusMessage = Value.status_message;
                        const Data = Value.data;
    
                        if (Status === "success") {
                            setEntranceData(Data);
                            setEntranceModal(true);
                            setPhone();
                            setValid();
                            setValidType();
                            setValidStep(1);
                            setCrew();
                        } else if (Status === "empty") {
                            window.alert(`이용 가능한 이용권이 없습니다.\n이용권 구매 후 이용 부탁드립니다.`);
                            setPhone();
                            setValid();
                            setValidType();
                            setValidStep(1);
                            setCrew();
                        } else if (Status === "reservation") {
                            const TicketList = Value.ticket_list;
                            window.alert(`오늘 예약된 내역이 없습니다\n시설 이용을 위해 시설 예약이 필요합니다`);
                            setTicketList(TicketList);
                            setTicketModal(true);
                            setPhone();
                            setValid();
                            setValidType();
                            setValidStep(1);
                            setCrew();
                        } else if (Status === "not_crew") {
                            window.alert(StatusMessage);
                            setJoinModal(true);
                        } else {
                            window.alert(StatusMessage);
                            setPhone();
                            setValid();
                            setValidType();
                            setValidStep(1);
                            setCrew();
                        }
                    });
            }
        } else {
            window.alert('전화번호가 올바르지 않습니다.\n전화번호를 확인해주세요!')
        }
    }

    const authNumCheck = () => {

        if (valid?.length !== 6) {
            alert("인증번호 6자리를 입력해주세요.");
        } else {
            var token = GetOne("token");

            const frm = new FormData();
            frm.append("mode", "entrance_confirm");
            frm.append("verification_token", verifyToken);
            frm.append("verification_number", valid);
            frm.append("phone_number", phone);
            frm.append("crew", crew);
            frm.append("token", token);

            axios
                .post("https://ai.semos.kr/semos_partner/v3/activity/Facility", frm, {
                headers: {
                    "Content-Type": "multipart/form-data",
                    Accept: "application/json",
                },
                })
                .then((data) => {
                    const Value = data.data;
                    const Status = Value.status;
                    const StatusMessage = Value.status_message;
                    const Data = Value.data;

                    if (Status === "success") {
                        setEntranceData(Data);
                        setEntranceModal(true);
                        setPhone();
                        setValid();
                        setValidType();
                        setValidStep(1);
                        setCrew();
                    } else if (Status === "empty") {
                        window.alert(`이용 가능한 이용권이 없습니다.\n이용권 구매 후 이용 부탁드립니다.`);
                        setPhone();
                        setValid();
                        setValidType();
                        setValidStep(1);
                        setCrew();
                    } else if (Status === "reservation") {
                        const TicketList = Value.ticket_list;
                        window.alert(`오늘 예약된 내역이 없습니다\n시설 이용을 위해 시설 예약이 필요합니다`);

                        setTicketList(TicketList);
                        setTicketModal(true);
                        setPhone();
                        setValid();
                        setValidType();
                        setValidStep(1);
                        setCrew();
                    } else {
                        window.alert(StatusMessage);
                        setPhone();
                        setValid();
                        setValidType();
                        setValidStep(1);
                        setCrew();
                    }
                });
            }
    };

    const ValidReturn = () => {

        //let Check = width < 1300;
        let Check = false;

        switch(Check) {

            case true :
                return (
                    <Wrapper
                        jc="flex-start"
                        display="inline-flex"
                        fd={`column`}
                        ai="center"
                        width="100%"
                        height={`calc(100% - 50px)`}
                        border={`1px solid ${colors.Gr02}`}
                        radius={`8px`}
                        of={`hidden auto`}
                        bgc={`${colors.Gr01}`}
                        wrap={`wrap`}
                    >

                        <Wrapper
                            jc="center"
                            display="inline-flex"
                            ai="center"
                            width="50%"
                            height={`100%`}
                            br={`1px solid ${colors.Gr02}`}
                            radius={`8px`}
                            of={`hidden auto`}
                            bgc={`${colors.Gr01}`}
                            wrap={`wrap`}
                        >     
                        
                        </Wrapper>

                        <Wrapper
                            jc="center"
                            display="inline-flex"
                            ai="center"
                            width="50%"
                            height={`100%`}
                            radius={`8px`}
                            of={`hidden auto`}
                            bgc={`${colors.Gr01}`}
                            wrap={`wrap`}
                        >     
                            <Wrapper
                                jc="center"
                                display="inline-flex"
                                ai="center"
                                width="100%"
                                height={`100%`}
                                style={{
                                    maxHeight : 600,
                                    maxWidth : 600
                                }}
                                br={`3px solid ${colors.Gr05}`}
                                cursor={`pointer`}
                                bgc={`${colors.Gr02}`}
                            >
                                <Wrapper
                                    jc="center"
                                    display="inline-flex"
                                    ai="center"
                                    width="calc(100% / 3)"
                                    height="calc(100% / 4)"
                                    br={`3px solid ${colors.Gr05}`}
                                    cursor={`pointer`}
                                    bb={`3px solid ${colors.Gr05}`}
                                >
                                    
                                </Wrapper>

                                <Wrapper
                                    jc="center"
                                    display="inline-flex"
                                    ai="center"
                                    width="calc(100% / 3)"
                                    height="calc(100% / 4)"
                                    br={`3px solid ${colors.Gr05}`}
                                    cursor={`pointer`}
                                    bb={`3px solid ${colors.Gr05}`}
                                >
                                    
                                </Wrapper>

                                <Wrapper
                                    jc="center"
                                    display="inline-flex"
                                    ai="center"
                                    width="calc(100% / 3)"
                                    height="calc(100% / 4)"
                                    bb={`3px solid ${colors.Gr05}`}
                                >
                                    
                                </Wrapper>

                                <Wrapper
                                    jc="center"
                                    display="inline-flex"
                                    ai="center"
                                    width="calc(100% / 3)"
                                    height="calc(100% / 4)"
                                    br={`3px solid ${colors.Gr05}`}
                                    cursor={`pointer`}
                                    bb={`3px solid ${colors.Gr05}`}
                                >
                                    
                                </Wrapper>

                                <Wrapper
                                    jc="center"
                                    display="inline-flex"
                                    ai="center"
                                    width="calc(100% / 3)"
                                    height="calc(100% / 4)"
                                    br={`3px solid ${colors.Gr05}`}
                                    cursor={`pointer`}
                                    bb={`3px solid ${colors.Gr05}`}
                                >
                                    
                                </Wrapper>

                                <Wrapper
                                    jc="center"
                                    display="inline-flex"
                                    ai="center"
                                    width="calc(100% / 3)"
                                    height="calc(100% / 4)"
                                    bb={`3px solid ${colors.Gr05}`}
                                >
                                    
                                </Wrapper>

                                <Wrapper
                                    jc="center"
                                    display="inline-flex"
                                    ai="center"
                                    width="calc(100% / 3)"
                                    height="calc(100% / 4)"
                                    br={`3px solid ${colors.Gr05}`}
                                    cursor={`pointer`}

                                >
                                    
                                </Wrapper>

                                <Wrapper
                                    jc="center"
                                    display="inline-flex"
                                    ai="center"
                                    width="calc(100% / 3)"
                                    height="calc(100% / 4)"
                                    br={`3px solid ${colors.Gr05}`}
                                    cursor={`pointer`}
                                >
                                    
                                </Wrapper>

                                <Wrapper
                                    jc="center"
                                    display="inline-flex"
                                    ai="center"
                                    width="calc(100% / 3)"
                                    height="calc(100% / 4)"
                                >
                                    
                                </Wrapper>

                            </Wrapper>
                        </Wrapper>
                    </Wrapper>
                )

            case false :
                return (
                    <Wrapper
                        jc="flex-start"
                        display="inline-flex"
                        fd={`column`}
                        ai="center"
                        width="100%"
                        height={`calc(100% - 50px)`}
                        border={`1px solid ${colors.Gr02}`}
                        radius={`8px`}
                        of={`hidden auto`}
                        bgc={`${colors.Gr01}`}
                        wrap={`wrap`}
                    >

                        <Wrapper
                            jc="center"
                            display="inline-flex"
                            ai="center"
                            width="50%"
                            height={`100%`}
                            br={`1px solid ${colors.Gr02}`}
                            radius={`8px`}
                            of={`hidden auto`}
                            bgc={`${colors.Gr01}`}
                            wrap={`wrap`}
                        >     
                            <Wrapper
                                jc="center"
                                display="inline-flex"
                                ai="center"
                                width="100%"
                                padding={`0 12px`}
                                wrap={`wrap`}
                            >
                                <Text 
                                    ta="left" 
                                    size="3.5rem" 
                                    weight="700" 
                                    color={colors.Gr05}
                                    width={`100%`}
                                    margin={`0 0 10px 0`}
                                >
                                    {validStep == 1 ? `전화번호를 입력해주세요!` : `인증번호를 입력해주세요!`}
                                </Text>

                                <Wrapper
                                    jc="space-between"
                                    display="inline-flex"
                                    ai="center"
                                    width="100%"
                                    padding={`10px 15px`}
                                    border={`3px solid ${colors.Gr05}`}
                                    radius={`8px`}
                                    bgc={`${colors.Gr01}`}
                                >
                                    <Text 
                                        ta="left" 
                                        size="3.5rem" 
                                        weight="700" 
                                        color={phone?.length ? colors.Gr05 : colors.Gr03}
                                    >
                                        {phone?.length ? `${phone}` : '예시) 01X-XXXX-XXXX'}
                                    </Text>

                                    <Text 
                                        ta="right" 
                                        size="3.5rem" 
                                        weight="700" 
                                        color={colors.Gr05}
                                    >
                                        {`📱`}
                                    </Text>
                                </Wrapper>

                                <Wrapper
                                    jc="flex-end"
                                    display="inline-flex"
                                    ai="center"
                                    width="100%"
                                    radius={`8px`}
                                    margin={`10px 0 0 0`}
                                >
                                    
                                    {/*<Wrapper
                                        jc="center"
                                        display="inline-flex"
                                        ai="center"
                                        padding={`10px 15px`}
                                        radius={`8px`}
                                        border={`1px solid ${colors.Gr02}`}
                                        margin={`0 15px 0 0`}
                                        bgc={colors.Kakao}
                                        cursor={`pointer`}
                                        onClick={() => ValidSend('KAKAO')}
                                    >
                                        <Text 
                                            ta="center" 
                                            size="3rem" 
                                            weight="700" 
                                            color={colors.Gr05}
                                        >
                                            {`카카오톡 인증`}
                                        </Text> 
                                    </Wrapper>

                                    <Wrapper
                                        jc="center"
                                        display="inline-flex"
                                        ai="center"
                                        padding={`10px 15px`}
                                        radius={`8px`}
                                        border={`1px solid ${colors.Gr02}`}
                                        bgc={colors.Bl07}
                                        cursor={`pointer`}
                                        onClick={() => ValidSend('SNS')}
                                    >
                                        <Text 
                                            ta="center" 
                                            size="3rem" 
                                            weight="700" 
                                            color={colors.White}
                                        >
                                            {`문자 인증`}
                                        </Text> 
                                    </Wrapper>*/}

                                    <Wrapper
                                        jc="center"
                                        display="inline-flex"
                                        ai="center"
                                        padding={`10px 15px`}
                                        radius={`8px`}
                                        border={`1px solid ${colors.Gr02}`}
                                        margin={`0 15px 0 0`}
                                        bgc={colors.Green03}
                                        cursor={`pointer`}
                                        onClick={() => NotValidEnter('ONEDAY')}
                                    >
                                        <Text 
                                            ta="center" 
                                            size="3.5rem" 
                                            weight="700" 
                                            color={colors.White}
                                        >
                                            {`일일 입장`}
                                        </Text> 
                                    </Wrapper>

                                    <Wrapper
                                        jc="center"
                                        display="inline-flex"
                                        ai="center"
                                        padding={`10px 15px`}
                                        radius={`8px`}
                                        border={`1px solid ${colors.Gr02}`}
                                        bgc={colors.Bl07}
                                        cursor={`pointer`}
                                        onClick={() => NotValidEnter('NORMAL')}
                                    >
                                        <Text 
                                            ta="center" 
                                            size="3.5rem" 
                                            weight="700" 
                                            color={colors.White}
                                        >
                                            {`회원 입장`}
                                        </Text>  
                                    </Wrapper>
                                
                                </Wrapper>

                                {validStep == 2 &&
                                    <>
                                        <Wrapper
                                            jc="space-between"
                                            display="inline-flex"
                                            ai="center"
                                            width="100%"
                                            padding={`10px 15px`}
                                            border={`3px solid ${colors.Gr05}`}
                                            radius={`8px`}
                                            bgc={`${colors.Gr02}`}
                                            margin={`15px 0 0 0`}
                                        >
                                            <Text 
                                                ta="left" 
                                                size="3.5rem" 
                                                weight="700" 
                                                color={valid?.length ? colors.Gr05 : colors.Gr03}
                                            >
                                                {valid?.length ? valid : '6자리의 인증번호를 입력해주세요!'}
                                            </Text>

                                            <Text 
                                                ta="right" 
                                                size="3.5rem" 
                                                weight="700" 
                                                color={colors.Gr05}
                                            >
                                                {``}
                                            </Text>
                                        </Wrapper>

                                        <Wrapper
                                            jc="flex-end"
                                            display="inline-flex"
                                            ai="center"
                                            width="100%"
                                            radius={`8px`}
                                            margin={`10px 0 0 0`}
                                        >
                                            <Wrapper
                                                jc="center"
                                                display="inline-flex"
                                                ai="center"
                                                padding={`10px 15px`}
                                                radius={`8px`}
                                                border={`1px solid ${colors.Gr02}`}
                                                bgc={colors.Red03}
                                                cursor={`pointer`}
                                                margin={`0 15px 0 0`}
                                                onClick={() => {
                                                    setPhone();
                                                    setValid();
                                                    setValidType();
                                                    setValidStep(1);
                                                    setCrew();
                                                }}
                                            >
                                                <Text 
                                                    ta="center" 
                                                    size="3rem" 
                                                    weight="700" 
                                                    color={colors.White}
                                                >
                                                    {`처음부터`}
                                                </Text> 
                                            </Wrapper>

                                            <Wrapper
                                                jc="center"
                                                display="inline-flex"
                                                ai="center"
                                                padding={`10px 15px`}
                                                radius={`8px`}
                                                border={`1px solid ${colors.Gr02}`}
                                                bgc={colors.Bl07}
                                                cursor={`pointer`}
                                                onClick={() => authNumCheck()}
                                            >
                                                <Text 
                                                    ta="center" 
                                                    size="3rem" 
                                                    weight="700" 
                                                    color={colors.White}
                                                >
                                                    {`인증하기`}
                                                </Text> 
                                            </Wrapper>
                                        
                                        </Wrapper>
                                    </>
                                    
                                }

                            </Wrapper>
                        </Wrapper>

                        <Wrapper
                            jc="center"
                            display="inline-flex"
                            ai="center"
                            width="50%"
                            height={`100%`}
                            radius={`8px`}
                            of={`hidden auto`}
                            bgc={`${colors.Gr01}`}
                            wrap={`wrap`}
                        >     
                            <Wrapper
                                jc="center"
                                display="inline-flex"
                                ai="center"
                                width="100%"
                                height={`100%`}
                                style={{
                                    maxHeight : 600,
                                    maxWidth : 450
                                }}
                                border={`3px solid ${colors.Gr05}`}
                                wrap={`wrap`}
                                radius={`8px`}
                                bgc={`${colors.Gr01}`}
                            >
                                <Wrapper
                                    jc="center"
                                    display="inline-flex"
                                    ai="center"
                                    width="calc(100% / 3)"
                                    height="calc(100% / 4)"
                                    br={`3px solid ${colors.Gr05}`}
                                    cursor={`pointer`}
                                    bb={`3px solid ${colors.Gr05}`}
                                    onClick={() => InsertNumber(1)}
                                >
                                    <Text 
                                        ta="center" 
                                        size="8rem" 
                                        weight="700" 
                                        color={colors.Gr05}
                                    >
                                        1
                                    </Text>
                                </Wrapper>

                                <Wrapper
                                    jc="center"
                                    display="inline-flex"
                                    ai="center"
                                    width="calc(100% / 3)"
                                    height="calc(100% / 4)"
                                    br={`3px solid ${colors.Gr05}`}
                                    cursor={`pointer`}
                                    bb={`3px solid ${colors.Gr05}`}
                                    onClick={() => InsertNumber(2)}
                                >
                                    <Text 
                                        ta="center" 
                                        size="8rem" 
                                        weight="700" 
                                        color={colors.Gr05}
                                    >
                                        2
                                    </Text>
                                </Wrapper>

                                <Wrapper
                                    jc="center"
                                    display="inline-flex"
                                    ai="center"
                                    width="calc(100% / 3)"
                                    height="calc(100% / 4)"
                                    bb={`3px solid ${colors.Gr05}`}
                                    cursor={`pointer`}
                                    onClick={() => InsertNumber(3)}
                                >
                                    <Text 
                                        ta="center" 
                                        size="8rem" 
                                        weight="700" 
                                        color={colors.Gr05}
                                    >
                                        3
                                    </Text>
                                </Wrapper>

                                <Wrapper
                                    jc="center"
                                    display="inline-flex"
                                    ai="center"
                                    width="calc(100% / 3)"
                                    height="calc(100% / 4)"
                                    br={`3px solid ${colors.Gr05}`}
                                    cursor={`pointer`}
                                    bb={`3px solid ${colors.Gr05}`}
                                    onClick={() => InsertNumber(4)}
                                >
                                    <Text 
                                        ta="center" 
                                        size="8rem" 
                                        weight="700" 
                                        color={colors.Gr05}
                                    >
                                        4
                                    </Text>
                                </Wrapper>

                                <Wrapper
                                    jc="center"
                                    display="inline-flex"
                                    ai="center"
                                    width="calc(100% / 3)"
                                    height="calc(100% / 4)"
                                    br={`3px solid ${colors.Gr05}`}
                                    cursor={`pointer`}
                                    bb={`3px solid ${colors.Gr05}`}
                                    onClick={() => InsertNumber(5)}
                                >
                                    <Text 
                                        ta="center" 
                                        size="8rem" 
                                        weight="700" 
                                        color={colors.Gr05}
                                    >
                                        5
                                    </Text>
                                </Wrapper>

                                <Wrapper
                                    jc="center"
                                    display="inline-flex"
                                    ai="center"
                                    width="calc(100% / 3)"
                                    height="calc(100% / 4)"
                                    bb={`3px solid ${colors.Gr05}`}
                                    cursor={`pointer`}
                                    onClick={() => InsertNumber(6)}
                                >
                                    <Text 
                                        ta="center" 
                                        size="8rem" 
                                        weight="700" 
                                        color={colors.Gr05}
                                    >
                                        6
                                    </Text>
                                </Wrapper>

                                <Wrapper
                                    jc="center"
                                    display="inline-flex"
                                    ai="center"
                                    width="calc(100% / 3)"
                                    height="calc(100% / 4)"
                                    br={`3px solid ${colors.Gr05}`}
                                    cursor={`pointer`}
                                    bb={`3px solid ${colors.Gr05}`}
                                    onClick={() => InsertNumber(7)}
                                >
                                    <Text 
                                        ta="center" 
                                        size="8rem" 
                                        weight="700" 
                                        color={colors.Gr05}
                                    >
                                        7
                                    </Text>
                                </Wrapper>

                                <Wrapper
                                    jc="center"
                                    display="inline-flex"
                                    ai="center"
                                    width="calc(100% / 3)"
                                    height="calc(100% / 4)"
                                    br={`3px solid ${colors.Gr05}`}
                                    cursor={`pointer`}
                                    bb={`3px solid ${colors.Gr05}`}
                                    onClick={() => InsertNumber(8)}
                                >
                                    <Text 
                                        ta="center" 
                                        size="8rem" 
                                        weight="700" 
                                        color={colors.Gr05}
                                    >
                                        8
                                    </Text>
                                </Wrapper>

                                <Wrapper
                                    jc="center"
                                    display="inline-flex"
                                    ai="center"
                                    width="calc(100% / 3)"
                                    height="calc(100% / 4)"
                                    bb={`3px solid ${colors.Gr05}`}
                                    cursor={`pointer`}
                                    onClick={() => InsertNumber(9)}
                                >
                                    <Text 
                                        ta="center" 
                                        size="8rem" 
                                        weight="700" 
                                        color={colors.Gr05}
                                    >
                                        9
                                    </Text>
                                </Wrapper>

                                <Wrapper
                                    jc="center"
                                    display="inline-flex"
                                    ai="center"
                                    width="calc(100% / 3)"
                                    height="calc(100% / 4)"
                                    br={`3px solid ${colors.Gr05}`}
                                    cursor={`pointer`}
                                    onClick={() => InsertNumber('all_clear')}
                                >
                                    <Text 
                                        ta="center" 
                                        size="8rem" 
                                        weight="700" 
                                        color={colors.Gr05}
                                    >
                                        🔄
                                    </Text>
                                </Wrapper>

                                <Wrapper
                                    jc="center"
                                    display="inline-flex"
                                    ai="center"
                                    width="calc(100% / 3)"
                                    height="calc(100% / 4)"
                                    br={`3px solid ${colors.Gr05}`}
                                    cursor={`pointer`}
                                    onClick={() => InsertNumber(0)}
                                >
                                    <Text 
                                        ta="center" 
                                        size="8rem" 
                                        weight="700" 
                                        color={colors.Gr05}
                                    >
                                        0
                                    </Text>
                                </Wrapper>

                                <Wrapper
                                    jc="center"
                                    display="inline-flex"
                                    ai="center"
                                    width="calc(100% / 3)"
                                    height="calc(100% / 4)"
                                    cursor={`pointer`}
                                    onClick={() => InsertNumber('back_space')}
                                >
                                    <Text 
                                        ta="center" 
                                        size="8rem" 
                                        weight="700" 
                                        color={colors.Gr05}
                                    >
                                        🔙
                                    </Text>
                                </Wrapper>

                            </Wrapper>
                        </Wrapper>
                    </Wrapper>
                )
            
            default : 
                return (
                    <></>
                )  
        }
    }

    const CheckBtweenNotEmpty = (ST, ET, BETWEEN, index) => {
        const YMD = `${selectDay.year}.${(selectDay.month > 9) ? selectDay.month : `0${selectDay.month}`}.${(selectDay.day > 9) ? selectDay.day : `0${selectDay.day}`}`;
        const YMDMINUS = `${selectDay.year}-${(selectDay.month > 9) ? selectDay.month : `0${selectDay.month}`}-${(selectDay.day > 9) ? selectDay.day : `0${selectDay.day}`}`;

        let type = selectTicket.type;
        let classTime = selectTicket.classTime;

        let Check = true;

        for(let i=0; i < (BETWEEN + 1); i++) {
            const YMDDATE = new Date(`${YMDMINUS} ${ST}`);
            YMDDATE.setHours(YMDDATE.getHours() + i);
            const NewHours = `${YMDDATE.getHours() > 9 ? `${YMDDATE.getHours()}:00` : `0${YMDDATE.getHours()}`}:00`;
            const NewYMD = `${YMD} ${NewHours}`;

            if(facility[index]?.off_list[NewYMD] == false) {

                let ticketTime = "";

                if(classTime) {
                    ticketTime = classTime.split(' ~')[0];
                }

                if(type == '정기 레슨' && ticketTime == NewHours) {
                } else {
                    Check = false;
                    break;
                }
            }
        }

        return Check;
    };

    const HandleTime = (time, fid, index) => {

        if(fid != FID) {
            let newTime = time.split(' ')[1];
            setEndTime();
            setStartTime(newTime);

            setFID(fid)
        } else {
            let newTime = time.split(' ')[1];

            let ST = startTime;
            let ET = endTime;
            let type = selectTicket.type;
            let classDay = selectTicket.classDay;
            let maxTime = selectTicket.max_time;
    
            if(ST && ET) {
                setEndTime();
                setStartTime(newTime);
            } else if(ST && !ET) {
                let STH = Number(ST.split(':')[0]);
                let TTH = Number(newTime.split(':')[0]);
                let BTT = Math.abs(STH - TTH);
    
                if(type == '정기 레슨' && classDay) {
                    BTT = BTT - 1;
                }
    
                if(BTT < maxTime) {
                    if(STH > TTH) {
                        if(CheckBtweenNotEmpty(newTime, ST, BTT, index)) {
                            setStartTime(newTime);
                            setEndTime(ST);
                        } else {
                            window.alert('선택한 일정 사이에 마감된 시간이 있습니다.\n다른 시간을 선택해주세요!')
                        }
                    } else if(STH < TTH) {
                        if(CheckBtweenNotEmpty(ST, newTime, BTT, index)) {
                            setStartTime(ST);
                            setEndTime(newTime);
                        } else {
                            window.alert('선택한 일정 사이에 마감된 시간이 있습니다.\n다른 시간을 선택해주세요!')
                        }
                    }
                } else {
                    window.alert(`${'최대로 선택할 수 있는 시간은'} ${maxTime}${'시간 입니다.'}`)
                }
            } else if(!ST && !ET) {
                setEndTime();
                setStartTime(newTime);
            }
        }
    }

    const BetweenCheck = (time, id) => {
        let Check = true;

        if(FID == id) {
            if(startTime && endTime) {
                let ST = Number(startTime.split(":")[0]);
                let ET = Number(endTime.split(":")[0]);
                let TT = Number(time.split(":")[0]);
    
                if((ST <= TT) && (ET >= TT)) {
                    Check = true;
                } else {
                    Check = false;
                }
            } else if(startTime && !endTime) {
                if(startTime == time) {
                    Check = true;
                } else {
                    Check = false;
                }
            } else if(!startTime && !endTime) {
                Check = false;
            }
        } else {
            Check = false;
        }

        return Check;
    }


    const Reservation = async (Ticket) => {
        setLoading(true);

        var token = GetOne("token");
    
        if (
            token && 
            //(startTime || endTime) && 
            selectDay?.year && 
            selectDay?.month && 
            selectDay?.day
        ) {
            const frm = new FormData();
        
            frm.append('mode', 'facility_ticket_use');
            frm.append('token', token);
            /*frm.append('start_time', startTime);
            frm.append('end_time', endTime);*/
            frm.append("year", selectDay.year);
            frm.append("month", (selectDay.month > 9) ? selectDay.month : `0${selectDay.month}`);
            frm.append("day", (selectDay.day > 9) ? selectDay.day : `0${selectDay.day}`);
            frm.append("ticket_id", Ticket.ticket_id);
            frm.append("activity_id", Ticket.activity_id);
            frm.append("payment_id", Ticket.payment_id);
            frm.append("facility_id", FID);
    
            axios
            .post('https://ai.semos.kr/semos_partner/v3/activity/Facility', frm, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    Accept: 'application/json',
                },
            })
            .then(res => {
                const Value = res.data;
                const status = Value.status;
                const statusMessage = Value.status_message;
                const Data = Value.data;
        
                if (status === 'success') {
                    setStep(1);
                    setEntranceData(Data);
                    setEntranceModal(true);
                    setTicketModal(false);
                    setFID();
                    setStartTime();
                    setEndTime();
                } else if (status === 'fail') {
                    window.alert(statusMessage);
                    window.location.reload();
                } else if (status === 'waring') {
                    window.alert(statusMessage);
                    window.location.reload();
                }

                setLoading(false);
            });
        } else {
            window.alert('선택하지 않은 정보가 있습니다.\n정보를 다시 한번 확인해주세요!');
            setLoading(false);
        }
    };

    const ListReturn = () => {

        let Check = width < 1300;

        switch(Check) {

            case true :
                if(facility) {
                    let target = facility[title];
                    const ChartTime = target.chart_time;
                    const ChartUser = target.chart_user;
                    const TicketList = target.ticket_list;
                    const ChartCount = target.chart_count;
                    const TimeList = target.time_list;
                    const FacilityId = target.id;
                    const OffList = target.off_list;
                    const CapacityList = target.capacity_list;
    
                    return (
                        <>
                            <Wrapper
                                display="inline-block"
                                width="100%"
                                height={`50px`}
                                style={{
                                    overflowY : 'hidden',
                                    overflowX : 'auto',
                                    whiteSpace : "nowrap",
                                }}
                            >
                                {
                                    titleList?.map((item, idx) => (
                                        <Wrapper
                                            key={`${idx}_chart`}
                                            display="inline-flex"
                                            padding={`5px 8px`}
                                            radius={`8px`}
                                            border={`1px solid ${(idx == title) ? colors.Bl07 : colors.Gr03}`}
                                            bgc={`${colors.White}`}
                                            ai={`center`}
                                            jc={`center`}
                                            cursor={`pointer`}
                                            margin={(idx == 0) ? `0` : `0 0 0 8px`}
                                            onClick={() => setTitle(idx)}
                                        >
                                            <Text 
                                                ta="center" 
                                                size="1.6rem" 
                                                weight="600" 
                                                color={(idx == title) ? colors.Bl07 :  colors.Gr05}
                                            >
                                                {item}
                                            </Text>
                                        </Wrapper>
                                    ))
                                }
                            </Wrapper>

                            <Wrapper
                                jc="flex-start"
                                display="inline-flex"
                                fd={`column`}
                                ai="center"
                                width="100%"
                                height={`calc(100% - 50px)`}
                                border={`1px solid ${colors.Gr02}`}
                                radius={`8px`}
                                of={`hidden auto`}
                                bgc={`${colors.Gr01}`}
                                wrap={`wrap`}
                                position={`relative`}
                                padding={`0 0 60px 0`}
                            >
                                <Wrapper
                                    ai="center"
                                    width={`calc(100%)`}
                                    radius={`8px`}
                                    jc={`center`}
                                    bgc={colors.Bl07}
                                    height={`50px`}
                                    position={`absolute`}
                                    style={{
                                        bottom : 0,
                                        left : 0
                                    }}
                                    onClick={() => Reservation()}
                                >
                                    <Text 
                                        ta="center" 
                                        size="1.8rem" 
                                        weight="600" 
                                        color={colors.White}
                                    >
                                        {`${TimeFullText()} 예약하기`}
                                    </Text>
                                </Wrapper>
                                {
                                            <Wrapper
                                                jc="flex-start"
                                                display="inline-block"
                                                fd={`column`}
                                                ai="center"
                                                width={`calc(100%)`}
                                                height={`auto`}
                                                border={`1px solid ${colors.Gr02}`}
                                                radius={`8px`}
                                                of={`hidden auto`}
                                                bgc={`${colors.Gr01}`}
                                                wrap={`wrap`}
                                            >
                                                <Wrapper
                                                        bb={`solid 1px ${colors.Gr02}`}
                                                        br={`solid 1px ${colors.Gr02}`}
                                                        bgc={`${colors.Gr01}`}
                                                        width={`100%`}
                                                        //height={`45px`}
                                                        position={`sticky`}
                                                        style={{
                                                            top : 0,
                                                            left : 0,
                                                            zIndex : 10,
                                                            minHeight : 45
                                                        }}
                                                >
                                                        <Wrapper
                                                            width={`100%`}
                                                            //height={`45px`}
                                                            ai={`center`}
                                                            jc={`center`}
                                                            bgc={`${colors.Gr01}`}
                                                            style={{
                                                                minHeight : 45
                                                            }}
                                                        >
                                                            <Text 
                                                                ta="center" 
                                                                size="1.8rem" 
                                                                weight="600" 
                                                                color={colors.Gr05}
                                                            >
                                                                {target.title}
                                                            </Text>
                                                        </Wrapper>
                        
                                                </Wrapper>    
        
                                                {ChartTime?.map((item, idx) => (
                    
                                                    <Wrapper
                                                        key={`${idx}_time_chart`}
                                                        bb={`solid 1px ${colors.Gr02}`}
                                                        br={`solid 1px ${colors.Gr02}`}
                                                        width={`100%`}
                                                        display={`flex`}
                                                        //height={`45px`}
                                                        style={{
                                                            minHeight : 45
                                                        }}
                                                        bgc={OffList[item] ? 'transparent' : colors.B20}
                                                        cursor={`pointer`}
                                                        onClick={() => {
                                                            if(OffList[item]) {
                                                                HandleTime(item, FacilityId, title);
                                                            }
                                                        }}
                                                    >
                                                        <Wrapper
                                                            width={`20%`}
                                                            //height={`45px`}
                                                            //br={`1px solid ${colors.Gr02}`}
                                                            ai={`center`}
                                                            jc={`center`}
                                                            style={{
                                                                minHeight : 45,
                                                                height : '100%'
                                                            }}
                                                        >
                                                            <Text 
                                                                ta="center" 
                                                                size="1.6rem" 
                                                                weight="600" 
                                                                color={colors.Gr05}
                                                            >
                                                                {item.split(' ')[1]}
                                                            </Text>
                                                        </Wrapper>
                        
                                                        <Wrapper
                                                            width={`80%`}
                                                            //height={`45px`}
                                                            bl={`1px solid ${colors.Gr02}`}
                                                            padding={`2px 10px 2px 10px`}
                                                            wrap={`nowrap`}
                                                            gap={`5px`}
                                                            style={{
                                                                minHeight : 45
                                                            }}
                                                            jc={`flex-end`}
                                                        >
                                                            {/*<Wrapper
                                                                width={`100%`}
                                                                wrap={`wrap`}
                                                                jc={`flex-end`}
                                                                br={`solid 1px ${colors.Gr02}`}
                                                                padding={`0 3px 0 0 `}
                                                                gap={`5px`}
                                                            >
                                                                {
                                                                    ChartUser[item]?.map((i, id) => (
                                                                        <Text 
                                                                            key={`${idx}_${id}_chart`}
                                                                            ta="right" 
                                                                            size="1.6rem" 
                                                                            weight="600" 
                                                                            color={colors.Gr05}
                                                                            jc={`flex-end`}
                                                                        >
                                                                            {(id == 0) ? `${ReturnName(i.name)}` : `, ${ReturnName(i.name)}`}
                                                                        </Text>
                                                                    ))
                                                                }
                                                                
                                                            </Wrapper>*/}
                        
                                                            <Text 
                                                                ta="right" 
                                                                size="1.6rem" 
                                                                weight="600" 
                                                                width={`50px`}
                                                                height={`100%`}
                                                                color={colors.Gr05}
                                                                ai={`center`}
                                                                jc={`center`}
                                                            >
                                                                {`${ChartCount[item]}/${CapacityList[item]}`}
                                                            </Text>

                                                            {BetweenCheck(item.split(' ')[1], FacilityId) == true ? 
                                                                <img 
                                                                    style={{
                                                                        width: 25,
                                                                        height: 25,
                                                                        display: "inline-block"
                                                                    }}
                                                                    alt={`blueCheck`}
                                                                    src={BlueCheck}
                                                                /> 
                                                            : <></>}
                        
                                                        </Wrapper>
                                                    </Wrapper>
                                                ))}
                                            </Wrapper>
                                }
                            </Wrapper> 
                        </>
                    )
                }
            break;

            case false :
                return (
                    <Wrapper
                        jc="flex-start"
                        display="inline-flex"
                        fd={`column`}
                        ai="center"
                        width="100%"
                        height={`calc(100% - 50px)`}
                        border={`1px solid ${colors.Gr02}`}
                        radius={`8px`}
                        of={`hidden auto`}
                        bgc={`${colors.Gr01}`}
                        wrap={`wrap`}
                    >
                        {
                            facility?.map((item2, idx2) => {
                                const ChartTime = item2.chart_time;
                                const ChartUser = item2.chart_user;
                                const TicketList = item2.ticket_list;
                                const ChartCount = item2.chart_count;
                                const TimeList = item2.time_list;
                                const OffList = item2.off_list;
                                const CapacityList = item2.capacity_list;
                                const FacilityId = item2.id;

                                return (
                                    <Wrapper
                                        key={`${idx2}_chart`}
                                        jc="flex-start"
                                        display="inline-block"
                                        fd={`column`}
                                        ai="center"
                                        width={`calc(100% / ${facility?.length})`}
                                        height={`auto`}
                                        border={`1px solid ${colors.Gr02}`}
                                        radius={`8px`}
                                        of={`hidden auto`}
                                        bgc={`${colors.Gr01}`}
                                        wrap={`wrap`}
                                        position={`relative`}
                                        padding={`0 0 60px 0`}
                                    >
                                        <Wrapper
                                            ai="center"
                                            width={`calc(100%)`}
                                            radius={`8px`}
                                            jc={`center`}
                                            bgc={colors.Bl07}
                                            height={`50px`}
                                            position={`absolute`}
                                            style={{
                                                bottom : 0,
                                                left : 0
                                            }}
                                            onClick={() => Reservation()}
                                        >
                                            <Text 
                                                ta="center" 
                                                size="1.8rem" 
                                                weight="600" 
                                                color={colors.White}
                                            >
                                                {`${TimeFullText()} 예약하기`}
                                            </Text>
                                        </Wrapper>

                                        <Wrapper
                                                bb={`solid 1px ${colors.Gr02}`}
                                                br={`solid 1px ${colors.Gr02}`}
                                                bgc={`${colors.Gr01}`}
                                                width={`100%`}
                                                //height={`45px`}
                                                position={`sticky`}
                                                style={{
                                                    top : 0,
                                                    left : 0,
                                                    zIndex : 10,
                                                    minHeight : 45
                                                }}
                                        >
                                                <Wrapper
                                                    width={`100%`}
                                                    //height={`45px`}
                                                    ai={`center`}
                                                    jc={`center`}
                                                    bgc={`${colors.Gr01}`}
                                                    style={{
                                                        minHeight : 45
                                                    }}
                                                >
                                                    <Text 
                                                        ta="center" 
                                                        size="2rem" 
                                                        weight="700" 
                                                        color={colors.Gr05}
                                                    >
                                                        {item2.title}
                                                    </Text>
                                                </Wrapper>
                
                                        </Wrapper>    

                                        {ChartTime?.map((item, idx) => (
            
                                            <Wrapper
                                                key={`${idx2}_${idx}_time_chart`}
                                                bb={`solid 1px ${colors.Gr02}`}
                                                br={`solid 1px ${colors.Gr02}`}
                                                width={`100%`}
                                                //height={`45px`}
                                                style={{
                                                    minHeight : 45
                                                }}
                                                cursor={`pointer`}
                                                onClick={() => {
                                                    if(OffList[item]) {
                                                        HandleTime(item, FacilityId, idx2);
                                                    }
                                                }}
                                                bgc={OffList[item] ? 'transparent' : colors.B20}
                                            >
                                                <Wrapper
                                                    width={`20%`}
                                                    //height={`45px`}
                                                    //br={`1px solid ${colors.Gr02}`}
                                                    ai={`center`}
                                                    jc={`center`}
                                                    style={{
                                                        minHeight : 45,
                                                        height : '100%'
                                                    }}
                                                >
                                                    <Text 
                                                        ta="center" 
                                                        size="1.8rem" 
                                                        weight="500" 
                                                        color={colors.Gr05}
                                                    >
                                                        {item.split(' ')[1]}
                                                    </Text>
                                                </Wrapper>
                
                                                <Wrapper
                                                    width={`80%`}
                                                    //height={`45px`}
                                                    bl={`1px solid ${colors.Gr02}`}
                                                    padding={`2px 10px 2px 10px`}
                                                    wrap={`nowrap`}
                                                    gap={`5px`}
                                                    style={{
                                                        minHeight : 45
                                                    }}
                                                    jc={`flex-end`}
                                                >
                                                    {/*<Wrapper
                                                        width={`100%`}
                                                        wrap={`wrap`}
                                                        jc={`flex-end`}
                                                        br={`solid 1px ${colors.Gr02}`}
                                                        padding={`0 3px 0 0 `}
                                                        gap={`5px`}
                                                    >
                                                        {
                                                            ChartUser[item]?.map((i, id) => (
                                                                <Text 
                                                                    key={`${idx2}_${idx}_${id}_user_chart`}
                                                                    ta="right" 
                                                                    size="1.8rem" 
                                                                    weight="500" 
                                                                    color={colors.Gr05}
                                                                    jc={`flex-end`}
                                                                >
                                                                    {(id == 0) ? `${ReturnName(i.name)}` : `, ${ReturnName(i.name)}`}
                                                                </Text>
                                                            ))
                                                        }
                                                        
                                                    </Wrapper>*/}
                
                                                    <Text 
                                                        ta="right" 
                                                        size="1.8rem" 
                                                        weight="500" 
                                                        width={`50px`}
                                                        height={`100%`}
                                                        color={colors.Gr05}
                                                        ai={`center`}
                                                        jc={`center`}
                                                    >
                                                        {`${ChartCount[item]}/${CapacityList[item]}`}
                                                    </Text>

                                                    {BetweenCheck(item.split(' ')[1], FacilityId) == true ? 
                                                        <img 
                                                            style={{
                                                                width: 25,
                                                                height: 25,
                                                                display: "inline-block"
                                                            }}
                                                            alt={`blueCheck`}
                                                            src={BlueCheck}
                                                        /> 
                                                    : <></>}
                
                                                </Wrapper>
                                            </Wrapper>
                                        ))}
                                    </Wrapper>
                                )

                            })

                        }
                    </Wrapper> 
                )
            default :
                return (
                    <></>   
                )

        }

    } 

    const SelectTicket = (item) => {
        Reservation(item);
    }

    const LoadFacilityList = () => {

        setLoading(true);

        var token = GetOne("token");

        const frm = new FormData();

        frm.append("mode", "facility_entrance");
        frm.append("token", token);
        frm.append("year", selectDay.year);
        frm.append("month", (selectDay.month > 9) ? selectDay.month : `0${selectDay.month}`);
        frm.append("day", (selectDay.day > 9) ? selectDay.day : `0${selectDay.day}`);
        frm.append("ticket_id", selectTicket.ticket_id);
        frm.append("activity_id", selectTicket.activity_id);
        frm.append("payment_id", selectTicket.payment_id);

        axios
        .post("https://ai.semos.kr/semos_partner/v3/activity/Facility", frm, {
            headers: { "Content-Type": "multipart/form-data" },
        })
        .then((data) => {
            var Value = data.data;
            var Status = Value.status;
            var StatusMessage = Value.status_message;
            var FacilityInfo = Value.facility_info;
            var FacilityTitle = Value.facility_title;

            if (Status == "success") {
                setFacility(FacilityInfo);
                setTitleList(FacilityTitle);

                setLoading(false);
            } else {
                window.alert(StatusMessage);
                setLoading(false);
            }
        });

    }

    useEffect(() => {
        if(step == 2 && selectTicket) {
            LoadFacilityList();
        }
    }, [step, selectTicket]);

    const TimeFullText = () => {
        let Time = "";

        if(startTime && endTime) {
            let startEx = Number(endTime.split(':')[0]);
            let endEx = (startEx + 1) > 9 ? (startEx + 1) : `0${startEx + 1}`;

            Time = `${startTime} ~ ${endEx}:00`;
        } else if(startTime && !endTime) {
            let startEx = Number(startTime.split(':')[0]);
            let endEx = (startEx + 1) > 9 ? (startEx + 1) : `0${startEx + 1}`;

            Time = `${startTime} ~ ${endEx}:00`;
        }

        return Time;
    }

    return (
        <Container id="description_container">

            <FacilityHeader
                status={true}
                title={'입장 확인/예약'}
                available={false}
                statusHandler={() => console.log("신규 회원 등록")}
            />

            <Wrapper
                jc="flex-start"
                ai="center"
                fd={`column`}
                width="100%"
                height={`calc((var(--vh, 1vh) * 100) - 40px)`}
                padding={`0 24px 10px 24px`}
                margin={`0 0 0 0`}
                gap={`10px`}
                bb={`1px solid ${colors.Gr02}`}
            >
                <Wrapper
                    width={`100%`}
                    height={`50px`}
                    jc={'center'}
                    ai={`center`}
                    position={`relative`}
                >
                    {/*<Wrapper
                        margin={`0 3rem 0 0`}
                        cursor={`pointer`}
                        onClick={() => PrevDay()}
                    >
                        <Arrow style={{transform : "rotate(180deg)"}} width="40px" height="40px" fill={colors.Gr06} />
                    </Wrapper>*/}
                    <Text 
                        ta="center" 
                        size="2.2rem" 
                        weight="600" 
                        lh="22px" 
                        ls="-0.28px" 
                        color={colors.Gr05}
                    >
                        {`
                            ${selectDay.year}.
                            ${(selectDay.month > 9) ? selectDay.month : `0${selectDay.month}`}.
                            ${(selectDay.day > 9) ? selectDay.day : `0${selectDay.day}`}
                        `}
                    </Text>

                    {
                        step == 2 ?
                            <Wrapper
                                position={`absolute`}
                                style={{
                                    top : 0,
                                    right : 0
                                }}
                            >
                                <Text 
                                    ta="center" 
                                    size="2rem" 
                                    weight="500" 
                                    lh="22px" 
                                    ls="-0.28px" 
                                    color={colors.Gr05}
                                >
                                    {`* 최대 ${selectTicket?.max_time}시간`}
                                </Text>
                            </Wrapper>
                        : <></>
                    }

                    {/*<Wrapper
                        margin={`0 0 0 3rem`}
                        cursor={`pointer`}
                        onClick={() => NextDay()}
                    >
                        <Arrow width="40px" height="40px" fill={colors.Gr06} />
                    </Wrapper>*/}

                    {/*
                        (width >= 1300) ?
                            <Wrapper
                                position={`absolute`}
                                onClick={() => setUser(!user)}
                                padding={`5px 8px`}
                                radius={`8px`}
                                bgc={colors.Bl07}
                                cursor={`pointer`}
                                style={{
                                    top : 0,
                                    right : 0
                                }}
                            >
                                <Text 
                                    ta="center" 
                                    size="2.2rem" 
                                    weight="600" 
                                    lh="22px" 
                                    ls="-0.28px" 
                                    color={colors.White}
                                >
                                    {user ? '유저' : '파트너'}
                                </Text>
                            </Wrapper>
                        : <></>
                    */}

                </Wrapper>

                {   step == 1 ?
                        ValidReturn()   
                    :
                        ListReturn()
                }

            </Wrapper>

            {loading && <Loading />}


            {
                manage ?
                    <FacilityManageModal
                        modal={manage}
                        setModal={setManage}
                        title={`시설 예약 관리`}
                        data={data}
                        GetData={console.log()}
                        setLoading={setLoading}
                    />
                :
                <></>
            }

            {
                entranceModal ?
                    <Wrapper
                        width={`100%`}
                        height={`calc(var(--vh, 1vh) * 100)`}
                        jc={'center'}
                        ai={`center`}
                        position={`fixed`}
                        bgc={colors.B20}
                        style={{
                            zIndex : 99,
                            top : 0,
                            left : 0
                        }}
                    >
                        <Wrapper
                            padding={`24px`}
                            radius={`8px`}
                            style={{
                                zIndex : 100
                            }}
                            width={`500px`}
                            border={`1px solid ${colors.Gr02}`}
                            bgc={colors.White}
                            wrap={`wrap`}
                        >
                            <Wrapper
                                width={`100%`}
                                jc={`center`}
                                margin={`0 0 10px 0`}
                            >
                                <Text 
                                    ta="center" 
                                    size="2.2rem" 
                                    weight="600" 
                                    lh="22px" 
                                    ls="-0.28px" 
                                    color={colors.Gr05}
                                >
                                    예약 정보
                                </Text>
                            </Wrapper>

                            <Wrapper
                                width={`100%`}
                                jc={`flex-start`}
                                margin={`0 0 10px 0`}
                                wrap={`wrap`}
                            >
                                <Text 
                                    size="2rem" 
                                    weight="600" 
                                    lh="22px" 
                                    ls="-0.28px" 
                                    color={colors.Gr05}
                                    width={`100%`}
                                    style={{
                                        marginBottom : 5 
                                    }}
                                >
                                    이용권 : {entranceData?.activity}
                                </Text>

                                <Text 
                                    size="2rem" 
                                    weight="600" 
                                    lh="22px" 
                                    ls="-0.28px" 
                                    color={colors.Gr05}
                                    width={`100%`}
                                    style={{
                                        marginBottom : 5 
                                    }}
                                >
                                    시설 : {entranceData?.facility}
                                </Text>

                                <Text 
                                    size="2rem" 
                                    weight="600" 
                                    lh="22px" 
                                    ls="-0.28px" 
                                    color={colors.Gr05}
                                    width={`100%`}
                                    style={{
                                        marginBottom : 5 
                                    }}
                                >
                                    날짜 : {entranceData?.start_date.split(' ')[0]}
                                </Text>

                                <Text 
                                    size="2rem" 
                                    weight="600" 
                                    lh="22px" 
                                    ls="-0.28px" 
                                    color={colors.Gr05}
                                    width={`100%`}
                                    style={{
                                        marginBottom : 5 
                                    }}
                                >
                                    이용 시간 : {`${entranceData?.start_date.split(' ')[1]} ~ ${entranceData?.end_date.split(' ')[1]}`}
                                </Text>
                            </Wrapper>

                            <Wrapper
                                width={`100%`}
                                jc={`center`}
                                radius={`8px`}
                                bgc={colors.Bl07}
                                padding={`5px 0`}
                                cursor={`pointer`}
                                onClick={() => setEntranceModal(false)}
                            >
                                <Text 
                                    ta="center" 
                                    size="2.2rem" 
                                    weight="600" 
                                    lh="22px" 
                                    ls="-0.28px" 
                                    color={colors.White}
                                >
                                    확인
                                </Text>
                            </Wrapper> 

                        </Wrapper>
                    </Wrapper>
                : <></>
            }

            {
                (joinModal == true) ?
                    <CrewRegisterJoin 
                        Phone={(phone?.length > 0) ? phone : "010"}
                        Entrance={NotValidEnter}
                        setJoinModal={setJoinModal}
                        joinModal={joinModal}
                    />
                : <></>
            }

            {
                ticketModal ?
                    <Wrapper
                        width={`100%`}
                        height={`calc(var(--vh, 1vh) * 100)`}
                        jc={'center'}
                        ai={`center`}
                        position={`fixed`}
                        bgc={colors.B20}
                        style={{
                            zIndex : 99,
                            top : 0,
                            left : 0
                        }}
                    >
                        <Wrapper
                            padding={`24px`}
                            radius={`8px`}
                            style={{
                                zIndex : 100
                            }}
                            width={`800px`}
                            border={`1px solid ${colors.Gr02}`}
                            bgc={colors.White}
                            wrap={`wrap`}
                        >
                            <Wrapper
                                width={`100%`}
                                jc={`center`}
                                margin={`0 0 20px 0`}
                            >
                                <Text 
                                    ta="center" 
                                    size="2.2rem" 
                                    weight="600" 
                                    lh="22px" 
                                    ls="-0.28px" 
                                    color={colors.Gr05}
                                >
                                    이용권 선택
                                </Text>
                            </Wrapper>

                            <Wrapper
                                width={`100%`}
                                wrap={`wrap`}
                                gap={`5px`}
                            >

                                {
                                    ticketList?.map((item, idx) => (
                                        <Wrapper
                                            width={`calc(50% - 5px)`}
                                            padding={`10px`}
                                            radius={`8px`}
                                            border={`1px solid ${colors.Gr02}`}
                                            bgc={`${colors.White}`}
                                            height={`230px`}
                                            ai={`flex-start`}
                                            wrap={`wrap`}
                                            display={`inline-block`}
                                            position={`relative`}
                                            key={`${idx}_ticket_list`}
                                        >
                                            <Text 
                                                size="2.2rem" 
                                                weight="600" 
                                                lh="22px" 
                                                ls="-0.28px" 
                                                color={colors.Gr05}
                                                width={`100%`}
                                                style={{
                                                    marginBottom : 10 
                                                }}
                                            >
                                                {`${item.title}`}
                                            </Text>

                                            <Text 
                                                size="2rem" 
                                                weight="500" 
                                                lh="22px" 
                                                ls="-0.28px" 
                                                color={colors.Gr05}
                                                width={`100%`}
                                                style={{
                                                    marginBottom : 5 
                                                }}
                                            >
                                                이용 시작일 : {`${item.start_date}`}
                                            </Text>

                                            <Text 
                                                size="2rem" 
                                                weight="500" 
                                                lh="22px" 
                                                ls="-0.28px" 
                                                color={colors.Gr05}
                                                width={`100%`}
                                                style={{
                                                    marginBottom : 5 
                                                }}
                                            >
                                                이용 종료일 : {`${item.finish_date}`}
                                            </Text>

                                            <Text 
                                                size="2rem" 
                                                weight="500" 
                                                lh="22px" 
                                                ls="-0.28px" 
                                                color={colors.Gr05}
                                                width={`100%`}
                                                style={{
                                                    marginBottom : 5 
                                                }}
                                            >
                                                이용 가능 횟수 : {`${item.use} / ${item.size}`}
                                            </Text>

                                            <Text 
                                                size="2rem" 
                                                weight="500" 
                                                lh="22px" 
                                                ls="-0.28px" 
                                                color={colors.Gr05}
                                                width={`100%`}
                                            >
                                                이용 가능 시간 : {`${item.max_time}`}
                                            </Text>

                                            <Wrapper
                                                position={`absolute`}
                                                cursor={`pointer`}
                                                padding={`5px 8px`}
                                                radius={`8px`}
                                                bgc={`${colors.Bl07}`}
                                                style={{
                                                    bottom : 10,
                                                    left : '50%',
                                                    transform : "translate(-50%, 0)"
                                                }}
                                                onClick={() => SelectTicket(item)}
                                            >
                                                <Text 
                                                    size="2rem" 
                                                    weight="600" 
                                                    lh="22px" 
                                                    ls="-0.28px" 
                                                    color={colors.White}
                                                    width={`100%`}
                                                >
                                                    선택
                                                </Text>
                                            </Wrapper>
                                        </Wrapper>
                                    ))
                                }
                                

                            </Wrapper>


                        </Wrapper>

                    </Wrapper>
                : <></>
            }

        </Container>
    );
};

export default FacilityReservation;

const Container = styled.section`
    height: calc(var(--vh, 1vh) * 100);
    overflow-x: hidden;
    width: 100%;

    .slick-slider {
        width: 100%;
        height: ${W * IMAGE_RATIO}px;
    }
`;