import React from "react";
import styled from "styled-components";
import colors from "../../styles/colors";

const Toast = ({ children, width, bottom }) => {
  return (
    <ToastContainer width={width} bottom={bottom}>
      {children}
    </ToastContainer>
  );
};

export default Toast;

const ToastContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${({ width }) => (width ? width : "auto")};
  padding: 12px 0;
  background-color: ${colors.B70};
  border-radius: 8px;
  position: fixed;
  z-index: 999;
  bottom: ${({ bottom }) => (bottom ? bottom : "80px")};
  left: 50%;
  transform: translateX(-50%);

  span {
    color: ${colors.White};
    line-height: 142%;
    letter-spacing: -0.02em;
    font-size: 14px;
    font-weight: 500;
  }
`;
