import React, { useState, useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import axios from "axios";

// component
import { GetOne, SaveMany } from "../../tool/localstorage";

//img
import ClearIcon from "../../icon/clearInput.png";
import RedWarning from "../../icon/redWarning.png";
import OkaySign from "../../icon/allowedInputicon.png";
import { WrapperColumn } from "../layouts/layoutStorage";
import colors from "../../styles/colors";

const W = window.document.documentElement.clientWidth;
const H = window.innerHeight;

const DesignSize = H / 812;

function SemosPhoneAuth({ userInfo, setUserInfo, status, setStatus }) {
    const navigate = useNavigate();

    const phoneRef = useRef(null);
    const authRef = useRef(null);

    const [open, setOpen] = useState(false);
    const [phoneText, setPhoneText] = useState("");
    const [verifyToken, setVerifyToken] = useState("");

    const [timer, setTimer] = useState("");

    const min = `0${Math.floor(timer / 60)}`;
    const sec = timer % 60 < 10 ? "0" + (timer % 60) : timer % 60;

    const clearInput = (type) => {
        setUserData(type, "");
        if (type == "phone") {
            phoneRef.current.focus();
        } else if (type == "auth") {
            authRef.current.focus();
        }
    };

    useEffect(() => {
        var phoneCopy = userInfo.phone?.replace(/[^0-9]/g, "");
        var int = Number(phoneCopy);

        if (!isNaN(int)) {
        if (phoneCopy.length > 9) {
            setStatus({ ...status, phone: "ok" });
        } else {
            setStatus({ ...status, phone: "default" });
        }
        } else if (phoneCopy == undefined) {
            setStatus({ ...status, phone: "default" });
        } else {
            setStatus({ ...status, phone: "denied" });
        }

        phoneCopy = phoneCopy ? phoneCopy.replace(/[^0-9]/, "").replace(/^(\d{2,3})(\d{3,4})(\d{4})$/, `$1-$2-$3`) : "";

        setPhoneText(phoneCopy);
    }, [userInfo?.phone]);

    useEffect(() => {
        if (timer !== 0 && status.auth !== "ok") {
          const time = setInterval(() => {
              setTimer((prev) => prev - 1);
          }, 1000);

          return () => clearInterval(time);
        } else if (timer == 0 && status.auth !== "ok") {
            alert("인증번호가 만료되었습니다. 다시 시도해 주세요.");
            setOpen(false);
            setUserInfo({ ...userInfo, phone: "" });
            setStatus({ ...status, phone: "default" });
        }
    }, [timer]);

    const phoneVerify = () => {

        if (userInfo.phone.length > 9 && !isNaN(userInfo.phone)) {
        const frm = new FormData();
        frm.append("mode", "semos_login_check");
        frm.append("phone_number", phoneText);

        axios
            .post("https://ai.semos.kr/semos_partner/v3/activity/Facility", frm, {
            headers: {
                "Content-Type": "multipart/form-data",
                Accept: "application/json",
            },
            })
            .then((data) => {
            var Value = data.data;
            var Status = Value.status;
            var StatusMessage = Value.status_message;
            var CheckToken = Value.check_t;

            if (Status === "success") {
                setOpen(true);
                setUserInfo((prev) => ({ ...prev, phone: phoneText }));
                setStatus({ ...status, phone: "ok" });
                setTimer(180);
                setVerifyToken(CheckToken);
            } else if (Status === "fail") {
                window.alert(StatusMessage);
            }
            });
        } else {
        alert(`전화번호를 다시 한번 확인해주세요.`);
        }
    };

    const authNumCheck = () => {

        if (userInfo.auth.length !== 6) {
            alert("인증번호 6자리를 입력해주세요.");
        } else {
            const frm = new FormData();
            frm.append("mode", "verification_confirm");
            frm.append("verification_token", verifyToken);
            frm.append("verification_number", userInfo.auth);
            frm.append("phone_number", phoneText);

            axios
                .post("https://ai.semos.kr/semos_partner/v3/activity/Facility", frm, {
                headers: {
                    "Content-Type": "multipart/form-data",
                    Accept: "application/json",
                },
                })
                .then((data) => {
                    const Value = data.data;
                    const Status = Value.status;
                    const StatusMessage = Value.status_message;
                    const UserData = Value.user_data;

                    if (Status === "success") {
                        const status = UserData.partner_status ? UserData.partner_status : null;
                        const Token = Value.login_token;
                        const nickname = UserData.user_name;
                        const name = UserData.user_real_name;
                        const age = UserData.user_age;
                        const profile = UserData.profile_img;
                        const phone = UserData.user_phone_number;
                        const gender = UserData.user_gender;
                        const email = UserData.user_email;

                        const LocalStorageData = [
                            { type: "partner_status", value: status },
                            { type: "nickname", value: nickname },
                            { type: "name", value: name },
                            { type: "age", value: age },
                            { type: "profile", value: profile },
                            { type: "phone", value: phone },
                            { type: "gender", value: gender },
                            { type: "email", value: email },
                            { type: "token", value: Token },
                            { type: "login_type", value: "카카오" },
                        ];

                        setStatus({ ...status, auth: "ok" });

                        if (SaveMany(LocalStorageData)) {
                            navigate("/home", {
                                replace : true
                            });
                        }
                    } else {
                        setStatus({ ...status, auth: "denied" });
                        alert(StatusMessage);
                    }
                });
            }
    };

    const setUserData = (type, value) => {
        if (type == "phone") {
        if (status.phone !== "ok" || status.auth !== "ok") {
            setUserInfo((prev) => ({ ...prev, [type]: value }));
        }
        } else {
        setUserInfo((prev) => ({ ...prev, [type]: value }));
        }
    };

    const PhoneReset = () => {
            setOpen(false);
            setTimer("");
            setUserInfo({ ...userInfo, phone: "", auth: "" });
            setTimeout(() => {
              setStatus({ ...status, phone: "default", auth: "default" });
            }, 200);
    };

  return (
    <WrapperColumn width="100%">
      <InputContainerWithButton phone={true} status={status.phone}>
        {open ? (
          <PhoneBox>{phoneText}</PhoneBox>
        ) : (
          <TextInput
            ref={phoneRef}
            type="tel"
            maxLength={11}
            placeholder="전화번호를 입력해 주세요."
            onChange={(e) => setUserData("phone", e.target.value)}
            value={userInfo.phone}
            disabled={status.auth == "ok"}
          />
        )}
        {status.phone !== "ok" || status.auth !== "ok" ? (
          <JustBetween>
            {userInfo.phone && !open && (
              <ClearButton
                src={ClearIcon}
                onClick={() => {
                  clearInput("phone");
                }}
              />
            )}
            {status.phone !== "denied" && (
              <PhoneButton open={open} onClick={() => phoneVerify()}>
                {open ? "전송완료" : "인증번호 전송"}
              </PhoneButton>
            )}
          </JustBetween>
        ) : (
          <JustBetween>
            <PhoneButton onClick={() => PhoneReset()} open={false}>
              수정하기
            </PhoneButton>
          </JustBetween>
        )}
      </InputContainerWithButton>

      <Divider h={8} />

      {open && (
        <AnimationBox>
          {status.auth === "ok" ? (
            <InputContainerWithButton status="default" style={{ backgroundColor: colors.Gr01 }}>
              <AuthBox>{userInfo.auth}</AuthBox>
              <JustBetween>
                <Okay src={OkaySign} />
                <AuthButton status={status.auth}>인증완료</AuthButton>
              </JustBetween>
            </InputContainerWithButton>
          ) : (
            <InputContainerWithButton status={status.auth}>
              <TextInput
                ref={authRef}
                type="tel"
                maxLength={6}
                placeholder="인증번호를 입력해 주세요!"
                onChange={(e) => setUserData("auth", e.target.value)}
                value={userInfo.auth}
              />

              <JustBetween>
                {userInfo.auth && status.auth !== "ok" && <ClearButton src={ClearIcon} onClick={() => clearInput("auth")} />}
                {timer && (
                  <Timer>
                    {min}:{sec}
                  </Timer>
                )}
                <AuthButton status={status.auth} onClick={() => authNumCheck()}>
                  인증완료
                </AuthButton>
              </JustBetween>
            </InputContainerWithButton>
          )}
          {status.phone === "ok" && status.auth !== "ok" && (
            <>
              <Divider h={6} />
              <WarningTextContainer need={true}>
                <WarningIcon src={OkaySign} />
                <WarningText negative={false}>카카오톡으로 인증번호 전송이 완료되었습니다.</WarningText>
              </WarningTextContainer>
            </>
          )}

          {status.auth === "ok" && (
            <>
              <Divider h={6} />
              <WarningTextContainer need={true}>
                <WarningIcon src={OkaySign} />
                <WarningText negative={false}>인증이 완료되었습니다.</WarningText>
              </WarningTextContainer>
            </>
          )}

          {status.auth === "denied" && (
            <>
              <Divider h={6} />
              <WarningTextContainer>
                <WarningIcon src={RedWarning} />
                <WarningText negative={true}>올바른 인증번호로 다시 입력해주세요!</WarningText>
              </WarningTextContainer>
            </>
          )}
        </AnimationBox>
      )}
    </WrapperColumn>
  );
}

export default SemosPhoneAuth;

const InputContainer = styled.div`
  /* width: ${W - 48}px; */
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid;
  border-radius: 8px;
  padding: 16px;
  border-color: ${({ status }) => (status == "ok" ? colors.Bl05 : status == "black" ? colors.Gr04 : status == "default" ? colors.Gr02 : colors.Red03)};
  height: 52px;
  width: 100%;
`;

const InputContainerWithButton = styled(InputContainer)`
  position: relative;
  padding: 7px 0px 7px 15px;
  height: 52px;
  width: 100%;
  background-color: white;
  z-index: ${({ phone }) => (phone ? 3 : 1)};
`;

const TextInput = styled.input`
  border: none;
  /* width: ${({ need }) => need && "65%"}; */
  width: 65%;
  font-size: 14px;
  outline: none;
  font-weight: 500;
  line-height: 19.88px;

  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  cursor: text;

  &::placeholder {
    color: ${colors.Gr03};
  }
`;

const PhoneBox = styled.div`
  width: 65%;
  font-size: 14px;
  font-weight: 500;
  line-height: 19.88px;
`;

const JustBetween = styled.div`
  display: flex;
  align-items: center;
`;

const PhoneButton = styled.div`
  cursor: pointer;
  padding: 9px 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 700;
  font-size: 14px;
  line-height: 142%;
  background-color: ${({ open }) => (open ? colors.Gr02 : colors.Bl07)};
  letter-spacing: -0.02em;
  color: ${colors.White};
  border-radius: 8px;
  margin-left: 10px;
  margin-right: 7px;
  white-space: nowrap;
`;

const ClearButton = styled.img`
  width: 20px;
  height: 20px;
  cursor: pointer;
`;

const WarningTextContainer = styled.div`
  display: flex;
  align-items: ${({ need }) => (need ? "center" : "")};
`;

const WarningIcon = styled.img`
  width: 14px;
  height: 14px;
  margin-right: 6px;
`;

const WarningText = styled.div`
  font-weight: 600;
  font-size: 12px;
  line-height: 142%;
  display: flex;
  align-items: center;
  letter-spacing: -0.02em;
  color: ${({ negative }) => (negative ? colors.Red03 : colors.Bl05)};
`;

const AuthButton = styled(PhoneButton)`
  background-color: ${({ status }) => (status == "ok" ? colors.Gr02 : colors.Bl07)};
`;

const Okay = styled.img`
  width: 24px;
  height: 24px;
`;

const AuthBox = styled.div`
  width: 65%;
  font-size: 14px;
  font-weight: 500;
  line-height: 19.88px;
  color: ${colors.Gr03};
`;

const AnimationBox = styled.div`
  @keyframes dropdown {
    0% {
      transform: translateY(-52px);
      /* opacity: 0; */
    }
    100% {
      transform: translateY(0);
      /* opacity: 1; */
    }
  }

  animation: dropdown 0.5s ease;
`;

const Timer = styled.div`
  font-weight: 600;
  font-size: 12px;
  line-height: 142%;
  display: flex;
  align-items: center;
  letter-spacing: -0.02em;
  color: ${colors.Bl06};
  margin-left: 6px;
`;

const Divider = styled.div`
  height: ${({ h }) => h * DesignSize}px;
`;
