import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import axios from "axios";
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

//tool
import { GetOne } from "../../tool/localstorage";
import { fbevent } from "../../tool/firebase";
import { setImageFromFile, CropImage } from "../../tool/ImageCrop";

//style
import { Wrapper, Text, AbsoluteWrapper, Icon, TextArea} from "../layouts/layoutStorage";
import colors from "../../styles/colors";
import { W, H } from "../../styles/globalStyles";

//component
import TagSelector from "../newActivityRegister/TagSelector";
import ActivityHeader from "./NewActivityHeader";

//icon
import { Loading } from "../../tool/Loading";
import { ReactComponent as Camera } from "../../resource/icon/camera.svg";
import { ReactComponent as DeleteIcon } from "../../resource/icon/close_circle_filled.svg";
import { ReactComponent as Arrow } from "../../resource/icon/arrow.svg";
import { ReactComponent as CheckFilledIcon } from "../../resource/icon/check_square_filled.svg";
import { ReactComponent as CheckEmptyIcon } from "../../resource/icon/check_square.svg";

const POPULAR_RECOMMEND = ["이색체험을 원하시는 분", "자격증에 도전하실 분"];
const POPULAR_REQUIRE = ["원데이 체험 경험", "레벨 1 라이센스 보유"];
const KEYWORD = [
  {
    value: "parking",
    icon: "https://ai.semos.kr/img/RNAPP/parking.png",
    title: "편리한 주차 시설",
    content: "주차 시설이 있어 자차 이용 가능해요",
  },
  {
    value: "shower_room",
    icon: "https://ai.semos.kr/img/RNAPP/shower_room.png",
    title: "실내 샤워 가능",
    content: "개운하게 샤워해요",
  },
  {
    value: "inside",
    icon: "https://ai.semos.kr/img/RNAPP/inside.png",
    title: "실내 액티비티",
    content: "날씨에 영향을 받지 않아요",
  },
  {
    value: "outside",
    icon: "https://ai.semos.kr/img/RNAPP/outside.png",
    title: "실외 액티비티",
    content: "야외에서 자연과 함께 즐길 수 있어요",
  },
  {
    value: "yes_kids",
    icon: "https://ai.semos.kr/img/RNAPP/yes_kids.png",
    title: "키즈 가능 액티비티",
    content: "아이와 함께 즐길 수 있어요",
  },
  {
    value: "photo",
    icon: "https://ai.semos.kr/img/RNAPP/photo.png",
    title: "사진 촬영 포함",
    content: "액티비티 추억을 남겨드려요",
  },
  {
    value: "fast_answer",
    icon: "https://ai.semos.kr/img/RNAPP/fast_answer.png",
    title: "빠른 답변",
    content: "빠르게 문의에 대응할 수 있어요",
  },
  {
    value: "full_package",
    icon: "https://ai.semos.kr/img/RNAPP/full_package.png",
    title: "추가 비용 제로!",
    content: "시설, 장비, 액티비티까지 모두 포함이에요",
  },
];
const MAX_IMAGE_INFO_LENGTH = 100;
const MAX_IMAGE_LENGTH = 10;
const MIN_IMAGE_LENGTH = 1;
const MAX_KEYWORD_LENGTH = 4; //키워드 개수

const MAX_KEYWORD_ADD_LENGTH = 5; //다른 키워드들의 추가 가능 개수
const TIME_LIST = [0,1,2,3,4,5,6,7,8,9,10];
const DISCOUNT_LIST = [0, 5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55, 60, 65, 70, 75, 80, 85, 90, 95];

const ActivityKeyword = ({ open, DAT, aId, step, setStep, setPageType, modal, setModal, status, setStatus, available, statusHandler, setChange }) => {
  const navigate = useNavigate();

  const textareaRef = useRef();
  const ref = useRef();

  const dicountRef = useRef();
  const maxRef = useRef();

  /*const [dropdown, setDropdown] = useState({
    min: false,
    max: false,
  });*/
  const [dropdown, setDropdown] = useState(false);
  const [dropdownD, setDropdownD] = useState(false);

  const [first, setFirst] = useState(false);

  const [loading, setLoading] = useState(false);
  const [deleteD, setDelete] = useState({
    recommend: [], // 추천 대상 (*)
    require: [], // 자격 대상
    caution: [], // 유의 사항 (*)
    background: [],
    keyword : []
  });
  const [data, setData] = useState({
    recommend: [], // 추천 대상 (*)
    require: [], // 자격 대상
    caution: [], // 유의 사항 (*)
    background: [],
    keyword : [],
    facility_time: 0

  });
  const [recommendSearch, setRecommendSearch] = useState([]);
  const [requireSearch, setRequireSearch] = useState([]);
  const [cautionSearch, setCautionSearch] = useState([]);
  const [cropModal, setCropModal] = useState(false);
  const [cropImageSetting, setCropImageSetting] = useState({
    image: "",
    type: "",
    width: 0,
    height: 0,
  });
  const [showIndex, setShowIndex] = useState(0);

  const activeCheck = () => {
    return (
      /*data.recommend.length &&
      data.recommend.length <= MAX_KEYWORD_ADD_LENGTH &&
      data.caution.length &&
      data.caution.length <= MAX_KEYWORD_ADD_LENGTH &&
      data.require.length <= MAX_KEYWORD_ADD_LENGTH &&
      data.background.length <= MAX_IMAGE_LENGTH*/
      true
    );
  };

  /*const minHandleClose = (e) => {
    if (dropdown.min && (!minRef.current || !minRef.current.contains(e.target))) {
      setDropdown({
        min: false,
        max: false,
      });
    }
  };*/
  const maxHandleClose = (e) => {
    if (dropdown && (!maxRef.current || !maxRef.current.contains(e.target))) {
      /*setDropdown({
        min: false,
        max: false,
      });*/
      setDropdown(false);
    }
  };

  const getBigData = (searchType, searchText) => {

    let token = GetOne("token");

    const frm = new FormData();
    frm.append("mode", "hot_tag");
    frm.append("token", token);
    frm.append("category", searchType);
    frm.append("text", searchText);
    axios
      .post("https://ai.semos.kr/semos_partner/v3/activity/ActivityKeyword", frm, {
        headers: { "Content-Type": "multipart/form-data" },
      })
      .then((data) => {
        const value = data.data;
        const status = value.status;
        const statusMessage = value.status_message;
        const registerText = value.text_list;

        if (status === "success") {
          if (searchType === "recommend") {
            setRecommendSearch(registerText);
          } else if (searchType === "require") {
            setRequireSearch(registerText);
          } else if (searchType === "caution") {
            setCautionSearch(registerText);
          }
        } else {
          window.alert(statusMessage);
        }
      });
  };

  const temporarySaveData = () => {
    if (activeCheck()) { 

      fbevent("B2B_ActivityEdit_Confirm_Click", {
        email : GetOne("email"),
        id : aId
      });

      setLoading(true);
      const token = GetOne("token");
      axios
        .post(
          "https://ai.semos.kr/semos_partner/v3/activity/ActivityKeyword",
          {
            mode: "save",
            token: token,
            data: data,
            delete: deleteD,
            activity_id: aId,
            status: ""
          },
          {
            headers: {
              "Content-Type": "application/x-www-form-urlencoded",
            },
          }
        ).then((data) => {
          let Value = data.data;
          let Status = Value.status;
          let StatusMessage = Value.status_message;
  
          if (Status == "success") {
            setModal({
              status: true,
              type: "revise",
              link: `/activity_manage`,
            });
            setChange(false);
            setLoading(false);
          } else {
            window.alert(StatusMessage);
          }
        });
    } else {
      window.alert('입력하지 않은 정보가 있어요!\n정보를 확인해주세요!');
    }

  };

  const justSaveData2 = (URL) => {
    if (activeCheck()) { 

      fbevent("B2B_ActivityEdit_Save_Click", {
        email : GetOne("email"),
        id : aId
      });

      setLoading(true);
      const token = GetOne("token");
      axios
        .post(
          "https://ai.semos.kr/semos_partner/v3/activity/ActivityKeyword",
          {
            mode: "save",
            token: token,
            data: data,
            delete: deleteD,
            activity_id: aId,
            status: ""
          },
          {
            headers: {
              "Content-Type": "application/x-www-form-urlencoded",
            },
          }
        ).then((data) => {
          let Value = data.data;
          let Status = Value.status;
          let StatusMessage = Value.status_message;
  
          if (Status == "success") {
            setModal({
              status: true,
              type: "just_save2",
              link: URL ? URL : `/activity_manage`,
            });
            setChange(false);
            setLoading(false);
          } else {
            window.alert(StatusMessage);
          }
        });
    } else {
      window.alert('입력하지 않은 정보가 있어요!\n정보를 확인해주세요!');
    }

  };

  const saveData = () => {
    temporarySaveData();
  };

  const addImage = (file) => {
    let copy = data.background;
    copy.push({
      id: "new",
      image: file,
      info: "",
    });
    setData((prev) => ({ ...prev, background: copy }));
    setShowIndex(copy.length - 1);
    setChange(true);
  };


  const textareaHeight = () => {
    textareaRef.current.style.height = "22px";
    textareaRef.current.style.height = textareaRef.current.scrollHeight + "px";
  };

  const onChangeInfo = (e) => {
    textareaHeight();
    let copy = data.background;
    copy[showIndex].info = e.target.value.substring(0, MAX_IMAGE_INFO_LENGTH);
    setData((prev) => ({ ...prev, background: copy }));
    setChange(true);
  };

  useEffect(() => {
    if (modal.confirmSave) {
      temporarySaveData("save");
    }
  }, [modal.confirmSave]);

  useEffect(() => {
    if (DAT) {
      if(DAT?.activity_name) { 
        if(!first) {
          setFirst(true);
          setData(DAT);
        }
      }
    }
  }, [DAT]);

  /*useEffect(() => {
    window.addEventListener("click", minHandleClose);

    return () => {
      window.removeEventListener("click", minHandleClose);
    };
  }, [dropdown.min]);*/

  useEffect(() => {
    window.addEventListener("click", maxHandleClose);

    return () => {
      window.removeEventListener("click", maxHandleClose);
    };
  }, [dropdown]);

  const FacilityCheck = () => {
    if(DAT.activity_type == '이용권(횟수)' || DAT.activity_type == '이용권(기간)' || DAT.activity_type == '정기 레슨') {
      return true;
    }
  }

  const Reset = () => {
    setData(DAT);
    setDelete({
      recommend: [], // 추천 대상 (*)
      require: [], // 자격 대상
      caution: [], // 유의 사항 (*)
      background: [],
    })
  }

  const ImageDND = (result) => {
    const Destination = result.destination;
    const Source = result.source;
    let List = [...data.background];

    if (!Destination) return;

    const DI = Destination.index;
    const SI = Source.index;

    let SIMG = List[SI].image;
    let DIMG = List[DI].image;
    let SINTRO = List[SI].intro;
    let DINTRO = List[DI].intro;

    List[SI]['image'] = DIMG;
    List[SI]['intro'] = DINTRO;
    List[DI]['image'] = SIMG;
    List[DI]['intro'] = SINTRO;

    setData((prev) => ({ ...prev, background: List }));
  };


  return (
    open ? 
      <>
        {!loading ? (
          <>
            <ActivityHeader 
            status={status}
            title="액티비티 수정" 
            available={available}
            statusHandler={statusHandler} />
            
            {
                (aId != 'new') &&
                  <Wrapper 
                    ai="center" jc="space-between" width="100%" padding="24px 24px 8px 24px"
                    style={{
                      position : "fixed",
                      maxWidth: '1300px',
                      top : "40px",
                      left : "50%",
                      transform: "translate(-50%, 0)",
                      backgroundColor : "#ffffff",
                      zIndex : 10
                    }}  
                  >
                            
                    <Wrapper 
                      onClick={() => setPageType('info', justSaveData2, Reset)}
                      ai="center" 
                      jc="center" 
                      radius="8px"
                      bgc={colors.White} 
                      border={`1px solid ${colors.Gr02}`}
                      padding={`6px 0`}
                      width="30%">
                      <Text ta="center" size="16px" weight="600" lh="24px" ls="-0.28px" color={colors.Gr05}>
                        기본 정보
                      </Text>
                    </Wrapper>

                    <Wrapper 
                      onClick={() => setPageType('schedule', justSaveData2, Reset)}
                      ai="center" 
                      jc="center" 
                      radius="8px"
                      bgc={colors.White} 
                      border={`1px solid ${colors.Gr02}`}
                      padding={`6px 0`}
                      width="30%">
                      <Text ta="center" size="16px" weight="600" lh="24px" ls="-0.28px" color={colors.Gr05}>
                        일정 정보
                      </Text>
                    </Wrapper>

                    <Wrapper 
                      //onClick={() => setPageType('etc')}
                      ai="center" 
                      jc="center" 
                      radius="8px"
                      bgc={colors.Bl07} 
                      padding={`6px 0`}
                      width="30%">
                      <Text ta="center" size="16px" weight="600" lh="24px" ls="-0.28px" color={colors.White}>
                        추가 정보
                      </Text>
                    </Wrapper>

                  </Wrapper>
            }
            
            <Wrapper ai="flex-start" fd="column" width="100%" height={`80px`} padding="24px 24px 8px 24px"/>
            
            <Wrapper width="100%" fd="column" of="auto" padding="24px 0 0 0">

              <Wrapper width="100%" ai="flex-start" fd="column" padding="0px">
                <Wrapper width="100%" jc="space-between" padding="0 24px">
                  <Text size="16px" weight="600" lh="24px" ls="-0.32px" color={colors.Gr05}>
                    액티비티 소개할 사진을 등록해주세요
                  </Text>
                  <Text size="14px" weight="500" lh="22px" ls="-0.28px" color={colors.Gr03}>
                    {data.background.length}/{MAX_IMAGE_LENGTH}
                  </Text>
                </Wrapper>

                <Wrapper width="100%" fd="column" ai="flex-start" padding="0 24px">
                  <TipWrap>
                    <span className="tip">Tip! </span>
                    <span>사진에 대한 설명도 함께 적을 수 있어요</span>
                    <span style={{width : '100%', display : 'inline-flex'}}>사진을 드래그하면 순서를 변경할 수 있어요</span>
                  </TipWrap>

                  <TipWrap>
                    <span className="red">최소 {MIN_IMAGE_LENGTH}장 업로드해야해요.</span>
                  </TipWrap>
                </Wrapper>

                {/* 이미지 등록 */}
                <Wrapper width="100%" padding="0 0 0 24px">
                  <ImageLabelBox>
                    <Camera width="32px" height="32px" fill={colors.Gr03} />
                    <Text size="10px" weight="500" lh="132%" ls="-0.22px" color={colors.Gr03}>
                      이미지 등록
                    </Text>
                    <ImageInput
                      type="file"
                      id="background"
                      accept="image/*"
                      onChange={({ target: { files } }) => {
                        if (files.length && data.background.length < MAX_IMAGE_LENGTH) {
                          setImageFromFile({
                            file: files[0],
                            setImageUrl: ({ result }) => {
                              setCropImageSetting({
                                image: result,
                                width: 8,
                                height: 5,
                                type: "background",
                              });
                              setCropModal(true);
                            },
                          });
                        } else {
                          alert(`배경 이미지는 최대 ${MAX_IMAGE_LENGTH}개까지 등록하실 수 있습니다.`);
                        }
                      }}
                    />
                  </ImageLabelBox>

                  <DragDropContext onDragEnd={ImageDND}>
                    <Droppable droppableId="droppable" direction="horizontal">
                      {(provided, snapshot) => (
                        <Wrapper 
                          ref={provided.innerRef} 
                          {...provided.droppableProps}

                          className={`droppable`}
                          position={'relative'}
                          display={'inline-flex'}
                          width={'calc(100% - 86px)'}
                          padding={'20px 0 0 0'}
                          style={{
                            overflowX : 'auto',
                            overflowY : "hidden"
                          }}
                          gap={'16px'}
                        >
                          {data.background.map((item, idx) => (
                            <Draggable
                              key={`background_${idx}`}
                              draggableId={`${idx}_${item.id}`}
                              index={idx}
                            >
                              {(provided, snapshot) => {
                                return (
                                  <Wrapper 
                                    style={{
                                      position : "relative",
                                      borderRadius : '8px',
                                      height : '78px',
                                      width : '78px',
                                      cursor : "pointer",
                                      display : 'inline-flex'
                                    }}
                                    onClick={() => setShowIndex(idx)}
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                  >
                                    <Icon src={item.image} width="78px" height="78px" radius="8px" />
                                    <AbsoluteWrapper right="-12px" top="-12px" z={1}>
                                      <DeleteIcon
                                        onClick={(e) => {
                                          e.stopPropagation();
                                          let copy = [...data.background];

                                          let deleteData = copy[idx];
                                          let deleted = [...deleteD.background];
                                          deleted.push(deleteData);
                                          setDelete((prev) => ({ ...prev, background: deleted }));

                                          copy.splice(idx, 1);
                                          setShowIndex(0);
                                          setData((prev) => ({ ...prev, background: copy }));
                                          setChange(true);
                                        }}
                                        width="24px"
                                        height="24px"
                                        fill={colors.Gr05}
                                      />
                                    </AbsoluteWrapper>
                                  </Wrapper>
                                )
                              }}
                            </Draggable>
                          ))}
                          {provided.placeholder}
                        </Wrapper>
                      )}
                    </Droppable>
                  </DragDropContext>
                </Wrapper>

                {/* 배경 이미지 미리보기 & 설명란 */}
                {data.background.length > 0 && (
                  <Wrapper width="100%" fd="column" gap="24px" padding="0 24px" margin="0px" style={{maxWidth : 800}}>
                    <Icon radius="8px" margin="20px 0 0 0" width="100%" height={(W > 800) ? ((800 * 5) / 8) : ((W * 5) / 8)} src={data.background[showIndex].image}></Icon>
                    <Wrapper width="100%" fd="column" gap="8px">
                      <TextArea
                        style={{ maxHeight: `${H * 0.5}px` }}
                        ref={textareaRef}
                        maxLength={100}
                        value={data.background[showIndex].info}
                        onChange={onChangeInfo}
                        size="14px"
                        weight="500"
                        height="22px"
                        lh="22px"
                        ls="-0.28px"
                        width="100%"
                        placeholder="사진에 대한 설명을 적어주세요"
                      />
                      <Wrapper height="1px" width="100%" bgc={colors.Gr02} />
                      <InfoText ref={ref}>
                        <span className="dark">{data.background[showIndex].info.length}</span>
                        <span> / 100</span>
                      </InfoText>
                    </Wrapper>
                  </Wrapper>
                )}
              </Wrapper>

              <Wrapper ai="flex-start" fd="column" width="100%" padding="0 24px 24px 24px">

                {/* 자격 대상 */}
                <TagSelector
                  data={data}
                  setData={setData}
                  bigData={getBigData}
                  textRecommend={requireSearch}
                  deleteD={deleteD}
                  setDelete={setDelete}
                  POPULAR_LIST={POPULAR_REQUIRE}
                  titleText={"자격 대상"}
                  requirementText={"선택사항"}
                  placeholderText={"예시) 수영을 배워보신 크루님"}
                  type={"require"}
                  setChange={setChange}
                />

                {/* 추천 대상 */}
                <TagSelector
                  data={data}
                  setData={setData}
                  bigData={getBigData}
                  textRecommend={recommendSearch}
                  deleteD={deleteD}
                  setDelete={setDelete}
                  POPULAR_LIST={POPULAR_RECOMMEND}
                  titleText={"추천 대상"}
                  requirementText={"필수사항"}
                  placeholderText={"예시) 모험심 뿜뿜 크루님"}
                  type={"recommend"}
                  setChange={setChange}
                />

                {/* 유의 사항 */}
                <TagSelector
                  data={data}
                  setData={setData}
                  bigData={getBigData}
                  textRecommend={cautionSearch}
                  deleteD={deleteD}
                  setDelete={setDelete}
                  POPULAR_LIST={[]}
                  titleText={"유의 사항"}
                  requirementText={"필수사항"}
                  placeholderText={"예시) 건강상 문제가 있으신 분은 말씀주세요."}
                  type={"caution"}
                  setChange={setChange}
                />

                <Wrapper width="100%" height="24px" />

                <Text size="16px" weight="600" lh="24px" ls="-0.32px" color={colors.Gr05}>
                  키워드
                </Text>
                <Text margin="8px 0 12px 0" size="14px" weight="500" lh="22px" ls="-0.28px" color={colors.Gr03}>
                  액티비티를 어필할 키워드를 선택해주세요 (최대 4개)
                </Text>
                <Wrapper wrap="wrap" width="100%" >
                  {KEYWORD.map((item, index) => (
                    <Wrapper
                      key={item.value}
                      onClick={() => {
                        let keywordCopy = [...data.keyword];
                        if (keywordCopy.includes(item.value)) {
                          keywordCopy = keywordCopy.filter((el) => el !== item.value);
                        } else {
                          if (keywordCopy.length >= MAX_KEYWORD_LENGTH) {
                            return;
                          } else {
                            keywordCopy.push(item.value);
                          }
                        }
                        setData((prev) => ({ ...prev, keyword: keywordCopy }));
                      }}
                      cursor="pointer"
                      padding="12px 0"
                      width={(W > 800) ? '50%' :  "100%"}
                      jc="space-between"
                      ai="center"
                      bb={`solid 1px ${colors.Gr01}`}
                    >
                      {data.keyword?.includes(item.value) ? (
                        <CheckFilledIcon width="24px" height="24px" fill={colors.Bl07} />
                      ) : (
                        <CheckEmptyIcon width="24px" height="24px" fill={colors.Gr03} />
                      )}
                      <Icon src={item.icon} style={{ alignSelf: "center" }} jc="flex-start" margin="0 10px 0 16px" width="24px" height="24px" />
                      <Wrapper ai="flex-start" width="100%" fd="column">
                        <Text size="14px" weight="500" lh="142%" ls="-0.32px" color={colors.Gr05}>
                          {item.title}
                        </Text>
                        <Text margin="6px 0 0 0" size="12px" weight="500" lh="142%" ls="-0.28px" color={colors.Gr03}>
                          {item.content}
                        </Text>
                      </Wrapper>
                    </Wrapper>
                  ))}
                </Wrapper>


                {
                  FacilityCheck() ? 
                    <>
                      <Wrapper padding="16px 0 8px 0" width="100%" fd="column" ai="flex-start">

                        <ModalSubTitle>시설 예약 가능 시간</ModalSubTitle>

                        <Wrapper fd="column" margin="12px 0 8px 0" width="100%">
                          <Wrapper width="100%" jc="space-between" gap="8px">
                            {/*<Wrapper width="100%">
                              <Text ws="pre" margin="0 12px 0 0" size="14px" weight="500" lh="22px" ls="-0.28px" color={colors.Gr04}>
                                최소
                              </Text>
                              <Wrapper
                                ref={minRef}
                                padding="12px"
                                cursor="pointer"
                                position="relative"
                                onClick={(e) => {
                                  e.stopPropagation();
                                  setDropdown({
                                    min: !dropdown.min,
                                    max: false,
                                  });
                                }}
                                height="44px"
                                radius="8px"
                                width="100%"
                                bgc={colors.White}
                                border={`1px solid ${colors.Gr02}`}
                                jc="space-between"
                              >
                                <Text size="14px" weight="500" lh="22px" ls="-0.28px" color={colors.Gr05}>
                                  {``}시간
                                </Text>
                                <Arrow
                                  width="16px"
                                  height="16px"
                                  fill={colors.Gr04}
                                  style={{ transition: "all ease 0.5s", transform: dropdown.min ? "rotate(-90deg)" : "rotate(90deg)" }}
                                />

                                {dropdown.min && (
                                  <AbsoluteWrapper
                                    maxHeight="250px"
                                    z={1}
                                    fd="column"
                                    width="100%"
                                    top="54px"
                                    left="0"
                                    bgc={colors.White}
                                    border={`1px solid ${colors.Gr02}`}
                                    radius="8px"
                                    of="auto"
                                  >
                                    {[...Array(20)].map((x, idx) => (
                                      <DropDown
                                      key={`${idx}_min_capacity`}
                                        last={idx + 1 === 20}
                                        onClick={() => console.log('ss')}
                                      >
                                        {idx + 1}시간
                                      </DropDown>
                                    ))}
                                  </AbsoluteWrapper>
                                )}
                              </Wrapper>
                            </Wrapper>*/}

                            <Wrapper width="50%">

                              <Text ws="pre" margin="0 12px 0 0" size="14px" weight="500" lh="22px" ls="-0.28px" color={colors.Gr04}>
                                최대
                              </Text>

                              <Wrapper
                                ref={maxRef}
                                padding="12px"
                                cursor="pointer"
                                position="relative"
                                onClick={(e) => {
                                  e.stopPropagation();
                                  setDropdown(!dropdown);
                                }}
                                height="44px"
                                radius="8px"
                                width="100%"
                                bgc={colors.White}
                                border={`1px solid ${colors.Gr02}`}
                                jc="space-between"
                              >
                                <Text size="14px" weight="500" lh="22px" ls="-0.28px" color={colors.Gr05}>
                                  {data?.facility_time}시간
                                </Text>

                                <Arrow
                                  width="16px"
                                  height="16px"
                                  fill={colors.Gr04}
                                  style={{ 
                                    transition: "all ease 0.5s", 
                                    transform: dropdown ? "rotate(-90deg)" : "rotate(90deg)" 
                                  }}
                                />

                                {dropdown && (
                                  <AbsoluteWrapper
                                    maxHeight="250px"
                                    z={1}
                                    fd="column"
                                    width="100%"
                                    top="54px"
                                    left="0"
                                    bgc={colors.White}
                                    border={`1px solid ${colors.Gr02}`}
                                    radius="8px"
                                    of="auto"
                                  >
                                    {TIME_LIST.map((_, idx) => (
                                      <DropDown
                                        key={`${idx}_max_time`}
                                        onClick={() => {
                                          setData((prev) => ({ ...prev, facility_time: idx }))
                                          setChange(true);
                                        }}
                                        last={idx == 9}
                                      >
                                        {idx}시간
                                      </DropDown>
                                    ))}
                                  </AbsoluteWrapper>
                                )}
                              </Wrapper>
                            </Wrapper>

                          </Wrapper>

                        </Wrapper>
                      </Wrapper>

                      <Wrapper padding="16px 0 8px 0" width="100%" fd="column" ai="flex-start">

                        <ModalSubTitle>이벤트 및 할인</ModalSubTitle>

                        <Wrapper fd="column" margin="12px 0 8px 0" width="100%">
                          <Wrapper width="100%" jc="space-between" gap="8px">
                            <Wrapper width="50%">
                              <Wrapper
                                ref={dicountRef}
                                padding="12px"
                                cursor="pointer"
                                position="relative"
                                onClick={(e) => {
                                  e.stopPropagation();
                                  setDropdownD(!dropdownD);
                                }}
                                height="44px"
                                radius="8px"
                                width="100%"
                                bgc={colors.White}
                                border={`1px solid ${colors.Gr02}`}
                                jc="space-between"
                              >
                                <Text size="14px" weight="500" lh="22px" ls="-0.28px" color={colors.Gr05}>
                                  {data?.facility_discount}%
                                </Text>

                                <Arrow
                                  width="16px"
                                  height="16px"
                                  fill={colors.Gr04}
                                  style={{ 
                                    transition: "all ease 0.5s", 
                                    transform: dropdownD ? "rotate(-90deg)" : "rotate(90deg)" 
                                  }}
                                />

                                {dropdownD && (
                                  <AbsoluteWrapper
                                    maxHeight="250px"
                                    z={1}
                                    fd="column"
                                    width="100%"
                                    top="54px"
                                    left="0"
                                    bgc={colors.White}
                                    border={`1px solid ${colors.Gr02}`}
                                    radius="8px"
                                    of="auto"
                                  >
                                    {DISCOUNT_LIST.map((x, idx) => (
                                      <DropDown
                                        key={`${idx}_discount`}
                                        onClick={() => {
                                          setData((prev) => ({ ...prev, facility_discount: x }))
                                          setChange(true);
                                        }}
                                        last={(idx + 1) == DISCOUNT_LIST.length}
                                      >
                                        {x}%
                                      </DropDown>
                                    ))}
                                  </AbsoluteWrapper>
                                )}
                              </Wrapper>
                            </Wrapper>

                            <Wrapper width="50%">

                              <Wrapper 
                                height="44px"
                                border={`2px solid ${(data?.facility_event == 1) ? colors.Bl07 : colors.Gr02}`}
                                cursor={`pointer`}
                                padding={`0 12px`}
                                radius={`8px`}
                                style={{
                                  fontSize : 13,
                                  fontWeight : 600,
                                  color : (data?.facility_event == 1) ? colors.Bl07 : colors.Gr04
                                }}
                                onClick={() => {
                                  setData((prev) => ({ ...prev, facility_event: 1 }))
                                  setChange(true);
                                }}
                              >
                                활성
                              </Wrapper>

                              <Wrapper 
                                height="44px"
                                border={`2px solid ${(data?.facility_event == 0) ? colors.Bl07 : colors.Gr02}`}
                                margin={`0 0 0 5px`}
                                cursor={`pointer`}
                                padding={`0 12px`}
                                radius={`8px`}
                                style={{
                                  fontSize : 13,
                                  fontWeight : 600,
                                  color : (data?.facility_event == 0) ? colors.Bl07 : colors.Gr04
                                }}
                                onClick={() => {
                                  setData((prev) => ({ ...prev, facility_event: 0 }))
                                  setChange(true);
                                }}
                              >
                                비활성
                              </Wrapper>

                            </Wrapper>
                          </Wrapper>

                        </Wrapper>
                      </Wrapper>

                      <Wrapper padding="16px 0 8px 0" width="100%" fd="column" ai="flex-start">

                        <ModalSubTitle>이용권 선물 (횟수권만 가능)</ModalSubTitle>

                        <Wrapper fd="column" margin="12px 0 8px 0" width="100%">
                          <Wrapper width="100%" jc="space-between" gap="8px">
                            {/*<Wrapper width="50%">
                              <Wrapper
                                ref={dicountRef}
                                padding="12px"
                                cursor="pointer"
                                position="relative"
                                onClick={(e) => {
                                  e.stopPropagation();
                                  setDropdownD(!dropdownD);
                                }}
                                height="44px"
                                radius="8px"
                                width="100%"
                                bgc={colors.White}
                                border={`1px solid ${colors.Gr02}`}
                                jc="space-between"
                              >
                                <Text size="14px" weight="500" lh="22px" ls="-0.28px" color={colors.Gr05}>
                                  {data?.facility_discount}%
                                </Text>

                                <Arrow
                                  width="16px"
                                  height="16px"
                                  fill={colors.Gr04}
                                  style={{ 
                                    transition: "all ease 0.5s", 
                                    transform: dropdownD ? "rotate(-90deg)" : "rotate(90deg)" 
                                  }}
                                />

                                {dropdownD && (
                                  <AbsoluteWrapper
                                    maxHeight="250px"
                                    z={1}
                                    fd="column"
                                    width="100%"
                                    top="54px"
                                    left="0"
                                    bgc={colors.White}
                                    border={`1px solid ${colors.Gr02}`}
                                    radius="8px"
                                    of="auto"
                                  >
                                    {DISCOUNT_LIST.map((x, idx) => (
                                      <DropDown
                                        key={`${idx}_discount`}
                                        onClick={() => setData((prev) => ({ ...prev, facility_discount: x }))}
                                        last={(idx + 1) == DISCOUNT_LIST.length}
                                      >
                                        {x}%
                                      </DropDown>
                                    ))}
                                  </AbsoluteWrapper>
                                )}
                              </Wrapper>
                            </Wrapper>*/}

                            <Wrapper width="50%">

                              <Wrapper 
                                height="44px"
                                border={`2px solid ${(data?.ticket_share == 1) ? colors.Bl07 : colors.Gr02}`}
                                cursor={`pointer`}
                                padding={`0 12px`}
                                radius={`8px`}
                                style={{
                                  fontSize : 13,
                                  fontWeight : 600,
                                  color : (data?.ticket_share == 1) ? colors.Bl07 : colors.Gr04
                                }}
                                onClick={() => {
                                  setData((prev) => ({ ...prev, ticket_share: 1 }))
                                  setChange(true);
                                }}
                              >
                                활성
                              </Wrapper>

                              <Wrapper 
                                height="44px"
                                border={`2px solid ${(data?.ticket_share == 0) ? colors.Bl07 : colors.Gr02}`}
                                margin={`0 0 0 5px`}
                                cursor={`pointer`}
                                padding={`0 12px`}
                                radius={`8px`}
                                style={{
                                  fontSize : 13,
                                  fontWeight : 600,
                                  color : (data?.ticket_share == 0) ? colors.Bl07 : colors.Gr04
                                }}
                                onClick={() => {
                                  setData((prev) => ({ ...prev, ticket_share: 0 }))
                                  setChange(true);
                                }}
                              >
                                비활성
                              </Wrapper>

                            </Wrapper>
                          </Wrapper>

                        </Wrapper>
                      </Wrapper>
                    </>
                  : <></>
                }

              </Wrapper>
            </Wrapper>
            <BottomWrap>
              <Wrapper
                onClick={() => saveData()}
                jc="center"
                ai="center"
                width="100%"
                height="52px"
                cursor="pointer"
                radius="8px"
                border=""
                bgc={activeCheck() ? colors.Bl07 : colors.Gr01}
              >
                <Text ta="center" size="14px" weight="500" lh="22px" ls="-0.28px" color={activeCheck() ? colors.White : colors.Gr03}>
                  추가 정보 수정하기
                </Text>
              </Wrapper>
            </BottomWrap>

            <CropImage open={cropModal} setOpen={setCropModal} setting={cropImageSetting} setSetting={setCropImageSetting} setImageFunc={addImage} />
          </>
        ) : (
          <Loading />
        )}
      </>
    : <></>
  );
};

export default ActivityKeyword;

const BottomWrap = styled.div`
  width: 100%;
  max-width: 1300px;
  transform : translate(-50%, 0);
  display: flex;
  padding: 12px 24px;
  border-top: 1px solid ${colors.Gr01};
  background-color: ${colors.White};
  position: fixed;
  bottom : 0;
  left : 50%;
  justify-content: space-between;
  align-items: center;
  z-index: 10;
  gap: 8px;
`;

const TipWrap = styled.p`
  word-break: keep-all;
  font-size: 14px;
  font-weight: 500;
  line-height: 22px;
  letter-spacing: -0.28px;
  color: ${colors.Gr03};
  .tip {
    color: ${colors.Bl05};
  }
  .red {
    color: ${colors.Red03};
  }
`;

const ImageLabelBox = styled.label`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-top: 20px;
  margin-right: 8px;
  width: 78px;
  height: 78px;
  border: 1px solid ${colors.Gr02};
  border-radius: 8px;
`;

const ImageInput = styled.input`
  display: none;
`;

const InfoText = styled.p`
  word-break: keep-all;
  margin-right: 12px;
  align-self: flex-end;
  white-space: pre;
  font-size: 12px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: -0.24px;
  color: ${colors.Gr03};
  .dark {
    color: ${colors.Gr04};
  }
`;

const ModalSubTitle = styled.span`
  word-break: keep-all;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: -0.32px;
  color: ${colors.Gr05};
`;

const DropDown = styled.div`
  width: 100%;
  height: 52px;
  padding: 12px;
  border-bottom: ${({ last }) => !last && `1px solid ${colors.Gr02}`};
  color: ${colors.Gr04};
  font-size: 13px;
  font-weight: 500;
  line-height: 142%;
  letter-spacing: -0.26px;
`;
