import React, { useEffect, useState, useRef } from "react";
import styled from "styled-components";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";

//tool
import { GetOne } from "../tool/localstorage";

//style
import { Wrapper, Text, Input, AbsoluteWrapper } from "../components/layouts/layoutStorage";
import colors from "../styles/colors";

//component
import { Loading } from "../tool/Loading";
import FacilityHeader from "../components/facilityRegister/FacilityHeader";

//icon

// Default Data
const MAX_INTRO_LENGTH = 300;

const NoticeDetail = () => {

    const navigate = useNavigate();

    const params = useParams();

    const [loading, setLoading] = useState(false);

    const [data, setData] = useState({
        name : "",
        description : "",
        image : "",
        view : "",
        like : "",
        id : "",
        status : "",
        upload : "",
        updated : ""
    });

    const W = window.document.documentElement.clientWidth;


    const GetData = () => {
        setLoading(true);

        var token = GetOne("token");

        const frm = new FormData();

        frm.append("mode", "notice_info");
        frm.append("token", token);
        frm.append("notice_id", params.id);

        axios
        .post("https://ai.semos.kr/semos_partner/v3/profile/Notice", frm, {
            headers: { "Content-Type": "multipart/form-data" },
        })
        .then((data) => {
            var Value = data.data;
            var Status = Value.status;
            var StatusMessage = Value.status_message;
            var NoticeInfo = Value.notice_info;

            if (Status == "success") {
                setData(NoticeInfo);
                setLoading(false);
            } else {
                window.alert(StatusMessage);
                setLoading(false);
            }
        });
    }

    useEffect(() => {
        GetData()
    }, []);

    return (
        <Container id="description_container">

            <FacilityHeader
                status={true}
                title={`공지사항`}
                available={false}
                statusHandler={() => console.log("신규 회원 등록")}
            />

            <Wrapper
                jc="space-between"
                ai="flex-end"
                width="100%"
                padding={`20px 24px 10px 24px`}
                margin={`0 0 20px 0`}
                bb={`1px solid ${colors.Gr02}`}
            >

                <Text 
                    ta="center" 
                    size="1.8rem" 
                    weight="600" 
                    lh="22px" 
                    ls="-0.28px" 
                    color={colors.Bl07}
                >
                    {``}
                </Text>

                <Text 
                    ta="center" 
                    size="1.6rem" 
                    weight="600" 
                    lh="22px" 
                    ls="-0.28px" 
                    color={colors.Gr03}
                >
                    {`등록일 : ${data?.upload}`}
                </Text>

            </Wrapper>

            <Wrapper
                jc="flex-start"
                ai="center"
                fd={`column`}
                width="100%"
                padding={`0 24px 10px 24px`}
                margin={`0 0 20px 0`}
                gap={`15px`}
            >
                <Wrapper
                    width={`100%`}
                    jc={'space-between'}
                >
                    <Text 
                        ta="center" 
                        size="2.2rem" 
                        weight="600" 
                        lh="22px" 
                        ls="-0.28px" 
                        color={colors.Gr05}
                        width={`calc(100%)`}
                    >
                        {`${data?.name}`}
                    </Text>
                </Wrapper>

            </Wrapper>

            <Wrapper
                jc="flex-start"
                ai="center"
                width="100%"
                wrap={'wrap'}
                padding={`0 24px 10px 24px`}
                margin={`0 0 20px 0`}
            >
                <Wrapper
                    jc="space-between"
                    ai="center"
                    width="100%"
                    wrap={'wrap'}
                    margin={`0 0 10px 0`}
                >

                    <img 
                        style={{
                            width : '100%'
                        }} 
                        alt={data?.name} 
                        src={data?.image} 
                    />

                </Wrapper>

            </Wrapper>

            <Wrapper
                jc="flex-start"
                ai="center"
                width="100%"
                wrap={'wrap'}
                padding={`0 24px 10px 24px`}
                margin={`0 0 20px 0`}
            >
    
                <Wrapper width="100%">
                    <Text ws="pre-wrap" size="1.8rem" weight="500" ls="-0.02em" color={colors.Gr05}>
                        {data?.description}
                    </Text>
                </Wrapper>

            </Wrapper>

            <BottomWrap>
                <Wrapper
                    onClick={() => navigate(`/notice_revise/${params.id}`)}
                    jc="center"
                    ai="center"
                    width="calc(100%)"
                    height="52px"
                    cursor={"pointer"}
                    radius="8px"
                    bgc={colors.Bl07}
                >
                    <Text 
                        ta="center" 
                        size="1.6rem" 
                        weight="600" 
                        lh="22px" 
                        ls="-0.28px" 
                        color={colors.White}
                    >
                        {`공지사항 수정하기`}
                    </Text>
                </Wrapper>
            </BottomWrap>

            {loading && <Loading />}

        </Container>
    );
};

export default NoticeDetail;

const Container = styled.section`
    height: calc(var(--vh, 1vh) * 100);
    overflow-x: hidden;
    width: 100%;
    padding-bottom : 150px;
`;

const BottomWrap = styled.div`
    width: 100%;
    max-width: 900px;
    transform : translate(-50%, 0);
    display: flex;
    justify-content: space-between;
    padding: 12px 24px;
    border-top: 1px solid ${colors.Gr01};
    background-color: ${colors.White};
    position: fixed;
    bottom : 0;
    left : 50%;
    z-index: 10;
`;

const Popup = styled.div`
    position: fixed;
    display: flex;
    justify-content: center;
    text-align: center;
    z-index: 99;
    bottom: 48px;
    left: 50%;
    transform: translate(-50%, 0);
    width: 320px;
    height: 44px;
    background-color: rgba(0, 0, 0, 0.7);
    border-radius: 8px;
`;

const TextareaContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 150px;
    border-radius: 8px;
`;

const TextArea = styled.textarea`
    display: inline-flex;
    width: 100%;
    height: 100%;
    border: none;
    border-radius: 8px;
    resize: none;
    line-height: 22px;
    letter-spacing: -0.02em;
    font-size: 1.6rem;
    font-weight: 500;

    overflow: auto;
    outline: none;

    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;

    &::placeholder {
        color: ${colors.Gr03};
    }
`;

const IntroText = styled.div`
    flex: 1;
    display : inline-flex;
    align-items : flex-end;
    justify-content : flex-end;
    width: 100%;
    padding: 8px 12px;
    color: ${colors.Gr03};
    line-height: 20px;
    letter-spacing: -0.02em;
    font-size: 1.3rem;
    font-weight: 500;
`;

const NewCrew = styled.div`
    z-index: 100;
    cursor : pointer;
    border : .2rem solid ${colors.Bl07};
    width : 3.3rem;
    height : 3.3rem;
    border-radius: 50%;
    display: inline-flex;
    align-items: center;
    justify-content: center;

    img {
        width : 3rem;
        display: inline-block;
    }
`;