import React, { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

// style
import colors from "../../styles/colors";

// component
import { Wrapper, Text } from "../../components/layouts/layoutStorage";
import { PC } from "../../styles/globalStyles";
import { kakaoChannel } from "../../tool/kakao";

const Type = ['베이직 플랜', '프로 플랜'];
const PlanText = {
    "베이직 플랜" : {
        'price' : "• 월간 결제 16,500원/월\n• 연간 결제 178,200원(10% OFF)/연",
        'service' : "• 예약관리\n1. 전용 홈페이지 제작\n2. 다양한 온라인 결제수단(카드/간편결제, 현장결제)\n3. 상담 예약 신청 받기\n4. 예약 단계별 고객 알림톡/푸시 자동 발송\n\n• 판매관리\n1. 레슨, 이용권, 자격증, 국내/해외 투어 카테고리별 상품 등록\n2. 예약 받을 일정 설정\n3. 휴무 설정\n\n회원관리(Coming Soon)\n\n소속강사관리(Coming Soon)"
    },

    "프로 플랜" : {
        'price' : "• 월간 결제 231,000원/월\n• 연간 결제 2,494,800원(10% OFF)/연",
        'service' : "• 예약관리\n1. 전용 홈페이지 제작\n2. 다양한 온라인 결제수단(카드/간편결제, 현장결제)\n3. 상담 예약 신청 받기\n4. 예약 단계별 고객 알림톡/푸시 자동 발송\n\n• 판매관리\n1. 레슨, 이용권, 자격증, 국내/해외 투어 카테고리별 상품 등록\n2. 예약 받을 일정 설정\n3. 휴무 설정\n\n• 시설관리\n1. 출입관리\n2. 실시간 이용현황 대시보드\n3. 회원권 판매/등록\n\n회원관리(Coming Soon)\n\n소속강사관리(Coming Soon)\n\n• 안내 : 프로 플랜 구독은 문의가 필요합니다."
    },
}


const PartnerWellcome = ({setPage, page, setData, data}) => {

    const navigate = useNavigate();

    const NextPage = () => {
        navigate("/activity_manage", {replace: true});
    }

    return (

        <Content>

            <Wrapper
                width="calc(100% - 48px)"
                border={`1px solid ${colors.Bl07}`}
                bgc={colors.White}
                padding={`15px 10px`}
                radius={"8px"}
                wrap={`wrap`}
                style={{
                    position : 'fixed',
                    top : "50%",
                    left : '50%',
                    transform : "translate(-50%, -50%)",
                    maxWidth : '800px'
                }}
            >
                <Text
                    size="1.8rem"
                    weight="600"
                    width={`100%`}
                    style={{
                        whiteSpace : "pre-wrap",
                        marginBottom : '20px',
                        alignItems : "center",
                        textAlign : "center",
                        justifyContent : "center"
                    }}
                    color={colors.Bl07}
                >
                    {`🎉 세모스 파트너 입점을 환영합니다 🎉`}
                </Text>

                <Text
                    size="1.6rem"
                    weight="500"
                    width={`100%`}
                    style={{
                        whiteSpace : "pre-wrap"
                    }}
                >
                    {`고객님에게 보여질 파트너 정보와 판매할 프로그램을 등록해주세요!\n\n예약 진행 상황은 카카오톡 메시지를 통해 안내하고 있습니다.\n원활한 예약 진행을 위해 '세모스 파트너' 카카오톡 채널 추가/차단해제를 부탁드립니다.\n\n세모스 담당 매니저가 추가 안내를 드릴게요!\n전화/카카오톡 메시지를 꼭 확인해주세요!\n\n`}
                </Text>
            </Wrapper>

            <Wrapper
                width="100%"
                bgc={colors.White}
                padding={`10px 24px`}
                style={{
                    position : "fixed",
                    bottom : 0,
                    left : '50%',
                    transform : "translate(-50%, 0%)",
                    zIndex : 10,
                    borderTop : `1px solid ${colors.Gr01}`
                }}
            >
                <Wrapper
                    width="100%"
                    bgc={colors.Bl07}
                    radius={`8px`}
                    height={`52px`}
                    cursor={`pointer`}
                    ai={`center`}
                    jc={`center`}
                    onClick={() => NextPage()}
                >
                    <Text
                        size="1.6rem"
                        weight="600"
                        color={colors.White}
                    >
                        {"프로그램 등록하러 가기"}
                    </Text>
                </Wrapper>
            </Wrapper> 

        </Content>

    )
}

export default PartnerWellcome;

const Content = styled.div`
    padding: 24px 24px 70px 24px;
    background-color: ${colors.White};
    width : 100%;
    max-width : 800px;
    margin : 0 auto;
`;

const ControlButton = styled.div`
    width: ${({ width }) => `${width}`};
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    padding: 8px 12px;
    background-color: ${({ active }) => (active === "active" ? colors.Bl02 : colors.White)};
    border: 1px solid ${({ active }) => (active === "active" ? colors.Bl07 : colors.Bl04)};
    margin : 0 8px 0 0;
`;

const ControlText = styled.div`
    white-space: pre-wrap;
    text-align: center;
    color: ${({ active }) => (active === "active" ? colors.Bl07 : colors.Bl04)};
    font-size: 13px;
    font-weight: 600;
`;
