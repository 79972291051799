import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { IPhone, IPad, Android } from "../tool/device_check";
import { GetOne, SaveMany } from "../tool/localstorage";

const LoginCheck2 = () => {
  const navigate = useNavigate();

  const GoogleLoginCheck = () => {
    const current = decodeURI(window.location.href);
    const search = current.split("#")[1];
    const params = new URLSearchParams(search);
    const googleToken = params.get("id_token");

    const frm = new FormData();

    frm.append("mode", "google_login");
    frm.append("google_code", googleToken);

    axios
      .post("https://ai.semos.kr/semos_partner/v3/profile/Login", frm, {
        headers: { "Content-Type": "multipart/form-data" },
      })
      .then((data) => {
        var Value = data.data;
        var Status = Value.login_status;
        var Token = Value.login_token;
        var UserData = Value.user_data;

        if (Status === "success") {
          var nickname = UserData.user_name;
          var name = UserData.user_real_name;
          var age = UserData.user_age;
          var profile = UserData.user_profile_img;
          var phone = UserData.user_phone_number;
          var gender = UserData.user_gender;
          var email = UserData.user_email;

          var LocalStorageData = [
            { type: "nickname", value: nickname },
            { type: "name", value: name },
            { type: "age", value: age },
            { type: "profile", value: profile },
            { type: "phone", value: phone },
            { type: "gender", value: gender },
            { type: "email", value: email },
            { type: "token", value: Token },
            { type: "login_type", value: "구글" },
          ];

          if (SaveMany(LocalStorageData)) {
            navigate("/home");
          }
        } else if (Status === "un_partner") {
          var nickname = UserData.user_name;
          var name = UserData.user_real_name;
          var age = UserData.user_age;
          var profile = UserData.user_profile_img;
          var phone = UserData.user_phone_number;
          var gender = UserData.user_gender;
          var email = UserData.user_email;

          var LocalStorageData = [
            { type: "nickname", value: nickname },
            { type: "name", value: name },
            { type: "age", value: age },
            { type: "profile", value: profile },
            { type: "phone", value: phone },
            { type: "gender", value: gender },
            { type: "email", value: email },
            { type: "token", value: Token },
            { type: "login_type", value: "구글" },
          ];

          if (SaveMany(LocalStorageData)) {
            //navigate("/partner_register_new/0", {replace: true});
            navigate("/partner_register");
          }

          /*window.alert('미등록된 파트너입니다.\n등록 상담 신청을 통해 파트너 등록을 완료해주세요!')
          window.location.href = 'https://forms.gle/mpMXYxmcxyXetueB8'*/
        } else if (Status === "unready") {
          const StatusMessage = Value.status_message;
          window.alert(StatusMessage);
          navigate("/");
        } else if (Status === "other_user") {
          window.alert("이전에 가입한 아이디가 있어요.\n다른 아이디로 로그인해 주세요!");
          navigate("/");
        } else {
          window.alert("로그인이 실패했습니다.\n로그인 실패가 반복된다면 고객센터로 문의해주세요!");
          navigate("/");
        }
      });
  };

  useEffect(() => {
    GoogleLoginCheck();
  }, []);

  return <></>;
};

export default LoginCheck2;
