import React from "react";
import styled from "styled-components";

// style
import colors from "../../styles/colors";

const W = window.document.documentElement.clientWidth;

function AlarmItem({ category, data }) {
  return (
    <AlarmContainer>
      <Styler
        pt={20}
        pb={20}
        pl={24}
        pr={18}
        flex={true}
        align="center"
        justify="space-between"
        update={!data.view}
      >
        <JustBetween>
          <Circle update={!data.view}>
            <img src={category.img} />
          </Circle>
          <TextContainer>
            <AlarmTitle>{data.title}</AlarmTitle>
            <AlarmContent>{data.description}</AlarmContent>
          </TextContainer>
        </JustBetween>

        <DDC>
          <DateBox>{data.date.split(" ")[0]}</DateBox>
          {!data.view && (
            <DotContainer>
              <Dot />
            </DotContainer>
          )}
        </DDC>
      </Styler>
    </AlarmContainer>
  );
}

export default AlarmItem;

const Styler = styled.div`
  display: ${({ flex }) => (flex ? "flex" : "")};
  flex-direction: ${({ direction }) => direction};
  align-items: ${({ align }) => align};
  justify-content: ${({ justify }) => justify};
  padding-top: ${({ pt }) => pt}px;
  padding-right: ${({ pr }) => pr}px;
  padding-bottom: ${({ pb }) => pb}px;
  padding-left: ${({ pl }) => pl}px;
  background-color: ${({ update }) =>
    update ? `${colors.Bl01}` : `${colors.White}`};
  position: relative;
`;

const JustBetween = styled.div`
  display: flex;
  align-items: center;
`;

const AlarmContainer = styled.div`
  border-bottom: 1px solid ${colors.Gr01};
`;

const Circle = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  min-width: 34px;
  width: 34px;
  height: 34px;
  margin-right: 16px;
  background-color: ${({ update }) =>
    update ? `${colors.Bl02}` : `${colors.Gr01}`};
  border-radius: 50%;

  img {
    display: inline-block;
    width: 22px;
    height: 22px;
  }
`;

const TextContainer = styled.div`
  /* background-color: aliceblue; */
`;

const AlarmTitle = styled.div`
  color: ${colors.Gr05};
  line-height: 142%;
  font-size: 14px;
  font-weight: 600;
`;

const AlarmContent = styled.div`
  max-width: ${W - 174}px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  color: ${colors.Gr03};
  line-height: 142%;
  letter-spacing: -0.02em;
  font-size: 12px;
  font-weight: 500;
`;

const DateBox = styled.div`
  display: flex;
  justify-content: end;
  min-width: 67px;
  width: 67px;
  font-weight: 500;
  font-size: 12px;
  line-height: 142%;
  letter-spacing: -0.02em;
  color: ${colors.Gr04};
  /* margin-left: 10px; */
`;

const DDC = styled.div`
  display: inline-flex;
`;

const DotContainer = styled.div`
  width: 16px;
  height: 16px;
  padding: 5px;
`;

const Dot = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 4px;
  height: 4px;
  border-radius: 50%;
  background-color: ${colors.Bl05};
`;
