import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Sheet from "react-modal-sheet";

// components
import HomeHeader from "../components/home/HomeHeader";
import AffiliateAlertModal from "../components/home/AffiliateAlertModal";
import PaymentModal from "../components/home/PaymentModal";
import { GetOne, SaveOne, removeOne, SaveMany } from "../tool/localstorage";
import { LoadingSpinner } from "../tool/loading";
import detectDevice from "../tool/detectDevice";
import ActivityRegisterAlertModal from "../components/home/ActivityRegisterAlertModal";
import { fbevent } from "../tool/firebase";

//style
import colors from "../styles/colors";
import { W, H } from "../styles/globalStyles";

// img
import PartnerProfileModalImg from "../icon/partnerProfileBanner.png";
import { ReactComponent as CheckCircle } from "../icon/checkCircle.svg";
import { ReactComponent as CheckCircleFilled } from "../icon/checkCircleFilled.svg";
import { ReactComponent as Chat } from "../icon/chat.svg";
import { ReactComponent as Dashboard } from "../icon/dashboard.svg";
import { ReactComponent as Pay } from "../icon/pay.svg";
import { ReactComponent as Review } from "../icon/review.svg";
import { ReactComponent as Facility } from "../icon/facility.svg";
import { ReactComponent as Crew } from "../icon/crew.svg";
import { ReactComponent as Notice } from "../icon/partner_notice.svg";

const MENU_LIST = [
  {
    title: "예약 관리",
    content: "진행중인 예약과\n지난 예약을 관리해요",
    url: "/reservation_manage",
    img: "pay",
    count: 0,
  },
  {
    title: "상담 관리",
    content: "상담 내역을 확인하고\n관리해요",
    url: "/consult",
    img: "chat",
    count: 0,
  },
  {
    title: "액티비티 관리",
    content: "액티비티를 등록하고\n관리해요",
    url: "/activity_manage",
    img: "dashboard",
    count: 0,
  },
  /*{
    title: "회원 관리",
    content: "등록된 회원 정보 확인과\n회원권을 관리해요",
    url: "/crew_coach",
    img: "crew",
    count: 0,
  },
  {
    title: "채팅 관리",
    content: "크루님들이 보낸\n세모스챗을 관리해요",
    url: "/chat_list",
    img: "chat",
    count: 0,
  },*/
  {
    title: "공지사항 관리",
    content: "크루님에게 전달할\n공지사항을 관리해요",
    url: "/notice",
    img: "notice",
    count: 0,
  },
  {
    title: "리뷰 관리",
    content: "액티비티에 작성된\n리뷰를 관리해요",
    url: "/review_list",
    img: "review",
    count: 0,
  },
];

const MENU_LIST2 = [
  {
    title: "예약 관리",
    content: "진행중인 예약과\n지난 예약을 관리해요",
    url: "/reservation_manage",
    img: "pay",
    count: 0,
  },
  /*{
    title: "상담 관리",
    content: "상담 내역을 확인하고\n관리해요",
    url: "/consult",
    img: "chat",
    count: 0,
  },*/
  {
    title: "액티비티 관리",
    content: "액티비티를 등록하고\n관리해요",
    url: "/activity_manage",
    img: "dashboard",
    count: 0,
  },
  /*{
    title: "회원 관리",
    content: "등록된 회원 정보 확인과\n회원권을 관리해요",
    url: "/crew_coach",
    img: "crew",
    count: 0,
  },
  {
    title: "채팅 관리",
    content: "크루님들이 보낸\n세모스챗을 관리해요",
    url: "/chat_list",
    img: "chat",
    count: 0,
  },*/
  {
    title: "공지사항 관리",
    content: "크루님에게 전달할\n공지사항을 관리해요",
    url: "/notice",
    img: "notice",
    count: 0,
  },
  {
    title: "리뷰 관리",
    content: "액티비티에 작성된\n리뷰를 관리해요",
    url: "/review_list",
    img: "review",
    count: 0,
  },
];

const IconConverter = (img) => {
  switch (img) {
    case "pay":
      return <Pay fill={colors.Gr05} />;
    case "chat":
      return <Chat fill={colors.Gr05} />;
    case "dashboard":
      return <Dashboard fill={colors.Gr05} />;
    case "review":
      return <Review fill={colors.Gr05} />;
    case "facility":
      return <Facility fill={colors.Gr05} />;
    case "crew":
      return <Crew fill={colors.Gr05} />;
    case "notice":
      return <Notice fill={colors.Gr05} />;
    default:
      break;
  }
};

const Home = () => {
  const navigate = useNavigate();

  //const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const [isMobile, setIsMobile] = useState(true);
  const [defaultInfo, setDefaultInfo] = useState({
    center: 0,
    holiday: false,
    name: "",
    profile: "",
    facility : 1,
    facility_count : 0
  });
  const [newAlarm, setNewAlarm] = useState(false);
  const [payment, setPayment] = useState("");
  const [menuList, setMenuList] = useState(MENU_LIST);
  const [menuList2, setMenuList2] = useState(MENU_LIST2);
  const [paymentAlertTotal, setPaymentAlertTotal] = useState(0);
  const [paymentAlertActivity, setPaymentAlertActivity] = useState({
    payment_id: 0,
    title: "",
    profile: "",
    people: 0,
    schedule: "",
  });
  const [activityStatus, setActivityStatus] = useState(false);
  const [paymentModal, setPaymentModal] = useState(false);
  const [activityRegisterAlertModal, setActivityRegisterAlertModal] = useState(false);
  const [affiliateModal, setAffiliateModal] = useState(false);
  const [affiliateCheck, setAffiliateCheck] = useState(false);

  useEffect(() => {
    fbevent("B2B_Home_Page_View", {
      email : GetOne("email")
    });

    const res = detectDevice(window.navigator.userAgent);
    setIsMobile(res);
    getData();
  }, []);

  // GET DATA
  const getData = () => {
    const token = GetOne("token");
    const device_token = GetOne("deviceToken");

    const frm = new FormData();

    frm.append("mode", "main");
    frm.append("token", token);

    if (device_token) {
      frm.append("device_token", device_token);
    }

    axios
      .post("https://ai.semos.kr/semos_partner/v3/profile/Home", frm, {
        headers: { "Content-Type": "multipart/form-data" },
      })
      .then((res) => {
        const value = res.data;
        const status = value.status;
        const statusMessage = value.status_message;
        const data = value.data;
        if (status === "success") {
          // console.log("HOME DATA >> ", data);
          setDefaultInfo(data.default_info);
          setNewAlarm(data.new_alarm);
          setPayment(data.payment);
          setMenuList(
            menuList.map((val) => {
              switch (val.title) {
                case "예약 관리":
                  return { ...val, count: data.reservation_count };
                case "채팅 관리":
                  return { ...val, count: data.chat_count };
                case "상담 관리":
                  return { ...val, count: data.chat_count };
                case "공지사항 관리":
                    return { ...val, count: 0 };
                case "액티비티 관리":
                  return { ...val, count: data.dashboard_update ? "NEW" : 0 };
                case "리뷰 관리":
                  return { ...val, count: data.review_count };
                default:
              }
            })
          );

          setMenuList2(
            menuList2.map((val) => {
              switch (val.title) {
                case "예약 관리":
                  return { ...val, count: data.reservation_count };
                case "채팅 관리":
                  return { ...val, count: data.chat_count };
                case "상담 관리":
                  return { ...val, count: data.chat_count };
                case "공지사항 관리":
                    return { ...val, count: 0 };
                case "액티비티 관리":
                  return { ...val, count: data.dashboard_update ? "NEW" : 0 };
                case "리뷰 관리":
                  return { ...val, count: data.review_count };
                default:
              }
            })
          );
          setPaymentAlertActivity(data.payment_alert_activity);
          setPaymentAlertTotal(data.reservation_count);
          data.reservation_count !== 0 ? setPaymentModal(true) : setPaymentModal(false);
          setActivityStatus(data.activity_status);

          const localStorageData = [{ type: "nickname", value: data.default_info.name }];
          SaveMany(localStorageData);
          setLoading(false);
        } else if (status === "waring") {
          setLoading(false);
          window.localStorage.clear();
          window.alert(statusMessage);
          window.location.replace("/");
        }
        // 파트너 홈 광고 노출
        const today = new Date();
        const todayStr = `${today.getFullYear()}-${Number(today.getMonth() + 1)}-${today.getDate()}`;
        const modalDate = GetOne("affiliate_modal");
        if (!modalDate) {
          setAffiliateModal(true);
        } else {
          if (todayStr === modalDate) {
            setAffiliateModal(false);
          } else {
            setAffiliateModal(true);
            removeOne("affiliate_modal");
          }
        }
      });
  };

  // 제휴 상품 팝업 오늘 하루 보지 않기
  const handleAffiliateCheck = () => {
    if (affiliateCheck) {
      removeOne("affiliate_modal");
    } else {
      const today = new Date();
      const todayStr = `${today.getFullYear()}-${Number(today.getMonth()) + 1}-${today.getDate()}`;

      SaveOne("affiliate_modal", todayStr);
    }
    setAffiliateCheck(!affiliateCheck);
  };

  return (
    <>
      {loading ? (
        <></>
      ) : (
        <Container>
          <HomeHeader newAlarm={newAlarm ? newAlarm : false} />
          <ProfileContainer>
            <ProfileCard>
              <ProfileInfoContainer>
                <ProfileImgContainer>
                  <ProfileImg src={defaultInfo.profile} />
                </ProfileImgContainer>
                <ProfileInfo>
                  <ProfileNameContainer>
                    <ProfileName>{defaultInfo.name} 님</ProfileName>
                    {defaultInfo.holiday ? <ProfileHoliday>휴무일</ProfileHoliday> : null}
                  </ProfileNameContainer>
                  <ProfileButtonContainer>
                    <button onClick={() => navigate("/day_off")}>휴무 관리</button>
                    <button onClick={() => navigate("/my_info")}>내 정보 관리</button>
                  </ProfileButtonContainer>
                </ProfileInfo>
              </ProfileInfoContainer>

              {/*<ProfilePaymentContainer
                onClick={() =>
                  navigate("/reservation_manage", {
                    state: {
                      index: 3,
                    },
                  })
                }
              >
                <PaymentText>이번 달 정산 예정 금액</PaymentText>
                <PaymentAmount>
                  <span>{payment}원</span>
                  <Arrow fill={colors.Bl07} />
                </PaymentAmount>
              </ProfilePaymentContainer>*/}
            </ProfileCard>
          </ProfileContainer>

            {/*
              (defaultInfo?.facility == 2) 
              ?
                <MenuItem2
                  onClick={() => navigate('/facility_reservation')}
                >

                  <h3>시설 예약 현황</h3>
                  <MenuContent>{'시설 예약 현황을 확인할 수 있어요!'}</MenuContent>
                </MenuItem2>
              : ""
              */}

          <MenuContainer>
            {
              (defaultInfo?.facility == 2) 
              ?
                <>
                  <MenuItem
                    onClick={() => navigate('/facility_reservation')}
                  >

                    <h3>시설 이용 현황</h3>
                    <MenuContent>{'시설 이용 현황을 확인할 수 있어요!'}</MenuContent>
                  </MenuItem>

                  <MenuItem
                    onClick={() => navigate('/class_reservation')}
                  >

                    <h3>정기 레슨 현황</h3>
                    <MenuContent>{'정기 레슨 현황을 확인할 수 있어요!'}</MenuContent>
                  </MenuItem>

                  <MenuItem
                      onClick={() => navigate('/facility')}
                    >
                      {IconConverter('facility')}
                      <h3>시설 관리</h3>
                      <MenuContent>{'운영중인 시설 정보를\n확인하고 관리해요'}</MenuContent>
                      {/*<MenuCount>
                        <span>{defaultInfo?.facility_count}</span>
                      </MenuCount>*/}
                  </MenuItem>
            
                  <MenuItem
                      onClick={() => navigate('/crew')}
                    >
                      {IconConverter('crew')}
                      <h3>회원 관리</h3>
                      <MenuContent>{'등록된 회원 정보 확인과\n회원권을 관리해요'}</MenuContent>
                      
                      {/*<MenuCount>
                        <span>{defaultInfo?.facility_count}</span>
                      </MenuCount>*/}
                  </MenuItem>

                  {
                    menuList2.map((val, index) => {
                      const { title, content, url, img, count } = val;
        
                      return (
                        <MenuItem
                          key={`${title}-${index}`}
                          onClick={() => {
                            title === "액티비티 관리" && !activityStatus ? setActivityRegisterAlertModal(true) : navigate(url);
                          }}
                        >
                          {IconConverter(img)}
                          <h3>{title}</h3>
                          <MenuContent>{content}</MenuContent>
                          {count !== 0 ? (
                            <MenuCount>
                              <span>{count}</span>
                            </MenuCount>
                          ) : (
                            <></>
                          )}
                        </MenuItem>
                      );
                    })
                  }
                </>
              : 
                menuList.map((val, index) => {
                  const { title, content, url, img, count } = val;
    
                  return (
                    <MenuItem
                      key={`${title}-${index}`}
                      onClick={() => {
                        title === "액티비티 관리" && !activityStatus ? setActivityRegisterAlertModal(true) : navigate(url);
                      }}
                    >
                      {IconConverter(img)}
                      <h3>{title}</h3>
                      <MenuContent>{content}</MenuContent>
                      {count !== 0 ? (
                        <MenuCount>
                          <span>{count}</span>
                        </MenuCount>
                      ) : (
                        <></>
                      )}
                    </MenuItem>
                  );
                })
            }

          </MenuContainer>

          {/*<GuideContainer onClick={() => navigate("/partner_invite")}>
            <GuideImg src={FriendsPartner} />
          </GuideContainer>*/}

          {/* 제휴 상품 팝업 */}
          {isMobile ? (
            <AffiliateModal
              style={{
                width: `${W}px`,
                display: "flex",
                justifyContent: "center",
                left: "50%",
                transform: "translate(-50%,0)",
              }}
              isOpen={affiliateModal}
              snapPoints={[0.25]}
            >
              <AffiliateModal.Container
                style={{
                  width: `${W}px`,
                  left: "none",
                  bottom: "0",
                }}
              >
                <AffiliateImg
                  src={PartnerProfileModalImg}
                  alt="affiliate modal"
                  onClick={() => {
                    navigate("/profile");
                  }}
                />
                <AffiliateModalLayout>
                  <AffiliateCheck onClick={() => {
                    handleAffiliateCheck()
                    fbevent("B2B_Home_ModalCloseDay_Click", {
                      email : GetOne("email")
                    });
                  }}>
                    {affiliateCheck ? <CheckCircleFilled fill={colors.Gr04} /> : <CheckCircle fill={colors.Gr04} />}
                    <span>오늘 하루 보지 않기</span>
                  </AffiliateCheck>
                  <AffiliateBtnContainer>
                    <AffiliateBtn
                      onClick={() => {
                        setAffiliateModal(false);
                        fbevent("B2B_Home_ModalClose_Click", {
                          email : GetOne("email")
                        });
                      }}
                      isClose={true}
                    >
                      닫기
                    </AffiliateBtn>
                    <AffiliateBtn
                      onClick={() => {
                        navigate("/profile");
                        fbevent("B2B_Home_ModalOpen_Click", {
                          email : GetOne("email")
                        });
                      }}
                      isClose={false}
                    >
                      홍보 페이지 사용하기
                    </AffiliateBtn>
                  </AffiliateBtnContainer>
                </AffiliateModalLayout>
              </AffiliateModal.Container>

              {affiliateModal && <AffiliateModal.Backdrop />}
            </AffiliateModal>
          ) : (
            <AffiliateAlertModal
              modal={affiliateModal}
              setModal={() => {
                setAffiliateModal(false);
              }}
              affiliateCheck={affiliateCheck}
              handleAffiliateCheck={() => handleAffiliateCheck()}
            />
          )}

          {/* 결제 발생 모달 */}
          {paymentModal ? (
            <PaymentModal
              paymentModal={paymentModal}
              setPaymentModal={setPaymentModal}
              paymentAlertActivity={paymentAlertActivity}
              paymentAlertTotal={paymentAlertTotal}
            />
          ) : (
            <></>
          )}

          {/* 액티비티 등록 추가정보 모달 */}
          {activityRegisterAlertModal ? <ActivityRegisterAlertModal modal={activityRegisterAlertModal} setModal={setActivityRegisterAlertModal} /> : <></>}

          <LoadingSpinner loading={loading} title={`정보를 확인하고 있습니다.`} content={`잠시만 기다려 주세요!`} />
        </Container>
      )}
    </>
  );
};

export default Home;

const Container = styled.section`
  height: calc(var(--vh, 1vh) * 100);
  overflow-x: hidden;
  background-color: ${colors.Gr01};
  padding-bottom : 30rem;
`;

const ProfileContainer = styled.div`
  padding: 12px 24px;
  background-color: ${colors.Gr01};
`;

const ProfileCard = styled.div`
  background-color: ${colors.White};
  border-radius: 12px;
  overflow: hidden;
  border: 1px solid ${colors.Gr02};
`;

const ProfileInfoContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 16px;
  padding: 16px;
`;

const ProfilePaymentContainer = styled.div`
  background-color: ${colors.White};
  padding: 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-top: 1px solid ${colors.Gr02};
  cursor: pointer;
`;

const ProfileImgContainer = styled.div`
  width: 56px;
  height: 56px;
  border-radius: 50%;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ProfileImg = styled.img`
  width: 100%;
  height: 100%;
`;

const ProfileInfo = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const ProfileNameContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 4px;
  background-color: ${colors.White};
`;

const ProfileName = styled.span`
  color: ${colors.Gr06};
  line-height: 26px;
  font-size: 18px;
  font-weight: 700;
`;

const ProfileHoliday = styled.span`
  color: ${colors.Red03};
  line-height: 22px;
  font-size: 14px;
  font-weight: 600;
`;

const ProfileButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 4px;

  button {
    flex: 1;
    padding: 10px 16px;
    background-color: ${colors.Gr01};
    border-radius: 8px;
    color: ${colors.Gr05};
    text-align: center;
    line-height: 22px;
    letter-spacing: -0.28px;
    font-size: 14px;
    font-weight: 500;
  }
`;

const PaymentText = styled.span`
  color: ${colors.Gr04};
  line-height: 20px;
  font-size: 12px;
  font-weight: 500;
`;

const PaymentAmount = styled.span`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 6px;

  span {
    color: ${colors.Bl07};
    line-height: 24px;
    font-size: 16px;
    font-weight: 600;
  }
`;

const MenuContainer = styled.ul`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 12px;
  padding: 12px 24px 16px 24px;
  background-color: ${colors.Gr01};
  width : 100%;
`;

const MenuItem = styled.li`
  padding: 16px 12px;
  background-color: ${colors.White};
  border: 1px solid ${colors.Gr02};
  border-radius: 8px;
  position: relative;
  cursor: pointer;

  h3 {
    color: ${colors.Gr05};
    line-height: 24px;
    letter-spacing: -0.32px;
    font-size: 16px;
    font-weight: 500;
  }

  svg {
    width: 24px;
    height: 24px;
  }
`;

const MenuItem2 = styled.li`
  padding: 16px 12px;
  background-color: ${colors.White};
  border: 1px solid ${colors.Gr02};
  border-radius: 8px;
  position: relative;
  cursor: pointer;
  width : calc(100% - 48px);
  margin : 0 24px;

  h3 {
    color: ${colors.Gr05};
    line-height: 24px;
    letter-spacing: -0.32px;
    font-size: 16px;
    font-weight: 500;
  }

  svg {
    width: 24px;
    height: 24px;
  }
`;

const MenuContent = styled.span`
  color: ${colors.Gr03};
  line-height: 20px;
  letter-spacing: -0.24px;
  font-size: 12px;
  font-weight: 500;
  white-space: pre-wrap;
`;

const MenuCount = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 36px;
  height: 28px;
  background-color: ${colors.Red03};
  border-radius: 12px;
  position: absolute;
  top: 12px;
  right: 8px;

  span {
    color: ${colors.White};
    text-align: center;
    line-height: 20px;
    letter-spacing: -0.24px;
    font-size: 12px;
    font-weight: 600;
  }
`;

const GuideContainer = styled.div`
  padding: 0 24px 16px 24px;
  background-color: ${colors.Gr01};
`;

const GuideImg = styled.img`
  width : 100%;
  display : inline-block;
`;

const GuideItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  gap: 4px;
  width: 100%;
  padding: 12px 16px;
  background-color: ${colors.Gr03};
  border-radius: 8px;
  position: relative;

  h3,
  span {
    color: ${colors.White};
  }

  h3 {
    line-height: 24px;
    letter-spacing: -0.32px;
    font-size: 16px;
    font-weight: 600;
  }

  span {
    line-height: 22px;
    letter-spacing: -0.28px;
    font-size: 14px;
    font-weight: 500;
  }

  svg {
    position: absolute;
    top: 50%;
    right: 16px;
    transform: translateY(-50%);
  }
`;

const AffiliateModal = styled(Sheet)`
  .react-modal-sheet-container {
    height: 152px !important;
    width: 100% !important;
    border-radius: 0 !important;
  }
`;

const AffiliateModalLayout = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  padding: 20px 24px 44px 24px;
  border-radius: 0;
`;

const AffiliateImg = styled.img`
  width: 100%;
  /* height: 100%; */
  position: absolute;
  bottom: 152px;
  cursor: pointer;
`;

const AffiliateCheck = styled.span`
  display: flex;
  align-items: center;
  gap: 4px;
  background-color: ${colors.White};
  line-height: 22px;
  font-size: 14px;
  font-weight: 600;
  cursor: pointer;

  svg {
    width: 16px;
    height: 16px;
  }
`;

const AffiliateBtnContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
`;

const AffiliateBtn = styled.button`
  flex: 1;
  padding: 14px 0;
  background-color: ${({ isClose }) => (isClose ? `${colors.Gr01}` : `${colors.Bl07}`)};
  border-radius: 8px;
  color: ${({ isClose }) => (isClose ? `${colors.Gr05}` : `${colors.White}`)};
`;
