import React, { useState, useEffect } from "react";
import styled from "styled-components";
// import { TimePicker } from "react-ios-time-picker";
import TimePicker from "rc-time-picker";
import "rc-time-picker/assets/index.css";
import moment from "moment";

import AddressService from "../../tool/address";

//img
import GreySun from "../../icon/greySun.png";
import GreyMoon from "../../icon/greyMoon.png";
import colors from "../../styles/colors";

function SemosSchedule({
  scheduleName,
  setScheduleName,
  activeTime,
  setActiveTime,
  yoil,
  setYoil,
  start,
  setStart,
  focus,
  setFocus,
  address,
  setAddress,
  setAddressS,
}) {
  let SunTime = [];
  let MoonTime = [];

  let sunTimSplit = activeTime.start.split(":");
  let moonTimSplit = activeTime.end.split(":");
  let sunH = Number(sunTimSplit[0]);
  let sunM = Number(sunTimSplit[1]);
  let moonH = Number(moonTimSplit[0]);
  let moonM = Number(moonTimSplit[1]);
  let sunAM = sunH * 60 + sunM;
  let moonAM = moonH * 60 + moonM;

  let TimeCount = ((moonH - sunH) * 60 + (moonM - sunM)) / 30;

  for (var i = 0; i < TimeCount; i++) {
    let ScheduleTime = sunAM + 30 * i;

    let ScheduleHour = parseInt(ScheduleTime / 60);
    let ScheduleMinute = ScheduleTime % 60;

    let ScheduleHourText = ScheduleHour;
    let ScheduleMinuteText = ScheduleMinute;

    if (ScheduleHourText < 10) {
      ScheduleHourText = `0${ScheduleHourText}`;
    }

    if (ScheduleMinuteText == 0 || !ScheduleMinuteText) {
      ScheduleMinuteText = `00`;
    }

    let TimeData = {
      text: `${ScheduleHourText} : ${ScheduleMinuteText}`,
      value: `${ScheduleHourText}:${ScheduleMinuteText}`,
    };

    if (ScheduleHour < 12) {
      SunTime.push(TimeData);
    } else {
      MoonTime.push(TimeData);
    }
  }

  const [pickTime, setPickTime] = useState({
    start: moment(),
    end: moment(),
  });

  const ChangeName = (type, value) => {
    if (type == "address_extra") {
      if (value) {
        setAddress((prev) => ({ ...prev, [type]: value }));
        // setFocus((prev) => ({ ...prev, [type]: true }));
      } else {
        setAddress((prev) => ({ ...prev, [type]: value }));
        // setFocus((prev) => ({ ...prev, [type]: false }));
      }
    } else {
      if (value) {
        setScheduleName(value);
        // setFocus((prev) => ({ ...prev, [type]: true }));
      } else {
        setScheduleName(value);
        // setFocus((prev) => ({ ...prev, [type]: false }));
      }
    }
  };

  const AddressChange = (data) => {
    var locationSigungu = data.sigungu.split(" ");

    if (locationSigungu.length > 1) {
      var loGu = locationSigungu[1].length > 2 ? locationSigungu[1].slice(0, -1) : locationSigungu[1];
      var loSi = locationSigungu[0].length > 2 ? locationSigungu[0].slice(0, -1) : locationSigungu[0];
      var loDong = data.bname.length > 2 ? data.bname.slice(0, -1) : data.bname;
    } else {
      var loGu = data.sigungu.length > 2 ? data.sigungu.slice(0, -1) : data.sigungu;
      var loSi = data.sido;
      var loDong = data.bname.length > 2 ? data.bname.slice(0, -1) : data.bname;
    }

    var addressData = {
      address: data.roadAddress,
      address_extra: "",
      address_dong: loDong,
      address_gu: loGu,
      address_si: loSi == "강원특별자치도" ? "강원" : loSi,
    };

    setAddress(addressData);
    setAddressS(false);
  };

  const mapService = new AddressService(AddressChange);

  const SearchAddress = () => {
    setAddressS(true);
    mapService.open();
  };

  const SettingYoil = (value) => {
    let copy = [...yoil];

    if (copy.includes(value)) {
      copy = copy.filter((x) => x !== value);
    } else {
      copy.push(value);
    }

    setYoil(copy);
  };

  const SettingStartTime = (value) => {
    let copy = [...start];

    if (copy.includes(value)) {
      copy = copy.filter((x) => x !== value);
    } else {
      copy.push(value);
    }

    setStart(copy);
  };

  const timePick = (value, type) => {
    let copy = { ...activeTime };

    copy[type] = value;

    setActiveTime(copy);
  };

  const handleChange = (value, type) => {
    const str = "HH:mm";

    setPickTime((prev) => ({ ...prev, [type]: value && value.format(str) }));

    setActiveTime((prev) => ({ ...prev, [type]: value && value.format(str) }));
  };

  useEffect(() => {}, [pickTime]);

  const defaultTime = (h, m) => {
    return moment().set("hour", h).set("minute", m);
  };

  return (
    <>
      <BackgroundContent wrap={`wrap`} padding={`24px 24px 0 24px`} ai={`center`}>
        <TextContent width={`100%`} style={{ justifyContent: "flex-start" }}>
          <TextView color={colors.Gr05} fw={`500`} fs={`16px`} lh={`142%`}>{`일정 이름 작성하기`}</TextView>
        </TextContent>

        <Divider height={`12`} color={colors.White} />

        <BackgroundContent jc={`space-between`} padding={`0`} ai={`center`}>
          <InputView
            onChange={(e) => ChangeName("name", e.target.value)}
            value={`${scheduleName}`}
            placeholder={`일정 이름을 작성해 주세요 (예 : [평일] 강남 일정)`}
            br={`4`}
            padding={`10px 12px`}
            pc={colors.Gr03}
            color={colors.Gr05}
            width={`100%`}
            fs={`14px`}
            onFocus={() => setFocus((prev) => ({ ...prev, name: true }))}
            onBlur={() => setFocus((prev) => ({ ...prev, name: false }))}
            border={`1px solid ${focus.name ? colors.Gr04 : colors.Gr02}`}
          />
        </BackgroundContent>

        <Divider height={`9`} color={colors.White} />
      </BackgroundContent>

      <BackgroundContent wrap={`wrap`} padding={`24px 24px 0 24px`} ai={`center`}>
        <TextContent width={`100%`} style={{ justifyContent: "flex-start" }}>
          <TextView color={colors.Gr05} fw={`500`} fs={`16px`} lh={`142%`}>{`액티비티 위치 등록`}</TextView>
        </TextContent>

        <Divider height={`15`} color={colors.White} />

        <BackgroundContent jc={`space-between`} padding={`0`} ai={`center`}>
          <TextContent
            cursor="pointer"
            onClick={() => SearchAddress()}
            jc={`center`}
            ai={`center`}
            width={`97px`}
            padding={`7.5px 0`}
            br={`8`}
            border={`1px solid ${colors.Bl07}`}
          >
            <TextView color={colors.Bl07} fw={`600`} fs={`12px`}>{`주소찾기`}</TextView>
          </TextContent>

          <InputView
            value={address.address}
            readOnly
            br={`4`}
            padding={`6px 18px`}
            pc={colors.Gr03}
            fs={`14px`}
            color={colors.Gr05}
            width={`calc(100% - 112px)`}
            bgc={colors.Gr01}
            border={`1px solid ${colors.Gr02}`}
          />
        </BackgroundContent>

        <Divider height={`6`} color={colors.White} />

        <BackgroundContent jc={`space-between`} padding={`0`} ai={`center`}>
          <InputView
            value={address.address_extra}
            maxLength={`30`}
            placeholder={`상세 주소를 입력해 주세요`}
            br={`4`}
            padding={`6px 18px`}
            fs={`14px`}
            pc={colors.Gr03}
            color={colors.Gr05}
            width={`100%`}
            onChange={(e) => ChangeName("address_extra", e.target.value)}
            onFocus={() => setFocus((prev) => ({ ...prev, address_extra: true }))}
            onBlur={() => setFocus((prev) => ({ ...prev, address_extra: false }))}
            border={`1px solid ${focus.address_extra ? colors.Gr04 : colors.Gr02}`}
          />
        </BackgroundContent>
      </BackgroundContent>

      <BackgroundContent wrap={`wrap`} padding={`32px 24px 0 24px`} ai={`center`}>
        <TextContent width={`100%`} wrap={`wrap`} style={{ justifyContent: "flex-start" }}>
          <TextView width={`100%`} color={colors.Gr05} fw={`500`} fs={`16px`} lh={`142%`}>{`액티비티 활동 시간 설정`}</TextView>
          <TextView width={`100%`} color={colors.Gr03} fw={`500`} fs={`12px`} lh={`142%`}>{`파트너님의 액티비티 활동 시간을 설정해 주세요`}</TextView>
        </TextContent>

        <Divider height={`20`} color={colors.White} />

        <BackgroundContent jc={`space-between`} padding={`0`} ai={`center`}>
          <TextContent /*style={{ marginRight: "24px" }}*/ ai={`center`}>
            <TextView
              //   style={{ marginRight: "6px" }}
              color={colors.Gr04}
              fw={`600`}
              fs={`12px`}
              lh={`142%`}
            >{`활동 시작`}</TextView>

            <StartTimePick
              id="start"
              inputReadOnly={true}
              onChange={(time) => handleChange(time, "start")}
              defaultValue={defaultTime(sunH, sunM)}
              value={defaultTime(sunH, sunM)}
              showSecond={false}
              minuteStep={30}
            />
          </TextContent>

          <ColorLine
            bgc={colors.Gr02}
            //style={{ marginRight: "24px" }}
            width={`1`}
            height={`23`}
          />

          <TextContent ai={`center`}>
            <TextView
              //   style={{ marginRight: "6px" }}
              color={colors.Gr04}
              fw={`600`}
              fs={`12px`}
              lh={`142%`}
            >{`활동 종료`}</TextView>

            <StartTimePick
              id="end"
              defaultValue={defaultTime(moonH, moonM)}
              value={defaultTime(moonH, moonM)}
              inputReadOnly={true}
              onChange={(time) => handleChange(time, "end")}
              showSecond={false}
              minuteStep={30}
            />

            {/* <TextContent
              ai={`center`}
              jc={`space-between`}
              padding={`6px 5.5px`}
              bgc={colors.White}
              border={`1px solid ${colors.Gr02}`}
              br={`8`}
            >
              <TextView
                color={colors.Gr05}
                fw={`600`}
                fs={`14px`}
                lh={`142%`}
              >{`${
                activeTime &&
                (Number(activeTime.end.split(":")[0]) > 11 ? "오후" : "오전")
              } ${activeTime.end}`}</TextView>
            </TextContent> */}
          </TextContent>
        </BackgroundContent>

        <Divider height={`15`} color={colors.White} />

        <BackgroundContent jc={`flex-end`} padding={`0`} ai={`center`}>
          <TextContent
            cursor="pointer"
            onClick={(e) => SettingYoil("공휴일")}
            jc={`center`}
            width={`calc((100% - 54px) / 3)`}
            padding={`8px 0`}
            br={`8`}
            bgc={yoil.includes("공휴일") ? colors.Red03 : colors.White}
            border={`1px solid ${colors.Gr02}`}
            style={{ marginRight: "10px" }}
          >
            <TextView color={yoil.includes("공휴일") ? colors.White : colors.Gr03} fw={`500`} fs={`14px`} lh={`142%`}>{`공휴일 포함`}</TextView>
          </TextContent>

          <TextContent
            cursor="pointer"
            onClick={(e) => SettingYoil("공휴일")}
            jc={`center`}
            width={`calc((100% - 54px) / 3)`}
            padding={`8px 0`}
            br={`8`}
            bgc={!yoil.includes("공휴일") ? colors.Bl07 : colors.White}
            border={`1px solid ${colors.Gr02}`}
          >
            <TextView color={!yoil.includes("공휴일") ? colors.White : colors.Gr03} fw={`500`} fs={`14px`} lh={`142%`}>{`공휴일 제외`}</TextView>
          </TextContent>
        </BackgroundContent>

        <Divider height={`15`} color={colors.White} />

        <BackgroundContent jc={`space-between`} padding={`0`} ai={`center`}>
          <TextContent
            cursor="pointer"
            onClick={(e) => SettingYoil("일")}
            jc={`center`}
            width={`calc((100% - 54px) / 7)`}
            padding={`8px 0`}
            br={`8`}
            bgc={yoil.includes("일") ? colors.Red03 : colors.White}
            border={`1px solid ${colors.Gr02}`}
          >
            <TextView color={yoil.includes("일") ? colors.White : colors.Gr03} fw={`500`} fs={`14px`} lh={`142%`}>{`일`}</TextView>
          </TextContent>

          <TextContent
            cursor="pointer"
            onClick={(e) => SettingYoil("월")}
            jc={`center`}
            width={`calc((100% - 54px) / 7)`}
            padding={`8px 0`}
            br={`8`}
            border={`1px solid ${colors.Gr02}`}
            bgc={yoil.includes("월") ? colors.Bl07 : colors.White}
          >
            <TextView color={yoil.includes("월") ? colors.White : colors.Gr03} fw={`500`} fs={`14px`} lh={`142%`}>{`월`}</TextView>
          </TextContent>

          <TextContent
            cursor="pointer"
            onClick={(e) => SettingYoil("화")}
            jc={`center`}
            width={`calc((100% - 54px) / 7)`}
            padding={`8px 0`}
            br={`8`}
            bgc={yoil.includes("화") ? colors.Bl07 : colors.White}
            border={`1px solid ${colors.Gr02}`}
          >
            <TextView color={yoil.includes("화") ? colors.White : colors.Gr03} fw={`500`} fs={`14px`} lh={`142%`}>{`화`}</TextView>
          </TextContent>

          <TextContent
            cursor="pointer"
            onClick={(e) => SettingYoil("수")}
            jc={`center`}
            width={`calc((100% - 54px) / 7)`}
            padding={`8px 0`}
            br={`8`}
            bgc={yoil.includes("수") ? colors.Bl07 : colors.White}
            border={`1px solid ${colors.Gr02}`}
          >
            <TextView color={yoil.includes("수") ? colors.White : colors.Gr03} fw={`500`} fs={`14px`} lh={`142%`}>{`수`}</TextView>
          </TextContent>

          <TextContent
            cursor="pointer"
            onClick={(e) => SettingYoil("목")}
            jc={`center`}
            width={`calc((100% - 54px) / 7)`}
            padding={`8px 0`}
            br={`8`}
            bgc={yoil.includes("목") ? colors.Bl07 : colors.White}
            border={`1px solid ${colors.Gr02}`}
          >
            <TextView color={yoil.includes("목") ? colors.White : colors.Gr03} fw={`500`} fs={`14px`} lh={`142%`}>{`목`}</TextView>
          </TextContent>

          <TextContent
            cursor="pointer"
            onClick={(e) => SettingYoil("금")}
            jc={`center`}
            width={`calc((100% - 54px) / 7)`}
            padding={`8px 0`}
            br={`8`}
            bgc={yoil.includes("금") ? colors.Bl07 : colors.White}
            border={`1px solid ${colors.Gr02}`}
          >
            <TextView color={yoil.includes("금") ? colors.White : colors.Gr03} fw={`500`} fs={`14px`} lh={`142%`}>{`금`}</TextView>
          </TextContent>

          <TextContent
            cursor="pointer"
            onClick={(e) => SettingYoil("토")}
            jc={`center`}
            width={`calc((100% - 54px) / 7)`}
            padding={`8px 0`}
            br={`8`}
            bgc={yoil.includes("토") ? colors.Red03 : colors.White}
            border={`1px solid ${colors.Gr02}`}
          >
            <TextView color={yoil.includes("토") ? colors.White : colors.Gr03} fw={`500`} fs={`14px`} lh={`142%`}>{`토`}</TextView>
          </TextContent>
        </BackgroundContent>
      </BackgroundContent>

      <BackgroundContent wrap={`wrap`} padding={`40px 24px 0 24px`} ai={`center`}>
        <TextContent width={`100%`} wrap={`wrap`} style={{ justifyContent: "flex-start" }}>
          <TextView width={`100%`} color={colors.Gr05} fw={`500`} fs={`16px`} lh={`142%`}>{`액티비티 예약 시간 선택`}</TextView>
          <TextView width={`100%`} color={colors.Gr03} fw={`500`} fs={`12px`} lh={`142%`}>{`액티비티 시작이 가능한 시간을 선택해 주세요`}</TextView>
        </TextContent>

        <Divider height={`10`} color={colors.White} />

        <BackgroundContent jc={`flex-start`} padding={`0`} ai={`center`}>
          <TextContent ai={`center`} width={`100%`}>
            <ImageIcon100 style={{ marginRight: "6px" }} width={`11px`} height={`11px`}>
              <img src={GreySun} />
            </ImageIcon100>

            <TextView color={colors.Gr03} fw={`500`} fs={`12px`} lh={`142%`}>{`오전`}</TextView>
          </TextContent>
        </BackgroundContent>

        <Divider height={`10`} color={colors.White} />

        <BackgroundContent width={`100%`} wrap={`wrap`} jc={`flex-start`} padding={`0`} ai={`center`}>
          {SunTime.map((item, idx) => (
            <TextContentT
              cursor="pointer"
              index={(idx + 1) % 4}
              key={`${idx}_sunTime`}
              onClick={() => SettingStartTime(item.value)}
              jc={`center`}
              width={`calc((100% - 28px) / 4)`}
              padding={`8px 0`}
              br={`7`}
              bgc={start.includes(item.value) ? colors.Bl07 : colors.White}
              border={`1px solid ${colors.Gr02}`}
            >
              <TextView color={start.includes(item.value) ? colors.White : colors.Gr03} fw={`500`} fs={`14px`} lh={`142%`}>{`${item.text}`}</TextView>
            </TextContentT>
          ))}
        </BackgroundContent>

        <Divider height={`10`} color={colors.White} />

        <BackgroundContent jc={`flex-start`} padding={`0`} ai={`center`}>
          <TextContent ai={`center`} width={`100%`}>
            <ImageIcon100 style={{ marginRight: "6px" }} width={`11px`} height={`11px`}>
              <img src={GreyMoon} />
            </ImageIcon100>

            <TextView color={colors.Gr03} fw={`500`} fs={`12px`} lh={`142%`}>{`오후`}</TextView>
          </TextContent>
        </BackgroundContent>

        <Divider height={`10`} color={colors.White} />

        <BackgroundContent width={`100%`} wrap={`wrap`} jc={`flex-start`} padding={`0`} ai={`center`}>
          {MoonTime.map((item, idx) => (
            <TextContentT
              cursor="pointer"
              index={(idx + 1) % 4}
              key={`${idx}_moonTime`}
              onClick={() => SettingStartTime(item.value)}
              jc={`center`}
              width={`calc((100% - 28px) / 4)`}
              padding={`8px 0`}
              br={`7`}
              bgc={start.includes(item.value) ? colors.Bl07 : colors.White}
              border={`1px solid ${colors.Gr02}`}
            >
              <TextView color={start.includes(item.value) ? colors.White : colors.Gr03} fw={`500`} fs={`14px`} lh={`142%`}>{`${item.text}`}</TextView>
            </TextContentT>
          ))}
        </BackgroundContent>
      </BackgroundContent>
    </>
  );
}

export default SemosSchedule;

const BackgroundContent = styled.div`
  width: 100%;
  border: ${({ border }) => border && `${border}`};
  background-color: ${({ bgc }) => bgc && `${bgc}`};
  padding: ${({ padding }) => padding && `${padding}`};
  display: inline-flex;
  border-radius: ${({ br }) => br && `${br}px`};
  justify-content: ${({ jc }) => jc && `${jc}`};
  align-items: ${({ ai }) => ai && `${ai}`};
  flex-wrap: ${({ wrap }) => wrap && `${wrap}`};
  position: relative;
`;

const TextContent = styled.div`
  width: ${({ width }) => width && `${width}`};
  border: ${({ border }) => border && `${border}`};
  background-color: ${({ bgc }) => bgc && `${bgc}`};
  padding: ${({ padding }) => padding && `${padding}`};
  display: inline-flex;
  flex-wrap: ${({ wrap }) => wrap && `${wrap}`};
  border-radius: ${({ br }) => br && `${br}px`};
  justify-content: ${({ jc }) => jc && `${jc}`};
  align-items: ${({ ai }) => ai && `${ai}`};
  cursor: ${({ cursor }) => cursor && `${cursor}`};
  ${({ gmarket }) => gmarket && `font-family : "GmarketSans" !important;`}
`;

const TextContentT = styled.div`
  width: ${({ width }) => width && `${width}`};
  border: ${({ border }) => border && `${border}`};
  background-color: ${({ bgc }) => bgc && `${bgc}`};
  padding: ${({ padding }) => padding && `${padding}`};
  display: inline-flex;
  flex-wrap: ${({ wrap }) => wrap && `${wrap}`};
  border-radius: ${({ br }) => br && `${br}px`};
  justify-content: ${({ jc }) => jc && `${jc}`};
  align-items: ${({ ai }) => ai && `${ai}`};
  cursor: ${({ cursor }) => cursor && `${cursor}`};
  ${({ gmarket }) => gmarket && `font-family : "GmarketSans" !important;`}
  ${({ index }) => index !== 0 && `margin-right : 9px;`}
  margin-bottom : 9px;
`;

const TextView = styled.span`
  width: ${({ width }) => width && `${width}`};
  text-align: ${({ ta }) => ta && `${ta}`};
  color: ${({ color }) => color && `${color}`};
  font-weight: ${({ fw }) => fw && `${fw}`};
  font-size: ${({ fs }) => fs && `${fs}`};
  line-height: ${({ lh }) => lh && `${lh}`};
  display: inline-block;
  text-overflow: ${({ to }) => to && `${to}`};
  overflow: hidden;
  white-space: ${({ ws }) => ws && `${ws}`};
  ${({ gmarket }) => gmarket && `font-family : "GmarketSans" !important;`}
`;

const InputView = styled.input`
  width: ${({ width }) => width && `${width}`};
  border: ${({ border }) => border && `${border}`};
  text-align: ${({ ta }) => ta && `${ta}`};
  color: ${({ color }) => color && `${color}`};
  font-weight: ${({ fw }) => fw && `${fw}`};
  font-size: ${({ fs }) => fs && `${fs}`};
  line-height: ${({ lh }) => lh && `${lh}`};
  border-radius: ${({ br }) => br && `${br}px`};
  background-color: ${({ bgc }) => bgc && `${bgc}`};
  display: inline-block;
  overflow: hidden;
  padding: ${({ padding }) => padding && `${padding}`};
  ${({ gmarket }) => gmarket && `font-family : "GmarketSans" !important;`}
  ::placeholder {
    color: ${({ pc }) => pc && `${pc}`};
  }
  ::-ms-input-placeholder {
    color: ${({ pc }) => pc && `${pc}`};
  }
  cursor: text;
`;

const ImageIcon100 = styled.div`
  width: ${({ width }) => width && `${width}`};
  height: ${({ height }) => height && `${height}`};
  display: inline-block;

  img {
    width: ${({ width }) => width && `${width}`};
    display: inline-block;
  }
`;

const StartTimePick = styled(TimePicker)`
  width: 64px;
  margin-left: 9px;

  &::placeholder {
    color: ${colors.Gr03};
  }
`;

const Divider = styled.div`
  height: ${({ height }) => height && `${height}px`};
  background-color: ${({ color }) => color && `${color}`};
  width: 100%;
`;

const ColorLine = styled.div`
  height: ${({ height }) => height && `${height}px`};
  width: ${({ width }) => width && `${width}px`};
  background-color: ${({ bgc }) => bgc && `${bgc}`};
  display: inline-block;
`;
