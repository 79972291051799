import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import axios from "axios";

import StatusBar from "../components/semosActivityCalender/statusbar";
import SemosScheduleBox from "../components/semosActivityCalender/semos_schedule_box";
import Header from "../components/basic/Header";
import Footer from "../components/basic/footer";
import { GetOne } from "../tool/localstorage";
import { LoadingSpinner } from "../tool/loading";

import { W } from "../styles/globalStyles";

import PrevMonthIcon from "../icon/prevMonth.png";
import NextMonthIcon from "../icon/nextMonth.png";
import Plus from "../icon/noAppointment.png";

const DateBox = (W - (48 + 36)) / 7;

function SemosScheduleCalender() {
  const navigate = useNavigate();
  const today = {
    year: new Date().getFullYear(),
    month:
      new Date().getMonth() + 1 > 9
        ? String(new Date().getMonth() + 1)
        : `0${new Date().getMonth() + 1}`,
    date:
      new Date().getDate() > 9
        ? String(new Date().getDate())
        : `0${new Date().getDate()}`,
  };

  const [loading, setLoading] = useState(true);

  const [nowTime, setNowTime] = useState({
    year: today.year,
    month: today.month,
    date: today.date,
  });

  const [selectedDate, setSelectedDate] = useState({
    year: today.year,
    month: today.month,
    date: today.date,
  });

  const [lastDay, setLastDay] = useState(
    new Date(nowTime.year, Number(nowTime.month), 0).getDate()
  );

  const [lastMonth, setLastMonth] = useState(
    new Date(nowTime.year, Number(nowTime.month) - 1, 0).getDate()
  );

  const [schedule, setSchedule] = useState();

  const [rest, setRest] = useState([]);
  const [offDay, setOffDay] = useState([]);
  const [onYoil, setOnYoil] = useState([]);
  const [holiday, setHoliday] = useState([]);

  const [onCount, setOnCount] = useState([]);
  const [holidayCount, setHolidayCount] = useState([]);

  useEffect(() => {
    var token = GetOne("token");

    const frm = new FormData();

    frm.append("mode", "schedule_list");
    frm.append("token", token);

    axios
      .post("https://ai.semos.kr/semos_partner/schedule/data", frm, {
        headers: { "Content-Type": "multipart/form-data" },
      })
      .then((data) => {
        var Value = data.data;
        var Status = Value.status;
        var StatusMessage = Value.status_message;
        var ScheduleList = Value.schedule_list;
        var ScheduleOff = Value.off_list;
        var ScheduleYoil = Value.yoil_list;
        var ScheduleHoliday = Value.holiday_list;
        var ScheduleOnCount = Value.on_count;
        var ScheduleHolidayCount = Value.holiday_count;

        if (Status == "success") {
          setSchedule(ScheduleList);
          setOffDay(ScheduleOff);
          setOnYoil(ScheduleYoil);
          setHoliday(ScheduleHoliday);
          setOnCount(ScheduleOnCount);
          setHolidayCount(ScheduleHolidayCount);

          var last = new Date(nowTime.year, Number(nowTime.month), 0).getDate();
          var arr = [];
          setLastDay(last);
          setLastMonth(
            new Date(nowTime.year, Number(nowTime.month) - 1, 0).getDate()
          );
          for (let i = 1; i < last + 1; i++) {
            let DateStr = i;

            if (i < 10) {
              DateStr = `0${i}`;
            }

            if (ScheduleOff[`${nowTime.year}.${nowTime.month}.${DateStr}`]) {
              arr[`${nowTime.year}.${nowTime.month}.${DateStr}`] =
                ScheduleOff[`${nowTime.year}.${nowTime.month}.${DateStr}`];
            } else {
              arr[`${nowTime.year}.${nowTime.month}.${DateStr}`] = {
                status: false,
                start_time: "00:00",
                end_time: "23:30",
              };
            }
          }
          setRest(arr);
          setLoading(false);
        } else {
          setLoading(false);
          window.alert(StatusMessage);
        }
      });
  }, []);

  var Daylist = [];

  for (let i = 0; i < lastDay; i++) {
    Daylist.push(i + 1);
  }

  const PrevMonth = () => {
    if (nowTime.month === "01") {
      var PYear = nowTime.year - 1;
      var PMonth = "12";
      setNowTime({
        year: PYear,
        month: PMonth,
        date: "01",
      });
      setSelectedDate({
        year: PYear,
        month: PMonth,
        date: "01",
      });
    } else {
      var num = Number(nowTime.month) - 1;
      var PYear = nowTime.year;
      var PMonth = num > 9 ? String(num) : `0${num}`;
      setNowTime({
        year: nowTime.year,
        month: PMonth,
        date: "01",
      });
      setSelectedDate({
        year: nowTime.year,
        month: PMonth,
        date: "01",
      });
    }

    var last = new Date(PYear, Number(PMonth), 0).getDate();
    var arr = [];
    setLastDay(last);
    setLastMonth(new Date(PYear, Number(PMonth) - 1, 0).getDate());
    for (let i = 1; i < last + 1; i++) {
      let DateStr = i;

      if (i < 10) {
        DateStr = `0${i}`;
      }

      if (offDay[`${PYear}.${PMonth}.${DateStr}`]) {
        arr[`${PYear}.${PMonth}.${DateStr}`] =
          offDay[`${PYear}.${PMonth}.${DateStr}`];
      } else {
        arr[`${PYear}.${PMonth}.${DateStr}`] = {
          status: false,
          start_time: "00:00",
          end_time: "23:30",
        };
      }
    }
    setRest(arr);
  };

  const NextMonth = () => {
    if (nowTime.month === "12") {
      var NYear = nowTime.year + 1;
      var NMonth = "01";
      setNowTime({
        year: NYear,
        month: NMonth,
        date: "01",
      });
      setSelectedDate({
        year: NYear,
        month: NMonth,
        date: "01",
      });
    } else {
      var num = Number(nowTime.month) + 1;
      var NYear = nowTime.year;
      var NMonth = num > 9 ? String(num) : `0${num}`;
      setNowTime({
        year: nowTime.year,
        month: NMonth,
        date: "01",
      });
      setSelectedDate({
        year: nowTime.year,
        month: NMonth,
        date: "01",
      });
    }

    var last = new Date(NYear, Number(NMonth), 0).getDate();
    var arr = [];
    setLastDay(last);
    setLastMonth(new Date(NYear, Number(NMonth) - 1, 0).getDate());
    for (let i = 1; i < last + 1; i++) {
      let DateStr = i;

      if (i < 10) {
        DateStr = `0${i}`;
      }

      if (offDay[`${NYear}.${NMonth}.${DateStr}`]) {
        arr[`${NYear}.${NMonth}.${DateStr}`] =
          offDay[`${NYear}.${NMonth}.${DateStr}`];
      } else {
        arr[`${NYear}.${NMonth}.${DateStr}`] = {
          status: false,
          start_time: "00:00",
          end_time: "23:30",
        };
      }
    }
    setRest(arr);
  };

  const DateSelector = (y, m, d) => {
    setSelectedDate({
      year: y,
      month: m,
      date: d,
    });
  };

  const DayMaker = (date) => {
    var YoilCount = [0, 2, 3, 4, 5, 6, 7];
    var YoilText = ["일", "월", "화", "수", "목", "금", "토"];
    var YoilTextEng = ["sun", "mon", "tue", "wed", "thu", "fri", "sat"];

    var Year = nowTime.year;
    var Month = nowTime.month;
    var DateText = date > 9 ? `${date}` : `0${date}`;

    var DayCheckContent = [];
    var Yoil = new Date(Year, Month - 1, date).getDay();
    var Onday = false;
    var Offday = false;

    var YoilCheck = YoilCount[Yoil];
    var YoilCheck2 = YoilText[Yoil];
    var YoilCheck3 = YoilTextEng[Yoil];

    var OnCount = onCount[YoilCheck3];
    var HolidayCount = holidayCount[YoilCheck3] != 0;

    const holidayCheck = holiday.includes(`${Year}${Month}${DateText}`);

    if (onYoil.includes(YoilCheck2)) {
      Onday = true;
    }

    if (holidayCheck) {
      if (HolidayCount) {
        Onday = true;
      } else {
        Onday = false;
      }
    }

    var OffDayCheck = offDay[`${Year}.${Month}.${DateText}`];
    if (OffDayCheck) {
      if (OffDayCheck.status == true) {
        Offday = true;
        Onday = false;
      }
    }

    var YoilColor =
      YoilCheck !== 0 && YoilCheck !== 7 && !holidayCheck
        ? "#818792"
        : YoilCheck === 0 || holidayCheck
        ? "#FF9187"
        : "#0E6DFF";

    var YoilMargin = YoilCheck !== 0 ? true : false;

    var SelectedDate =
      Year === selectedDate.year &&
      Month === selectedDate.month &&
      DateText === selectedDate.date;

    var BeforeDate =
      Number(Year) == Number(nowTime.year) &&
      Number(Month) - 1 == Number(nowTime.month) &&
      Number(date) < Number(nowTime.date);

    var oneul =
      Number(Year) == Number(today.year) &&
      Number(Month) == Number(today.month) &&
      Number(date == Number(today.date));

    if (date === 1) {
      // empty day
      var empty = lastMonth - YoilCheck + 2;
      for (var i = 0; i < YoilCheck - 1; i++) {
        DayCheckContent.push(
          <Box mr={i !== 0 ? true : ""}>
            <DayContentNotTouch>
              <DayContentText color={"#DCDCDC"}>{empty + i}</DayContentText>
            </DayContentNotTouch>
            <VacationText></VacationText>
          </Box>
        );
      }

      if (BeforeDate) {
        DayCheckContent.push(
          <Box mr={YoilMargin}>
            <DayContentClose>
              <DayContentText color={"#DCDCDC"}>{date}</DayContentText>
            </DayContentClose>
            <VacationText></VacationText>
          </Box>
        );
      } else {
        DayCheckContent.push(
          <Box mr={YoilMargin}>
            <DayContent
              oneul={oneul}
              active={SelectedDate}
              onClick={() =>
                DateSelector(nowTime.year, nowTime.month, DateText)
              }
            >
              <DayContentText
                oneul={oneul}
                active={SelectedDate}
                color={YoilColor}
              >
                {oneul && SelectedDate ? "오늘" : date}
              </DayContentText>

              {Onday && <BlueDot />}
            </DayContent>
            <VacationText>{Offday && "휴무"}</VacationText>
          </Box>
        );
      }
    } else if (date === lastDay) {
      if (BeforeDate) {
        DayCheckContent.push(
          <Box mr={YoilMargin}>
            <DayContentClose>
              <DayContentText color={"#DCDCDC"}>{date}</DayContentText>
            </DayContentClose>
            <VacationText></VacationText>
          </Box>
        );
      } else {
        DayCheckContent.push(
          <Box mr={YoilMargin}>
            <DayContent
              oneul={oneul}
              active={SelectedDate}
              onClick={() =>
                DateSelector(nowTime.year, nowTime.month, DateText)
              }
            >
              <DayContentText
                oneul={oneul}
                active={SelectedDate}
                color={YoilColor}
              >
                {oneul && SelectedDate ? "오늘" : date}
              </DayContentText>

              {Onday && <BlueDot />}
            </DayContent>
            <VacationText>{Offday && "휴무"}</VacationText>
          </Box>
        );
      }
      for (var i = 0; i < 7 - YoilCheck; i++) {
        DayCheckContent.push(
          <Box mr={true}>
            <DayContentNotTouch>
              <DayContentText color="#DCDCDC">{i + 1}</DayContentText>
            </DayContentNotTouch>
            <VacationText></VacationText>
          </Box>
        );
      }
    } else {
      if (BeforeDate) {
        DayCheckContent.push(
          <Box mr={YoilMargin}>
            <DayContentClose>
              <DayContentText color={"#DCDCDC"}>{date}</DayContentText>
            </DayContentClose>
            <VacationText></VacationText>
          </Box>
        );
      } else {
        DayCheckContent.push(
          <Box mr={YoilMargin}>
            <DayContent
              oneul={oneul}
              active={SelectedDate}
              onClick={() =>
                DateSelector(nowTime.year, nowTime.month, DateText)
              }
            >
              <DayContentText
                oneul={oneul}
                active={SelectedDate}
                color={YoilColor}
              >
                {oneul && SelectedDate ? "오늘" : date}
              </DayContentText>

              {Onday && <BlueDot />}
            </DayContent>
            <VacationText>{Offday && "휴무"}</VacationText>
          </Box>
        );
      }
    }

    return DayCheckContent;
  };

  const ScheduleClose = (value, number, index) => {
    setLoading(true);

    var token = GetOne("token");

    const frm = new FormData();

    frm.append("mode", "schedule_close");
    frm.append("token", token);
    frm.append("number", number);
    frm.append("status", value);

    axios
      .post("https://ai.semos.kr/semos_partner/schedule/data", frm, {
        headers: { "Content-Type": "multipart/form-data" },
      })
      .then((data) => {
        var Value = data.data;
        var Status = Value.status;
        var StatusMessage = Value.status_message;
        var ScheduleStatus = Value.schedule_status;
        var ScheduleOff = Value.off_list;
        var ScheduleYoil = Value.yoil_list;
        var ScheduleHoliday = Value.holiday_list;
        var ScheduleOnCount = Value.on_count;
        var ScheduleHolidayCount = Value.holiday_count;

        if (Status == "success") {
          let ScheduleData = [...schedule];
          let ScheduleValue = ScheduleData[index];
          ScheduleValue["status"] = ScheduleStatus;
          ScheduleData[index] = ScheduleValue;

          setSchedule(ScheduleData);
          setOffDay(ScheduleOff);
          setOnYoil(ScheduleYoil);
          setHoliday(ScheduleHoliday);
          setOnCount(ScheduleOnCount);
          setHolidayCount(ScheduleHolidayCount);
          setLoading(false);
        } else {
          setLoading(false);
          window.alert(StatusMessage);
        }
      });
  };

  const CloseDay = (value, status) => {
    var token = GetOne("token");
    setLoading(true);

    if (status == "close") {
      const frm = new FormData();

      frm.append("mode", "day_close");
      frm.append("token", token);
      frm.append("day", value);

      axios
        .post("https://ai.semos.kr/semos_partner/schedule/data", frm, {
          headers: { "Content-Type": "multipart/form-data" },
        })
        .then((data) => {
          var Value = data.data;
          var Status = Value.status;
          var StatusMessage = Value.status_message;

          if (Status == "success") {
            setOffDay((prev) => ({
              ...prev,
              [value]: {
                status: true,
                start_time: "00:00",
                end_time: "23:30",
              },
            }));
            setRest((prev) => ({
              ...prev,
              [value]: {
                status: true,
                start_time: "00:00",
                end_time: "23:30",
              },
            }));
            setLoading(false);
          } else {
            setLoading(false);
            window.alert(StatusMessage);
          }
        });
    } else if (status == "open") {
      const frm = new FormData();

      frm.append("mode", "day_open");
      frm.append("token", token);
      frm.append("day", value);

      axios
        .post("https://ai.semos.kr/semos_partner/schedule/data", frm, {
          headers: { "Content-Type": "multipart/form-data" },
        })
        .then((data) => {
          var Value = data.data;
          var Status = Value.status;
          var StatusMessage = Value.status_message;

          if (Status == "success") {
            setOffDay((prev) => ({
              ...prev,
              [value]: {
                status: false,
                start_time: "00:00",
                end_time: "23:30",
              },
            }));
            setRest((prev) => ({
              ...prev,
              [value]: {
                status: false,
                start_time: "00:00",
                end_time: "23:30",
              },
            }));
            setLoading(false);
          } else {
            setLoading(false);
            window.alert(StatusMessage);
          }
        });
    }
  };

  const timeChange = (type, time, ymd) => {
    setLoading(true);
    var token = GetOne("token");

    const frm = new FormData();

    frm.append("mode", "off_time");
    frm.append("token", token);
    frm.append("day", ymd);
    frm.append("type", type);
    frm.append("time", time);

    axios
      .post("https://ai.semos.kr/semos_partner/schedule/data", frm, {
        headers: { "Content-Type": "multipart/form-data" },
      })
      .then((data) => {
        var Value = data.data;
        var Status = Value.status;
        var StatusMessage = Value.status_message;

        if (Status == "success") {
          var Ogcontent = rest;
          var Ogcontent2 = offDay;

          var OgTime = Ogcontent[ymd];
          var OgTime2 = Ogcontent2[ymd];
          OgTime2[type] = time;
          OgTime[type] = time;

          setOffDay((prev) => ({ ...prev, [ymd]: OgTime }));
          setRest((prev) => ({ ...prev, [ymd]: OgTime2 }));
          setLoading(false);
        } else {
          setLoading(false);
          window.alert(StatusMessage);
        }
      });
  };

  return (
    <Container>
      <Header title="세모스 일정 관리" back={true} />

      <CalnederContent>
        <Styler pb={14} pt={12} pl={24} pr={24}>
          <CalendarMonthSelector>
            <MonthSelector onClick={PrevMonth}>
              <MonthSelectorIcon src={PrevMonthIcon} />
            </MonthSelector>

            <MonthSelectorYM>{`${nowTime.year}년 ${nowTime.month}월`}</MonthSelectorYM>

            <MonthSelector onClick={NextMonth}>
              <MonthSelectorIcon src={NextMonthIcon} />
            </MonthSelector>
          </CalendarMonthSelector>

          <YoilContainer>
            <YoilContent>
              <YoilContentText color={"#FF9187"}>일</YoilContentText>
            </YoilContent>

            <YoilContent>
              <YoilContentText color={"#ACAFB5"}>월</YoilContentText>
            </YoilContent>

            <YoilContent>
              <YoilContentText color={"#ACAFB5"}>화</YoilContentText>
            </YoilContent>

            <YoilContent>
              <YoilContentText color={"#ACAFB5"}>수</YoilContentText>
            </YoilContent>

            <YoilContent>
              <YoilContentText color={"#ACAFB5"}>목</YoilContentText>
            </YoilContent>

            <YoilContent>
              <YoilContentText color={"#ACAFB5"}>금</YoilContentText>
            </YoilContent>

            <YoilContent>
              <YoilContentText color={"#0E6DFF"}>토</YoilContentText>
            </YoilContent>
          </YoilContainer>

          <DayContainer>
            {Daylist ? Daylist.map((item, index) => DayMaker(item)) : ""}
          </DayContainer>
        </Styler>
        <StatusBar
          data={rest}
          index={Number(selectedDate.date)}
          selectDay={`${selectedDate.year}.${selectedDate.month}.${selectedDate.date}`}
          close={CloseDay}
          timeChange={timeChange}
          setData={setRest}
        />
      </CalnederContent>
      <Styler pl={24} pr={24}>
        <NoAppointment onClick={() => navigate("/semos_schedule_add")}>
          <PlusIcon src={Plus} />
          <NoAppointmentText>세모스 일정 등록하기</NoAppointmentText>
        </NoAppointment>
      </Styler>

      {schedule &&
        schedule.map((item, index) => (
          <SemosScheduleBox
            index={index}
            close={ScheduleClose}
            data={item}
            key={`${index}_schedule`}
          />
        ))}

      <div style={{ height: "99px" }} />
      {/*<Footer />*/}

      <LoadingSpinner
        loading={loading}
        title={`정보를 확인하고 있습니다.`}
        content={`잠시만 기다려 주세요!`}
      />
    </Container>
  );
}

export default SemosScheduleCalender;

const Container = styled.section`
  height: calc(var(--vh, 1vh) * 100);
  overflow-x: hidden;
`;

const CalnederContent = styled.div`
  border-bottom: 4px solid #f5f5f5;
  margin-bottom: 16px;
`;

const Styler = styled.div`
  position: relative;
  display: ${({ flex }) => (flex ? "flex" : "")};
  flex-direction: ${({ direction }) => direction};
  justify-content: ${({ justify }) => justify};
  padding-top: ${({ pt }) => pt}px;
  padding-bottom: ${({ pb }) => pb}px;
  padding-left: ${({ pl }) => pl}px;
  padding-right: ${({ pr }) => pr}px;
`;

const CalendarMonthSelector = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  /* align-items: center; */
  margin-bottom: 8px;
`;

const MonthSelector = styled.div`
  cursor: pointer;
  width: 24px;
  height: 24px;
`;

const MonthSelectorIcon = styled.img`
  width: 24px;
  height: 24px;
`;

const MonthSelectorYM = styled.div`
  font-weight: 600;
  font-size: 16px;
  line-height: 142%;
  display: flex;
  align-items: center;
  letter-spacing: -0.02em;
  color: #1a1c1f;
`;

const YoilContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: ${W - 48}px;
`;

const YoilContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${DateBox}px;
  height: ${DateBox}px;
`;

const YoilContentText = styled.div`
  color: ${(props) => props.color};
  font-weight: 500;
  font-size: 12px;
  line-height: 142%;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: -0.02em;
  margin-bottom: 2px;
`;

const DayContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  width: ${W - 48}px;
`;

const DayContentNotTouch = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${DateBox}px;
  height: ${DateBox}px;
  margin-bottom: 2px;
  opacity: 0.9;
  /* ${(props) => (props.mr ? `margin-left : 6px;` : "")}; */
`;

const DayContentClose = styled.div`
  align-items: center;
  justify-content: center;
  width: ${DateBox}px;
  height: ${DateBox}px;
  margin-bottom: 2px;
  /* ${(props) => (props.mr ? `margin-left : 6px;` : "")}; */
  border: 1px solid #dcdcdc;
  background: #f5f5f5;
  position: relative;
`;

const DayContentText = styled.div`
  color: ${(props) => props.color};
  ${(props) =>
    props.active
      ? `
  color : #ffffff;
  `
      : ""}
  font-weight: 500;
  font-size: ${({ oneul, active }) => (oneul && active ? "11.5px" : "14px")};
  line-height: 142%;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: -0.02em;
`;

const DayContent = styled.div`
  cursor: pointer;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${DateBox}px;
  height: ${DateBox}px;
  margin-bottom: 2px;
  /* ${(props) => (props.mr ? `margin-left : 6px;` : "")}; */
  ${(props) =>
    props.active
      ? `
  border-radius : 50%;
  background-color : #0E6DFF;
 
  `
      : ""}
  ${({ oneul }) =>
    oneul &&
    `border: 1px dashed #0E6DFF; 
  border-radius: 50%;
 `}
`;

const BlueDot = styled.div`
  background-color: #0e6dff;
  width: 4px;
  height: 4px;
  border-radius: 50%;
  position: absolute;
  top: 0;
  right: 0;
`;

const NoAppointment = styled.div`
  cursor: pointer;
  padding: 7px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #66a2ff;
  border-radius: 8px;
`;

const AddScheduleButton = styled.div`
  position: absolute;
  width: ${W - 48}px;
  bottom: 24px;
  padding: 7px 0;
  margin: 0 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #66a2ff;
  border-radius: 8px;
  background-color: #ffffff;
`;

const PlusIcon = styled.img`
  width: 24px;
  height: 24px;
  margin-right: 10px;
`;

const NoAppointmentText = styled.div`
  font-size: 14px;
  font-weight: 500;
  margin-top: 2px;
  /* line-height: 142%; */
  color: #66a2ff;
`;

const EmptySchedule = styled.div`
  width: 100%;
  background-color: #e5f0ff;
  border-radius: 15px;
  color: #3383ff;
  font-weight: 500;
  align-items: center;
  justify-content: center;
  line-height: 142%;
  font-size: 14px;
  padding: 24px;
  display: inline-block;

  span {
    text-align: center;
    width: 100%;
    display: inline-block;
  }
`;

const VacationText = styled.div`
  height: 14px;
  color: #ff9187;
  font-size: 10px;
  font-weight: 600;
`;

const Box = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  ${(props) => (props.mr ? `margin-left : 6px;` : "")};
`;
