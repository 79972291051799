import React, { useState, useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import styled from "styled-components";

import Header from "../components/basic/Header";
import ImageModal from "../components/elements/ImageModal";
import SkipModal from "../components/partnerTip/SkipModal";
import { GetOne } from "../tool/localstorage";

// style
import colors from "../styles/colors";

// icon
import SemosBlueLogo from "../icon/semosBlueLogo.png";
import Shop from "../icon/shop.png";
import TipPreview01 from "../icon/tip_preview_01.png";
import TipPreview02 from "../icon/tip_preview_02.png";
import TipPreview03 from "../icon/tip_preview_03.png";
import Consult from "../icon/login_splash_04.png";
import { ReactComponent as Arrow } from "../resource/icon/arrow.svg";

const TIP_STEP = [
  {
    step: 1,
    title: "정산 정보 입력하기",
    boldContent: "신분증, 통장사본, 사업자등록증 사본을 준비해 주세요!",
    content:
      "파트너님의 편리한 소득 정산과 문서처리를 위해 \n정보들이 필요해요. \n해당 정보들은 철저한 보안으로 관리되고 있어요.",
    img: TipPreview01,
  },
  {
    step: 2,
    title: "파트너 정보 등록하기",
    boldContent: "이력 및 경력사항을 알려주세요",
    content: "파트너님의 자격증과 같은 간단한 이력과 \n경력사항을 알려주세요",
    img: TipPreview02,
  },
  {
    step: 3,
    title: "세모스 일정 등록하기",
    boldContent: "일정을 등록해 주세요",
    content: "일정은 액티비티 진행이 가능한 근무시간을 설정하는 것을 뜻해요.",
    img: TipPreview03,
  },
];

const CONSULT = [
  {
    step: 1,
    title: "빠른 입점심사",
    content: "우선 입점 심사로 시간을 아낄 수 있어요",
  },
  {
    step: 2,
    title: "액티비티 대리 등록",
    content: "최단 기간 내 액티비티를 등록해드려요",
  },
  {
    step: 3,
    title: "간단한 신청",
    content: "5분만 투자하시면 바로 신청이 가능해요",
  },
  {
    step: 4,
    title: "애프터 케어",
    content: "수정사항이 있어도 바로 반영해드려요",
  },
];

const NOTION_URL =
  "https://www.notion.so/semos/073abf5eadb84b4cbdb1e4ff78b8f09e?pvs=4";

const PartnerTip = () => {
  const navigate = useNavigate();
  const targetRef = useRef(null);

  const [previewModal, setPreviewModal] = useState({ isOpen: false, img: "" });
  const [skipModal, setSkipModal] = useState(false);
  const [target, setTarget] = useState("");

  useEffect(() => {
    if (targetRef.current)
      targetRef.current.scrollIntoView({ behavior: "smooth" });
    return () => {
      setTarget(""); // 스크롤 후 동일한 section 재클릭시 이동
    };
  }, [target]);

  const handlePreviewModal = (img) => setPreviewModal({ isOpen: true, img });

  const handleNext = (res) => {
    if (res === "4") {
      setSkipModal(true);
    } else if (res === "0" || res === "1" || res === "2") {
      navigate("/home");
    } else {
      //navigate("/partner_register");
      //navigate("/partner_register_new/0", {replace: true});
      window.alert('미등록된 파트너입니다.\n등록 상담 신청을 통해 파트너 등록을 완료해주세요!')
      window.location.href = 'https://forms.gle/mpMXYxmcxyXetueB8'
    }
  };

  const verifyStatus = () => {
    const token = GetOne("token");
    const frm = new FormData();

    frm.append("mode", "coordinator");
    frm.append("token", token);

    axios
      .post("https://ai.semos.kr/semos_partner/auth/profile", frm, {
        headers: { "Content-Type": "multipart/form-data" },
      })
      .then((res) => {
        const data = res.data;
        if (data.status === "warning") {
          window.alert(data.status_message);
        } else {
          handleNext(data.status);
        }
      });
  };

  return (
    <StPartnerTip>
      <Header title="세모스 파트너님을 위한 Tip" jc="flex-end">
        <StSkipBtn
          onClick={() => {
            verifyStatus();
          }}
        >
          건너뛰기
        </StSkipBtn>
      </Header>

      <StTipHeader>
        <StTipTitle>
          {"이것만 지켜도 \n입점 심사 100% 통과! \n입점 가이드"}
        </StTipTitle>
        <StHeaderImgContainer>
          <StLogoImg src={SemosBlueLogo} alt="semos logo" />
          <StShopImg src={Shop} alt="semos shop" />
        </StHeaderImgContainer>
      </StTipHeader>

      <StTipBody>
        {/* 입점 가이드 단계 안내 */}
        {TIP_STEP.map((val, idx) => {
          const { step, title, boldContent, content, img } = val;
          return (
            <StStep
              key={`${title}-${step}`}
              ref={target === step ? targetRef : null}
              isTarget={target === step}
            >
              <StStepUl>
                {TIP_STEP.map((item) => (
                  <StStepLi
                    key={item.step}
                    isSelected={item.step === idx + 1}
                    onClick={() => setTarget(item.step)}
                  >
                    {item.step === idx + 1
                      ? `${item.step}. ${item.title}`
                      : item.step}
                  </StStepLi>
                ))}
              </StStepUl>
              <StStepPreview onClick={() => handlePreviewModal(img)}>
                <img src={img} alt={title} />
              </StStepPreview>
              <StStepTextContainer>
                <StStepBoldText>{boldContent}</StStepBoldText>
                <br />
                <StStepText>{content}</StStepText>
              </StStepTextContainer>
            </StStep>
          );
        })}

        <Divider />

        {/* 전담 코디 안내 */}
        <StStep>
          <StConsultTitle>
            오직 수업만 집중하고 싶다면? <br />
            전담코디에게 맡겨보세요
          </StConsultTitle>
          <StConsultImg src={Consult} alt="semos consulting" />
          <StConsultUl>
            {CONSULT.map((val) => {
              const { step, title, content } = val;
              return (
                <li key={`${title}-${step}`}>
                  <StConsultContent fw={600}>
                    {`혜택 ${step}`}
                    <br />
                    {title}
                  </StConsultContent>
                  <br />
                  <StConsultContent fw={500}>{content}</StConsultContent>
                </li>
              );
            })}
          </StConsultUl>
          <StConsultBtn
            onClick={() => {
              window.open(NOTION_URL, "_blank");
            }}
          >
            <span>전담코디 더 알아보기</span>
            <Arrow fill={colors.Bl07} />
          </StConsultBtn>
        </StStep>

        <Divider />

        {/* 주의 사항 */}
        <StStep>
          <StCaution fw={600}>주의사항</StCaution>
          <StCaution fw={500}>
            • 욕설/비방/음란성 글과 사진은 입점 심사 제한이 있어요.
            <br />
            • 업로드 후 수정이 필요하다면 세모스 파트너 카카오 채널로
            <br />
            &nbsp;&nbsp;&nbsp;문의주세요!
          </StCaution>
        </StStep>
      </StTipBody>

      <StFooter>
        <NextButton
          onClick={() => {
            verifyStatus();
          }}
        >
          <span>입점하러가기</span>
        </NextButton>
      </StFooter>

      {previewModal.isOpen && (
        <ImageModal
          setModal={() =>
            setPreviewModal({
              ...previewModal,
              isOpen: false,
            })
          }
          modal={previewModal}
        />
      )}

      {skipModal ? (
        <SkipModal modal={skipModal} setModal={() => setSkipModal(false)} />
      ) : (
        <></>
      )}
    </StPartnerTip>
  );
};

export default PartnerTip;

const Divider = styled.div`
  width: 100%;
  height: 2px;
  margin-top: 24px;
  background-color: ${colors.Bl02};
`;

const StPartnerTip = styled.div`
  height: 100vh;
  overflow: auto;
`;

const StSkipBtn = styled.button`
  color: ${colors.Gr04};
  line-height: 22px;
  font-size: 14px;
  font-weight: 600;
`;

const StTipHeader = styled.div`
  background-color: ${colors.Bl02};
  padding: 36px 0 20px 26px;
`;

const StTipTitle = styled.h2`
  white-space: pre-wrap;
  color: ${colors.Bl06};
  line-height: 32px;
  font-size: 24px;
  font-weight: 700;
`;

const StHeaderImgContainer = styled.div`
  display: flex;
  align-items: end;
  justify-content: space-between;
  width: 100%;
`;

const StLogoImg = styled.img`
  opacity: 0.1;
  width: 34px;
  height: 34px;
`;

const StShopImg = styled.img`
  width: 238px;
  height: auto;
`;

const StTipBody = styled.div`
  padding: 0px 24px 32px 24px;
  background-color: ${colors.Bl01};
`;

const StStep = styled.div`
  padding-top: 56px;
  display: flex;
  flex-direction: column;
`;

const StStepUl = styled.ul`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
  padding: 0 30px 24px 30px;
`;

const StStepLi = styled.li`
  flex: ${({ isSelected }) => (isSelected ? 1 : "initial")};
  width: ${({ isSelected }) => (isSelected ? "auto" : "44px")};
  padding: 12px 0;
  background-color: ${({ isSelected }) =>
    isSelected ? colors.Bl07 : colors.Bl02};
  border-radius: 8px;
  color: ${({ isSelected }) => (isSelected ? colors.White : colors.Bl07)};
  text-align: center;
  line-height: 22px;
  font-size: 14px;
  font-weight: 600;
  cursor: pointer;
`;

const StStepPreview = styled.div`
  display: flex;
  justify-content: center;
  padding: 0 28px;

  img {
    width: 100%;
    border-radius: 16px;
    cursor: pointer;
  }
`;

const StStepTextContainer = styled.div`
  padding-top: 24px;
`;

const StStepBoldText = styled.span`
  color: ${colors.Gr06};
  text-align: center;
  line-height: 24px;
  letter-spacing: -0.02em;
  font-size: 16px;
  font-weight: 600;
`;

const StStepText = styled.p`
  color: ${colors.Gr06};
  white-space: pre-wrap;
  line-height: 24px;
  letter-spacing: -0.02em;
  font-size: 16px;
  font-weight: 500;
`;

const StConsultTitle = styled.h3`
  color: ${colors.Gr06};
  line-height: 26px;
  letter-spacing: -0.02em;
  font-size: 18px;
  font-weight: 700;
`;

const StConsultImg = styled.img`
  align-self: center;
  width: 298px;
  height: auto;
  padding: 24px 0;
`;

const StConsultUl = styled.ul`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 8px;

  li {
    padding: 12px;
    background-color: ${colors.Bl02};
    border-radius: 16px;
  }
`;

const StConsultContent = styled.span`
  color: ${colors.Gr05};
  line-height: 24px;
  letter-spacing: -0.02em;
  font-size: 16px;
  font-weight: ${({ fw }) => fw};
`;

const StConsultBtn = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  width: 100%;
  margin-top: 24px;
  padding: 14px 0;
  background-color: transparent;

  span {
    color: ${colors.Bl07};
    line-height: 24px;
    letter-spacing: -0.02em;
    font-size: 16px;
    font-weight: 600;
  }
`;

const StCaution = styled.span`
  color: ${colors.Gr04};
  line-height: 22px;
  letter-spacing: -0.02em;
  font-size: 14px;
  font-weight: ${({ fw }) => fw};
`;

const StFooter = styled.div`
  padding: 16px 24px;
`;

const NextButton = styled.button`
  width: 100%;
  padding: 14px 0;
  background-color: ${colors.Bl07};
  border-radius: 8px;

  span {
    color: ${colors.White};
    line-height: 24px;
    letter-spacing: -0.02em;
    font-size: 16px;
    font-weight: 600;
  }
`;
