import React, { useEffect, useState, useRef } from "react";
import styled from "styled-components";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";

//tool
import { GetOne } from "../tool/localstorage";
import { IPad, Android } from "../tool/device_check";

//style
import { Wrapper, Text, Input, AbsoluteWrapper } from "../components/layouts/layoutStorage";
import colors from "../styles/colors";

//component
import { Loading } from "../tool/Loading";
import FacilityHeader from "../components/facilityRegister/FacilityHeader";
import ManageModal from "../components/facilityCrew/ManageModal";
import PresentModal from "../components/facilityCrew/PresentModal";
import CrewTicketModal from "../components/facilityCrew/CrewTicketManage";

//icon
import { ReactComponent as Copy } from "../resource/icon/Copy.svg";
import plusBlue from "../icon/plusBlue.png";

// Default Data
const MAX_INTRO_LENGTH = 300;

const CrewDetail = () => {

    const navigate = useNavigate();

    const params = useParams();

    const memoRef = useRef();

    const [loading, setLoading] = useState(false);
    const [copyPop, setCopyPop] = useState(false);
    const [focus, setFocus] = useState(false);
    const [manage, setManage] = useState(false);
    const [manageT, setManageT] = useState(false);
    const [manageP, setManageP] = useState(false);
    const [changeTitle, setChangeTitle] = useState();
    const [changeTime, setChangeTime] = useState();
    const [changeTicket, setChangeTicket] = useState();
    const [activityList, setActivityList] = useState();
    const [present, setPresent] = useState();
    
    const [timeChart, setTimeChart] = useState();
    const [data, setData] = useState({
        name : "",
        gender : "남",
        phone : "010",
        car_number : "",
        car_type : "",
        birth_y : "",
        birth_m : "01",
        birth_d : "01",
        confirm : "",
        semos_account : "",
        age : "",
        register_date : "",
        user_tier : "",
        memo : ""
    });

    const [crewTicket, setCrewTicket] = useState();
    const [crewReservation, setCrewReservation] = useState();
    const [crewTicketClose, setCrewTicketClose] = useState();
    const [pdfUrl, setPdfUrl] = useState();

    const [dataT, setDataT] = useState({});

    const W = window.document.documentElement.clientWidth;

    const CrewApplication = () => {
        setLoading(true);

        var token = GetOne("token");

        const frm = new FormData();

        frm.append("mode", "crew_form");
        frm.append("token", token);
        frm.append("crew_id", params.id);

        axios
        .post("https://ai.semos.kr/semos_partner/v3/activity/Crew", frm, {
            headers: { "Content-Type": "multipart/form-data" },
        })
        .then((data) => {
            var Value = data.data;
            var Status = Value.status;
            var StatusMessage = Value.status_message;
            var Url = Value.url;

            if (Status == "success") {
                setPdfUrl(Url);
                window.open(Url, "_blank")
                setLoading(false);
            } else {
                window.alert(StatusMessage);
                setLoading(false);
            }
        });
    }

    const GetData = () => {
        setLoading(true);

        var token = GetOne("token");

        const frm = new FormData();

        frm.append("mode", "crew_info_coach");
        frm.append("token", token);
        frm.append("crew_id", params.id);

        axios
        .post("https://ai.semos.kr/semos_partner/v3/activity/Crew", frm, {
            headers: { "Content-Type": "multipart/form-data" },
        })
        .then((data) => {
            var Value = data.data;
            var Status = Value.status;
            var StatusMessage = Value.status_message;
            var CrewInfo = Value.crew_info;
            var crewTicket = Value.crew_ticket;
            var crewReservation = Value.crew_reservation;
            var crewTicketClose = Value.crew_ticket_close;
            var timeChart = Value.time_chart;
            var ActivityList = Value.activity_list;

            if (Status == "success") {
                setData(CrewInfo);
                setCrewTicket(crewTicket);
                setCrewReservation(crewReservation);
                setCrewTicketClose(crewTicketClose);
                setTimeChart(timeChart);
                setActivityList(ActivityList);
                setLoading(false);
            } else {
                window.alert(StatusMessage);
                setLoading(false);
            }
        });
    }

    const MemoSave = () => {
        setLoading(true);

        var token = GetOne("token");

        const frm = new FormData();

        frm.append("mode", "crew_memo");
        frm.append("token", token);
        frm.append("crew_id", params.id);
        frm.append("memo", data.memo);

        axios
        .post("https://ai.semos.kr/semos_partner/v3/activity/Crew", frm, {
            headers: { "Content-Type": "multipart/form-data" },
        })
        .then((data) => {
            var Value = data.data;
            var Status = Value.status;
            var StatusMessage = Value.status_message;
            var Url = Value.url;

            if (Status == "success") {
                setLoading(false);
            } else {
                window.alert(StatusMessage);
                setLoading(false);
            }
        });
    }

    const CopyText = (text) => {
        if(text) {
            const AC = Android();

            if (AC) {
                window.ReactNativeWebView.postMessage(text);
            } else {
                navigator.clipboard.writeText(text);
            }

            setCopyPop(true);

            setTimeout(() => {
                setCopyPop(false);
            }, 2000);
        }
    }

    const SetMemo = (value) => {
        value = value.substr(0, MAX_INTRO_LENGTH);

        setData({ ...data, memo: value });
    }

    useEffect(() => {
        GetData()
    }, []);

    return (
        <Container id="description_container">

            <FacilityHeader
                status={true}
                title={'회원 정보'}
                available={false}
                statusHandler={() => console.log("신규 회원 등록")}
            />

            <Wrapper
                jc="space-between"
                ai="flex-end"
                width="100%"
                padding={`20px 24px 10px 24px`}
                margin={`0 0 20px 0`}
                bb={`1px solid ${colors.Gr02}`}
            >

                <Text 
                    ta="center" 
                    size="1.8rem" 
                    weight="600" 
                    lh="22px" 
                    ls="-0.28px" 
                    color={colors.Bl07}
                >
                    {`[${data?.user_tier}]`}
                </Text>

                <Text 
                    ta="center" 
                    size="1.6rem" 
                    weight="600" 
                    lh="22px" 
                    ls="-0.28px" 
                    color={colors.Gr03}
                >
                    {`등록일 : ${data?.register_date}`}
                </Text>

            </Wrapper>

            <Wrapper
                jc="flex-start"
                ai="center"
                fd={`column`}
                width="100%"
                padding={`0 24px 10px 24px`}
                margin={`0 0 20px 0`}
                gap={`15px`}
                bb={`1px solid ${colors.Gr02}`}
            >
                <Wrapper
                    width={`100%`}
                    jc={'space-between'}
                >
                    <Text 
                        ta="center" 
                        size="2rem" 
                        weight="600" 
                        lh="22px" 
                        ls="-0.28px" 
                        color={colors.Gr05}
                        width={`calc(100% - 3rem)`}
                    >
                        {`회원명 : ${data?.name} (${data?.gender})`}
                    </Text>

                    <Copy
                        width="2.5rem"
                        height="2.5rem"
                        fill={colors.Gr05}
                        style={{
                            cursor : "pointer"
                        }}
                        onClick={() => CopyText(`${data?.name} (${data?.gender})`)}
                    />
                </Wrapper>

                <Wrapper
                    width={`100%`}
                    jc={'space-between'}
                >
                    <Text 
                        ta="center" 
                        size="2rem" 
                        weight="600" 
                        lh="22px" 
                        ls="-0.28px" 
                        color={colors.Gr05}
                        width={`calc(100% - 3rem)`}
                    >
                        {`생년월일 : ${data?.birth_y}.${data?.birth_m}.${data?.birth_d} (${data?.age}세)`}
                    </Text>

                    <Copy
                        width="2.5rem"
                        height="2.5rem"
                        fill={colors.Gr05}
                        style={{
                            cursor : "pointer"
                        }}
                        onClick={() => CopyText(`${data?.birth_y}.${data?.birth_m}.${data?.birth_d} (${data?.age}세)`)}
                    />
                </Wrapper>

                <Wrapper
                    width={`100%`}
                    jc={'space-between'}
                >
                    <Text 
                        ta="center" 
                        size="2rem" 
                        weight="600" 
                        lh="22px" 
                        ls="-0.28px" 
                        color={colors.Gr05}
                        width={`calc(100% - 3rem)`}
                    >전화번호 :
                        <a 
                            style={{
                                textDecoration : "underline",
                                color : colors.Gr05,
                                marginLeft : 8
                            }}    
                            href={`tel:${data?.phone}`}
                        >
                            {`${data?.phone}`}
                        </a>
                    </Text>

                    <Copy
                        width="2.5rem"
                        height="2.5rem"
                        fill={colors.Gr05}
                        style={{
                            cursor : "pointer"
                        }}
                        onClick={() => CopyText(data?.phone)}
                    />
                </Wrapper>
                
                {/*<Wrapper
                    width={`100%`}
                    jc={'space-between'}
                >
                    <Text 
                        ta="center" 
                        size="2rem" 
                        weight="600" 
                        lh="22px" 
                        ls="-0.28px" 
                        color={colors.Gr05}
                        width={`calc(100% - 3rem)`}
                    >
                        {`차량번호 : ${data?.car_number}`}
                    </Text>

                    <Copy
                        width="2.5rem"
                        height="2.5rem"
                        fill={colors.Gr05}
                        style={{
                            cursor : "pointer"
                        }}
                        onClick={() => CopyText(data?.car_number)}
                    />
                </Wrapper>

                <Wrapper
                    width={`100%`}
                    jc={'space-between'}
                >
                    <Text 
                        ta="center" 
                        size="2rem" 
                        weight="600" 
                        lh="22px" 
                        ls="-0.28px" 
                        color={colors.Gr05}
                        width={`calc(100% - 3rem)`}
                    >
                        {`차종 : ${data?.car_type}`}
                    </Text>

                    <Copy
                        width="2.5rem"
                        height="2.5rem"
                        fill={colors.Gr05}
                        style={{
                            cursor : "pointer"
                        }}
                        onClick={() => CopyText(data?.car_type)}
                    />
                </Wrapper>*/}

                <Wrapper
                    width={`100%`}
                    jc={'space-between'}
                >
                    <Text 
                        ta="center" 
                        size="1.8rem" 
                        weight="600" 
                        lh="22px" 
                        ls="-0.28px" 
                        color={colors.Gr03}
                        width={`50%`}
                        jc={`flex-start`}
                    >
                        {`이용 동의 : ${data?.confirm}`}
                    </Text>

                    <Text 
                        ta="center" 
                        size="1.8rem" 
                        weight="600" 
                        lh="22px" 
                        ls="-0.28px" 
                        color={colors.Gr03}
                        width={`50%`}
                        jc={`flex-start`}
                    >
                        {`세모스 가입 : ${data?.semos_account}`}
                    </Text>
                </Wrapper>

                <Wrapper
                    width={`100%`}
                    fd={`column`}
                    jc={'flex-start'}
                    padding={`3px 5px`}
                    border={`1px solid ${colors.Gr04}`}
                    radius={`8px`}
                >
                    <Text 
                        ta="center" 
                        size="1.8rem" 
                        weight="600" 
                        lh="22px" 
                        ls="-0.28px" 
                        color={colors.Gr04}
                        width={`100%`}
                        margin={`0 0 5px 0`}
                    >
                        {`메모`}
                    </Text>

                    <TextareaContainer w={100} focus={focus.intro}>
                        <TextArea
                            maxLength={MAX_INTRO_LENGTH}
                            type="text"
                            placeholder={`회원에 대한 메모를 적어주세요! (${MAX_INTRO_LENGTH}자 이내)`}
                            value={data?.memo}
                            onChange={(e) => SetMemo(e.target.value)}
                            onFocus={() => setFocus({ ...focus, intro: true })}
                            onBlur={() => setFocus({ ...focus, intro: false })}
                        />
                        <IntroText>
                            <div>
                                <span style={{
                                    color : colors.Gr04
                                }}>{data?.memo.length}</span>/{MAX_INTRO_LENGTH}
                            </div>

                            <Wrapper
                                width={`auto`}
                                jc={'center'}
                                ai={`center`}
                                padding={`3px 5px`}
                                border={`1px solid ${colors.Bl07}`}
                                radius={`8px`}
                                bgc={colors.Bl07}
                                margin={`0 0 0 10px`}
                                onClick={() => MemoSave()}
                            >
                                <Text 
                                    ta="center" 
                                    size="1.6rem" 
                                    weight="600" 
                                    lh="22px" 
                                    ls="-0.28px" 
                                    color={colors.White}
                                >
                                    {`저장`}
                                </Text>
                            </Wrapper>

                        </IntroText>
                    </TextareaContainer>
                </Wrapper>

            </Wrapper>

            <Wrapper
                jc="flex-start"
                ai="center"
                width="100%"
                wrap={'wrap'}
                padding={`0 24px 10px 24px`}
                margin={`0 0 20px 0`}
                bb={`1px solid ${colors.Gr02}`}
            >
                <Wrapper
                    jc="space-between"
                    ai="center"
                    width="100%"
                    wrap={'wrap'}
                    margin={`0 0 10px 0`}
                >

                    <Text 
                        size="2rem" 
                        weight="600" 
                        lh="22px" 
                        ls="-0.28px" 
                        color={colors.Gr05}
                    >
                        {`예약 내역`}
                    </Text>

                    {/*<NewCrew
                        onClick={() => {
                            setManageT(true)
                            setDataT({
                                crew_id : params.id
                            })
                        }}
                    >
                        <img src={plusBlue} />
                    </NewCrew>*/}

                </Wrapper>

                <Wrapper
                    jc="flex-start"
                    ai="center"
                    fd="column"
                    width="100%"
                    gap="15px"
                >
                    {
                        crewTicket?.map((item, idx) => (
                            <Wrapper
                                key={`${idx}_ticket`}
                                jc="flex-start"
                                ai="center"
                                fd="column"
                                width="100%"
                                gap="5px"
                                bb={`1px solid ${colors.Gr01}`}
                                padding={`3px 0`}
                            >
                                <Text 
                                    size="1.6rem" 
                                    weight="600" 
                                    lh="22px" 
                                    ls="-0.28px" 
                                    color={colors.Gr05}
                                    width={`100%`}
                                >
                                    {`${item.title}`}
                                </Text>
        
                                <Text 
                                    size="1.6rem" 
                                    weight="00" 
                                    lh="22px" 
                                    ls="-0.28px" 
                                    color={colors.Gr04}
                                    width={`100%`}
                                >
                                    {`유효 기간 : ${item.start_date.split(' ')[0]} ~ ${item.finish_date.split(' ')[0]}`}
                                </Text>

                                {
                                    (item.activity_type == '정기 레슨') ?
                                        <Text 
                                            size="1.6rem" 
                                            weight="00" 
                                            lh="22px" 
                                            ls="-0.28px" 
                                            color={colors.Gr04}
                                            width={`100%`}
                                        >
                                            {`이용 시간대 : ${item.start_date.split(' ')[1]} ~ ${item.finish_date.split(' ')[1]}`}
                                        </Text>
                                    : 
                                    <></>
                                }

                                <Wrapper
                                    jc="space-between"
                                    ai="flex-end"
                                    width="100%"
                                    margin={`0 0 5px 0`}
                                >
                                    {
                                        (item.category == 1) ?
                                            <Text 
                                                size="1.6rem" 
                                                weight="00" 
                                                lh="22px" 
                                                ls="-0.28px" 
                                                color={colors.Gr04}
                                                width={`100%`}
                                            >
                                                {`사용 횟수 : ${item.use} / ${item.size}`}
                                            </Text>
                                        : 
                                            <Text 
                                                size="1.6rem" 
                                                weight="00" 
                                                lh="22px" 
                                                ls="-0.28px" 
                                                color={colors.Gr04}
                                                width={`100%`}
                                            >
                                                {`이용 기간 : ${item.count}개월`}
                                            </Text>
                                    }

                                    {
                                        (item.activity_type == '정기 레슨') ?
                                            <Wrapper
                                                jc="center"
                                                ai="center"
                                                padding={`3px 6px`}
                                                radius={`8px`}
                                                bgc={colors.Bl07}
                                                cursor={`pointer`}
                                                onClick={() => {
                                                        setManage(true)
                                                        
                                                    }
                                                }
                                            >
                                                <Text 
                                                    size="1.6rem" 
                                                    weight="600" 
                                                    lh="22px" 
                                                    ls="-0.28px" 
                                                    color={colors.White}
                                                >
                                                    {`반변경`}
                                                </Text>
                                            </Wrapper>
                                        : <></>
                                    }

                                    {
                                        (item.present === true) ?
                                            <Wrapper
                                                jc="center"
                                                ai="center"
                                                padding={`3px 6px`}
                                                radius={`8px`}
                                                bgc={colors.Bl07}
                                                cursor={`pointer`}
                                                onClick={() => {
                                                        setManageP(true);
                                                        setPresent(item);
                                                    }
                                                }
                                            >
                                                <Text 
                                                    size="1.6rem" 
                                                    weight="600" 
                                                    lh="22px" 
                                                    ls="-0.28px" 
                                                    color={colors.White}
                                                >
                                                    {`선물`}
                                                </Text>
                                            </Wrapper>
                                        : <></>
                                    }

                                </Wrapper>
                                
                            </Wrapper>
                        ))
                    }

                </Wrapper>

            </Wrapper>

            {/*<Wrapper
                jc="flex-start"
                ai="center"
                width="100%"
                wrap={'wrap'}
                padding={`0 24px 10px 24px`}
                margin={`0 0 20px 0`}
                bb={`1px solid ${colors.Gr02}`}
            >
            
                <Text 
                    size="2rem" 
                    weight="600" 
                    lh="22px" 
                    ls="-0.28px" 
                    color={colors.Gr05}
                    width={`100%`}
                    margin={`0 0 10px 0`}
                >
                    {`시설 예약 내역`}
                </Text>

                <Wrapper
                    jc="flex-start"
                    ai="center"
                    fd="column"
                    width="100%"
                    gap="15px"
                >
                    {
                        crewReservation?.map((item, idx) => ( 
                            <Wrapper
                                key={`${idx}_reservation`}
                                jc="flex-start"
                                ai="center"
                                fd="column"
                                width="100%"
                                gap="5px"
                                bb={`1px solid ${colors.Gr01}`}
                                padding={`3px 0`}
                            >
                                <Text 
                                    size="1.6rem" 
                                    weight="600" 
                                    lh="22px" 
                                    ls="-0.28px" 
                                    color={colors.Gr05}
                                    width={`100%`}
                                >
                                    {`${item.facility}`}
                                </Text>
        
                                <Text 
                                    size="1.6rem" 
                                    weight="00" 
                                    lh="22px" 
                                    ls="-0.28px" 
                                    color={colors.Gr04}
                                    width={`100%`}
                                >
                                    {`${item.start_date} ~ ${item.end_date.split(' ')[1]}`}
                                </Text>
                            </Wrapper>
                        )
                    )}  


                </Wrapper>

            </Wrapper>*/}

            <BottomWrap>
                {/*<Wrapper
                    onClick={() => CrewApplication()}
                    jc="center"
                    ai="center"
                    width="calc(50% - 5px)"
                    height="52px"
                    cursor={"pointer"}
                    radius="8px"
                    bgc={colors.Red03}
                >
                    <Text 
                        ta="center" 
                        size="1.6rem" 
                        weight="600" 
                        lh="22px" 
                        ls="-0.28px" 
                        color={colors.White}
                    >
                        {`가입 신청서 출력`}
                    </Text>
                </Wrapper>*/}

                <Wrapper
                    onClick={() => navigate(`/crew_revise/${params.id}`)}
                    jc="center"
                    ai="center"
                    width="100%"
                    height="52px"
                    cursor={"pointer"}
                    radius="8px"
                    bgc={colors.Bl07}
                >
                    <Text 
                        ta="center" 
                        size="1.6rem" 
                        weight="600" 
                        lh="22px" 
                        ls="-0.28px" 
                        color={colors.White}
                    >
                        {`정보 수정하기`}
                    </Text>
                </Wrapper>
            </BottomWrap>

            {
                manage ?
                    <ManageModal
                        modal={manage}
                        setModal={setManage}
                        title={`회원권 반변경`}
                        change={changeTitle}
                        changeTime={changeTime}
                        data={timeChart}
                        ticketId={changeTicket}
                        setLoading={setLoading}
                    />
                :
                <></>
            }

            {
                manageT ?
                    <CrewTicketModal
                        modal={manageT}
                        setModal={setManageT}
                        title={`회원권 관리`}
                        data={dataT}
                        GetData={GetData}
                        setLoading={setLoading}
                        activityList={activityList}
                    />
                :
                <></>
            }

            {
                manageP ?
                    <PresentModal
                        modal={manageP}
                        setModal={setManageP}
                        title={`이용권 선물`}
                        ticket={present}
                        crew={data}
                        setLoading={setLoading}
                    />
                :
                <></>
            }

            {loading && <Loading />}

            {copyPop && (
                <Popup>
                    <Text size="1.2rem" weight="600" color={colors.White}>
                        복사가 완료되었습니다.
                    </Text>
                </Popup>
            )}
        </Container>
    );
};

export default CrewDetail;

const Container = styled.section`
    height: calc(var(--vh, 1vh) * 100);
    overflow-x: hidden;
    width: 100%;
    padding-bottom : 150px;
`;

const BottomWrap = styled.div`
    width: 100%;
    max-width: 900px;
    transform : translate(-50%, 0);
    display: flex;
    justify-content: space-between;
    padding: 12px 24px;
    border-top: 1px solid ${colors.Gr01};
    background-color: ${colors.White};
    position: fixed;
    bottom : 0;
    left : 50%;
    z-index: 10;
`;

const Popup = styled.div`
    position: fixed;
    display: flex;
    justify-content: center;
    text-align: center;
    z-index: 99;
    bottom: 48px;
    left: 50%;
    transform: translate(-50%, 0);
    width: 320px;
    height: 44px;
    background-color: rgba(0, 0, 0, 0.7);
    border-radius: 8px;
`;

const TextareaContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 150px;
    border-radius: 8px;
`;

const TextArea = styled.textarea`
    display: inline-flex;
    width: 100%;
    height: 100%;
    border: none;
    border-radius: 8px;
    resize: none;
    line-height: 22px;
    letter-spacing: -0.02em;
    font-size: 1.6rem;
    font-weight: 500;

    overflow: auto;
    outline: none;

    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;

    &::placeholder {
        color: ${colors.Gr03};
    }
`;

const IntroText = styled.div`
    flex: 1;
    display : inline-flex;
    align-items : flex-end;
    justify-content : flex-end;
    width: 100%;
    padding: 8px 12px;
    color: ${colors.Gr03};
    line-height: 20px;
    letter-spacing: -0.02em;
    font-size: 1.3rem;
    font-weight: 500;
`;

const NewCrew = styled.div`
    z-index: 100;
    cursor : pointer;
    border : .2rem solid ${colors.Bl07};
    width : 3.3rem;
    height : 3.3rem;
    border-radius: 50%;
    display: inline-flex;
    align-items: center;
    justify-content: center;

    img {
        width : 3rem;
        display: inline-block;
    }
`;