import React, { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import Modal from "react-modal";
import axios from "axios";

//component 
import { GetOne } from "../../tool/localstorage";

// style
import colors from "../../styles/colors";
import { Wrapper, Input, Text } from "../../components/layouts/layoutStorage";

// img

const ManageModal = ({
    modal,
    setModal,
    title,
    change,
    data,
    changeTime,
    ticketId,
    type,
    setLoading
}) => {

    const [ogSelect, setOgSelect] = useState(changeTime);
    const [select, setSelect] = useState(changeTime);

    const ChangeClass = () => {
        setLoading(true);

        var token = GetOne("token");

        const frm = new FormData();

        frm.append("mode", "change_class");
        frm.append("token", token);
        frm.append("ticket_id", ticketId);
        frm.append("change_time", select);

        axios
        .post("https://ai.semos.kr/semos_partner/v3/activity/Crew", frm, {
            headers: { "Content-Type": "multipart/form-data" },
        })
        .then((data) => {
            var Value = data.data;
            var Status = Value.status;
            var StatusMessage = Value.status_message;

            if (Status == "success") {
                window.alert(StatusMessage);
                setLoading(false);
                window.location.reload();
            } else {
                window.alert(StatusMessage);
                setLoading(false);
            }
        });
    }

    return (
        <Modal
            isOpen={modal}
            onRequestClose={() => setModal(false)}
            contentLabel="Alert Modal"
            style={customStyles}
        >
            <Container>

                <AffiliateModalLayout>
                    <Wrapper
                        width={`100%`}
                        margin={`0 0 10px 0`}
                        jc={`center`}
                    >   
                        <Text 
                            width={`100%`} 
                            size="1.8rem" 
                            weight="600" 
                            lh="24px" 
                            ls="-0.32px" 
                            color={colors.Gr05}
                            ta={`center`}
                        >
                            {title}
                        </Text>
                    </Wrapper>      

                    <Wrapper
                        width={`100%`}
                        margin={`0 0 8px 0`}
                    >   
                        <Text 
                            width={`100%`} 
                            size="1.6rem" 
                            weight="500" 
                            lh="24px" 
                            ls="-0.32px" 
                            color={colors.Gr05}
                        >
                            변경 가능 시간대
                        </Text>
                    </Wrapper>   

                    <Wrapper
                        width={`100%`}
                        style={{
                            maxHeight : 300,
                            overflowX : 'hidden',
                            overflowY : "auto"
                        }}
                        border={`1px solid ${colors.Gr02}`}
                        radius={`8px`}
                        padding={`5px`}
                        wrap={`wrap`}
                    >  
                        {
                            change ?
                                data[change]?.chart_time?.map((item, idx) => (
                                    <Wrapper
                                        onClick={() => setSelect(item.split(' ')[1])}
                                        jc="center"
                                        ai="center"
                                        width="calc(100%)"
                                        cursor={"pointer"}
                                        radius="8px"
                                        bgc={colors.White}
                                        //margin={ `0 0 8px ${((idx % 2) == 1) ? `10px` : `0` }`}
                                        margin={ `0 0 8px 0`}
                                        padding={`5px 0`}
                                        border={`2px solid ${(select == item.split(' ')[1]) ? colors.Bl07 : colors.Gr04}`}
                                    >
                                        <Text 
                                            ta="center" 
                                            size="2rem" 
                                            weight="600" 
                                            lh="22px" 
                                            ls="-0.28px" 
                                            color={colors.Gr05}
                                            padding={`0 10px 0 0`}
                                            style={{
                                                borderRightWidth : 2,
                                                borderRightColor : colors.Gr02,
                                                borderRightStyle : 'solid',
                                                paddingRight : '10px'
                                            }}
                                        >
                                            {item.split(' ')[1]}
                                        </Text>

                                        <Text 
                                            ta="center" 
                                            size="1.8rem" 
                                            weight="500" 
                                            lh="22px" 
                                            ls="-0.28px" 
                                            color={colors.Bl07}
                                            margin={`0 0 0 10px`}
                                        >
                                            {data[change].chart_count ?
                                                `(${data[change].chart_count[item]}/${data[change].max_capacity})`
                                            : `(0/0)`}
                                        </Text>
                
                                    </Wrapper>
                                ))
                            : <></>
                        }
                    </Wrapper> 

                    <Wrapper
                        width={`100%`}
                        margin={`15px 0 0 0`}
                        jc={`flex-end`}
                    >   
                        <Text 
                            size="2rem" 
                            weight="600" 
                            lh="24px" 
                            ls="-0.32px" 
                            color={colors.Gr05}
                            ta={`right`}
                        >
                            {`변경 시간대 : ${ogSelect} -> ${select}`}
                        </Text>
                    </Wrapper>   

                    <Wrapper
                        onClick={() => ChangeClass()}
                        jc="center"
                        ai="center"
                        width="calc(100%)"
                        height="40px"
                        cursor={"pointer"}
                        radius="8px"
                        bgc={colors.Bl07}
                        margin={`15px 0 0 0`}
                    >
                        <Text 
                            ta="center" 
                            size="2rem" 
                            weight="600" 
                            lh="22px" 
                            ls="-0.28px" 
                            color={colors.White}
                        >
                            {`반변경`}
                        </Text>
                    </Wrapper>


                </AffiliateModalLayout>
            </Container>
        </Modal>
    );
};

export default ManageModal;

const customStyles = {
    content: {
        width: "calc(100% - 24px)",
        marginRight: "-50%",
        padding: 0,
        borderRadius: "16px",
        top: "50%",
        left: "50%",
        right: "auto",
        bottom: "auto",
        transform: "translate(-50%, -50%)",
    },
};

const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`;

const AffiliateModalLayout = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    max-height: 600px;
    max-width: 600px;
    padding: 12px 10px;
    border-radius: 0;
`;
