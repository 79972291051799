import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import axios from "axios";
import styled from "styled-components";

// components
import Header from "../components/basic/Header";
import Modal from "../components/newActivityRegister/modal";
import { GetOne } from "../tool/localstorage";
import { LoadingSpinner } from "../tool/loading";
import { fbevent } from "../tool/firebase";

import colors from "../styles/colors";

// img
import arrowBlack from "../icon/arrowBlack.png";
import arrowBlue from "../icon/blueArrow.png";
import plusBlue from "../icon/plusBlue.png";
import semosLogoWhite from "../icon/semosLogoWhite.png";
import locationGrey from "../icon/locationGrey.png";
import arrowWhite from "../icon/arrowWhite.png";
import waringWhite from "../icon/waringWhite.png";
import WhiteFile from "../icon/whiteFile.png";
import WhiteReturn from "../icon/whiteReturn.png";
import WhiteSearch from "../icon/whiteSearch.png";

const ActivityManage = () => {
  const [modalStatus, setModalStatus] = useState({
    status: false,
    type: "",
    link: "",
  });

  const [loading, setLoading] = useState(true);

  const navigate = useNavigate();

  const [SportOption, setSportOption] = useState({
    text: "스포츠 전체",
    value: "스포츠",
  });

  const [LocaitonOption, setLocaitonOption] = useState({
    text: "스포츠 전체",
    value: "스포츠",
  });

  const [sport, setSport] = useState({
    text: "스포츠 전체",
    value: "스포츠",
  });

  const [location, setLocation] = useState({
    text: "지역 전체",
    value: "지역",
  });

  const [height, setHeight] = useState();
  const [search, setSearch] = useState("");
  const [activityList, setActivityList] = useState();

  useEffect(() => {
    fbevent("B2B_ActivityManage_Page_View", {
      email : GetOne("email")
    });

    setTimeout(() => {
      var FirstComponent = document.getElementById("HeightCheck").clientHeight;
      setHeight(FirstComponent);
    }, 500);

    var token = GetOne("token");

    const frm = new FormData();

    frm.append("mode", "activity_list");
    frm.append("token", token);

    axios
      .post("https://ai.semos.kr/semos_partner/coaching/activity", frm, {
        headers: { "Content-Type": "multipart/form-data" },
      })
      .then((data) => {
        var Value = data.data;
        var Status = Value.status;
        var StatusMessage = Value.status_message;
        var ActivityList = Value.activity_list;
        var SportList = Value.sport_list;
        var LocationList = Value.location_list;

        if (Status == "success") {
          setActivityList(ActivityList);
          setSportOption(SportList);
          setLocaitonOption(LocationList);
          setLoading(false);
        } else {
          window.alert(StatusMessage);
        }
      });
  }, []);

  useEffect(() => {
    if (height) {
      setLoading(true);

      var token = GetOne("token");
      const frm = new FormData();

      frm.append("mode", "activity_list_update");
      frm.append("token", token);
      frm.append("sport", sport.value);
      frm.append("location", location.value);
      frm.append("search", search);

      axios
        .post("https://ai.semos.kr/semos_partner/coaching/activity", frm, {
          headers: { "Content-Type": "multipart/form-data" },
        })
        .then((data) => {
          var Value = data.data;
          var Status = Value.status;
          var StatusMessage = Value.status_message;
          var ActivityList = Value.activity_list;

          if (Status == "success") {
            setLoading(false);
            setActivityList(ActivityList);
          } else {
            setLoading(false);
            window.alert(StatusMessage);
          }
        });
    }
  }, [sport.value, location.value]);

  const GoSearch = () => {
    setLoading(true);

    var token = GetOne("token");

    const frm = new FormData();

    frm.append("mode", "activity_list_update");
    frm.append("token", token);
    frm.append("sport", sport.value);
    frm.append("location", location.value);
    frm.append("search", search);

    axios
      .post("https://ai.semos.kr/semos_partner/coaching/activity", frm, {
        headers: { "Content-Type": "multipart/form-data" },
      })
      .then((data) => {
        var Value = data.data;
        var Status = Value.status;
        var StatusMessage = Value.status_message;
        var ActivityList = Value.activity_list;

        if (Status == "success") {
          setActivityList(ActivityList);
          setLoading(false);
        } else {
          setLoading(false);
          window.alert(StatusMessage);
        }
      });
  };

  const GoRegister = () => {
    navigate("/new_activity");
  };

  const GoCalender = () => {
    navigate("/semos_activity_calender");
  };

  // 수정 페이지 이동 전 modal display
  // (사용자에게 노출된 상태에서 수정 들어갈 경우 안내 유도)
  /**
   * 1 활성 : 모달 -> 수정 페이지 이동 O
   * 2 비활성 : 수정 페이지 이동 불가 X
   * 3 승인심사중 : 수정 페이지 이동 불가 X
   * 4 임시저장 : 수정 페이지 이동 O
   * 5 신규 : 신규 등록 페이지 이동 O
   */

  const GoRevise = (number, status) => {
    if (status === "5") {
      navigate(`/activity_revise/${number}/info`);
    } else {
      /*setModalStatus({
        status: true,
        type: "revise",
        link: `/activity_revise/${number}/info`,
      });*/
      navigate(`/activity_revise/${number}/info`);
    }

    /*fbevent("B2B_ActivityManage_Edit_Click", {
      email : GetOne("email"),
      id : number
    });*/
  };

  const GoView = (number) => {
    fbevent("B2B_ActivityManage_Preview_Click", {
      email : GetOne("email"),
      id : number
    });

    window.open(`https://semos.kr/activity/${number}`, "_blank");
  };

  const ActivityTitle = (item) => {
    var activityCard = [];

    var activityStatus = item.status;
    var activitySchedule = item.schedule;

    if (activityStatus == 3 || activityStatus == 5) {
      var activityCardView = (
        <BackgroundContent bgc={colors.Bl02} padding={`6px 8px 6px 8px`} jc={`space-between`} ai={`center`}>
          <FliterView ai={`center`}>
            <BackgroundContent
              style={{
                whiteSpace: "nowrap",
              }}
              padding={`0`}
              ai={`center`}
            >
              <TextView color={colors.Bl07} fw={`500`} fs={`12px`} lh={`142%`}>{`승인 심사중`}</TextView>
            </BackgroundContent>
          </FliterView>

          <FliterView ai={`center`}>
            <BackgroundContent style={{ whiteSpace: "nowrap" }} padding={`0`} ai={`center`}>
              <ImageIcon100 width={`16px`} height={`16px`}>
                {<img src={arrowBlue} />}
              </ImageIcon100>
            </BackgroundContent>
          </FliterView>
        </BackgroundContent>
      );
      activityCard.push(activityCardView);
    } else if (activityStatus == 2) {
      var activityCardView = (
        <BackgroundContent bgc={colors.Gr01} padding={`6px 8px 6px 8px`} jc={`space-between`} ai={`center`}>
          <FliterView ai={`center`}>
            <BackgroundContent
              style={{
                whiteSpace: "nowrap",
              }}
              padding={`0`}
              ai={`center`}
            >
              <TextView color={colors.Gr04} fw={`500`} fs={`12px`} lh={`142%`}>{`비활성 액티비티 `}</TextView>
            </BackgroundContent>
          </FliterView>

          <FliterView ai={`center`}>
            <BackgroundContent style={{ whiteSpace: "nowrap" }} padding={`0`} ai={`center`}>
              <ImageIcon100 width={`16px`} height={`16px`}>
                <img src={arrowBlack} />
              </ImageIcon100>
            </BackgroundContent>
          </FliterView>
        </BackgroundContent>
      );
      activityCard.push(activityCardView);
    } else if (activityStatus == 4 ) {
      var activityCardView = (
        <BackgroundContent bgc={colors.Yellow01} padding={`6px 8px 6px 8px`} jc={`space-between`} ai={`center`}>
          <FliterView ai={`center`}>
            <BackgroundContent
              style={{
                whiteSpace: "nowrap",
              }}
              padding={`0`}
              ai={`center`}
            >
              <TextView color={colors.Gr04} fw={`500`} fs={`12px`} lh={`142%`}>{`임시 저장`}</TextView>
            </BackgroundContent>
          </FliterView>

          <FliterView ai={`center`}>
            <BackgroundContent style={{ whiteSpace: "nowrap" }} padding={`0`} ai={`center`}>
              <ImageIcon100 width={`16px`} height={`16px`}>
                <img src={arrowBlack} />
              </ImageIcon100>
            </BackgroundContent>
          </FliterView>
        </BackgroundContent>
      );
      activityCard.push(activityCardView);
    } else {
      if (activitySchedule) {
        var activityCardView = (
          <BackgroundContent jc={`space-between`} ai={`center`}>
            <FliterView ai={`center`}>
              <BackgroundContent
                style={{
                  borderRight: `1px solid ${colors.Gr04}`,
                  whiteSpace: "nowrap",
                }}
                padding={`0 6px 0 0`}
                ai={`center`}
              >
                <ImageIcon100 style={{ marginRight: "4px" }} width={`16px`} height={`16px`}>
                  <img src={semosLogoWhite} />
                </ImageIcon100>

                <TextView color={colors.Bl07} fw={`500`} fs={`12px`} lh={`142%`}>{`${item.sport}`}</TextView>
              </BackgroundContent>

              <BackgroundContent
                style={{
                  borderRight: `1px solid ${colors.Gr04}`,
                  whiteSpace: "nowrap",
                }}
                padding={`0 6px`}
                ai={`center`}
              >
                <TextView color={colors.Gr04} fw={`500`} fs={`12px`} lh={`142%`}>{`${item.duration}`}</TextView>
              </BackgroundContent>

              <BackgroundContent style={{ whiteSpace: "nowrap" }} padding={`0 6px`} ai={`center`}>
                <TextView color={colors.Gr04} fw={`500`} fs={`12px`} lh={`142%`}>{`${item.type}`}</TextView>
              </BackgroundContent>
            </FliterView>

            <FliterView ai={`center`}>
              <BackgroundContent style={{ whiteSpace: "nowrap" }} padding={`0`} ai={`center`}>
                <ImageIcon100 style={{ marginRight: "4px" }} width={`16px`} height={`16px`}>
                  <img src={locationGrey} />
                </ImageIcon100>

                <TextView color={colors.Gr04} fw={`500`} fs={`12px`} lh={`142%`}>{`${item.location}`}</TextView>
              </BackgroundContent>
            </FliterView>
          </BackgroundContent>
        );
        activityCard.push(activityCardView);
      } else {
        var activityCardView = (
          <BackgroundContent bgc={colors.Red03} padding={`6px 8px 6px 8px`} jc={`space-between`} ai={`center`}>
            <FliterView ai={`center`}>
              <BackgroundContent
                style={{
                  whiteSpace: "nowrap",
                }}
                padding={`0`}
                ai={`center`}
              >
                <TextView color={colors.White} fw={`500`} fs={`12px`} lh={`142%`}>{`일정 없음`}</TextView>
              </BackgroundContent>
            </FliterView>

            <FliterView ai={`center`}>
              <BackgroundContent style={{ whiteSpace: "nowrap" }} padding={`0`} ai={`center`}>
                <ImageIcon100 width={`16px`} height={`16px`}>
                  <img src={arrowWhite} />
                </ImageIcon100>
              </BackgroundContent>
            </FliterView>
          </BackgroundContent>
        );
        activityCard.push(activityCardView);
      }
    }

    return activityCard;
  };

  const ActivityContent = (item) => {
    var activityCard = [];

    var activityStatus = item.status;
    var activitySchedule = item.schedule;

    if (activityStatus == "3") {
      let activityContentView = (
        <div className="emptySchedule">
          <img alt={'activity_image'} src={WhiteSearch} />
        </div>
      );
      activityCard.push(activityContentView);
    } else if (activityStatus == "4" || activityStatus == "5") {
      let activityContentView = (
        <div className="emptySchedule">
          <img alt={'activity_image'} src={WhiteFile} />
        </div>
      );
      activityCard.push(activityContentView);
    } else if (activityStatus == "2") {
      let activityContentView = (
        <div className="emptySchedule">
          <img alt={'activity_image'} src={WhiteReturn} />
        </div>
      );
      activityCard.push(activityContentView);
    } else {
      if (!activitySchedule) {
        let activityContentView = (
          <div className="emptySchedule">
            <img alt={'activity_image'} src={waringWhite} />
          </div>
        );
        activityCard.push(activityContentView);
      }
    }

    return activityCard;
  };

  return (
    <Container>
      <Header title="액티비티 관리" back={true} handleClick={() => navigate("/home")} />

      <InnerContainer>
        <HeightCheck id="HeightCheck">
          <Divider height={"16"} color={colors.White} />

          <Divider height={"16"} color={colors.White} />

          <ContentContainer
            style={{ cursor: "pointer" }}
            onClick={() => {
              GoRegister();
            }}
          >
            <BackgroundContent
              border={`1px solid ${colors.Bl04}`}
              bgc={colors.Bl01}
              padding={`16px 18px 16px 18px`}
              br={`8`}
              jc={`space-between`}
              ai={`center`}
            >
              <TextContent width={`calc(100% - 23px)`} wrap={`wrap`}>
                <TextView width={`100%`} color={colors.Bl07} fw={`600`} fs={`16px`} lh={`142%`}>
                  신규 액티비티 등록
                </TextView>

                <Divider height={"4"} />

                <TextView width={`100%`} color={colors.Bl04} fw={`500`} fs={`12px`} lh={`142%`}>
                  새로운 액티비티를 등록할 수 있어요
                </TextView>
              </TextContent>

              <ImageIcon100 width={`22px`} height={`22px`}>
                <img src={plusBlue} />
              </ImageIcon100>
            </BackgroundContent>
          </ContentContainer>

          <Divider height={"12"} color={colors.White} />

          <ContentContainer>
            <BackgroundContent jc={`space-between`} ai={`center`}>
              {activityList ? <TextView color={colors.Gr04} fw={`500`} fs={`13px`} lh={`142%`}>{`총 ${activityList.length}개`}</TextView> : ""}

            </BackgroundContent>
          </ContentContainer>
        </HeightCheck>

        <ScrollContainer height={height && height}>
          {activityList &&
            activityList.map((item, idx) => (
              <CardContainer
                // status === 2 || 3 => cursor = default
                active={(item.status === "1" || item.status === "4" || item.status === "5") ? "pointer" : "default"}
                key={`${item.title}_${idx}`}
                style={{
                  borderBottom: `border-bottom: 1px solid ${colors.Gr01}`,
                }}
              >
                {ActivityTitle(item)}
                <Divider height={"12"} color={colors.White} />

                <BackgroundContent jc={`space-between`} ai={`center`}>
                  <ImageIconCenter width={`65px`} height={`65px`}>
                    <img alt={`activity_thumbnail`} src={`${item.profile}`} />
                    {ActivityContent(item)}
                  </ImageIconCenter>

                  <TextContent style={{ justifyContent: "space-between", height: "65px" }} width={`calc(100% - 77px)`} wrap={`wrap`}>
                    <TextView2 width={`100%`} color={item.schedule ? colors.Gr05 : colors.Gr04} fw={`600`} fs={`14px`} lh={`142%`}>{`${item.title}`}</TextView2>

                    <TextView
                      style={{ alignItems: "flex-end", height: "auto" }}
                      width={`100%`}
                      color={item.schedule ? colors.Gr06 : colors.Gr02}
                      fw={`600`}
                      fs={`15px`}
                      lh={`142%`}
                    >{`${item.price} 원`}</TextView>
                  </TextContent>
                </BackgroundContent>

                <Divider height={"6"} color={colors.White} />
                
                <BottomButtonView>
                  <div onClick={() => GoView(item.number)} style={{backgroundColor : colors.Bl07}}>
                    <span>미리보기</span>
                  </div>

                  <div onClick={() => GoRevise(item.number, item.status)} style={{backgroundColor : colors.Red03}}>
                    <span>수정하기</span>
                  </div>
                </BottomButtonView>
              </CardContainer>
            ))}
        </ScrollContainer>
      </InnerContainer>

      <LoadingSpinner loading={loading} title={`정보를 확인하고 있습니다.`} content={`잠시만 기다려 주세요!`} />

      <Modal modal={modalStatus} setModal={setModalStatus} />
    </Container>
  );
};

export default ActivityManage;

const Container = styled.section`
  /* height: calc(var(--vh, 1vh) * 100); */
  overflow-x: hidden;
`;

const Divider = styled.div`
  height: ${({ height }) => height && `${height}px`};
  background-color: ${({ color }) => color && `${color}`};
  width: 100%;
`;

const InnerContainer = styled.div`
  width: 100%;
  /* height: calc((var(--vh, 1vh) * 100) - 50.55px); */
  overflow: hidden;
`;

const HeightCheck = styled.div`
  width: 100%;
  height: auto;
  display: inline-block;
`;

const ContentContainer = styled.div`
  width: 100%;
  padding: 0 24px;
  display: inline-flex;
  position: relative;
  margin-bottom: 6px;
`;

const BackgroundContent = styled.div`
  width: 100%;
  border: ${({ border }) => border && `${border}`};
  background-color: ${({ bgc }) => bgc && `${bgc}`};
  padding: ${({ padding }) => padding && `${padding}`};
  display: inline-flex;
  border-radius: ${({ br }) => br && `${br}px`};
  justify-content: ${({ jc }) => jc && `${jc}`};
  align-items: ${({ ai }) => ai && `${ai}`};
  position: relative;
`;

const TextContent = styled.div`
  width: ${({ width }) => width && `${width}`};
  border: ${({ border }) => border && `${border}`};
  background-color: ${({ bgc }) => bgc && `${bgc}`};
  padding: ${({ padding }) => padding && `${padding}`};
  display: inline-flex;
  flex-wrap: ${({ wrap }) => wrap && `${wrap}`};
  border-radius: ${({ br }) => br && `${br}px`};
`;

const TextView = styled.span`
  width: ${({ width }) => width && `${width}`};
  text-align: ${({ ta }) => ta && `${ta}`};
  color: ${({ color }) => color && `${color}`};
  font-weight: ${({ fw }) => fw && `${fw}`};
  font-size: ${({ fs }) => fs && `${fs}`};
  line-height: ${({ lh }) => lh && `${lh}`};
  display: inline-flex;
`;

const TextView2 = styled.span`
  width: ${({ width }) => width && `${width}`};
  text-align: ${({ ta }) => ta && `${ta}`};
  color: ${({ color }) => color && `${color}`};
  font-weight: ${({ fw }) => fw && `${fw}`};
  font-size: ${({ fs }) => fs && `${fs}`};
  line-height: ${({ lh }) => lh && `${lh}`};

  text-overflow: ellipsis;
  overflow: hidden;
  word-break: break-word;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
`;

const FliterView = styled.div`
  display: inline-flex;
  align-items: ${({ ai }) => ai && `${ai}`};
`;

const InputView = styled.input`
  width: ${({ width }) => width && `${width}`};
  height: ${({ height }) => height && `${height}`};
  padding: ${({ padding }) => padding && `${padding}`};
  color: ${({ color }) => color && `${color}`};
  font-weight: ${({ fw }) => fw && `${fw}`};
  font-size: ${({ fs }) => fs && `${fs}`};
  line-height: ${({ lh }) => lh && `${lh}`};
  cursor: text;
  &::placeholder {
    color: ${({ colorP }) => colorP && `${colorP}`};
  }
`;

const ImageIcon100 = styled.div`
  cursor: pointer;
  width: ${({ width }) => width && `${width}`};
  height: ${({ height }) => height && `${height}`};
  display: inline-block;

  img {
    width: ${({ width }) => width && `${width}`};
    display: inline-block;
  }
`;

const ImageIconCenter = styled.div`
  width: ${({ width }) => width && `${width}`};
  height: ${({ height }) => height && `${height}`};
  display: inline-block;
  position: relative;
  overflow: hidden;

  img {
    height: ${({ height }) => height && `${height}`};
    display: inline-block;
    position: absolute;
    top: 0;
    right: 50%;
    transform: translate(50%, 0);
  }

  .emptySchedule {
    width: ${({ width }) => width && `${width}`};
    height: ${({ height }) => height && `${height}`};
    position: absolute;
    top: 0;
    right: 0;
    display: inline-flex !important;
    align-items: cetner !important;
    justify-content: center !important;
    z-index: 100;
    background-color: ${colors.B60};

    img {
      width: 33px !important;
      height: 33px !important;
      top: 50% !important;
      right: 50% !important;
      transform: translate(50%, -50%) !important;
    }
  }
`;

const ScrollContainer = styled.div`
  height: ${({ height }) => height && `calc(100vh - ${height}px)`};
  /* max-height: ${({ height }) => height && `calc( (var(--vh, 1vh) * 100) - (${height}px + 133.55px) )`}; */
  /* min-height: ${({ height }) => height && `calc((var(--vh, 1vh) * 100) - (${height}px + 133.55px) )`}; */
  /* height: ${({ height }) => height && `calc(100vh - (${height}px + 133.55px) )`}; */
  width: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  display: inline-block;
  flex-wrap: wrap;
  padding-bottom: 85px;
`;

const CardContainer = styled.div`
  width: 100%;
  display: inline-block;
  flex-wrap: wrap;
  padding: 20px 24px;
  border-bottom: 1px solid ${colors.Gr01};
  cursor: ${({ active }) => active && `${active}`};
`;

const BottomButtonView =  styled.div`
  width: 100%;
  display : inline-flex;
  justify-content: flex-end;
  gap : 10px;

  div {
    height : 32px;
    display : inline-flex;
    border-radius : 4px;
    padding : 0 12px;
    align-items: center;
    justify-content: center;

    span {
      display: inline-block;
      letter-spacing: -0.24px;
      font-size: 12px;
      font-weight: 600;
      word-break: keep-all;
      line-height: 20px;
      color : ${colors.White};
    }
  }
`;