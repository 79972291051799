import React, { useEffect, useState, useRef } from "react";
import styled, { css } from "styled-components";
import axios from "axios";

//tool
import { GetOne } from "../tool/localstorage";

//style
import { Wrapper, Text, } from "../components/layouts/layoutStorage";
import colors from "../styles/colors";
import {PC, W, useWindowSize } from "../styles/globalStyles";

//component
import { Loading } from "../tool/Loading";
import FacilityHeader from "../components/facilityRegister/FacilityHeader";
import ClassManageModal from "../components/facilityCrew/ClassManageModal";

//icon
import { ReactComponent as Arrow } from "../resource/icon/arrow.svg";

// Default Data
const IMAGE_RATIO = 234.375 / 375;

const ClassReservation = () => {

    const [loading, setLoading] = useState(false);
    const [manage, setManage] = useState(false);
    const [facility, setFacility] = useState();
    const [titleList, setTitleList] = useState();
    const [title, setTitle] = useState(0);
    const [data, setData] = useState();

    const [selectDay, setSelectDay] = useState({
        year : new Date().getFullYear(),
        month : (new Date().getMonth() + 1),
        day : `01`
    });

    const [width, setWidth] = useState(useWindowSize());

    let WID = useWindowSize();
    
    useEffect(() => {
        setWidth(WID);
    }, [WID]);

    const NextMonth = () => {
        let ThisDate = `${selectDay.year}-${(selectDay.month > 9) ? selectDay.month : `0${selectDay.month}`}-${(selectDay.day > 9) ? selectDay.day : `0${selectDay.day}`}`;

        ThisDate = new Date(ThisDate);

        ThisDate.setMonth(ThisDate.getMonth() + 1);

        setSelectDay({
            year : ThisDate.getFullYear(),
            month : (ThisDate.getMonth() + 1),
            day : `01`
        })
    }

    const PrevMonth = () => {
        let ThisDate = `${selectDay.year}-${(selectDay.month > 9) ? selectDay.month : `0${selectDay.month}`}-${(selectDay.day > 9) ? selectDay.day : `0${selectDay.day}`}`;

        ThisDate = new Date(ThisDate);

        ThisDate.setMonth(ThisDate.getMonth() - 1);

        setSelectDay({
            year : ThisDate.getFullYear(),
            month : (ThisDate.getMonth() + 1),
            day : `01`
        })
    }

    const GetData = () => {
        setLoading(true);

        var token = GetOne("token");

        const frm = new FormData();

        frm.append("mode", "class_reservation");
        frm.append("token", token);
        frm.append("year", selectDay.year);
        frm.append("month", (selectDay.month > 9) ? selectDay.month : `0${selectDay.month}`);
        frm.append("day", `01`);

        axios
        .post("https://ai.semos.kr/semos_partner/v3/activity/Facility", frm, {
            headers: { "Content-Type": "multipart/form-data" },
        })
        .then((data) => {
            var Value = data.data;
            var Status = Value.status;
            var StatusMessage = Value.status_message;
            var FacilityInfo = Value.facility_info;
            var FacilityTitle = Value.facility_title;

            if (Status == "success") {
                setFacility(FacilityInfo);
                setTitleList(FacilityTitle);

                setLoading(false);
            } else {
                window.alert(StatusMessage);
                setLoading(false);
            }
        });
    };

    useEffect(() => {
        GetData()
    }, [selectDay]);

    const ListReturn = () => {

        let Check = width < 1300;

        switch(Check) {

            case true :
                if(facility){
                    let target = facility[title];
                    const ChartTime = target.chart_time;
                    const ChartUser = target.chart_user;
                    const ChartCount = target.chart_count;
                    const OffList = target.off_list;
                    const CapacityList = target.capacity_list;
                    const ClassId = target.id;
    
                    return (
                        <>
                            <Wrapper
                                display="inline-block"
                                width="100%"
                                height={`50px`}
                                style={{
                                    overflowY : 'hidden',
                                    overflowX : 'auto',
                                    whiteSpace : "nowrap",
                                }}
                            >
                                {
                                    titleList?.map((item, idx) => (
                                        <Wrapper
                                            key={`${idx}_chart`}
                                            display="inline-flex"
                                            padding={`5px 8px`}
                                            radius={`8px`}
                                            border={`1px solid ${(idx == title) ? colors.Bl07 : colors.Gr03}`}
                                            bgc={`${colors.White}`}
                                            ai={`center`}
                                            jc={`center`}
                                            cursor={`pointer`}
                                            margin={(idx == 0) ? `0` : `0 0 0 8px`}
                                            onClick={() => setTitle(idx)}
                                        >
                                            <Text 
                                                ta="center" 
                                                size="1.6rem" 
                                                weight="600" 
                                                color={(idx == title) ? colors.Bl07 :  colors.Gr05}
                                            >
                                                {item}
                                            </Text>
                                        </Wrapper>
                                    ))
                                }
                            </Wrapper>

                            <Wrapper
                                jc="flex-start"
                                display="inline-flex"
                                fd={`column`}
                                ai="center"
                                width="100%"
                                height={`calc(100% - 50px)`}
                                border={`1px solid ${colors.Gr02}`}
                                radius={`8px`}
                                of={`hidden auto`}
                                bgc={`${colors.Gr01}`}
                                wrap={`wrap`}
                            >
                                {
                                            <Wrapper
                                                jc="flex-start"
                                                display="inline-block"
                                                fd={`column`}
                                                ai="center"
                                                width={`calc(100%)`}
                                                height={`auto`}
                                                border={`1px solid ${colors.Gr02}`}
                                                radius={`8px`}
                                                of={`hidden auto`}
                                                bgc={`${colors.Gr01}`}
                                                wrap={`wrap`}
                                            >
                                                <Wrapper
                                                        bb={`solid 1px ${colors.Gr02}`}
                                                        br={`solid 1px ${colors.Gr02}`}
                                                        bgc={`${colors.Gr01}`}
                                                        width={`100%`}
                                                        //height={`45px`}
                                                        position={`sticky`}
                                                        style={{
                                                            top : 0,
                                                            left : 0,
                                                            zIndex : 10,
                                                            minHeight : 45
                                                        }}
                                                >
                                                        <Wrapper
                                                            width={`100%`}
                                                            //height={`45px`}
                                                            ai={`center`}
                                                            jc={`center`}
                                                            bgc={`${colors.Gr01}`}
                                                            style={{
                                                                minHeight : 45
                                                            }}
                                                        >
                                                            <Text 
                                                                ta="center" 
                                                                size="1.8rem" 
                                                                weight="600" 
                                                                color={colors.Gr05}
                                                            >
                                                                {target.title}
                                                            </Text>
                                                        </Wrapper>
                        
                                                </Wrapper>    
        
                                                {ChartTime?.map((item, idx) => (
                    
                                                    <Wrapper
                                                        key={`${idx}_time_chart`}
                                                        bb={`solid 1px ${colors.Gr02}`}
                                                        br={`solid 1px ${colors.Gr02}`}
                                                        width={`100%`}
                                                        display={`flex`}
                                                        //height={`45px`}
                                                        style={{
                                                            minHeight : 45
                                                        }}
                                                        onClick={() => {
                                                            setManage(true);
                                                            setData({
                                                                year : selectDay?.year,
                                                                month : selectDay?.month > 9 ? selectDay?.month : `0${selectDay?.month}`,
                                                                day : selectDay.day,
                                                                time : item.split(' ')[1],
                                                                status : true,
                                                                now : ChartCount[item],
                                                                off : OffList[item],
                                                                capacity : CapacityList[item],
                                                                classId : ClassId
                                                            })
                                                        }}
                                                        bgc={OffList[item] ? 'transparent' : colors.B20}
                                                    >
                                                        <Wrapper
                                                            width={`20%`}
                                                            //height={`45px`}
                                                            //br={`1px solid ${colors.Gr02}`}
                                                            ai={`center`}
                                                            jc={`center`}
                                                            style={{
                                                                minHeight : 45,
                                                                height : '100%'
                                                            }}
                                                        >
                                                            <Text 
                                                                ta="center" 
                                                                size="1.6rem" 
                                                                weight="500" 
                                                                color={colors.Gr05}
                                                            >
                                                                {item.split(' ')[1]}
                                                            </Text>
                                                        </Wrapper>
                        
                                                        <Wrapper
                                                            width={`80%`}
                                                            //height={`45px`}
                                                            bl={`1px solid ${colors.Gr02}`}
                                                            padding={`2px 10px 2px 10px`}
                                                            wrap={`nowrap`}
                                                            gap={`5px`}
                                                            style={{
                                                                minHeight : 45
                                                            }}
                                                        >
                                                            <Wrapper
                                                                width={`100%`}
                                                                wrap={`wrap`}
                                                                jc={`flex-end`}
                                                                br={`solid 1px ${colors.Gr02}`}
                                                                padding={`0 3px 0 0 `}
                                                                gap={`5px`}
                                                            >
                                                                {
                                                                    ChartUser[item]?.map((i, id) => (
                                                                        <Text 
                                                                            key={`${idx}_${id}_chart`}
                                                                            ta="right" 
                                                                            size="1.6rem" 
                                                                            weight="500" 
                                                                            color={colors.Gr05}
                                                                            jc={`flex-end`}
                                                                        >
                                                                            {(id == 0) ? `${i.name}` : `, ${i.name}`}
                                                                        </Text>
                                                                    ))
                                                                }
                                                                
                                                            </Wrapper>
                        
                                                            <Text 
                                                                ta="right" 
                                                                size="1.6rem" 
                                                                weight="600" 
                                                                width={`50px`}
                                                                height={`100%`}
                                                                color={colors.Gr05}
                                                                ai={`center`}
                                                                jc={`center`}
                                                            >
                                                                {`${ChartCount[item]}/${CapacityList[item]}`}
                                                            </Text>
                        
                                                        </Wrapper>
                                                    </Wrapper>
                                                ))}
                                            </Wrapper>
                                }
                            </Wrapper> 
                        </>
                    )
                }
            break;

            case false :
                return (
                    <Wrapper
                        jc="flex-start"
                        display="inline-flex"
                        fd={`row`}
                        ai="flex-start"
                        width="100%"
                        height={`calc(100% - 50px)`}
                        border={`1px solid ${colors.Gr02}`}
                        radius={`8px`}
                        of={`hidden auto`}
                        bgc={`${colors.Gr01}`}
                        wrap={`wrap`}
                    >
                        {
                            facility?.map((item2, idx2) => {
                                const ChartTime = item2.chart_time;
                                const ChartUser = item2.chart_user;
                                const ChartCount = item2.chart_count;
                                const OffList = item2.off_list;
                                const CapacityList = item2.capacity_list;
                                const ClassId = item2.id;

                                return (
                                    <Wrapper
                                        key={`${idx2}_chart`}
                                        jc="flex-start"
                                        display="inline-block"
                                        fd={`column`}
                                        ai="center"
                                        width={`calc(100% / ${facility?.length})`}
                                        height={`auto`}
                                        border={`1px solid ${colors.Gr02}`}
                                        radius={`8px`}
                                        of={`hidden auto`}
                                        bgc={`${colors.Gr01}`}
                                        wrap={`wrap`}
                                    >
                                        <Wrapper
                                                bb={`solid 1px ${colors.Gr02}`}
                                                br={`solid 1px ${colors.Gr02}`}
                                                bgc={`${colors.Gr01}`}
                                                width={`100%`}
                                                //height={`45px`}
                                                position={`sticky`}
                                                style={{
                                                    top : 0,
                                                    left : 0,
                                                    zIndex : 10,
                                                    minHeight : 45
                                                }}
                                        >
                                                <Wrapper
                                                    width={`100%`}
                                                    //height={`45px`}
                                                    ai={`center`}
                                                    jc={`center`}
                                                    bgc={`${colors.Gr01}`}
                                                    style={{
                                                        minHeight : 45
                                                    }}
                                                >
                                                    <Text 
                                                        ta="center" 
                                                        size="2rem" 
                                                        weight="700" 
                                                        color={colors.Gr05}
                                                    >
                                                        {item2.title}
                                                    </Text>
                                                </Wrapper>
                
                                        </Wrapper>    

                                        {ChartTime?.map((item, idx) => (
            
                                            <Wrapper
                                                key={`${idx2}_${idx}_time_chart`}
                                                bb={`solid 1px ${colors.Gr02}`}
                                                br={`solid 1px ${colors.Gr02}`}
                                                width={`100%`}
                                                //height={`45px`}
                                                style={{
                                                    minHeight : 45
                                                }}
                                                cursor={`pointer`}
                                                onClick={() => {
                                                    setManage(true);
                                                    setData({
                                                        year : selectDay?.year,
                                                        month : selectDay?.month > 9 ? selectDay?.month : `0${selectDay?.month}`,
                                                        day : selectDay.day,
                                                        time : item.split(' ')[1],
                                                        status : true,
                                                        now : ChartCount[item],
                                                        off : OffList[item],
                                                        capacity : CapacityList[item],
                                                        classId : ClassId
                                                    })
                                                }}
                                                bgc={OffList[item] ? 'transparent' : colors.B20}
                                            >
                                                <Wrapper
                                                    width={`20%`}
                                                    //height={`45px`}
                                                    //br={`1px solid ${colors.Gr02}`}
                                                    ai={`center`}
                                                    jc={`center`}
                                                    style={{
                                                        minHeight : 45,
                                                        height : '100%'
                                                    }}
                                                >
                                                    <Text 
                                                        ta="center" 
                                                        size="1.8rem" 
                                                        weight="500" 
                                                        color={colors.Gr05}
                                                    >
                                                        {item.split(' ')[1]}
                                                    </Text>
                                                </Wrapper>
                
                                                <Wrapper
                                                    width={`80%`}
                                                    //height={`45px`}
                                                    bl={`1px solid ${colors.Gr02}`}
                                                    padding={`2px 10px 2px 10px`}
                                                    wrap={`nowrap`}
                                                    gap={`5px`}
                                                    style={{
                                                        minHeight : 45
                                                    }}
                                                >
                                                    <Wrapper
                                                        width={`100%`}
                                                        wrap={`wrap`}
                                                        jc={`flex-end`}
                                                        br={`solid 1px ${colors.Gr02}`}
                                                        padding={`0 3px 0 0 `}
                                                        gap={`5px`}
                                                    >
                                                        {
                                                            ChartUser[item]?.map((i, id) => (
                                                                <Text 
                                                                    key={`${idx2}_${idx}_${id}_user_chart`}
                                                                    ta="right" 
                                                                    size="1.8rem" 
                                                                    weight="500" 
                                                                    color={colors.Gr05}
                                                                    jc={`flex-end`}
                                                                >
                                                                    {(id == 0) ? `${i.name}` : `, ${i.name}`}
                                                                </Text>
                                                            ))
                                                        }
                                                        
                                                    </Wrapper>
                
                                                    <Text 
                                                        ta="right" 
                                                        size="1.8rem" 
                                                        weight="600" 
                                                        width={`50px`}
                                                        height={`100%`}
                                                        color={colors.Gr05}
                                                        ai={`center`}
                                                        jc={`center`}
                                                    >
                                                        {`${ChartCount[item]}/${CapacityList[item]}`}
                                                    </Text>
                
                                                </Wrapper>
                                            </Wrapper>
                                        ))}
                                    </Wrapper>
                                )

                            })

                        }
                    </Wrapper> 
                )
            default :
                return (
                    <></>   
                )

        }

    } 

    return (
        <Container id="description_container">

            <FacilityHeader
                status={true}
                title={'강습반 예약 현황'}
                available={false}
                statusHandler={() => console.log("신규 회원 등록")}
            />

            <Wrapper
                jc="flex-start"
                ai="center"
                fd={`column`}
                width="100%"
                height={`calc((var(--vh, 1vh) * 100) - 40px)`}
                padding={`0 24px 10px 24px`}
                margin={`0 0 0 0`}
                gap={`10px`}
                bb={`1px solid ${colors.Gr02}`}
            >
                <Wrapper
                    width={`100%`}
                    height={`50px`}
                    jc={'center'}
                    ai={`center`}
                >
                    <Wrapper
                        margin={`0 3rem 0 0`}
                        cursor={`pointer`}
                        onClick={() => PrevMonth()}
                    >
                        <Arrow style={{transform : "rotate(180deg)"}} width="40px" height="40px" fill={colors.Gr06} />
                    </Wrapper>
                    <Text 
                        ta="center" 
                        size="2.2rem" 
                        weight="600" 
                        lh="22px" 
                        ls="-0.28px" 
                        color={colors.Gr05}
                    >
                        {`
                            ${selectDay.year}.
                            ${(selectDay.month > 9) ? selectDay.month : `0${selectDay.month}`}
                        `}
                    </Text>

                    <Wrapper
                        margin={`0 0 0 3rem`}
                        cursor={`pointer`}
                        onClick={() => NextMonth()}
                    >
                        <Arrow width="40px" height="40px" fill={colors.Gr06} />
                    </Wrapper>
                </Wrapper>

                {ListReturn()}

            </Wrapper>

            {loading && <Loading />}

            {
                manage ?
                    <ClassManageModal
                        modal={manage}
                        setModal={setManage}
                        title={`강습반 예약 관리`}
                        data={data}
                        GetData={GetData}
                        setLoading={setLoading}
                    />
                :
                <></>
            }

        </Container>
    );
};

export default ClassReservation;

const Container = styled.section`
    height: calc(var(--vh, 1vh) * 100);
    overflow-x: hidden;
    width: 100%;

    .slick-slider {
        width: 100%;
        height: ${W * IMAGE_RATIO}px;
    }
`;