import React, { useState } from "react";
import styled from "styled-components";

import { setImageFromFile, CropImage } from "../../tool/imageCrop";

import CameraAdd from "../../icon/cameraAdd.png";
import CameraRevise from "../../icon/cameraRevise.png";
import { W } from "../../styles/globalStyles";
import colors from "../../styles/colors";

const WW = W - 48;
const H = WW * 0.63;

function IdCardView({ data, setData }) {
  const [crop, setCrop] = useState(false);
  const [cropImage, setCropImage] = useState({
    image: "",
    type: "",
    width: 0,
    height: 0,
  });

  const StateSettingAdd = (key, value) => {
    setData((prev) => ({ ...prev, [key]: value }));
  };

  const InputValueClear = (type) => {
    var input = document.getElementById(type);

    input.value = null;
  };

  if (data) {
    return (
      <Container data={data}>
        <Cover />
        <Background src={data} />
        <Add data={data} htmlFor="id_card">
          <AddIcon src={CameraRevise} />
          <div>사진 편집하기</div>
        </Add>
        <input
          style={{ display: "none" }}
          type="file"
          id="id_card"
          accept="image/*"
          onChange={({ target: { files } }) => {
            if (files.length) {
              setImageFromFile({
                file: files[0],
                setImageUrl: ({ result }) => {
                  setCropImage({
                    image: result,
                    width: 0,
                    height: 0,
                    type: "id_card",
                  });
                  setCrop(true);
                },
              });
            }
          }}
        />

        <CropImage
          status={crop}
          OnOff={setCrop}
          image={cropImage}
          imageSetting={setCropImage}
          setImage={StateSettingAdd}
          inputClear={InputValueClear}
          defaultArray={
            cropImage.type === "id_card"
              ? {
                  id: "new",
                  image: "",
                  status: 1,
                  change: "no",
                }
              : ""
          }
        />
      </Container>
    );
  } else {
    return (
      <Container>
        <Add data={data} htmlFor="id_card">
          <AddIcon src={CameraAdd} />
          <div>사진 등록하기</div>
        </Add>
        <input
          style={{ display: "none" }}
          type="file"
          id="id_card"
          accept="image/*"
          onChange={({ target: { files } }) => {
            if (files.length) {
              setImageFromFile({
                file: files[0],
                setImageUrl: ({ result }) => {
                  setCropImage({
                    image: result,
                    width: 1,
                    height: 0.63,
                    type: "id_card",
                  });
                  setCrop(true);
                },
              });
            }
          }}
        />

        <CropImage
          status={crop}
          OnOff={setCrop}
          image={cropImage}
          imageSetting={setCropImage}
          setImage={StateSettingAdd}
          inputClear={InputValueClear}
          defaultArray={
            cropImage.type === "id_card"
              ? {
                  id: "new",
                  image: "",
                  status: 1,
                  change: "no",
                }
              : ""
          }
        />
      </Container>
    );
  }
}

export default IdCardView;

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: ${H}px;
  position: relative;
  margin: 8px 0;
  border: 1px solid ${colors.Gr02};
  border-radius: 8px;
  color: ${({ data }) => (data ? colors.White : colors.Gr03)};
  font-size: 12px;
  font-weight: 500;
`;

const Add = styled.label`
  cursor: pointer;
  padding: 10px;
  border: 1px solid ${colors.White};
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 5;
`;

const AddIcon = styled.img`
  width: 40px;
  height: 40px;
  margin-bottom: 8px;
`;

const Background = styled.img`
  height: ${H}px;
  width: ${WW}px;
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 8px;
`;

const Cover = styled.div`
  width: ${WW}px;
  height: ${H}px;
  background-color: ${colors.B50};
  position: absolute;
  top: 0;
  left: 0;
  z-index: 3;
  border-radius: 8px;
`;
