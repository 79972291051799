import React, { useEffect, useState, useRef } from "react";
import styled from "styled-components";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";

//tool
import { GetOne } from "../tool/localstorage";

//style
import { Wrapper, Text } from "../components/layouts/layoutStorage";
import colors from "../styles/colors";

//component
import { Loading } from "../tool/Loading";
import FacilityHeader from "../components/facilityRegister/FacilityHeader";
import PresentModal from "../components/present/PresentModal";
import SemosPhoneAuth from "../components/present/SemosPhoneAuth";

//icon
import { ReactComponent as Copy } from "../resource/icon/Copy.svg";

const Present = () => {

    const params = useParams();

    const [loading, setLoading] = useState(false);
    const [manageP, setManageP] = useState(false);
    const [present, setPresent] = useState();

    const [center, setCenter] = useState();
    const [confirm, setConfirm] = useState(false);
    const [userId, setUserId] = useState();

    const [status, setStatus] = useState({
        phone: "default",
        auth: "default",
    });
    
    const [userInfo, setUserInfo] = useState({
        phone: "",
        auth: "",
    });
    
    const [data, setData] = useState({
        name : "",
        gender : "남",
        phone : "010",
        car_number : "",
        car_type : "",
        birth_y : "",
        birth_m : "01",
        birth_d : "01",
        confirm : "",
        semos_account : "",
        age : "",
        register_date : "",
        user_tier : "",
        memo : ""
    });

    const [crewTicket, setCrewTicket] = useState();

    const CenterId = params.id;

    const GetData = () => {
        setLoading(true);

        var token = GetOne("token");

        const frm = new FormData();

        frm.append("mode", "present");
        frm.append("token", token);
        frm.append("center_id", CenterId);

        axios
        .post("https://ai.semos.kr/semos_partner/v3/activity/Facility", frm, {
            headers: { "Content-Type": "multipart/form-data" },
        })
        .then((data) => {
            var Value = data.data;
            var Status = Value.status;
            var StatusMessage = Value.status_message;

            if(Status == 'success') {
                let center = Value.data;
                setCenter(center);
                setLoading(false);
            } else {
                window.alert(StatusMessage);
            }
        });
    }

    useEffect(() => {
        GetData()
    }, []);

    useEffect(() => {
        if(userId) {
            setLoading(true);

            var token = GetOne("token");
    
            const frm = new FormData();
    
            frm.append("mode", "crew_info_present");
            frm.append("token", token);
            frm.append("crew_id", userId);
            frm.append("center_id", CenterId);
    
            axios
            .post("https://ai.semos.kr/semos_partner/v3/activity/Crew", frm, {
                headers: { "Content-Type": "multipart/form-data" },
            })
            .then((data) => {
                var Value = data.data;
                var Status = Value.status;
                var StatusMessage = Value.status_message;
                var CrewInfo = Value.crew_info;
                var crewTicket = Value.crew_ticket;
    
                if (Status == "success") {
                    setData(CrewInfo);
                    setCrewTicket(crewTicket);
                    setConfirm(true);
                    setLoading(false);
                } else {
                    window.alert(StatusMessage);
                    setLoading(false);
                }
            });
        }
    }, [userId]);

    return (
        center ?
            <Container id="description_container">

                <FacilityHeader
                    status={true}
                    title={'이용권 선물'}
                    available={false}
                    statusHandler={() => console.log("신규 회원 등록")}
                />

                {
                    confirm ?
                        <>
                            <Wrapper
                                jc="space-between"
                                ai="flex-end"
                                width="100%"
                                padding={`20px 24px 10px 24px`}
                                margin={`0 0 20px 0`}
                                bb={`1px solid ${colors.Gr02}`}
                            >

                            </Wrapper>

                            <Wrapper
                                jc="flex-start"
                                ai="center"
                                fd={`column`}
                                width="100%"
                                padding={`0 24px 10px 24px`}
                                margin={`0 0 20px 0`}
                                gap={`15px`}
                                bb={`1px solid ${colors.Gr02}`}
                            >
                                <Wrapper
                                    width={`100%`}
                                    jc={'space-between'}
                                >
                                    <Text 
                                        ta="center" 
                                        size="2rem" 
                                        weight="600" 
                                        lh="22px" 
                                        ls="-0.28px" 
                                        color={colors.Gr05}
                                        width={`calc(100% - 3rem)`}
                                    >
                                        {`회원명 : ${data?.name} (${data?.gender})`}
                                    </Text>

                                    <Copy
                                        width="2.5rem"
                                        height="2.5rem"
                                        fill={colors.Gr05}
                                        style={{
                                            cursor : "pointer"
                                        }}
                                        //onClick={() => CopyText(`${data?.name} (${data?.gender})`)}
                                    />
                                </Wrapper>

                                <Wrapper
                                    width={`100%`}
                                    jc={'space-between'}
                                >
                                    <Text 
                                        ta="center" 
                                        size="2rem" 
                                        weight="600" 
                                        lh="22px" 
                                        ls="-0.28px" 
                                        color={colors.Gr05}
                                        width={`calc(100% - 3rem)`}
                                    >전화번호 :
                                        <a 
                                            style={{
                                                textDecoration : "underline",
                                                color : colors.Gr05,
                                                marginLeft : 8
                                            }}    
                                            href={`tel:${data?.phone}`}
                                        >
                                            {`${data?.phone}`}
                                        </a>
                                    </Text>

                                    <Copy
                                        width="2.5rem"
                                        height="2.5rem"
                                        fill={colors.Gr05}
                                        style={{
                                            cursor : "pointer"
                                        }}
                                        //onClick={() => CopyText(data?.phone)}
                                    />
                                </Wrapper>

                            </Wrapper>

                            <Wrapper
                                jc="flex-start"
                                ai="center"
                                width="100%"
                                wrap={'wrap'}
                                padding={`0 24px 10px 24px`}
                                margin={`0 0 20px 0`}
                                bb={`1px solid ${colors.Gr02}`}
                            >
                                <Wrapper
                                    jc="space-between"
                                    ai="center"
                                    width="100%"
                                    wrap={'wrap'}
                                    margin={`0 0 10px 0`}
                                >

                                    <Text 
                                        size="2rem" 
                                        weight="600" 
                                        lh="22px" 
                                        ls="-0.28px" 
                                        color={colors.Gr05}
                                    >
                                        {`보유 회원권 및 티켓 내역`}
                                    </Text>

                                </Wrapper>

                                <Wrapper
                                    jc="flex-start"
                                    ai="center"
                                    fd="column"
                                    width="100%"
                                    gap="15px"
                                >
                                    {
                                        crewTicket?.map((item, idx) => (
                                            <Wrapper
                                                key={`${idx}_ticket`}
                                                jc="flex-start"
                                                ai="center"
                                                fd="column"
                                                width="100%"
                                                gap="5px"
                                                bb={`1px solid ${colors.Gr01}`}
                                                padding={`3px 0`}
                                            >
                                                <Text 
                                                    size="1.6rem" 
                                                    weight="600" 
                                                    lh="22px" 
                                                    ls="-0.28px" 
                                                    color={colors.Gr05}
                                                    width={`100%`}
                                                >
                                                    {`${item.title}`}
                                                </Text>
                        
                                                <Text 
                                                    size="1.6rem" 
                                                    weight="00" 
                                                    lh="22px" 
                                                    ls="-0.28px" 
                                                    color={colors.Gr04}
                                                    width={`100%`}
                                                >
                                                    {`유효 기간 : ${item.start_date.split(' ')[0]} ~ ${item.finish_date.split(' ')[0]}`}
                                                </Text>

                                                {
                                                    (item.activity_type == '정기 레슨') ?
                                                        <Text 
                                                            size="1.6rem" 
                                                            weight="00" 
                                                            lh="22px" 
                                                            ls="-0.28px" 
                                                            color={colors.Gr04}
                                                            width={`100%`}
                                                        >
                                                            {`이용 시간대 : ${item.start_date.split(' ')[1]} ~ ${item.finish_date.split(' ')[1]}`}
                                                        </Text>
                                                    : 
                                                    <></>
                                                }

                                                <Wrapper
                                                    jc="space-between"
                                                    ai="flex-end"
                                                    width="100%"
                                                    margin={`0 0 5px 0`}
                                                >
                                                    {
                                                        (item.category == 1) ?
                                                            <Text 
                                                                size="1.6rem" 
                                                                weight="00" 
                                                                lh="22px" 
                                                                ls="-0.28px" 
                                                                color={colors.Gr04}
                                                                width={`100%`}
                                                            >
                                                                {`사용 횟수 : ${item.use} / ${item.size}`}
                                                            </Text>
                                                        : 
                                                            <Text 
                                                                size="1.6rem" 
                                                                weight="00" 
                                                                lh="22px" 
                                                                ls="-0.28px" 
                                                                color={colors.Gr04}
                                                                width={`100%`}
                                                            >
                                                                {`이용 기간 : ${item.count}개월`}
                                                            </Text>
                                                    }

                                                    {
                                                        (item.present === true) ?
                                                            <Wrapper
                                                                jc="center"
                                                                ai="center"
                                                                padding={`3px 6px`}
                                                                radius={`8px`}
                                                                bgc={colors.Bl07}
                                                                cursor={`pointer`}
                                                                onClick={() => {
                                                                        setManageP(true);
                                                                        setPresent(item);
                                                                    }
                                                                }
                                                            >
                                                                <Text 
                                                                    size="1.6rem" 
                                                                    weight="600" 
                                                                    lh="22px" 
                                                                    ls="-0.28px" 
                                                                    color={colors.White}
                                                                >
                                                                    {`선물`}
                                                                </Text>
                                                            </Wrapper>
                                                        : <></>
                                                    }

                                                </Wrapper>
                                                
                                            </Wrapper>
                                        ))
                                    }

                                </Wrapper>

                            </Wrapper>

                            {
                                manageP ?
                                    <PresentModal
                                        modal={manageP}
                                        setModal={setManageP}
                                        title={`이용권 선물`}
                                        ticket={present}
                                        crew={data}
                                        setLoading={setLoading}
                                        centerId={CenterId}
                                    />
                                :
                                <></>
                            }

                        </>
                    : 
                    <>
                        <Wrapper
                            jc="space-between"
                            ai="flex-end"
                            width="100%"
                            padding={`20px 24px 10px 24px`}
                            margin={`0 0 20px 0`}
                            bb={`1px solid ${colors.Gr02}`}
                        >
                            <Wrapper
                                jc="space-between"
                                ai="center"
                                width="100%"
                                wrap={'wrap'}
                                margin={`0 0 10px 0`}
                            >

                                <Text 
                                    size="2rem" 
                                    weight="600" 
                                    lh="22px" 
                                    ls="-0.28px" 
                                    color={colors.Gr05}
                                    style={{
                                        marginBottom : 10
                                    }}
                                >
                                    {`회원 검색 (전화번호로 검색)`}
                                </Text>

                                <SemosPhoneAuth
                                    userInfo={userInfo}
                                    setUserInfo={setUserInfo}
                                    status={status}
                                    setStatus={setStatus}
                                    setUserId={setUserId}
                                />

                            </Wrapper>

                        </Wrapper>
                    </>
                }

                {loading && <Loading />}
            </Container>
        : 
            <></>
    );
};

export default Present;

const Container = styled.section`
    height: calc(var(--vh, 1vh) * 100);
    overflow-x: hidden;
    width: 100%;
    padding-bottom : 150px;
`;

const BottomWrap = styled.div`
    width: 100%;
    max-width: 900px;
    transform : translate(-50%, 0);
    display: flex;
    justify-content: space-between;
    padding: 12px 24px;
    border-top: 1px solid ${colors.Gr01};
    background-color: ${colors.White};
    position: fixed;
    bottom : 0;
    left : 50%;
    z-index: 10;
`;

const Popup = styled.div`
    position: fixed;
    display: flex;
    justify-content: center;
    text-align: center;
    z-index: 99;
    bottom: 48px;
    left: 50%;
    transform: translate(-50%, 0);
    width: 320px;
    height: 44px;
    background-color: rgba(0, 0, 0, 0.7);
    border-radius: 8px;
`;

const TextareaContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 150px;
    border-radius: 8px;
`;

const TextArea = styled.textarea`
    display: inline-flex;
    width: 100%;
    height: 100%;
    border: none;
    border-radius: 8px;
    resize: none;
    line-height: 22px;
    letter-spacing: -0.02em;
    font-size: 1.6rem;
    font-weight: 500;

    overflow: auto;
    outline: none;

    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;

    &::placeholder {
        color: ${colors.Gr03};
    }
`;

const IntroText = styled.div`
    flex: 1;
    display : inline-flex;
    align-items : flex-end;
    justify-content : flex-end;
    width: 100%;
    padding: 8px 12px;
    color: ${colors.Gr03};
    line-height: 20px;
    letter-spacing: -0.02em;
    font-size: 1.3rem;
    font-weight: 500;
`;

const NewCrew = styled.div`
    z-index: 100;
    cursor : pointer;
    border : .2rem solid ${colors.Bl07};
    width : 3.3rem;
    height : 3.3rem;
    border-radius: 50%;
    display: inline-flex;
    align-items: center;
    justify-content: center;

    img {
        width : 3rem;
        display: inline-block;
    }
`;