import React, { useEffect, useState } from "react";
import styled from "styled-components";
// import { TimePicker } from "react-ios-time-picker";
import TimePicker from "rc-time-picker";
import "rc-time-picker/assets/index.css";
import moment from "moment";
import { useParams, useNavigate } from "react-router-dom";
import axios from "axios";

// components
import Header from "../components/basic/Header";
import { GetOne } from "../tool/localstorage";
import { getDayOfWeek } from "../tool/date";
import { LoadingSpinner } from "../tool/loading";

const W = window.document.documentElement.clientWidth;
const H = window.innerHeight;

const DesignSize = H / 812;

function AddSchedule() {
  const [loading, setLoading] = useState(true);

  const Params = useParams();
  const navigate = useNavigate();

  const Year = Params.y;
  const Month = Params.m;
  const Date = Params.d;

  const [info, setInfo] = useState({
    title: "",
    start_time: "00:00",
    end_time: "00:00",
    memo: "",
  });

  const [pickTime, setPickTime] = useState({
    start: moment(),
    end: moment(),
  });

  const ScheduleSave = () => {
    setLoading(true);

    var token = GetOne("token");
    const frm = new FormData();

    frm.append("mode", "user_schedule");
    frm.append("token", token);
    frm.append("year", Year);
    frm.append("month", Month);
    frm.append("date", Date);
    frm.append("title", info.title);
    frm.append("start_time", info.start_time);
    frm.append("finish_time", info.end_time);
    frm.append("memo", info.memo);

    axios
      .post("https://ai.semos.kr/semos_partner/schedule/data", frm, {
        headers: { "Content-Type": "multipart/form-data" },
      })
      .then((data) => {
        var Value = data.data;
        var Status = Value.status;
        var StatusMessage = Value.status_message;

        if (Status == "success") {
          setLoading(false);
          window.alert(StatusMessage);
          navigate("/my_calender", { replace: true });
        } else {
          setLoading(false);
          window.alert(StatusMessage);
        }
      });
  };

  const handleChange = (value, type) => {
    setPickTime((prev) => ({ ...prev, [type]: value }));
  };

  useEffect(() => {
    var start = document.getElementById("start").value;
    var end = document.getElementById("end").value;

    setInfo((prev) => ({ ...prev, start_time: start, end_time: end }));
    setLoading(false);
  }, [pickTime]);

  const defaultTime = (h, m) => {
    return moment().set("hour", h).set("minute", m);
  };

  return (
    <Container>
      <Header title="일정 추가하기" back={true} />
      <Styler
        pt={24 * DesignSize}
        pr={24}
        pb={24 * DesignSize}
        pl={24}
        h={H - 50.55}
        flex={true}
        direction="column"
        justify="space-between"
      >
        <ScheduleContainerRevise>
          <SelectDate>{`선택 된 날짜 : ${Month}월 ${Date}일 ${getDayOfWeek(
            `${Year}-${Month}-${Date}`
          )}요일`}</SelectDate>

          <FirstLine>
            <DotContainer>
              <GreyDot />
            </DotContainer>
            <SemosActivityTitleInput
              value={info.title}
              placeholder="일정 제목을 입력해 주세요."
              onChange={(e) =>
                setInfo((prev) => ({ ...prev, title: e.target.value }))
              }
            />
          </FirstLine>

          <Divider h={10} />

          <OpenedContainer>
            <TimeSelection>
              <StartTimePicker>
                <StartText>시작</StartText>
                <StartTimePick
                  id="start"
                  inputReadOnly={true}
                  onChange={(time) => handleChange(time, "start")}
                  defaultValue={defaultTime("00", "00")}
                  showSecond={false}
                  minuteStep={15}
                />
              </StartTimePicker>
              <DividingBar>|</DividingBar>
              <EndTimePicker>
                <EndText>종료</EndText>
                <StartTimePick
                  id="end"
                  defaultValue={defaultTime("00", "00")}
                  inputReadOnly={true}
                  onChange={(time) => handleChange(time, "end")}
                  showSecond={false}
                  minuteStep={15}
                />
              </EndTimePicker>
            </TimeSelection>

            <Divider h={16} />

            <MemoContainer
              placeholder="일정 상세 내용을 작성해 주세요!"
              value={info.memo}
              onChange={(e) =>
                setInfo((prev) => ({ ...prev, memo: e.target.value }))
              }
            />
          </OpenedContainer>
        </ScheduleContainerRevise>
        <SaveButton
          active={info.title}
          onClick={() => info.title && ScheduleSave()}
        >
          {info.title ? "저장하기" : "제목을 작성해 주세요!"}
        </SaveButton>
      </Styler>

      <LoadingSpinner
        loading={loading}
        title={`정보를 확인하고 있습니다.`}
        content={`잠시만 기다려 주세요!`}
      />
    </Container>
  );
}

export default AddSchedule;

const Container = styled.section`
  height: calc(var(--vh, 1vh) * 100);
  overflow-x: hidden;
`;

const Styler = styled.div`
  position: relative;
  display: ${({ flex }) => (flex ? "flex" : "")};
  flex-direction: ${({ direction }) => direction};
  justify-content: ${({ justify }) => justify};
  padding-top: ${({ pt }) => pt}px;
  padding-bottom: ${({ pb }) => pb}px;
  padding-left: ${({ pl }) => pl}px;
  padding-right: ${({ pr }) => pr}px;
  height: ${({ h }) => h}px;
`;

const DotContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 7px;
  height: 20px;
  margin-right: 4px;
`;

const GreyDot = styled.div`
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background-color: #818792;
`;

const ScheduleContainerRevise = styled.section`
  padding-bottom: 24px;
  margin-bottom: 32px;
`;

const FirstLine = styled.div`
  display: flex;
  align-items: center;
`;

const SemosActivityTitleInput = styled.input`
  cursor: text;
  border: 1px solid #dcdcdc;
  padding: 5px 8px;
  width: ${W - 72}px;
  border-radius: 4px;
  height: ${(W - 72) / 10}px;
  font-weight: 500;
  font-size: 14px;
  line-height: 142%;
  display: flex;
  align-items: center;
  letter-spacing: -0.02em;

  &::placeholder {
    color: #acafb5;
  }
`;

const OpenedContainer = styled.div`
  margin-left: 24px;
`;

const Divider = styled.div`
  height: ${({ h }) => h}px;
`;

const TimeSelection = styled.div`
  display: flex;
  align-items: center;
`;

const StartTimePicker = styled.div`
  display: flex;
`;

const StartText = styled.div`
  font-weight: 500;
  font-size: 12px;
  line-height: 142%;
  display: flex;
  align-items: center;
  letter-spacing: -0.02em;
  color: #acafb5;
  margin-top: 1px;
`;

const DividingBar = styled.div`
  font-size: 9px;
  margin: 0 12px;
  color: #acafb5;
`;

const EndTimePicker = styled(StartTimePicker)``;

const EndText = styled(StartText)``;

const StartTimePick = styled(TimePicker)`
  width: 64px;
  margin-left: 9px;

  &::placeholder {
    color: #acafb5;
  }
`;

const MemoContainer = styled.textarea`
  background-color: #f9f9f9;
  outline: none;
  resize: none;
  border: none;
  border-radius: 8px;
  width: 100%;
  height: 180px;
  padding: 14px 18px;
  font-weight: 500;
  font-size: 12px;
  line-height: 142%;
  letter-spacing: -0.02em;
  color: #3c3e41;

  &::placeholder {
    color: #acafb5;
  }
`;

const SaveButton = styled.div`
  padding: 16.5px 0;
  background-color: ${({ active }) => (active ? "#0e6dff" : "#DCDCDC")};
  border-radius: 8px;
  font-weight: 600;
  font-size: 16px;
  line-height: 142%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  letter-spacing: -0.02em;
  color: #ffffff;
  cursor: ${({ active }) => (active ? "pointer" : "default")};
`;

const SelectDate = styled.div`
  width: 100%;
  display: inline-flex;
  margin-bottom: 20px;
  font-size: 14px;
  color: #acafb5;
`;
