import React, { useState, useEffect } from "react";
import styled from "styled-components";
import axios from "axios";
import { useNavigate } from "react-router-dom";

//component
import BackHeader from "../components/signup/back_header_for_signup";
import SignUpFirst from "../components/signup/signup_first";
import SignUpSecond from "../components/signup/signup_second";
import PartnerRuleService from "../components/signup/PartnerRuleService";
import PartnerRuleInformation from "../components/signup/PartnerRuleInformation";
import { GetOne, SaveMany } from "../tool/localstorage";
import { LoadingSpinner } from "../tool/loading";
import { fbevent } from "../tool/firebase";

const W = window.document.documentElement.clientWidth;
const H = window.innerHeight;

const DesignSize = H / 812;

function SignUp() {
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);

  const Email = GetOne("email") ? GetOne("email") : "";
  const Token = GetOne("token") ? GetOne("token") : "";
  const NickName = GetOne("nickname") ? GetOne("nickname") : "";
  const Name = GetOne("name") ? GetOne("name") : "";
  const Age = GetOne("age") ? GetOne("age") : "";
  const Gender = GetOne("gender") ? GetOne("gender") : "3";
  const PhoneSplit = GetOne("phone")?.split("-")
    ? GetOne("phone")?.split("-")
    : "";
  const PhoneOg = GetOne("phone") ? GetOne("phone") : "";
  var Phone = "";

  for (var i = 0; i < PhoneSplit?.length; i++) {
    var content = PhoneSplit[i];
    Phone = `${Phone}${content}`;
  }

  const [rule1, setRule1] = useState(false);
  const [rule2, setRule2] = useState(false);
  const [page, setPage] = useState(2);
  // const [page, setPage] = useState(1);
  const [status, setStatus] = useState({
    nickname:
      NickName != "가입미완" && NickName != "null" && NickName
        ? "ok"
        : "default",
    age: Age != "가입미완" && Age != "null" && Age ? "ok" : "default",
    gender:
      Gender != "가입미완" && Gender != "null" && Gender ? "ok" : "default",
    name: Name != "가입미완" && Name != "null" && Name ? "ok" : "default",
    phone:
      PhoneOg != "가입미완" && PhoneOg != "null" && PhoneOg?.length > 10
        ? "ok"
        : "default",
    auth:
      PhoneOg != "가입미완" && PhoneOg != "null" && PhoneOg?.length > 10
        ? "ok"
        : "default",
  });

  const [userInfo, setUserInfo] = useState({
    nickname: NickName != "가입미완" && NickName != "null" ? NickName : "",
    age: Age != "가입미완" && Age != "null" ? Age : "",
    gender: Gender != "가입미완" && Gender != "null" ? Gender : "",
    name: Name != "가입미완" && Name != "null" ? Name : "",
    phone: PhoneOg != "가입미완" && PhoneOg != "null" ? PhoneOg : "",
    auth: "",
  });

  const [focus, setFocus] = useState({
    nickname: false,
    age: false,
    gender: false,
    name: false,
    phone: false,
    auth: false,
  });

  const SetName = () => {
    let pattern = /^[가-힣a-zA-Z]{2,15}$/i;
    let NameText = userInfo.name;

    if (NameText.length > 1) {
      if (pattern.test(NameText)) {
        setStatus((prev) => ({ ...prev, name: "ok" }));
      } else {
        setStatus((prev) => ({ ...prev, name: "denied" }));
      }
    } else if (NameText.length === 1) {
      setStatus((prev) => ({ ...prev, name: "black" }));
    } else {
      setStatus((prev) => ({ ...prev, name: "default" }));
    }
  };

  useEffect(() => {
    fbevent("B2B_Signup_Page_View", {
      email : GetOne("email")
    });
  }, []);

  useEffect(() => {
    if (userInfo.age) {
      setStatus((prev) => ({ ...prev, age: "ok" }));
    } else {
      setStatus((prev) => ({ ...prev, age: "default" }));
    }

    if (userInfo.gender) {
      setStatus((prev) => ({ ...prev, gender: "ok" }));
    } else {
      setStatus((prev) => ({ ...prev, gender: "default" }));
    }
  }, [userInfo]);

  const focusChanger = (type, value) => {
    setFocus((prev) => ({ ...prev, [type]: value }));
  };

  const nextFunction = async () => {
    if (
      page == 1 &&
      userInfo.nickname.length < 16 &&
      status.nickname == "ok" &&
      status.age == "ok" &&
      status.gender == "ok"
    ) {
      setPage(2);
    } else if (
      page === 2 &&
      status.name == "ok" &&
      status.phone === "ok" &&
      status.auth === "ok"
    ) {
      //setPage(1);
      setLoading(true);
      const frm = new FormData();
      frm.append("mode", "register");
      frm.append("nickname", userInfo.nickname);
      frm.append("age", userInfo.age);
      frm.append("gender", userInfo.gender);
      frm.append("name", userInfo.name);
      frm.append("phone", userInfo.phone);
      frm.append("token", Token);

      axios
        .post("https://ai.semos.kr/semos_partner/auth/login", frm, {
          headers: {
            "Content-Type": "multipart/form-data",
            Accept: "application/json",
          },
        })
        .then((data) => {
          var Value = data.data;
          var Status = Value.status;
          var StatusMessage = Value.status_message;
          var Nickname = Value.nickname;
          var Name = Value.name;
          var Age = Value.age;
          var Phone = Value.phone;
          var Gender = Value.gender;

          if (Status === "success") {
            window.alert(StatusMessage);

            var LocalStorageData = [
              { type: "nickname", value: Nickname },
              { type: "name", value: Name },
              { type: "age", value: Age },
              { type: "phone", value: Phone },
              { type: "gender", value: Gender },
            ];
            setLoading(false);
            if (SaveMany(LocalStorageData)) {
              window.location.replace("/partner_tip");
              // window.location.replace("/partner_register_account");
            }
          } else {
            setLoading(false);
            window.alert(StatusMessage);
          }
        });
    }
  };

  return (
    <Container>
      <BackHeader title="간편 30초 가입" page={page} setPage={setPage} />
      <Styler
        pl={24}
        pr={24}
        flex={true}
        direction={"column"}
        justify={"space-between"}
      >
        <div>
          <Divider h={34} />
          <SignUpIndexTitleContainer>
            <SignUpIndexTitle>
              {page == 1
                ? "1. 원활한 세모스 사용을 위한 정보 기입"
                : page == 2
                ? "2. 안전한 세모스 이용을 위한 본인 인증"
                : ""}
            </SignUpIndexTitle>
            <SignUpIndexContainer>
              <SignUpIndex>{page}</SignUpIndex>
              <SignUpIndexTotal>/2</SignUpIndexTotal>
            </SignUpIndexContainer>
          </SignUpIndexTitleContainer>
          <Divider h={page == 1 ? 51 : 28} />

          <SignUpFirst
            page={page}
            status={status}
            setStatus={setStatus}
            userInfo={userInfo}
            setUserInfo={setUserInfo}
            focus={focus}
            setFocus={setFocus}
            focusChanger={focusChanger}
          />

          <SignUpSecond
            page={page}
            status={status}
            setStatus={setStatus}
            userInfo={userInfo}
            setUserInfo={setUserInfo}
            SetName={SetName}
          />
        </div>
        <div>
          {page == 1 && (
            <>
              <WordBallon>이제 딱 한 단계 남았어요!</WordBallon>
              <div
                style={{
                  backgroundColor: "transparent",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Triangle />
              </div>
            </>
          )}

          {page == 2 && (
            <PolicyContainer>
              <Policy>
                <u onClick={() => setRule2(true)}>개인정보처리방침</u>,{" "}
                <u onClick={() => setRule1(true)}>이용약관</u>
              </Policy>

              <Policy>
                위 내용을 모두 확인하였으며, 회원가입에 동의합니다.
              </Policy>
            </PolicyContainer>
          )}

          <NextButton
            page={page}
            status={status}
            onClick={() => nextFunction()}
          >
            {page == 1 ? "다음 단계로" : page == 2 ? "🎉 가입 완료! 🎉" : ""}
          </NextButton>
          <Divider h={42} />
        </div>
      </Styler>

      {rule1 && <PartnerRuleService setRule={setRule1} />}
      {rule2 && <PartnerRuleInformation setRule={setRule2} />}

      <LoadingSpinner
        loading={loading}
        title={`정보를 확인하고 있습니다.`}
        content={`잠시만 기다려 주세요!`}
      />
    </Container>
  );
}

export default SignUp;

const Container = styled.div`
  height: calc(var(--vh, 1vh) * 100);
  overflow-x: hidden;
`;

const Divider = styled.div`
  height: ${({ h }) => h * DesignSize}px;
`;

const Styler = styled.div`
  height: ${H - 49.55}px;
  display: ${({ flex }) => (flex ? "flex" : "")};
  flex-direction: ${({ direction }) => direction};
  justify-content: space-between;
  align-items: ${({ align }) => align};
  /* justify-content: ${({ justify }) => justify}; */
  padding-top: ${({ pt }) => pt}px;
  padding-bottom: ${({ pb }) => pb}px;
  padding-left: ${({ pl }) => pl}px;
  padding-right: ${({ pr }) => pr}px;
`;

const SignUpIndexTitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const SignUpIndexTitle = styled.div`
  font-size: 16px;
  font-weight: 400;
  line-height: 21.44px;
  color: #0e6dff;
  font-family: "GmarketSans" !important;
`;

const SignUpIndexContainer = styled.div`
  font-size: 16px;
  font-weight: 400;
  line-height: 21.44px;
`;

const SignUpIndex = styled.span`
  color: #0e6dff;
  font-family: "GmarketSans" !important;
`;

const SignUpIndexTotal = styled.span`
  color: #acafb5;
  font-family: "GmarketSans" !important;
`;

const NextButton = styled.div`
  width: 100%;
  padding: 16.5px 0;
  border: 1px solid #dcdcdc;
  border-radius: 8px;
  align-self: baseline;
  font-size: 16px;
  font-weight: 600;
  line-height: 22.72px;
  text-align: center;
  color: ${({ page, status }) =>
    (page == 1 &&
      status.nickname == "ok" &&
      status.age == "ok" &&
      status.gender == "ok") ||
    (page == 2 &&
      status.name == "ok" &&
      status.phone == "ok" &&
      status.auth == "ok")
      ? "#ffffff"
      : "#acafb5"};
  background-color: ${({ page, status }) =>
    (page == 1 &&
      status.nickname == "ok" &&
      status.age == "ok" &&
      status.gender == "ok") ||
    (page == 2 &&
      status.name == "ok" &&
      status.phone == "ok" &&
      status.auth == "ok")
      ? "#0E6DFF"
      : "#f5f5f5"};
`;

const WordBallon = styled.div`
  /* width: 100%; */
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 22px;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
  border-radius: 19px;
  padding: 8px 0;
  color: #0e6dff;
  font-family: "GmarketSans" !important;
  font-size: 12px;
  font-weight: 400;
`;

const Triangle = styled.div`
  width: 0;
  height: 0;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-top-color: white;
  margin-bottom: 5px;
`;

const PolicyContainer = styled.div`
  font-weight: 500;
  font-size: 14px;
  line-height: 142%;
  color: #acafb5;
  letter-spacing: -0.02em;
  margin-bottom: 20px;
`;

const Policy = styled.div`
  margin-bottom: 4px;
`;
