import React, { useState, useEffect } from "react";
import styled from "styled-components";
import axios from "axios";
import { useLocation, useNavigate } from "react-router-dom";

// component
import PaymentFinish from "../components/reservationManage/payment_finished";
import ActivityConfirm from "../components/reservationManage/activity_confirm";
import PaidFinished from "../components/reservationManage/paid_finished";
import ActivityCancel from "../components/reservationManage/activity_cancel";
import Header from "../components/basic/Header";
import Toast from "../components/elements/Toast";

// tool
import { GetOne } from "../tool/localstorage";
import { kakaoChannel } from "../tool/kakao";
import { LoadingSpinner } from "../tool/loading";
import { fbevent } from "../tool/firebase";

// style
import { PC, W } from "../styles/globalStyles";
import { Wrapper, Text } from "../components/layouts/layoutStorage";
import colors from "../styles/colors";

//icon
import FocusPayment from "../icon/paymentFinishFocus.png";
import FocusConfirm from "../icon/activityConfirmFocus.png";
import FocusActivityFinish from "../icon/activityFinishFocus.png";
import FocusCancel from "../icon/cancelFocus.png";
import DefaultPayment from "../icon/paymentFinishDefault.png";
import DefaultConfirm from "../icon/activityConfirmDefault.png";
import DefaultActivityFinish from "../icon/activityFinishDefault.png";
import DefaultCancel from "../icon/cancelDefault.png";
import FilterArrow from "../icon/filterArrow.png";
import PrevMonthIcon from "../icon/prevMonth.png";
import NextMonthIcon from "../icon/nextMonth.png";
import NoResult from "../icon/noSchedule.png";
import Faq from "../icon/faqIcon.png";
import ClientCenter from "../icon/clientCenterIcon.png";
import NavArrow from "../icon/navigationArrow.png";

const OPTIONS = [
  {
    name: "예약 대기",
    focus_src: FocusPayment,
    default_src: DefaultPayment,
  },
  {
    name: "예약 확정",
    focus_src: FocusConfirm,
    default_src: DefaultConfirm,
  },
  {
    name: "이용 완료",
    focus_src: FocusActivityFinish,
    default_src: DefaultActivityFinish,
  },
  /*{
    name: "정산 완료",
    focus_src: FocusPaid,
    default_src: DefaultPaid,
  },*/
  {
    name: "취소 내역",
    focus_src: FocusCancel,
    default_src: DefaultCancel,
  },
];

const OPTIONS_COUNT = {
  "예약 대기" : 0,
  "예약 확정" : 1,
  "이용 완료" : 2,
  "정산 완료" : 3,
  "취소 내역" : 3
};

const BarLength = (W - 48 - 5) / OPTIONS?.length;

function ReservationManage() {
  const navigate = useNavigate();
  const location = useLocation();

  const [selected, setSelected] = useState('예약 대기');
  const [loading, setLoading] = useState(true);
  const [reservation, setReservation] = useState();
  const [amount, setAmount] = useState();
  const [doneSport, setDoneSport] = useState();
  const [paySport, setPaySport] = useState();
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [openCancelToast, setOpenCancelToast] = useState(false);
  const [filter, setFilter] = useState({
    payment_type: false,
    activity_finish: false,
    payment_finish: false,
  });
  const [filterText, setFilterText] = useState({
    payment_type: "전체",
    activity_finish: "전체",
    payment_finish: "전체",
  });
  const [paymentDate, setPaymentDate] = useState({
    year: new Date().getFullYear(),
    month: new Date().getMonth() + 1 < 10 ? `0${new Date().getMonth() + 1}` : `${new Date().getMonth() + 1}`,
  });

  useEffect(() => {
    fbevent("B2B_Reservation_Page_View", {
      email : GetOne("email")
    });

    getData();
    //setSelected(location.state ? (location.state.index ? location.state.index : 0) : 0);
  }, []);

  // 예약 거절 TOAST MODAL
  useEffect(() => {
    if (!loading) {
      setOpenCancelToast(location.state ? location.state.openCancelToast : false);
      setTimeout(() => {
        setOpenCancelToast(false);
      }, 2000);
    }
  }, [loading]);

  // GET DATA
  const getData = () => {
    const token = GetOne("token");

    const frm = new FormData();

    frm.append("mode", "reservation_list");
    frm.append("token", token);

    axios
      .post("https://ai.semos.kr/semos_partner/v3/profile/Schedule", frm, {
        headers: { "Content-Type": "multipart/form-data" },
      })
      .then((data) => {
        const Value = data.data;
        const Status = Value.status;
        const StatusMessage = Value.status_message;
        const ReservationList = Value.reservation_list;
        const DoneSport = Value.sport_list;
        const PaySport = Value.sport_pay_list;
        const Amount = Value.amount;

        if (Status == "success") {
          setReservation(ReservationList);
          setDoneSport(DoneSport);
          setPaySport(PaySport);
          setAmount(Amount);
          setLoading(false);
        } else {
          setLoading(false);
          window.alert(StatusMessage);
        }
      });
  };

  const menuChange = (value) => {
    setSelected(value);

    fbevent("B2B_Reservation_Menu_Click", {
      category : value
    });
  };

  const MonthHandler = (type) => {
    if (type == "prev") {
      if (paymentDate.month !== "01") {
        const lastmonth = parseInt(paymentDate.month) - 1 < 10 ? `0${parseInt(paymentDate.month) - 1}` : `${parseInt(paymentDate.month) - 1}`;
        setPaymentDate((prev) => ({ ...prev, month: lastmonth }));
      } else {
        setPaymentDate({ year: paymentDate.year - 1, month: 12 });
      }
    } else {
      if (paymentDate.month !== "12") {
        const nextmonth = parseInt(paymentDate.month) + 1 < 10 ? `0${parseInt(paymentDate.month) + 1}` : `${parseInt(paymentDate.month) + 1}`;
        setPaymentDate((prev) => ({ ...prev, month: nextmonth }));
      } else {
        setPaymentDate({ year: paymentDate.year + 1, month: "01" });
      }
    }
  };

  const CheckData = reservation && reservation.check;
  const ConfirmData = reservation && reservation.confirm;
  const DoneData = reservation && reservation.done;
  const PayData = reservation && reservation.pay;
  const CancelData = reservation && reservation.cancel;

  const CheckList = (data, index) => {
    let checkList = [];

    const SplitYMD = data[0].split(".");
    const YMDData = `${SplitYMD[0]}년 ${SplitYMD[1]}월 ${SplitYMD[2]}일`;
    const ReserveList = data[1];

    checkList.push(
      <Styler key={`YMD_check_${index}`} pl={24} pr={24} pt={24}>
        <DateDivider>
          <Line />
          <DateBox>{YMDData}</DateBox>
        </DateDivider>
      </Styler>
    );

    for (let ic2 = 0; ic2 < ReserveList.length; ic2++) {
      checkList.push(<PaymentFinish modalIsOpen={modalIsOpen} setModalIsOpen={setModalIsOpen} data={ReserveList[ic2]} key={`${ic2}_${index}_info_check`} />);
    }

    return checkList;
  };

  const ConfirmList = (data, index) => {
    let confirmList = [];

    const SplitYMD = data[0].split(".");
    const YMDData = `${SplitYMD[0]}년 ${SplitYMD[1]}월 ${SplitYMD[2]}일`;
    const ReserveList = data[1];

    confirmList.push(
      <Styler key={`YMD_confirm_${index}`} pl={24} pr={24} pt={24}>
        <DateDivider>
          <Line />
          <DateBox>{YMDData}</DateBox>
        </DateDivider>
      </Styler>
    );

    for (let ic2 = 0; ic2 < ReserveList.length; ic2++) {
      const num = ReserveList[ic2].number;

      confirmList.push(
        <ActivityConfirm data={ReserveList[ic2]} modalIsOpen={modalIsOpen} setModalIsOpen={setModalIsOpen} key={`${ic2}_${index}_info_confirm`} id={num} />
      );
    }

    return confirmList;
  };

  const DoneList = (data, index) => {
    /*let doneList = [];

    let SplitYMD = data[0].split(".");
    let YMDData = `${SplitYMD[0]}년 ${SplitYMD[1]}월 ${SplitYMD[2]}일`;
    let ReserveList = data[1];

    doneList.push(
      <Styler key={`YMD_done_${index}`} pl={24} pr={24} pt={24} pb={4}>
        <DateDivider>
          <Line />
          <DateBox>{YMDData}</DateBox>
        </DateDivider>
      </Styler>
    );

    for (let ic2 = 0; ic2 < ReserveList.length; ic2++) {
      doneList.push(<ActivityFinished data={ReserveList[ic2]} key={`${ic2}_${index}_info_done`} />);
    }

    return doneList;*/

    let payList = [];

    const SplitYMD = data[0].split(".");
    const YMDData = `${SplitYMD[0]}년 ${SplitYMD[1]}월 ${SplitYMD[2]}일`;
    const ReserveList = data[1];

    if (paymentDate.year == SplitYMD[0] && paymentDate.month == SplitYMD[1]) {
      for (let ic2 = 0; ic2 < ReserveList.length; ic2++) {
        payList.push(<PaidFinished data={ReserveList[ic2]} key={`${ic2}_${index}_info_confirm`} />);
      }

      return payList;
    }
  };

  const PayList = (data, index) => {
    let payList = [];

    const SplitYMD = data[0].split(".");
    const YMDData = `${SplitYMD[0]}년 ${SplitYMD[1]}월 ${SplitYMD[2]}일`;
    const ReserveList = data[1];

    if (paymentDate.year == SplitYMD[0] && paymentDate.month == SplitYMD[1]) {
      for (let ic2 = 0; ic2 < ReserveList.length; ic2++) {
        payList.push(<PaidFinished data={ReserveList[ic2]} key={`${ic2}_${index}_info_confirm`} />);
      }

      return payList;
    }
  };

  const CancelList = (data, index) => {
    let cancelList = [];

    const SplitYMD = data[0].split(".");
    const YMDData = `${SplitYMD[0]}년 ${SplitYMD[1]}월 ${SplitYMD[2]}일`;
    const ReserveList = data[1];

    cancelList.push(
      <Styler key={`YMD_check_${index}`} pl={24} pr={24} pt={24}>
        <DateDivider>
          <Line />
          <DateBox>{YMDData}</DateBox>
        </DateDivider>
      </Styler>
    );

    for (let ic2 = 0; ic2 < ReserveList.length; ic2++) {
      cancelList.push(<ActivityCancel data={ReserveList[ic2]} key={`${ic2}_${index}_info_check`} />);
    }

    return cancelList;
  };

  const menuTop = document.getElementById("tab")?.offsetTop;

  useEffect(() => {
    let scroll = document.getElementById("scroll");

    scroll.scroll({
      top: menuTop ? menuTop - 50 : 0,
      left: 0,
      behavior: "smooth",
    });
  }, [menuTop]);

  return (
    <Container id="scroll">
      <Header title="예약 관리" back={true} border={false} handleClick={() => navigate("/home")} />

      {/*selected == 3 && AmountPopup()*/}

      {/* <HorizontalCalender schedule={schedule} /> */}
      {!modalIsOpen && (
        <MenuTab id="tab">
          <Relative>
            {OPTIONS.map((menu, index) => (
              <MenuItem key={`${menu.name.toString()}_menu`} focus={menu.name === selected} onClick={() => menuChange(menu.name)} index={index}>
                <MenuIcon src={menu.name == selected ? menu.focus_src : menu.default_src} />
                {menu.name}
              </MenuItem>
            ))}
            <BlueBar index={OPTIONS_COUNT[selected]} />
          </Relative>
        </MenuTab>
      )}
      <Min>
        <div>
          {selected == '예약 대기' &&
            CheckData &&
            (Object.keys(CheckData).length ? (
              Object.entries(CheckData).map((el, i) => CheckList(el, i))
            ) : (
              <NoContainer>
                <NoData src={NoResult} />
                내역이 생기면 알려드려요!
              </NoContainer>
            ))}
          {selected == '예약 확정' &&
            ConfirmData &&
            (Object.keys(ConfirmData).length ? (
              Object.entries(ConfirmData).map((el, i) => ConfirmList(el, i))
            ) : (
              <NoContainer>
                <NoData src={NoResult} />
                내역이 생기면 알려드려요!
              </NoContainer>
            ))}
          {/*selected == '이용 완료' &&
            DoneData &&
            (Object.keys(DoneData).length ? (
              Object.entries(DoneData)
                .reverse()
                .map((el, i) => DoneList(el, i))
            ) : (
              <NoContainer>
                <NoData src={NoResult} />
                내역이 생기면 알려드려요!
              </NoContainer>
            ))
          */}

          {(selected == '이용 완료' && DoneData) &&
            (Object.keys(DoneData)?.length ? (
              <>
                <Styler pt={12} pb={12} pl={24} pr={24}flex={true} justify="space-between" align="center">
                  <MonthButton src={PrevMonthIcon} onClick={() => MonthHandler("prev")} />
                  <MonthSelectorYM>{`${paymentDate.year}년 ${paymentDate.month}월`}</MonthSelectorYM>
                  <MonthButton src={NextMonthIcon} onClick={() => MonthHandler("next")} />
                </Styler>

                {/*<Styler style={{width : 'calc(100% - 48px', height : "1px", margin : '0 24px', backgroundColor : colors.Gr01 }} />

                <ItemDivider>
                  <Styler pl={24} pr={24} pb={12} pt={12} flex={true} align="center" justify="space-between">
                    {<div></div>}

                    <Filter
                      onClick={() =>
                        setFilter((prev) => ({
                          ...prev,
                          payment_finish: !filter.payment_finish,
                        }))
                      }
                    >
                      <FilterText>{(filterText.payment_finish == "전체") ? "스포츠 전체" : filterText.payment_finish}</FilterText>
                      <FilterIcon src={FilterArrow} open={filter.payment_finish} />
                    </Filter>
                    {filter?.payment_finish && (
                      <FilterBox2>
                        {paySport.map((el, i) => (
                          <FilterItem
                            key={`${i}_pay_sport`}
                            onClick={() => {
                              setFilterText((prev) => ({
                                ...prev,
                                payment_finish: el,
                              }));

                              setFilter((prev) => ({
                                ...prev,
                                payment_finish: false,
                              }));
                            }}
                          >
                            {el}
                          </FilterItem>
                        ))}
                      </FilterBox2>
                    )}
                  </Styler>
                </ItemDivider>*/}

                {
                  (amount[`${paymentDate.year}.${paymentDate.month}`]?.all > 0) ?
                    <>
                      <Wrapper margin={'0 24px'} padding={`12px 0`} bb={`1px solid ${colors.Gr01}`} width="calc(100% - 24px)" jc="space-between">
                        <Text 
                            ta="center" 
                            size="14px" 
                            weight="500" 
                            lh="142%" 
                            ls="-0.28px" 
                            color={colors.Bl07}
                        >
                            {`총 금액 : ${amount[`${paymentDate.year}.${paymentDate.month}`]?.all_text}원 / ${amount[`${paymentDate.year}.${paymentDate.month}`]?.count}개`}
                        </Text>
                      </Wrapper>

                      {Object.entries(DoneData).map((el, i) => DoneList(el, i))}
                    </>

                  : 
                    <NoContainer2>
                      <NoData src={NoResult} />
                      내역이 생기면 알려드려요!
                    </NoContainer2>
                }
              </>
            ) : (
              <NoContainer>
                <NoData src={NoResult} />
                내역이 생기면 알려드려요!
              </NoContainer>
            ))
          }

          {(selected == '정산 완료' && PayData) &&
            (Object.keys(PayData)?.length ? (
              <>
                <Styler pt={24} pl={24} pr={24} pb={18} flex={true} justify="space-between" align="center">
                  <MonthButton src={PrevMonthIcon} onClick={() => MonthHandler("prev")} />
                  <MonthSelectorYM>{`${paymentDate.year}년 ${paymentDate.month}월`}</MonthSelectorYM>
                  <MonthButton src={NextMonthIcon} onClick={() => MonthHandler("next")} />
                </Styler>
                <ItemDivider>
                  <Styler pl={24} pr={24} pb={8} flex={true} align="center" justify="space-between">
                    {/*TierCheck()
                    <div></div>*/}

                    <Filter
                      onClick={() =>
                        setFilter((prev) => ({
                          ...prev,
                          payment_finish: !filter.payment_finish,
                        }))
                      }
                    >
                      <FilterText>{filterText.payment_finish == "전체" ? "액티비티 전체" : filterText.payment_finish}</FilterText>
                      <FilterIcon src={FilterArrow} open={filter.payment_finish} />
                    </Filter>
                    {filter.payment_finish && (
                      <FilterBox2>
                        {paySport.map((el, i) => (
                          <FilterItem
                            key={`${i}_pay_sport`}
                            onClick={() => {
                              setFilterText((prev) => ({
                                ...prev,
                                payment_finish: el,
                              }));
                              setFilter((prev) => ({
                                ...prev,
                                payment_finish: false,
                              }));
                            }}
                          >
                            {el}
                          </FilterItem>
                        ))}
                      </FilterBox2>
                    )}
                  </Styler>
                </ItemDivider>
                {Object.entries(PayData).map((el, i) => PayList(el, i))}
              </>
            ) : (
              <NoContainer>
                <NoData src={NoResult} />
                내역이 생기면 알려드려요!
              </NoContainer>
            ))
          }

          {/* {PayData && Object.entries(PayData).map((el, i) => PayList(el, i))} */}
          {selected == '취소 내역' &&
            CancelData &&
            (Object.keys(CancelData).length ? (
              Object.entries(CancelData)
                .reverse()
                .map((el, i) => CancelList(el, i))
            ) : (
              <NoContainer>
                <NoData src={NoResult} />
                내역이 생기면 알려드려요!
              </NoContainer>
            ))}
        </div>

        <Styler pl={24} pr={24} pt={24}>
          <AboutAppTitle>고객센터</AboutAppTitle>

          <Divider height={24} />

          <JustBetween onClick={() => navigate("/faq")}>
            <IconTextContainer>
              <AboutAppIcon src={Faq} />
              자주 묻는 질문
            </IconTextContainer>
            <AboutAppArrow src={NavArrow} />
          </JustBetween>

          <Divider height={24} />

          <JustBetween
            onClick={() => {
              if (window.document.documentElement.clientWidth > PC) {
                window.open("http://pf.kakao.com/_CBUHxj", "_blank");
              } else {
                kakaoChannel();
              }
            }}
          >
            <IconTextContainer>
              <AboutAppIcon src={ClientCenter} />
              고객센터 연결 <ClientCenterTime>10:00 ~ 19:00</ClientCenterTime>
            </IconTextContainer>
            <AboutAppArrow src={NavArrow} />
          </JustBetween>
        </Styler>
      </Min>

      <Styler h={OPTIONS_COUNT[selected] == 3 ? 180 : 100} />

      {/* <Footer /> */}

      {openCancelToast ? (
        <Toast width={"205px"}>
          <span>예약 거절을 완료했어요</span>
        </Toast>
      ) : (
        <></>
      )}

      <LoadingSpinner loading={loading} title={`정보를 확인하고 있습니다.`} content={`잠시만 기다려 주세요!`} />
    </Container>
  );
}

export default ReservationManage;

const Container = styled.section`
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  overflow-x: hidden;
`;

const Divider = styled.div`
  height: ${({ height }) => height && `${height}px`};
  background-color: ${({ color }) => color && `${color}`};
  width: 100%;
`;

const MenuTab = styled.div`
  position: sticky;
  top: 40px;
  padding: 0 24px;
  border-bottom: 2px solid #f5f5f5;
  border-top: 2px solid #f5f5f5;
  /* z-index: 3; */
  background-color: white;
  z-index: 2;
`;

const Relative = styled.div`
  position: relative;
  display: flex;
  width: ${(BarLength + 1) * OPTIONS.length}px;
`;

const MenuItem = styled.div`
  cursor: pointer;
  width: calc(100% / ${OPTIONS.length});
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-weight: 500;
  font-size: 10px;
  line-height: 132%;
  letter-spacing: -0.02em;
  padding: 19px 0;
  color: ${({ focus }) => (focus ? "#3383FF" : "#818792")};
  background-color: white;
  ${({ index }) => index == 1 && "margin-right: 5px"};
`;

const BlueBar = styled.div`
  width: ${BarLength}px;
  height: 2px;
  position: absolute;
  bottom: -1px;
  background-color: #0e6dff;
  left: ${({ index }) => ((index > 1) ? ((index * BarLength) + OPTIONS.length) : (index * BarLength))}px;
  transition: all 0.3s ease;

  /* border-radius: 20px; */
`;

const Styler = styled.div`
  position: relative;
  display: ${({ flex }) => (flex ? "flex" : "")};
  flex-direction: ${({ direction }) => direction};
  justify-content: ${({ justify }) => justify};
  padding-top: ${({ pt }) => pt}px;
  padding-bottom: ${({ pb }) => pb}px;
  padding-left: ${({ pl }) => pl}px;
  padding-right: ${({ pr }) => pr}px;
  height: ${({ h }) => h}px;
  width: ${({ w }) => w}%;
`;

const DateDivider = styled.div`
  position: relative;
  width: 100%;
`;

const Line = styled.div`
  width: 100%;
  height: 2px;
  background-color: #f5f5f5;
  margin: 12px 0;
`;

const DateBox = styled.div`
  position: absolute;
  top: -12px;
  left: ${(W - 48) / 2 - 67}px;
  padding: 4px 23px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 500;
  font-size: 12px;
  line-height: 142%;
  letter-spacing: -0.02em;
  color: #acafb5;
  background-color: #f5f5f5;
  border-radius: 16px;
`;

const MenuIcon = styled.img`
  width: 28px;
  height: 28px;
  margin-bottom: 5px;
`;

const Filter = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
`;

const FilterText = styled.div`
  font-weight: 500;
  font-size: 14px;
  /* line-height: 142%; */
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: -0.02em;
  color: ${colors.Gr05};
`;

const FilterIcon = styled.img`
  width: 16px;
  height: 16px;
  margin-left: 4px;
  transform: ${({ open }) => (open ? "rotate(-180deg)" : "rotate(0deg)")};
  transition: 0.5s all ease;
`;

const FilterBox = styled.div`
  position: absolute;
  top: 45px;
  right: 24px;
  border: 1px solid #dcdcdc;
  z-index: 3;
  background-color: white;
  width: 102px;
  border-radius: 8px;
`;

const FilterItem = styled.div`
  cursor: pointer;
  padding: 10px;
  font-weight: 500;
  font-size: 12px;
  line-height: 142%;

  letter-spacing: -0.02em;
  color: #818792;
  border-bottom: ${({ last }) => (last ? 0 : 1)}px solid #f5f5f5;

  text-align : right;
`;

const MonthButton = styled.img`
  cursor: pointer;
  width: 24px;
  height: 24px;
`;

const MonthSelectorYM = styled.div`
  font-weight: 600;
  font-size: 16px;
  /* line-height: 142%; */
  display: flex;
  align-items: center;
  letter-spacing: -0.02em;
  color: #1a1c1f;
`;

const PartnerRateIcon = styled.img`
  width: 30px;
  height: 30px;
  margin-right: 10px;
`;

const PartnerRateText = styled.div`
  width: 75%;
  font-weight: 500;
  font-size: 10px;
  line-height: 132%;
  display: flex;
  align-items: center;
  letter-spacing: -0.022em;
  color: ${({ color }) => color};
`;

const FilterBox2 = styled.div`
  position: absolute;
  top: 30px;
  right: 24px;
  border: 1px solid #dcdcdc;
  z-index: 3;
  background-color: white;
  width: 102px;
  border-radius: 8px;
`;

const ItemDivider = styled.div`
  border-bottom: 2px solid #f5f5f5;
`;

const NoData = styled.img`
  width: 20rem;
  height: 20rem;
  border: 1px solid #dcdcdc;
  border-radius: 50%;
  margin-bottom: 24px;
`;

const NoContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 88px;
  margin-bottom: 64px;

  font-weight: 600;
  font-size: 18px;
  line-height: 142%;
  /* identical to box height, or 26px */

  display: flex;
  align-items: center;
  letter-spacing: -0.02em;

  /* Greyscale / Dark Gr */

  color: #1a1c1f;
`;

const NoContainer2 = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 60px;
  margin-bottom: 44px;

  font-weight: 600;
  font-size: 18px;
  line-height: 142%;
  /* identical to box height, or 26px */

  display: flex;
  align-items: center;
  letter-spacing: -0.02em;

  /* Greyscale / Dark Gr */

  color: #1a1c1f;
`;

const IconTextContainer = styled.div`
  display: flex;
  align-items: center;
  font-weight: 600;
  font-size: 16px;
  line-height: 142%;
  letter-spacing: -0.02em;
  color: #3c3e41;
`;

const AboutAppIcon = styled.img`
  width: 20px;
  height: 20px;
  margin-right: 12px;
  margin-bottom: 2px;
`;

const AboutAppArrow = styled.img`
  width: 20px;
  height: 22px;
`;

const ClientCenterTime = styled.span`
  font-size: 14px;
  font-weight: 500;
  line-height: 142%;
  letter-spacing: -0.02em;
  color: #acafb5;
  margin-left: 12px;
`;

const JustBetween = styled.div`
  display: flex;
  justify-content: space-between;
  /* align-items: center; */
  align-items: flex-end;
  cursor: pointer;
`;

const AboutAppTitle = styled.div`
  font-weight: 500;
  font-size: 14px;
  line-height: 142%;
  display: flex;
  align-items: center;
  letter-spacing: -0.02em;
  color: #818792;
`;

const Min = styled.div``;