import React, { useState } from "react";
import styled from "styled-components";
import { useNavigate, useLocation } from "react-router-dom";

//component
import { Text, Wrapper } from "../layouts/layoutStorage";
import colors from "../../styles/colors";

//icon
import { ReactComponent as Close } from "../../resource/icon/close.svg";
import Header from "../basic/Header";

const ActivityHeader = ({ status, title, available, statusHandler }) => {
    const navigate = useNavigate();
    const { pathname } = useLocation();
    
    return (
        <StNewHeader padding="8px 16px 10px 16px" gap="4px" jc="space-between" width="100%">
            <Header title={title ? title : "액티비티 수정"} border={false}>
                <Close onClick={() => navigate(`/activity_manage`)} fill={colors.Gr06} width="24px" height="24px" style={{ cursor: "pointer" }} />

                {
                    available ?
                        <OnOff 
                            onClick={() => statusHandler()}
                            bgc={status == 1 ? `${colors.Bl07}` : `${colors.Red03}`}  
                        >
                            {status == 1 ? `활성` : `비활성`}
                        </OnOff>
                    : ""
                }

            </Header>
        </StNewHeader>
    );
};

export default ActivityHeader;

const StNewHeader = styled.div`
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 100%;
    background-color: ${colors.White};
    position: sticky;
    z-index: 10;
    top: 0;
`;

const OnOff = styled.div`
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    padding: 6px 10px;
    background-color: ${({ bgc }) => (bgc ? `${bgc}` : "#ffffff")};
    border: ${({ border }) => (border ? `${border}` : "none")};
    color : ${colors.White};
    font-size: 14px;
    font-weight: 600;
    cursor : pointer;
    align-self : center;
`;