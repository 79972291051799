import React, { useState, useEffect } from "react";
import styled from "styled-components";
import axios from "axios";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import Sheet from "react-modal-sheet";

// components
import Header from "../components/basic/Header";
import InfoBox from "../components/reservationDetail/info_box";
import EscrowCancel from "../components/reservationDetail/EscrowCancel";
import EscrowAfterPay from "../components/reservationDetail/escrow_after_pay";
import EscrowAfterConfirm from "../components/reservationDetail/escrow_after_confirm";
import EscrowAfterActivity from "../components/reservationDetail/escrow_after_activity";
import EscrowLast from "../components/reservationDetail/escrow_last";
import CancelModal from "../components/reservationManage/CancelModal";
import Toast from "../components/elements/Toast";
import { GetOne } from "../tool/localstorage";
import { LoadingSpinner } from "../tool/loading";
import { fbevent } from "../tool/firebase";

// style
import { W, H } from "../styles/globalStyles";
import colors from "../styles/colors";

// icon
import { ReactComponent as QuestionCircle } from "../resource/icon/question_circle.svg";
import Location from "../icon/locationIcon.png";
import PaymentTypeInfoModal from "../components/activityRegister/PaymentTypeInfoModal";

const TEXT = W - 48 - 96;
const SNAP = H < 740 ? 0.3 : 0.25;

const ReservationDetail = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const params = useParams();

  const [loading, setLoading] = useState(true);
  const [payment, setPayment] = useState([]);
  const [bottom, setBottom] = useState(false);
  const [openCancelModal, setOpenCancelModal] = useState(false);
  const [paymentModal, setPaymentModal] = useState(false);
  const [openToast, setOpenToast] = useState(false);

  useEffect(() => {
    fbevent("B2B_ReservationDetail_Page_View", {
      email : GetOne("email"),
      id : params.id
    });

    getData();
  }, []);

  useEffect(() => {
    if (!loading) {
      const pathFromReservation = location.state ? location.state.from : "";
      setOpenToast(pathFromReservation === "paymentModal");
      setTimeout(() => {
        setOpenToast(false);
      }, 2000);
    }
  }, [loading]);

  const getData = () => {
    setLoading(true);
    const token = GetOne("token");

    const frm = new FormData();

    frm.append("mode", "my_payment");
    frm.append("paymentKey", params.id);
    frm.append("token", token);

    axios
      .post("https://ai.semos.kr/semos_partner/schedule/data", frm, {
        headers: { "Content-Type": "multipart/form-data" },
      })
      .then((data) => {
        const value = data.data;
        const status = value.status;
        const statusMessage = value.status_message;
        const paymentInfo = value.payment_info;

        if (status === "success") {
          setPayment(paymentInfo);
          setLoading(false);
        } else {
          alert(statusMessage);
        }
      });
  };

  const activityCancel = (type) => {
    setLoading(true);
    const token = GetOne("token");

    const frm = new FormData();

    frm.append("mode", "sold_status");
    frm.append("number", params.id);
    frm.append("type", type);
    frm.append("token", token);

    axios
      .post("https://ai.semos.kr/semos_partner/auth/profile", frm, {
        headers: { "Content-Type": "multipart/form-data" },
      })
      .then((data) => {
        const Value = data.data;

        if (Value.status == "success") {


          window.alert(Value.status_message);
          if (type == "confirm") {
            fbevent("B2B_ReservationDetail_Confirm_Click", {
              email : GetOne("email"),
              id : params.id
            });
          } else {
            fbevent("B2B_ReservationDetail_Cancel_Click", {
              email : GetOne("email"),
              id : params.id
            });
          }
          setLoading(false);
          window.location.reload();
        } else {
          alert(Value.status_message);
          setLoading(false);
        }
      });
  };

  if (payment !== {}) {
    return (
      <>
        {!loading ? (
          <Container>
            <Header title="예약 내역" back={true} />

            <StepContainer>
              <JustBetween style={{ marginBottom: "24px", padding: "0 6px" }}>
                {payment.pay_status === "0" && (
                  <StepTitleContainer>
                    <StepTitle status={payment.pay_status}>예약이 취소됐어요</StepTitle>
                    <StepSubTitle>{payment.refund_message}</StepSubTitle>
                  </StepTitleContainer>
                )}
                {payment.pay_status === "1" && (
                  <StepTitleContainer>
                    <StepTitle status={payment.pay_status}>예약을 확정해 주세요</StepTitle>
                    {payment.payment_type === "현장결제" && <StepSubTitle>현장결제 예약이에요. 진행 전 결제에 대한 안내가 필요해요.</StepSubTitle>}
                  </StepTitleContainer>
                )}
                {payment.pay_status === "2" && (
                  <>
                    <StepTitle status={payment.pay_status}>이제 이용할 수 있어요!</StepTitle>
                  </>
                )}
                {payment.pay_status === "3" && <StepTitle status={payment.pay_status}>이용 완료! 리뷰를 독려해주세요</StepTitle>}
                {payment.pay_status === "4" && (
                  <StepTitle last={true} status={payment.pay_status}>
                    이번달에 정산되어요
                  </StepTitle>
                )}
              </JustBetween>
              {payment.pay_status === "0" && <EscrowCancel />}
              {payment.pay_status === "1" && <EscrowAfterPay />}
              {payment.pay_status === "2" && <EscrowAfterConfirm />}
              {payment.pay_status === "3" && <EscrowAfterActivity />}
              {payment.pay_status === "4" && <EscrowLast />}
            </StepContainer>

            <DivideLine />

            {/* 액티비티 정보 */}
            <Title>예약 정보</Title>
            <ActivityInfo>
              <JustBetween>
                <ActivityImage src={payment.activity_profile} />
                <ActivityTextContainer>
                  <ActivityName>{payment.activity_name}</ActivityName>
                  <ActivityLocation>
                    <SmallIcon src={Location} />
                    {`${payment.activity_location?.address}`}
                  </ActivityLocation>
                </ActivityTextContainer>
              </JustBetween>
              <InfoBox data={payment} />
            </ActivityInfo>

            <DivideLine />

            {/* 액티비티 이용자 정보 */}
            <Title>예약 회원 정보</Title>
            <UserContainer>

              <JustBetween>
                <DataName>회원 이름</DataName>
                <DataBox w={66}>{payment.pay_user}</DataBox>
              </JustBetween>

              <div style={{ height: "6px" }} />

              <JustBetween>
                <DataName>회원 전화번호</DataName>
                <DataBox w={66}>{payment.pay_user_phone}</DataBox>
              </JustBetween>

            </UserContainer>

            <DivideLine />

            {/* 금액 및 할인 정보 */}
            <Title>금액 및 할인 정보</Title>
            <Styler pr={24} pl={24}>
              <BigPriceContainer>
                <DataName>결제 수단</DataName>

                <PaymentTypeWrap>
                  <PaymentTypeText>{payment.payment_type}</PaymentTypeText>
                  {payment.payment_type === "현장결제" && (
                    <QuestionCircle
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        setPaymentModal(true);
                      }}
                      width="20px"
                      height="20px"
                      fill={colors.Gr04}
                    />
                  )}
                </PaymentTypeWrap>
              </BigPriceContainer>
              <DivideLine3 />
              <SmallPriceContainer>
                <DataName>액티비티 금액</DataName>
                {payment.total_amount_text}원
              </SmallPriceContainer>
              <SmallPriceContainer>
                <DataName>포인트 / 쿠폰 할인</DataName>-{payment.point_text}원
              </SmallPriceContainer>
              <DivideLine3 />
              <BigPriceContainer>
                <DataName>최종 결제금액</DataName>
                {payment.payment_type === "현장결제" ? (
                  <Dye color={colors.Red03}>
                    {payment.amount_text}원 ({payment.payment_type})
                  </Dye>
                ) : (
                  `${payment.amount_text}원`
                )}
              </BigPriceContainer>
            </Styler>

            <DivideLine />

            {/* DISCLAIMER */}
            <Styler pr={24} pl={24} pt={10} pb={20}>
              <BottomTitle>취소불가 및 수수료</BottomTitle>
              <BottomText>결제 약관에 따라 취소가 불가하거나 수수료가 발생할 수 있습니다.</BottomText>
              <Styler pt={16} />
              <BottomTitle>일정 조정</BottomTitle>
              <BottomText>1회 이상 액티비티의 경우 이후 일정은 결제 후 파트너님과 직접 조율을 통해 진행됩니다.</BottomText>
              <Styler pt={10} />
              <BottomText>
                <u style={{ cursor: "pointer" }} onClick={() => navigate("/partner_info_policy")}>
                  개인정보 처리 방침
                </u>
                ,&nbsp;&nbsp;
                <u style={{ cursor: "pointer" }} onClick={() => navigate("/partner_use_info")}>
                  이용약관
                </u>
                ,&nbsp;&nbsp;
                <u style={{ cursor: "pointer" }} onClick={() => navigate("/partner_pay_info")}>
                  결제약관
                </u>
              </BottomText>
              <Styler pt={10} />
              <BottomText>위 내용을 모두 확인하였으며, 결제에 동의합니다.</BottomText>
            </Styler>

            {payment.pay_status === "1" || payment.pay_status === "2" ? <Styler pt={100} /> : ""}

            {payment.pay_status === "1" && !payment.semos_only && (
              <BottomTab>
                <Decline onClick={() => setOpenCancelModal(true)}>예약거절</Decline>
                <Confirm onClick={() => setBottom(true)}>예약확정</Confirm>
              </BottomTab>
            )}

            {/*payment.pay_status === "2" && (
              <BottomTab>
                <Chat onClick={() => navigate(`/chat/${params.id}`)}>
                  <ChatIcon src={ChatImg} />
                  채팅하기
                </Chat>
              </BottomTab>
            )*/}

            <CancelModal openCancelModal={openCancelModal} setOpenCancelModal={setOpenCancelModal} id={params.id} />

            <BackButtonModal
              style={{ width: "100%", display: "flex", justifyContent: "center", left: "50%", transform: "translate(-50%,0)" }}
              isOpen={bottom}
              snapPoints={[SNAP]}
            >
              <BackButtonModal.Container style={{ width: `${W}px`, left: "none" }}>
                <BackModalLayout>
                  <ModalHeader>바로 예약확정 하시겠어요?</ModalHeader>
                  <Divider h={9} />
                  <ModalText>아직 상세내역을 확인하지 않았어요!</ModalText>
                  <Divider h={36} />
                  <ButtonContainer>
                    <StayButton onClick={() => setBottom(false)}>취소</StayButton>
                    <BackButton onClick={() => activityCancel("confirm")}>예약확정</BackButton>
                  </ButtonContainer>
                </BackModalLayout>
              </BackButtonModal.Container>
              <BackButtonModal.Backdrop />
            </BackButtonModal>

            {openToast ? (
              <Toast width={"205px"}>
                <span>예약이 확정되었어요</span>
              </Toast>
            ) : (
              <></>
            )}
          </Container>
        ) : (
          <LoadingSpinner loading={loading} title={`정보를 확인하고 있습니다.`} content={`잠시만 기다려 주세요!`} />
        )}

        <PaymentTypeInfoModal open={paymentModal} setOpen={setPaymentModal} />
      </>
    );
  }
};

export default ReservationDetail;

const Container = styled.section`
  height: calc(var(--vh, 1vh) * 100);
  overflow-x: hidden;
`;

const DivideLine = styled.div`
  height: 4px;
  background-color: ${colors.Gr01};
`;

const Title = styled.div`
  padding: 24px 0px 24px 24px;
  line-height: 142%;
  letter-spacing: -0.02em;
  color: ${colors.Gr05};
  font-size: 18px;
  font-weight: 600;
`;

const ActivityInfo = styled.div`
  padding: 0px 24px 32px 24px;
`;

const JustBetween = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const ActivityImage = styled.img`
  width: 78px;
  height: 78px;
  margin-right: 18px;
`;

const ActivityTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: ${TEXT}px;
  max-width: ${TEXT};
  height: 78px;
`;

const ActivityName = styled.div`
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  color: ${colors.Gr05};
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 142%;
  letter-spacing: -0.02em;
  font-size: 16px;
  font-weight: 600;
`;

const ActivityLocation = styled.div`
  display: flex;
  align-items: center;
  color: ${colors.Gr04};
  letter-spacing: -0.02em;
  font-size: 12px;
  font-weight: 500;
`;

const SmallIcon = styled.img`
  width: 16px;
  height: 16px;
  margin-right: 4px;
`;

const Dye = styled.span`
  color: ${({ color }) => color};
`;

const Styler = styled.div`
  display: ${({ flex }) => (flex ? "flex" : "")};
  flex-direction: ${({ direction }) => direction};
  align-items: ${({ align }) => align};
  justify-content: ${({ justify }) => justify};
  width: ${({ w }) => w}%;
  height: ${({ h }) => h}px;
  padding-top: ${({ pt }) => pt}px;
  padding-right: ${({ pr }) => pr}px;
  padding-bottom: ${({ pb }) => pb}px;
  padding-left: ${({ pl }) => pl}px;
  position: relative;
`;

const UserContainer = styled.div`
  padding: 0 24px 32px 24px;
`;

const UserCount = styled.div`
  color: ${colors.Gr05};
  line-height: 142%;
  letter-spacing: -0.02em;
  font-size: 16px;
  font-weight: 600;
`;

const Indices = styled.div`
  color: ${colors.Gr03};
  line-height: 142%;
  font-size: 16px;
  font-weight: 500;
`;

const DivideLine2 = styled.div`
  margin: 20px 0;
  border-bottom: 1px solid ${colors.Gr01};
`;

const DataName = styled.div`
  display: flex;
  align-items: center;
  color: ${colors.Gr04};
  letter-spacing: -0.02em;
  font-size: 14px;
  font-weight: 500;
`;

const DataBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${({ w }) => w}%;
  padding: 6px 0;
  background-color: ${colors.Gr01};
  border: 1px solid ${colors.Gr02};
  border-radius: 4px;
  color: ${colors.Gr04};
  line-height: 142%;
  letter-spacing: -0.02em;
  font-size: 14px;
  font-weight: 500;
`;

const BigPriceContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 18px 0;
  color: ${colors.Bl06};
  font-size: 14px;
  font-weight: 500;
`;

const SmallPriceContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 0;
  color: ${colors.Gr05};
  font-size: 14px;
  font-weight: 500;
`;

const DivideLine3 = styled.div`
  border-bottom: 1px solid ${colors.Gr01};
`;

const BottomTitle = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 4px;
  color: ${colors.Gr03};
  line-height: 142%;
  letter-spacing: -0.02em;
  font-size: 12px;
  font-weight: 600;
`;

const BottomText = styled.div`
  display: flex;
  align-items: center;
  color: ${colors.Gr03};
  line-height: 142%;
  letter-spacing: -0.02em;
  font-size: 10px;
  font-weight: 500;
`;

const BottomTab = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: ${W}px;
  padding: 11px 24px 24px 24px;
  background-color: ${colors.White};
  position: fixed;
  bottom: 0;
  box-shadow: 0px -2px 8px ${colors.B20};
  font-size: 16px;
  font-weight: 600;
`;

const Decline = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 28%;
  padding-left: 6px;
  color: ${colors.Red03};
  cursor: pointer;
`;

const Confirm = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 66%;
  padding: 16px 0;
  background-color: ${colors.Bl07};
  border-radius: 8px;
  color: ${colors.White};
  cursor: pointer;
`;

const OuterCircle = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 16px;
  height: 16px;
  border: 1px solid ${({ color }) => color};
  border-radius: 50%;
`;

const InnerCircle = styled.div`
  width: 10px;
  height: 10px;
  background-color: ${colors.Bl07};
  border-radius: 50%;
`;

const Chat = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  /* width: 66%; */
  padding: 7px 0;
  background-color: ${colors.Bl02};
  border: 1px solid ${colors.Bl03};
  border-radius: 8px;
  color: ${colors.Bl07};
  cursor: pointer;
`;

const ChatIcon = styled.img`
  width: 42px;
  height: 42px;
`;

const StepContainer = styled.div`
  padding: 24px 18.5px;
`;

const StepTitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
`;

const StepTitle = styled.h2`
  display: flex;
  align-items: center;
  color: ${({ status }) => {
    if (status === "0") return `${colors.Red03}`;
    else return `${colors.Bl07}`;
  }};
  line-height: 142%;
  letter-spacing: -0.02em;
  font-family: "GmarketSans" !important;
  font-size: 20px;
  font-weight: 400;
`;

const StepSubTitle = styled.span`
  color: ${colors.Gr05};
  line-height: 22px;
  letter-spacing: -0.02em;
  font-size: 14px;
  font-weight: 500;
`;

const BackButtonModal = styled(Sheet)``;

const BackModalLayout = styled.div`
  padding: 36px 24px 20px 24px;
`;

const ModalHeader = styled.div`
  font-weight: 600;
  font-size: 18px;
  line-height: 142%;
  letter-spacing: -0.02em;
  color: ${colors.Gr06};
`;

const ModalText = styled.div`
  color: ${colors.Gr04};
  letter-spacing: -0.02em;
  line-height: 142%;
  font-size: 14px;
  font-weight: 500;
`;

const Divider = styled.div`
  height: ${({ h }) => h}px;
`;

const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const Button = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 48%;
  padding: 18px 0;
  border-radius: 8px;
  text-align: center;
  letter-spacing: -0.02em;
  line-height: 142%;
  font-size: 14px;
  font-weight: 500;
`;

const BackButton = styled(Button)`
  width: 55%;
  background-color: ${colors.Bl07};
  color: ${colors.White};
`;

const StayButton = styled(Button)`
  width: 40%;
  background-color: ${colors.White};
  color: ${colors.Bl07};
`;

const PaymentTypeText = styled.span`
  font-size: 14px;
  font-weight: 500;
  line-height: 142%;
  letter-spacing: -0.28px;
  color: ${colors.Bl07};
`;

const PaymentTypeWrap = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;
`;
