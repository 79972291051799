import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";

//tool
import AddressService from "../../tool/address";

//style
import ActivityModal from "./ActivityModal";
import { PC } from "../../styles/globalStyles";

//component
import { Wrapper, Text, Input, WrapperColumn } from "../layouts/layoutStorage";
import colors from "../../styles/colors";

//icon
import { ReactComponent as Search } from "../../resource/icon/search.svg";
import { ReactComponent as Plus } from "../../resource/icon/plus.svg";
import { ReactComponent as Minus } from "../../resource/icon/minus.svg";
import { ReactComponent as Close } from "../../resource/icon/close.svg";

const WEEK_DAY = ["월", "화", "수", "목", "금", "토", "일"];
const HALF_DAY = ["오전", "오후"];
const HOUR = {
  AM: ["00", "01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11"],
  PM: ["12", "01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11"],
};
const MINUTE = ["00", "30"];
const ADDRESS_EXTRA_MIN_LENGTH = 5;

const ActivityLocationModal = ({ data, setData, open, setOpen, deleteD, setDelete, showAddress, showAddressIndex, setChange }) => {
  const [locationData, setLocationData] = useState([]);
  const [deleteSchedule, setDeleteSchedule] = useState([]);
  const [addressS, setAddressS] = useState(false);

  const [address, setAddress] = useState({
    address: "",
    address_extra: "",
    address_dong: "",
    address_gu: "",
    address_si: "",
  });

  useEffect(() => {
    if (showAddress.address) {
      let dataCopy = [...data.activity_location];

      let array = [];

      dataCopy.filter((val) => {
        if (val.address.address === showAddress.address && val.address.address_extra === showAddress.address_extra) {
          let newObj = {};
          newObj = JSON.parse(JSON.stringify(val));

          Object.keys(val).forEach((key) => {
            newObj[key] = val[key];
          });
          array.push(newObj);
        }
      });
      setLocationData(array);
      dataCopy.length > 0 && setShowData(array[0]);
      setAddress(showAddress);
    }

    if (locationData.length > 0 && locationData[0].id) {
      scrollToItem("item_0");
    }

    return () => {
      setShowIndex(0);
      setSelectTime({
        halfDay: "",
        hour: "",
        minute: "",
      });
      setShowData({
        address: { address: "", address_extra: "", address_dong: "", address_gu: "", address_si: "" },
        id: "",
        days: [],
        start: [],
      });
      setAddress({
        address: "",
        address_extra: "",
        address_dong: "",
        address_gu: "",
        address_si: "",
      });
      setSelectDaysIndexArray([]);
      setLocationData([]);
    };
  }, [open, showAddressIndex]);

  const [showIndex, setShowIndex] = useState(0);

  const [showData, setShowData] = useState({
    address: { address: "", address_extra: "", address_dong: "", address_gu: "", address_si: "" },
    id: "",
    days: [],
    start: [],
  });

  const [selectDaysIndexArray, setSelectDaysIndexArray] = useState([]);

  const [selectTime, setSelectTime] = useState({
    halfDay: "",
    hour: "",
    minute: "",
  });

  const activeCheck = () => {
    let checkLocationAllFilled = locationData.every(
      (item) => item.days.length && item.start.length && item.address.address && item.address.address_extra.length >= ADDRESS_EXTRA_MIN_LENGTH
    );
    return locationData.length && checkLocationAllFilled;
  };

  const AddressChange = (data) => {
    let locationSigungu = data.sigungu.split(" ");
    let loGu = "";
    let loSi = "";
    let loDong = "";

    if (locationSigungu.length > 1) {
      loGu = locationSigungu[1].length > 2 ? locationSigungu[1].slice(0, -1) : locationSigungu[1];
      loSi = locationSigungu[0].length > 2 ? locationSigungu[0].slice(0, -1) : locationSigungu[0];
      loDong = data.bname.length > 2 ? data.bname.slice(0, -1) : data.bname;
    } else {
      loGu = data.sigungu.length > 2 ? data.sigungu.slice(0, -1) : data.sigungu;
      loSi = data.sido;
      loDong = data.bname.length > 2 ? data.bname.slice(0, -1) : data.bname;
    }

    let addressData = {
      address: data.roadAddress,
      address_extra: "",
      address_dong: loDong,
      address_gu: loGu,
      address_si: loSi == "강원특별자치도" ? "강원" : loSi,
    };

    //전체 주소 변경
    let locationDataCopy = [...locationData];
    locationDataCopy.map((item) => {
      item.address = addressData;
    });
    setLocationData(locationDataCopy);
    setAddress(addressData);
    setAddressS(false);
  };

  const mapService = new AddressService(AddressChange);

  const SearchAddress = () => {
    setAddressS(true);
    mapService.open();
  };

  const addTime = () => {
    if (selectTime.halfDay && selectTime.hour && selectTime.minute && showData.id) {
      let showDataStartCopy = [...showData.start];
      let startTimeText = `${selectTime.halfDay === "오전" || selectTime.hour === "12" ? selectTime.hour : Number(selectTime.hour) + 12}:${selectTime.minute}`;
      let checkArray = showDataStartCopy.findIndex((item) => item === startTimeText);
      if (checkArray !== -1) {
        setSelectTime({
          halfDay: "",
          hour: "",
          minute: "",
        });
        return window.alert("중복된 시간이 있습니다");
      } else {
        let locationDataCopy = [...locationData];
        showDataStartCopy.push(startTimeText);
        locationDataCopy[showIndex].start = showDataStartCopy;
        setLocationData(locationDataCopy);
        setShowData((prev) => ({ ...prev, start: showDataStartCopy }));
        setSelectTime({
          halfDay: "",
          hour: "",
          minute: "",
        });
        scrollToBottom();
      }
    }
  };

  const startTimeText = (item) => {
    let timeSplit = item.split(":");
    let showText = `${Number(timeSplit[0]) < 12 ? "오전" : "오후"} ${Number(timeSplit[0]) <= 12 ? timeSplit[0] : Number(timeSplit[0]) - 12}시 ${
      timeSplit[1]
    }분`;
    return showText;
  };

  const scrollRef = useRef(null);

  const scrollToItem = (index) => {
    if (scrollRef.current) {
      const item = scrollRef.current.querySelector(`.${index}`);
      const targetScrollLeft = item.offsetLeft;
      scrollRef.current.scrollTo({ left: targetScrollLeft - 24, behavior: "smooth" });
    }
  };

  const containerRef = useRef(null);

  const scrollToBottom = () => {
    const container = containerRef.current;
    const targetScrollTop = container.scrollHeight - container.clientHeight;
    container.scrollTo({ top: targetScrollTop, behavior: "smooth" });
  };

  const changeAddressCheck = () => {
    let idCheck = locationData.some((item, index) => item.id && item.id !== "new");
    return address.address && address.address_extra && idCheck;
  };

  const saveData = () => {
    let dataCopy = [...data.activity_location];
    let deleteArray = [...deleteD.activity_location];

    // 삭제된 일정 제거
    if (deleteSchedule.length > 0) {
      deleteSchedule.map((item, index) => {
        dataCopy = dataCopy.filter((el, idx) => {
          if (JSON.stringify(el.days) === JSON.stringify(item.days)) {
            deleteArray.push(el.id);
            setDelete((prev) => ({ ...prev, activity_location: deleteArray }));
          }
          return JSON.stringify(el.days) !== JSON.stringify(item.days);
        });
      });
    }

    // 수정된 일정 업데이트
    locationData.map((item, index) => {
      if (item.id === "new") {
        let checkIndex = dataCopy.findIndex(
          (el, idx) =>
            JSON.stringify(el.days) === JSON.stringify(item.days) &&
            item.address.address === el.address.address &&
            item.address.address_extra === el.address.address_extra
        );
        if (
          // id === new 일때 중복 push를 막음(4단계 가기 전에 두번 열고 닫으면 발생 가능)
          checkIndex === -1
        ) {
          dataCopy.push(item);
        } else {
          // 4단계 가기 전에 id === new 데이터 수정 시 업데이트
          dataCopy[checkIndex] = item;
        }
      } else if (dataCopy.findIndex((el, idx) => el.id === item.id) >= 0) {
        let findIndex = dataCopy.findIndex((el, idx) => el.id === item.id);
        dataCopy[findIndex] = item;
      }
    });

    setData((prev) => ({ ...prev, activity_location: dataCopy }));
    setOpen(false);
    setChange(true);
  };

  const selectDays = (index) => {
    let selectDaysCopy = [...selectDaysIndexArray];
    if (selectDaysCopy.includes(index)) {
      selectDaysCopy = selectDaysCopy.filter((el) => el !== index);
    } else {
      selectDaysCopy.push(index);
    }

    setSelectDaysIndexArray(selectDaysCopy);
    setShowIndex("");
    setShowData({
      address: { address: "", address_extra: "", address_dong: "", address_gu: "", address_si: "" },
      id: "",
      days: [],
      start: [],
    });

    setSelectTime({
      halfDay: "",
      hour: "",
      minute: "",
    });
  };
  const addSchedule = () => {
    if (selectDaysIndexArray.length > 0) {
      let daysArray = selectDaysIndexArray.sort().map((item) => WEEK_DAY[item]);
      let locationDataCopy = [...locationData];
      let checkArray = locationData.findIndex((item) => JSON.stringify(item.days) === JSON.stringify(daysArray));
      if (checkArray !== -1) {
        return window.alert("중복된 일정이 있습니다.");
      } else {
        locationDataCopy.push({
          id: "new",
          address: address,
          start: [],
          days: daysArray,
        });
        setLocationData(locationDataCopy);
        setSelectDaysIndexArray([]);
        setTimeout(() => {
          setShowData({
            id: "new",
            address: address,
            start: [],
            days: daysArray,
          });
          scrollToItem(`item_${locationData.length}`);
          setShowIndex(locationData.length);
        }, 200);
      }
    }
  };

  return (
    <ActivityModal
      // twoButton={changeAddressCheck()}
      // buttonLeftFuc={}
      // buttonLeftText="삭제하기"
      buttonText="등록하기"
      buttonActive={activeCheck()}
      title="진행 장소 및 진행 시간"
      open={open}
      setOpen={setOpen}
      saveData={saveData}
    >
      <Wrapper fd="column" height="100%" width="100%" ai="flex-start" padding="0 0 130px 0">
        <Text margin="8px 24px 12px 24px" size="14px" weight="500" lh="22px" ls="-0.28px" color={colors.Gr04}>
          액티비티를 진행할 장소와 진행 시간을 입력해주세요
        </Text>
        <Wrapper ref={containerRef} fd="column" width="100%" height="100%" ai="flex-start" of="auto">
          <Wrapper padding="16px 0px 8px 0" width="100%" fd="column" ai="flex-start">
            <ModalSubTitle>진행 장소</ModalSubTitle>

            <TipWrap>
              <span className="tip">Tip! </span>
              <span>등록하기 버튼 클릭시 주소 수정이 불가해요.</span>
            </TipWrap>
            <Wrapper width="100%" fd="column" padding="0 24px">
              <Wrapper
                margin="16px 0 10px 0"
                height="48px"
                width="100%"
                border={`1px solid ${colors.Gr02}`}
                radius="8px"
                padding="0 16px"
                jc="center"
                ai="center"
                cursor={!changeAddressCheck() ? "pointer" : "default"}
                bgc={changeAddressCheck() ? colors.Gr01 : colors.White}
                onClick={() => !changeAddressCheck() && SearchAddress()}
              >
                <Text width="calc(100% - 24px)" size="14px" weight="500" lh="22px" ls="-0.28px" color={address.address ? colors.Gr06 : colors.Gr03}>
                  {address.address ? address.address : "지역을 입력하여 검색하세요"}
                </Text>
                <Search fill={colors.Gr03} width="24px" height="24px" />
              </Wrapper>

              <Wrapper
                height="44px"
                width="100%"
                border={`1px solid ${address.address_extra.length > 4 ? colors.Gr02 : colors.Red03}`}
                bgc={changeAddressCheck() ? colors.Gr01 : colors.White}
                radius="8px"
                padding="0 16px"
                jc="center"
                ai="center"
              >
                <Input
                  disabled={changeAddressCheck()}
                  width="100%"
                  value={address.address_extra}
                  onChange={(e) => {
                    //전체 주소 변경
                    let locationDataCopy = [...locationData];
                    locationDataCopy.map((item) => {
                      item.address.address_extra = e.target.value;
                    });
                    setLocationData(locationDataCopy);
                    setAddress((prev) => ({ ...prev, address_extra: e.target.value }));
                  }}
                  size="14px"
                  weight="500"
                  lh="22px"
                  ls="-0.28px"
                  color={colors.Gr06}
                  phColor={colors.Gr03}
                  placeholder="상세 주소를 5자 이상 입력해주세요"
                />
              </Wrapper>
            </Wrapper>
          </Wrapper>

          <Wrapper padding="16px 0 8px 0" width="100%" fd="column" ai="flex-start">
            <ModalSubTitle>진행 시간</ModalSubTitle>

            <TipWrap>
              <span className="tip">Tip! </span>
              <span>{`요일 선택 후 시간 추가를 클릭해주세요.`}</span>
              <span style={{width : "100%", display : "inline-block"}}>{`시간 선택 후 일정 추가를 클릭하면 진행 시간을 추가할 수 있어요.`}</span>
            </TipWrap>

            <Wrapper margin="8px 0" fd="column" width="100%" padding="8px 0" bgc={colors.Gr01} gap="8px">
              <Wrapper width="100%" jc="space-between" gap="8px" padding="0 24px">
                {WEEK_DAY.map((item, index) => (
                  <Wrapper
                    cursor="pointer"
                    onClick={() => selectDays(index)}
                    radius="8px"
                    bgc={selectDaysIndexArray.includes(index) ? colors.Bl02 : colors.White}
                    border={`1px solid ${selectDaysIndexArray.includes(index) ? colors.Bl05 : colors.Gr02}`}
                    width="100%"
                    key={index}
                    padding="0 12px"
                    height="44px"
                    jc="center"
                    ai="center"
                  >
                    <Text size="14px" weight="500" lh="22px" ls="-0.28px" color={colors.Gr05}>
                      {item}
                    </Text>
                  </Wrapper>
                ))}
              </Wrapper>

              <Wrapper style={{alignSelf : "end"}} margin={`0 24px 0 0`} cursor="pointer" radius="4px" jc="center" ai="center" width="68px" height="32px" bgc={colors.Bl02} onClick={addSchedule}>
                <Text size="12px" weight="600" lh="20px" ls="-0.24px" color={colors.Bl07}>
                  시간 추가
                </Text>
                {/*<Plus width="14px" height="14px" fill={colors.Gr05} />*/}
              </Wrapper>
            </Wrapper>

            <Wrapper gap="8px" ref={scrollRef} width="100%" of="auto" padding="0 24px 8px 24px">
              {locationData.length > 0 && locationData[0].id && (
                <>
                  {locationData.map((item, index) => ( 
                    <Wrapper
                      cursor="pointer"
                      onClick={() => {
                        setShowData(item);
                        setShowIndex(index);
                        scrollToItem(`item_${index}`);
                        setSelectDaysIndexArray([]);
                      }}
                      radius="8px"
                      style={{ minWidth: "148px" }}
                      key={index}
                      width="148px"
                      height="44px"
                      padding="0 12px"
                      jc="space-between"
                      className={`item_${index}`}
                      bgc={showIndex === index ? colors.Bl02 : colors.White}
                      border={`1px solid ${showIndex === index ? colors.Bl05 : colors.Gr02}`}
                    >
                      <Text width="calc(100% - 16px)" ws="pre" size="12px" weight="600" lh="20px" ls="-0.24px" color={colors.Gr05}>
                        {item.days.map((el, idx) => (item.days.length === idx + 1 ? `${el}` : `${el}, `))}
                      </Text>
                      <Minus
                        style={{ cursor: "pointer" }}
                        onClick={(e) => {
                          e.stopPropagation();
                          let deleteCopy = [...deleteSchedule];
                          deleteCopy.push(item);
                          setDeleteSchedule(deleteCopy);

                          let locationDataCopy = [...locationData];
                          locationDataCopy.splice(index, 1);
                          setLocationData(locationDataCopy);

                          setShowIndex("");
                          setShowData({
                            address: { address: "", address_extra: "", address_dong: "", address_gu: "", address_si: "" },
                            id: "",
                            days: [],
                            start: [],
                          });
                        }}
                        width="16px"
                        height="16px"
                        fill={colors.Gr05}
                      />
                    </Wrapper>
                  ))}
                </>
              )}
            </Wrapper>
            {showData.id && (
              <WrapperColumn width="100%" padding="8px 24px 48px 24px">
                <Wrapper radius="12px" width="100%" fd="column" bgc={colors.White} border={`1px solid ${colors.Gr02}`}>
                  <Wrapper
                    width="100%"
                    // height="196px"
                    jc="space-between"
                    style={{ borderBottom: `1px solid ${colors.Gr02}` }}
                  >
                    <WrapperColumn height="148px" width="100%">
                      {HALF_DAY.map((item, index) => (
                        <Wrapper
                          cursor="pointer"
                          onClick={() => {
                            setSelectTime((prev) => ({ ...prev, halfDay: item }));
                            setSelectDaysIndexArray([]);
                          }}
                          bgc={selectTime.halfDay === item && colors.Bl02}
                          height="32px"
                          jc="center"
                          ai="center"
                          width="100%"
                          key={index}
                          style={{
                            borderBottom: selectTime.halfDay === item ? `1px solid ${colors.Bl03}` : `1px solid ${colors.Gr01}`,
                            borderTop: selectTime.halfDay === item && `1px solid ${colors.Bl03}`,
                          }}
                        >
                          <Text size="16px" weight="500" lh="24px" ls="-0.32px" color={colors.Gr05}>
                            {item}
                          </Text>
                        </Wrapper>
                      ))}
                    </WrapperColumn>

                    <WrapperColumn
                      height="148px"
                      width="100%"
                      of="auto"
                      style={{ borderRight: `1px solid ${colors.Gr02}`, borderLeft: `1px solid ${colors.Gr02}` }}
                    >
                      {HOUR[selectTime.halfDay ? (selectTime.halfDay === "오전" ? "AM" : "PM") : `AM`].map((item, index) => (
                        <Wrapper
                          cursor="pointer"
                          onClick={() => {
                            setSelectTime((prev) => ({ ...prev, hour: item }));
                            setSelectDaysIndexArray([]);
                          }}
                          bgc={selectTime.hour === item && colors.Bl02}
                          height="32px"
                          style={{
                            borderBottom: selectTime.hour === item ? `1px solid ${colors.Bl03}` : HOUR[`AM`].length !== index && `1px solid ${colors.Gr01}`,
                            borderTop: selectTime.hour === item && `1px solid ${colors.Bl03}`,
                            minHeight: "32px",
                          }}
                          jc="center"
                          ai="center"
                          width="100%"
                          key={index}
                        >
                          <Text size="16px" weight="500" lh="24px" ls="-0.32px" color={colors.Gr05}>
                            {item}시
                          </Text>
                        </Wrapper>
                      ))}
                    </WrapperColumn>

                    <WrapperColumn height="148px" width="100%" of="auto">
                      {MINUTE.map((item, index) => (
                        <Wrapper
                          cursor="pointer"
                          onClick={() => {
                            setSelectTime((prev) => ({ ...prev, minute: item }));
                            setSelectDaysIndexArray([]);
                          }}
                          bgc={selectTime.minute === item && colors.Bl02}
                          height="32px"
                          style={{
                            borderBottom: selectTime.minute === item ? `1px solid ${colors.Bl03}` : MINUTE.length !== index && `1px solid ${colors.Gr01}`,
                            borderTop: selectTime.minute === item && `1px solid ${colors.Bl03}`,
                            minHeight: "32px",
                          }}
                          jc="center"
                          ai="center"
                          width="100%"
                          key={index}
                        >
                          <Text size="16px" weight="500" lh="24px" ls="-0.32px" color={colors.Gr05}>
                            {item}분
                          </Text>
                        </Wrapper>
                      ))}
                    </WrapperColumn>
                  </Wrapper>

                  <Wrapper padding="0 16px" jc="space-between" height="48px" width="100%">
                    <Text size="12px" weight="600" lh="20px" ls="-0.24px" color={colors.Bl07}>
                      {selectTime.halfDay && selectTime.hour && selectTime.minute && `${selectTime.halfDay} ${selectTime.hour}시 ${selectTime.minute}분`}
                    </Text>
                    <Wrapper cursor="pointer" onClick={addTime} radius="4px" jc="center" ai="center" width="68px" height="32px" bgc={colors.Bl02}>
                      <Text size="12px" weight="600" lh="20px" ls="-0.24px" color={colors.Bl07}>
                        일정 추가
                      </Text>
                    </Wrapper>
                  </Wrapper>
                </Wrapper>

                <Wrapper width="100%" wrap="wrap" gap="4px">
                  {showData &&
                    showData.start.map((item, index) => (
                      <Wrapper
                        key={`${item}_${index}`}
                        radius="8px"
                        margin="16px 0 0 0"
                        bgc={colors.Gr01}
                        height="44px"
                        width="132px"
                        jc="center"
                        ai="center"
                        gap="4px"
                      >
                        <Text size="14px" weight="500" lh="22px" ls="-0.28px" color={colors.Gr05}>
                          {startTimeText(item)}
                        </Text>
                        <Close
                          cursor="pointer"
                          onClick={() => {
                            let startArray = [...showData.start];
                            startArray = startArray.filter((el) => el !== item);
                            setShowData((prev) => ({ ...prev, start: startArray }));

                            let locationDataCopy = [...locationData];
                            locationDataCopy[showIndex].start = [...startArray];
                            setLocationData(locationDataCopy);
                          }}
                          width="16px"
                          height="16px"
                          fill={colors.Gr05}
                        />
                      </Wrapper>
                    ))}
                </Wrapper>
              </WrapperColumn>
            )}
          </Wrapper>
        </Wrapper>
      </Wrapper>
      {window.document.documentElement.clientWidth < PC && <AddressPopup view={addressS} id={"PostCodeAdd"} />}
    </ActivityModal>
  );
};

export default ActivityLocationModal;

const ModalSubTitle = styled.span`
  word-break: keep-all;
  padding: 0 24px;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: -0.32px;
  color: ${colors.Gr05};
`;

const TipWrap = styled.p`
  word-break: keep-all;
  padding: 0 24px;
  font-size: 14px;
  font-weight: 500;
  line-height: 22px;
  letter-spacing: -0.28px;
  color: ${colors.Gr03};
  .tip {
    color: ${colors.Bl05};
  }
`;

const ButtonWrap = styled.div`
  width: ${({ width }) => `${width}`};
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  padding: 8px 16px;
  height: 56px;
  background-color: ${({ active }) => (active ? colors.Bl02 : colors.White)};
  border: 1px solid ${({ active }) => (active ? colors.Bl05 : colors.Gr02)};
`;

const ButtonText = styled.span`
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: -0.32px;
  color: ${colors.Gr05};
`;

const AddressPopup = styled.section`
  height: calc(var(--vh, 1vh) * 100);
  /* height: 100vh; */
  width: calc(var(--vw, 1vw) * 100);
  overflow: hidden;
  position: fixed;
  z-index: 150;
  background-color: #ffffff;
  top: 0;
  right: 0;
  display: ${({ view }) => (view ? `inline-flex` : `none`)};

`;
