import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

// components
import Header from "../components/basic/Header";
import SubscribeContents from "../components/subscribe/content";
import { GetOne } from "../tool/localstorage";
import { LoadingSpinner } from "../tool/loading";
import { kakaoChannel } from "../tool/kakao";

// style
import colors from "../styles/colors";
import { PC, W } from "../styles/globalStyles";

// img


const SubscribeManage = () => {
    const navigate = useNavigate();

    const [loading, setLoading] = useState(true);
    const [subscribe, setSubscribe] = useState();

    useEffect(() => {
        getData();
    }, []);


    // GET DATA
    const getData = () => {
        setLoading(true);

        const token = GetOne("token");
        const frm = new FormData();

        frm.append("mode", "subscribe");
        frm.append("token", token);

        axios
        .post("https://ai.semos.kr/semos_partner/v3/profile/AccountRevise", frm, {
            headers: {
            "Content-Type": "multipart/form-data",
            Accept: "application/json",
            },
        })
        .then((res) => {
            const value = res.data;
            const data = value.subscribe_data;
            const status = value.status;
            if (status === "success") {
                setSubscribe(data);

                setLoading(false);
            } else {
                console.log("ACCOUNT REVISE GET DATA ERROR");

                setLoading(false);
            }
        })
        .catch((err) => console.log(err));
    };

    const NextPage = () => {
        if(subscribe?.status > 0) {
            if (window.document.documentElement.clientWidth > PC) {
                window.open("http://pf.kakao.com/_CBUHxj", "_blank");
            } else {
                kakaoChannel();
            }
        } else {
            window.location.href = 'https://forms.gle/mpMXYxmcxyXetueB8'
        }

    }

    const SubscribeTitle = ['', '구독 정보', '구독 정보'];
    const SubscribeDescription = ['', '', `결제가 완료되지 않았습니다. 결제 정보를 확인해주세요!`];
    const SubscribeButton = ['등록 상담 신청하기', '문의하기', '문의하기'];

    const CheckStatus = () => {
        if(subscribe?.status > 0) {
            return (
                <InnerContainer>
                    <InputTitle>
                        {SubscribeTitle[subscribe?.status]}  
                    </InputTitle>

                    <Divider h="10" />

                    <Contents>
                        <div className="Title">
                            {subscribe?.plan}
                        </div>

                        <Divider h="10" />

                        <div className="Description">
                            {`결제 방법 : ${subscribe?.plan_type}`}
                        </div>

                        <Divider h="10" />

                        <div className="Description">
                            {`결제 금액 : ${subscribe?.plan_price_text}원`}
                        </div>

                        <Divider h="10" />

                        <div className="Description">
                            {`최초 시작일 : ${subscribe?.plan_og}`}
                        </div>
                    </Contents>

                    <Divider h="10" />

                    <ContentsDescription>
                        <div className="Title">
                            {`다음 결제일 : ${subscribe?.plan_end}`}
                        </div>
                    </ContentsDescription>

                    <Divider h="20" />

                    <InputTitle>
                        결제 정보 
                    </InputTitle>

                    <Divider h="10" />

                    <Contents>
                        <div className="Description">
                            {`카드번호 : ${subscribe?.card_number}`}
                        </div>

                        <Divider h="10" />

                        <div className="Description">
                            {`유효기간 : ${subscribe?.expiration_year} / ${subscribe?.expiration_month}`}
                        </div>
                    </Contents>

                    <Divider h="10" />

                    <ContentsDescription>
                        <div className="Warning">
                            {`${SubscribeDescription[subscribe?.status]}`}
                        </div>
                    </ContentsDescription>
                    
                </InnerContainer>
            )
        } else {
            return (
                <InnerContainer2>
                    <SubscribeContents/>
                </InnerContainer2>
            )
        }
    }


    return (
        <Container>
            <Header
                title="구독 관리"
                back={true}
                handleClick={() => navigate(-1)}
            />

            <NextButtonContainer>
                <NextButton
                    next={true}
                    updated={true}
                    onClick={() => {
                        NextPage()
                    }}
                >
                    {SubscribeButton[subscribe?.status]}
                </NextButton>
            </NextButtonContainer>

            {CheckStatus()}

            {
                (loading == true) 
                ?
                    <LoadingSpinner
                    loading={loading}
                    title={`정보를 확인하고 있습니다.`}
                    content={`잠시만 기다려 주세요!`}
                    />
                : 
                    <></>
            }

        </Container>
    );
};

export default SubscribeManage;

const Container = styled.section`
    height: 100vh;
    height: calc(var(--vh, 1vh) * 100);
    overflow-x: hidden;
    overflow-y: auto;
    padding-bottom : 90px;
    width : 100%;
    display : inline-block;
`;

const InnerContainer = styled.section`
    width : 100%;
    max-width: 900px;
    padding : 24px 24px;
    display : block;
    margin : 0 auto;
    //flex-wrap: wrap;
`;

const InnerContainer2 = styled.section`
    width : 100%;
    max-width: 1000px;
    padding : 0px 24px;
    display : block;
    margin : 0 auto;
    //flex-wrap: wrap;
`;

const NextButton = styled.button`
    width: ${W - 48}px;
    max-width: 900px;
    height : 52px;
    background-color: ${({ updated }) => (updated ? colors.Bl07 : colors.Gr01)};
    border: 1px solid;
    border-color: ${({ updated }) => (updated ? colors.Bl07 : colors.Gr02)};
    border-radius: 8px;
    color: ${({ updated }) => (updated ? colors.White : colors.Gr02)};
    text-align: center;
    font-size: 16px;
    font-weight: 600;
    cursor: ${({ updated }) => (updated ? "pointer" : "default")};
`;

const Divider = styled.div`
    height: ${({ h }) => h}px;
    width : 100%;
`;

const NextButtonContainer = styled.div`
    width: 100%;
    margin-top: 32px;
    padding: 10px 24px;
    border-top: 2px solid ${colors.Gr01};
    position : fixed;
    z-index: 500;
    bottom : 0;
    left : 0;
    background-color : #ffffff;
    display : inline-flex;
    align-items: center;
    justify-content: center;
`;

const InputTitle = styled.span`
    display: inline-block;
    color: ${colors.Gr05};
    line-height: 142%;
    letter-spacing: -0.32px;
    font-size: 1.8rem;
    font-weight: 600;

    .Description {
        color: ${colors.Red03};
        font-size: 1.4rem;
        font-weight: 500;
        line-height: 26px;
    }
`;

const Contents = styled.div`
    display: inline-flex;
    background-color : ${colors.Gr01};
    border : 1px solid ${colors.Gr02};
    width : 100%;
    padding: 10px 14px;
    border-radius: 8px;
    flex-wrap: wrap;

    .Title {
        width : 100%;
        color: ${colors.Gr05};
        font-size: 1.6rem;
        line-height: 142%;
        letter-spacing: -0.32px;
        font-weight: 600;
    }

    .Description {
        width : 100%;
        color: ${colors.Gr05};
        font-size: 1.4rem;
        line-height: 142%;
        letter-spacing: -0.32px;
        font-weight: 500;
    }
`;

const ContentsDescription = styled.div`
    display: inline-flex;
    width : 100%;
    flex-wrap: wrap;
    justify-content: flex-end;

    .Title {
        color: ${colors.Bl07};
        font-size: 1.6rem;
        line-height: 142%;
        letter-spacing: -0.32px;
        font-weight: 600;
    }

    .Warning {
        color: ${colors.Red03};
        font-size: 1.6rem;
        line-height: 142%;
        letter-spacing: -0.32px;
        font-weight: 600;
    }

    .Description {
        color: ${colors.Gr05};
        font-size: 1.4rem;
        line-height: 142%;
        letter-spacing: -0.32px;
        font-weight: 500;
    }
`;