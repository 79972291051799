import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";
import axios from "axios";

//component
import ActivityInfo from "../components/activityRegister/NewActivityInfo";
import ActivitySchedule from "../components/activityRegister/ActivitySchedule";
import ActivityKeyword from "../components/activityRegister/ActivityKeyword";
import Modal from "../components/newActivityRegister/modal";

//tool
import { GetOne } from "../tool/localstorage";
import { fbevent } from "../tool/firebase";

const ActivityRegister = () => {
  const navigate = useNavigate();
  const params = useParams();
  const Id = params.id;
  const Type = params.type;

  const [step, setStep] = useState(1);
  const [change, setChange] = useState(false);
  const [status, setStatus] = useState("");
  const [ogPrice, setOgPrice] = useState({
    1: { id: "new", price: "", type : "" },
  });
  const [ogPriceM, setOgPriceM] = useState({
    1: { id: "new", price: "", type : "" },
  });
  const [ogPriceC, setOgPriceC] = useState({
    1: { id: "new", price: "", type : "" },
  });
  const [openModal, setOpenModal] = useState({ status: false, type: "", link: "", confirmSave: false });
  const [pageType, setPageType] = useState(Type);
  const [MCCount, setMCCount] = useState(1);
  const [MCCountOg, setMCCountOg] = useState(1);
  const [TypeOg, setTypeOg] = useState();
  const [data, setData] = useState({
    activity_name: "",
    activity_type: "",
    min_capacity: 1,
    max_capacity: 1,
    thumbnail: "",
    category: [],
    prepare: [], 
    include: [], 
    exclude: [],  
    activity_location: [],
    recommend: [], 
    require: [], 
    caution: [], 
    background: [],
    price: {
      1: { id: "new", price: "" },
    },
    curriculum: {
      day1: [
        {
          id: "new",
          content: "",
          last: "",
          title: "",
        },
      ],
    },
  });

  const getData = () => {
    let token = GetOne("token");
    const frm = new FormData();
    frm.append("mode", "main");
    frm.append("token", token);
    frm.append("activity_id", Id);

    axios
      .post("https://ai.semos.kr/semos_partner/v3/activity/ActivityInfo", frm, {
        headers: { "Content-Type": "multipart/form-data" },
      })
      .then((data) => {
        let Value = data.data;
        let Status = Value.status;
        let StatusMessage = Value.status_message;
        if (Status == "success") {
          const ActivityType = Value.data.activity_type;

          if(
            ActivityType == '단기 레슨' || 
            ActivityType == '장비 대여' || 
            ActivityType == '자격증' || 
            ActivityType == '국내/해외 투어' ) {
              setOgPrice(Value.data.price)
          } else if (
            ActivityType == '정기 레슨' || 
            ActivityType == '이용권(기간)') {
              setOgPriceM(Value.data.price)
          } else if (
            ActivityType == '이용권(횟수)') {
              setOgPriceC(Value.data.price)
          }

          setData(Value.data);

          setStatus(Value.activity_status);
          setMCCount(Object.keys(Value.data.price).length)
          setMCCountOg(Object.keys(Value.data.price).length)
          setTypeOg(ActivityType);
        } else {
          window.alert(StatusMessage);
        }
      });
  };

  useEffect(() => {
    fbevent("B2B_ActivityEdit_Page_View", {
      email : GetOne("email"),
      id : Id
    });
  }, []);

  useEffect(() => {
    getData();
  }, [status]);

  useEffect(() => {
    setChange(false);
  }, [pageType]);

  const TypeHandler = (value, save, reset) => {

    const URL = `/activity_revise/${Id}/${value}`;

    if(change) {
      setOpenModal({
        status: true,
        type: "navigation",
        link: URL,
        function : save,
        reset : reset,
        pageType : value
      });
    } else {
      reset();
      setPageType(value);
      navigate(URL);
    }
  }

  const Available = (Id != 'new') && ((status == 1) || (status == 2));

  const ActivityStatusHandler = () => {

    if(window.confirm("액티비티 상태를 변경하시겠습니까?")) {

      fbevent("B2B_ActivityEdit_Status_Click", {
        email : GetOne("email"),
        id : Id
      });

      let token = GetOne("token");

      const frm = new FormData();

      frm.append("mode", "status");
      frm.append("token", token);
      frm.append("activity_id", Id);
      frm.append("activity_status", status);

      axios
        .post("https://ai.semos.kr/semos_partner/v3/activity/ActivityInfo", frm, {
          headers: { "Content-Type": "multipart/form-data" },
        })
        .then((data) => {
          let Value = data.data;
          let Status = Value.status;
          let StatusMessage = Value.status_message;
          

          if (Status == "success") {
            if(status == 1) {
              setStatus(2)
            } else {
              setStatus(1)
            }
            window.alert(StatusMessage);
            window.location.reload();
          } else {
            window.alert(StatusMessage);
            window.location.reload();
          }
        });
    }

  }

  return (
      <Container id="description_container">
            <ActivityInfo
              open={pageType == 'info'}
              DAT={{
                activity_name: data.activity_name,
                activity_type: data.activity_type,
                min_capacity: data.min_capacity,
                max_capacity: data.max_capacity,
                thumbnail: data.thumbnail,
                category: data.category,
                prepare: data.prepare, 
                include: data.include, 
                exclude: data.exclude, 
                price: data.price,
                curriculum: data.curriculum
              }}
              aId={Id}
              pageType={pageType}
              setPageType={TypeHandler}
              setModal={setOpenModal}
              step={step}
              status={status}
              available={Available}
              statusHandler={ActivityStatusHandler}
              ogPrice={ogPrice}
              ogPriceM={ogPriceM}
              ogPriceC={ogPriceC}
              MCCount={MCCount}
              setMCCount={setMCCount}
              MCCountOg={MCCountOg}
              TypeOg={TypeOg}
              setChange={setChange}
            />

            <ActivitySchedule
              open={pageType == 'schedule'}
              DAT={{
                activity_name: data.activity_name,
                activity_location: data.activity_location
              }}
              modal={openModal}
              aId={Id}
              pageType={pageType}
              setPageType={TypeHandler}
              setModal={setOpenModal}
              step={step}
              setStep={setStep}
              status={status}
              setStatus={setStatus}
              available={Available}
              statusHandler={ActivityStatusHandler}
              type={data?.activity_type}
              setChange={setChange}
            />

            <ActivityKeyword
              open={pageType == 'etc'}
              DAT={{
                activity_name: data.activity_name,
                recommend: data.recommend, // 추천 대상 (*)
                require: data.require, // 자격 대상
                caution: data.caution,  // 유의 사항 (*)
                background : data.background,
                facility_time : data.facility_time,
                facility_event : data.facility_event,
                facility_discount : data.facility_discount,
                activity_type : data.activity_type,
                ticket_share : data.ticket_share,
                keyword : data.keyword
              }}
              modal={openModal}
              aId={Id}
              pageType={pageType}
              setPageType={TypeHandler}
              setModal={setOpenModal}
              step={step}
              setStep={setStep}
              status={status}
              setStatus={setStatus}
              available={Available}
              statusHandler={ActivityStatusHandler}
              setChange={setChange}
            />

          <Modal setPageType={setPageType} modal={openModal} setModal={setOpenModal} />
      </Container>
  );
};

export default ActivityRegister;

const Container = styled.section`
  height: calc(var(--vh, 1vh) * 100);
  overflow-x: hidden;
  width: 100%;
  padding-bottom : 80px;
  position : relative;
  z-index: 11;
`;
