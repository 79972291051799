import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import axios from "axios";


//tool
import { GetOne } from "../../tool/localstorage";

//style
import { Wrapper } from "../layouts/layoutStorage";
import colors from "../../styles/colors";

//component
import ActivityModal from "./NewActivityModal";
import TagSelector from "../newActivityRegister/TagSelector";

//icon

const POPULAR_PREPARE = ["수영복", "세면도구", "수건"];
const POPULAR_INCLUDE = ["장비 렌탈비", "슈트 렌탈비", "풀장 입장료"];

const ActivityPriceModal = ({ data, setData, open, setOpen, deleteD, setDelete, setChange }) => {

    const [activityItem, setActivityItem] = useState({
        prepare: [], // 준비물 (*)
        include: [], // 포함 사항 (*)
        exclude: []  // 불포함 사항 (*)
    });

    const [deleteItem, setDeleteItem] = useState({
        prepare: [], // 준비물 (*)
        include: [], // 포함 사항 (*)
        exclude: []  // 불포함 사항 (*)
    });

    const [prepareSearch, setPrepareSearch] = useState([]);
    const [includeSearch, setIncludeSearch] = useState([]);
    const [excludeSearch, setExcludeSearch] = useState([]);

    useEffect(() => {
        if (open) {
            setActivityItem({
                prepare: data.prepare,
                include: data.include,
                exclude: data.exclude
            });
        };
    }, [open]);

    const getBigData = (searchType, searchText) => {
        let token = GetOne("token");
        const frm = new FormData();
        frm.append("mode", "hot_tag");
        frm.append("token", token);
        frm.append("category", searchType);
        frm.append("text", searchText);
        axios
            .post("https://ai.semos.kr/semos_partner/v2/activity/ActivityKeyword", frm, {
                headers: { "Content-Type": "multipart/form-data" },
            })
            .then((data) => {
                const value = data.data;
                const status = value.status;
                const statusMessage = value.status_message;
                const registerText = value.text_list;
        
                if (status === "success") {
                    if (searchType === "prepare") {
                        setPrepareSearch(registerText);
                    } else if (searchType === "include") {
                        setIncludeSearch(registerText);
                    } else if (searchType === "exclude") {
                        setExcludeSearch(registerText);
                    }
                } else {
                window.alert(statusMessage);
                }
            });
    };

    return (
        <ActivityModal
            buttonText="저장하기"
            buttonActive={activityItem.prepare.length && activityItem.include.length && activityItem.exclude.length}
            title="액티비티 포함 사항 / 준비물"
            open={open}
            setOpen={setOpen}
            saveData={() => {
                setData((prev) => ({
                    ...prev,
                    prepare: activityItem.prepare,
                    include: activityItem.include,
                    exclude: activityItem.exclude
                }));
                setDelete((prev) => ({
                    ...prev,
                    prepare: deleteItem.prepare,
                    include: deleteItem.include,
                    exclude: deleteItem.exclude
                }));
                setOpen(false);
                setChange(true);
            }}
        >

        <Wrapper fd="column" height="100%" width="100%" ai="flex-start" padding="0 0 130px 0">

            <Wrapper padding={'0 24px'} margin="8px 0 12px 0" wrap="wrap">
            <TextP color={colors.Gr04}>
                {`액티비티 판매 가격에 포함된 / 불포함된 서비스와 금액을 적어주시고,\n액티비티에 꼭 필요한 준비물을 적어주세요!`}
            </TextP>
            </Wrapper>

            <Wrapper padding={'0 24px 24px 24px'} height="100%" fd="column" width="100%" ai="flex-start" of="auto">

                {/* 포함 사항  */}
                <TagSelector
                    data={activityItem}
                    setData={setActivityItem}
                    bigData={getBigData}
                    textRecommend={includeSearch}
                    deleteD={deleteItem}
                    setDelete={setDeleteItem}
                    POPULAR_LIST={POPULAR_INCLUDE}
                    titleText={"포함 사항"}
                    requirementText={"필수사항"}
                    placeholderText={"# 태그로 검색해 보세요. 예) 장비렌탈"}
                    type={"include"}
                />

                {/* 불포함 사항 */}
                <TagSelector
                    data={activityItem}
                    setData={setActivityItem}
                    bigData={getBigData}
                    textRecommend={excludeSearch}
                    deleteD={deleteItem}
                    setDelete={setDeleteItem}
                    POPULAR_LIST={[]}
                    titleText={"불포함 사항"}
                    tipText={"액티비티 금액 외 추가결제 항목의 금액을 명시해야해요\n예시) 풀장입장료(15,000원)"}
                    requirementText={"필수사항"}
                    placeholderText={"# 태그로 검색해 보세요. 예) 풀장입장료(15,000원)"}
                    type={"exclude"}
                />
                        
                {/* 준비물  */}
                <TagSelector
                    data={activityItem}
                    setData={setActivityItem}
                    bigData={getBigData}
                    textRecommend={prepareSearch}
                    deleteD={deleteItem}
                    setDelete={setDeleteItem}
                    POPULAR_LIST={POPULAR_PREPARE}
                    titleText={"준비물"}
                    requirementText={"필수사항"}
                    placeholderText={"# 태그로 검색해 보세요. 예) 수영복"}
                    type={"prepare"}
                />

            </Wrapper>

        </Wrapper>

        </ActivityModal>
    );
};

export default ActivityPriceModal;

const TextP = styled.p`
    font-size: 14px;
    font-weight: 500;
    line-height: 22px;
    letter-spacing: -0.28px;
    color: ${colors.Gr04};
    word-break: keep-all;
    span {
        color: ${colors.Red03};
        word-break: keep-all;
    }
`;

