import React, { useState, useRef, useEffect } from "react";
import styled, { css } from "styled-components";
import { useNavigate, Link } from "react-router-dom";
import axios from "axios";

// component
import { kakaoChannel } from "../tool/kakao";
import colors from "../styles/colors";
import { PC, W } from "../styles/globalStyles";
import { Wrapper, Text } from "../components/layouts/layoutStorage";
import Header from "../components/basic/Header";
import BasicConfirmModal from "../components/basic/basic_confirm";
import BasicAlert from "../components/basic/basic_alert";

// tool
import { GetOne, SaveOne } from "../tool/localstorage";
import { fbevent } from "../tool/firebase";
import { LoadingSpinner } from "../tool/loading";

// img
import { ReactComponent as LightBulb } from "../resource/icon/light_bulb.svg";
import { ReactComponent as Question } from "../resource/icon/question.svg";
import { ReactComponent as CustomerService } from "../resource/icon/customer_service.svg";

const Settings = () => {
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [logOutModal, setLogOutModal] = useState(false);
  const [logOutAlertModal, setLogOutAlertModal] = useState(false);
  const [appVersion, setAppVersion] = useState("");
  const [retire, setRetire] = useState(false);

  const handleLogOut = () => {
    setTimeout(() => {
      navigate("/", { replace: true });
    }, 1000)
  };

  const handleRetire = () => {
    if (
      window.confirm(
        "회원탈퇴를 진행하시겠습니까?\n삭제된 계정은 복구할 수 없습니다."
      )
    ) {
      setLoading(true);

      const token = GetOne("token");

      const frm = new FormData();

      frm.append("mode", "user_retire");
      frm.append("token", token);

      axios
        .post("https://ai.semos.kr/semos_partner/auth/profile", frm, {
          headers: {
            "Content-Type": "multipart/form-data",
            Accept: "application/json",
          },
        })
        .then((data) => {
          setLoading(false);

          window.alert("회원탈퇴가 완료되었습니다.");
          localStorage.clear();
          navigate("/", { replace: true });
        });
    }
  };

  useEffect(() => {
    fbevent("B2B_Setting_Page_View", {
      email : GetOne("email")
    });

    const frm = new FormData();

    frm.append("mode", "main");

    axios
      .post("https://ai.semos.kr/semos_partner/v2/profile/Settings", frm, {
        headers: {
          "Content-Type": "multipart/form-data",
          Accept: "application/json",
        },
      })
      .then((res) => {
        const value = res.data;
        setAppVersion(value.version);
        setRetire(value.retire);
      });
  });

  return (
    <Wrapper fd="column" width="100%" bgc={colors.White} cursor="pointer">
      <Header title="설정" back={true} />
      <Wrapper width="100%" padding="24px 24px 16px 24px" ta="start">
        <Text size="14px" weight="600" color={colors.Gr04}>
          고객센터
        </Text>
      </Wrapper>

      <Wrapper
        width="100%"
        padding="12px 24px"
        cursor="pointer"
        onClick={() => navigate("/announcement")}
      >
        <IconTextContainer color={colors.Gr06}>
          <LightBulb fill={colors.Bl07} />
          공지사항
        </IconTextContainer>
      </Wrapper>

      <Wrapper
        width="100%"
        padding="12px 24px"
        cursor="pointer"
        onClick={() => navigate("/faq")}
      >
        <IconTextContainer color={colors.Gr06}>
          <Question fill={colors.Bl07} />
          자주 묻는 질문
        </IconTextContainer>
      </Wrapper>

      <Wrapper
        width="100%"
        padding="12px 24px"
        cursor="pointer"
        onClick={() => {
          if (window.document.documentElement.clientWidth > PC) {
            window.open("http://pf.kakao.com/_CBUHxj", "_blank");
          } else {
            kakaoChannel();
          }
        }}
      >
        <IconTextContainer color={colors.Gr06}>
          <CustomerService fill={colors.Bl07} />
          고객센터 문의 <ClientCenterTime>10:00 ~ 19:00</ClientCenterTime>
        </IconTextContainer>
      </Wrapper>

      <Divider h={16} />

      <Wrapper width="100%" padding="24px 24px 16px 24px" ta="start">
        <Text size="14px" weight="600" color={colors.Gr04}>
          정보
        </Text>
      </Wrapper>

      <Wrapper
        jc="space-between"
        width="100%"
        padding="12px 24px"
        cursor="pointer"
      >
        <IconTextContainer color={colors.Gr06}>버전 정보</IconTextContainer>
        <AppVersion>{appVersion}</AppVersion>
      </Wrapper>

      <Wrapper
        width="100%"
        padding="12px 24px"
        cursor="pointer"
        onClick={() => navigate("/partner_info_policy")}
      >
        <IconTextContainer color={colors.Gr06}>
          개인정보처리방침
        </IconTextContainer>
      </Wrapper>

      <Wrapper
        width="100%"
        padding="12px 24px"
        cursor="pointer"
        onClick={() => navigate("/partner_use_info")}
      >
        <IconTextContainer color={colors.Gr06}>이용약관</IconTextContainer>
      </Wrapper>

      <Divider h={8} />

      <Wrapper
        width="100%"
        padding="10px 24px"
        cursor="pointer"
        onClick={() => setLogOutModal(true)}
      >
        <IconTextContainer color={colors.Gr04}>로그아웃하기</IconTextContainer>
      </Wrapper>

      {retire && (
        <Wrapper
          width="100%"
          padding="10px 24px"
          cursor="pointer"
          onClick={() => handleRetire()}
        >
          <IconTextContainer color={colors.Gr04}>회원탈퇴</IconTextContainer>
        </Wrapper>
      )}

      <Divider h={16} />

      <BasicConfirmModal
        modal={logOutModal}
        setModal={setLogOutModal}
        title="로그아웃 하시겠습니까?"
        small="다시 로그인해서 세모스 파트너에 올 수 있어요!"
        leftText="로그아웃"
        rightText="취소"
        leftFunc={() => {
          setLogOutModal(false);
          setLogOutAlertModal(true);
          const LoginType = GetOne("login_type");
          window.localStorage.clear();
          
          SaveOne('lastLogin', LoginType);
        }}
        rightFunc={() => setLogOutModal(false)}
      />

      <BasicAlert
        modal={logOutAlertModal}
        setModal={setLogOutAlertModal}
        title="로그아웃이 완료되었습니다."
        small="세모스 파트너에 다시 오실 때까지 기다릴게요."
        text="확인"
        func={() => handleLogOut()}
      />

      <LoadingSpinner
        loading={loading}
        title={`정보를 확인하고 있습니다.`}
        content={`잠시만 기다려 주세요!`}
      />
    </Wrapper>
  );
};

export default Settings;

const Divider = styled.div`
  height: ${({ h }) => h}px;
`;

const IconTextContainer = styled.div`
  display: flex;
  align-items: center;
  font-weight: 600;
  font-size: 14px;
  line-height: 22px;
  color: ${({ color }) => color};

  svg {
    width: 24px;
    height: 24px;
    margin-right: 8px;
  }
`;

const AboutAppIcon = styled.img`
  width: 24px;
  height: 24px;
  margin-right: 8px;
`;

const ClientCenterTime = styled.span`
  font-size: 14px;
  font-weight: 500;
  line-height: 22px;
  letter-spacing: -0.02em;
  color: ${colors.Gr03};
  margin-left: 4px;
`;

const AppVersion = styled.span`
  display: inline-block;
  font-size: 14px;
  color: ${colors.Gr04};
  font-weight: 500;
`;
