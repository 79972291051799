import React, { useState, useEffect } from "react";
import styled from "styled-components";
import axios from "axios";
import Sheet from "react-modal-sheet";

//style
import { Wrapper, Text, Input } from "../layouts/layoutStorage";
import colors from "../../styles/colors";
import { W } from "../../styles/globalStyles";

//component
import ActivityLocationModal from "./LocationScheduleModal";
import ActivityFacilityModal from "./FacilityScheduleModal";
import ActivityClassModal from "./ClassScheduleModal";
import ScheduleListModal from "./ScheduleListModal";
import Toast from "../elements/Toast";
import { GetOne } from "../../tool/localstorage";
import ActivityHeader from "./NewActivityHeader";

//icon
import { ReactComponent as ModalCloseIcon } from "../../resource/icon/close_circle.svg";
import { ReactComponent as Arrow } from "../../resource/icon/arrow.svg";
import { ReactComponent as Plus } from "../../resource/icon/plus.svg";
import { Loading } from "../../tool/Loading";

//default
const TypeEng = {
  "정기 레슨" : "month",
  "이용권(횟수)" : "count",
  "이용권(기간)" : "count",
  "단기 레슨" : "people",
  "국내/해외 투어" : "people",
  "자격증" : "people",
  "장비 대여" : "people"
}

const ActivitySchedule = ({
  open,
  DAT,
  status,
  aId,
  setPageType,
  available,
  statusHandler,
  type,
  setModal,
  setChange
}) => {

  const [loading, setLoading] = useState(true);
  const [data, setData] = useState({
    activity_location: [],
    activity_name : ""
  });
  const [deleteD, setDelete] = useState({
    activity_location: []
  });
  const [addressList, setSddressList] = useState([]);
  const [facilityList, setFacilityList] = useState([]);
  const [facilityLocation, setFacilityLocation] = useState([]);
  const [scheduleListModal, setScheduleListModal] = useState(false);
  const [locationModal, setLocationModal] = useState(false);
  const [facilityModal, setFacilityModal] = useState(false);
  const [classModal, setClassModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState({
    type: "",
    open: false,
  });
  const [showAddressIndex, setShowAddressIndex] = useState(0);
  const [deleteAddress, setDeleteAddress] = useState({});
  const [first, setFirst] = useState(false);
  const [showAddress, setShowAddress] = useState({
    address: "",
    address_extra: "",
    address_dong: "",
    address_gu: "",
    address_si: "",
    title: "",
  });
  const [newSchedule, setNewSchedule] = useState({
    address: "",
    address_extra: "",
    boxColor: false,
    toast: false,
  });

  useEffect(() => {
    if(first === true) {
      const Type = TypeReturn();

      if(Type == 'people') {
        makeAddressList(data);
      } else {
        makeFacilityList(data);
      }
    } else {
      const Type = TypeReturn();

      if(Type == 'people') {
        makeAddressList(data);
      } else {
        makeFacilityList(data);
      }
    }
  }, [deleteModal.open, scheduleListModal, locationModal, facilityModal, classModal]);

  useEffect(() => {
    if(DAT) {
      if(DAT.activity_name) {
        if(!first) { 
          setData(DAT);
          setFirst(true);

          const Type = TypeReturn();
          if(Type == 'people') {
            makeAddressList(DAT);
          } else {
            makeFacilityList(DAT);
          }
        }
      }
    }
  }, [DAT]);

  // 중복된 장소 데이터들을 소거 하여 장소배열로 만듦
  const makeAddressList = (data) => {
    let addressArray = [];
    data.activity_location.map((item, index) => {
      if (addressArray.findIndex((el, idx) => (el.address === item.address.address) && (el.address_extra === item.address.address_extra)) === -1) {
        addressArray.push(item.address);
      }
    });
    setSddressList(addressArray);
  };

  // 중복된 장소 데이터들을 소거 하여 장소배열로 만듦
  const makeFacilityList = (data) => {
    let addressArray = [];
    data.activity_location.map((item, index) => {
      if (addressArray.findIndex((el, idx) => (el.title === item.title)) === -1) {

        let FacilityAddress = item.address;
        FacilityAddress.title = item.title;

        addressArray.push(FacilityAddress);
      }
    });
    setSddressList(addressArray);
  };

  const activeCheck = () => {
    let checkLocationAllFilled = data.activity_location.every(
      (item) => item.days.length && item.start.length && item.address.address && item.address.address_extra
    );
    return data.activity_location.length && checkLocationAllFilled;
  };

  const checkNewSchedule = (item) => {
    return newSchedule.boxColor && newSchedule.address === item.address && newSchedule.address_extra === item.address_extra;
  };

  const temporarySaveData = () => {

    if (activeCheck()) { 

      setLoading(true);
      const token = GetOne("token");
      axios.post(
          "https://ai.semos.kr/semos_partner/v3/activity/ActivitySchedule",
          {
            mode: "save",
            token: token,
            data: data,
            delete: deleteD,
            activity_id: aId
          },
          {
            headers: {
              "Content-Type": "application/x-www-form-urlencoded",
            },
          }
        ).then((data) => {
          let Value = data.data;
          let Status = Value.status;
          let StatusMessage = Value.status_message;

          if (Status == "success") {
            setModal({
              status: true,
              type: "revise",
              link: `/activity_manage`,
            });
            setChange(false);
            setLoading(false);
          } else {
            setLoading(true);
            window.alert(StatusMessage);
            window.location.reload();
          }
      });
    } else {
      window.alert('입력하지 않은 일정 정보가 있어요!\n일정 정보를 확인해주세요!')
    }

  };

  const justSaveData2 = (URL) => {
    if (activeCheck()) { 

      setLoading(true);
      const token = GetOne("token");
      axios.post(
          "https://ai.semos.kr/semos_partner/v3/activity/ActivitySchedule",
          {
            mode: "save",
            token: token,
            data: data,
            delete: deleteD,
            activity_id: aId
          },
          {
            headers: {
              "Content-Type": "application/x-www-form-urlencoded",
            },
          }
        ).then((data) => {
          let Value = data.data;
          let Status = Value.status;
          let StatusMessage = Value.status_message;

          if (Status == "success") {
            setModal({
              status: true,
              type: "just_save2",
              link: URL ? URL : `/activity_manage`,
            });
            setChange(false);
            setLoading(false);
          } else {
            setLoading(true);
            window.alert(StatusMessage);
            window.location.reload();
          }
      });
    } else {
      window.alert('입력하지 않은 일정 정보가 있어요!\n일정 정보를 확인해주세요!')
    }

  };

  const GetFacility = () => {

    setLoading(true);
    const token = GetOne("token");

    axios.post(
      "https://ai.semos.kr/semos_partner/v3/activity/Facility",
      {
        mode: "facility_list",
        token: token,
        set_facility: []
      },
      {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      }
    ).then((data) => {
      let Value = data.data;
      let Status = Value.status;
      let StatusMessage = Value.status_message;
      let FacilityList = Value.facility_list;
      let FacilityLocation = Value.facility_location;

      if (Status == "success") {
        setLoading(false);
        setFacilityList(FacilityList);
        setFacilityLocation(FacilityLocation);
      } else {
        setLoading(true);
        window.alert(StatusMessage);
        window.location.href = '/home';
      }
  });
  }

  const deleteSchedule = (scheduleAddress) => {
    let deleteCopy = [...deleteD.activity_location];
    let dataCopy = [...data.activity_location];

    dataCopy = dataCopy.filter((val, index) => {
      if (val.address.address === scheduleAddress.address && val.address.address_extra === scheduleAddress.address_extra) {
        deleteCopy.push(val.id);
      } else {
        return val;
      }
    });
    setData((prev) => ({ ...prev, activity_location: dataCopy }));
    setDelete((prev) => ({ ...prev, activity_location: deleteCopy }));
    setDeleteAddress({});
  };

  const saveData = () => {
    temporarySaveData();
  };

  const TypeReturn = () => {
    var Type = type;
    var PriceType = TypeEng[Type];

    if(PriceType) {
      return PriceType
    } else {
      return ""
    }
  };


  useEffect(() => {
    GetFacility(false);
  }, []);

  const LoadScheduleSelector = () => {

    const Type = TypeReturn();

    switch(Type) {
      case 'people': 
        return (
          <Wrapper 
            width="100%" ai="flex-start" gap="8px" fd="column" padding="24px 24px 8px 24px"
          >
            <Text size="16px" weight="600" lh="24px" ls="-0.32px" color={colors.Gr05}>
              액티비티 진행 장소 및 진행 시간
            </Text>
            {addressList.map((item, index) => (
              <Wrapper key={index} fd="column" width="100%" gap="4px">
                <Wrapper
                  key={`${item.address}_${index}`}
                  onClick={() => {
                    setShowAddressIndex(data.activity_location.length);
                    setShowAddress(item);
                    setLocationModal(true);
                    checkNewSchedule(item) &&
                      setNewSchedule({
                        address: "",
                        address_extra: "",
                        boxColor: false,
                        toast: false,
                      });
                  }}
                  cursor="pointer"
                  jc="space-between"
                  width="100%"
                  height="56px"
                  padding="8px 16px"
                  border={checkNewSchedule(item) ? `1px solid ${colors.Bl07}` : `1px solid ${colors.Gr02}`}
                  bgc={checkNewSchedule(item) ? colors.Bl01 : colors.Gr01}
                  radius="8px"
                >
                  <Wrapper width="calc(100% - 24px)" gap="4px">
                    {checkNewSchedule(item) && (
                      <Text size="12px" weight="600" lh="20px" ls="-0.24px" color={colors.Bl07}>
                        NEW
                      </Text>
                    )}
                    <Text ellipsis={true} clamp={1} size="16px" weight="500" lh="24px" ls="-0.32px" color={colors.Gr05}>
                      {item.address} {item.address_extra}
                    </Text>
                  </Wrapper>
                  <Arrow width="24px" height="24px" fill={colors.Gr06} />
                </Wrapper>

                <Text
                  onClick={() => {
                    setDeleteAddress(item);
                    setDeleteModal({
                      type: "schedule",
                      open: true,
                    });
                    setChange(true);
                  }}
                  cursor="pointer"
                  style={{ alignSelf: "end" }}
                  size="14px"
                  weight="600"
                  lh="22px"
                  color={colors.Gr04}
                >
                  일정 삭제
                </Text>
              </Wrapper>
            ))}

            <Wrapper
              onClick={() => {
                setShowAddressIndex(0);
                setShowAddress({
                  address: "",
                  address_extra: "",
                  address_dong: "",
                  address_gu: "",
                  address_si: "",
                });
                setLocationModal(true);
                setChange(true);
              }}
              cursor="pointer"
              jc="center"
              width="100%"
              height="56px"
              padding="8px 16px"
              border={`1px solid ${colors.Gr02}`}
              radius="8px"
              gap="8px"
            >
              <Text size="16px" weight="500" lh="24px" ls="-0.32px" color={colors.Gr05}>
                장소 및 진행 시간 추가
              </Text>
              <Plus width="24px" height="24px" fill={colors.Gr05} />
            </Wrapper>

          </Wrapper>
        )

      case 'month': 
        return (
          <Wrapper width="100%" ai="flex-start" gap="8px" fd="column" padding="24px 24px 8px 24px">
            <Text size="16px" weight="600" lh="24px" ls="-0.32px" color={colors.Gr05}>
              액티비티 진행 장소 및 진행 시간
            </Text>
            {addressList.map((item, index) => (
              <Wrapper key={index} fd="column" width="100%" gap="4px">
                <Wrapper
                  key={`${item.address}_${index}`}
                  onClick={() => {
                    setShowAddressIndex(data.activity_location.length);
                    setShowAddress(item);
                    setClassModal(true);
                  }}
                  cursor="pointer"
                  jc="space-between"
                  width="100%"
                  height="56px"
                  padding="8px 16px"
                  border={checkNewSchedule(item) ? `1px solid ${colors.Bl07}` : `1px solid ${colors.Gr02}`}
                  bgc={checkNewSchedule(item) ? colors.Bl01 : colors.Gr01}
                  radius="8px"
                >
                  <Wrapper width="calc(100% - 24px)" gap="4px">
                    <Text ellipsis={true} clamp={1} size="16px" weight="500" lh="24px" ls="-0.32px" color={colors.Gr05}>
                      {item.title}
                    </Text>
                  </Wrapper>
                  <Arrow width="24px" height="24px" fill={colors.Gr06} />
                </Wrapper>

                <Text
                  onClick={() => {
                    setDeleteAddress(item);
                    setDeleteModal({
                      type: "schedule",
                      open: true,
                    });
                    setChange(true);
                  }}
                  cursor="pointer"
                  style={{ alignSelf: "end" }}
                  size="14px"
                  weight="600"
                  lh="22px"
                  color={colors.Gr04}
                >
                  일정 삭제
                </Text>
              </Wrapper>
            ))}

            <Wrapper
              onClick={() => {
                setShowAddressIndex(0);
                setShowAddress({
                  address: "",
                  address_extra: "",
                  address_dong: "",
                  address_gu: "",
                  address_si: "",
                });
                setClassModal(true);
                setChange(true);
              }}
              cursor="pointer"
              jc="center"
              width="100%"
              height="56px"
              padding="8px 16px"
              border={`1px solid ${colors.Gr02}`}
              radius="8px"
              gap="8px"
            >
              <Text size="16px" weight="500" lh="24px" ls="-0.32px" color={colors.Gr05}>
                장소 및 진행 시간 추가
              </Text>
              <Plus width="24px" height="24px" fill={colors.Gr05} />
            </Wrapper>

          </Wrapper>
        )

      case 'count': 
        return (
          <Wrapper width="100%" ai="flex-start" gap="8px" fd="column" padding="24px 24px 8px 24px">
            <Text size="16px" weight="600" lh="24px" ls="-0.32px" color={colors.Gr05}>
              액티비티 진행 장소 및 진행 시간
            </Text>

            {addressList.map((item, index) => (
              <Wrapper key={index} fd="column" width="100%" gap="4px">
                <Wrapper
                  key={`${item.address}_${index}`}
                  onClick={() => {
                    setShowAddressIndex(data.activity_location.length);
                    setShowAddress(item);
                    setFacilityModal(true);
                  }}
                  cursor="pointer"
                  jc="space-between"
                  width="100%"
                  height="56px"
                  padding="8px 16px"
                  border={`1px solid ${colors.Gr02}`}
                  bgc={colors.Gr01}
                  radius="8px"
                >
                  <Wrapper width="calc(100% - 24px)" gap="4px">
                    <Text ellipsis={true} clamp={1} size="16px" weight="500" lh="24px" ls="-0.32px" color={colors.Gr05}>
                      {item.title}
                    </Text>
                  </Wrapper>
                  <Arrow width="24px" height="24px" fill={colors.Gr06} />
                </Wrapper>

                <Text
                  onClick={() => {
                    setDeleteAddress(item);
                    setDeleteModal({
                      type: "schedule",
                      open: true,
                    });
                    setChange(true);
                  }}
                  cursor="pointer"
                  style={{ alignSelf: "end" }}
                  size="14px"
                  weight="600"
                  lh="22px"
                  color={colors.Gr04}
                >
                  일정 삭제
                </Text>
              </Wrapper>
            ))}

            <Wrapper
              onClick={() => {
                setShowAddressIndex(0);
                setShowAddress({
                  address: "",
                  address_extra: "",
                  address_dong: "",
                  address_gu: "",
                  address_si: "",
                  title: ""
                });
                setFacilityModal(true);
                setChange(true);
              }}
              cursor="pointer"
              jc="center"
              width="100%"
              height="56px"
              padding="8px 16px"
              border={`1px solid ${colors.Gr02}`}
              radius="8px"
              gap="8px"
            >
              <Text size="16px" weight="500" lh="24px" ls="-0.32px" color={colors.Gr05}>
                장소 및 진행 시간 추가
              </Text>
              <Plus width="24px" height="24px" fill={colors.Gr05} />
            </Wrapper>
          </Wrapper>
        )

      default: 
        return (
          <></>
        )
    }

  }

  const Reset = () => {
    setData(DAT);
    setDelete({
      activity_location: []
    })
  }

  return (
    open ? 
      <>
        {!loading ? (
          <>
            <ActivityHeader
              status={status}
              title="액티비티 수정"
              available={available}
              statusHandler={statusHandler} />

              {
                (aId != 'new') &&
                  <Wrapper 
                    ai="center" jc="space-between" width="100%" padding="24px 24px 8px 24px"
                    style={{
                      position : "fixed",
                      maxWidth: '1300px',
                      top : "40px",
                      left : "50%",
                      transform: "translate(-50%, 0)",
                      backgroundColor : "#ffffff",
                      zIndex : 10
                    }}    
                  >
                            
                    <Wrapper 
                      onClick={() => setPageType('info', justSaveData2, Reset)}
                      ai="center" 
                      jc="center" 
                      radius="8px"
                      bgc={colors.White}
                      border={`1px solid ${colors.Gr02}`}
                      padding={`6px 0`}
                      width="30%">
                      <Text ta="center" size="16px" weight="600" lh="24px" ls="-0.28px" color={colors.Gr05}>
                        기본 정보
                      </Text>
                    </Wrapper>

                    <Wrapper 
                      //onClick={() => setPageType('schedule', justSaveData2)}
                      ai="center" 
                      jc="center" 
                      radius="8px"
                      bgc={colors.Bl07} 
                      padding={`6px 0`}
                      width="30%">
                      <Text ta="center" size="16px" weight="600" lh="24px" ls="-0.28px" color={colors.White}>
                        일정 정보
                      </Text>
                    </Wrapper>

                    <Wrapper 
                      onClick={() => setPageType('etc', justSaveData2, Reset)}
                      ai="center" 
                      jc="center" 
                      radius="8px"
                      bgc={colors.White} 
                      border={`1px solid ${colors.Gr02}`}
                      padding={`6px 0`}
                      width="30%">
                      <Text ta="center" size="16px" weight="600" lh="24px" ls="-0.28px" color={colors.Gr05}>
                        추가 정보
                      </Text>
                    </Wrapper>

                  </Wrapper>
              }

              <Wrapper ai="flex-start" fd="column" width="100%" height={`80px`} padding="24px 24px 8px 24px"/>

              <Wrapper width="100%" fd="column" of="auto" padding="0 0 240px 0">

                {LoadScheduleSelector()}

              </Wrapper>
              
              <BottomWrap>
                <Wrapper
                  onClick={() => saveData()}
                  jc="center"
                  ai="center"
                  width="100%"
                  height="52px"
                  cursor={activeCheck() ? "pointer" : "default"}
                  radius="8px"
                  border=""
                  bgc={activeCheck() ? colors.Bl07 : colors.Gr01}
                >
                  <Text ta="center" size="14px" weight="500" lh="22px" ls="-0.28px" color={activeCheck() ? colors.White : colors.Gr03}>
                      일정 정보 수정하기
                  </Text>
                </Wrapper>
              </BottomWrap>

              {newSchedule.toast && (
                <Toast bottom="100px" width="217px">
                  <span>등록된 일정을 불러왔어요</span>
                </Toast>
              )}

              <ActivityLocationModal
                showAddressIndex={showAddressIndex}
                showAddress={showAddress}
                data={data}
                setData={setData}
                open={locationModal}
                setOpen={setLocationModal}
                deleteD={deleteD}
                setDelete={setDelete}
                setChange={setChange}
              />

              <ActivityFacilityModal
                showAddressIndex={showAddressIndex}
                showAddress={showAddress}
                facilityList={facilityList}
                facilityLocation={facilityLocation}
                data={data}
                setData={setData}
                open={facilityModal}
                setOpen={setFacilityModal}
                deleteD={deleteD}
                setDelete={setDelete}
                setChange={setChange}
              />

              <ActivityClassModal
                showAddressIndex={showAddressIndex}
                showAddress={showAddress}
                facilityList={facilityList}
                facilityLocation={facilityLocation}
                data={data}
                setData={setData}
                open={classModal}
                setOpen={setClassModal}
                deleteD={deleteD}
                setDelete={setDelete}
                setChange={setChange}
              />

              <ScheduleListModal
                open={scheduleListModal}
                setOpen={setScheduleListModal}
                data={data}
                setData={setData}
                newSchedule={newSchedule}
                setNewSchedule={setNewSchedule}
                setChange={setChange}
              />

              <Modal
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  left: "50%",
                  transform: "translate(-50%,0)",
                }}
                isOpen={deleteModal.open}
                snapPoints={[0.25]}
              >
                <Modal.Container style={{ width: `${W}px`, left: "none", padding: "24px" }}>
                  <Wrapper width="100%" jc="space-between" ai="flex-start">
                    <Text size="18px" weight="700" lh="26px" ls="-0.36px">
                      입력된 정보가 있어요!
                      <br />
                      그래도 삭제할까요?
                    </Text>
                    <ModalCloseIcon
                      onClick={() =>
                        setDeleteModal({
                          type: "",
                          open: false,
                        })
                      }
                      width="28px"
                      height="28px"
                      fill={colors.Gr03}
                      style={{ cursor: "pointer" }}
                    />
                  </Wrapper>
                  <Wrapper margin="48px 0 0 0" gap="8px" width="100%" jc="space-between">
                    <Wrapper
                      cursor="pointer"
                      width="100%"
                      radius="8px"
                      height="52px"
                      jc="center"
                      ai="center"
                      bgc={colors.Gr01}
                      onClick={() =>
                        setDeleteModal({
                          type: "",
                          open: false,
                        })
                      }
                    >
                      <Text size="16px" weight="500" lh="24px" ls="-0.32px" color={colors.Gr05}>
                        취소
                      </Text>
                    </Wrapper>
                    <Wrapper
                      cursor="pointer"
                      width="100%"
                      radius="8px"
                      height="52px"
                      jc="center"
                      ai="center"
                      bgc={colors.Bl07}
                      onClick={() => {
                        if (deleteModal.type === "schedule") {
                          deleteSchedule(deleteAddress);
                          setChange(true);
                        }
                        setDeleteModal({
                          type: "",
                          open: false,
                        });
                      }}
                    >
                      <Text size="16px" weight="500" lh="24px" ls="-0.32px" color={colors.White}>
                        삭제하기
                      </Text>
                    </Wrapper>
                  </Wrapper>
                </Modal.Container>
                <Modal.Backdrop />
              </Modal>
          </>
        ) : (
          <Loading />
        )}
      </>
    : <></>
  );
};

export default ActivitySchedule;

const BottomWrap = styled.div`
  width: 100%;
  max-width: 1300px;
  transform : translate(-50%, 0);
  display: flex;
  padding: 12px 24px;
  border-top: 1px solid ${colors.Gr01};
  background-color: ${colors.White};
  justify-content: space-between;
  align-items: center;
  z-index: 10;
  position: fixed;
  bottom : 0;
  left : 50%;
  gap: 8px;
`;

const Modal = styled(Sheet)``;
