import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import axios from "axios";

// component
import Header from "../components/basic/Header";
import { GetOne } from "../tool/localstorage";
import { LoadingSpinner } from "../tool/loading";
import { fbevent } from "../tool/firebase";

// style
import colors from "../styles/colors";

// img
import BlueWarning from "../icon/blueWarning.png";
import ArrowIcon from "../icon/reviewArrow.png";

function AnnouncementList() {
  const navigate = useNavigate();

  const [loading, setLoading] = useState(true);
  const [list, setList] = useState([]);

  useEffect(() => {
    fbevent("B2B_Announcement_Page_View", {
      email : GetOne("email")
    });

    const token = GetOne("token");
    const frm = new FormData();

    frm.append("mode", "notice_list");
    frm.append("token", token);

    axios
      .post("https://ai.semos.kr/semos_partner/notice/data", frm, {
        headers: { "Content-Type": "multipart/form-data" },
      })
      .then((res) => {
        const data = res.data;

        if (data.status == "success") {
          setList(data.notice_list);
          setLoading(false);
        } else {
          window.alert("잘못된 접근입니다.");
          setLoading(false);
        }
      });
  }, []);

  return (
    <Container>
      <Header title="공지사항" back={true} />
      <Styler
        flex={true}
        justify="space-between"
        pt={24}
        pl={24}
        pr={24}
        pb={12}
      >
        <JustBetween>
          <WarningImg src={BlueWarning} />
          <Gongji>공지사항</Gongji>
        </JustBetween>
        <LengthText>총 {list.length}개</LengthText>
      </Styler>
      {list.map((el, index) => (
        <AnnounceItem
          key={el.notice_id}
          onClick={() =>
            navigate("/announcement_detail" + "/" + el.notice_id, {
              state: { date: el.notice_date },
            })
          }
        >
          <Styler
            pt={16}
            pb={16}
            pl={24}
            pr={24}
            flex={true}
            justify="space-between"
            align="center"
          >
            <JustBetween>
              <SemosAnnounceMark>세모스 공지</SemosAnnounceMark>
              <SemosAnnounceItem>
                <AnnounceTitle>{el.notice_name}</AnnounceTitle>
                <Divider h={10} />
                <AnnounceDate>{el.notice_date}</AnnounceDate>
              </SemosAnnounceItem>
            </JustBetween>
            <Arrow src={ArrowIcon} />
          </Styler>
        </AnnounceItem>
      ))}

      <div style={{ height: "83px" }} />

      {/* <Footer /> */}

      <LoadingSpinner
        loading={loading}
        title={`정보를 확인하고 있습니다.`}
        content={`잠시만 기다려 주세요!`}
      />
    </Container>
  );
}

export default AnnouncementList;

const Container = styled.section`
  height: calc(var(--vh, 1vh) * 100);
  overflow-x: hidden;
`;

const JustBetween = styled.div`
  display: flex;
  align-items: center;
`;

const Divider = styled.div`
  height: ${({ h }) => h}px;
`;

const Styler = styled.div`
  position: relative;
  display: ${({ flex }) => (flex ? "flex" : "")};
  flex-direction: ${({ direction }) => direction};
  justify-content: ${({ justify }) => justify};
  align-items: ${({ align }) => align};
  padding-top: ${({ pt }) => pt}px;
  padding-bottom: ${({ pb }) => pb}px;
  padding-left: ${({ pl }) => pl}px;
  padding-right: ${({ pr }) => pr}px;
`;

const WarningImg = styled.img`
  width: 20px;
  height: 20px;
  margin-right: 4px;
`;

const Gongji = styled.div`
  font-weight: 500;
  font-size: 14px;
  /* line-height: 142%; */
  letter-spacing: -0.02em;
  color: ${colors.Gr04};
`;

const LengthText = styled.div`
  color: ${colors.Bl07};
  font-weight: 500;
  font-size: 14px;
  line-height: 142%;
  letter-spacing: -0.02em;
`;

const AnnounceItem = styled.div`
  cursor: pointer;
  border-bottom: 2px solid ${colors.Gr01};
`;

const SemosAnnounceMark = styled.div`
  padding: 4px 11px;
  font-size: 10px;
  font-weight: 600;
  color: ${colors.White};
  background-color: ${colors.Bl07};
  border-radius: 8px;
  margin-right: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const SemosAnnounceItem = styled.div`
  flex: 1;
`;

const AnnounceTitle = styled.div`
  font-size: 14px;
  font-weight: 600;
  line-height: 142%;
  color: ${colors.Gr05};
  letter-spacing: -0.02em;
`;

const AnnounceDate = styled.div`
  font-size: 12px;
  font-weight: 500;
  line-height: 142%;
  color: ${colors.Gr04};
  letter-spacing: -0.02em;
`;

const Arrow = styled.img`
  width: 24px;
  height: 24px;
`;
