import React, { useEffect, useState } from "react";
import styled from "styled-components";
import axios from "axios";

import colors from "../../styles/colors";

import { ReactComponent as CheckCircle } from "../../resource/icon/check_circle.svg";
import { ReactComponent as BangMark } from "../../resource/icon/bang_mark.svg";
import { GetOne } from "../../tool/localstorage";
import { MAX_ID_NUMBER_LENGTH, MAX_PHONE_NUMBER_LENGTH, MAX_REGISTER_NUMBER_LENGTH } from "../../data/config";
import { useNavigate } from "react-router-dom";

const SPECIAL_CHARACTERS = /[0-9~!@#$%^&*()_+={}[\]|\\:;"'<>,.?/`-]/g;
const PATTERN = /^[가-힣a-zA-Z\s]{2,20}$/i;

const MAX_NICKNAME_LENGTH = 20;

const RegisterInput = ({ type, placeholder, STEP_LIST, step, data, setData, setNext, valid, setValid }) => {
  const navigate = useNavigate();
  const [focused, setFocused] = useState(false);
  const [duplicated, setDuplicated] = useState(false);
  const [displayIdNumber, setDisplayIdNumber] = useState("");

  useEffect(() => {
    switch (type) {
      case "phoneNumber":
        if (data[type].length > 4) {
          setValid({ ...valid, [type]: true });
          setNext(true);
        } else {
          setValid({ ...valid, [type]: false });
          setNext(false);
        }
        break;
      case "idNumber":
        // console.log(data[type].length);
        if (data[type].length === MAX_ID_NUMBER_LENGTH) {
          const encode = data[type]
            .split("")
            .map((val, idx) => (idx > 7 ? "*" : val))
            .join("");
          setDisplayIdNumber(encode);
          setValid({ ...valid, [type]: true });
          setNext(true);
        } else {
          setValid({ ...valid, [type]: false });
          setNext(false);
        }
        break;
      case "registerNumber":
        if (data[type].length === MAX_REGISTER_NUMBER_LENGTH) {
          setValid({ ...valid, [type]: true });
          setNext(true);
        } else {
          setValid({ ...valid, [type]: false });
          setNext(false);
        }
        break;
      default:
        if (data[type]) {
          setValid({ ...valid, [type]: true });
          setNext(true);
        } else {
          setValid({ ...valid, [type]: false });
          setNext(false);
        }
        break;
    }
  }, []);

  const handleChange = (target, currentTarget) => {
    let text = target;

    switch (type) {
      case "userName":
        setNext(false);
        text = target.substr(0, MAX_NICKNAME_LENGTH).replace(SPECIAL_CHARACTERS, "");
        break;
      case "phoneNumber":
        let phoneStr = target;
  
        const phoneArr = [];
        const phoneNum = target.replaceAll("-", "").replaceAll(" ", "");

        phoneArr.push(phoneNum.substr(0, 3));
        if (phoneNum.length > 3) phoneArr.push(phoneNum.substr(3, 4));
        if (phoneNum.length > 7) phoneArr.push(phoneNum.substr(7, 4));

        phoneStr = phoneArr.join("-");
        text = phoneStr.substr(0, MAX_PHONE_NUMBER_LENGTH);

        if (text.length === MAX_PHONE_NUMBER_LENGTH) {
          const frm = new FormData();

          frm.append("mode", "phone_duplicate");
          frm.append("phone", target);
      
          axios
            .post("https://ai.semos.kr/semos_partner/v3/profile/Login", frm, {
              headers: {
                "Content-Type": "multipart/form-data",
                Accept: "application/json",
              },
            })
            .then((data) => {
              const duplicateStatus = data.data.existedUser;
              setDuplicated(duplicateStatus);
              if (duplicateStatus) {
                setNext(false);
              } else {
                setValid({ ...valid, [type]: true });
                setNext(true);
              }
            });
        } else {
          setValid({ ...valid, [type]: false });
          setNext(false);
        }
        break;
      case "idNumber":
        // 주민등록번호 DATA
        if (currentTarget !== " ") {
          // currentTarget !== " " : 띄어쓰기 방지
          const idArr = [];
          const idNum = data[type].replaceAll("-", "").replaceAll(" ", "");

          const currentNum =
            currentTarget === null
              ? `${idNum.substring(0, idNum.length - 1)}` // 지울 경우
              : `${idNum}${currentTarget}`;

          // 앞자리
          idArr.push(currentNum.substring(0, 6));
          // 뒷자리
          if (currentNum.length > 6) {
            const idStr = currentNum.substring(6, MAX_ID_NUMBER_LENGTH);
            idArr.push(idStr);
          }

          const idText = idArr.join("-").substring(0, MAX_ID_NUMBER_LENGTH);
          setData({ ...data, [type]: idText });
        }

        // 주민등록번호 DISPLAY
        const displayArr = [];
        const displayNum = target.replaceAll("-", "").replaceAll(" ", "");

        // 앞자리
        displayArr.push(displayNum.substr(0, 6));
        // 뒷자리
        if (displayNum.length > 6) {
          const displayStr = displayNum.substr(6, 7);
          const encode = displayStr
            .split("")
            .map((val, idx) => (idx > 0 ? "*" : val))
            .join("");
          displayArr.push(encode);
        }

        const displayText = displayArr.join("-").substring(0, MAX_ID_NUMBER_LENGTH);
        setDisplayIdNumber(displayText);

        if (displayText.length === MAX_ID_NUMBER_LENGTH) {
          setValid({ ...valid, [type]: true });
          setNext(true);
        } else {
          setValid({ ...valid, [type]: false });
          setNext(false);
        }
        return;
      case "registerNumber":
        let registerText = target;

        const registerArr = [];
        const registerNum = target.replaceAll("-", "").replaceAll(" ", "");

        registerArr.push(registerNum.substr(0, 3));
        if (registerNum.length > 3) registerArr.push(registerNum.substr(3, 2));
        if (registerNum.length > 5) registerArr.push(registerNum.substr(5, 5));

        registerText = registerArr.join("-");
        text = registerText.substr(0, MAX_REGISTER_NUMBER_LENGTH);

        if (text.length === MAX_REGISTER_NUMBER_LENGTH) {
          setValid({ ...valid, [type]: true });
          setNext(true);
        } else {
          setValid({ ...valid, [type]: false });
          setNext(false);
        }
        break;
      default:
        break;
    }

    setData({ ...data, [type]: text });
  };

  // 활동명 중복체크
  const userNameVali = (name) => {
    const token = GetOne("token");
    const frm = new FormData();

    frm.append("token", token);
    frm.append("mode", "partner_name_dulicate");
    frm.append("partner_name", name);

    axios
      .post("https://ai.semos.kr/semos_partner/auth/profile", frm, {
        headers: {
          "Content-Type": "multipart/form-data",
          Accept: "application/json",
        },
      })
      .then((data) => {
        const duplicateStatus = data.data.status;

        if (duplicateStatus === "success") {
          if (PATTERN.test(name)) {
            setDuplicated(false);
            setValid({ ...valid, [type]: true });
            setNext(true);
          } else {
            setDuplicated(false);
            setValid({ ...valid, [type]: false });
            setNext(false);
          }
        } else {
          // duplicateStatus === 'duplicate'
          setDuplicated(true);
          setValid({ ...valid, [type]: false });
          setNext(false);
        }
      });
  };

  // 전화번호 중복체크
  const phoneNumberVali = (phone) => {
    const frm = new FormData();

    frm.append("mode", "phone_duplicate");
    frm.append("phone", phone);

    axios
      .post("https://ai.semos.kr/semos_partner/v3/profile/Login", frm, {
        headers: {
          "Content-Type": "multipart/form-data",
          Accept: "application/json",
        },
      })
      .then((data) => {
        const duplicateStatus = data.data.existedUser;
        setDuplicated(duplicateStatus);
        if (duplicateStatus) {
          setNext(false);
        }
      });
  };

  const ClearStorage = async () => {
    window.localStorage.clear();
    navigate("/")
  }

  return (
    <RegisterContent>
      <label htmlFor={`${STEP_LIST[type].label}`}>{`${STEP_LIST[type].label}`}</label>
      <InputContainer>
        <Input
          id={`${STEP_LIST[type].label}`}
          type={type !== "userName" ? "tel" : "text"}
          placeholder={placeholder}
          disabled={step.name !== type}
          isValid={!duplicated}
          isFocused={focused}
          onFocus={() => {
            if (type === "userName" && data[type].trim().length > 1) {
              userNameVali(data[type]);
            }
            setFocused(true);
          }}
          onBlur={(e) => {
            if (type === "userName" && data[type].trim().length > 1) {
              userNameVali(data[type]);
            } else if (type === "phoneNumber" && data[type].trim().length > 12) {
              phoneNumberVali(data[type]);
            }
            setFocused(false);
          }}
          value={type === "idNumber" ? displayIdNumber : data[type]}
          onChange={(e) => handleChange(e.target.value, e.nativeEvent.data)}
        />
        <InputIconContainer>
          {step.name === type ? <>{valid[type] ? <CheckCircle fill={colors.Bl07} /> : <></>}</> : <CheckCircle fill={colors.Bl07} />}
        </InputIconContainer>
      </InputContainer>

      {type === "userName" ? (
        <>
          {duplicated ? (
            <SubText warning={duplicated}>
              <BangMark fill={colors.Red03} />
              <span>중복된 활동명이에요</span>
            </SubText>
          ) : (
            <SubText warning={false}>
              <span>최소 2자 이상, 20자 이하</span>
            </SubText>
          )}
        </>
      ) : (
        <></>
      )}

      {type === "phoneNumber" && duplicated && (
        <SubText warning={duplicated}>
          <BangMark fill={colors.Red03} />
          <span>이미 가입된 번호에요</span>
          <span onClick={() => ClearStorage()} className="underline">
            로그인 화면으로 돌아가기
          </span>
        </SubText>
      )}

      {type === "idNumber" || type === "registerNumber" ? (
        <SubText warning={false}>
          <span>정산을 위한 정보예요</span>
        </SubText>
      ) : (
        <></>
      )}
    </RegisterContent>
  );
};

export default RegisterInput;

const RegisterContent = styled.div`
  background-color: ${colors.White};
  padding: 24px 0px 8px 0px;

  label {
    display: inline-block;
    color: ${colors.Gr03};
    line-height: 142%;
    letter-spacing: -0.28px;
    font-size: 14px;
    font-weight: 500;
  }
`;

const InputContainer = styled.div`
  position: relative;
  padding: 8px 0px;

  svg {
    width: 24px;
    height: 24px;
  }
`;

const Input = styled.input`
  width: 100%;
  padding: 14px 56px 14px 16px;
  background-color: ${colors.White};
  border: 1px solid ${({ isValid, isFocused }) => (isFocused ? `${colors.Bl07}` : isValid ? `${colors.Gr02}` : `${colors.Red03}`)};
  border-radius: 8px;
  font-size : 14px;
`;

const InputIconContainer = styled.div`
  position: absolute;
  top: 50%;
  right: 16px;
  z-index: 11;
  transform: translateY(-50%);

  svg {
    width: 24px;
    height: 24px;
  }
`;

const SubText = styled.span`
  display: flex;
  align-items: center;
  gap: 6px;
  span {
    color: ${({ warning }) => (warning ? colors.Red03 : colors.Gr03)};
    line-height: 20px;
    letter-spacing: -0.24px;
    font-size: 12px;
    font-weight: 500;
  }
  .underline {
    cursor: pointer;
    text-decoration-line: underline;
  }
`;
