import React, { useState, useEffect } from "react";
import styled from "styled-components";
import axios from "axios";

//tool
import { GetOne } from "../../tool/localstorage";
import { setImageFromFile, CropImage } from "../../tool/ImageCrop";

//style
import { Wrapper, Text, Input, Icon } from "../layouts/layoutStorage";
import colors from "../../styles/colors";

//component
import ActivityCategoryModal from "./NewCategoryModal";
import ActivityHeader from "./NewActivityHeader";
import ActivityPriceModal from "./NewActivityPrice";
import ActivityItemModal from "./NewActivityItem";
import ActivityCurriculumModal from "./NewActivityCurri";
import { Loading } from "../../tool/Loading";
import { LoadingSpinner } from "../../tool/loading";
import Modal from "../newActivityRegister/modal";

//icon
import { ReactComponent as Arrow } from "../../resource/icon/arrow.svg";
import { ReactComponent as Camera } from "../../resource/icon/camera.svg";

const MAX_NAME_LENGTH = 60;
const MIN_NAME_LENGTH = 5;
const MAX_CATEGORY_LENGTH = 3;
const Regex = /(?:[\u2700-\u27bf]|(?:\ud83c[\udde6-\uddff]){2}|[\ud800-\udbff][\udc00-\udfff]|[\u0023-\u0039]\ufe0f?\u20e3|\u3299|\u3297|\u303d|\u3030|\u24c2|\ud83c[\udd70-\udd71]|\ud83c[\udd7e-\udd7f]|\ud83c\udd8e|\ud83c[\udd91-\udd9a]|\ud83c[\udde6-\uddff]|\ud83c[\ude01-\ude02]|\ud83c\ude1a|\ud83c\ude2f|\ud83c[\ude32-\ude3a]|\ud83c[\ude50-\ude51]|\u203c|\u2049|[\u25aa-\u25ab]|\u25b6|\u25c0|[\u25fb-\u25fe]|\u00a9|\u00ae|\u2122|\u2139|\ud83c\udc04|[\u2600-\u26FF]|\u2b05|\u2b06|\u2b07|\u2b1b|\u2b1c|\u2b50|\u2b55|\u231a|\u231b|\u2328|\u23cf|[\u23e9-\u23f3]|[\u23f8-\u23fa]|\ud83c\udccf|\u2934|\u2935|[\u2190-\u21ff])/g;

const ActivityInfo = ({ open, DAT, aId, setPageType, step, setModal, status, available, statusHandler, ogPrice, ogPriceM, ogPriceC, MCCount, setMCCount, MCCountOg, TypeOg, setChange}) => {
  const [loading, setLoading] = useState(false);
  const [getLoading, setGetLoading] = useState(true);
  const [data, setData] = useState({
    activity_name: "",
    activity_type: "",
    min_capacity: 1,
    max_capacity: 1,
    thumbnail: "",
    category: [],
    prepare: [], 
    include: [], 
    exclude: [],  
    price: {
      1: { id: "new", price: "", weekend_price : "" },
    },
    curriculum: {
      day1: [
        {
          id: "new",
          content: "",
          last: "",
          title: "",
        },
      ],
    },
  });
  const [cropImageSetting, setCropImageSetting] = useState({
    image: "",
    type: "",
    width: 0,
    height: 0,
  });
  const [deleteD, setDelete] = useState({
    category: [],
    prepare: [],
    include: [],
    exclude: [],
    curriculum: [],
    price: []
  });

  const [focusName, setFocusName] = useState(false);
  const [categoryModal, setCategoryModal] = useState(false);
  const [priceModal, setPriceModal] = useState(false);
  const [itemModal, setItemModal] = useState(false);
  const [curriModal, setCurriModal] = useState(false);
  const [cropModal, setCropModal] = useState(false);
  const [first, setFirst] = useState(false);
  const [saveModal, setSaveModal] = useState({ status: false, type: "", link: "", confirmSave: false });

  useEffect(() => {
    setGetLoading(false);
  }, []);

  useEffect(() => {
    if(DAT) {
      if(DAT.activity_name) {
        if(!first) {
          setFirst(true)
          setData(DAT)
        }
      }
    }
  }, [DAT]);

  const Reset = () => {
    setData(DAT);
    setDelete({
      category: [],
      prepare: [],
      include: [],
      exclude: [],
      curriculum: [],
      price: []
    })
  }

  useEffect(() => {
    if(data) {
      if(data.activity_type == '정기 레슨') {
        if(ogPriceM?.length) {
          let OgPriceList = [];
          let OgPriceKeys = Object.keys(ogPriceM);
  
          for(let i=0; i < OgPriceKeys.length; i++) {
            let ThisKey = OgPriceKeys[i];
            OgPriceList[OgPriceList.length] = ogPriceM[ThisKey];
          }
  
          setDelete((prev) => ({ ...prev, price: OgPriceList }));
  
          if(TypeOg == '정기 레슨') {
            setMCCount(MCCountOg);
          } else {
            setMCCount(1);
          }
        } else {
          setMCCount(1);
        }

        setData((prev) => ({ ...prev, price: ogPriceM }));
      } else if(data.activity_type == '이용권(횟수)') {
        if(ogPriceC?.length) {
          let OgPriceList = [];
          let OgPriceKeys = Object.keys(ogPriceC);
  
          for(let i=0; i < OgPriceKeys.length; i++) {
            let ThisKey = OgPriceKeys[i];
            OgPriceList[OgPriceList.length] = ogPriceC[ThisKey];
          }
  
          setDelete((prev) => ({ ...prev, price: OgPriceList }));
  
          if(TypeOg == '이용권(횟수)') {
            setMCCount(MCCountOg);
          } else {
            setMCCount(1);
          }
        } else {
          setMCCount(1);
        }

        setData((prev) => ({ ...prev, price: ogPriceC }));
      } else if(data.activity_type == '이용권(기간)') {
        if(ogPriceM?.length) { 
          let OgPriceList = [];
          let OgPriceKeys = Object.keys(ogPriceM);
  
          for(let i=0; i < OgPriceKeys.length; i++) {
            let ThisKey = OgPriceKeys[i];
            OgPriceList[OgPriceList.length] = ogPriceM[ThisKey];
          }
  
          setDelete((prev) => ({ ...prev, price: OgPriceList }));
  
          if(TypeOg == '이용권(기간)') {
            setMCCount(MCCountOg);
          } else {
            setMCCount(1);
          }
        } else {
          setMCCount(1);
        }

        setData((prev) => ({ ...prev, price: ogPriceM }));
      } else {
        if(ogPrice) {
          setDelete((prev) => ({ ...prev, price: [] }));
  
          setMCCount(1);

          setData((prev) => ({ ...prev, price: ogPrice }));
        }
      }
    }
  }, [data.activity_type]);


  const temporarySaveData = () => {
    setSaveModal({ ...saveModal, status: false })
    setLoading(true);
    if (activeCheck()) {
      const token = GetOne("token");
      axios
        .post(
          "https://ai.semos.kr/semos_partner/v3/activity/ActivityInfo",
          {
            mode: "save",
            token: token,
            data: data,
            delete: deleteD,
            activity_id: aId
          },
          {
            headers: {
              "Content-Type": "application/x-www-form-urlencoded",
            },
          }
        )
        .then((data) => {
          let Value = data.data;
          let Status = Value.status;
          let StatusMessage = Value.status_message;

          if (Status == "success") {
              setModal({
                status: true,
                type: (aId == 'new') ? "new" : "confirm",
                link: `/activity_manage`,
              });
              setChange(false);
              setLoading(false);
          } else {
            window.alert(StatusMessage);
          }
        });
    } else {
      window.alert('입력하지 않은 정보가 있어요!\n정보를 확인해주세요!');
      setLoading(false);
    }
  };

  const justSaveData = (URL) => {
    setLoading(true);
    if (activeCheck()) {
      const token = GetOne("token");
      axios
        .post(
          "https://ai.semos.kr/semos_partner/v3/activity/ActivityInfo",
          {
            mode: "just_save",
            token: token,
            data: data,
            delete: deleteD,
            activity_id: aId
          },
          {
            headers: {
              "Content-Type": "application/x-www-form-urlencoded",
            },
          }
        )
        .then((data) => {
          let Value = data.data;
          let Status = Value.status;
          let StatusMessage = Value.status_message;

          if (Status == "success") {
              setModal({
                status: true,
                type: "just_save",
                link: URL ? URL : `/activity_manage`,
              });
              setChange(false);
              setLoading(false);
          } else {
            window.alert(StatusMessage);
          }
        });
    } else {
      window.alert('입력하지 않은 정보가 있어요!\n정보를 확인해주세요!');
      setLoading(false);
    }
  };

  const justSaveData2 = (URL) => {
    setLoading(true);
    if (activeCheck()) {
      const token = GetOne("token");
      axios
        .post(
          "https://ai.semos.kr/semos_partner/v3/activity/ActivityInfo",
          {
            mode: "just_save",
            token: token,
            data: data,
            delete: deleteD,
            activity_id: aId
          },
          {
            headers: {
              "Content-Type": "application/x-www-form-urlencoded",
            },
          }
        )
        .then((data) => {
          let Value = data.data;
          let Status = Value.status;
          let StatusMessage = Value.status_message;

          if (Status == "success") {
              setModal({
                status: true,
                type: "just_save3",
                link: URL ? URL : `/activity_manage`,
              });
              setChange(false);
              setLoading(false);
          } else {
            window.alert(StatusMessage);
          }
        });
    } else {
      window.alert('입력하지 않은 정보가 있어요!\n정보를 확인해주세요!');
      setLoading(false);
    }
  };

  const saveData = () => {
    setSaveModal({
      status: true,
      type: "save",
      link: `/activity_manage`,
    });
  };

  const ItemTagsChange = (In, Ex, Pre) => {
    if(In.length && Ex.length && Pre.length) {
      let InDes = (In.length > 1) ? `${In[0].tag_name} 외 ${In.length - 1}개, ` : `${In[0].tag_name}, `;
      let ExDes = (Ex.length > 1) ? `${Ex[0].tag_name} 외 ${Ex.length - 1}개, ` : `${Ex[0].tag_name}, `;
      let PreDes = (Pre.length > 1) ? `${Pre[0].tag_name} 외 ${Pre.length - 1}개 ` : Pre[0].tag_name;

      return `${InDes}${ExDes}${PreDes}`;
    } else {
      return "포함/불포함 사항 및 준비물 설정하기";
    }
  }

  const addImage = (file) => {
    setData((prev) => ({ ...prev, thumbnail: file }));
    setChange(true);
  };

  const ReturnPriceCheck = () => {

    const TypeEng = {
      "정기 레슨" : "month",
      "이용권(횟수)" : "count",
      "이용권(기간)" : "month",
      "단기 레슨" : "people",
      "국내/해외 투어" : "people",
      "자격증" : "people",
      "이용권" : "people"
    }

    const ActivityType = data?.activity_type;
    
    return TypeEng[ActivityType];

  }

  const ReturnMinCapacity = () => {
    const PriceType = ReturnPriceCheck();
    const PriceList = data?.price

    if(PriceType == 'people'){
      return data?.min_capacity;
    } else {
      if(PriceList) {
        const PriceKey = Object.keys(PriceList);

        return PriceKey[0];
      } else {
        return false;
      }
    }
  }

  const ReturnMaxCapacity = () => {
    const PriceType = ReturnPriceCheck();
    const PriceList = data?.price

    if(PriceType == 'people'){
      return data?.max_capacity;
    } else {
      if(PriceList) {
        const PriceKey = Object.keys(PriceList);

        return PriceKey[(PriceKey.length - 1)];
      } else {
        return false;
      }
    }
  }

  const PriceInsertCheck = () => {
    const Min = data?.min_capacity;
    const Max = data?.max_capacity;
    const TypeMin = ReturnMinCapacity();
    const TypeMax = ReturnMaxCapacity();
    const PriceType = ReturnPriceCheck();
    const MinPrice = data?.price[`${TypeMin}`].price

    if(Min && Max && MinPrice) {
      if(PriceType == 'people'){
        return `최대 ${Max}인, ${Min}인 ${MinPrice}원`
      } else if(PriceType == 'month') {
        return `최대 ${TypeMax}개월, ${TypeMin}개월 ${MinPrice}원`
      } else if(PriceType == 'count') {
        return `최대 ${TypeMax}회, ${TypeMin}회 ${MinPrice}원`
      }
    } else {
      return "가격 설정하기";
    }
  }


  // [다음] 버튼 활성화 유무
  const activeCheck = () => {
    return (
      data.activity_name.trim().length >= MIN_NAME_LENGTH &&
      data.activity_name.trim().length <= MAX_NAME_LENGTH &&
      data.category.length <= MAX_CATEGORY_LENGTH &&
      data.category[0] &&
      data.activity_type &&
      data.thumbnail &&
      data.min_capacity &&
      data.max_capacity &&
      data.include.length &&
      data.prepare.length &&
      data.exclude.length &&
      (ReturnMinCapacity() ? data.price[`${ReturnMinCapacity()}`] : false) &&
      data.curriculum['day1'][0].content.length
    );
  };

  const W = window.document.documentElement.clientWidth;

  return (
    open ?
      <>
        {!getLoading ? (
          <>
            <ActivityHeader
              status={status}
              title={(aId == 'new') ? "신규 액티비티 등록" : "액티비티 수정"}
              available={available}
              statusHandler={statusHandler}
            />

              {
                (aId != 'new') &&
                  <Wrapper 
                    ai="center" jc="space-between" width="100%" padding="24px 24px 8px 24px"
                    style={{
                      position : "fixed",
                      maxWidth: '1300px',
                      top : "40px",
                      left : "50%",
                      transform: "translate(-50%, 0)",
                      backgroundColor : "#ffffff",
                      zIndex : 10
                    }}  
                  >
                            
                    <Wrapper 
                      //onClick={() => setPageType('info', justSaveData2)}
                      ai="center" 
                      jc="center" 
                      radius="8px"
                      bgc={colors.Bl07} 
                      padding={`6px 0`}
                      width="30%">
                      <Text ta="center" size="16px" weight="600" lh="24px" ls="-0.28px" color={colors.White}>
                        기본 정보
                      </Text>
                    </Wrapper>

                    <Wrapper 
                      onClick={() => setPageType('schedule', justSaveData2, Reset)}
                      ai="center" 
                      jc="center" 
                      radius="8px"
                      bgc={colors.White} 
                      border={`1px solid ${colors.Gr02}`}
                      padding={`6px 0`}
                      width="30%">
                      <Text ta="center" size="16px" weight="600" lh="24px" ls="-0.28px" color={colors.Gr05}>
                        일정 정보
                      </Text>
                    </Wrapper>

                    <Wrapper 
                      onClick={() => setPageType('etc', justSaveData2, Reset)}
                      ai="center" 
                      jc="center" 
                      radius="8px"
                      bgc={colors.White} 
                      border={`1px solid ${colors.Gr02}`}
                      padding={`6px 0`}
                      width="30%">
                      <Text ta="center" size="16px" weight="600" lh="24px" ls="-0.28px" color={colors.Gr05}>
                        추가 정보
                      </Text>
                    </Wrapper>

                  </Wrapper>
              }

            {(aId !== 'new') ? <Wrapper ai="flex-start" fd="column" width="100%" height={`80px`} padding="24px 24px 8px 24px"/> : <></>}

            {/* 이미지 등록 */}
            <Wrapper ai="flex-start" fd="column" width="100%" padding="24px 24px 8px 24px">
              <Text size="16px" weight="600" lh="24px" ls="-0.32px" color={colors.Gr05}>
                액티비티 대표 이미지
              </Text>

              <ImageLabelBox MD={W > 800} htmlFor={ (status != 3 && status != 5) ? "thumbnail" : "vㅡv"}>
                {
                  data.thumbnail ?
                    <Icon className={`ThumbnailImage`} src={data.thumbnail} radius="8px" />
                  : 
                  <>
                    <Camera width="32px" height="32px" fill={colors.Gr03} />
                    <Text size="10px" weight="500" lh="132%" ls="-0.22px" color={colors.Gr03}>
                        이미지 등록
                    </Text>
                  </>
                }
                  <ImageInput
                    type="file"
                    id="thumbnail"
                    accept="image/*"
                    onChange={({ target: { files } }) => {
                      if( (status != 3 && status != 5) ) {
                        setImageFromFile({
                          file: files[0],
                          setImageUrl: ({ result }) => {
                            setCropImageSetting({
                              image: result,
                              width: 8,
                              height: 5,
                              type: "thumbnail",
                            });
                            setCropModal(true);
                          },
                        });
                      }
                    }}
                  />
              </ImageLabelBox>
            </Wrapper>

            {/* 액티비티 이름 */}
            <Wrapper width="100%" ai="flex-start" fd="column" gap="8px" padding="24px 24px 8px 24px">
              <Text size="16px" weight="600" lh="24px" ls="-0.32px" color={colors.Gr05}>
                액티비티 이름
              </Text>
              <Wrapper width="100%" height="56px" padding="8px 16px" border={`1px solid ${focusName ? colors.Bl07 : colors.Gr02}`} radius="8px">
                <Input
                  onFocus={() => setFocusName(true)}
                  onBlur={() => setFocusName(false)}
                  value={data.activity_name}
                  onChange={(e) => {
                    let value = e.target.value;
                    if( (status != 3 && status != 5) ) {
                      if (Regex.test(value)) {
                        let text = value.replace(Regex, "");
                        setData((prev) => ({ ...prev, activity_name: text.substring(0, MAX_NAME_LENGTH) }));
                        setChange(true);
                      } else {
                        setData((prev) => ({ ...prev, activity_name: value.substring(0, MAX_NAME_LENGTH) }));
                        setChange(true);
                      }
                    }
                  }}
                  maxLength={MAX_NAME_LENGTH}
                  size="14px"
                  weight="500"
                  lh="22px"
                  ls="-0.28px"
                  width="100%"
                  phColor={colors.Gr03}
                  placeholder="수원 주말 프리다이빙 풀패키지 원데이 체험"
                />
                <Text margin="0 0 0 4px" size="14px" weight="500" lh="22px" ls="-0.28px" color={colors.Gr03}>
                  {data.activity_name.length}/{MAX_NAME_LENGTH}
                </Text>
              </Wrapper>
              <TipWrap>
                <span className="tip">Tip!</span>
                <span>{` 카테고리, 지역, 진행 요일을 함께 적어주면 좋아요.`}</span>
              </TipWrap>
            </Wrapper>

            {/* 액티비티 카테고리 */}
            <Wrapper width="100%" ai="flex-start" fd="column" gap="8px" padding="24px 24px 8px 24px">
              <Text size="16px" weight="600" lh="24px" ls="-0.32px" color={colors.Gr05}>
                액티비티 카테고리
              </Text>
              <Wrapper
                onClick={() => {
                  if( (status != 3 && status != 5) ) {
                    setCategoryModal(true) 
                  }
                }}
                cursor="pointer"
                jc="space-between"
                width="100%"
                height="56px"
                padding="8px 16px"
                border={`1px solid ${colors.Gr02}`}
                radius="8px"
              >
                <Text
                  size="14px"
                  weight="500"
                  lh="22px"
                  ls="-0.28px"
                  width="calc(100% - 24px)"
                  color={data.category.length && data.activity_type ? colors.Gr05 : colors.Gr03}
                >
                  {(data.category.length && data.activity_type)
                    ? `${data.category.length > 1 ? 
                        `${data.category[0]} 외 ${data.category.length - 1}개` : 
                        data.category[0]}, ${data.activity_type}`
                    : "액티비티 종목, 타입 선택하기"}
                </Text>
                <Arrow width="24px" height="24px" fill={colors.Gr06} />
              </Wrapper>
            </Wrapper>

            {/* 액티비티 가격 */}
            <Wrapper width="100%" ai="flex-start" fd="column" gap="8px" padding="24px 24px 8px 24px">
                  <Text size="16px" weight="600" lh="24px" ls="-0.32px" color={colors.Gr05}>
                  액티비티 가격
                  </Text>
                  <Wrapper
                  onClick={() => {
                    if( (status != 3 && status != 5) ) {
                      setPriceModal(true)
                    }
                  }}
                  cursor="pointer"
                  jc="space-between"
                  width="100%"
                  height="56px"
                  padding="8px 16px"
                  border={`1px solid ${colors.Gr02}`}
                  radius="8px"
                  >
                  <Text
                      size="14px"
                      weight="500"
                      lh="22px"
                      ls="-0.28px"
                      width="calc(100% - 24px)"
                      color={(PriceInsertCheck() != '가격 설정하기') ? colors.Gr05 : colors.Gr03}
                  >
                      {PriceInsertCheck()}
                  </Text>
                  <Arrow width="24px" height="24px" fill={colors.Gr06} />
                  </Wrapper>
            </Wrapper>

            {/* 액티비티 포함사항 / 준비물 */}
            <Wrapper width="100%" ai="flex-start" fd="column" gap="8px" padding="24px 24px 8px 24px">
              <Text size="16px" weight="600" lh="24px" ls="-0.32px" color={colors.Gr05}>
                액티비티 포함/불포함 사항 및 준비물
              </Text>
                <Wrapper
                  onClick={() => {
                    if( (status != 3 && status != 5) ) {
                      setItemModal(true)
                    }
                  }}
                  cursor="pointer"
                  jc="space-between"
                  width="100%"
                  height="56px"
                  padding="8px 16px"
                  border={`1px solid ${colors.Gr02}`}
                  radius="8px"
                >
                  <Text
                    size="14px"
                    weight="500"
                    lh="22px"
                    ls="-0.28px"
                    width="calc(100% - 24px)"
                    color={(data.prepare.length && data.include.length && data.exclude.length) ? colors.Gr05 : colors.Gr03}
                  >
                    {ItemTagsChange(data.include, data.exclude, data.prepare)}
                  </Text>
                  <Arrow width="24px" height="24px" fill={colors.Gr06} />
                </Wrapper>
            </Wrapper>

            {/* 액티비티 커리큘럼 */}
            <Wrapper width="100%" ai="flex-start" fd="column" gap="8px" padding="24px 24px 8px 24px">
              <Text size="16px" weight="600" lh="24px" ls="-0.32px" color={colors.Gr05}>
                액티비티 커리큘럼
              </Text>
                <Wrapper
                  onClick={() => {
                    if( (status != 3 && status != 5) ) {
                      setCurriModal(true)
                    }
                  }}
                  cursor="pointer"
                  jc="space-between"
                  width="100%"
                  height="56px"
                  padding="8px 16px"
                  border={`1px solid ${colors.Gr02}`}
                  radius="8px"
                >
                  <Text
                    size="14px"
                    weight="500"
                    lh="22px"
                    ls="-0.28px"
                    width="calc(100% - 24px)"
                    color={data.curriculum['day1'][0].content ? colors.Gr05 : colors.Gr03}
                  >
                    {data.curriculum['day1'][0].content ? `${Object.keys(data.curriculum).length}회차 커리큘럼` : `회차별 커리큘럼 설정하기`}
                  </Text>
                  <Arrow width="24px" height="24px" fill={colors.Gr06} />
                </Wrapper>
            </Wrapper>

            <BottomWrap>
              { (status != 3 && status != 5) ?
              <>
                {
                  (aId !== 'new') ? 
                    <Wrapper
                      onClick={() => justSaveData()}
                      jc="center"
                      ai="center"
                      width={"50%"}
                      height="52px"
                      cursor={activeCheck() ? "pointer" : "default"}
                      radius="8px"
                      bgc={activeCheck() ? colors.Gr04 : colors.Gr01}
                    >
                      <Text ta="center" size="14px" weight="500" lh="22px" ls="-0.28px" color={activeCheck() ? colors.White : colors.Gr03}>
                        {"저장하기"}
                      </Text>
                    </Wrapper>
                  : <></>
                }

                <Wrapper
                  onClick={() => saveData()}
                  jc="center"
                  ai="center"
                  width={(aId != 'new') ? "50%" : "100%"}
                  height="52px"
                  cursor={activeCheck() ? "pointer" : "default"}
                  radius="8px"
                  bgc={activeCheck() ? colors.Bl07 : colors.Gr01}
                >
                  <Text ta="center" size="14px" weight="500" lh="22px" ls="-0.28px" color={activeCheck() ? colors.White : colors.Gr03}>
                    {(aId != 'new') ? `승인 신청하기` : `등록하기`}
                  </Text>
                </Wrapper>
              </>
              : 
              <Wrapper
                jc="center"
                ai="center"
                width="100%"
                height="52px"
                cursor={"default"}
                radius="8px"
                bgc={colors.Gr01}
              >
                <Text ta="center" size="14px" weight="500" lh="22px" ls="-0.28px" color={colors.Gr03}>
                  {`정보 변경 심사중`}
                </Text>
              </Wrapper>
              }
            </BottomWrap>

            {/* 액티비티 카테고리 모달*/}
            <ActivityCategoryModal
              data={data}
              deleteD={deleteD}
              setDelete={setDelete}
              setData={setData}
              open={categoryModal}
              setOpen={setCategoryModal}
              status={status}
              setChange={setChange}
            />

            {/* 액티비티 가격 모달 */}
            <ActivityPriceModal 
              data={data} 
              setData={setData} 
              deleteD={deleteD} 
              setDelete={setDelete} 
              open={priceModal} 
              setOpen={setPriceModal} 
              MCCount={MCCount}
              setMCCount={setMCCount}
              setChange={setChange}
            />
          
            {/* 액티비티 포함사항, 준비물 */}
            <ActivityItemModal 
              data={data} 
              setData={setData} 
              deleteD={deleteD} 
              setDelete={setDelete} 
              open={itemModal} 
              setOpen={setItemModal} 
              setChange={setChange}
            />

            {/* 액티비티 커리큘럼 */}
            <ActivityCurriculumModal
              data={data} 
              setData={setData} 
              deleteD={deleteD} 
              setDelete={setDelete} 
              open={curriModal} 
              setOpen={setCurriModal} 
              setChange={setChange}
            />

            <CropImage open={cropModal} setOpen={setCropModal} setting={cropImageSetting} setSetting={setCropImageSetting} setImageFunc={addImage} />

            <Modal modal={saveModal} setModal={setSaveModal} save={temporarySaveData} />
          </>
        ) : (
          <LoadingSpinner loading={getLoading} title={`정보를 확인하고 있습니다.`} content={`잠시만 기다려 주세요!`} />
        )}
        {loading && <Loading />}
      </>
    : <></>
  );
};

export default ActivityInfo;

const BottomWrap = styled.div`
  width: 100%;
  max-width: 1300px;
  transform : translate(-50%, 0);
  display: flex;
  padding: 12px 24px;
  border-top: 1px solid ${colors.Gr01};
  background-color: ${colors.White};
  position: fixed;
  bottom : 0;
  left : 50%;
  z-index: 10;
  gap : 10px;
`;

const TipWrap = styled.div`
  display: flex;
  gap: 2px;
  font-size: 14px;
  font-weight: 500;
  line-height: 22px;
  letter-spacing: -0.28px;
  color: ${colors.Gr03};
  .tip {
    color: ${colors.Bl05};
  }
  .notice {
    color: ${colors.Red03};
  }
`;

const ImageLabelBox = styled.label`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-top: 20px;
  width: 100%;
  ${({ MD }) => (MD ? 
    `height: calc((800px - 48px) * (5/8));`
    :
    `height: calc((100vw - 48px) * (5/8));`
  )};
  border: 1px solid ${colors.Gr02};
  border-radius: 8px;

  .ThumbnailImage {
    ${({ MD }) => (MD ? 
    `height: calc((800px - 48px) * (5/8));`
    :
    `height: calc((100vw - 48px) * (5/8));`
  )};
  }
`;

const ImageInput = styled.input`
  display: none;
`;
