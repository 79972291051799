import React from "react";
import styled from "styled-components";
import Modal from "react-modal";

// style
import { W, H } from "../../styles/globalStyles";

const ImageModal = ({ modal, setModal }) => {
  const { isOpen, img } = modal;

  return (
    <Modal
      isOpen={modal.isOpen}
      onRequestClose={setModal}
      contentLabel="Alert Modal"
      style={customStyles}
    >
      <ImgContainer>
        <img src={img} alt="modal preview" />
      </ImgContainer>
    </Modal>
  );
};

export default ImageModal;

const customStyles = {
  content: {
    width: `${W - 72}px`,
    maxHeight: `${H - 70}px`,
    marginTop: 30,
    marginRight: "-50%",
    padding: 0,
    backgroundColor: "transparent",
    border: "none",
    borderRadius: "16px 16px 0 0",
    overflow: "scroll",
    left: "50%",
    right: "auto",
    bottom: "auto",
    transform: "translateX(-50%)",
  },
};

const ImgContainer = styled.div`
  width: 100%;

  img {
    width: 100%;
    border-radius: 16px;
  }
`;
