import React, { useState, useEffect } from "react";
import styled from "styled-components";

// style
import { H, W } from "../../styles/globalStyles";
import colors from "../../styles/colors";

// img
import ModalCloseIcon from "../../icon/modalCloseButton.png";

const SPORT_TYPELIST = [
  {
    name: "워터스포츠",
    value: 1,
  },
  {
    name: "윈터스포츠",
    value: 2,
  },
];

const SPORT_LIST = [
  {
    type: SPORT_TYPELIST[0].name,
    name: "스쿠버다이빙",
    value: "스쿠버다이빙",
  },
  {
    type: SPORT_TYPELIST[0].name,
    name: "프리다이빙",
    value: "프리다이빙",
  },
  {
    type: SPORT_TYPELIST[0].name,
    name: "서핑",
    value: "서핑",
  },
  {
    type: SPORT_TYPELIST[0].name,
    name: "패들보드",
    value: "패들보드",
  },
  {
    type: SPORT_TYPELIST[0].name,
    name: "수영/스노클링",
    value: "수영/스노클링",
  },
  {
    type: SPORT_TYPELIST[1].name,
    name: "스키",
    value: "스키",
  },
  {
    type: SPORT_TYPELIST[1].name,
    name: "스노보드",
    value: "스노보드",
  },
];

const SportModal = ({ open, setOpen, sportCategory, setSportCategory }) => {
  const [pick, setPick] = useState({
    id: "new",
    typeName: SPORT_TYPELIST[0].name,
    typeValue: SPORT_TYPELIST[0].value,
    sport: SPORT_LIST[0].name,
  });

  useEffect(() => {
    console.log("PICKED SPORT", pick);
  }, [pick]);

  const handleSubmit = () => {
    if (!sportCategory.map((val) => val.sport).includes(pick.sport)) {
      setSportCategory([...sportCategory, pick]);
    }
    setOpen();
  };

  return (
    <>
      {open ? (
        <ModalBackground>
          <ModalLayout>
            <div>
              <ModalFirstLine>
                <ModalTitleContainer>
                  <ModalTitle>스포츠 선택</ModalTitle>
                </ModalTitleContainer>
                <CloseIcon src={ModalCloseIcon} onClick={() => setOpen()} />
              </ModalFirstLine>
              <ModalContentSection>
                <ModalContentContainer>
                  {SPORT_TYPELIST.map((val) => {
                    const { name, value } = val;
                    return (
                      <RadioButtonSelector key={name} reselect={true} selected={pick.typeName === name}>
                        <RadioButtonText>{name}</RadioButtonText>
                        <RadioButton
                          type="radio"
                          value="water"
                          name="water"
                          disabled={false}
                          checked={pick.typeName === name}
                          onChange={() => {
                            setPick({
                              ...pick,
                              typeName: name,
                              typeValue: value,
                              sport: name === SPORT_TYPELIST[0].name ? SPORT_LIST[0].name : SPORT_LIST[5].name,
                            });
                          }}
                        />
                      </RadioButtonSelector>
                    );
                  })}
                </ModalContentContainer>
                <div style={{ width: "2px", backgroundColor: `${colors.Gr01}` }} />
                <ModalContentContainer>
                  {SPORT_LIST.filter((val) => val.type === pick.typeName).map((val) => {
                    const { type, name, value } = val;
                    return (
                      <RadioButtonSelector key={name} selected={pick.sport === name}>
                        <RadioButtonText>{name}</RadioButtonText>
                        <RadioButton
                          type="radio"
                          value={name}
                          name="sport"
                          checked={pick.sport === name}
                          onChange={() =>
                            setPick((prev) => ({
                              ...prev,
                              sport: name,
                            }))
                          }
                        />
                      </RadioButtonSelector>
                    );
                  })}
                </ModalContentContainer>
              </ModalContentSection>
            </div>
            <SelectionButton
              active={pick.typeName && pick.typeValue && pick.sport}
              onClick={() => pick.typeName && pick.typeValue && pick.sport && handleSubmit()}
            >
              선택한 카테고리 추가하기
            </SelectionButton>
          </ModalLayout>
        </ModalBackground>
      ) : (
        <></>
      )}
    </>
  );
};

export default SportModal;

const ModalBackground = styled.div`
  width: ${W}px;
  height: ${H}px;
  background-color: ${colors.B60};
  position: fixed;
  z-index: 999;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
`;

const ModalLayout = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  height: 60%;
  padding: 24px;
  background-color: ${colors.White};
  border-radius: 8px 8px 0 0;
  position: absolute;
  bottom: 0;
`;

const ModalFirstLine = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
`;

const ModalTitleContainer = styled.div`
  display: flex;
  /* align-items: flex-end; */
  align-items: center;
`;

const ModalTitle = styled.div`
  margin-right: 12px;
  color: ${colors.Gr06};
  font-size: 18px;
  font-weight: 600;
  line-height: 142%;
  letter-spacing: -0.02em;
`;

const ModalContentContainer = styled.div`
  width: 45%;
`;

const RadioButtonSelector = styled.label`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 13px 0;
  border-bottom: 1px solid;
  margin-bottom: 12px;
  border-color: ${({ selected, reselect }) => (reselect ? (selected ? `${colors.Bl07}` : `${colors.Gr01}`) : `${colors.Gr01}`)};
  transition: 0.2s all ease;
  cursor: pointer;
`;

const RadioButtonText = styled.div`
  display: flex;
  align-items: center;
  color: ${colors.Gr03};
  line-height: 142%;
  font-size: 14px;
  font-weight: 600;
`;

const CloseIcon = styled.img`
  width: 24px;
  height: 24px;
  cursor: pointer;
`;

const RadioButton = styled.input`
  width: 14px;
  height: 14px;
  margin-left: 10px;
`;

const SelectionButton = styled.div`
  display: flex;
  align-items: center;
  padding: 16px;
  justify-content: center;
  background-color: ${({ active }) => (active ? `${colors.Bl07}` : `${colors.Gr01}`)};
  border: 1px solid ${colors.Gr02};
  border-radius: 8px;
  color: ${({ active }) => (active ? `${colors.White}` : `${colors.Gr03}`)};
  text-align: center;
  line-height: 142%;
  letter-spacing: -0.02em;
  font-size: 16px;
  font-weight: 600;
  cursor: ${({ active }) => (active ? "pointer" : "default")};
`;

const ModalContentSection = styled.div`
  display: flex;
  justify-content: space-between;
`;
