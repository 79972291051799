import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import { useParams, useNavigate } from "react-router-dom";
import axios from "axios";

// component
import Header from "../components/basic/Header";
import SportModal from "../components/signup/SportModal";
import { setImageFromFile, CropImage } from "../tool/imageCrop";
import { GetOne } from "../tool/localstorage";
import { LoadingSpinner } from "../tool/loading";

// style
import { W } from "../styles/globalStyles";
import colors from "../styles/colors";

//img
import DefaultImageIcon from "../icon/profileReviseImg.png";
import ProfileEditIcon from "../icon/profileEditIcon.png";
import AddCamera from "../icon/add_camera.png";
import CloseBlack from "../icon/closeBlack.png";
import InputClear from "../icon/clearInput.png";
import XIcon from "../icon/x.png";
import PartnerProfilePlus from "../icon/partnerProfilePlus.svg";
import PartnerProfileMinus from "../icon/partnerProfileMinus.svg";
import { ReactComponent as Arrow } from "../resource/icon/arrow.svg";
import { ReactComponent as Select } from "../icon/select.svg";
import { ReactComponent as SelectFilled } from "../icon/select_filled.svg";

const SPECIAL_CHARACTERS = /[0-9~!@#$%^&*()_+={}[\]|\\:;"'<>,.?/`-]/g;
const PATTERN = /^[가-힣a-zA-Z\s]{2,20}$/i;

const MAX_NICKNAME_LENGTH = 20;
const MIN_INTRO_LENGTH = 20;
const MAX_INTRO_LENGTH = 400;
const MAX_CAREER_LENGTH = 30;
const MAX_LICENSE_LENGTH = 10;
const MAX_IMG_LENGTH = 3;

const SPORT_TYPELIST = [
  {
    name: "워터스포츠",
    value: 1,
  },
  {
    name: "윈터스포츠",
    value: 2,
  },
];

function PartnerRegisterProfile() {
  const navigate = useNavigate();

  const [loading, setLoading] = useState(true);
  const [profileImg, setProfileImg] = useState("");
  const [nickname, setNickname] = useState("");
  const [status, setStatus] = useState("default");
  const [sport, setSport] = useState([]); // [{typeName: "", typeValue: 0, sport: "", id: 'new'}]
  const [intro, setIntro] = useState("");
  const [career, setCareer] = useState([]);
  const [careerText, setCareerText] = useState("");
  const [license, setLicense] = useState([]);
  const [background, setBackground] = useState([]);
  const [marketing, setMarketing] = useState(false);
  const [valid, setValid] = useState({
    profileImg: false,
    nickname: false,
    sport: false,
    intro: false,
    career: false,
  });
  const [focus, setFocus] = useState({
    nickname: false,
    intro: false,
    career: false,
  });
  const [careerEditFocus, setCareerEditFocus] = useState(-1);
  const [openSportModal, setOpenSportModal] = useState(false);
  const [crop, setCrop] = useState(false);
  const [cropImage, setCropImage] = useState({
    image: "",
    type: "",
    width: 0,
    height: 0,
  });
  const [deleteD, setDeleteD] = useState({
    sport: [], // sport
    career: [],
    background: [],
    license: [],
  });

  const nicknameRef = useRef(null);

  useEffect(() => {
    getData();
  }, []);

  const next =
    // profileImg &&
    status === "ok" && valid.intro && sport.length;
  //&& career.length;

  console.log(profileImg, status, valid.intro, sport.length, career.length);

  // GET DATA
  const getData = async () => {
    const token = GetOne("token");

    axios
      .post(
        "https://ai.semos.kr/semos_partner/auth/profile",
        { mode: "partner_profile_info", token: token },
        { headers: { "Content-Type": "application/x-www-form-urlencoded" } }
      )
      .then((data) => {
        const Value = data.data;
        const profileInfo = Value.profile_info;
        const status = Value.status;
        const statusMessage = Value.status_message;

        console.log("GET DATA >> ", Value);

        if (status === "success") {
          setProfileImg(profileInfo.profile);
          setNickname(profileInfo.name);
          setSport(
            profileInfo.sport.map((val) => ({
              typeName: SPORT_TYPELIST.find((item) => item.value === val.type)
                .name,
              typeValue: val.type,
              sport: val.sport,
              id: val.id,
            }))
          );
          setIntro(profileInfo.intro);
          setCareer(profileInfo.career);
          setLicense(profileInfo.license);
          setBackground(profileInfo.background);

          changeValue("intro", profileInfo.intro);
          partnerDuplicate(profileInfo.name);

          setLoading(false);
        } else {
          setLoading(false);
          window.alert(statusMessage);
        }
      });
  };

  const changeValue = (type, value) => {
    switch (type) {
      case "license":
        if (value) {
          let ValueData = license;
          ValueData[ValueData.length] = value;

          setLicense(ValueData);
        }
        break;
      case "background":
        if (value) {
          let ValueData = background;
          ValueData[ValueData.length] = value;

          setBackground(ValueData);
        }
        break;
      case "career":
        if (value) {
          let ValueData = career;
          ValueData[ValueData.length] = value;

          ValueData.length
            ? setValid({ ...valid, career: true })
            : setValid({ ...valid, career: false });
          setCareer(ValueData);
          setCareerText("");
        }
        break;
      case "nickname":
        value = value
          .substr(0, MAX_NICKNAME_LENGTH)
          .replace(SPECIAL_CHARACTERS, "");

        setNickname(value);
        break;
      case "profile_image":
        setProfileImg(value);
        value
          ? setValid({ ...valid, intro: true })
          : setValid({ ...valid, intro: false });
        break;
      case "intro":
        // 파트너 소개
        value = value.substr(0, MAX_INTRO_LENGTH);
        const targetLength = value.trim().length && value.trim().length;
        targetLength <= MAX_INTRO_LENGTH && targetLength >= MIN_INTRO_LENGTH
          ? setValid({ ...valid, intro: true })
          : setValid({ ...valid, intro: false });
        setIntro(value);
        break;
      default:
        break;
    }
  };

  // 이력 및 경력 작성하기
  const careerTextSetting = (text) => {
    const Text = text.substr(0, MAX_CAREER_LENGTH);
    setCareerText(Text);
  };

  // 이력 및 경력 수정하기
  const changeCareer = (value, i) => {
    const text = value.substr(0, MAX_CAREER_LENGTH);
    let copy = [...career];

    copy[i] = {
      id: "new",
      text: text,
      change: "yes",
    };

    copy.length !== 0 && text.trim().length
      ? setValid({ ...valid, career: true })
      : setValid({ ...valid, career: false });
    setCareer(copy);
  };

  // 스포츠 카테고리, 이력, 자격증 배경사진 삭제
  const deleteValue = (type, value) => {
    let DeleteData = deleteD[type];
    let deleted = "";

    if (type === "career") {
      let ValueData = career;
      deleted = ValueData[value];

      DeleteData.push(deleted);
      ValueData.splice(value, 1);

      ValueData.length !== 0
        ? setValid({ ...valid, career: true })
        : setValid({ ...valid, career: false });

      setCareer(ValueData);
    } else if (type === "sport") {
      let ValueData = sport;

      deleted = ValueData[value];
      DeleteData.push(deleted);
      ValueData.splice(value, 1);

      setSport(ValueData);
    } else if (type === "license") {
      let ValueData = license;

      deleted = ValueData[value];
      DeleteData.push(deleted);
      ValueData.splice(value, 1);

      setLicense(ValueData);
    } else if (type === "background") {
      let ValueData = background;

      deleted = ValueData[value];
      DeleteData.push(deleted);
      ValueData.splice(value, 1);

      setBackground(ValueData);
    }

    setDeleteD((prev) => ({ ...prev, [type]: DeleteData }));
  };

  // 파트너명 중복 체크
  const partnerDuplicate = (name) => {
    const token = GetOne("token");
    const nickName = name;
    const pattern = /^[가-힣a-zA-Z0-9-\s]{2,15}$/i;

    if (nickName.length > 1) {
      const frm = new FormData();
      frm.append("mode", "partner_name_dulicate");
      frm.append("partner_name", nickName);
      frm.append("token", token);

      axios
        .post("https://ai.semos.kr/semos_partner/auth/profile", frm, {
          headers: {
            "Content-Type": "multipart/form-data",
            Accept: "application/json",
          },
        })
        .then((data) => {
          const DulicateStatus = data.data.status;

          if (window.localStorage.getItem("nickname") !== nickname) {
            if (DulicateStatus === "success") {
              if (pattern.test(nickName)) {
                setStatus("ok");
              } else {
                setStatus("denied");
              }
            } else {
              setStatus("duplicated");
            }
          } else {
            setStatus("default");
          }
        });
    } else if (nickName.length == 1) {
      setStatus("black");
    } else {
      setStatus("default");
    }
  };

  // 다음 단계로
  const checkNextStep = () => {
    const token = GetOne("token");
    setLoading(true);

    console.log("profileImg >> ", profileImg);
    console.log("nickname >> ", nickname);
    console.log("intro >> ", intro);
    console.log("career >> ", career);
    console.log("sport >> ", sport);
    console.log("license >> ", license);
    console.log("background >> ", background);
    console.log("marketing >> ", marketing);
    console.log("deleteD >> ", deleteD);

    const sportArr = sport.map((val) => ({
      id: val.id,
      type: val.typeValue,
      sport: val.sport,
    }));

    axios
      .post(
        "https://ai.semos.kr/semos_partner/auth/profile",
        {
          mode: "partner_profile",
          token: token,
          data: {
            profile_img: profileImg,
            nickname: nickname,
            intro: intro,
            career: career,
            sport: sportArr,
            license: license,
            background: background,
            marketing: marketing,
          },
          delete: deleteD,
        },
        {
          headers: { "Content-Type": "application/x-www-form-urlencoded" },
        }
      )
      .then((data) => {
        const Value = data.data;
        const Success = Value.status;
        const SuccessMessage = Value.status_message;

        if (Success == "success") {
          setLoading(false);
          navigate("/partner_register_schedule");
        } else {
          setLoading(false);
          window.alert(SuccessMessage);
        }
      });
  };

  const clearInput = (type) => {
    changeValue(type, "");
    nicknameRef.current.focus();
  };

  const InputValueClear = (type) => {
    let input = document.getElementById(type);
    input.value = null;
  };

  const handleMarketing = () => {
    setMarketing(!marketing);
  };

  return (
    <Container>
      <Header title="세모스 파트너 입점하기" back={true} />
      <InnerContainer id="chatContainer">
        <BackgroundContent
          style={{ borderBottom: `2px solid ${colors.Gr01}` }}
          jc={`space-between`}
          padding={`24px`}
          ai={`center`}
        >
          <TextContent
            gmarket={true}
            style={{ justifyContent: "space-between" }}
            wrap={`wrap`}
          >
            <TextView
              gmarket={true}
              color={colors.Bl07}
              fw={`400`}
              fs={`16px`}
              lh={`142%`}
            >{`2. 파트너 정보 등록하기`}</TextView>
          </TextContent>

          <TextContent
            gmarket={true}
            style={{ justifyContent: "space-between" }}
            wrap={`wrap`}
          >
            <TextView
              gmarket={true}
              color={colors.Bl07}
              fw={`400`}
              fs={`16px`}
              lh={`142%`}
            >{`2`}</TextView>

            <TextView
              gmarket={true}
              color={colors.Gr03}
              fw={`400`}
              fs={`16px`}
              lh={`142%`}
            >{`/3`}</TextView>
          </TextContent>
        </BackgroundContent>

        {/* 프로필 사진 */}
        <Styler
          pt={26}
          pl={24}
          pr={24}
          pb={4}
          flex={true}
          direction={"column"}
          align={"center"}
        >
          <ProfileReviseBox htmlFor="profile_img">
            <ProfileReviseImg
              src={profileImg ? profileImg : DefaultImageIcon}
            />
            <ProfileEdit src={ProfileEditIcon} />
          </ProfileReviseBox>
          <PostImage
            type="file"
            id="profile_img"
            accept="image/*"
            onChange={({ target: { files } }) => {
              if (files.length) {
                setImageFromFile({
                  file: files[0],
                  setImageUrl: ({ result }) => {
                    setCropImage({
                      image: result,
                      width: 1,
                      height: 1,
                      type: "profile_image",
                    });
                    setCrop(true);
                  },
                });
                setValid({ ...valid, profileImg: true });
              } else {
                setValid({ ...valid, profileImg: false });
              }
            }}
          />
        </Styler>

        {/* 파트너명 */}
        <BackgroundContent
          wrap={`wrap`}
          padding={`24px 24px 0 24px`}
          ai={`center`}
        >
          <TextContent width={`100%`} style={{ justifyContent: "flex-start" }}>
            <TextView
              color={colors.Gr05}
              fw={`500`}
              fs={`14px`}
              lh={`142%`}
            >{`파트너명`}</TextView>
          </TextContent>

          <BackgroundContent
            jc={`space-between`}
            padding={`8px 0`}
            ai={`center`}
          >
            <InputContainerNick focus={focus.nickname}>
              <NicknameInput
                ref={nicknameRef}
                maxLength={MAX_NICKNAME_LENGTH}
                // onChange={(e) => handleNickname(e)}
                onChange={(e) => changeValue("nickname", e.target.value)}
                value={nickname ? nickname : ""}
                onFocus={() => {
                  setFocus({ ...focus, nickname: true });
                  partnerDuplicate(nickname);
                }}
                onBlur={() => {
                  setFocus({ ...focus, nickname: false });
                  partnerDuplicate(nickname);
                }}
                type="text"
                placeholder="파트너 이름을 입력해 주세요."
              />

              {nickname.length > 0 && (
                <ClearButton
                  src={InputClear}
                  onClick={() => clearInput("nickname")}
                />
              )}
              {/* 
            {status == "ok" && !focus && (
                <OkayContainer>
                <OkayIconBig src={OkaySign} />
                <ReviseButton>수정하기</ReviseButton>
                </OkayContainer>
            )} */}
            </InputContainerNick>
          </BackgroundContent>
        </BackgroundContent>

        {/* 스포츠 카테고리 */}
        <BackgroundContent
          wrap={`wrap`}
          padding={`24px 24px 0 24px`}
          ai={`center`}
        >
          <TextContent width={`100%`} style={{ justifyContent: "flex-start" }}>
            <TextView
              color={colors.Gr05}
              fw={`500`}
              fs={`14px`}
              lh={`142%`}
            >{`스포츠 카테고리`}</TextView>
          </TextContent>

          <BackgroundContent
            jc={`space-between`}
            padding={`8px 0`}
            ai={`center`}
          >
            <SportButton
              onClick={() => {
                setOpenSportModal(true);
              }}
            >
              <span>카테고리 선택</span>
              <Arrow fill={colors.Gr06} />
            </SportButton>
          </BackgroundContent>

          {sport.map((val, idx) => {
            const { typeName, typeValue, sport } = val;
            return (
              <TagItem key={`${typeName}-${sport}`}>
                <TagText>{typeName}</TagText>
                <TagDivider />
                <TagText>{sport}</TagText>
                <Xbutton
                  src={XIcon}
                  onClick={() => deleteValue("sport", idx)}
                />
              </TagItem>
            );
          })}
        </BackgroundContent>

        {/* 파트너 소개 작성하기 */}
        <BackgroundContent
          wrap={`wrap`}
          padding={`24px 24px 0 24px`}
          ai={`center`}
        >
          <TextContent width={`100%`} style={{ justifyContent: "flex-start" }}>
            <TextView
              color={colors.Gr05}
              fw={`500`}
              fs={`14px`}
              lh={`142%`}
            >{`파트너 소개 작성하기`}</TextView>
          </TextContent>

          <BackgroundContent
            jc={`space-between`}
            padding={`8px 0`}
            ai={`center`}
          >
            <TextareaContainer focus={focus.intro}>
              <TextArea
                maxLength={MAX_INTRO_LENGTH}
                type="text"
                placeholder={`예시) OOO 프리다이빙/수영 전문 교육 강사입니다. \n수중 영상 촬영을 통해 남들과는 다른 나만의 인생샷도 교육마다 촬영해서 드리고 있습니다.`}
                value={intro}
                onChange={(e) => changeValue("intro", e.target.value)}
                onFocus={() => setFocus({ ...focus, intro: true })}
                onBlur={(e) => setFocus({ ...focus, intro: false })}
              />
              <IntroText>
                <span>{intro.length}</span> / {MAX_INTRO_LENGTH}{" "}
                {`(최소 ${MIN_INTRO_LENGTH}자)`}
              </IntroText>
            </TextareaContainer>
          </BackgroundContent>
        </BackgroundContent>

        {/* 이력 및 경력 작성하기 */}
        <BackgroundContent
          wrap={`wrap`}
          padding={`24px 24px 0 24px`}
          ai={`center`}
        >
          <TextContent width={`100%`} style={{ justifyContent: "flex-start" }}>
            <TextView
              color={colors.Gr05}
              fw={`500`}
              fs={`14px`}
              lh={`142%`}
            >{`이력 및 경력 작성하기`}</TextView>
          </TextContent>

          <BackgroundContent
            jc={`space-between`}
            padding={`8px 0`}
            ai={`center`}
          >
            <InputContainer
              w={100}
              h={38}
              focus={focus.career}
              isValid={valid.career}
            >
              <CareerInput
                type="text"
                placeholder={`${MAX_CAREER_LENGTH}자 이내로  작성해 주세요.`}
                value={careerText}
                onChange={(e) => careerTextSetting(e.target.value)}
                maxLength={MAX_CAREER_LENGTH}
                onFocus={() => setFocus({ ...focus, career: true })}
                onBlur={() => setFocus({ ...focus, career: false })}
              />
            </InputContainer>
            <CareerButton
              src={PartnerProfilePlus}
              onClick={() =>
                careerText.trim().length &&
                changeValue("career", {
                  id: "new",
                  career: careerText,
                  change: "no",
                })
              }
            />
          </BackgroundContent>
          {career &&
            career.map((el, i) => (
              <BackgroundContent
                key={`${i}_background`}
                jc={`space-between`}
                padding={`0 0 10px 0`}
                ai={`center`}
              >
                <InputContainer w={100} h={38} focus={i === careerEditFocus}>
                  <CareerInput
                    type="text"
                    placeholder={`${MAX_CAREER_LENGTH}자 이내로  작성해 주세요.`}
                    value={el.career}
                    onChange={(e) => changeCareer(e.target.value, i)}
                    maxLength={MAX_CAREER_LENGTH}
                    onFocus={() => setCareerEditFocus(i)}
                    onBlur={() => setCareerEditFocus(-1)}
                  />
                </InputContainer>

                {/* </TextContent> */}
                <CareerButton
                  src={PartnerProfileMinus}
                  onClick={() => deleteValue("career", i)}
                />
              </BackgroundContent>
            ))}
        </BackgroundContent>

        {/* 자격증 사진 등록하기 */}
        <BackgroundContent
          wrap={`wrap`}
          padding={`24px 24px 0 24px`}
          ai={`center`}
        >
          <TextContent
            width={`100%`}
            flexDirection={"column"}
            jc={"flex-start"}
          >
            <TextView color={colors.Gr05} fw={`500`} fs={`14px`} lh={`142%`}>
              자격증 등록하기
            </TextView>
            <AlertText>자격증 등록은 선택사항입니다.</AlertText>
          </TextContent>

          <BackgroundImageContainer>
            <BackgroundImageAdd>
              <label htmlFor="license">
                <ImageAddView>
                  <div>
                    <ImageAddIcon src={AddCamera} />
                    <ImageAddText>{`${
                      license && license.length
                    } / ${MAX_LICENSE_LENGTH}`}</ImageAddText>
                  </div>
                </ImageAddView>
              </label>
              <PostImage
                type="file"
                id="license"
                accept="image/*"
                onChange={({ target: { files } }) => {
                  if (files.length && license.length < MAX_LICENSE_LENGTH) {
                    setImageFromFile({
                      file: files[0],
                      setImageUrl: ({ result }) => {
                        setCropImage({
                          image: result,
                          width: 8,
                          height: 5,
                          type: "license",
                        });
                        setCrop(true);
                      },
                    });
                  } else {
                    window.alert(
                      `이미지는 최대 ${MAX_LICENSE_LENGTH}개까지 등록하실 수 있습니다.`
                    );
                  }
                }}
              />
            </BackgroundImageAdd>

            <BackgroundImageSlider>
              {license &&
                license.map((item, index) => (
                  <BackgroundImage key={`${item}-${index}`}>
                    <img className="BI" src={item.image} />
                    <CloseIcon onClick={() => deleteValue("license", index)}>
                      <img className="CI" src={CloseBlack} />
                    </CloseIcon>
                  </BackgroundImage>
                ))}
            </BackgroundImageSlider>
          </BackgroundImageContainer>
        </BackgroundContent>

        {/* 배경 사진 등록하기 */}
        <BackgroundContent
          wrap={`wrap`}
          padding={`24px 24px 0 24px`}
          ai={`center`}
        >
          <TextContent width={`100%`} style={{ justifyContent: "flex-start" }}>
            <TextView color={colors.Gr05} fw={`500`} fs={`14px`} lh={`142%`}>
              배경 사진 등록하기
            </TextView>
          </TextContent>

          <BackgroundImageContainer>
            <BackgroundImageAdd>
              <label htmlFor="background">
                <ImageAddView>
                  <div>
                    <ImageAddIcon src={AddCamera} />
                    <ImageAddText>{`${
                      background && background.length
                    } / ${MAX_IMG_LENGTH}`}</ImageAddText>
                  </div>
                </ImageAddView>
              </label>
              <PostImage
                type="file"
                id="background"
                accept="image/*"
                onChange={({ target: { files } }) => {
                  if (files.length && background.length < MAX_IMG_LENGTH) {
                    setImageFromFile({
                      file: files[0],
                      setImageUrl: ({ result }) => {
                        setCropImage({
                          image: result,
                          width: 8,
                          height: 5,
                          type: "background",
                        });
                        setCrop(true);
                      },
                    });
                  } else {
                    window.alert(
                      `이미지는 최대 ${MAX_IMG_LENGTH}개까지 등록하실 수 있습니다.`
                    );
                  }
                }}
              />
            </BackgroundImageAdd>

            <BackgroundImageSlider>
              {background &&
                background.map((item, index) => (
                  <BackgroundImage key={`${item}-${index}`}>
                    <img className="BI" src={item.image} />
                    <CloseIcon onClick={() => deleteValue("background", index)}>
                      <img className="CI" src={CloseBlack} />
                    </CloseIcon>
                  </BackgroundImage>
                ))}
            </BackgroundImageSlider>
          </BackgroundImageContainer>
        </BackgroundContent>

        {/* 마케팅 정보 수신 동의 */}
        <BackgroundContent
          wrap={`wrap`}
          ai={`center`}
          gap={"24px"}
          margin={"24px 0 0 0"}
          padding={`24px`}
          bgc={colors.Gr01}
        >
          <TextContent
            flexDirection={"column"}
            width={`100%`}
            jc={"flex-start"}
            gap={"12px"}
          >
            <TextView
              ta={"center"}
              color={colors.Gr06}
              lh={"22px"}
              ls={"-0.02em"}
              fs={`14px`}
              fw={500}
            >
              액티비티 관련 사진 사용에 동의하면 <br />
              비즈니스 홍보에 도움을 드리고 있어요
            </TextView>
            <TextView
              ta={"center"}
              color={colors.Gr04}
              lh={"20px"}
              ls={"-0.02em"}
              fs={`12px`}
              fw={500}
            >
              마케팅 홍보에 활용되거나 홈 배너에 노출 되는 등 <br />
              동의를 한 파트너분들께 우선적 혜택을 드리고 있어요
            </TextView>
          </TextContent>
          <TextContent
            onClick={() => handleMarketing()}
            jc={"center"}
            ai={"center"}
            gap={"8px"}
            width={"100%"}
          >
            <MarketingLabel>사진 사용에 동의할게요</MarketingLabel>
            <MarketingCheckbox>
              {marketing ? (
                <SelectFilled fill={colors.Bl07} />
              ) : (
                <Select fill={colors.Gr03} />
              )}
            </MarketingCheckbox>
          </TextContent>
        </BackgroundContent>

        <BackgroundContent
          style={{ borderTop: `1px solid ${colors.Gr01}` }}
          margin={"22px 0 0 0"}
          padding={"10px 24px"}
          bgc={colors.White}
        >
          <NextButton
            active={next}
            onClick={() =>
              next
                ? checkNextStep()
                : window.alert(
                    "입력하지 않은 정보가 있습니다.\n정보를 확인해주세요!"
                  )
            }
          >
            다음 단계로
          </NextButton>
        </BackgroundContent>
        {/* <div style={{ height: "100px" }} /> */}
      </InnerContainer>

      <CropImage
        status={crop}
        OnOff={setCrop}
        image={cropImage}
        imageSetting={setCropImage}
        setImage={changeValue}
        imageChange={changeValue}
        setImage2={setProfileImg}
        inputClear={InputValueClear}
        defaultArray={
          cropImage.type === "background"
            ? {
                id: "new",
                image: "",
                change: "no",
              }
            : ""
        }
      />

      {openSportModal ? (
        <SportModal
          open={openSportModal}
          setOpen={() => setOpenSportModal(false)}
          sportCategory={sport}
          setSportCategory={setSport}
        />
      ) : (
        <></>
      )}

      <LoadingSpinner
        loading={loading}
        title={`정보를 확인하고 있습니다.`}
        content={`잠시만 기다려 주세요!`}
      />
    </Container>
  );
}

export default PartnerRegisterProfile;

const Container = styled.section`
  height: calc(var(--vh, 1vh) * 100);
  height: 100vh;
  overflow: hidden;
`;

const InnerContainer = styled.div`
  width: 100%;
  height: calc((var(--vh, 1vh) * 100) - (51px));
  height: calc(100vh - (51px));
  overflow-y: auto;
  overflow-x: hidden;
  display: inline-block;
  position: relative;
  z-index: 51;
`;

const BackgroundContent = styled.div`
  display: inline-flex;
  flex-wrap: ${({ wrap }) => wrap && `${wrap}`};
  align-items: ${({ ai }) => ai && `${ai}`};
  justify-content: ${({ jc }) => jc && `${jc}`};
  gap: ${({ gap }) => gap && `${gap}`};
  width: 100%;
  margin: ${({ margin }) => margin && `${margin}`};
  padding: ${({ padding }) => padding && `${padding}`};
  background-color: ${({ bgc }) => bgc && `${bgc}`};
  border: ${({ border }) => border && `${border}`};
  border-radius: ${({ br }) => br && `${br}px`};
  position: relative;
`;

const TextContent = styled.div`
  display: inline-flex;
  flex-direction: ${({ flexDirection }) => flexDirection && `${flexDirection}`};
  flex-wrap: ${({ wrap }) => wrap && `${wrap}`};
  align-items: ${({ ai }) => ai && `${ai}`};
  justify-content: ${({ jc }) => jc && `${jc}`};
  gap: ${({ gap }) => gap && `${gap}`};
  width: ${({ width }) => width && `${width}`};
  height: ${({ height }) => height && `${height}`};
  padding: ${({ padding }) => padding && `${padding}`};
  background-color: ${({ bgc }) => bgc && `${bgc}`};
  border: ${({ border }) => border && `${border}`};
  border-radius: ${({ br }) => br && `${br}px`};
  ${({ gmarket }) => gmarket && `font-family : "GmarketSans" !important;`}
`;

const TextView = styled.span`
  display: inline-block;
  width: ${({ width }) => width && `${width}`};
  overflow: hidden;
  text-align: ${({ ta }) => ta && `${ta}`};
  text-overflow: ${({ to }) => to && `${to}`};
  white-space: ${({ ws }) => ws && `${ws}`};
  color: ${({ color }) => color && `${color}`};
  line-height: ${({ lh }) => lh && `${lh}`};
  letter-spacing: ${({ ls }) => ls && `${ls}`};
  font-size: ${({ fs }) => fs && `${fs}`};
  font-weight: ${({ fw }) => fw && `${fw}`};
  ${({ gmarket }) => gmarket && `font-family : "GmarketSans" !important;`}
`;

const Styler = styled.div`
  position: relative;
  display: ${({ flex }) => (flex ? "flex" : "")};
  flex-direction: ${({ direction }) => direction};
  justify-content: ${({ justify }) => justify};
  align-items: ${({ align }) => align};
  padding-top: ${({ pt }) => pt}px;
  padding-bottom: ${({ pb }) => pb}px;
  padding-left: ${({ pl }) => pl}px;
  padding-right: ${({ pr }) => pr}px;
`;

const ProfileReviseBox = styled.label`
  position: relative;
  width: 160px;
  height: 160px;
  border-radius: 50%;
`;

const ProfileReviseImg = styled.img`
  width: 160px;
  height: 160px;
  border-radius: 50%;
`;

const ProfileEdit = styled.img`
  position: absolute;
  bottom: 0;
  right: 0;
  width: 38px;
  height: 38px;
`;

const PostImage = styled.input`
  display: none;
`;

const InputContainer = styled.div`
  border: 1px solid;
  border-color: ${({ focus }) => (focus ? colors.Bl07 : colors.Gr02)};
  width: ${({ w }) => w}%;
  border-radius: 8px;
  height: ${({ h }) => h}px;
`;

const Input = styled.input`
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
  padding: 16px;
  width: 100%;
  height: 100%;
  color: ${colors.Gr06};
  position: relative;

  &::placeholder {
    color: ${colors.Gr03};
  }

  &:focus {
    outline: none;
  }
`;

const TextareaContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 126px;
  border: 1px solid ${({ focus }) => (focus ? colors.Bl07 : colors.Gr02)};
  border-radius: 8px;
`;

const TextArea = styled.textarea`
  display: inline-flex;
  width: 100%;
  height: 100%;
  padding: 16px 16px 0px 16px;
  border: none;
  border-radius: 8px;
  resize: none;
  line-height: 22px;
  letter-spacing: -0.02em;
  font-size: 14px;
  font-weight: 500;

  overflow: auto;
  outline: none;

  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;

  &::placeholder {
    color: ${colors.Gr03};
  }
`;

const IntroText = styled.div`
  align-self: flex-end;
  padding: 8px 12px;
  color: ${colors.Gr03};
  line-height: 20px;
  letter-spacing: -0.02em;
  font-size: 12px;
  font-weight: 500;

  span {
    color: ${colors.Gr04};
  }
`;

const SportButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 14px 16px;
  background-color: ${colors.White};
  border: 1px solid ${colors.Gr02};
  border-radius: 8px;

  span {
    line-height: 24px;
    letter-spacing: -0.02em;
    font-size: 16px;
    font-weight: 500;
  }
`;

const TagItem = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
  margin-right: 8px;
  margin-bottom: 8px;
  padding: 6px 12px;
  background-color: ${colors.Gr01};
  border: 1px solid ${colors.Gr02};
  border-radius: 24px;
`;

const TagText = styled.span`
  color: ${colors.Gr05};
  word-break: break-all;
  text-overflow: ellipsis;
  line-height: 20px;
  letter-spacing: -0.02em;
  font-size: 12px;
  font-weight: 500;
`;

const TagDivider = styled.div`
  width: 1px;
  height: 16px;
  background-color: ${colors.Gr02};
`;

const Xbutton = styled.img`
  width: 12px;
  height: 18px;
`;

const CareerButton = styled.img`
  width: 38px;
  height: 38px;
  margin-left: 8px;
`;

const CareerInput = styled(Input)`
  font-weight: 500;
  font-size: 12px;
  line-height: 142%;
  display: flex;
  align-items: center;
  letter-spacing: -0.02em;
`;

const BackgroundImageContainer = styled.div`
  width: 100%;
  justify-content: space-between;
  align-items: flex-end;
  display: inline-flex;
`;

const BackgroundImageAdd = styled.div`
  display: inline-block;
  width: 78px;
  height: 78px;
  margin-bottom: 4px;
  margin-top: 12px;
`;

const BackgroundImageSlider = styled.div`
  display: inline-block;
  /* max-width: calc(100% - 91px); */
  width: calc(100% - 91px);
  overflow-x: auto;
  overflow-y: hidden;
  white-space: nowrap;
  padding-top: 12px;
`;

const BackgroundImage = styled.div`
  width: calc(78px * (8 / 5));
  height: 78px;
  position: relative;
  border-radius: 8px;
  display: inline-block;
  margin-right: 13px;

  .BI {
    width: 100%;
    display: inline-block;
    border-radius: 8px;
  }
`;

const CloseIcon = styled.div`
  position: absolute;
  top: -12px;
  right: -12px;
  width: 24px;
  height: 24px;

  .CI {
    width: 100%;
    display: inline-block;
  }
`;

const ImageAddView = styled.div`
  width: 78px;
  height: 78px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  border: 1px solid ${colors.Gr02};
  border-radius: 8px;
`;

const ImageAddText = styled.span`
  display: inline-block;
  text-align: center;
  font-weight: 500;
  font-size: 10px;
  line-height: 132%;
  width: 100%;
  color: ${colors.Gr03};
`;

const ImageAddIcon = styled.img`
  width: 32px;
  height: 32px;
  display: block;
  margin-bottom: 2px;
  margin: 0 auto;
`;

const NextButton = styled.div`
  width: 100%;
  /* margin: 32px 24px 24px 24px; */
  padding: 16px;
  border-radius: 8px;
  background-color: ${({ active }) => (active ? colors.Bl07 : colors.Gr01)};
  color: ${({ active }) => (active ? colors.White : colors.Gr03)};
  text-align: center;
  font-size: 16px;
  font-weight: 600;
  border: 1px solid;
  border-color: ${({ active }) => (active ? colors.Bl07 : colors.Gr02)};
`;

const InputContainerNick = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  position: relative;
  border: 1px solid;
  border-radius: 8px;
  border-color: ${({ focus }) => (focus ? colors.Bl07 : colors.Gr02)};
`;

const InputBox = styled.input`
  width: 100%;
  border: none;
  outline: none;
  border-radius: 8px;
  font-size: 14px;
  font-weight: 500;
  line-height: 142%;
  color: ${colors.Gr06};
`;

const NicknameInput = styled(InputBox)`
  padding: 16px;
  ::placeholder {
    color: ${colors.Gr03};
  }
`;

const MarketingLabel = styled.span`
  color: ${colors.Gr03};
  line-height: 22px;
  font-size: 14px;
  font-weight: 600;
`;

const MarketingCheckbox = styled.div`
  svg {
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

const ClearButton = styled.img`
  width: 24px;
  height: 24px;
  margin-right: 16px;
`;

const AlertText = styled.span`
  color: ${colors.Red03};
  line-height: 20px;
  letter-spacing: -0.02em;
  font-size: 12px;
  font-weight: 500;
`;
