import NoticeAlarm from "../icon/notice_alarm.png";
import ReservationAlarm from "../icon/reservation_alarm.png";
import ChatAlarm from "../icon/chat_alarm.png";
import ScheduleAlarm from "../icon/schedule_alarm.png";
import KB from "../icon/kb.png";
import KakaoBank from "../icon/kakao.png";
import Woori from "../icon/woori.png";
import Shinhan from "../icon/shinhan.png";
import Hana from "../icon/hana.png";
import NongHyub from "../icon/nonghyub.png";
import IBK from "../icon/ibk.png";
import SaeMaeul from "../icon/saemaeul.png";

export const HomeText = [
  "세모스가 액티비티 파트너가 되어드릴게요.",
  "오늘도 파트너님 덕분에 누군가가 Sporty해졌어요.",
  "세모스와 함께 Sporty할 준비되셨나요?",
  "에너제틱한 하루 세모스와 함께해요!",
  "세모스가 효율적인 액티비티 관리를 도울게요.",
];

export const AlarmCategory = [
  {
    text: "세모스 공지",
    img: NoticeAlarm,
  },
  {
    text: "채팅 알림",
    img: ChatAlarm,
  },
  {
    text: "에약 알림",
    img: ReservationAlarm,
  },
  {
    text: "일정 알림",
    img: ScheduleAlarm,
  },
];

export const BankList = [
  {
    name: "국민은행",
    src: KB,
  },
  {
    name: "카카오뱅크",
    src: KakaoBank,
  },
  {
    name: "우리은행",
    src: Woori,
  },
  {
    name: "신한은행",
    src: Shinhan,
  },
  {
    name: "하나은행",
    src: Hana,
  },
  {
    name: "농협은행",
    src: NongHyub,
  },
  {
    name: "기업은행",
    src: IBK,
  },
  {
    name: "새마을금고",
    src: SaeMaeul,
  },
  {
    name: "케이뱅크",
  },
  {
    name: "토스뱅크",
  },
  {
    name: "지역농협",
  },
  {
    name: "SC제일은행",
  },
  {
    name: "우체국",
  },
  {
    name: "수협은행",
  },
  {
    name: "지역수협",
  },
  {
    name: "신협",
  },
  {
    name: "씨티은행",
  },
  {
    name: "KDB산업은행",
  },
  {
    name: "대구은행",
  },
  {
    name: "부산은행",
  },
  {
    name: "경남은행",
  },
  {
    name: "광주은행",
  },
  {
    name: "전북은행",
  },
  {
    name: "제주은행",
  },
];
