import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { useParams, useNavigate } from "react-router-dom";
import axios from "axios";
import Sheet from "react-modal-sheet";

// components
import Header from "../components/basic/Header";
import AccountInfoInput from "../components/signup/account_input";
import BankModal from "../components/signup/bank_modal";
import { GetOne } from "../tool/localstorage";
import { LoadingSpinner } from "../tool/loading";

const W = window.document.documentElement.clientWidth;

function PartnerRegister() {
  const navigate = useNavigate();

  const [loading, setLoading] = useState(true);

  const [bank, setBank] = useState(false);
  const [info, setInfo] = useState({
    bank: "",
    account_number: "",
    date_of_birth: "",
    id_number: "",
    register_number: "",
    id_card: "",
    bankbook: "",
    register_card: "",
    type: 0, // 개인 0 센터 1
  });

  const [valid, setValid] = useState({
    bank: false,
    account_number: false,
    date_of_birth: false,
    id_number: false,
    register_number: true,
    id_card: false,
    bankbook: false,
    register_card: true,
    type: true,
  });

  const [focus, setFocus] = useState({
    account_number: "default",
    date_of_birth: "default",
    id_number: "default",
    register_number: "default",
  });

  useEffect(() => {
    var token = GetOne("token");

    axios
      .post(
        "https://ai.semos.kr/semos_partner/auth/profile",
        {
          mode: "partner_account_info",
          token: token,
        },
        {
          headers: { "Content-Type": "application/x-www-form-urlencoded" },
        }
      )
      .then((data) => {
        let Value = data.data;
        let Success = Value.status;
        let SuccessMessage = Value.status_message;
        let AccountInfo = Value.accrount_info;
        if (Success == "success") {
          setInfo({
            bank: AccountInfo.bank,
            account_number: AccountInfo.account_number,
            date_of_birth: AccountInfo.birth,
            id_number: AccountInfo.id_number,
            register_number: AccountInfo.business_number,
            id_card: AccountInfo.id_card,
            bankbook: AccountInfo.bank_card,
            register_card: AccountInfo.business_card,
            type: AccountInfo.type,
          });

          setValid({
            ...valid,
            bank: AccountInfo.bank ? true : false,
            account_number:
              AccountInfo.account_number &&
              AccountInfo.account_number.length > 4
                ? true
                : false,
            date_of_birth:
              AccountInfo.birth && AccountInfo.birth.length === 6
                ? true
                : false,
            id_number:
              AccountInfo.id_number && AccountInfo.id_number.length === 7
                ? true
                : false,
            register_number:
              AccountInfo.business_number &&
              AccountInfo.business_number.length === 12
                ? true
                : false,
          });

          setLoading(false);
        } else {
          window.alert(SuccessMessage);
          setLoading(false);
        }
      });
  }, []);

  const checkNextStep = () => {
    setLoading(true);

    var token = GetOne("token");

    axios
      .post(
        "https://ai.semos.kr/semos_partner/auth/profile",
        {
          mode: "partner_account",
          token: token,
          data: info,
        },
        {
          headers: { "Content-Type": "application/x-www-form-urlencoded" },
        }
      )
      .then((data) => {
        var Value = data.data;
        var Success = Value.status;
        var SuccessMessage = Value.status_message;

        if (Success == "success") {
          setLoading(false);
          navigate("/partner_register_profile");
        } else {
          setLoading(false);
          window.alert(SuccessMessage);
        }
      });
  };

  const next =
    info.type === 0
      ? info.bank &&
        valid.account_number &&
        valid.date_of_birth &&
        valid.id_number &&
        info.id_card &&
        info.bankbook
      : info.bank &&
        valid.account_number &&
        valid.date_of_birth &&
        valid.id_number &&
        valid.register_number &&
        info.id_card &&
        info.bankbook &&
        info.register_card;

  return (
    <Container>
      <Header title={`세모스 파트너 입점하기`} />

      <InnerContainer id="chatContainer">
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            alignItems: "center",
            width: "100%",
            minHeight: "calc((var(--vh, 1vh) * 100) - 102px)",
          }}
        >
          <div>
            <BackgroundContent
              style={{ borderBottom: "1px solid #F5F5F5" }}
              jc={`space-between`}
              padding={`24px`}
              ai={`center`}
            >
              <TextContent
                gmarket={true}
                style={{ justifyContent: "space-between" }}
                wrap={`wrap`}
              >
                <TextView
                  gmarket={true}
                  color={`#0E6DFF`}
                  fw={`400`}
                  fs={`16px`}
                  lh={`142%`}
                >{`1. 정산 정보 입력하기`}</TextView>
              </TextContent>

              <TextContent
                gmarket={true}
                style={{ justifyContent: "space-between" }}
                wrap={`wrap`}
              >
                <TextView
                  gmarket={true}
                  color={`#0E6DFF`}
                  fw={`400`}
                  fs={`16px`}
                  lh={`142%`}
                >{`1`}</TextView>

                <TextView
                  gmarket={true}
                  color={`#ACAFB5`}
                  fw={`400`}
                  fs={`16px`}
                  lh={`142%`}
                >{`/3`}</TextView>
              </TextContent>
            </BackgroundContent>

            <AccountInfoInput
              info={info}
              setInfo={setInfo}
              bank={bank}
              setBank={setBank}
              valid={valid}
              setValid={setValid}
              isRevise={false}
            />
          </div>

          <NextButton active={next} onClick={() => next && checkNextStep()}>
            다음 단계로
          </NextButton>
        </div>
      </InnerContainer>

      <BankModal
        bank={bank}
        setBank={setBank}
        info={info.bank}
        setInfo={setInfo}
      />

      <LoadingSpinner
        loading={loading}
        title={`정보를 확인하고 있습니다.`}
        content={`잠시만 기다려 주세요!`}
      />
    </Container>
  );
}

export default PartnerRegister;

const Container = styled.section`
  height: calc(var(--vh, 1vh) * 100);
  /* height: 100vh; */
  overflow: hidden;
`;

const InnerContainer = styled.div`
  width: 100%;
  height: calc((var(--vh, 1vh) * 100) - (51px));
  /* height: calc(100vh - (51px)); */
  overflow-y: auto;
  overflow-x: hidden;
  display: inline-block;
  position: relative;
  z-index: 51;
  padding-bottom: 51px;
`;

const BackgroundContent = styled.div`
  width: 100%;
  border: ${({ border }) => border && `${border}`};
  background-color: ${({ bgc }) => bgc && `${bgc}`};
  padding: ${({ padding }) => padding && `${padding}`};
  display: inline-flex;
  border-radius: ${({ br }) => br && `${br}px`};
  justify-content: ${({ jc }) => jc && `${jc}`};
  align-items: ${({ ai }) => ai && `${ai}`};
  flex-wrap: ${({ wrap }) => wrap && `${wrap}`};
  position: relative;
`;

const TextContent = styled.div`
  width: ${({ width }) => width && `${width}`};
  height: ${({ height }) => height && `${height}`};
  border: ${({ border }) => border && `${border}`};
  background-color: ${({ bgc }) => bgc && `${bgc}`};
  padding: ${({ padding }) => padding && `${padding}`};
  display: inline-flex;
  flex-wrap: ${({ wrap }) => wrap && `${wrap}`};
  border-radius: ${({ br }) => br && `${br}px`};
  justify-content: ${({ jc }) => jc && `${jc}`};
  align-items: ${({ ai }) => ai && `${ai}`};
  ${({ gmarket }) => gmarket && `font-family : "GmarketSans" !important;`}
`;

const TextView = styled.span`
  width: ${({ width }) => width && `${width}`};
  text-align: ${({ ta }) => ta && `${ta}`};
  color: ${({ color }) => color && `${color}`};
  font-weight: ${({ fw }) => fw && `${fw}`};
  font-size: ${({ fs }) => fs && `${fs}`};
  line-height: ${({ lh }) => lh && `${lh}`};
  display: inline-block;
  text-overflow: ${({ to }) => to && `${to}`};
  overflow: hidden;
  white-space: ${({ ws }) => ws && `${ws}`};
  ${({ gmarket }) => gmarket && `font-family : "GmarketSans" !important;`}
`;

const ImageIcon100 = styled.div`
  width: ${({ width }) => width && `${width}`};
  height: ${({ height }) => height && `${height}`};
  display: inline-block;

  img {
    width: ${({ width }) => width && `${width}`};
    display: inline-block;
  }
`;

const InputContainer = styled.div`
  border: 1px solid #dcdcdc;
  width: ${({ w }) => w}%;
  border-radius: 8px;
  height: 40px;
`;

const AccountInput = styled.input`
  font-size: 14px;
  font-weight: 500;
  text-align: ${({ value }) => (value ? "start" : "center")};
  padding: 10px 18px;
  width: 100%;
  height: 100%;
  color: #3c3e41;
  position: relative;

  &::placeholder {
    color: #acafb5;
  }

  &:focus {
    outline: none;
  }
`;

const Input = styled.input`
  font-size: 14px;
  font-weight: 500;
  text-align: center;
  padding: 10px 18px;
  width: 100%;
  height: 100%;
  color: #3c3e41;

  &::placeholder {
    color: #acafb5;
  }

  &:focus {
    outline: none;
  }
`;

const BackButtonModal = styled(Sheet)``;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Button = styled.div`
  font-weight: 500;
  font-size: 14px;
  line-height: 142%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  letter-spacing: -0.02em;
  width: 48%;
  padding: 18px 0;
  border-radius: 8px;
`;

const ModalLayout = styled.div`
  height: 100%;
  padding: 24px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const ModalFirstLine = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
`;

const ModalTitleContainer = styled.div`
  display: flex;
  /* align-items: flex-end; */
  align-items: center;
`;

const ModalTitle = styled.div`
  font-weight: 600;
  font-size: 18px;
  line-height: 142%;
  letter-spacing: -0.02em;
  color: #1a1c1f;
  margin-right: 12px;
`;

const CloseIcon = styled.img`
  width: 24px;
  height: 24px;
`;

const ModalContentContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const BankItem = styled.div`
  width: ${(W - 48 - 36) / 4}px;
  border: 1px solid;
  border-color: ${({ selected }) => (selected ? "#0e6dff" : "#DCDCDC")};
  margin-right: ${({ mr }) => (mr ? 12 : 0)}px;
  padding: 13px 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  margin-bottom: 12px;
`;

const BankAlone = styled.div`
  border: 1px solid;
  width: ${(W - 48 - 36) / 4}px;
  border-color: ${({ selected }) => (selected ? "#0e6dff" : "#DCDCDC")};
  margin-right: ${({ mr }) => (mr ? 12 : 0)}px;
  padding: 10px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  margin-bottom: 12px;
`;

const Img = styled.img`
  width: 37px;
  height: 37px;
  margin-bottom: 10px;
`;

const SubmitButton = styled.div`
  border-radius: 8px;
  background-color: ${({ active }) => (active ? "#0e6dff" : "#F5F5F5")};
  color: ${({ active }) => (active ? "#ffffff" : "#acafb5")};
  padding: 16px;
  text-align: center;
  font-size: 16px;
  font-weight: 600;
`;

const NextButton = styled(SubmitButton)`
  margin: 0 24px;
  border: 1px solid #dcdcdc;

  width: ${W - 48}px;
`;
