import React from "react";
import styled from "styled-components";

//img
import ReservationSchedule from "../../icon/reservationSchedule.png";
import ReservationPeople from "../../icon/reservationPeople.png";
import DefaultConfirm from "../../icon/activityConfirmDefault.png";
import { ReactComponent as Dashboard } from "../../icon/dashboard.svg";


import colors from "../../styles/colors";

function InfoBox({ data }) {

  let TypeCheck = (data?.activity_type != '정기 레슨' && data?.activity_type != '이용권(기간)' && data?.activity_type != '이용권(횟수)');

  const UseCheck = {
    '정기 레슨' : "개월",
    '이용권(기간)' : "개월",
    '이용권(횟수)' : "회"
  };
  const CategoryCheck = {
    '정기 레슨' : "이용 기간",
    '이용권(기간)' : "이용 기간",
    '이용권(횟수)' : "이용 횟수"
  };

  return (
    <InfoBoxContainer>
      <Styler flex={true} pb={6}>
        <InfoTypeContainer>
          <SmallIcon src={DefaultConfirm} />
          <GreyText>
            <Dye color={colors.Bl07}>스포츠</Dye>&nbsp;
            <Seperator>|</Seperator>&nbsp;유형
          </GreyText>
        </InfoTypeContainer>
        <InfoDataContainer>
          <Dye color={colors.Bl07}>{data.activity_category}</Dye>
          <Dye color={colors.Gr04}>
            {
              (TypeCheck) ?
                <>
                  <Seperator>&nbsp;&nbsp;|&nbsp;&nbsp;</Seperator>
                  {data?.activity_duration}
                </>
              : <></>
            }

            <Seperator>&nbsp;&nbsp;|&nbsp;&nbsp;</Seperator>
            {data?.activity_type}
          </Dye>
        </InfoDataContainer>
      </Styler>

      <Styler flex={true} pb={6}>
        <InfoTypeContainer>
          <SmallIcon src={ReservationSchedule} />
          <GreyText>{TypeCheck ? '예약 일정' : '유효 기간'}</GreyText>
        </InfoTypeContainer>
        <InfoDataContainer>{TypeCheck ? data?.schedule : data?.facility_schedule}</InfoDataContainer>
      </Styler>

      {
        data?.activity_type == '정기 레슨' ?
          <Styler flex={true} pb={6}>
            
            <InfoTypeContainer>
              <SmallIcon src={ReservationSchedule} />
              <GreyText>{`이용 시간대`}</GreyText>
            </InfoTypeContainer>

            <InfoDataContainer>{data?.facility_time}</InfoDataContainer>
          </Styler>
        : <></>
      }

      <Styler flex={true}>
        <InfoTypeContainer>
          {TypeCheck ?
            <SmallIcon src={ReservationPeople} />
            : 
            <Dashboard 
              width={`16px`} 
              height={`16px`} 
              style={{
                marginRight: 4
              }} fill={colors.Gr04} />
          }
          <GreyText>{TypeCheck ? '예약 인원' : CategoryCheck[data?.activity_type]}</GreyText>
        </InfoTypeContainer>
        <InfoDataContainer>
          {TypeCheck ? `${data?.people}인` : `${data?.people}${UseCheck[data?.activity_type]}`}
        </InfoDataContainer>
      </Styler>

    </InfoBoxContainer>
  );
}

export default InfoBox;

const InfoBoxContainer = styled.div`
  border: 1px solid ${colors.Gr02};
  padding: 12px;
  border-radius: 8px;
  margin-top: 24px;
`;

const InfoTypeContainer = styled.div`
  width: 100px;
  display: flex;
`;

const InfoDataContainer = styled.div`
  font-weight: 500;
  font-size: 12px;
  line-height: 142%;
  display: flex;
  align-items: center;
  letter-spacing: -0.02em;
  color: ${colors.Gr05};
  width: calc(100% - 100px);
`;

const Dye = styled.span`
  color: ${({ color }) => color};
`;

const Seperator = styled.span`
  font-size: 10px;
`;

const Styler = styled.div`
  position: relative;
  display: ${({ flex }) => (flex ? "flex" : "")};
  flex-direction: ${({ direction }) => direction};
  justify-content: ${({ justify }) => justify};
  align-items: ${({ align }) => align};
  padding-top: ${({ pt }) => pt}px;
  padding-bottom: ${({ pb }) => pb}px;
  padding-left: ${({ pl }) => pl}px;
  padding-right: ${({ pr }) => pr}px;
  height: ${({ h }) => h}px;
  width: ${({ w }) => w}%;
`;

const GreyText = styled.div`
  font-weight: 500;
  font-size: 12px;
  line-height: 142%;
  display: flex;
  align-items: center;
  letter-spacing: -0.02em;
  color: ${colors.Gr04};
`;

const SmallIcon = styled.img`
  width: 16px;
  height: 16px;
  margin-right: 4px;
`;
