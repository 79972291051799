import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import axios from "axios";
import styled from "styled-components";

import Header from "../components/basic/Header";
import { GetOne, SaveMany } from "../tool/localstorage";
import { LoadingSpinner } from "../tool/loading";
import PartnerPlan from "../components/signup/PartnerPlan";
import PartnerInfo from "../components/signup/PartnerInfo";
import PartnerPayment from "../components/signup/PartnerPayment";
import PartnerAccount from "../components/signup/PartnerAccount";
import PartnerWellcome from "../components/signup/PartnerWellcome";

import colors from "../styles/colors";
import { W, H } from "../styles/globalStyles";

import { ReactComponent as Arrow } from "../resource/icon/arrow.svg";

const PartnerRegister = () => {

  const navigate = useNavigate();

  const { pages } = useParams();

  const [data, setData] = useState({
    plan : "베이직 플랜",
    plan_type : "월간",
    price : "16500",
    price_text : "16,500",
    credit_card : "",
    expiration_year : "",
    expiration_month : "",
    confirm : false,
    name : "",
    phone : "",
    auth: "",
    id_card : "",
    business_card : "",
    account_card : ""
  });

  const [page, setPage] = useState(0);

  const [status, setStatus] = useState({
    phone: "default",
    auth: "default",
  });

  const [valid, setValid] = useState({
      name : 'default',
      credit_card : 'default',
      expiration_year : 'default',
      expiration_month : 'default'
  });

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    //setPage(Number(pages))
    //navigate("/partner_register");
    navigate("/partner_register_new/0", {replace: true});
    setPage(0);

    getData();
  }, []);

  const getData = () => {
    setLoading(true);

    const token = GetOne("token");
    const frm = new FormData();

    frm.append("mode", "main");
    frm.append("token", token);

    axios
      .post("https://ai.semos.kr/semos_partner/v3/profile/ApplicationForm", frm, {
        headers: {
          "Content-Type": "multipart/form-data",
          Accept: "application/json",
        },
      })
      .then((res) => {
        const data = res.data;
        const value = data.data;
        const status = data.status;
        if (status === "success") {

          setLoading(false);
        } else {
        }
      })
      .catch((err) => console.log(err));
  };

  return (
    <Container>
      <Header title="세모스 파트너 입점신청" back={false} jc="flex-start">
        {
          (page !== 0) ?
            <BackButton onClick={() => {
              navigate(`/partner_register_new/${Number(page - 1)}`, {replace: true});
              setPage(Number(page - 1));
            }}>
              <Arrow fill={colors.Gr05} />
            </BackButton>
          : <></>
        }
      </Header>
      
      {
        (page === 0) ?
          <PartnerPlan setPage={setPage} page={page} setData={setData} data={data}/>
        : <></>
      }

      {
        (page === 1) ?
          <PartnerInfo setPage={setPage} page={page} setData={setData} data={data} status={status} setStatus={setStatus} valid={valid} setValid={setValid}/>
        : <></>
      }

      {
        (page === 2) ?
          <PartnerPayment setPage={setPage} page={page} setData={setData} data={data} valid={valid} setValid={setValid}/>
        : <></>
      }

      {
        (page === 3) ?
          <PartnerAccount setPage={setPage} page={page} setData={setData} data={data} loading={loading} setLoading={setLoading}/>
        : <></>
      }

      {
        (page === 4) ?
          <PartnerWellcome setPage={setPage} page={page} setData={setData} data={data}/>
        : <></>
      }

    </Container>
  );
};

export default PartnerRegister;

const Container = styled.div`
  position: relative;
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  overflow-x: hidden;
  overflow-y: auto;
`;

const BackButton = styled.button`
  svg {
    width: 24px;
    height: 24px;
    transform: rotate(180deg);
  }
`;