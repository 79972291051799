import React, { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

// style
import colors from "../../styles/colors";
import { Wrapper, Input, Text, AbsoluteWrapper } from "../../components/layouts/layoutStorage";

// img
import { ReactComponent as Arrow } from "../../resource/icon/arrow.svg";
import { ReactComponent as Close } from "../../resource/icon/close.svg";

// default data
const TypeReturn = {
    "class" : "강습반",
    "ticket" : "이용권"
}

const CrewScheduleManage = ({
    item,
    id,
    time_list,
    setUser,
    user,
    DeleteTicket
}) => {

    const StartRef = useRef();
    const EndRef = useRef();

    const [dropdown, setDropdown] = useState({
        start : false,
        end : false
    });

    const StartHandleClose = (e) => {
        if (dropdown.start && (!StartRef.current || !StartRef.current.contains(e.target))) {
            setDropdown({
                start : false,
                end : dropdown.end
            });
        }
    };

    const EndHandleClose = (e) => {
        if (dropdown.end && (!EndRef.current || !EndRef.current.contains(e.target))) {
            setDropdown({
                start : dropdown.start,
                end : false
            });
        }
    };
    
    useEffect(() => {
        window.addEventListener("click", StartHandleClose);
    
        return () => {
            window.removeEventListener("click", StartHandleClose);
        };
    }, [dropdown.start]);

    useEffect(() => {
        window.addEventListener("click", EndHandleClose);
    
        return () => {
            window.removeEventListener("click", EndHandleClose);
        };
    }, [dropdown.end]);

    const SelectTime = (type, time) => {
        let Data = item;
        let DataList = user;

        if(type == 'start') {
            Data['start'] = time;
        } else if(type == 'end') {
            Data['end'] = time;
        }

        DataList[id] = Data;

        setUser(DataList);

        setDropdown({
            start : false,
            end : false
        });
    }

    return (
        <Wrapper
            width={`100%`}
            margin={`0 0 0 0`}
            jc={`space-between`}
            ai={`center`}
            padding={`3px`}
            bb={`1px solid ${colors.Gr02}`}
            key={`${id}_user_data`}
        > 
            <Text 
                ta="center" 
                size="1.6rem" 
                weight="600" 
                lh="22px" 
                ls="-0.28px" 
                color={colors.Gr05}
            >
                {`[${TypeReturn[item.type]}] ${item.name}`}
            </Text>

            <Wrapper
                margin={`0 0 0 3px`}
            >
                <Wrapper
                    ref={StartRef}
                    padding="5px"
                    cursor="pointer"
                    position="relative"
                    onClick={(e) => {
                        e.stopPropagation();
                        if(item.type == 'ticket') { 
                            setDropdown({
                                start: !dropdown.start,
                                end: false,
                            });
                        } else {
                            window.alert('강습반 일정은 시간 변경이 불가능합니다.')
                        }
                    }}
                    radius="8px"
                    bgc={colors.White}
                    border={`1px solid ${colors.Gr02}`}
                    jc="space-between"
                >
                        <Text size="1.6rem" weight="500" lh="22px" ls="-0.28px" color={colors.Gr05}>
                            {item.start}
                        </Text>

                        <Arrow
                            width="16px"
                            height="16px"
                            fill={colors.Gr04}
                            style={{ 
                                transition: "all ease 0.5s", 
                                transform: dropdown.start ? "rotate(-90deg)" : "rotate(90deg)" 
                            }}
                        />

                        {dropdown.start && (
                            <AbsoluteWrapper
                                maxHeight="10rem"
                                z={1}
                                fd="column"
                                width="100%"
                                top="36px"
                                left="0"
                                bgc={colors.White}
                                border={`1px solid ${colors.Gr02}`}
                                radius="8px"
                                of="auto"
                            >
                                {time_list.map((x, idx) => (
                                    <DropDown
                                        active={x == item.start}
                                        key={`${idx}_capacity`}
                                        last={idx + 1 === time_list.length}
                                        onClick={() => {
                                            SelectTime('start', x);
                                        }}
                                    >
                                        {x}
                                    </DropDown>
                                ))}
                            </AbsoluteWrapper>
                        )}
                </Wrapper>

                <Text 
                    size="1.8rem"
                    weight="600" 
                    lh="22px" 
                    ls="-0.28px" 
                    color={colors.Gr05}
                    style={{
                        marginLeft : 5
                    }}
                >
                    ~
                </Text>

                <Wrapper
                    ref={EndRef}
                    padding="5px"
                    cursor="pointer"
                    position="relative"
                    onClick={(e) => {
                        e.stopPropagation();
                        if(item.type == 'ticket') {
                            setDropdown({
                                start: false,
                                end: !dropdown.end,
                            });
                        } else {
                            window.alert('강습반 일정은 시간 변경이 불가능합니다.')
                        }
                    }}
                    radius="8px"
                    bgc={colors.White}
                    border={`1px solid ${colors.Gr02}`}
                    jc="space-between"
                    margin={`0 0 0 5px`}
                >
                        <Text size="1.6rem" weight="500" lh="22px" ls="-0.28px" color={colors.Gr05}>
                            {item.end}
                        </Text>

                        <Arrow
                            width="16px"
                            height="16px"
                            fill={colors.Gr04}
                            style={{ 
                                transition: "all ease 0.5s", 
                                transform: dropdown.end ? "rotate(-90deg)" : "rotate(90deg)" 
                            }}
                        />

                        {dropdown.end && (
                            <AbsoluteWrapper
                                maxHeight="10rem"
                                z={1}
                                fd="column"
                                width="100%"
                                top="36px"
                                left="0"
                                bgc={colors.White}
                                border={`1px solid ${colors.Gr02}`}
                                radius="8px"
                                of="auto"
                            >
                                {time_list.map((x, idx) => (
                                    <DropDown
                                        active={x == item.end}
                                        key={`${idx}_end`}
                                        last={idx + 1 === time_list.length}
                                        onClick={() => {
                                            SelectTime('end', x);
                                        }}
                                    >
                                        {x}
                                    </DropDown>
                                ))}
                            </AbsoluteWrapper>
                        )}
                </Wrapper>
                
                {
                    item.type != 'class' ?
                        <Wrapper
                            margin={`0 0 0 5px`}
                            radius={`8px`}
                            border={`2px solid ${colors.Red03}`}
                            width={`30px`}
                            height={`30px`}
                            ai={`center`}
                            jc={`center`}
                            cursor={`pointer`}
                            bgc={`${colors.White}`}
                        >
                            <Close 
                                onClick={() => DeleteTicket(item, id)}
                                fill={colors.Red03} 
                                width="26px" 
                                height="26px" 
                                style={{ 
                                    cursor: "pointer"
                                }} 
                            />
                        </Wrapper>
                    : <></>
                }

            </Wrapper>
        </Wrapper>  
    );

};

export default CrewScheduleManage;


const DropDown = styled.div`
    width: 100%;
    padding: 3px 5px;
    border-bottom: ${({ last }) => !last && `1px solid ${colors.Gr02}`};
    color: ${({ active }) => active ? colors.Bl07 : colors.Gr04 };
    font-size: 1.6rem;
    font-weight: ${({ active }) => active ? 600 : 500};
    line-height: 142%;
    letter-spacing: -0.26px;
    text-align : center;
    cursor : pointer;
`;