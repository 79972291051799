import React from "react";
import axios from "axios";
import { useNavigate, params, useLocation } from "react-router-dom";
import { useEffect } from "react";

function SaveMany(list) {
  for (let i = 0; i < list.length; i++) {
    let type = list[i].type;
    let value = list[i].value;

    if (value) {
      window.localStorage.setItem(type, value);
    }
  }

  return true;
}

const NaverLogin = () => {
  const { hash } = useLocation();
  const navigate = useNavigate();

  const accessToken = hash.split("&")[0].split("=")[1];

  useEffect(() => {
    if (accessToken) {
      NaverAxios(accessToken);
    }
  }, [accessToken]);

  const NaverAxios = (access) => {
    const frm = new FormData();

    frm.append("mode", "naver_login");
    frm.append("naver_code", access);

    axios
      .post("https://ai.semos.kr/semos_partner/v3/profile/Login", frm, {
        headers: {
          "Content-Type": "multipart/form-data",
          Accept: "application/json",
        },
      })
      .then((data) => {
        let Value = data.data;
        let Status = Value.login_status;
        let UserData = Value.user_data;
        let Token = Value.login_token;

        if (Status == "success") {
          let age = UserData.user_age;
          let email = UserData.user_email;
          let gender = UserData.user_gender;
          let nickname = UserData.user_name;
          let name = UserData.user_real_name;
          let phone = UserData.user_phone_number;
          let profile = UserData.user_profile_img;

          let LocalStorageData = [
            { type: "nickname", value: nickname },
            { type: "name", value: name },
            { type: "age", value: age },
            { type: "profile", value: profile },
            { type: "phone", value: phone },
            { type: "gender", value: gender },
            { type: "email", value: email },
            { type: "token", value: Token },
            { type: "login_type", value: "네이버" },
          ];

          if (SaveMany(LocalStorageData)) {
            navigate("/home");
          }
        } else if (Status == "un_partner") {
          let age = UserData.user_age;
          let email = UserData.user_email;
          let gender = UserData.user_gender;
          let nickname = UserData.user_name;
          let name = UserData.user_real_name;
          let phone = UserData.user_phone_number;
          let profile = UserData.user_profile_img;

          var LocalStorageData = [
            { type: "nickname", value: nickname },
            { type: "name", value: name },
            { type: "age", value: age },
            { type: "profile", value: profile },
            { type: "phone", value: phone },
            { type: "gender", value: gender },
            { type: "email", value: email },
            { type: "token", value: Token },
            { type: "login_type", value: "네이버" },
          ];

          if (SaveMany(LocalStorageData)) {
            //navigate("/partner_register_new/0", {replace: true});
            navigate("/partner_register");
          }
          /*window.alert('미등록된 파트너입니다.\n등록 상담 신청을 통해 파트너 등록을 완료해주세요!')
          window.location.href = 'https://forms.gle/mpMXYxmcxyXetueB8'*/
        } else if (Status === "unready") {
          const StatusMessage = Value.status_message;
          window.alert(StatusMessage);
          navigate("/");
        }  else if (Status == "other_user") {
          // navigate data 전달
          window.alert(
            "이전에 가입한 아이디가 있어요.\n다른 아이디로 로그인해 주세요!"
          );
          window.location.replace(window.location.origin);
        } else if (Status == "fail") {
          // navigate data 전달
          window.alert(
            "로그인이 실패했습니다.\n로그인 실패가 반복된다면 고객센터로 문의해주세요!"
          );
          window.location.replace(window.location.origin);
        }
      });
  };

  return <div></div>;
};

export default NaverLogin;
