import React, { useState } from "react";
import styled from "styled-components";
import Modal from "react-modal";

// style
import { W } from "../../styles/globalStyles";

// img
import ConfirmWarn from "../../icon/ConfirmWarning.png";
import colors from "../../styles/colors";

function BasicConfirm({
  modal,
  setModal,
  title,
  small,
  leftText,
  rightText,
  leftFunc,
  rightFunc,
}) {
  const closeModal = () => {
    setModal(false);
  };
  return (
    <Modal
      isOpen={modal}
      // onAfterOpen={afterOpenModal}
      onRequestClose={closeModal}
      contentLabel="Confirm Modal"
      style={customStyles}
    >
      <Container>
        <Icon src={ConfirmWarn} />
        <Space h={32} />
        <Title>{title}</Title>
        <Space h={8} />
        <SmallTitle>{small}</SmallTitle>
        <Space h={42} />
        <ButtonBox>
          <Button bg={colors.White} c={colors.Bl07} onClick={leftFunc}>
            {leftText}
          </Button>
          <Button bg={colors.Bl07} c={colors.White} onClick={rightFunc}>
            {rightText}
          </Button>
        </ButtonBox>
      </Container>
    </Modal>
  );
}

export default BasicConfirm;

const customStyles = {
  content: {
    width: `${W - 48}px`,
    height: "323px",
    marginRight: "-50%",
    padding: "45px 24px 24px 24px",
    borderRadius: "16px",
    top: "50%",
    right: "auto",
    bottom: "auto",
    left: "50%",
    transform: "translate(-50%, -50%)",
    // zIndex: 20,
  },
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  /* justify-content: space-between; */
  align-items: center;
`;

const Icon = styled.img`
  width: 76px;
  height: 76px;
`;

const Title = styled.div`
  color: ${colors.Gr05};
  text-align: center;
  line-height: 142%;
  letter-spacing: -0.02em;
  font-size: 18px;
  font-weight: 600;
`;

const SmallTitle = styled.div`
  color: ${colors.Gr03};
  text-align: center;
  line-height: 142%;
  letter-spacing: -0.02em;
  font-size: 12px;
  font-weight: 500;
`;

const Space = styled.div`
  height: ${({ h }) => h}px;
`;

const ButtonBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

const Button = styled.div`
  display: flex;
  justify-content: center;
  width: 49%;
  padding: 14px 0;
  background-color: ${({ bg }) => bg};
  border-radius: 8px;
  color: ${({ c }) => c};
  font-size: 14px;
  font-weight: 500;
  line-height: 142%;
  cursor: pointer;
`;
