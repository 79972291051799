import React, { useState } from "react";
import styled from "styled-components";
import Modal from "react-modal";

// style
import { W } from "../../styles/globalStyles";
import colors from "../../styles/colors";

// img
import AlertWarn from "../../icon/blueWarning.png";

function BasicAlert({ modal, setModal, title, small, text, func }) {
  const closeModal = () => {
    setModal(false);
    func();
  };

  return (
    <Modal
      isOpen={modal}
      // onAfterOpen={afterOpenModal}
      onRequestClose={closeModal}
      contentLabel="Alert Modal"
      style={customStyles}
    >
      <Container>
        <Title>{title}</Title>
        <Space h={8} />
        <SmallTitle>{small}</SmallTitle>
        <Space h={32} />
        <Icon src={AlertWarn} />
        <Space h={40} />

        <Button c={colors.White} bg={colors.Bl07} onClick={func}>
          {text}
        </Button>
      </Container>
    </Modal>
  );
}

export default BasicAlert;

const customStyles = {
  content: {
    width: `${W - 48}px`,
    height: "323px",
    marginRight: "-50%",
    padding: "48px 24px 20px 24px",
    borderRadius: "16px",
    top: "50%",
    right: "auto",
    bottom: "auto",
    left: "50%",
    transform: "translate(-50%, -50%)",
    // zIndex: 20,
  },
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  /* justify-content: space-between; */
  align-items: center;
`;

const Icon = styled.img`
  width: 76px;
  height: 76px;
`;

const Title = styled.div`
  color: ${colors.Gr05};
  text-align: center;
  line-height: 142%;
  letter-spacing: -0.02em;
  font-size: 18px;
  font-weight: 600;
`;

const SmallTitle = styled.div`
  font-weight: 500;
  font-size: 12px;
  line-height: 142%;
  text-align: center;
  letter-spacing: -0.02em;
  color: ${colors.Gr03};
`;

const Space = styled.div`
  height: ${({ h }) => h}px;
`;

const Button = styled.div`
  cursor: pointer;
  width: 100%;
  padding: 14px 0;
  display: flex;
  justify-content: center;
  background-color: ${({ bg }) => bg};
  color: ${({ c }) => c};
  font-weight: 500;
  font-size: 14px;
  line-height: 142%;
  border-radius: 8px;
`;
