import React, { useEffect, useState, useRef } from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import axios from "axios";

// style
import colors from "../../styles/colors";

// component
import { Wrapper, Text } from "../../components/layouts/layoutStorage";
import PartnerRuleInformation from "./PartnerRuleInformation";
import PartnerRuleService from "./PartnerRuleService";
import { GetOne, SaveMany } from "../../tool/localstorage";

// img
import { ReactComponent as CheckCircle } from "../../resource/icon/check_circle.svg";

const TERMS_LIST = [
    { name: "개인정보 수집・이용 동의(필수)", value: "information" },
    { name: "서비스 이용약관(필수)", value: "service" },
];

const PriceList = {
    "월간" : {
        price : 16500,
        price_text : '16,500',
        month : 1,
        month_price : 16500,
        month_price_text : '16,500',
        sub_title : "매월",
        discount : 0
    },
    "연간" : {
        price : 178200,
        price_text : '178,200',
        month : 12,
        month_price : 14850,
        month_price_text : '14,850',
        sub_title : "매년",
        discount : 10
    },
}

const PartnerPayment = ({setPage, page, setData, data, valid, setValid}) => {

    const navigate = useNavigate();

    const [openTerms, setOpenTerms] = useState({
        information: false,
        service: false,
        advertise: false,
    });

    const ChangeMonth = (Value) => {

        const PlanData = PriceList[Value];

        let PT = Value;
        let PRICE = PlanData.price;
        let PRICE_TEXT = PlanData.price_text;

        setData((prev) => ({ ...prev, plan_type : PT, price : PRICE, price_text : PRICE_TEXT }));
    }

    const ChangeCard = (Value) => {

        let CardNumber = Value.replace(/[^0-9]/g, '');
        if(CardNumber?.length) {
            CardNumber = CardNumber.match(/[0-9●]{1,4}/g)?.join('-');
        }

        setData((prev) => ({ ...prev, credit_card : CardNumber }));

        if(CardNumber?.length > 18) {
            setValid((prev) => ({ ...prev, credit_card : true }));
        } else {
            setValid((prev) => ({ ...prev, credit_card : 'default' }));
        }
    }

    const ChangeExpiration = (type, Value) => {

        let Expiration = Value.replace(/[^0-9]/g, '');
        let Max = (type == 'expiration_year') ? 13 : 32;

        setData((prev) => ({ ...prev, [type] : Expiration }));

        if(Expiration?.length > 1 && Number(Expiration) < Max) {
            setValid((prev) => ({ ...prev, [type] : true }));
        } else {
            setValid((prev) => ({ ...prev, [type] : false }));
        }
    }

    const SaveData = () => {

            const token = GetOne("token");
            const login_type = GetOne("login_type");
            const age = GetOne("age");
            const gender = GetOne("gender");
            const email = GetOne("email");
            const nickname = GetOne("nickname");
            const name = GetOne("name");

            const frm = new FormData();
            frm.append("mode", "partner_info_save");
            frm.append("token", token);
            frm.append("page", page);
            frm.append("name", data?.name);
            frm.append("plan", data?.plan);
            frm.append("plan_type", data?.plan_type);
            frm.append("price", data?.price);
            frm.append("price_text", data?.price_text);
            frm.append("credit_card", data?.credit_card);
            frm.append("expiration_year", data?.expiration_year);
            frm.append("expiration_month", data?.expiration_month);
            frm.append("confirm", data?.confirm);
            frm.append("phone", data?.phone);
            frm.append("login_type", login_type);
            frm.append("age", age);
            frm.append("gender", gender);
            frm.append("email", email);

            axios
                .post("https://ai.semos.kr/semos_partner/v3/profile/ApplicationForm", frm, {
                headers: {
                    "Content-Type": "multipart/form-data",
                    Accept: "application/json",
                },
                })
                .then((data) => {
                    const Value = data.data;
                    const Status = Value.status;
                    const StatusMessage = Value.status_message;

                    if (Status === "success") {
                        setPage(3);
                        navigate("/partner_register_new/3", {replace: true});
                    } else {
                        window.alert(StatusMessage)
                    }
                });
    };

    const NextPage = () => {
        if(NextCheck) {
            SaveData()
        }
    }

    const handleOpenTerms = (target) =>
        setOpenTerms({ ...openTerms, [target]: !openTerms[target] });

    const NextCheck = (data?.credit_card) 
    && (data?.expiration_year) 
    && (data?.expiration_month)
    && (data?.confirm) 
    && (data?.price) 
    && (data?.price_text) 
    && (data?.plan_type) 
    && (valid?.credit_card === true) 
    && (valid?.expiration_year === true) 
    && (valid?.expiration_month === true) 
    ;

    return (
        <Content>

            <Text
                size="1.8rem"
                weight="600"
                color={colors.Gr05}
                width="100%"
                style={{
                    marginBottom : "12px"
                }}
            >
                {`[${data?.plan}] 정기결제 방식 선택`}
            </Text>           

            {
                Object.keys(PriceList).map((val, idx) => (
                    <Wrapper
                        width="100%"
                        border={`1px solid ${colors.Gr02}`}
                        bgc={colors.White}
                        padding={`15px 10px`}
                        radius={(idx == 0) ? "8px 8px 0 0" : "0 0 8px 8px"}
                        wrap={`wrap`}
                        key={`${idx}_price`}
                        bb={(idx == 0) ? "none" : `1px solid ${colors.Gr02}`}
                        cursor={`pointer`}
                        onClick={() => ChangeMonth(val)}
                    >
                        <Wrapper 
                            jc={`space-between`}
                            width={`100%`}
                        >
                            <Text
                                size="1.8rem"
                                weight="500"
                                color={(data.plan_type == val) ?  colors.Bl07 : colors.Bl04}
                            >
                                {`${PriceList[val].month}개월 (${val})`}
                            </Text>

                            <Wrapper 
                                ai={`center`}
                            >
                                <Text
                                    size="1.8rem"
                                    weight="500"
                                    color={(data.plan_type == val) ?  colors.Bl07 : colors.Bl04}
                                    style={{
                                        marginRight : '5px'
                                    }}
                                >
                                    {`월 ${PriceList[val].month_price_text}원`}
                                </Text>

                                <CheckCircle
                                    fill={(data.plan_type == val) ? colors.Bl07 : colors.Gr03}
                                />
                            </Wrapper>
                        </Wrapper>

                        {
                            (PriceList[val].month > 1) ?
                                <Wrapper 
                                    jc={`space-between`}
                                    width={`100%`}
                                    margin={'5px 0 0 0'}
                                >
                                    <Text
                                        size="1.6rem"
                                        weight="500"
                                        color={(data.plan_type == val) ?  colors.Bl07 : colors.Bl04}
                                    >
                                        {`${PriceList[val].discount}% 할인`}
                                    </Text>

                                    <Text
                                        size="1.6rem"
                                        weight="500"
                                        color={(data.plan_type == val) ?  colors.Bl07 : colors.Bl04}
                                    >
                                        {`총 ${PriceList[val].price_text}원`}
                                    </Text>
                                </Wrapper>
                            : 
                                <></>
                        }
                    </Wrapper>
                ))
            }

            <Text
                size="1.8rem"
                weight="600"
                color={colors.Gr06}
                width="100%"
                style={{
                    marginTop : '5px',
                    textalign : "right",
                    justifyContent : "flex-end"
                }}
            >
                {`가격 : ${data?.price_text}원`}
            </Text>

            <Divider h={`20`} />

            <Wrapper
                width="100%"
                bgc={colors.White}
                wrap={`wrap`}
            >
                <Text
                    size="1.8rem"
                    weight="600"
                    color={colors.Gr05}
                    width="100%"
                    style={{
                        marginBottom : "12px"
                    }}
                >
                    {'카드번호'}
                </Text>

                <Wrapper
                    width="100%"
                    border={`1px solid ${valid?.name ? (valid?.name === 'default' ? colors.Gr02 : colors.Bl07 ) : colors.Red03}`}
                    bgc={colors.White}
                    padding={`0 16px`}
                    radius={"8px"}
                    ai={`center`}
                    jc={`space-between`}
                    height={`52px`}
                >
                    <Input value={data?.credit_card} onChange={(e) => ChangeCard(e.target.value)} maxLength={`19`} width={`calc(100% - 34px)`} type={`text`} placeholder={`카드번호를 적어주세요`} />
                    {
                        (valid?.credit_card === true) && (valid?.credit_card !== 'default') ?
                            <CheckCircle width={`24px`} height={`24px`} fill={colors.Bl07} style={{ cursor : "pointer" }} />
                        : <></>
                    }
                </Wrapper>
                <Text
                    size="1.3rem"
                    weight="500"
                    color={colors.Gr04}
                    width="100%"
                    style={{
                        marginTop : '3px'
                    }}
                >
                    {'- 결제에 사용할 카드번호를 적어주세요'}
                </Text>

                <Divider h={`20`} />

                <Text
                    size="1.8rem"
                    weight="600"
                    color={colors.Gr05}
                    width="100%"
                    style={{
                        marginBottom : "12px"
                    }}
                >
                    {'유효기간'}
                </Text>

                <Wrapper
                    width="100%"
                    bgc={colors.White}
                    ai={`center`}
                    jc={`flex-start`}
                >
                    <Wrapper
                        width="100px"
                        border={`1px solid ${valid?.expiration_year ? (valid?.expiration_year === 'default' ? colors.Gr02 : colors.Bl07 ) : colors.Red03}`}
                        bgc={colors.White}
                        padding={`0 16px`}
                        radius={"8px"}
                        ai={`center`}
                        jc={`space-between`}
                        height={`52px`}
                    >
                        <Input value={data?.expiration_year} onChange={(e) => ChangeExpiration('expiration_year', e.target.value)} maxLength={`2`} width={`calc(100% - 34px)`} type={`text`} placeholder={`YY`} />
                        {
                            (valid?.expiration_year === true) && (valid?.expiration_year !== 'default') ?
                                <CheckCircle width={`24px`} height={`24px`} fill={colors.Bl07} style={{ cursor : "pointer" }} />
                            : <></>
                        }
                    </Wrapper>

                    <Text
                        size="2.5rem"
                        weight="500"
                        color={colors.Gr05}
                        width="auto"
                        margin="0 8px"
                    >
                        {'/'}
                    </Text>

                    <Wrapper
                        width="100px"
                        border={`1px solid ${valid?.expiration_month ? (valid?.expiration_month === 'default' ? colors.Gr02 : colors.Bl07 ) : colors.Red03}`}
                        bgc={colors.White}
                        padding={`0 16px`}
                        radius={"8px"}
                        ai={`center`}
                        jc={`space-between`}
                        height={`52px`}
                    >
                        <Input value={data?.expiration_month} onChange={(e) => ChangeExpiration('expiration_month', e.target.value)} maxLength={`2`} width={`calc(100% - 34px)`} type={`text`} placeholder={`MM`} />
                        {
                            (valid?.expiration_month === true) && (valid?.expiration_month !== 'default') ?
                                <CheckCircle width={`24px`} height={`24px`} fill={colors.Bl07} style={{ cursor : "pointer" }} />
                            : <></>
                        }
                    </Wrapper>
                </Wrapper>

                <Text
                    size="1.3rem"
                    weight="500"
                    color={colors.Gr04}
                    width="100%"
                    style={{
                        marginTop : '3px'
                    }}
                >
                    {'- 년도와 월을 적어주세요 예시) 28/08'}
                </Text>

                <TermsContainer>
                    <TermsList>
                            <li>
                                <div>
                                    {TERMS_LIST.map((val, idx) => {
                                        const { name, value } = val;
                                        return (
                                            <span key={`${idx}_term`} onClick={() => handleOpenTerms(value)}>{name}</span>
                                        )
                                    })}
                                </div>

                                <button onClick={() => setData((prev) => ({ ...prev, confirm : !data.confirm }))}>
                                    <CheckCircle
                                        fill={data.confirm ? colors.Bl07 : colors.Gr03}
                                    />
                                </button>
                            </li>
                    </TermsList>
                </TermsContainer>

            </Wrapper>

            <Wrapper
                width="100%"
                bgc={colors.White}
                padding={`10px 24px`}
                style={{
                    position : "fixed",
                    bottom : 0,
                    left : '50%',
                    transform : "translate(-50%, 0%)",
                    zIndex : 10,
                    borderTop : `1px solid ${colors.Gr01}`,
                    maxWidth : '800px'
                }}
            >
                <Wrapper
                    width="100%"
                    bgc={colors.Bl07}
                    radius={`8px`}
                    height={`52px`}
                    cursor={`pointer`}
                    ai={`center`}
                    jc={`center`}
                    onClick={() => NextPage()}
                    style={{
                        opacity : (NextCheck === true) ? 1 : 0.3,
                        position : "relative"
                    }}
                >
                    {/*<Text
                        size="1.8rem"
                        weight="600"
                        color={colors.Bl07}
                        style={{
                            position : "absolute",
                            top : '-30px',
                            right : 0
                        }}
                    >
                        {`가격 : ${data?.price_text}원 (${data?.plan_type})`}
                    </Text>*/}

                    <Text
                        size="1.6rem"
                        weight="600"
                        color={colors.White}
                    >
                        {`${data?.plan} 신청`}
                    </Text>
                </Wrapper>
            </Wrapper> 


            {openTerms.information && (
                <PartnerRuleInformation
                    setRule={() => handleOpenTerms("information")}
                />
            )}
            {openTerms.service && (
                <PartnerRuleService setRule={() => handleOpenTerms("service")} />
            )}

        </Content>

    )
}

export default PartnerPayment;

const Content = styled.div`
    padding: 24px 24px 70px 24px;
    background-color: ${colors.White};
    width : 100%;
    max-width : 800px;
    margin : 0 auto;
`;

const Input = styled.input`
    width: ${({ width }) => ( width ? width : '100%' )};
    background-color: ${colors.White};
    border-radius: 8px;
    font-size : 14px;   
    font-weight : 500;
`;

const Divider = styled.div`
    width : 100%;
    height: ${({ h }) => h}px;
    display : inline-block;
`;

const TermsContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    width : 100%;
`;

const TermsList = styled.ul`
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    gap: 8px;
    padding-top: 8px;

    li {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 8px 0px;
        cursor: pointer;

        span {
            color: ${colors.Gr04};
            text-decoration-line: underline;
            line-height: 142%;
            font-size: 14px;
            font-weight: 600;
            margin-right: 5px;
        }

        svg {
            width: 24px;
            height: 24px;
        }
    }
`;
