import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";

//style
import { Wrapper, Text, Input, AbsoluteWrapper, TextArea } from "../layouts/layoutStorage";
import colors from "../../styles/colors";

//tool
import { localeStringMaker, localeToNumber } from "../../tool/regex";

//icon
import { ReactComponent as Plus } from "../../resource/icon/plus.svg";
import { ReactComponent as Minus } from "../../resource/icon/minus.svg";

const MAX_TITLE_LENGTH = 50;
const MAX_CONTENT_LENGTH = 500;

const AddCurriculum = ({number, item, index, CurriHandler, CurriHandlerDel, CurriHandlerPlus, last }) => {
    const ref = useRef();
    const [focusTitle, setFocusTitle] = useState(false);
    const [focusTime, setFocusTime] = useState(false);
    const [focusContent, setFocusContent] = useState(false);
    const [height, setHeight] = useState("");

    const [minCheck, setMinCheck] = useState(true);

    useEffect(() => {
        if(Number(item.last) < 4) {
            setMinCheck(false);
        } else {
            setMinCheck(true);
        }
    }, [item.last]);

    return (
        <>
        <Wrapper key={`curriculum_${index}`} position="relative" fd="column" width="calc(100% - 20px)" style={{ alignSelf: "flex-end" }}>
            
            {/*<Wrapper
                width="100%"
                jc="space-between"
                ai="center"
            >
                <Wrapper
                    height="38px"
                    width="calc(100% - 160px)"
                    border={`1px solid ${focusTitle ? colors.Bl07 : colors.Gr02}`}
                    radius="8px"
                    padding="0 10px"
                    jc="center"
                    ai="center"
                >
                    <Input
                        onFocus={() => setFocusTitle(true)}
                        onBlur={() => setFocusTitle(false)}
                        width="100%"
                        value={item.title}
                        onChange={(e) => 
                            CurriHandler(number, index, 'title', e.target.value.substring(0, MAX_TITLE_LENGTH))}
                        size="14px"
                        weight="500"
                        lh="22px"
                        ls="-0.28px"
                        phColor={colors.Gr03}
                        placeholder="과정 제목을 입력해주세요"
                        maxLength={MAX_TITLE_LENGTH}
                    />
                    <Text margin="0 0 0 4px" size="14px" weight="500" lh="22px" ls="-0.28px" color={colors.Gr03}>
                        {item.title.length}/{MAX_TITLE_LENGTH}
                    </Text>
                </Wrapper>

                <Wrapper
                    height="38px"
                    width="150px"
                    border={`1px solid ${focusTime ? colors.Bl07 : colors.Gr02}`}
                    radius="8px"
                    padding="0 8px"
                    jc="center"
                    ai="center"
                >
                    <Input
                        width="100%"
                        value={item.last}
                        onChange={(e) => 
                            CurriHandler(number, index, 'last', localeToNumber(e.target.value))}
                        onFocus={() => setFocusTime(true)}
                        onBlur={() => setFocusTime(false)}
                        placeholder="분"
                        maxLength={4}
                    />
                </Wrapper>
            </Wrapper>*/}

            <Wrapper
                width="100%"
                jc="space-between"
                ai="flex-end"
                margin="8px 0 0 0"
            >
                <Wrapper
                    fd={height > 44 ? "column" : "row"}
                    style={{ minHeight: "44px" }}
                    width="calc(100% - 115px)"
                    border={`1px solid ${focusContent ? colors.Bl07 : colors.Gr02}`}
                    radius="8px"
                    padding="8px 10px"
                    jc="center"
                    ai="center"
                >
                    <TextArea
                        maxLength={MAX_CONTENT_LENGTH}
                        onFocus={() => setFocusContent(true)}
                        onBlur={() => setFocusContent(false)}
                        ref={ref}
                        rows="1"
                        width="100%"
                        value={item.content}
                        onChange={(e) => {
                            CurriHandler(number, index, 'content', e.target.value.substring(0, MAX_CONTENT_LENGTH))
                            if(ref.current) {
                                ref.current.style.height = 'auto';
                                let HeightMax = 160;
                                let HeightMin = 80;
                                if(HeightMax > ref.current.scrollHeight) {
                                    if(HeightMin > ref.current.scrollHeight) {
                                        HeightMax = HeightMin;
                                    } else {
                                        HeightMax = ref.current.scrollHeight;
                                    }
                                }
                                ref.current.style.height =`${HeightMax}px`;
                            }
                        }}
                        size="14px"
                        weight="500"
                        lh="22px"
                        ls="-0.28px"
                        phColor={colors.Gr03}
                        placeholder="과정 내용(필수 입력)"
                        style={{
                            minHeight : 80
                        }}
                    />
                    <Text margin="0 0 0 4px" style={{ alignSelf: "end" }} size="14px" weight="500" lh="22px" ls="-0.28px" color={colors.Gr03}>
                        {item.content.length}/{MAX_CONTENT_LENGTH}
                    </Text>
                </Wrapper>

                <Wrapper
                    style={{
                        flexDirection : "column",
                        justifyContent : "space-between",
                        alignItems : "flex-end",
                        height : "100%"
                    }}
                >
                    <Wrapper
                        height="38px"
                        width="105px"
                        border={`1px solid ${focusTime ? colors.Bl07 : colors.Gr02}`}
                        radius="8px"
                        padding="0 8px"
                        jc="center"
                        ai="center"
                    >
                        <Text style={{ whiteSpace : "nowrap", flexWrap : "nowrap" }} size="14px" weight="500" color={colors.Gr03}>
                            {`시간(분) :`}
                        </Text>

                        <Input
                            width="100%"
                            value={item.last}
                            onChange={(e) => 
                                CurriHandler(number, index, 'last', localeToNumber(e.target.value))
                            }
                            onFocus={() => setFocusTime(true)}
                            onBlur={() => setFocusTime(false)}
                            placeholder="분"
                            maxLength={4}
                            style={{
                                textAlign : "end"
                            }}
                        />
                    </Wrapper>

                    <Wrapper
                        jc={last ? `space-between` : `flex-end`}
                        width={`100%`}
                    >
                        <Wrapper
                            margin="8px 0 0 0"
                            style={{ maxHeight: "44px" }}
                            height="44px"
                            width="44px"
                            border={`1px solid ${colors.Gr02}`}
                            radius="8px"
                            jc="center"
                            ai="center"
                            onClick={() => CurriHandlerDel(number, index)}
                        >
                            {/*<Minus width="20px" height="20px" fill={colors.Red03} />*/}
                            <Text size="14px" weight="500" color={colors.Red03}>
                                {`삭제`}
                            </Text>
                        </Wrapper>

                        {   
                            last ? 
                                <Wrapper
                                    margin="8px 0 0 0"
                                    style={{ maxHeight: "44px" }}
                                    height="44px"
                                    width="44px"
                                    border={`1px solid ${colors.Gr02}`}
                                    radius="8px"
                                    jc="center"
                                    ai="center"
                                    onClick={() => CurriHandlerPlus(number, index)}
                                >
                                    {/*<Plus width="20px" height="20px" fill={colors.Bl07} />*/}

                                    <Text size="14px" weight="500" color={colors.Bl07}>
                                        {`추가`}
                                    </Text>
                                </Wrapper>
                            : <></>
                        }
                    </Wrapper>


                </Wrapper>
                
            </Wrapper>

            <Wrapper
                jc={`flex-end`}
                ai={`flex-end`}
                width={`100%`}
                margin={`5px 0 0 0`}
            >
                {
                    (minCheck == false) ? 
                        <Text style={{ whiteSpace : "nowrap", flexWrap : "nowrap" }} size="14px" weight="500" color={colors.Red03}>
                            {`소요시간은 분 단위로 입력해주세요!`}
                        </Text>
                    : <></>
                }
            </Wrapper>

            <AbsoluteWrapper top="3px" left="-16.5px">
            <Wrapper width="10px" height="10px" radius="50%" bgc={colors.Gr02} />
            </AbsoluteWrapper>
        </Wrapper>
        </>
    );
};

export default AddCurriculum;
