export function localeStringMaker(value) {
  var copy = String(value).replace(/\D/g, "");
  copy = copy.replace(/\B(?=(\d{3})+(?!\d))/g, ",");

  return copy;
}

export function localeToNumber(value) {
  var copy = value.replace(/\D/g, "");
  copy = parseInt(copy);

  if (isNaN(copy)) {
    return 0;
  } else {
    return copy;
  }
}
