import React, { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import axios from "axios";

// style
import colors from "../../styles/colors";

// component
import { Wrapper, Text } from "../../components/layouts/layoutStorage";
import AccountImageUploader from "../../components/accountRevise/AccountImageUploader";
import { GetOne } from "../../tool/localstorage";


const PartnerAccount = ({setPage, page, setData, data, loading, setLoading}) => {

    const navigate = useNavigate();

      // SAVE DATA
    const saveData = () => {
        const token = GetOne("token");
        
        axios
        .post(
            "https://ai.semos.kr/semos_partner/v3/profile/ApplicationForm",
            {
                mode: "partner_account_save",
                data: data,
                token: token,
            },
            { headers: { "Content-Type": "application/x-www-form-urlencoded" } }
        )
        .then((res) => {
            const value = res.data;
            const status = value.status;
            const statusMessage = value.status_message;

            if (status === "success") {
                navigate("/partner_register_new/4", {replace: true});
                setPage(4);
            } else {
                window.alert(statusMessage);
            }
        });
    };

    const NextPage = (type) => {
        if(type === 'now') {
            if(NextCheck) {
                saveData()
            }
        } else if(type === 'next') {
            navigate("/partner_register_new/4", {replace: true});
            setPage(4);
        }
    }

    const NextCheck = ((data?.id_card?.length > 0) || (data?.business_card?.length > 0)) && ((data?.account_card?.length > 0));

    return (

        <Content>

            <Wrapper
                width="100%"
                wrap="wrap"
            >

                {/* 신분증 사진 */}
                <TextInputContainer>
                    <InputTitle>
                        신분증 이미지
                        <span className="Description" >{'(사업자가 아닌 경우)'}</span>    
                    </InputTitle>
                    <AccountImageUploader
                        type="id_card"
                        data={data}
                        setData={setData}
                        loading={loading}
                        setLoading={setLoading}
                        loader={true}
                    />
                </TextInputContainer>

                {/* 사업자등록증 */}
                <TextInputContainer>
                    <InputTitle>
                        사업자등록증 
                        <span className="Description" >{'(사업자인 경우)'}</span>  
                    </InputTitle>
                    <AccountImageUploader
                        type="business_card"
                        data={data}
                        setData={setData}
                        loading={loading}
                        setLoading={setLoading}
                        loader={true}
                    />
                </TextInputContainer>

                {/* 통장 사본 */}
                <TextInputContainer>
                    <InputTitle>
                        통장 사본
                        <span className="Description" >{'(필수)'}</span>    
                    </InputTitle>
                    <AccountImageUploader
                        type="account_card"
                        data={data}
                        setData={setData}
                        loading={loading}
                        setLoading={setLoading}
                        loader={true}
                    />
                </TextInputContainer>

            </Wrapper>


            <Wrapper
                width="100%"
                bgc={colors.White}
                padding={`10px 24px`}
                style={{
                    position : "fixed",
                    bottom : 0,
                    left : '50%',
                    transform : "translate(-50%, 0%)",
                    zIndex : 10,
                    borderTop : `1px solid ${colors.Gr01}`,
                    gap : "10px",
                    maxWidth : '800px'
                }}
            >
                <Wrapper
                    width="50%"
                    bgc={colors.Red03}
                    radius={`8px`}
                    height={`52px`}
                    cursor={`pointer`}
                    ai={`center`}
                    jc={`center`}
                    onClick={() => NextPage('next')}
                >
                    <Text
                        size="1.6rem"
                        weight="600"
                        color={colors.White}
                    >
                        {"나중에 입력하기"}
                    </Text>
                </Wrapper>

                <Wrapper
                    width="50%"
                    bgc={colors.Bl07}
                    radius={`8px`}
                    height={`52px`}
                    cursor={`pointer`}
                    ai={`center`}
                    jc={`center`}
                    onClick={() => NextPage('now')}
                    style={{
                        opacity : (NextCheck === true) ? 1 : 0.3,
                        position : "relative"
                    }}
                >
                    <Text
                        size="1.6rem"
                        weight="600"
                        color={colors.White}
                    >
                        {"정산정보 저장하기"}
                    </Text>
                </Wrapper>
            </Wrapper> 

        </Content>

    )
}

export default PartnerAccount;

const Content = styled.div`
    padding: 24px 24px 70px 24px;
    background-color: ${colors.White};
    width : 100%;
    max-width : 800px;
    margin : 0 auto;
`;


const TextInputContainer = styled.div`
    background-color: ${colors.White};
    margin-bottom : 16px;
    //padding: 24px 24px 0 24px;
`;

const InputTitle = styled.span`
    display: inline-flex;
    color: ${colors.Gr05};
    line-height: 24px;
    letter-spacing: -0.32px;
    font-size: 16px;
    font-weight: 600;
    width : 100%;
    white-space : pre-wrap;
    align-items: center;
    margin-bottom : 4px;

    .Description {
        color: ${colors.Bl07};
        font-size: 12px;
        font-weight: 500;
        line-height: 26px;
        margin-left : 5px
    }
`;