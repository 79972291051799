import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import axios from "axios";

//tool
import { GetOne } from "../tool/localstorage";
import { fbevent } from "../tool/firebase";
import { Loading } from "../tool/Loading";

//style
import { Wrapper, Text } from "../components/layouts/layoutStorage";
import colors from "../styles/colors";

//component
import ActivityIntroduceFaqInput from "../components/newActivityRegister/activity_introduce_faq_input";
import ActivityIntroduceFaqItem from "../components/newActivityRegister/activity_introduce_faq_item";
import Header from "../components/basic/Header";

//icon


const CenterFaq = () => {
    const navigate = useNavigate();

    const [loading, setLoading] = useState(false);
    const [deleteD, setDelete] = useState({
        faq: []
    });
    const [data, setData] = useState({
        faq: [] // 자주 묻는 질문
    });
    const [faqSearch, setFaqSearch] = useState([]);

    const getData = () => {
        fbevent("B2B_PartnerFAQ_Page_View", {
            email : GetOne("email")
        });

        setLoading(true);
        let token = GetOne("token");

        const frm = new FormData();
        frm.append("mode", "main");
        frm.append("token", token);

        axios
        .post("https://ai.semos.kr/semos_partner/v3/profile/Faq", frm, {
            headers: { "Content-Type": "multipart/form-data" },
        })
        .then((data) => {
            let Value = data.data;
            let Status = Value.status;
            let StatusMessage = Value.status_message;

            if (Status == "success") {
                setData(Value.data);
                setLoading(false);
            } else {
                window.alert(StatusMessage);
                setLoading(false);
            }
        });
    };

    const getBigData = (searchType, searchText) => {
        let token = GetOne("token");
        const frm = new FormData();
        frm.append("mode", "hot_tag");
        frm.append("token", token);
        frm.append("category", searchType);
        frm.append("text", searchText);
        axios
        .post("https://ai.semos.kr/semos_partner/v3/profile/Faq", frm, {
            headers: { "Content-Type": "multipart/form-data" },
        })
        .then((data) => {
            const value = data.data;
            const status = value.status;
            const statusMessage = value.status_message;
            const registerText = value.text_list;

            if (status === "success") {
                if (searchType === "faq") {
                    setFaqSearch(registerText);
                }
            } else {
            window.alert(statusMessage);
            }
        });
    };

    const temporarySaveData = () => {
        fbevent("B2B_PartnerFAQ_Save_Click", {
            email : GetOne("email")
        });

        setLoading(true);
        const token = GetOne("token");

        axios
        .post(
            "https://ai.semos.kr/semos_partner/v3/profile/Faq",
            {
                mode: "save",
                token: token,
                data: data,
                delete: deleteD
            },
            {
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
            },
            }
        )
        .then((data) => {
            let Value = data.data;
            let Status = Value.status;
            let StatusMessage = Value.status_message;

            if (Status == "success") {
                window.alert('자주 묻는 질문 수정이 완료되었습니다.')
                setLoading(false);
                window.location.reload();
            } else {
                window.alert(StatusMessage);
                window.location.reload();
            }
        });
    };

    useEffect(() => {
        getData();
    }, []);

    return (
            <Container>
                <Header
                    title="자주 묻는 질문"
                    back={true}
                    handleClick={() => (navigate(-1))}
                />
                <Wrapper width="100%" fd="column" of="auto" padding="24px 0 120px 0">
                    <Wrapper ai="flex-start" fd="column" width="100%" padding="0 24px 48px 24px">

                        {/* 자주 묻는 질문 */}
                        <Wrapper padding="24px 0 14px 0" width="100%" ai="flex-start" jc="space-between">
                            <Text size="16px" weight="600" lh="24px" ls="-0.32px" color={colors.Gr05}>
                            자주 묻는 질문
                            </Text>
                        </Wrapper>
                        <ActivityIntroduceFaqInput bigData={getBigData} faq={data.faq} setData={setData} textRecommend={faqSearch} />
                        {data.faq.map((el, i) => (
                            <ActivityIntroduceFaqItem
                                key={`${el.q}_${i}_faq`}
                                faq={data.faq}
                                setData={setData}
                                deleteD={deleteD}
                                setDelete={setDelete}
                                bigData={getBigData}
                                index={i}
                                textRecommend={faqSearch}
                                el={el}
                            />
                        ))}
                    </Wrapper>
                </Wrapper>

                <BottomWrap>
                    <Wrapper
                        onClick={() => temporarySaveData()}
                        jc="center"
                        ai="center"
                        width="100%"
                        height="52px"
                        cursor="pointer"
                        radius="8px"
                        border=""
                        bgc={colors.Bl07}
                    >
                    <Text ta="center" size="14px" weight="500" lh="22px" ls="-0.28px" color={colors.White}>
                        저장하기
                    </Text>
                    </Wrapper>
                </BottomWrap>
            </Container>
    );
};

export default CenterFaq;

const Container = styled.section`
    height: calc(var(--vh, 1vh) * 100);
    overflow-x: hidden;
    width: 100%;
    padding-bottom : 80px;
`;

const BottomWrap = styled.div`
    width: 100%;
    display: flex;
    padding: 12px 24px;
    border-top: 1px solid ${colors.Gr01};
    background-color: ${colors.White};
    position: absolute;
    justify-content: space-between;
    align-items: center;
    z-index: 10;
    bottom: 0;
    gap: 8px;
`;