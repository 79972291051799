import React, { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import Modal from "react-modal";
import axios from "axios";

//component 
import { GetOne } from "../../tool/localstorage";

// style
import colors from "../../styles/colors";
import { Wrapper, Input, Text, AbsoluteWrapper } from "../../components/layouts/layoutStorage";

// img
import { ReactComponent as Arrow } from "../../resource/icon/arrow.svg";

// default 
const Year = ['2023', '2024', '2025', '2026', '2027', '2028', '2029', '2030'];
const Month = ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'];
const Day = ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12',
'13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23', '24', '25', '26',
'27', '28', '29', '30', '31'];
const Type = {
    '정기 레슨' : "개월",
    '이용권(기간)' : "개월",
    '이용권(횟수)' : "회",
};

const CrewTicketModal = ({
    modal,
    setModal,
    title,
    data,
    setLoading,
    GetData,
    activityList,
    consult_id
}) => {

    const YRef = useRef();
    const MRef = useRef();
    const DRef = useRef();

    const [startTime, setStartTime] = useState();
    const [endTime, setEndTime] = useState();
    const [activity, setActivity] = useState();
    const [people, setPeople] = useState();

    const [dropdownY, setDropdownY] = useState(false);
    const [dropdownM, setDropdownM] = useState(false);
    const [dropdownD, setDropdownD] = useState(false);

    const YearClose = (e) => {
        if (dropdownY && (!YRef.current || !YRef.current.contains(e.target))) {
            setDropdownY(false);
        }
    };

    const MonthClose = (e) => {
        if (dropdownM && (!MRef.current || !MRef.current.contains(e.target))) {
            setDropdownM(false);
        }
    };

    const DayClose = (e) => {
        if (dropdownD && (!DRef.current || !DRef.current.contains(e.target))) {
            setDropdownD(false);
        }
    };


    const [selectDay, setSelectDay] = useState({
        year : new Date().getFullYear(),
        month : ((new Date().getMonth() + 1) > 9) ? ((new Date().getMonth() + 1)) : `0${((new Date().getMonth() + 1))}`,
        day : (new Date().getDate() > 9) ? (new Date().getDate()) : `0${((new Date().getDate() + 1))}`,
    });

    const [deadline, setDeadline] = useState({
        year: "",
        month: "",
        day: "",
        lastDay: "",
    });

    const ChangeTicket = () => {
        setLoading(true);

        var token = GetOne("token");

        axios
        .post("https://ai.semos.kr/semos_partner/v3/profile/Consult", {
            mode: "make_reservation",
            token: token,
            crew_id: data.crew_id,
            activity_number: activity.id,
            time: startTime,
            schedule_id: '',
            amount: activity?.price[people].price,
            people: people,
            start_date : `${selectDay.year}.${selectDay.month}.${selectDay.day} ${startTime}`,
            end_date : `${deadline.year}.${deadline.month}.${deadline.day} ${endTime}`,
            consult_id : consult_id,
            device: 'iOS',
        }, {
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
            },
        })
        .then((data) => {
            var Value = data.data;
            var Status = Value.status;
            var StatusMessage = Value.status_message;

            if (Status == "success") {
                window.alert(StatusMessage);
                window.location.reload();
                setModal(false);
                setLoading(false);
            } else {
                window.alert(StatusMessage);
                setLoading(false);
            }
        });
    }

    const Deadline = () => {

        let Month = 0;
        let Type = activity?.type;

        if(Type == '정기 레슨' || Type == '이용권(기간)') {
            Month = people;
        } else {
            if(people == 1) {
                Month = 1;
            } else if(people == 10) {
                Month = 3;
            } else if(people == 20) {
                Month = 6;
            }
        }

        let Now = new Date(`${selectDay.year}-${selectDay.month}-${selectDay.day}`);

        let Today = new Date(
            Now.setMonth(Now.getMonth() + Number(Month))
        );

        Today = new Date(
            Today.setDate(Today.getDate() - 1)
        );
    
        let ToYear = Today.getFullYear();
        let ToMonth = Today.getMonth();
        let ToDay = Today.getDate();
    
        setDeadline({
            year: ToYear,
            month: (ToMonth + 1) > 9 ? ToMonth + 1 : `0${ToMonth + 1}`,
            day: ToDay > 9 ? ToDay : `0${ToDay}`,
            lastDay: new Date(ToYear, ToMonth + 1, 0).getDate(),
        });
        
    }

    useEffect(() => {
        if(activity) {
            if(activity.type == '정기 레슨' || activity.type == '단기 레슨' || activity.type == '자격증' || activity.type == '징비 대여' || activity.type == '국내/해외 투어') {
                setStartTime();
                setEndTime();
            } else {
                setStartTime('00:00');
                setEndTime('23:59');
            }
        } else {
            setStartTime();
            setEndTime();
        }
    }, [activity]);

    useEffect(() => {
        if(activity && selectDay && startTime && endTime && people) {
            Deadline();
        }
    }, [activity, selectDay, startTime, endTime, people]);

    const ValidCheck = () => {
        return activity && selectDay && startTime && endTime && people && deadline;
    }

    useEffect(() => {
        window.addEventListener("click", YearClose);
    
        return () => {
            window.removeEventListener("click", YearClose);
        };
    }, [dropdownY]);

    useEffect(() => {
        window.addEventListener("click", MonthClose);
    
        return () => {
            window.removeEventListener("click", MonthClose);
        };
    }, [dropdownM]);

    useEffect(() => {
        window.addEventListener("click", DayClose);
    
        return () => {
            window.removeEventListener("click", DayClose);
        };
    }, [dropdownD]);


    return (
        <Modal
            isOpen={modal}
            onRequestClose={() => setModal(false)}
            contentLabel="Alert Modal"
            style={customStyles}
        >
            <Container>

                <AffiliateModalLayout>
                    <Wrapper
                        width={`100%`}
                        margin={`0 0 16px 0`}
                        jc={`center`}
                    >   
                        <Text 
                            width={`100%`} 
                            size="2rem" 
                            weight="700" 
                            lh="24px" 
                            ls="-0.32px" 
                            color={colors.Gr05}
                            ta={`center`}
                        >
                            {title}
                        </Text>
                    </Wrapper>      

                    <Wrapper
                        width={`100%`}
                        margin={`0 0 12px 0`}
                        jc={`space-between`}
                        ai={`center`}
                        wrap={`wrap`}
                    >   
                        <Text 
                            size="1.8rem" 
                            weight="600" 
                            lh="24px" 
                            ls="-0.32px" 
                            color={colors.Gr05}
                            style={{
                                marginBottom : 8
                            }}
                            width={`100%`}
                        >
                            {`액티비티 :`}
                        </Text>

                        <Wrapper
                            jc={`flex-start`}
                            ai={`center`}
                            width={`100%`}
                            wrap={`wrap`}
                        >   
                            {
                                activityList?.map((x, idx) => (
                                    <Wrapper
                                        key={`${idx}_activity`}
                                        margin={`0 ${((idx + 1) != activityList.length) ? '8px' : '0'} 8px 0`}
                                        jc={`center`}
                                        ai={`center`}
                                        padding={`5px 8px`}
                                        radius={`8px`}
                                        border={`2px solid ${(x?.id == activity?.id) ? colors.Bl07 : colors.Gr05}`}
                                        onClick={() => setActivity(x)}
                                        cursor={`pointer`}
                                    >   
                                        <Text 
                                            size="1.4rem" 
                                            weight="600" 
                                            lh="24px" 
                                            ls="-0.32px" 
                                            color={`${(x.id == activity?.id) ? colors.Bl07 : colors.Gr05}`}
                                        >
                                            {x.title}
                                        </Text>
                                    </Wrapper>  
                                ))
                            }

                        </Wrapper>  
                    </Wrapper>   

                    <Wrapper
                        width={`100%`}
                        margin={`0 0 12px 0`}
                        jc={`space-between`}
                        ai={`center`}
                    >   
                        <Text 
                            size="1.8rem" 
                            weight="600" 
                            lh="24px" 
                            ls="-0.32px" 
                            color={colors.Gr05}
                            style={{
                                marginRight : 8
                            }}
                        >
                            {`시작일 :`}
                        </Text>

                        <Wrapper>
                            <Wrapper
                                ref={YRef}
                                padding="8px"
                                cursor="pointer"
                                position="relative"
                                onClick={(e) => {
                                    e.stopPropagation();
                                    setDropdownY(!dropdownY);
                                }}
                                height="44px"
                                radius="8px"
                                width="80px"
                                bgc={colors.White}
                                border={`1px solid ${colors.Gr02}`}
                                jc="space-between"
                            >
                                    <Text size="14px" weight="500" lh="22px" ls="-0.28px" color={colors.Gr05}>
                                        {selectDay.year}년
                                    </Text>

                                    <Arrow
                                        width="16px"
                                        height="16px"
                                        fill={colors.Gr04}
                                        style={{ 
                                            transition: "all ease 0.5s", 
                                            transform: dropdownY ? "rotate(-90deg)" : "rotate(90deg)" 
                                        }}
                                    />

                                    {dropdownY && (
                                        <AbsoluteWrapper
                                            maxHeight="10rem"
                                            z={1}
                                            fd="column"
                                            width="100%"
                                            top="58px"
                                            left="0"
                                            bgc={colors.White}
                                            border={`1px solid ${colors.Gr02}`}
                                            radius="8px"
                                            of="auto"
                                        >
                                            {Year.map((x, idx) => (
                                                <DropDown
                                                    active={x == selectDay.year}
                                                    key={`${idx}_year`}
                                                    last={idx + 1 === Year.length}
                                                    onClick={() => {
                                                        setSelectDay((prev) => ({ ...prev, year: x }));
                                                    }}
                                                >
                                                    {x}
                                                </DropDown>
                                            ))}
                                        </AbsoluteWrapper>
                                    )}
                            </Wrapper>

                            <Wrapper
                                ref={MRef}
                                padding="8px"
                                cursor="pointer"
                                position="relative"
                                onClick={(e) => {
                                    e.stopPropagation();
                                    setDropdownM(!dropdownM);
                                }}
                                height="44px"
                                radius="8px"
                                width="65px"
                                bgc={colors.White}
                                border={`1px solid ${colors.Gr02}`}
                                jc="space-between"
                                margin={`0 0 0 5px`}
                            >
                                    <Text size="14px" weight="500" lh="22px" ls="-0.28px" color={colors.Gr05}>
                                        {selectDay.month}월
                                    </Text>

                                    <Arrow
                                        width="16px"
                                        height="16px"
                                        fill={colors.Gr04}
                                        style={{ 
                                            transition: "all ease 0.5s", 
                                            transform: dropdownM ? "rotate(-90deg)" : "rotate(90deg)" 
                                        }}
                                    />

                                    {dropdownM && (
                                        <AbsoluteWrapper
                                            maxHeight="10rem"
                                            z={1}
                                            fd="column"
                                            width="100%"
                                            top="58px"
                                            left="0"
                                            bgc={colors.White}
                                            border={`1px solid ${colors.Gr02}`}
                                            radius="8px"
                                            of="auto"
                                        >
                                            {Month.map((x, idx) => (
                                                <DropDown
                                                    active={x == selectDay.month}
                                                    key={`${idx}_month`}
                                                    last={idx + 1 === Month.length}
                                                    onClick={() => {
                                                        setSelectDay((prev) => ({ ...prev, month: x }));
                                                    }}
                                                >
                                                    {x}
                                                </DropDown>
                                            ))}
                                        </AbsoluteWrapper>
                                    )}
                            </Wrapper>

                            <Wrapper
                                ref={DRef}
                                padding="8px"
                                cursor="pointer"
                                position="relative"
                                onClick={(e) => {
                                    e.stopPropagation();
                                    setDropdownD(!dropdownD);
                                }}
                                height="44px"
                                radius="8px"
                                width="65px"
                                bgc={colors.White}
                                border={`1px solid ${colors.Gr02}`}
                                jc="space-between"
                                margin={`0 0 0 5px`}
                            >
                                    <Text size="14px" weight="500" lh="22px" ls="-0.28px" color={colors.Gr05}>
                                        {selectDay.day}일
                                    </Text>

                                    <Arrow
                                        width="16px"
                                        height="16px"
                                        fill={colors.Gr04}
                                        style={{ 
                                            transition: "all ease 0.5s", 
                                            transform: dropdownD ? "rotate(-90deg)" : "rotate(90deg)" 
                                        }}
                                    />

                                    {dropdownD && (
                                        <AbsoluteWrapper
                                            maxHeight="10rem"
                                            z={1}
                                            fd="column"
                                            width="100%"
                                            top="58px"
                                            left="0"
                                            bgc={colors.White}
                                            border={`1px solid ${colors.Gr02}`}
                                            radius="8px"
                                            of="auto"
                                        >
                                            {Day.map((x, idx) => (
                                                <DropDown
                                                    active={x == selectDay.day}
                                                    key={`${idx}_day`}
                                                    last={idx + 1 === Day.length}
                                                    onClick={() => {
                                                        setSelectDay((prev) => ({ ...prev, day: x }));
                                                    }}
                                                >
                                                    {x}
                                                </DropDown>
                                            ))}
                                        </AbsoluteWrapper>
                                    )}
                            </Wrapper>
                        </Wrapper>

                    </Wrapper>

                    {
                        activity ?
                            <>
                                <Wrapper
                                    width={`100%`}
                                    margin={`0 0 12px 0`}
                                    jc={`space-between`}
                                    ai={`center`}
                                    wrap={`wrap`}
                                >   
                                    <Text 
                                        size="1.8rem" 
                                        weight="600" 
                                        lh="24px" 
                                        ls="-0.32px" 
                                        color={colors.Gr05}
                                        style={{
                                            marginBottom : 8
                                        }}
                                        width={`100%`}
                                    >
                                        {Type[activity.type] ? `기간/횟수 (개월/회) :` : `인원 :`}
                                    </Text>

                                    <Wrapper
                                        jc={`flex-start`}
                                        ai={`center`}
                                        width={`100%`}
                                        wrap={`wrap`}
                                    >   
                                        {
                                            Object.keys(activity?.price)?.map((x, idx) => (
                                                <Wrapper
                                                    margin={`0 ${((idx + 1) != Object.keys(activity?.price).length) ? '8px' : '0'} 8px 0`}
                                                    jc={`center`}
                                                    ai={`center`}
                                                    padding={`5px 8px`}
                                                    radius={`8px`}
                                                    border={`2px solid ${(x == people) ? colors.Bl07 : colors.Gr05}`}
                                                    onClick={() => setPeople(x)}
                                                    cursor={`pointer`}
                                                >   
                                                    <Text 
                                                        size="1.4rem" 
                                                        weight="600" 
                                                        lh="24px" 
                                                        ls="-0.32px" 
                                                        color={`${(x == people) ? colors.Bl07 : colors.Gr05}`}
                                                    >
                                                        {`${x}${Type[activity.type] ? Type[activity.type] : "인"} ${activity?.price[x]?.price_text}원`}
                                                    </Text>
                                                </Wrapper>  
                                            ))
                                        }
                                    </Wrapper>      

                                </Wrapper>
                                
                                {
                                    (activity.type == '정기 레슨') ?
                                        <Wrapper
                                            width={`100%`}
                                            margin={`0 0 12px 0`}
                                            jc={`space-between`}
                                            ai={`center`}
                                            wrap={`wrap`}
                                        >   
                                            <Text 
                                                size="1.8rem" 
                                                weight="600" 
                                                lh="24px" 
                                                ls="-0.32px" 
                                                color={colors.Gr05}
                                                style={{
                                                    marginBottom : 8
                                                }}
                                                width={`100%`}
                                            >
                                                {`시간 :`}
                                            </Text>

                                            <Wrapper
                                                jc={`flex-start`}
                                                ai={`center`}
                                                width={`100%`}
                                                wrap={`wrap`}
                                            >   
                                                {
                                                    activity?.time?.map((x, idx) => (
                                                        <Wrapper
                                                            margin={`0 ${((idx + 1) != activity?.time.length) ? '8px' : '0'} 8px 0`}
                                                            jc={`center`}
                                                            ai={`center`}
                                                            padding={`3px 5px`}
                                                            radius={`8px`}
                                                            border={`2px solid ${(x == startTime) ? colors.Bl07 : colors.Gr05}`}
                                                            onClick={() => {
                                                                let Full = activity?.time_full[idx].split(' ~ ')[1];
                                                                setStartTime(x)
                                                                setEndTime(Full)
                                                            }}
                                                            cursor={`pointer`}
                                                        >   
                                                            <Text 
                                                                size="1.4rem" 
                                                                weight="600" 
                                                                lh="24px" 
                                                                ls="-0.32px" 
                                                                color={`${(x == startTime) ? colors.Bl07 : colors.Gr05}`}
                                                            >
                                                                {`${activity?.time_full[idx]}`}
                                                            </Text>
                                                        </Wrapper>  
                                                    ))
                                                }
                                            </Wrapper>

                                        </Wrapper>
                                    : <></>
                                }

                                {
                                    ((activity.type == '자격증') || (activity.type == '단기 레슨') || (activity.type == '장비 대여') || (activity.type == '국내/해외 투어')) ?
                                        <Wrapper
                                            width={`100%`}
                                            margin={`0 0 12px 0`}
                                            jc={`space-between`}
                                            ai={`center`}
                                            wrap={`wrap`}
                                        >   
                                            <Text 
                                                size="1.8rem" 
                                                weight="600" 
                                                lh="24px" 
                                                ls="-0.32px" 
                                                color={colors.Gr05}
                                                style={{
                                                    marginBottom : 8
                                                }}
                                                width={`100%`}
                                            >
                                                {`시간 :`}
                                            </Text>

                                            <Wrapper
                                                jc={`flex-start`}
                                                ai={`center`}
                                                width={`100%`}
                                                wrap={`wrap`}
                                            >   
                                                {
                                                    activity?.time?.map((x, idx) => (
                                                        <Wrapper
                                                            margin={`0 ${((idx + 1) != activity?.time.length) ? '8px' : '0'} 8px 0`}
                                                            jc={`center`}
                                                            ai={`center`}
                                                            padding={`3px 5px`}
                                                            radius={`8px`}
                                                            border={`2px solid ${(x == startTime) ? colors.Bl07 : colors.Gr05}`}
                                                            onClick={() => {
                                                                let Full = activity?.time_full[idx].split(' ~ ')[1];
                                                                setStartTime(x)
                                                                setEndTime(Full)
                                                            }}
                                                            cursor={`pointer`}
                                                        >   
                                                            <Text 
                                                                size="1.4rem" 
                                                                weight="600" 
                                                                lh="24px" 
                                                                ls="-0.32px" 
                                                                color={`${(x == startTime) ? colors.Bl07 : colors.Gr05}`}
                                                            >
                                                                {`${activity?.time[idx]}`}
                                                            </Text>
                                                        </Wrapper>  
                                                    ))
                                                }
                                            </Wrapper>

                                        </Wrapper>
                                    : <></>
                                }
                            </>
                        :
                            <Wrapper
                                width={`100%`}
                                margin={`0 0 12px 0`}
                                jc={`space-between`}
                                ai={`center`}
                                wrap={`wrap`}
                            >   
                                <Text 
                                    size="1.8rem" 
                                    weight="600" 
                                    lh="24px" 
                                    ls="-0.32px" 
                                    color={colors.Gr05}
                                    style={{
                                        marginBottom : 8
                                    }}
                                    width={`100%`}
                                >
                                    {`예약할 액티비티를 선택해주세요!`}
                                </Text>
                            </Wrapper>
                    }

                    {
                        (activity && people && startTime && endTime) ? 
                            Type[activity.type] 
                            ?
                                <Wrapper
                                    width={`100%`}
                                    margin={`0 0 12px 0`}
                                    jc={`space-between`}
                                    ai={`center`}
                                    wrap={`wrap`}
                                >   
                                    <Text 
                                        size="1.8rem" 
                                        weight="600" 
                                        lh="24px" 
                                        ls="-0.32px" 
                                        color={colors.Gr05}
                                        style={{
                                            marginBottom : 12
                                        }}
                                        width={`100%`}
                                    >
                                        {`유효 기간 : `}
                                    </Text>

                                    <Text 
                                        size="1.8rem" 
                                        weight="600" 
                                        lh="24px" 
                                        ls="-0.32px" 
                                        color={colors.Gr05}
                                        style={{
                                            marginBottom : 12,
                                            justifyContent : "flex-end"
                                        }}
                                        width={`100%`}
                                        ta={`right`}
                                    >
                                        {`${selectDay.year}.${selectDay.month}.${selectDay.day} ~ ${deadline.year}.${deadline.month}.${deadline.day}`}
                                    </Text>
                                </Wrapper>
                            :
                                <Wrapper
                                    width={`100%`}
                                    margin={`0 0 12px 0`}
                                    jc={`space-between`}
                                    ai={`center`}
                                    wrap={`wrap`}
                                >   
                                    <Text 
                                        size="1.8rem" 
                                        weight="600" 
                                        lh="24px" 
                                        ls="-0.32px" 
                                        color={colors.Gr05}
                                        style={{
                                            marginBottom : 12
                                        }}
                                        width={`100%`}
                                    >
                                        {`예약 일정 : `}
                                    </Text>

                                    <Text 
                                        size="1.8rem" 
                                        weight="600" 
                                        lh="24px" 
                                        ls="-0.32px" 
                                        color={colors.Gr05}
                                        style={{
                                            marginBottom : 12,
                                            justifyContent : "flex-end"
                                        }}
                                        width={`100%`}
                                        ta={`right`}
                                    >
                                        {`${selectDay.year}.${selectDay.month}.${selectDay.day} ${startTime} - ${people}인`}
                                    </Text>
                                </Wrapper>
                        : 
                            <Wrapper
                                width={`100%`}
                                margin={`0 0 8px 0`}
                                jc={`space-between`}
                                ai={`center`}
                                wrap={`wrap`}
                            >   
                                <Text 
                                    size="1.8rem" 
                                    weight="600" 
                                    lh="24px" 
                                    ls="-0.32px" 
                                    color={colors.Gr05}
                                    style={{
                                        marginBottom : 12
                                    }}
                                    width={`100%`}
                                >
                                    {`아직 선택하지 않은 정보가 있습니다.`}
                                </Text>
                            </Wrapper>
                    }


                    <Wrapper
                        onClick={() => {
                            if(ValidCheck()) {
                                ChangeTicket();
                            }
                        }}
                        jc="center"
                        ai="center"
                        width="calc(100%)"
                        height="52px"
                        cursor={"pointer"}
                        radius="8px"
                        bgc={ValidCheck() ? colors.Bl07 : colors.Gr02}
                        style={{
                            /*position : "absolute",
                            bottom : '10px',
                            left : '50%',
                            transform : 'translate(-50%, 0)'*/
                        }}
                    >
                        <Text 
                            ta="center" 
                            size="2rem" 
                            weight="600" 
                            lh="22px" 
                            ls="-0.28px" 
                            color={colors.White}
                        >
                            {`예약 생성`}
                        </Text>
                    </Wrapper>

                </AffiliateModalLayout>
            </Container>

        </Modal>
    );
};

export default CrewTicketModal;

const customStyles = {
    content: {
        width: "calc(100% - 24px)",
        maxWidth : "600px",
        maxHeight: 'calc(100vh - 150px)',
        minHeight: '300px',
        marginRight: "-50%",
        padding: 0,
        borderRadius: "16px",
        top: "50%",
        left: "50%",
        right: "auto",
        bottom: "auto",
        transform: "translate(-50%, -50%)",
        overflow : 'hidden'
    },
};

const Container = styled.div`
    display: inline-block;
    flex-direction: column;
    align-items: center;
    max-height: calc(100vh - 150px);
    overflow: hidden auto;
    position : relative;
`;

const AffiliateModalLayout = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    max-width: 600px;
    padding: 12px 10px;
    border-radius: 0;
    // padding-bottom : 70px;
`;

const DropDown = styled.div`
    width: 100%;
    padding: 3px 5px;
    border-bottom: ${({ last }) => !last && `1px solid ${colors.Gr02}`};
    color: ${({ active }) => active ? colors.Bl07 : colors.Gr04 };
    font-size: 1.4rem;
    font-weight: ${({ active }) => active ? 600 : 500};
    line-height: 142%;
    letter-spacing: -0.26px;
    text-align : center;
    cursor : pointer;
`;
