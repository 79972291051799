import React, { useState } from "react";
import styled from "styled-components";
import Modal from "react-modal";
import axios from "axios";

//img
import { W } from "../../styles/globalStyles";
import colors from "../../styles/colors";

// tool
import { GetOne } from "../../tool/localstorage";

const BasicModal = ({ modal, setModal, RI, RU }) => {

    const [feedback, setFeedback] = useState('');

    const PopupFunction = async () => {
        const token = GetOne("token");

            if(feedback.length > 9) {
                const frm = new FormData();
                frm.append('token', token);
                frm.append('mode', 'review_feedback');
                frm.append('review_id', RI);
                frm.append('feedback', feedback);
    
                axios
                    .post('https://ai.semos.kr/semos_partner/v3/profile/Review', frm, {
                    headers: {'Content-Type': 'multipart/form-data'},
                    })
                    .then(data => {
                        const Value = data.data;
                        const StatusMessage = Value.status_message
        
                    if (Value.status === 'success') {
                        window.alert(StatusMessage)
                        setModal(false)
                        window.location.reload();
                    } else {
                        window.alert(StatusMessage)
                        setModal(false)
                        window.location.reload();
                    }
                    })
                    .catch(err => console.log('PROFILE ERROR', err));
            } else {
                window.alert('최소 10글자의 피드백을 적어주세요!')
            } 
    };

    return (
        <Modal
            isOpen={modal}
            onRequestClose={() => setModal(false)}
            contentLabel="Alert Modal"
            style={customStyles}
        >

            <Container>
                <Title>{`답글을 달아주세요!`}</Title>
                <Space h={24} />

                <FeedbackUser>{RU}님</FeedbackUser>

                <Feedback 
                    placeholder={`액티비티에 대한 피드백을 적어주세요!\n*최소 10글자`}
                    onChange={(e) => setFeedback(e.target.value)}    
                    value={feedback}
                />

                <Space h={24} />

                <ButtonContainer>
                    <Button c={colors.Bl07} bg={colors.Bl02} onClick={() => setModal(false)}>
                    취소
                    </Button>
                    <Button
                        c={colors.White}
                        bg={colors.Bl07}
                        onClick={() => {
                            PopupFunction();
                        }}
                    >
                    입력하기
                    </Button>
                </ButtonContainer>
            </Container>
        </Modal>
    );
};

export default BasicModal;

const customStyles = {
  content: {
    width: `${W - 48}px`,
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    borderRadius: "16px",
    padding: "24px",
    // zIndex: 20,
  },
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  /* justify-content: space-between; */
  align-items: center;
`;

const Icon = styled.img`
  width: 76px;
  height: 76px;
`;

const Title = styled.div`
  font-weight: 700;
  font-size: 18px;
  line-height: 142%;
  text-align: center;
  letter-spacing: -0.02em;
  color: ${colors.Gr05};
`;


const Space = styled.div`
  height: ${({ h }) => h}px;
`;

const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 12px;
  width: 100%;
`;

const Button = styled.div`
  cursor: pointer;
  display: flex;
  justify-content: center;
  width: ${({ width }) => (width ? width : "100%")};
  padding: 14px 0;
  background-color: ${({ bg }) => bg};
  border-radius: 8px;
  color: ${({ c }) => c};
  line-height: 142%;
  font-size: 14px;
  font-weight: 700;
`;

const Feedback = styled.textarea`
    width : 100%;
    font-size: 14px;
    font-weight: 600;
    color : ${colors.Gr06};
    display : inline-flex;
    padding : 10px;
    border : 1px solid ${colors.Gr03};
    border-radius: 8px;
    min-height: 200px;
`;

const FeedbackUser = styled.div`
    width : 100%;
    font-size: 16px;
    font-weight: 600;
    color : ${colors.Gr06};
    display : inline-flex;
    margin-bottom: 10px;
`;