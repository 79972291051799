import React from "react";
import styled from "styled-components";

//img
import EscrowStep1 from "../../icon/escrow_1.png";
import EscrowStep2 from "../../icon/escrow_2.png";
import EscrowStep3 from "../../icon/escrow_3.png";
import EscrowStep4 from "../../icon/escrow_after_activity_4.png";
import { PC, WIDTH } from "../../styles/globalStyles";
import colors from "../../styles/colors";

const W = window.document.documentElement.clientWidth > PC ? WIDTH : window.document.documentElement.clientWidth;
const Gap = ((W - 37) / 4 - 48) / 2;

function EscrowAfterActivity() {
  return (
    <EscrowContainer>
      <EscrowItem past={true}>
        <EscrowImg src={EscrowStep1} />
        예약 완료
        {/* 결제 완료 */}
      </EscrowItem>
      <EscrowItem past={true}>
        <EscrowImg src={EscrowStep2} />
        예약 확정
      </EscrowItem>
      <EscrowItem past={true}>
        <EscrowImg src={EscrowStep3} />
        이용 완료
      </EscrowItem>
      <EscrowItem last={true} now={true}>
        <EscrowImg src={EscrowStep4} />
        정산 반영
      </EscrowItem>
      <DotView>
        <DotContainer>
          <Dot />
          <Dot />
          <Dot />
        </DotContainer>
        <DotContainer>
          <Dot />
          <Dot />
          <Dot />
        </DotContainer>
        <DotContainer>
          <Dot />
          <Dot />
          <Dot />
        </DotContainer>
      </DotView>
    </EscrowContainer>
  );
}

export default EscrowAfterActivity;

const EscrowContainer = styled.div`
  display: flex;
  justify-content: space-between;
  position: relative;
  align-items: flex-start;
`;

const EscrowItem = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: ${({ now, past }) => (past ? colors.Gr02 : now ? colors.Bl06 : colors.Gr03)};
  font-weight: 500;
  font-size: 12px;
  letter-spacing: -0.02em;
`;

const EscrowImg = styled.img`
  width: 48px;
  height: 48px;
  margin-bottom: 12px;
`;

const DotView = styled.div`
  position: absolute;
  top: 22px;
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 0 ${Gap + 48}px;
`;

const DotContainer = styled.div`
  width: calc((100% - 96px) / 3);
  display: flex;
  justify-content: space-around;
`;

const Dot = styled.div`
  width: 4px;
  height: 4px;
  border-radius: 50%;
  background-color: ${colors.Gr02};
`;
