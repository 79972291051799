import React, { useState } from "react";
import styled from "styled-components";
import Switch from "react-switch";
import { useNavigate } from "react-router-dom";

// img
import ArrowIcon from "../../icon/semosActivityArrow.png";
import LocationIcon from "../../icon/locationIcon.png";
import ClockIcon from "../../icon/clockIcon.png";
import colors from "../../styles/colors";

function SemosScheduleBox({ data, close, index }) {
  const navigate = useNavigate();
  const active = data.status;

  return (
    <Box active={active}>
      <FirstLine active={active}>
        <div style={{ cursor: "pointer" }} onClick={() => navigate(`/semos_schedule_revise/${data.id}`)}>
          {data.title}
        </div>
        <Switch
          uncheckedIcon={false}
          checkedIcon={false}
          height={24}
          width={48}
          offColor={colors.Gr02}
          onColor={colors.Bl07}
          checked={active}
          onChange={(value) => close(value, data.id, index)}
          handleDiameter={18}
        />
      </FirstLine>
      <SecondLine onClick={() => navigate(`/semos_schedule_revise/${data.id}`)}>
        <InfoBox>
          <Info>
            <SmallIcon src={LocationIcon} active={active} />
            <SmallText color={active ? colors.Gr05 : colors.Gr04}>{data.address}</SmallText>
          </Info>
          <div style={{ height: "4px" }} />
          <Info>
            <SmallIcon src={ClockIcon} active={active} />
            <SmallText color={active ? colors.Gr05 : colors.Gr04}>{data.yoil}</SmallText>
            <SmallText color={active ? colors.Red03 : colors.Gr04}>{data.holiday ? "공휴일 포함" : "공휴일 제외"}</SmallText>
            <SmallText color={active ? colors.Bl07 : colors.Gr04} h={false}>
              {`${data.start_time} ~ ${data.finish_time}`}
            </SmallText>
          </Info>
        </InfoBox>
        <Arrow src={ArrowIcon} />
      </SecondLine>
    </Box>
  );
}

export default SemosScheduleBox;

const Box = styled.div`
  padding: 16px 18px;
  border: 1px solid ${colors.Gr02};
  border-radius: 8px;
  margin: 0 24px;
  margin-top: 12px;
  background-color: ${({ active }) => (active ? "transparent" : colors.Gr01)};
`;

const FirstLine = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: 600;
  font-size: 14px;
  line-height: 142%;
  display: flex;
  align-items: center;
  color: ${colors.Gr06};
  margin-bottom: 4px;
  color: ${({ active }) => (active ? colors.Gr05 : colors.Gr04)};
`;

const SecondLine = styled.div`
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const InfoBox = styled.div``;

const Info = styled.div`
  display: flex;
  align-items: center;
`;

const Arrow = styled.img`
  cursor: pointer;
  width: 22px;
  height: 22px;
`;

const SmallIcon = styled.img`
  width: 16px;
  height: 16px;
  margin-right: 4px;
  opacity: ${({ active }) => (active ? 1 : 0.5)};
`;

const SmallText = styled.div`
  font-weight: 500;
  font-size: 12px;
  line-height: ${({ h }) => h && "142%"};
  display: flex;
  justify-content: center;
  align-items: center;
  letter-spacing: -0.02em;
  color: ${({ color }) => color};
  margin-right: 4px;
`;
