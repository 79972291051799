import styled from "styled-components";

import colors from "../styles/colors";

export const LoadingSpinner = ({ loading, title, content }) => {
  return (
    <LoadingPayment isLoading={loading}>
      <div className="content">

        <div className={`dot_container`}>

        </div>

        <div className="text_view">{title}</div>
        <div className="text_view">{content}</div>
      </div>
    </LoadingPayment>
  );
};

const LoadingPayment = styled.div`
  display: ${({ isLoading }) => (isLoading ? "inline-flex" : "none")};
  align-items: center;
  justify-content: center;
  width: 100%;
  /* height: 100vh; */
  height: calc(var(--vh, 1vh) * 100);
  background-color: ${colors.B60};
  position: fixed;
  z-index: 999;
  top: 0;
  right: 0;

  @keyframes spinner {
    from {transform: rotate(0deg); }
    to {transform: rotate(360deg);}
  }

  .dot_container {
    width: 7rem;
    height : 7rem;
    border-radius: 50%;
    border : .5rem solid ${colors.Bl07};
    margin: 10px 0;
    position: relative;
    border-right-color: transparent;
    margin-bottom: 20px;
    animation: spinner .8s ease infinite;
  }

  .content {
    width: 100%;
    display: inline-flex;
    flex-wrap: wrap;
    justify-content: center;
    z-index: 1000;
  }

  .sehorang {
    display: inline-block;
    width: 123px;
  }

  .text_view {
    display: inline-flex;
    flex-wrap: wrap;
    width: 100%;
    font-weight: 400;
    line-height: 22.72px;
    font-size: 16px;
    color: ${colors.Bl03};
    justify-content: center;
  }
`;
