import React from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

import { W } from "../../styles/globalStyles";
import colors from "../../styles/colors";

import { ReactComponent as People } from "../../icon/people.svg";
import { ReactComponent as PG } from "../../resource/icon/moneysign_pg.svg";
import { ReactComponent as Onsite } from "../../resource/icon/moneysign_onSite.svg";
import { GetOne } from "../../tool/localstorage";
import axios from "axios";

const PAYMENT_TYPE = {
  바로결제: {
    type: "바로결제",
    typeColor: colors.Bl07,
    icon: "PG",
    content: [
      {
        subTitle: "앱 내 바로 결제 (기존의 결제 방식)",
        subContent: "세모스 플랫폼에서 결제하는 방식이에요. 세모스의 정산 정책에 따라 정산일에 바로결제 금액을 정산받을 수 있어요.",
      },
    ],
  },
  현장결제: {
    type: "현장결제",
    typeColor: colors.Green03,
    icon: "Onsite",
    content: [
      {
        subTitle: "수수료 및 예약금 없음",
        subContent: "서비스 이용 수수료를 받지 않는 결제 방식이나, 크루님께 받는 예약금이 없으니 예약 확정시 주의해주세요.",
      },
      { subTitle: "예약 후 개별 안내", subContent: "액티비티 진행 전 파트너님이 직접 결제방법에 대해 안내해주셔야 해요." },
    ],
  },
};

const PaymentModal = ({ paymentModal, setPaymentModal, paymentAlertActivity, paymentAlertTotal }) => {
  const navigate = useNavigate();

  const goToActivityManage = () => navigate("/reservation_manage");

  const activity = (type) => {
    const token = GetOne("token");
    const frm = new FormData();
    const paymentId = paymentAlertActivity.payment_id;

    frm.append("mode", "sold_status");
    frm.append("number", paymentId);
    frm.append("type", type);
    frm.append("token", token);

    axios
      .post("https://ai.semos.kr/semos_partner/auth/profile", frm, {
        headers: { "Content-Type": "multipart/form-data" },
      })
      .then((res) => {
        const data = res.data;
        const status = data.status;
        const statusMessage = data.status_message;

        if (status === "success") {
          if (type === "confirm") {
          }
          navigate(`/reservation_detail/${paymentId}`, {
            state: {
              from: "paymentModal",
            },
          });
          // window.location.reload();
        } else {
          alert(statusMessage);
        }
      });
  };

  return (
    <ModalContainer width={W} modal={paymentModal} setModal={setPaymentModal}>
      <PaymentTitle>
        {/* <Pay fill={colors.Bl07} /> */}
        <TypeWrap>
          {paymentAlertActivity.payment_type === "바로결제" ? (
            <PG width="16px" height="16px" fill={PAYMENT_TYPE[paymentAlertActivity.payment_type].typeColor} />
          ) : (
            <Onsite width="16px" height="16px" fill={PAYMENT_TYPE[paymentAlertActivity.payment_type].typeColor} />
          )}
          <TypeText color={PAYMENT_TYPE[paymentAlertActivity.payment_type].typeColor}>{paymentAlertActivity.payment_type}</TypeText>
        </TypeWrap>
        <h3>결제 {paymentAlertTotal === "1" ? "" : `${paymentAlertTotal}건`} 발생! 예약을 확정해주세요</h3>
      </PaymentTitle>
      <PaymentContent onClick={() => goToActivityManage()}>
        <PaymentImg>
          <img src={paymentAlertActivity.profile} />
        </PaymentImg>
        <PaymentInfo width={W}>
          <h4>{paymentAlertActivity.title}</h4>
          <PaymentSubInfo>
            <PaymentPeople>
              <People fill={colors.Gr04} />
              <span>{paymentAlertActivity.people}인</span>
            </PaymentPeople>
            <PaymentSchedule>{paymentAlertActivity.schedule}</PaymentSchedule>
          </PaymentSubInfo>
        </PaymentInfo>
      </PaymentContent>
      <PaymentButtonContainer>
        {Number(paymentAlertTotal) > 1 ? (
          <PaymentButton onClick={() => goToActivityManage()} bg={colors.Bl07} color={colors.White}>
            더보기
          </PaymentButton>
        ) : (
          <>
            <PaymentButton
              onClick={() => navigate(`/reservation_cancel_detail/${paymentAlertActivity.payment_id}`)}
              isCancel={true}
              bg={colors.Gr01}
              color={colors.Gr05}
            >
              예약 거절하기
            </PaymentButton>
            <PaymentButton onClick={() => activity("confirm")} bg={colors.Bl07} color={colors.White}>
              예약 확정하기
            </PaymentButton>
          </>
        )}
      </PaymentButtonContainer>
    </ModalContainer>
  );
};

export default PaymentModal;

const ModalContainer = styled.div`
  width: ${({ width }) => `calc(${width}px - 48px)`};
  background-color: ${colors.White};
  padding: 24px 16px 16px 16px;
  border-radius: 12px;
  box-shadow: 0px 1px 7px 0px ${colors.B10}, 0px 7px 23px 0px ${colors.B10};
  position: fixed;
  bottom: 16px;
  left: 50%;
  transform: translateX(-50%);
`;

const PaymentTitle = styled.div`
  display: flex;
  /* align-items: center;
  justify-content: flex-start; */
  gap: 4px;

  flex-direction: column;
  align-items: flex-start;
  h3 {
    color: ${colors.Gr06};
    line-height: 26px;
    letter-spacing: -0.36px;
    font-size: 18px;
    font-weight: 700;
  }
`;

const PaymentContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
  margin: 12px 0 24px 0;

  :hover {
    cursor: pointer;
  }
`;

const PaymentImg = styled.div`
  width: 56px;
  height: 56px;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

const PaymentInfo = styled.div`
  flex: 1;

  h4 {
    width: ${({ width }) => `calc(${width}px - 48px - 32px - 56px - 8px)`};
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    line-height: 22px;
    letter-spacing: -0.28px;
    font-size: 14px;
    font-weight: 500;
  }
`;

const PaymentSubInfo = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 4px;
`;

const PaymentPeople = styled.span`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 4px;

  svg {
    width: 20px;
    height: 20px;
  }

  span {
    color: ${colors.Gr04};
    line-height: 20px;
    letter-spacing: -0.24px;
    font-size: 12px;
    font-weight: 500;
  }
`;

const PaymentSchedule = styled.span`
  color: ${colors.Bl07};
  line-height: 22px;
  letter-spacing: -0.28px;
  font-size: 14px;
  font-weight: 500;
`;

const PaymentButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 12px;
`;

const PaymentButton = styled.button`
  flex: 1;
  padding: 16px;
  background-color: ${({ bg }) => bg};
  border-radius: 8px;
  color: ${({ color }) => color};
  line-height: 24px;
  letter-spacing: -0.32px;
  font-size: 16px;
  font-weight: 600;
`;

const TypeWrap = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;
`;

const TypeText = styled.span`
  word-break: keep-all;
  font-size: 12px;
  font-weight: 600;
  line-height: 20px; /* 166.667% */
  letter-spacing: -0.24px;
  color: ${({ color }) => (color ? color : `${colors.Gr05}`)};
`;
