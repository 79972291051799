import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import { useParams, useNavigate } from "react-router-dom";
import axios from "axios";
import io from "socket.io-client";

// components
import { GetOne } from "../tool/localstorage";
import { LoadingSpinner } from "../tool/loading";

// icon
import payCapacityGrey from "../icon/payCapacityGrey.png";
import payLocationGrey from "../icon/payLocationGrey.png";
import payScheduleGrey from "../icon/payScheduleGrey.png";
import payTypeGrey from "../icon/payTypeGrey.png";
import chatIcon from "../icon/chatIcon.png";
import arrowBlackBottom from "../icon/arrowBlackBottom.png";
import BackArrow from "../icon/backArrow.png";
import { PC } from "../styles/globalStyles";



function ActivityManage() {

  const Socket = useRef();

  const navigate = useNavigate();
  const Params = useParams();

  const [loading, setLoading] = useState(true);

  const ChatId = Params.n;

  const [chatList, setChatList] = useState();
  const [defaultInfo, setDefaultInfo] = useState();
  const [chatHeight, setChatHeight] = useState("58px");
  const [chatText, setChatText] = useState("");
  const [infoPopup, setInfoPopup] = useState(false);

  const DateMake = () => {
    let Now = new Date();
    let NowYear = Now.getFullYear();
    let NowMonth = ("0" + (Now.getMonth() + 1)).slice(-2);
    let NowDay = ("0" + Now.getDate()).slice(-2);
    let NowHour = ("0" + Now.getHours()).slice(-2);
    let NowMinute = ("0" + Now.getMinutes()).slice(-2);
    let NowSecond = ("0" + Now.getSeconds()).slice(-2);

    let NowYMDKR = `${NowYear}년 ${NowMonth}월 ${NowDay}일`;
    let NowOjeon = true;

    if (Number(NowHour) > 12) {
      NowHour = Number(NowHour) - 12;

      if (NowHour < 10) {
        NowHour = `0${NowHour}`;
      }
      NowOjeon = false;
    } else {
      NowHour = Number(NowHour);

      if (NowHour < 10) {
        NowHour = `0${NowHour}`;
      }
      NowOjeon = true;
    }

    return {
      date: NowYMDKR,
      time: `${NowHour}:${NowMinute}`,
      ojeon: NowOjeon,
    };
  };

  const SetMessage = (data, ChatList, TargetPartner) => {
    let massage = data;

    if (massage.check) {
      let MessageDate = DateMake();
      let MessageDateYMD = MessageDate.date;
      let MessageDateTime = MessageDate.time;
      let MessageDateOjeon = MessageDate.ojeon;

      let MessageData = massage.data;
      let MessageChat = MessageData.message;
      let MessageTo = MessageData.to;
      let MessageFrom = MessageData.from;
      let MessageMyChat = MessageData.from == TargetPartner;

      let ChatListYMD = ChatList && ChatList[MessageDateYMD];

      if (ChatListYMD) {
        ChatListYMD[ChatListYMD.length] = {
          date: MessageDateYMD,
          to: MessageTo,
          from: MessageFrom,
          message: MessageChat,
          my_chat: MessageMyChat,
          number: "new",
          ojeon: MessageDateOjeon,
          time: MessageDateTime,
        };

        setChatList((prev) => ({ ...prev, [MessageDateYMD]: ChatListYMD }));

        setTimeout(() => {
          var ChatContainer = document.getElementById("chatContainer");
          //ChatContainer.scrollTop = ChatContainer.scrollHeight;
          ChatContainer.scrollTo({
            top: ChatContainer.scrollHeight,
            behavior: "smooth",
          });
        }, 500);
      } else {
        ChatList[MessageDateYMD] = [
          {
            date: MessageDateYMD,
            to: MessageTo,
            from: MessageFrom,
            message: MessageChat,
            my_chat: MessageMyChat,
            number: "new",
            ojeon: MessageDateOjeon,
            time: MessageDateTime,
          },
        ];

        setChatList(ChatList);

        var token = GetOne("token");

        const frm = new FormData();

        frm.append("mode", "main");
        frm.append("token", token);
        frm.append("chat_number", ChatId);

        axios
          .post("https://ai.semos.kr/semos_partner/v2/profile/Chat", frm, {
            headers: { "Content-Type": "multipart/form-data" },
          })
          .then((data) => {
            var Value = data.data;
            var Status = Value.status;
            var StatusMessage = Value.status_message;
            var ChatList = Value.chat_list;
            var defaultInfo = Value.info;

            if (Status == "success") {
              setDefaultInfo(defaultInfo);
              setChatList(ChatList);

              setTimeout(() => {
                var ChatContainer = document.getElementById("chatContainer");
                ChatContainer.scrollTo({
                  top: ChatContainer.scrollHeight,
                  behavior: "smooth",
                });
              }, 500);
              setLoading(false);
            } else {
              window.alert(StatusMessage);
              navigate("/chat_list");
              setLoading(false);
            }
          });

        setTimeout(() => {
          var ChatContainer = document.getElementById("chatContainer");
          ChatContainer.scrollTo({
            top: ChatContainer.scrollHeight,
            behavior: "smooth",
          });
        }, 300);

      }
    }
  };       

  useEffect(() => {
    Socket.current = io.connect('https://dt.semos.kr/chat', {
      cors: {origin: '*'},
      transports: ['websocket']
    });

    console.log(Socket.current.connect())

    Socket.current.emit("join-chat", { roomName: ChatId });
    Socket.current.on("log", (massage) => {});

    return () => {
      Socket.current.emit("leave-chat", { roomName: ChatId });
      Socket.current.off("log", (massage) => {});
      Socket.current.disconnect();
    };
  }, [])

  useEffect(() => {
    
    var token = GetOne("token");

    const frm = new FormData();

    frm.append("mode", "main");
    frm.append("token", token);
    frm.append("chat_number", ChatId);

    axios
      .post("https://ai.semos.kr/semos_partner/v2/profile/Chat", frm, {
        headers: { "Content-Type": "multipart/form-data" },
      })
      .then((data) => {
        var Value = data.data;
        var Status = Value.status;
        var StatusMessage = Value.status_message;
        var ChatList = Value.chat_list;
        var defaultInfo = Value.info;

        if (Status == "success") {
          setDefaultInfo(defaultInfo);
          setChatList(ChatList);
 
          setTimeout(() => {
            var ChatContainer = document.getElementById("chatContainer");

            ChatContainer.scrollTo({
              top: ChatContainer.scrollHeight,
              behavior: "smooth",
            });
          }, 500);
          setLoading(false);
        } else {
          window.alert(StatusMessage);
          navigate("/chat_list");
          setLoading(false);
        }
      });

  }, []);

  useEffect(() => {
    if(Socket.current.connected) {
      let ChatList = chatList && chatList;
      let TargetPartner = defaultInfo && defaultInfo.target_partner;
  
      Socket.current.removeAllListeners("message");
  
      Socket.current.removeAllListeners("check");
  
      if (ChatList && TargetPartner) {
        Socket.current.on("message", (massage) => {
          SetMessage(massage, ChatList, TargetPartner);
        });
  
        Socket.current.on("check", (massage) => {
          SetMessage(massage, ChatList, TargetPartner);
        });
      }
    }
  }, [chatList, defaultInfo]);

  const PushNotice = () => {
    var token = GetOne("token");

    const frm = new FormData();

    frm.append("mode", "chat_push");
    frm.append("token", token);
    frm.append("target", defaultInfo.target_user);
    frm.append("content", chatText);
    frm.append("chat_id", ChatId);

    axios
      .post("https://ai.semos.kr/semos_partner/coaching/chat", frm, {
        headers: { "Content-Type": "multipart/form-data" },
      })
      .then((data) => {
        var Value = data.data;
        var Status = Value.status;

        if (Status == "success") {
        } else {
        }
      });
  };

  const GoChat = () => {
    if (chatText.trim().length > 0) {
      var token = GetOne("token");

      setChatText("");
      const MessageData = {
        roomName: ChatId,
        from: defaultInfo.target_partner,
        to: defaultInfo.target_user,
        message: chatText,
        platform: "partner",
        category: "payment",
        token: token,
      };

      Socket.current.emit("message", MessageData);

      PushNotice();
    } else {
      alert("내용을 입력해 주세요.");
    }
  };

  const GoChatEnter = (key) => {
    if (key === 13 && chatText.length) {
      var token = GetOne("token");

      setChatText("");

      const MessageData = {
        roomName: ChatId,
        from: defaultInfo.target_partner,
        to: defaultInfo.target_user,
        message: chatText,
        platform: "partner",
        category: "payment",
        token: token,
      };

      Socket.current.emit("message", MessageData);
    }
  };

  const StartChat = () => {
    setChatHeight("150px");
    setTimeout(() => {
      var ChatContainer = document.getElementById("chatContainer");
      ChatContainer.scrollTo({
        top: ChatContainer.scrollHeight,
        behavior: "smooth",
      });
    }, 300);
  };

  const StopChat = () => {
    setChatHeight("58px");
    setTimeout(() => {
      var ChatContainer = document.getElementById("chatContainer");
      ChatContainer.scrollTo({
        top: ChatContainer.scrollHeight,
        behavior: "smooth",
      });
    }, 300);
  };

  const ChatMake = (data, index, date) => {
    if (defaultInfo) {
      var ChatList = [];

      for (var i = 0; i < data.length; i++) {
        const chatNumber = i;
        const ChatDate = data[chatNumber].date;
        const ChatFrom = data[chatNumber].from;
        const ChatTo = data[chatNumber].to;
        const ChatMy = data[chatNumber].my_chat;
        const ChatOjeon = data[chatNumber].ojeon;
        const ChatTime = data[chatNumber].time;
        const ChatMessage = data[chatNumber].message.split("\n");

        var MessageContent = [];

        for (let i2 = 0; i2 < ChatMessage.length; i2++) {
          MessageContent.push(
            <TextView
              key={`${index}_${i}_${i2}_text`}
              color={`#3C3E41`}
              width={`100%`}
              fw={`500`}
              fs={`14px`}
              lh={`142%`}
              style={ChatMessage[i2] === "" ? { height: "1.42em" } : null}
            >
              {ChatMessage[i2]}
            </TextView>
          );
          // if (ChatMessage[i2]) {
          //   MessageContent.push(
          //     <TextView
          //       key={`${index}_${i}_${i2}_text`}
          //       color={`#3C3E41`}
          //       width={`100%`}
          //       fw={`500`}
          //       fs={`14px`}
          //       lh={`142%`}
          //     >
          //       {ChatMessage[i2]}
          //     </TextView>
          //   );
          // }
        }

        if (chatNumber === 0) {
          ChatList.push(
            <BackgroundContent key={`${index}_${i}_title`} style={{ marginBottom: "24px" }} padding={`0 24px`} jc={`center`} ai={`center`}>
              <TextContent style={{ position: "relative", zIndex: 10 }} br={`16`} bgc={`#F5F5F5`} padding={`4px 23px`}>
                <TextView ws={`nowrap`} color={`#ACAFB5`} fw={`500`} fs={`12px`} lh={`142%`}>
                  {ChatDate}
                </TextView>
              </TextContent>
              <GreyLineAbsol />
            </BackgroundContent>
          );
        }

        if (ChatMy) {
          ChatList.push(
            <BackgroundContent
              key={`${index}_${i}_message`}
              style={{ marginBottom: "24px" }}
              className="MyChat"
              padding={`0 24px`}
              jc={`space-between`}
              ai={`flex-top`}
            >
              <TextContent width={`70px`} style={{ marginTop: "8px" }}>
                <TextView color={`#818792`} fw={`500`} fs={`12px`} lh={`142%`}>{`${ChatOjeon ? "오전" : "오후"} ${ChatTime}`}</TextView>
              </TextContent>

              <TextContent style={{ borderTopRightRadius: "0" }} br={`14`} wrap={`wrap`} padding={`8px 16px`} bgc={`#F2F7FF`} width={`calc(100% - 70px)`}>
                {MessageContent}
              </TextContent>
            </BackgroundContent>
          );
        } else {
          ChatList.push(
            <BackgroundContent
              key={`${index}_${i}_message`}
              style={{ marginBottom: "24px" }}
              className="YourChat"
              padding={`0 24px`}
              jc={`space-between`}
              ai={`flex-top`}
            >
              <TextContent
                border={`1px solid #DCDCDC`}
                style={{ borderTopLeftRadius: "0" }}
                br={`14`}
                wrap={`wrap`}
                padding={`8px 16px`}
                bgc={`#FFFFFF`}
                width={`calc(100% - 70px)`}
              >
                {MessageContent}
              </TextContent>

              <TextContent style={{ justifyContent: "flex-end", marginTop: "12px" }} width={`70px`}>
                <TextView color={`#818792`} fw={`500`} fs={`12px`} lh={`142%`}>{`${ChatOjeon ? "오전" : "오후"} ${ChatTime}`}</TextView>
              </TextContent>
            </BackgroundContent>
          );
        }
      }

      return ChatList;
    }
  };

  return (
    <Container>
      <HeaderContainer infoPopup={infoPopup}>
        <Arrow src={BackArrow} onClick={() => navigate(-1)} />
        <HeaderTitle>{defaultInfo && `${defaultInfo.user_name}`}</HeaderTitle>
        <Box />
      </HeaderContainer>

      <InfoPopup>
        <ContentContainer
          display={infoPopup ? `500px` : `0px`}
          style={{
            boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.1)",
            // boxShadow: infoPopup ? "none" : "0px 2px 8px rgba(0, 0, 0, 0.1)",
          }}
        >
          <Divider height={"5"} color={"#ffffff"} />

          <BackgroundContent border={`1px solid rgba(204, 224, 255, 1)`} bgc={`#F2F7FF`} padding={`5px`} br={`8`} jc={`center`} ai={`center`}>
            <TextView color={`rgba(128, 178, 255, 1)`} fw={`500`} fs={`12px`}>
              {defaultInfo && defaultInfo.title}
            </TextView>
          </BackgroundContent>

          <Divider height={"12"} color={"#ffffff"} />

          <BackgroundContent wrap={"wrap"} border={`1px solid rgba(220, 220, 220, 1)`} bgc={`#ffffff`} padding={`20px`} br={`8`} jc={`center`} ai={`center`}>
            <BackgroundContent jc={`space-between`} ai={`center`}>
              <TextContent width={`100px`}>
                <ImageIcon100 style={{ marginRight: `4px` }} width={`16px`} height={`16px`}>
                  <img src={payLocationGrey} />
                </ImageIcon100>

                <TextView color={`#818792`} fw={`500`} fs={`12px`} lh={`142%`}>{`예약 장소`}</TextView>
              </TextContent>

              <TextContent width={`calc(100% - 100px)`}>
                <TextView color={`#3C3E41`} ws={`nowrap`} to={`ellipsis`} width={`100%`} fw={`500`} fs={`12px`} lh={`142%`}>
                  {defaultInfo && defaultInfo.address}
                </TextView>
              </TextContent>
            </BackgroundContent>

            <Divider height={"6"} color={"#ffffff"} />

            <BackgroundContent jc={`space-between`} ai={`center`}>
              <TextContent width={`100px`}>
                <ImageIcon100 style={{ marginRight: `4px` }} width={`16px`} height={`16px`}>
                  <img src={payTypeGrey} />
                </ImageIcon100>

                <TextView color={`#0E6DFF`} fw={`500`} fs={`12px`} lh={`142%`}>{`스포츠`}</TextView>
                <TextView style={{ padding: "0 6px" }} color={`#818792`} fw={`500`} fs={`12px`} lh={`142%`}>{`|`}</TextView>
                <TextView color={`#818792`} fw={`500`} fs={`12px`} lh={`142%`}>{`유형`}</TextView>
              </TextContent>

              <TextContent width={`calc(100% - 100px)`}>
                <TextView color={`#0E6DFF`} ws={`nowrap`} to={`ellipsis`} fw={`500`} fs={`12px`} lh={`142%`}>
                  {defaultInfo && defaultInfo.sport}
                </TextView>

                {defaultInfo && defaultInfo.duration && (
                  <>
                    <TextView style={{ padding: "0 6px" }} color={`#818792`} fw={`500`} fs={`12px`} lh={`142%`}>{`|`}</TextView>

                    <TextView color={`#3C3E41`} ws={`nowrap`} to={`ellipsis`} fw={`500`} fs={`12px`} lh={`142%`}>
                      {defaultInfo && defaultInfo.duration}
                    </TextView>
                  </>
                )}

                <TextView style={{ padding: "0 6px" }} color={`#818792`} fw={`500`} fs={`12px`} lh={`142%`}>{`|`}</TextView>

                <TextView color={`#3C3E41`} ws={`nowrap`} to={`ellipsis`} fw={`500`} fs={`12px`} lh={`142%`}>
                  {defaultInfo && defaultInfo.type}
                </TextView>
              </TextContent>
            </BackgroundContent>

            <Divider height={"6"} color={"#ffffff"} />

            <BackgroundContent jc={`space-between`} ai={`center`}>
              <TextContent width={`100px`}>
                <ImageIcon100 style={{ marginRight: `4px` }} width={`16px`} height={`16px`}>
                  <img src={payScheduleGrey} />
                </ImageIcon100>

                <TextView color={`#818792`} fw={`500`} fs={`12px`} lh={`142%`}>{`예약 일정`}</TextView>
              </TextContent>

              <TextContent width={`calc(100% - 100px)`}>
                <TextView color={`#3C3E41`} ws={`nowrap`} to={`ellipsis`} width={`100%`} fw={`500`} fs={`12px`} lh={`142%`}>
                  {defaultInfo && defaultInfo.schedule}
                </TextView>
              </TextContent>
            </BackgroundContent>

            <Divider height={"6"} color={"#ffffff"} />

            <BackgroundContent jc={`space-between`} ai={`center`}>
              <TextContent width={`100px`}>
                <ImageIcon100 style={{ marginRight: `4px` }} width={`16px`} height={`16px`}>
                  <img src={payCapacityGrey} />
                </ImageIcon100>

                <TextView color={`#818792`} fw={`500`} fs={`12px`} lh={`142%`}>{`예약 인원`}</TextView>
              </TextContent>

              <TextContent width={`calc(100% - 100px)`}>
                <TextView color={`#3C3E41`} ws={`nowrap`} to={`ellipsis`} width={`100%`} fw={`500`} fs={`12px`} lh={`142%`}>
                  {defaultInfo && defaultInfo.people}인
                </TextView>
              </TextContent>
            </BackgroundContent>
          </BackgroundContent>

          <Divider height={"17"} color={"#ffffff"} />
        </ContentContainer>

        <BackgroundContent style={{ position: "relative", zIndex: "53" }} padding={`0 24px`} jc={`flex-end`} ai={`center`}>
          <div
            onClick={() => setInfoPopup(!infoPopup)}
            style={{
              cursor: "pointer",
              //   borderBottom: "rgba(0, 0, 0, 0.1)",
              boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.1)",
              backgroundColor: "#ffffff",
              display: "inline-block",
              padding: "2px 5px",
              borderRadius: "0px 0px 8px 8px",
            }}
          >
            <ImageIconCenter isRotate={!infoPopup} width={`20px`} height={`20px`}>
              <img src={arrowBlackBottom} />
            </ImageIconCenter>
          </div>
        </BackgroundContent>
      </InfoPopup>
      <InnerContainer id="chatContainer" chatHeight={chatHeight}>
        {chatList && Object.entries(chatList).map((item, idx) => ChatMake(item[1], idx, item[0]))}
      </InnerContainer>

      <ChatBottom chatHeight={chatHeight}>
        <ChatArea
          chatHeight={chatHeight}
          type="search"
          value={chatText}
          placeholder="내용을 입력해 주세요."
          onBlur={() => StopChat()}
          onFocus={() => StartChat()}
          /*onKeyPress={(e) => {
            if (e.key === "Enter") {
              GoChat();
            }
          }}*/
          onChange={(e) => setChatText(e.target.value)}
        />

        <ChatIcon
          onMouseDown={() => {
            GoChat();
          }}
          width="42px"
          height="42px"
        >
          <img src={chatIcon} />
        </ChatIcon>
      </ChatBottom>

      <div style={{ height: "83px" }} />

      <LoadingSpinner loading={loading} title={`정보를 확인하고 있습니다.`} content={`잠시만 기다려 주세요!`} />
    </Container>
  );
}

export default ActivityManage;

const Container = styled.section`
  height: calc(var(--vh, 1vh) * 100);
  height: 100vh;
  overflow: hidden;
`;

const Divider = styled.div`
  height: ${({ height }) => height && `${height}px`};
  background-color: ${({ color }) => color && `${color}`};
  width: 100%;
`;

const InnerContainer = styled.div`
  width: 100%;
  height: calc((var(--vh, 1vh) * 100) - (51px + ${({ chatHeight }) => chatHeight && `${chatHeight}`}));
  height: calc(100vh - (51px + ${({ chatHeight }) => chatHeight && `${chatHeight}`}));
  overflow-y: auto;
  overflow-x: hidden;
  display: inline-block;
  position: relative;
  z-index: 51;
  padding: 24px 0;
`;

const ContentContainer = styled.div`
  width: 100%;
  padding: 0 24px;
  display: inline-block;
  max-height: ${({ display }) => display && `${display}`};
  position: relative;
  background-color: #ffffff;
  z-index: 50;
  overflow: hidden;

  transition: max-height 0.5s ease-out;
`;

const InfoPopup = styled.div`
  position: absolute;
  top: 50px;
  right: 0;
  display: inline-flex;
  flex-wrap: wrap;
  width: 100%;
  z-index: 52;
`;

const ChatBottom = styled.div`
  width: 100%;
  padding: 8px 24px;
  height: ${({ chatHeight }) => chatHeight && `${chatHeight}`};
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
  border-top: 1px solid #f5f5f5;
`;

const BackgroundContent = styled.div`
  width: 100%;
  border: ${({ border }) => border && `${border}`};
  background-color: ${({ bgc }) => bgc && `${bgc}`};
  padding: ${({ padding }) => padding && `${padding}`};
  display: inline-flex;
  border-radius: ${({ br }) => br && `${br}px`};
  justify-content: ${({ jc }) => jc && `${jc}`};
  align-items: ${({ ai }) => ai && `${ai}`};
  flex-wrap: ${({ wrap }) => wrap && `${wrap}`};
  position: relative;
`;

const ChatArea = styled.textarea`
  width: calc(100% - 52px);
  border-radius: 0;
  height: ${({ chatHeight }) => chatHeight && `calc( ${chatHeight} - 16px)`};
  resize: none;
  padding: 11px 18px;
  border: 1px solid #dcdcdc;
  border-radius: 24px;
  color: #000000;
  outline: none;

  textarea::placeholder {
    color: #acafb5;
  }
`;

const ImageIcon100 = styled.div`
  width: ${({ width }) => width && `${width}`};
  height: ${({ height }) => height && `${height}`};
  display: inline-block;

  img {
    width: ${({ width }) => width && `${width}`};
    display: inline-block;
  }
`;

const TextContent = styled.div`
  width: ${({ width }) => width && `${width}`};
  border: ${({ border }) => border && `${border}`};
  background-color: ${({ bgc }) => bgc && `${bgc}`};
  padding: ${({ padding }) => padding && `${padding}`};
  display: inline-flex;
  flex-wrap: ${({ wrap }) => wrap && `${wrap}`};
  border-radius: ${({ br }) => br && `${br}px`};
`;

const TextView = styled.span`
  width: ${({ width }) => width && `${width}`};
  text-align: ${({ ta }) => ta && `${ta}`};
  color: ${({ color }) => color && `${color}`};
  font-weight: ${({ fw }) => fw && `${fw}`};
  font-size: ${({ fs }) => fs && `${fs}`};
  line-height: ${({ lh }) => lh && `${lh}`};
  display: inline-block;
  text-overflow: ${({ to }) => to && `${to}`};
  overflow: hidden;
  white-space: ${({ ws }) => ws && `${ws}`};
`;

const ChatIcon = styled.div`
  cursor: pointer;
  width: ${({ width }) => width && `${width}`};
  height: ${({ height }) => height && `${height}`};
  display: inline-block;
  potisition: relative;
  z-index: 56;

  img {
    width: ${({ width }) => width && `${width}`};
    display: inline-block;
  }
`;

const ImageIconCenter = styled.div`
  width: ${({ width }) => width && `${width}`};
  height: ${({ height }) => height && `${height}`};
  display: inline-block;
  position: relative;
  overflow: hidden;
  transition: all ease 0.5s;
  ${({ isRotate }) => isRotate && `transform: rotate( 180deg )`};

  img {
    height: ${({ height }) => height && `${height}`};
    display: inline-block;
    position: absolute;
    top: 0;
    right: 50%;
    transform: translate(50%, 0);
  }
`;

const GreyLineAbsol = styled.div`
  width: calc(100% - 48px);
  display: inline-block;
  height: 2px;
  position: absolute;
  top: 50%;
  right: 24px;
  transform: translate(0, -50%);
  background-color: #f5f5f5;
`;

const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px 22px;
  border-bottom: 1px solid #f5f5f5;
  position: sticky;
  top: 0;
  background-color: #ffffff;
  z-index: 10;
  height: 50.55px;

  ${({ infoPopup }) => (infoPopup ? `box-shadow: none` : `box-shadow: 0px 2px 8px rgba(0,0,0,0.1)`)}
`;

const Arrow = styled.img`
  cursor: pointer;
  width: 24px;
  height: 24px;
  opacity: ${({ hidden }) => (hidden ? "0" : "1")};
`;

const HeaderTitle = styled.div`
  font-size: 18px;
  font-weight: 600;
  line-height: 25.56px;
  color: #1a1c1f;
`;

const Box = styled.div`
  width: 24px;
  height: 24px;
`;
