import React, { useState } from "react";
import styled from "styled-components";

// style
import colors from "../../styles/colors";

// img
import FaqItemIcon from "../../icon/faqItemIcon.png";
import FaqArrow from "../../icon/faqDropArrow.png";

function FaqItem({ item }) {
  const [open, setOpen] = useState(false);

  return (
    <>
      <FaqContainer onClick={() => setOpen((prev) => !prev)}>
        <JustBetween>
          <FaqIcon src={FaqItemIcon} />
          {item.title}
        </JustBetween>
        <ArrowIcon src={FaqArrow} open={open} />
      </FaqContainer>
      {open && <ShowContent>{item.info}</ShowContent>}
    </>
  );
}

export default FaqItem;

const FaqContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px 24px;
  border-bottom: 1px solid ${colors.Gr01};
  cursor: pointer;
`;

const JustBetween = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: ${colors.Gr05};
  line-height: 142%;
  font-size: 14px;
  font-weight: 600;
`;

const FaqIcon = styled.img`
  width: 20px;
  height: 20px;
  margin-right: 8px;
`;

const ArrowIcon = styled.img`
  width: 24px;
  height: 24px;
  margin-left: 12px;
  transform: ${({ open }) => (open ? "rotate(-180deg)" : "")};
  transition: all 0.75s;
`;

const ShowContent = styled.div`
  padding: 20px 24px;
  background-color: ${colors.Gr01};
  line-height: 142%;
  letter-spacing: -0.02em;
  font-size: 12px;
  font-weight: 500;
`;
