import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { useParams, useLocation } from "react-router-dom";
import axios from "axios";

// component
import Header from "../components/basic/Header";
import { GetOne } from "../tool/localstorage";
import { LoadingSpinner } from "../tool/loading";
import { fbevent } from "../tool/firebase";

// icon
import ShareIcon from "../icon/shareIcon.png";

function AnnouncementDetail() {
  const { id } = useParams();
  const [item, setItem] = useState();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fbevent("B2B_AnnouncementDetail_Page_View", {
      email : GetOne("email")
    });

    var token = GetOne("token");

    const frm = new FormData();

    frm.append("mode", "notice_intro");
    frm.append("token", token);
    frm.append("notice_number", id);

    axios
      .post("https://ai.semos.kr/semos_partner/notice/data", frm, {
        headers: { "Content-Type": "multipart/form-data" },
      })
      .then((data) => {
        var result = data.data;
        if (result.status == "success") {
          setItem(result.notice_list);
          setLoading(false);
        } else {
          window.alert("잘못된 접근입니다.");
          setLoading(false);
        }
      });
  }, []);

  return (
    <Container>
      <Header title={item && item.notice_name} back={true}>
        {/*<Arrow src={ShareIcon} onClick={() => window.alert("준비중입니다.")} />*/}
      </Header>
      <TitleBox>
        <Styler pl={24} pr={24} pt={24} pb={24}>
          <JustBetween>
            <SemosAnnounceMark>세모스 공지</SemosAnnounceMark>
            <AnnounceTitle>{item && item.notice_name}</AnnounceTitle>
          </JustBetween>
          <DateBox>{item && item.notice_date}</DateBox>
        </Styler>
      </TitleBox>
      {item?.notice_thumbnail && (
        <AnnounceContent src={item && item.notice_thumbnail} />
      )}
      <ContentSection>{item && item.notice_content}</ContentSection>
      <div style={{ height: "83px" }} />
      {/*<Footer />*/}
      <LoadingSpinner
        loading={loading}
        title={`정보를 확인하고 있습니다.`}
        content={`잠시만 기다려 주세요!`}
      />
    </Container>
  );
}

export default AnnouncementDetail;

const Container = styled.section`
  height: calc(var(--vh, 1vh) * 100);
  overflow-x: hidden;
`;

const Arrow = styled.img`
  opacity: ${({ hidden }) => (hidden ? "0" : "1")};
  width: 24px;
  height: 24px;
  cursor: pointer;
`;

const JustBetween = styled.div`
  display: flex;
  align-items: center;
`;

const Styler = styled.div`
  position: relative;
  display: ${({ flex }) => (flex ? "flex" : "")};
  flex-direction: ${({ direction }) => direction};
  justify-content: ${({ justify }) => justify};
  align-items: ${({ align }) => align};
  padding-top: ${({ pt }) => pt}px;
  padding-bottom: ${({ pb }) => pb}px;
  padding-left: ${({ pl }) => pl}px;
  padding-right: ${({ pr }) => pr}px;
`;

const SemosAnnounceMark = styled.div`
  padding: 3.5px 11px;
  font-size: 10px;
  font-weight: 600;
  color: #ffffff;
  background-color: #0f6eff;
  border-radius: 8px;
  margin-right: 14px;
  white-space: nowrap;
`;

const AnnounceTitle = styled.div`
  font-size: 18px;
  font-weight: 600;
  line-height: 142%;
  letter-spacing: -0.02em;
`;

const DateBox = styled.div`
  font-size: 12px;
  font-weight: 500;
  line-height: 142%;
  letter-spacing: -0.02em;
  color: #818792;
  margin-top: 8px;
  float: right;
`;

const TitleBox = styled.div`
  padding-bottom: 24px;
  border-bottom: 1px solid #f5f5f5;
`;

const AnnounceContent = styled.img`
  width: 100%;
`;

const ContentSection = styled.div`
  padding: 24px;
  font-size: 14px;
  font-weight: 500;
  line-height: 142%;
  letter-spacing: -0.02em;
  color: #3c3e41;
  white-space: pre-wrap;
`;
