import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import styled from "styled-components";

// components
import Header from "../components/basic/Header";
import Modal from "../components/newActivityRegister/modal";
import { GetOne } from "../tool/localstorage";
import { LoadingSpinner } from "../tool/loading";

// styles
import colors from "../styles/colors";

// img
import plusBlue from "../icon/plusBlue.png";

const YoilToText = ['일', '월', '화', '수', '목', '금', '토', '공휴일'];

const Facility = () => {

    const [modalStatus, setModalStatus] = useState({
        status: false,
        type: "",
        link: "",
    });

    const [loading, setLoading] = useState(true);

    const navigate = useNavigate();

    const [height, setHeight] = useState();
    const [facilityList, setFacilityList] = useState();

    useEffect(() => {
        setTimeout(() => {
            var FirstComponent = document.getElementById("HeightCheck").clientHeight;
            setHeight(FirstComponent);
        }, 500);

        var token = GetOne("token");

        const frm = new FormData();

        frm.append("mode", "main");
        frm.append("token", token);

        axios
        .post("https://ai.semos.kr/semos_partner/v3/activity/Facility", frm, {
            headers: { "Content-Type": "multipart/form-data" },
        })
        .then((data) => {
            var Value = data.data;
            var Status = Value.status;
            var StatusMessage = Value.status_message;
            var List = Value.list;

            if (Status == "success") {
                setFacilityList(List);
                setLoading(false);
            } else {
                window.alert(StatusMessage);
            }
        });
    }, []);

    const GoRegister = () => {
        navigate("/new_facility");
    };

    const ThisYoil = YoilToText[new Date().getDay()];

    const GetOpreateTime = (item) => {
        if(item) {
            let TodayTime = "";
            let TodayYoil = "";

            if(item.today_holiday) {
                TodayTime = item.time['공휴일'];
                TodayYoil = '공휴일';
            } else {
                TodayTime = item.time[ThisYoil];
                TodayYoil = ThisYoil;
            }

            if(TodayTime) {
                return `[${TodayYoil}] ${TodayTime.start_hour}:${TodayTime.start_min} ~ ${TodayTime.finish_hour}:${TodayTime.finish_min}`;
            } else {
                return "휴무일";
            }
        }
    }

    return (
        <Container>
            <Header title="시설 관리" back={true} handleClick={() => navigate("/home")} />

            <InnerContainer>

                <HeightCheck id="HeightCheck">

                    <Divider height={"16"} color={colors.White} />

                    <ContentContainer
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                            GoRegister();
                        }}
                    >
                        <BackgroundContent
                            border={`1px solid ${colors.Bl04}`}
                            bgc={colors.Bl01}
                            padding={`16px 18px 16px 18px`}
                            br={`8`}
                            jc={`space-between`}
                            ai={`center`}
                        >

                            <TextContent width={`calc(100% - 23px)`} wrap={`wrap`}>
                                <TextView width={`100%`} color={colors.Bl07} fw={`600`} fs={`16px`} lh={`142%`}>
                                신규 시설 등록
                                </TextView>

                                <Divider height={"4"} />

                                <TextView width={`100%`} color={colors.Bl04} fw={`500`} fs={`12px`} lh={`142%`}>
                                새로운 시설을 등록할 수 있어요
                                </TextView>
                            </TextContent>

                            <ImageIcon100 width={`22px`} height={`22px`}>
                                <img src={plusBlue} />
                            </ImageIcon100>

                        </BackgroundContent>
                        
                    </ContentContainer>

                    <Divider height={"12"} color={colors.White} />

                    <ContentContainer>
                        <BackgroundContent jc={`space-between`} ai={`center`}>
                            {facilityList ? <TextView color={colors.Gr04} fw={`500`} fs={`13px`} lh={`142%`}>{`총 ${facilityList.length}개`}</TextView> : ""}
                        </BackgroundContent>
                    </ContentContainer>

                </HeightCheck>

                <ScrollContainer height={height && height}>
                    {facilityList?.map((item, idx) => (
                        <CardContainer
                                active={(item.status == 1)}
                                onClick={() => navigate(`/facility_detail/${item.id}`)}
                                key={`${item.title}_${idx}`}
                                style={{
                                    borderBottom: `border-bottom: 1px solid ${colors.Gr01}`
                                }}
                        >
                            
                            <Divider height={"12"} color={colors.White} />

                            <BackgroundContent jc={`space-between`} ai={`center`}>
                                <ImageIconCenter width={`65px`} height={`65px`}>
                                    <img src={`${item.profile}`} />
                                </ImageIconCenter>

                                <TextContent 
                                    style={{ justifyContent: "space-between", height: "65px" }} 
                                    width={`calc(100% - 77px)`} 
                                    wrap={`wrap`}
                                >
                                    <TextView2 
                                        width={`100%`} 
                                        color={(item.status == 1) ? colors.Gr05 : colors.Gr04} 
                                        fw={`600`} 
                                        fs={`14px`} 
                                        lh={`142%`}
                                    >
                                        {`${item.title}`}
                                    </TextView2>

                                    <TextView
                                        style={{ 
                                            alignItems: "flex-end", 
                                            justifyContent : "flex-end",
                                            height: "auto", 
                                            textAlign : "right" 
                                        }}
                                        width={`100%`}
                                        color={(item.status == 1) ? colors.Gr06 : colors.Gr02}
                                        fw={`600`}
                                        fs={`15px`}
                                        lh={`142%`}
                                        //display={`inline-block`}
                                    >
                                        <span>
                                        {GetOpreateTime(item)}
                                        </span>
                                    </TextView>
                                </TextContent>
                            </BackgroundContent>

                        </CardContainer>
                    ))}
                </ScrollContainer>
                
            </InnerContainer>

            <LoadingSpinner loading={loading} title={`정보를 확인하고 있습니다.`} content={`잠시만 기다려 주세요!`} />

            <Modal modal={modalStatus} setModal={setModalStatus} />

        </Container>
    );
};

export default Facility;

const Container = styled.section`
    /* height: calc(var(--vh, 1vh) * 100); */
    overflow-x: hidden;
`;

const Divider = styled.div`
    height: ${({ height }) => height && `${height}px`};
    background-color: ${({ color }) => color && `${color}`};
    width: 100%;
`;

const InnerContainer = styled.div`
    width: 100%;
    /* height: calc((var(--vh, 1vh) * 100) - 50.55px); */
    overflow: hidden;
`;

const HeightCheck = styled.div`
    width: 100%;
    height: auto;
    display: inline-block;
`;

const ContentContainer = styled.div`
    width: 100%;
    padding: 0 24px;
    display: inline-flex;
    position: relative;
    margin-bottom: 6px;
`;

const BackgroundContent = styled.div`
    width: 100%;
    border: ${({ border }) => border && `${border}`};
    background-color: ${({ bgc }) => bgc && `${bgc}`};
    padding: ${({ padding }) => padding && `${padding}`};
    display: inline-flex;
    border-radius: ${({ br }) => br && `${br}px`};
    justify-content: ${({ jc }) => jc && `${jc}`};
    align-items: ${({ ai }) => ai && `${ai}`};
    position: relative;
`;

const TextContent = styled.div`
    width: ${({ width }) => width && `${width}`};
    border: ${({ border }) => border && `${border}`};
    background-color: ${({ bgc }) => bgc && `${bgc}`};
    padding: ${({ padding }) => padding && `${padding}`};
    display: inline-flex;
    flex-wrap: ${({ wrap }) => wrap && `${wrap}`};
    border-radius: ${({ br }) => br && `${br}px`};
`;

const TextView = styled.span`
    width: ${({ width }) => width && `${width}`};
    text-align: ${({ ta }) => ta && `${ta}`};
    color: ${({ color }) => color && `${color}`};
    font-weight: ${({ fw }) => fw && `${fw}`};
    font-size: ${({ fs }) => fs && `${fs}`};
    line-height: ${({ lh }) => lh && `${lh}`};
    display: ${({ display }) => display ? `${display}` : "inline-flex"};
`;

const TextView2 = styled.span`
    width: ${({ width }) => width && `${width}`};
    text-align: ${({ ta }) => ta && `${ta}`};
    color: ${({ color }) => color && `${color}`};
    font-weight: ${({ fw }) => fw && `${fw}`};
    font-size: ${({ fs }) => fs && `${fs}`};
    line-height: ${({ lh }) => lh && `${lh}`};

    text-overflow: ellipsis;
    overflow: hidden;
    word-break: break-word;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
`;

const FliterView = styled.div`
    display: inline-flex;
    align-items: ${({ ai }) => ai && `${ai}`};
`;

const InputView = styled.input`
    width: ${({ width }) => width && `${width}`};
    height: ${({ height }) => height && `${height}`};
    padding: ${({ padding }) => padding && `${padding}`};
    color: ${({ color }) => color && `${color}`};
    font-weight: ${({ fw }) => fw && `${fw}`};
    font-size: ${({ fs }) => fs && `${fs}`};
    line-height: ${({ lh }) => lh && `${lh}`};
    cursor: text;
    &::placeholder {
        color: ${({ colorP }) => colorP && `${colorP}`};
    }
`;

const ImageIcon100 = styled.div`
    cursor: pointer;
    width: ${({ width }) => width && `${width}`};
    height: ${({ height }) => height && `${height}`};
    display: inline-block;

    img {
        width: ${({ width }) => width && `${width}`};
        display: inline-block;
    }
`;

const ImageIconCenter = styled.div`
    width: ${({ width }) => width && `${width}`};
    height: ${({ height }) => height && `${height}`};
    display: inline-block;
    position: relative;
    overflow: hidden;

    img {
        height: ${({ height }) => height && `${height}`};
        display: inline-block;
        position: absolute;
        top: 0;
        right: 50%;
        transform: translate(50%, 0);
    }

    .emptySchedule {
        width: ${({ width }) => width && `${width}`};
        height: ${({ height }) => height && `${height}`};
        position: absolute;
        top: 0;
        right: 0;
        display: inline-flex !important;
        align-items: cetner !important;
        justify-content: center !important;
        z-index: 100;
        background-color: ${colors.B60};

        img {
        width: 33px !important;
        height: 33px !important;
        top: 50% !important;
        right: 50% !important;
        transform: translate(50%, -50%) !important;
        }
    }
`;

const ScrollContainer = styled.div`
    height: ${({ height }) => height && `calc(100vh - ${height}px)`};
    /* max-height: ${({ height }) => height && `calc( (var(--vh, 1vh) * 100) - (${height}px + 133.55px) )`}; */
    /* min-height: ${({ height }) => height && `calc((var(--vh, 1vh) * 100) - (${height}px + 133.55px) )`}; */
    /* height: ${({ height }) => height && `calc(100vh - (${height}px + 133.55px) )`}; */
    width: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    display: inline-block;
    flex-wrap: wrap;
    padding-bottom: 85px;
`;

const CardContainer = styled.div`
    width: 100%;
    display: inline-block;
    flex-wrap: wrap;
    padding: 20px 24px;
    border-bottom: 1px solid ${colors.Gr01};
    cursor: ${({ active }) => active && `${active}`};
`;
