import React from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import Modal from "react-modal";

// style
import colors from "../../styles/colors";

// img
import PartnerProfileModalImg from "../../icon/partnerProfileBanner.png";
import { ReactComponent as CheckCircle } from "../../icon/checkCircle.svg";
import { ReactComponent as CheckCircleFilled } from "../../icon/checkCircleFilled.svg";

const AffiliateAlertModal = ({
  modal,
  setModal,
  affiliateCheck,
  handleAffiliateCheck,
}) => {
  const navigate = useNavigate();

  return (
    <Modal
      isOpen={modal}
      onRequestClose={() => {}}
      contentLabel="Alert Modal"
      style={customStyles}
    >
      <Container>
        <AffiliateImg
          src={PartnerProfileModalImg}
          alt="affiliate modal"
          onClick={() => {
            navigate("/profile");
          }}
        />

        <AffiliateModalLayout>
          <AffiliateCheck onClick={handleAffiliateCheck}>
            {affiliateCheck ? (
              <CheckCircleFilled fill={colors.Gr04} />
            ) : (
              <CheckCircle fill={colors.Gr04} />
            )}
            <span>오늘 하루 보지 않기</span>
          </AffiliateCheck>
          <AffiliateBtnContainer>
            <AffiliateBtn onClick={setModal} isClose={true}>
              닫기
            </AffiliateBtn>
            <AffiliateBtn
              onClick={() => {
                navigate("/profile");
              }}
              isClose={false}
            >
              홍보 페이지 사용하기
            </AffiliateBtn>
          </AffiliateBtnContainer>
        </AffiliateModalLayout>
      </Container>
    </Modal>
  );
};

export default AffiliateAlertModal;

const customStyles = {
  content: {
    width: "328px",
    marginRight: "-50%",
    padding: 0,
    borderRadius: "16px",
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    transform: "translate(-50%, -50%)",
  },
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const AffiliateModalLayout = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 16px;
  width: 100%;
  height: 100%;
  padding: 16px 24px 24px 24px;
  border-radius: 0;
`;

const AffiliateImg = styled.img`
  width: 100%;
  cursor: pointer;
`;

const AffiliateCheck = styled.span`
  display: flex;
  align-items: center;
  gap: 4px;
  line-height: 22px;
  font-size: 14px;
  font-weight: 600;
  cursor: pointer;

  svg {
    width: 16px;
    height: 16px;
  }
`;

const AffiliateBtnContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
  width: 100%;
`;

const AffiliateBtn = styled.button`
  flex: 1;
  padding: 14px 0;
  background-color: ${({ isClose }) =>
    isClose ? `${colors.Gr01}` : `${colors.Bl07}`};
  border-radius: 8px;
  color: ${({ isClose }) => (isClose ? `${colors.Gr05}` : `${colors.White}`)};
`;
