import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import Sheet from "react-modal-sheet";

// components
import AccountReviseInput from "../components/accountRevise/AccountReviseInput";
import AccountImageUploader from "../components/accountRevise/AccountImageUploader";
import AccountCardModal from "../components/accountRevise/AccountCardModal";
import BankModal from "../components/signup/bank_modal";
import Header from "../components/basic/Header";
import { GetOne } from "../tool/localstorage";
import { fbevent } from "../tool/firebase";
import { LoadingSpinner } from "../tool/loading";
import {
  MAX_ID_NUMBER_LENGTH,
  MAX_REGISTER_NUMBER_LENGTH,
} from "../data/config";

// style
import colors from "../styles/colors";
import { W } from "../styles/globalStyles";

const STEP_LIST = {
  name: {
    name: "이름",
    description : " (필수)",
    placeholder: "정산 받을 분의 본명을 입력해주세요",
  },
  account: {
    name: "정산정보",
    description : " (필수)",
    placeholder: "계좌 번호를 입력해주세요",
  },
  idNumber: {
    name: "주민등록번호",
    description : " (필수)",
    placeholder: "주민등록번호를 입력해주세요",
  },
  registerNumber: {
    name: "사업자등록번호",
    description : " (필수)",
    placeholder: "사업자등록번호를 입력해주세요",
  },
  idCard: {
    name: "신분증",
    description : " (사업자가 아닌 경우 등록)",
    placeholder: "",
  },
  bankCard: {
    name: "통장 사본",
    description : " (필수)",
    placeholder: "",
  },
  registerCard: {
    name: "사업자등록증",
    description : " (사업자만 등록)",
    placeholder: "",
  },
  paymentCard: {
    name: "월간 이용료 결제 카드",
    description : " (구독형만 등록)",
    placeholder: "",
  },
};


const AccountRevise = () => {
  const navigate = useNavigate();

  const [loading, setLoading] = useState(true);
  const [defaultInfo, setDefaultInfo] = useState({
    id: "",
    name: "",
    bank: "",
    account: "",
    idNumber: "",
    registerNumber: "",
    idCard: "",
    bankCard: "",
    registerCard: "",
    type: "",
  });
  const [info, setInfo] = useState({
    id: "",
    name: "",
    bank: "",
    account: "",
    idNumber: "",
    registerNumber: "",
    idCard: "",
    bankCard: "",
    registerCard: "",
    type: "",
  });
  const [valid, setValid] = useState({
    name: false,
    bank: false,
    account: false,
    idNumber: false,
    registerNumber: false,
    idCard : false,
    bankCard : false
  });
  const [updated, setUpdated] = useState(false);
  const [bank, setBank] = useState(false);
  const [bottom, setBottom] = useState(false);
  const [goBackModal, setGoBackModal] = useState(false);
  const [cardModal, setCardModal] = useState(false);

  useEffect(() => {
    fbevent("B2B_AccountEdit_Page_View", {
      email : GetOne("email")
    });

    getData();
  }, []);

  useEffect(() => {
    setValid((prev) => ({ ...prev, name: info?.name?.trim().length > 0 }));
    setValid((prev) => ({ ...prev, bank: info?.bank?.length > 0 }));
    setValid((prev) => ({ ...prev, account: info?.account?.trim().length > 0 }));
    setValid((prev) => ({
      ...prev,
      idNumber: info?.idNumber?.trim().length === MAX_ID_NUMBER_LENGTH,
    }));
    setValid((prev) => ({
      ...prev,
      registerNumber:
        info?.registerNumber?.trim().length === MAX_REGISTER_NUMBER_LENGTH,
    }));

    defaultInfo.name === info.name &&
    defaultInfo.bank === info.bank &&
    defaultInfo.account === info.account &&
    defaultInfo.idNumber === info.idNumber &&
    defaultInfo.idCard === info.idCard &&
    defaultInfo.registerNumber === info.registerNumber &&
    defaultInfo.registerCard === info.registerCard &&
    defaultInfo.bankCard === info.bankCard
      ? setUpdated(false)
      : setUpdated(true);
  }, [info]);

  // GET DATA
  const getData = () => {
    setLoading(true);

    const token = GetOne("token");
    const frm = new FormData();

    frm.append("mode", "main");
    frm.append("token", token);

    axios
      .post("https://ai.semos.kr/semos_partner/v3/profile/AccountRevise", frm, {
        headers: {
          "Content-Type": "multipart/form-data",
          Accept: "application/json",
        },
      })
      .then((res) => {
        const value = res.data;
        const data = value.data;
        const status = value.status;
        if (status === "success") {

          setDefaultInfo(data);
          setInfo(data);
          setValid({
            name: data.name ? true : false,
            bank: data.bank ? true : false,
            account: data.account ? true : false,
            idNumber: data.idNumber ? true : false,
            registerNumber: data.registerNumber ? true : false,
          });

          setLoading(false);
        } else {
          console.log("ACCOUNT REVISE GET DATA ERROR");
        }
      })
      .catch((err) => console.log(err));
  };

  // SAVE DATA
  const saveData = () => {
    fbevent("B2B_AccountEdit_Save_Click", {
      email : GetOne("email")
    });
    
    const token = GetOne("token");
    
    axios
      .post(
        "https://ai.semos.kr/semos_partner/v3/profile/AccountRevise",
        {
          mode: "save",
          data: info,
          token: token,
        },
        { headers: { "Content-Type": "application/x-www-form-urlencoded" } }
      )
      .then((res) => {
        const value = res.data;
        const status = value.status;
        const statusMessage = value.status_message;
        if (status === "success") {
          setBottom(true);
        } else {
          window.alert(statusMessage);
        }
      });
  };

  // valid.bank && valid.account && valid.idNumber &&

  const Next = valid.name && valid.bankCard && (valid.idCard || valid.registerCard);

  return (
    <Container>
          <Header
            title="정산 관리"
            back={true}
            handleClick={() => (updated ? setGoBackModal(true) : navigate(-1))}
          />

          <TextInputContainer>
            <AccountReviseInput
              type="name"
              STEP_LIST={STEP_LIST}
              data={info}
              setData={setInfo}
              valid={valid}
              setValid={setValid}
            />
          </TextInputContainer>

          {/* 정산정보 
          <TextInputContainer>
            <AccountNumberInput
              STEP_LIST={STEP_LIST}
              type="account"
              data={info}
              setData={setInfo}
              bank={bank}
              setBank={setBank}
              valid={valid}
              setValid={setValid}
              isRevise={true}
            />
          </TextInputContainer>*/}

          {/* 주민등록번호 or 사업자등록번호 
          <TextInputContainer>
            <AccountReviseInput
              type={"idNumber"}
              STEP_LIST={STEP_LIST}
              data={info}
              setData={setInfo}
              valid={valid}
              setValid={setValid}
            />
          </TextInputContainer>*/}

          {/* 신분증 사진 */}
          <TextInputContainer>
            <InputTitle>
              {STEP_LIST["idCard"].name}
              <span className="Description" >{STEP_LIST["idCard"].description}</span>    
            </InputTitle>
            <AccountImageUploader
              type="idCard"
              data={info}
              setData={setInfo}
              loading={loading}
              setLoading={setLoading}
              loader={true}
            />
          </TextInputContainer>

          {/* 사업자등록증 */}
          <TextInputContainer>
            <InputTitle>
              {STEP_LIST["registerCard"].name}
              <span className="Description" >{STEP_LIST["registerCard"].description}</span>  
            </InputTitle>
            <AccountImageUploader
              type="registerCard"
              data={info}
              setData={setInfo}
              loading={loading}
              setLoading={setLoading}
              loader={true}
            />
          </TextInputContainer>

          {/* 통장 사본 */}
          <TextInputContainer>
            <InputTitle>
              {STEP_LIST["bankCard"].name}
              <span className="Description" >{STEP_LIST["bankCard"].description}</span>    
            </InputTitle>
            <AccountImageUploader
              type="bankCard"
              data={info}
              setData={setInfo}
              loading={loading}
              setLoading={setLoading}
              loader={true}
            />
          </TextInputContainer>

          {/* 파트너 이용료 결제 카드 
          <TextInputContainer>
            <InputTitle>
              {STEP_LIST["paymentCard"].name}
              <span className="Description" >{STEP_LIST["paymentCard"].description}</span>  
            </InputTitle>

            <PayCardContainer
              onClick={() => setCardModal(true)}
            >
              결제 카드 등록
            </PayCardContainer>
          </TextInputContainer>*/}

          <NextButtonContainer>
            <NextButton
              next={Next}
              updated={updated}
              onClick={() => {
                const next = Next;
                updated && saveData();
              }}
            >
              저장하기
            </NextButton>
          </NextButtonContainer>

          <BankModal
            info={info.bank}
            setInfo={setInfo}
            bank={bank}
            setBank={setBank}
          />

          <ReportModal
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              left: "50%",
              transform: "translate(-50%,0)",
            }}
            isOpen={goBackModal}
            snapPoints={[0.25]}
          >
            <ReportModal.Container style={{ width: `${W}px`, left: "none" }}>
              <BackModalLayout>
                <GoBackModalHeader>
                  지금 뒤로 가면 입력한 내용이 저장되지 않아요!
                  <br /> 정말 뒤로 가시겠어요?
                </GoBackModalHeader>
                <Divider h={36} bgc={colors.White} />
                <BtnConatiner>
                  <ModalButton
                    bgc={colors.White}
                    color={colors.Bl07}
                    onClick={() => navigate(-1)}
                  >
                    뒤로가기
                  </ModalButton>
                  <ModalButton
                    bgc={colors.Bl07}
                    color={colors.White}
                    onClick={() => setGoBackModal(false)}
                  >
                    계속 수정하기
                  </ModalButton>
                </BtnConatiner>
              </BackModalLayout>
            </ReportModal.Container>
            <ReportModal.Backdrop />
          </ReportModal>

          <ReportModal
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              left: "50%",
              transform: "translate(-50%,0)",
            }}
            isOpen={bottom}
            snapPoints={[0.25]}
          >
            <ReportModal.Container style={{ width: `${W}px`, left: "none" }}>
              <BackModalLayout>
                <ModalHeader>정산정보 편집이 저장되었어요.</ModalHeader>
                <Divider h={54} />
                <ModalButton
                  bgc={colors.Bl07}
                  color={colors.White}
                  onClick={() => {
                    setBottom(false);
                    window.location.reload();
                  }}
                >
                  확인
                </ModalButton>
              </BackModalLayout>
            </ReportModal.Container>
            <ReportModal.Backdrop />
          </ReportModal>

          <AccountCardModal
              open={cardModal}
              setOpen={setCardModal}
            />
        {
          (loading == true) ?
            <LoadingSpinner
              loading={loading}
              title={`정보를 확인하고 있습니다.`}
              content={`잠시만 기다려 주세요!`}
            />
          : <></>
        }

    </Container>
  );
};

export default AccountRevise;

const Container = styled.section`
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  overflow-x: hidden;
  overflow-y: auto;
  padding-bottom : 90px;
`;

const NextButton = styled.button`
  width: ${W - 48}px;
  /* padding: 16px; */
  padding: 16px;
  background-color: ${({ updated }) => (updated ? colors.Bl07 : colors.Gr01)};
  border: 1px solid;
  border-color: ${({ updated }) => (updated ? colors.Bl07 : colors.Gr02)};
  border-radius: 8px;
  color: ${({ updated }) => (updated ? colors.White : colors.Gr02)};
  /* background-color: ${({ updated, next }) =>
    updated && next ? `${colors.Bl07}` : `${colors.Gr01}`};
  border: 1px solid;
  border-color: ${({ updated, next }) =>
    updated && next ? `${colors.Bl07}` : `${colors.Gr02}`};
  border-radius: 8px;
  color: ${({ updated, next }) =>
    updated && next ? `${colors.White}` : `${colors.Gr02}`}; */
  text-align: center;
  font-size: 16px;
  font-weight: 600;
  cursor: ${({ updated }) => (updated ? "pointer" : "default")};
  /* cursor: ${({ updated, next }) =>
    updated && next ? "pointer" : "default"}; */
`;

const Divider = styled.div`
  height: ${({ h }) => h}px;
`;

const TextInputContainer = styled.div`
  background-color: ${colors.White};
  padding: 24px 24px 0 24px;
`;

const InputTitle = styled.span`
  display: inline-block;
  color: ${colors.Gr05};
  line-height: 24px;
  letter-spacing: -0.32px;
  font-size: 16px;
  font-weight: 600;

  .Description {
    color: ${colors.Red03};
    font-size: 12px;
    font-weight: 500;
    line-height: 26px;
  }
`;

const NextButtonContainer = styled.div`
  width: 100%;
  margin-top: 32px;
  padding: 10px 24px;
  border-top: 2px solid ${colors.Gr01};
  position : fixed;
  z-index: 500;
  bottom : 0;
  left : 0;
  background-color : #ffffff;
  display : inline-flex;
  align-items: center;
  justify-content: center;
`;

const ReportModal = styled(Sheet)``;

const BackModalLayout = styled.div`
  padding: 24px 24px 20px 24px;
`;

const ModalHeader = styled.div`
  color: ${colors.Gr06};
  line-height: 142%;
  letter-spacing: -0.02em;
  font-size: 18px;
  font-weight: 600;
`;

const GoBackModalHeader = styled.div`
  color: ${colors.Gr04};
  line-height: 142%;
  letter-spacing: -0.02em;
  font-size: 14px;
  font-weight: 700;
`;

const BtnConatiner = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
  width: 100%;
`;

const ModalButton = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 18px 0;
  background-color: ${({ bgc }) => bgc};
  border-radius: 8px;
  color: ${({ color }) => color};
  text-align: center;
  line-height: 142%;
  letter-spacing: -0.02em;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
`;

const PayCardContainer = styled.div`
  width : 100%;
  border-radius : 8px;
  line-height: 142%;
  letter-spacing: -0.02em;
  font-size: 1.8rem;
  font-weight: 500;
  background-color : ${colors.Bl07};
  color : ${colors.White};
  display : inline-flex;
  align-items: center;
  justify-content : center;
  padding : 10px 0;
  margin : 10px 0 0 0;
  cursor : pointer;
`;