import { PC } from "../styles/globalStyles";

class AddressService {
  constructor(callback) {
    this.postcode = new window.daum.Postcode({
      oncomplete: callback,
    });
  }

  open = () => {
    if (window.document.documentElement.clientWidth > PC) {
      this.postcode.open();
    } else {
      this.postcode.embed(document.getElementById("PostCodeAdd"));
    }
  };
}

export default AddressService;
