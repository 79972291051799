const detectDevice = (target) => {
  const regMobile = [
    /android/i,
    /iphone/i,
    /ipad/i,
    /ipod/i,
    /blackberry/i,
    /windows phone/i,
    /semospartnerios/i,
    /semospartnerandroid/i,
  ];

  return regMobile.some((mobile) => target.toLowerCase().match(mobile));
};

export default detectDevice;
