import React, { useEffect, useState, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";
import axios from "axios";

//tool
import { GetOne } from "../tool/localstorage";
import { setImageFromFile, CropImage } from "../tool/ImageCrop";

//style
import { Wrapper, Text, Input, TextArea, Icon } from "../components/layouts/layoutStorage";
import colors from "../styles/colors";

//component
import { Loading } from "../tool/Loading";
import FacilityHeader from "../components/facilityRegister/FacilityHeader";

//icon
import { ReactComponent as Camera } from "../resource/icon/camera.svg";

// Default Data

const NoticeRegister = () => {

    const navigate = useNavigate();

    const params = useParams();

    const [loading, setLoading] = useState(false);

    const [focus, setFocus] = useState({
        name : false,
        description : false,
        image : false
    });
    
    const [data, setData] = useState({
        name : "",
        description : "",
        image : ""
    });

    const [valid, setValid] = useState({
        name : false,
        description : false,
        image : false
    });

    const W = window.document.documentElement.clientWidth;
    const MAX_NAME_LENGTH = 100;
    const MAX_DESCRIPTION_LENGTH = 500;
    const Regex = /(?:[\u2700-\u27bf]|(?:\ud83c[\udde6-\uddff]){2}|[\ud800-\udbff][\udc00-\udfff]|[\u0023-\u0039]\ufe0f?\u20e3|\u3299|\u3297|\u303d|\u3030|\u24c2|\ud83c[\udd70-\udd71]|\ud83c[\udd7e-\udd7f]|\ud83c\udd8e|\ud83c[\udd91-\udd9a]|\ud83c[\udde6-\uddff]|\ud83c[\ude01-\ude02]|\ud83c\ude1a|\ud83c\ude2f|\ud83c[\ude32-\ude3a]|\ud83c[\ude50-\ude51]|\u203c|\u2049|[\u25aa-\u25ab]|\u25b6|\u25c0|[\u25fb-\u25fe]|\u00a9|\u00ae|\u2122|\u2139|\ud83c\udc04|[\u2600-\u26FF]|\u2b05|\u2b06|\u2b07|\u2b1b|\u2b1c|\u2b50|\u2b55|\u231a|\u231b|\u2328|\u23cf|[\u23e9-\u23f3]|[\u23f8-\u23fa]|\ud83c\udccf|\u2934|\u2935|[\u2190-\u21ff])/g;

    useEffect(() => {
        if(params?.id) {
            GetData()
        }
    }, []);

    const SaveNotice = () => {

        const NameStatus = data.name.length > 1;
        const DescriptionStatus = data.description.length > 10;

        if(
            NameStatus && 
            DescriptionStatus
        ) {

            setLoading(true);

            const token = GetOne("token");
    
            axios
                .post(
                    "https://ai.semos.kr/semos_partner/v3/profile/Notice",
                    {
                        mode: "notice_save",
                        token: token,
                        data: data,
                        notice_id: params?.id ? params?.id : 'new'
                    },
                    {
                        headers: {
                            "Content-Type": "application/x-www-form-urlencoded",
                        },
                    }
                )
                .then((data) => {
                    let Value = data.data;
                    let Status = Value.status;
                    let StatusMessage = Value.status_message;
        
                    if (Status == "success") {
                        setLoading(false);
                        window.alert(StatusMessage);
                        if(params?.id) {
                            navigate(-1)
                        } else {
                            window.location.replace('/notice')
                        }

                    } else {
                        setLoading(false);
                        window.alert(StatusMessage);
                    }
                });
        } else {
            window.alert("올바르지 않는 정보가 있습니다.\n입력한 정보를 확인해주세요!");

            setValid({
                name: NameStatus,
                description: DescriptionStatus
            })
        }

    }

    const GetData = () => {

        setLoading(true);

        const token = GetOne("token");

        axios
            .post(
                "https://ai.semos.kr/semos_partner/v3/profile/Notice",
                {
                    mode: "notice_revise",
                    token: token,
                    notice_id: params?.id
                },
                {
                    headers: {
                        "Content-Type": "application/x-www-form-urlencoded",
                    },
                }
            )
            .then((data) => {
                let Value = data.data;
                let Status = Value.status;
                let StatusMessage = Value.status_message;
                let NoticeInfo = Value.notice_info;
    
                if (Status == "success") {
                    setLoading(false);
                    setData(NoticeInfo)
                } else {
                    setLoading(false);
                    window.alert(StatusMessage);
                }
            });

    }

    const SetConfirm = () => {

        const NameStatus = data.name.length > 5;
        const DescriptionStatus = data.description.length > 10;

        if(
            NameStatus && 
            DescriptionStatus
        ) { 
            return true;
        } else {
            return false;
        }
    }

    console.log(data)

    return (
        <Container id="description_container">

            <FacilityHeader
                status={true}
                title={params?.id ? '공지사항 수정' : '공지사항 등록'}
                available={false}
                statusHandler={() => console.log("신규 회원 등록")}
            />

            {/* 액티비티 이름 */}
            <Wrapper 
                width="100%" 
                ai="flex-start" 
                gap="8px" 
                padding="24px 24px 8px 24px"
                jc="space-between"
                wrap="wrap"
            >
                <Text 
                    width='100%'
                    size="16px" 
                    weight="600" 
                    lh="24px" 
                    ls="-0.32px" 
                    color={colors.Gr05}
                >
                    공지사항 제목 (필수)
                </Text>

                <Wrapper 
                    width="100%" 
                    height="56px" 
                    padding="0 16px" 
                    border={`1px solid ${focus?.name ? colors.Bl07 : colors.Gr02}`} 
                    radius="8px"
                >
                    <Input
                        onFocus={() => setFocus({ ...focus, name: true })}
                        onBlur={() => setFocus({ ...focus, name: false })}
                        value={data?.name}
                        onChange={(e) => {
                            let value = e.target.value;
                            if (Regex.test(value)) {
                                let text = value.replace(Regex, "");
                                setData((prev) => ({ ...prev, name: text.substring(0, MAX_NAME_LENGTH) }));
                            } else {
                                setData((prev) => ({ ...prev, name: value.substring(0, MAX_NAME_LENGTH) }));
                            }
                        }}
                        maxLength={MAX_NAME_LENGTH}
                        size="14px"
                        weight="500"
                        lh="22px"
                        ls="-0.28px"
                        width="100%"
                        phColor={colors.Gr03}
                        placeholder="예시) 2인 이상 액티비티 할인 이벤트" 
                        style={{padding : '8px 0'}}
                    />
                    <Text margin="0 0 0 4px" size="14px" weight="500" lh="22px" ls="-0.28px" color={colors.Gr03}>
                        {data?.name.length}/{MAX_NAME_LENGTH}
                    </Text>
                </Wrapper>

                <TipWrap>
                    <span className="tip">Tip!</span>
                    <span>{`공지사항의 제목을 적어주세요! (최소 5자)`}</span>
                </TipWrap>
            </Wrapper>

            {/* 공지사항 내용 */}
            <Wrapper 
                width="100%" 
                ai="flex-start" 
                gap="8px" 
                padding="24px 24px 8px 24px"
                jc="space-between"
                wrap="wrap"
            >
                <Text 
                    width='100%'
                    size="16px" 
                    weight="600" 
                    lh="24px" 
                    ls="-0.32px" 
                    color={colors.Gr05}
                >
                    공지사항 소개 이미지
                </Text>

                <ImageLabelBox MD={W > 1000} htmlFor={"thumbnail"}>
                    {
                        data.image ?
                        <Icon className={`ThumbnailImage`} src={data.image} radius="8px" />
                        : 
                        <>
                        <Camera width="4.8rem" height="4.8rem" fill={colors.Gr03} />
                        <Text size="1.6rem" weight="600" lh="132%" ls="-0.22px" color={colors.Gr03}>
                            이미지 등록
                        </Text>
                        </>
                    }
                        <ImageInput
                        type="file"
                        id="thumbnail"
                        accept="image/*"
                        onChange={({ target: { files } }) => {
                            setImageFromFile({
                                file: files[0],
                                setImageUrl: ({ result }) => {
                                    setData((prev) => ({ ...prev, image: result }));
                                },
                            });
                        }}
                        />
                </ImageLabelBox>
        
                <TipWrap>
                    <span className="tip">Tip!</span>
                    <span>{`공지사항을 소개할 수 있는 이미지를 선택해주세요!`}</span>
                </TipWrap>
            </Wrapper> 

            {/* 공지사항 내용 */}
            <Wrapper 
                width="100%" 
                ai="flex-start" 
                gap="8px" 
                padding="24px 24px 8px 24px"
                jc="space-between"
                wrap="wrap"
            >
                <Text 
                    width='100%'
                    size="16px" 
                    weight="600" 
                    lh="24px" 
                    ls="-0.32px" 
                    color={colors.Gr05}
                >
                    공지사항 내용 (필수)
                </Text>

                {/* years */}
                <Wrapper 
                    width="100% " 
                    // height="56px" 
                    padding="8px 16px" 
                    border={`1px solid ${focus.description ? colors.Bl07 : colors.Gr02}`} 
                    radius="8px"
                    ai={`flex-end`}
                >
                    <TextArea
                        onFocus={() => setFocus({ ...focus, description: true })}
                        onBlur={() => setFocus({ ...focus, description: false })}
                        value={data?.description}
                        onChange={(e) => {
                            let value = e.target.value;
                            let text = value.replace(Regex, "");
                            setData((prev) => ({ ...prev, description: text.substring(0, MAX_DESCRIPTION_LENGTH) }));
                        }}
                        maxLength={MAX_DESCRIPTION_LENGTH}
                        size="1.4rem"
                        weight="500"
                        lh="22px"
                        ls="-0.28px"
                        width="100%"
                        phColor={colors.Gr03}
                        placeholder="예시) 시즌 오픈 기념 2인 이상 20% 할인 이벤트"
                        height={`40rem`}
                        resize="none"
                        style={{
                            maxHeight : '40rem'
                        }}
                    />
                    <Text 
                        margin="0 0 0 4px" 
                        size="1.4rem" 
                        weight="500" 
                        lh="22px" 
                        ls="-0.28px" 
                        color={colors.Gr03}
                    >
                        {data?.description.length}/{MAX_DESCRIPTION_LENGTH}
                    </Text>
                </Wrapper>

                <TipWrap>
                    <span className="tip">Tip!</span>
                    <span>{`공지사항의 내용을 적어주세요! (최소 10자)`}</span>
                </TipWrap>
                
            </Wrapper>

            <BottomWrap>
                <Wrapper
                    onClick={() => SaveNotice()}
                    jc="center"
                    ai="center"
                    width="100%"
                    height="52px"
                    cursor={SetConfirm() ? "pointer" : "default"}
                    radius="8px"
                    bgc={SetConfirm() ? colors.Bl07 : colors.Gr01}
                >
                    <Text 
                        ta="center" 
                        size="1.6rem" 
                        weight="500" 
                        lh="22px" 
                        ls="-0.28px" 
                        color={SetConfirm() ? colors.White : colors.Gr03}
                    >
                        {params?.id ? `공지사항 수정하기` : `공지사항 등록하기`}
                    </Text>
                </Wrapper>
            </BottomWrap>

            {loading && <Loading />}
        </Container>
    );
};

export default NoticeRegister;

const Container = styled.section`
    height: calc(var(--vh, 1vh) * 100);
    overflow-x: hidden;
    width: 100%;
    padding-bottom : 150px;
`;

const TipWrap = styled.div`
    display: flex;
    gap: 2px;
    font-size: 14px;
    font-weight: 500;
    line-height: 22px;
    letter-spacing: -0.28px;
    color: ${colors.Gr03};
    .tip {
        color: ${colors.Bl05};
    }
    .notice {
        color: ${colors.Red03};
    }
`;

const BottomWrap = styled.div`
    width: 100%;
    max-width: 1300px;
    transform : translate(-50%, 0);
    display: flex;
    padding: 12px 24px;
    border-top: 1px solid ${colors.Gr01};
    background-color: ${colors.White};
    position: fixed;
    bottom : 0;
    left : 50%;
    z-index: 10;
`;

const ImageLabelBox = styled.label`
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-top: 20px;
    width: 100%;
    ${({ MD }) => (MD ? 
        `height: calc(((100rem - 48px) * (5/8)) + 1rem);`
        :
        `height: calc(((100vw - 48px) * (5/8)) + 1rem);`
    )};
    border: 1px solid ${colors.Gr02};
    border-radius: 8px;

    .ThumbnailImage {
        ${({ MD }) => (MD ? 
        `height: calc((100rem - 48px) * (5/8));`
        :
        `height: calc((100vw - 48px) * (5/8));`
    )};
    }
`;

const ImageInput = styled.input`
    display: none;
`;