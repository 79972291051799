import React, { useEffect, useState } from "react";
import styled from "styled-components";

//component
import ActivityInfo from "../components/activityRegister/NewActivityInfo";
import Modal from "../components/newActivityRegister/modal";
import { fbevent } from "../tool/firebase";
import { GetOne } from "../tool/localstorage";

const ActivityRegister = () => {
  const [step, setStep] = useState(1);
  const [change, setChange] = useState(false);
  const [status, setStatus] = useState("");
  const [openModal, setOpenModal] = useState({ status: false, type: "", link: "", confirmSave: false });
  //사용 안하는 중
  const [backStep, setBackStep] = useState(1);
  const [finalData, setFinalData] = useState({
    activity_name: "",
    category: [],
    activity_duration: "",
    activity_type: "",
    min_capacity: 1,
    max_capacity: 1,
    min_age: "",
    price: {},

    background: [],

    curriculum: {
      day1: [
        {
          id: "",
          content: "",
          last: "",
          title: "",
        },
      ],
    },
    activity_location: [],
    keyword: [], // 키워드
    recommend: [], // 추천 대상
    require: [], // 자격 대상
    caution: [], // 유의 사항
    faq: [], // 자주 묻는 질문
  });
  const [saveDeleteD, setSaveDelete] = useState({
    category: [],

    background: [],

    recommend: [],
    require: [],
    caution: [],
    faq: [],

    curriculum: [],
    activity_location: [],
  });

  const [ogPrice, setOgPrice] = useState({
    1: { id: "new", price: "", type : "" },
  });
  const [ogPriceM, setOgPriceM] = useState({
    1: { id: "new", price: "", type : "" },
  });
  const [ogPriceC, setOgPriceC] = useState({
    1: { id: "new", price: "", type : "" },
  });
  const [MCCount, setMCCount] = useState(1);
  const [MCCountOg, setMCCountOg] = useState(1);
  const [TypeOg, setTypeOg] = useState();

  useEffect(() => {
    fbevent("B2B_NewActivity_Page_View", {
      email : GetOne("email")
    });

  }, []);

  return (
      <Container id="description_container">
          <ActivityInfo
            open={true}
            aId={'new'}
            modal={openModal}
            setModal={setOpenModal}
            step={step}
            setStep={setStep}
            backStep={backStep}
            setBackStep={setBackStep}
            status={status}
            setStatus={setStatus}
            saveDeleteD={saveDeleteD}
            setSaveDelete={setSaveDelete}
            finalData={finalData}
            setFinalData={setFinalData}
            available={false}
            ogPrice={ogPrice}
            ogPriceM={ogPriceM}
            ogPriceC={ogPriceC}
            MCCount={MCCount}
            setMCCount={setMCCount}
            MCCountOg={MCCountOg}
            TypeOg={TypeOg}
            setChange={setChange}
          />
          <Modal modal={openModal} setModal={setOpenModal} />
      </Container>
  );
};

export default ActivityRegister;

const Container = styled.section`
  height: calc(var(--vh, 1vh) * 100);
  overflow-x: hidden;
  width: 100%;
  padding-bottom : 80px;
  position : relative;
  z-index: 11;
`;
