import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { GetOne } from "../../tool/localstorage";
import axios from "axios";
import Sheet from "react-modal-sheet";

import CancelModal from "./CancelModal";

// style
import { H, W } from "../../styles/globalStyles";
import colors from "../../styles/colors";

// img
import ReservationSchedule from "../../icon/reservationSchedule.png";
import ReservationPeople from "../../icon/reservationPeople.png";
import DefaultConfirm from "../../icon/activityConfirmDefault.png";
import { ReactComponent as Dashboard } from "../../icon/dashboard.svg";

const snap = H < 740 ? 0.3 : 0.25;

function PaymentFinish({ data }) {
  const navigate = useNavigate();

  const [openCancelModal, setOpenCancelModal] = useState(false);
  const [bottom, setBottom] = useState(false);

  const activity = (type) => {
    var token = GetOne("token");

    const frm = new FormData();

    frm.append("mode", "sold_status");
    frm.append("number", data.number);
    frm.append("type", type);
    frm.append("token", token);

    axios
      .post("https://ai.semos.kr/semos_partner/v3/profile/Reservation", frm, {
        headers: { "Content-Type": "multipart/form-data" },
      })
      .then((data) => {
        var Value = data.data;

        if (Value.status == "success") {
          alert(Value.status_message);
          if (type == "confirm") {
          }
          window.location.reload();
        } else {
          alert(Value.status_message);
        }
      });
  };

  let TypeCheck = (data?.type != '정기 레슨' && data?.type != '이용권(기간)' && data?.type != '이용권(횟수)');
  const UseCheck = {
    '정기 레슨' : "개월",
    '이용권(기간)' : "개월",
    '이용권(횟수)' : "회"
  };
  const CategoryCheck = {
    '정기 레슨' : "이용 기간",
    '이용권(기간)' : "이용 기간",
    '이용권(횟수)' : "이용 횟수"
  };

  return (
    <Wrap>
      <PaymentItem
        onClick={() =>
          navigate(`/reservation_detail/${data.number}`, {
            state: {
              from: "",
            },
          })
        }
      >
        <Styler pl={24} pr={24} pt={20}>

          <ActivityName>{data.title && data.title}</ActivityName>

          <Styler pt={8} pb={8}>
            <InfoBox>
              <Styler flex={true} pb={6}>
                <InfoTypeContainer>
                  <SmallIcon src={DefaultConfirm} />
                  <GreyText>
                    <Dye color={colors.Bl07}>스포츠</Dye>&nbsp;
                    <Seperator>|</Seperator>&nbsp;유형
                  </GreyText>
                </InfoTypeContainer>
                <InfoDataContainer>
                  <Dye color={colors.Bl07}>{data.sport && data.sport}</Dye>
                  {
                    TypeCheck ?
                      <Dye color={colors.Gr03}>
                        {data.duration && (
                          <>
                            <Seperator>&nbsp;&nbsp;|&nbsp;&nbsp;</Seperator>
                            {data.duration}
                          </>
                        )}
                        <Seperator>&nbsp;&nbsp;|&nbsp;&nbsp;</Seperator>
                        {data.type && data.type}
                      </Dye>
                    :
                    <Dye color={colors.Gr03}>
                      <Seperator>&nbsp;&nbsp;|&nbsp;&nbsp;</Seperator>
                      {data.type && data.type}
                    </Dye>
                  }

                </InfoDataContainer>
              </Styler>

              <Styler flex={true} pb={6}>
                <InfoTypeContainer>
                  <SmallIcon src={ReservationSchedule} />
                  <GreyText>{TypeCheck ? '예약 일정' : '유효 기간'}</GreyText>
                </InfoTypeContainer>
                <InfoDataContainer>{TypeCheck ? data?.date : data?.schedule}</InfoDataContainer>
              </Styler>
              
              {
                data?.type == '정기 레슨' ?
                  <Styler flex={true} pb={6}>
                    <InfoTypeContainer>
                      <SmallIcon src={ReservationSchedule} />
                      <GreyText>{`이용 시간대`}</GreyText>
                    </InfoTypeContainer>
                    <InfoDataContainer>{data?.schedule_time}</InfoDataContainer>
                  </Styler>
                : <></>
              }

              <Styler flex={true} pb={6}>
                <InfoTypeContainer>

                  {TypeCheck ?
                    <SmallIcon src={ReservationPeople} />
                  : 
                    <Dashboard 
                      width={`16px`} 
                      height={`16px`} 
                      style={{
                        marginRight: 4
                    }} fill={colors.Gr04} />
                  }
                
                  <GreyText>{TypeCheck ? '예약 인원' : CategoryCheck[data?.type]}</GreyText>
                </InfoTypeContainer>
                <InfoDataContainer>{TypeCheck ? `${data?.people}인` : `${data?.people}${UseCheck[data?.type]}`}</InfoDataContainer>
              </Styler>

              <Styler flex={true}>
                <InfoTypeContainer>
                  <SmallIcon src={ReservationPeople} />
                  <GreyText>{`회원명`}</GreyText>
                  </InfoTypeContainer>
                <InfoDataContainer>{`${data?.user_name} (${data?.user_real_name})`}</InfoDataContainer>
              </Styler>

            </InfoBox>
          </Styler>
        </Styler>
      </PaymentItem>
      {!data.semos_only && (
        <Styler flex={true} justify="space-between" pr={24} pl={24}>
          <RejectText onClick={() => setOpenCancelModal(true)}>예약거절</RejectText>
          {/* <RejectText onClick={() => activity("cancel")}>예약거절</RejectText> */}
          <ConfirmButton onClick={() => setBottom(true)}>예약확정</ConfirmButton>
        </Styler>
      )}

      <CancelModal openCancelModal={openCancelModal} setOpenCancelModal={setOpenCancelModal} id={data.number} />

      <BackButtonModal
        style={{ width: "100%", display: "flex", justifyContent: "center", left: "50%", transform: "translate(-50%,0)" }}
        isOpen={bottom}
        snapPoints={[snap]}
      >
        <BackButtonModal.Container style={{ width: `${W}px`, left: "none" }}>
          <BackModalLayout>
            <ModalHeader>바로 예약확정 하시겠어요?</ModalHeader>
            <Divider h={9} />
            <ModalText>아직 상세내역을 확인하지 않았어요!</ModalText>
            <Divider h={36} />
            <ButtonContainer>
              <StayButton onClick={() => setBottom(false)}>취소</StayButton>
              <BackButton onClick={() => activity("confirm")}>예약확정</BackButton>
            </ButtonContainer>
          </BackModalLayout>
        </BackButtonModal.Container>
        <BackButtonModal.Backdrop />
      </BackButtonModal>
    </Wrap>
  );
}

export default PaymentFinish;

const Wrap = styled.div`
  padding-bottom: 12px;
  border-bottom: 1px solid ${colors.Gr01};
`;

const PaymentItem = styled.div`
  cursor: pointer;
`;

const Styler = styled.div`
  position: relative;
  display: ${({ flex }) => (flex ? "flex" : "")};
  flex-direction: ${({ direction }) => direction};
  justify-content: ${({ justify }) => justify};
  padding-top: ${({ pt }) => pt}px;
  padding-bottom: ${({ pb }) => pb}px;
  padding-left: ${({ pl }) => pl}px;
  padding-right: ${({ pr }) => pr}px;
  height: ${({ h }) => h}px;
`;

const ActivityScheduleContainer = styled.div`
  margin-bottom: 6px;
`;

const SmallIcon = styled.img`
  width: 16px;
  height: 16px;
  margin-right: 4px;
`;

const GreyText = styled.div`
  font-weight: 500;
  font-size: 12px;
  line-height: 142%;
  display: flex;
  align-items: center;
  letter-spacing: -0.02em;
  color: ${colors.Gr04};
`;

const ActivityName = styled.div`
  font-weight: 600;
  font-size: 16px;
  line-height: 142%;
  letter-spacing: -0.02em;
  color: ${colors.Gr05};
`;

const MenuIcon = styled.img`
  width: 28px;
  height: 28px;
  margin-bottom: 5px;
`;

const InfoBox = styled.div`
  border: 1px solid ${colors.Gr02};
  padding: 12px;
  border-radius: 8px;
`;

const InfoTypeContainer = styled.div`
  width: 31%;
  display: flex;
`;

const InfoDataContainer = styled.div`
  font-weight: 500;
  font-size: 12px;
  line-height: 142%;
  display: flex;
  align-items: center;
  letter-spacing: -0.02em;
  color: ${colors.Gr05};
`;

const Dye = styled.span`
  color: ${({ color }) => color};
`;

const Seperator = styled.span`
  font-size: 10px;
`;

const RejectText = styled.div`
  cursor: pointer;
  font-weight: 600;
  font-size: 12px;
  line-height: 142%;
  display: flex;
  align-items: center;
  letter-spacing: -0.02em;
  color: ${colors.Red03};
`;

const ConfirmButton = styled.div`
  cursor: pointer;
  padding: 10.5px 25.5px;
  font-weight: 600;
  font-size: 12px;
  line-height: 142%;
  display: flex;
  align-items: center;
  text-align: right;
  letter-spacing: -0.02em;
  color: ${colors.White};
  background-color: ${colors.Bl07};
  border-radius: 8px;
`;

const BackButtonModal = styled(Sheet)``;

const BackModalLayout = styled.div`
  padding: 36px 24px 20px 24px;
`;

const ModalHeader = styled.div`
  font-weight: 600;
  font-size: 18px;
  line-height: 142%;
  letter-spacing: -0.02em;
  color: ${colors.Gr06};
`;

const ModalText = styled.div`
  font-weight: 500;
  font-size: 14px;
  line-height: 142%;
  /* or 20px */

  letter-spacing: -0.02em;

  /* Greyscale/Gr 02 */

  color: ${colors.Gr04};
`;

const Divider = styled.div`
  height: ${({ h }) => h}px;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Button = styled.div`
  font-weight: 500;
  font-size: 14px;
  line-height: 142%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  letter-spacing: -0.02em;
  width: 48%;
  padding: 18px 0;
  border-radius: 8px;
`;

const BackButton = styled(Button)`
  color: ${colors.White};
  width: 55%;
  background-color: ${colors.Bl07};
`;

const StayButton = styled(Button)`
  color: ${colors.Bl07};
  width: 40%;
  background-color: ${colors.White};
`;
