import React, { useEffect, useState } from "react";
import styled from "styled-components";

import { Text } from "../../components/layouts/layoutStorage";
import { setImageFromFile, CropImage } from "../../tool/ImageCrop";

import { W } from "../../styles/globalStyles";
import colors from "../../styles/colors";

import { ReactComponent as Camera } from "../../resource/icon/camera.svg";
import { ReactComponent as CameraEdit } from "../../resource/icon/camera_edit.svg";

const ImageUploader = ({
  type,
  STEP_LIST,
  step,
  setStep,
  data,
  setData,
  setNext,
  valid,
  setValid,
}) => {
  const [cropModal, setCropModal] = useState(false);
  const [cropImageSetting, setCropImageSetting] = useState({
    image: "",
    type: "",
    width: 0,
    height: 0,
  });
  const [imageName, setImageName] = useState("");

  useEffect(() => {
    if (data[type]) {
      setValid({ ...valid, [type]: true });
      setNext(true);
    } else {
      setValid({ ...valid, [type]: false });
      setNext(false);
    }
  }, []);

  const addImage = (file) => {
    const copy = file;
    setData({ ...data, [type]: copy });
    setValid({ ...valid, [type]: true });
    setNext(true);
  };

  const handleSkip = () => {
    setValid({ ...valid, [type]: true });
    const arr = Object.keys(STEP_LIST);
    const index = arr.indexOf(arr.find((val) => val === step.name));
    if (index === 5) {
      setStep({ name: arr[index + 3], index: index + 3 });
    } else {
      setStep({ name: arr[index + 1], index: index + 1 });
    }
    setNext(false);
  };

  return (
    <Container>
      <ImageLabelBox htmlFor={type}>
        {data[type] !== "" ? <BackGroundImg src={data[type]} /> : <></>}
        <AddButton>
          {data[type] ? (
            <CameraEdit width="32px" height="32px" fill={colors.White} />
          ) : (
            <Camera width="32px" height="32px" fill={colors.Gr04} />
          )}
          <Text
            size="12px"
            weight="500"
            lh="20px"
            ls="-0.24px"
            color={data[type] ? colors.White : colors.Gr04}
          >
            {data[type] ? imageName : "이미지 불러오기"}
          </Text>
          <ImageInput
            type="file"
            id={type}
            accept="image/*"
            onChange={({ target: { files } }) => {
              setImageName(files[0].name);
              if (files.length) {
                setImageFromFile({
                  file: files[0],
                  setImageUrl: ({ result }) => {
                    setCropImageSetting({
                      image: result,
                      width: 2,
                      height: 1,
                      type: type,
                    });
                    setCropModal(true);
                  },
                });
              } else {
                alert("사진은 1장만 업로드 할 수 있습니다.");
              }
            }}
          />
        </AddButton>
      </ImageLabelBox>
      <SkipButton onClick={() => handleSkip()}>
        <span>나중에 등록할게요</span>
      </SkipButton>

      <CropImage
        open={cropModal}
        setOpen={setCropModal}
        setting={cropImageSetting}
        setSetting={setCropImageSetting}
        setImageFunc={addImage}
      />
    </Container>
  );
};

export default ImageUploader;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 16px;
`;

const ImageLabelBox = styled.label`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: calc(${W}px - 48px);
  height: calc((${W}px - 48px) / 2);
  background-color: ${colors.White};
  border: 1px solid ${colors.Gr02};
  border-radius: 8px;
  position: relative;
  cursor: pointer;
`;

const AddButton = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 11;
`;

const ImageInput = styled.input`
  display: none;
`;

const SkipButton = styled.button`
  padding: 8px 12px;
  span {
    color: ${colors.Gr04};
    text-decoration-line: underline;
    line-height: 22px;
    font-size: 14px;
    font-weight: 600;
  }
`;

const BackGroundImg = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 8px;
  position: absolute;
  top: 0;
  left: 0;
`;
