import React, { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";

// style
import colors from "../../styles/colors";
import { Wrapper, Text, Icon } from "../layouts/layoutStorage";

//icon

const FacilityCrew = ({ data }) => {
    
    const navigate = useNavigate();

    const noticeRef = useRef();

    return (
        <Wrapper 
            ref={noticeRef}
            wrap={`wrap`} 
            width="100%" 
            ai="flex-start" 
            bb={`1px solid ${colors.Gr02}`}
            padding="7px 24px"
            onClick={() => {
                navigate(`/notice_detail/${data.id}`)
            }}
        >   
            <Wrapper 
                width={`8rem`}
                height={`8rem`}
                ai={`center`}
                jc={`center`}
                margin={`0 .5rem 0 0`}
                border={`.1rem solid ${colors.Gr02}`}
                radius={`5px`}
                style={{
                    overflow : "hidden"
                }}
            >
                <Icon 
                    src={`${data?.image}`}
                    width={`8rem`}
                />
            </Wrapper>

            <Wrapper
                style={{
                    flexDirection : "column"
                }}
                width={`calc(100% - 8.5rem)`}
            >
                <Wrapper
                    width="100%" 
                    wrap={`nowrap`} 
                    ai="center" 
                    jc="space-between" 
                    margin={`0 0 .8rem 0`}
                >
                    <Text 
                        size="2rem" 
                        weight="600" 
                        lh="22px" 
                        ls="-0.28px" 
                        width={`100%`}
                        color={colors.Gr05}
                        to={`ellipsis`}
                        style={{
                            textDecoration : "underline",
                            cursor : 'pointer',
                            display : "inline-block"
                        }}

                    >   
                        {
                            `${data?.name}`
                        }
                    </Text>
                </Wrapper>

                <Wrapper
                    width="100%" 
                    wrap={`nowrap`} 
                    ai="center" 
                    jc="space-between" 
                >
                    <Text 
                        size="1.6rem" 
                        weight="600" 
                        lh="22px" 
                        ls="-0.28px" 
                        width={`100%`}
                        color={colors.Gr04}
                        ellipsis={true}
                        clamp={3}
                    >   
                        {
                            `${data?.description}`
                        }
                    </Text>
                </Wrapper>
                
            </Wrapper>



        </Wrapper>
    )
}

export default FacilityCrew;