import React from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";

// style
import colors from "../../styles/colors";

// img
import ReservationSchedule from "../../icon/reservationSchedule.png";
import ReservationPeople from "../../icon/reservationPeople.png";
import DefaultConfirm from "../../icon/activityConfirmDefault.png";
import DefaultCancel from "../../icon/cancelDefault.png";
import { ReactComponent as Dashboard } from "../../icon/dashboard.svg";

function ActivityCancel({ data }) {
  const navigate = useNavigate();

  let TypeCheck = (data?.type != '정기 레슨' && data?.type != '이용권(기간)' && data?.type != '이용권(횟수)');
  const UseCheck = {
    '정기 레슨' : "개월",
    '이용권(기간)' : "개월",
    '이용권(횟수)' : "회"
  };
  const CategoryCheck = {
    '정기 레슨' : "이용 기간",
    '이용권(기간)' : "이용 기간",
    '이용권(횟수)' : "이용 횟수"
  };

  return (
    <PaymentItem
      onClick={() =>
        navigate(`/reservation_detail/${data.number}`, {
          state: {
            from: "",
          },
        })
      }
    >
      <Styler pl={24} pr={24} pt={20}>

        <ActivityName>{data.title && data.title}</ActivityName>
        <Styler pt={8} pb={15.5}>
          <InfoBox>
            <Styler flex={true} pb={6}>
              <InfoTypeContainer>
                <SmallIcon src={DefaultConfirm} />
                <GreyText>
                  <Dye color={colors.Gr04}>스포츠</Dye>&nbsp;
                  <Seperator>|</Seperator>&nbsp;유형
                </GreyText>
              </InfoTypeContainer>
              <InfoDataContainer>
                <Dye color={colors.Gr04}>{data.sport && data.sport}</Dye>
                  {
                    TypeCheck ?
                      <Dye color={colors.Gr03}>
                        {data.duration && (
                          <>
                            <Seperator>&nbsp;&nbsp;|&nbsp;&nbsp;</Seperator>
                            {data.duration}
                          </>
                        )}
                        <Seperator>&nbsp;&nbsp;|&nbsp;&nbsp;</Seperator>
                        {data.type && data.type}
                      </Dye>
                    :
                    <Dye color={colors.Gr03}>
                      <Seperator>&nbsp;&nbsp;|&nbsp;&nbsp;</Seperator>
                      {data.type && data.type}
                    </Dye>
                  }
              </InfoDataContainer>
            </Styler>

            <Styler flex={true} pb={6}>
              <InfoTypeContainer>
                <SmallIcon src={ReservationSchedule} />
                <GreyText>{TypeCheck ? '예약 일정' : '유효 기간'}</GreyText>
              </InfoTypeContainer>
              <InfoDataContainer>{TypeCheck ? data?.date : data?.schedule}</InfoDataContainer>
            </Styler>

              {
                data?.type == '정기 레슨' ?
                  <Styler flex={true} pb={6}>
                    <InfoTypeContainer>
                      <SmallIcon src={ReservationSchedule} />
                      <GreyText>{`이용 시간대`}</GreyText>
                    </InfoTypeContainer>
                    <InfoDataContainer>{data?.schedule_time}</InfoDataContainer>
                  </Styler>
                : <></>
              }

            <Styler flex={true} pb={6}>
              <InfoTypeContainer>
                {TypeCheck ?
                    <SmallIcon src={ReservationPeople} />
                  : 
                    <Dashboard 
                      width={`16px`} 
                      height={`16px`} 
                      style={{
                        marginRight: 4
                    }} fill={colors.Gr03} />
                  }
                <GreyText>{TypeCheck ? '예약 인원' : CategoryCheck[data?.type]}</GreyText>
              </InfoTypeContainer>
              <InfoDataContainer>
                {TypeCheck ? `${data?.people}인` : `${data?.people}${UseCheck[data?.type]}`}
              </InfoDataContainer>
            </Styler>

            <Styler flex={true} pb={6}>
                <InfoTypeContainer>
                  <SmallIcon src={ReservationPeople} />
                  <GreyText>{`회원명`}</GreyText>
                  </InfoTypeContainer>
                <InfoDataContainer>{`${data?.user_name} (${data?.user_real_name})`}</InfoDataContainer>
              </Styler>

            <Styler flex={true}>
              <InfoTypeContainer>
                <SmallIcon src={DefaultCancel} />
                <GreyText>{data.refund_type} 취소</GreyText>
              </InfoTypeContainer>
              <InfoDataContainer>{data.refund}</InfoDataContainer>
            </Styler>
          </InfoBox>
        </Styler>
        <Styler flex={true} justify="flex-end">
          <RejectText>취소완료</RejectText>
        </Styler>
      </Styler>
    </PaymentItem>
  );
}

export default ActivityCancel;

const PaymentItem = styled.div`
  padding-bottom: 16.5px;
  border-bottom: 1px solid ${colors.Gr01};
  cursor: pointer;
`;

const Styler = styled.div`
  display: ${({ flex }) => (flex ? "flex" : "")};
  flex-direction: ${({ direction }) => direction};
  justify-content: ${({ justify }) => justify};
  height: ${({ h }) => h}px;
  padding-top: ${({ pt }) => pt}px;
  padding-right: ${({ pr }) => pr}px;
  padding-bottom: ${({ pb }) => pb}px;
  padding-left: ${({ pl }) => pl}px;
  position: relative;
`;

const ActivityScheduleContainer = styled.div`
  margin-bottom: 6px;
`;

const SmallIcon = styled.img`
  width: 16px;
  height: 16px;
  margin-right: 4px;
  opacity: 0.6;
`;

const GreyText = styled.div`
  display: flex;
  align-items: center;
  color: ${colors.Gr03};
  line-height: 142%;
  letter-spacing: -0.02em;
  font-size: 12px;
  font-weight: 500;
`;

const ActivityName = styled.div`
  font-weight: 600;
  font-size: 16px;
  line-height: 142%;
  letter-spacing: -0.02em;
  color: ${colors.Gr04};
`;

const MenuIcon = styled.img`
  width: 28px;
  height: 28px;
  margin-bottom: 5px;
`;

const InfoBox = styled.div`
  padding: 12px;
  background-color: ${colors.Gr01};
  border: 1px solid ${colors.Gr02};
  border-radius: 8px;
`;

const InfoTypeContainer = styled.div`
  display: flex;
  width: 31%;
`;

const InfoDataContainer = styled.div`
  display: flex;
  align-items: center;
  color: ${colors.Gr04};
  line-height: 142%;
  letter-spacing: -0.02em;
  font-size: 12px;
  font-weight: 500;
`;

const Dye = styled.span`
  color: ${({ color }) => color};
`;

const Seperator = styled.span`
  font-size: 10px;
`;

const RejectText = styled.div`
  display: flex;
  align-items: center;
  color: ${colors.Gr05};
  line-height: 142%;
  letter-spacing: -0.02em;
  font-size: 12px;
  font-weight: 600;
`;
