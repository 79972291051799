import axios from "axios";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { useGoogleLogin } from "@react-oauth/google";

// tool
import { IPad, Android } from "./device_check";
import { SaveMany } from "./localstorage";
import { fbevent } from "./firebase";

import AppleLogo from "../icon/appleLogo.png";

const H = window.innerHeight;
const DesignSize = H / 812;

export function kakaoLoginHandler() {
  const { Kakao } = window;

  const thisLink = window.location.href.split("/")[2];
  const AC = Android();
  const PC = IPad();

  fbevent("B2B_Login_Kakao_Click", {});

  if (AC) {
    Kakao.Auth.authorize({
      redirectUri: `https://${thisLink}/login_check`,
      throughTalk: true,
      prompts: "login",
    });
  } else {
    if (PC) {
      Kakao.Auth.authorize({
        redirectUri: `https://${thisLink}/login_check`,
      });
    } else {
      Kakao.Auth.authorize({
        redirectUri: `https://${thisLink}/login_check`,
        throughTalk: true,
        prompts: "login",
      });
    }
  }
}

export function KakaoLoginAxios(current, navigate) {
  const search = current.split("?")[1];
  const params = new URLSearchParams(search);
  const kakao_token = params.get("code");
  const thisLink = window.location.href.split("/")[2];

  const frm = new FormData();
  frm.append("mode", "kakao_login");
  frm.append("kakao_code", kakao_token);
  frm.append("url", thisLink);

  axios
    .post("https://ai.semos.kr/semos_partner/v3/profile/Login", frm, {
      headers: { "Content-Type": "multipart/form-data" },
    })
    .then((data) => {
      const Value = data.data;
      const Status = Value.login_status;
      const Token = Value.login_token;
      const UserData = Value.user_data;

      if (Status === "success") {
        const status = UserData.partner_status ? UserData.partner_status : null;
        const nickname = UserData.user_name;
        const name = UserData.user_real_name;
        const age = UserData.user_age;
        const profile = UserData.profile_img;
        const phone = UserData.user_phone_number;
        const gender = UserData.user_gender;
        const email = UserData.user_email;

        const LocalStorageData = [
          { type: "partner_status", value: status },
          { type: "nickname", value: nickname },
          { type: "name", value: name },
          { type: "age", value: age },
          { type: "profile", value: profile },
          { type: "phone", value: phone },
          { type: "gender", value: gender },
          { type: "email", value: email },
          { type: "token", value: Token },
          { type: "login_type", value: "카카오" },
        ];

        if (SaveMany(LocalStorageData)) {
          navigate("/home");
        }
      } else if (Status === "un_partner") {
        const status = UserData.partner_status ? UserData.partner_status : null;
        const nickname = UserData.user_name;
        const name = UserData.user_real_name;
        const age = UserData.user_age;
        const profile = UserData.profile_img;
        const phone = UserData.user_phone_number;
        const gender = UserData.user_gender;
        const email = UserData.user_email;

        const LocalStorageData = [
          { type: "partner_status", value: status },
          { type: "nickname", value: nickname },
          { type: "name", value: name },
          { type: "age", value: age },
          { type: "profile", value: profile },
          { type: "phone", value: phone },
          { type: "gender", value: gender },
          { type: "email", value: email },
          { type: "token", value: Token },
          { type: "login_type", value: "카카오" },
        ];

        if (SaveMany(LocalStorageData)) {
          navigate("/partner_register");
          //navigate("/partner_register_new/0", {replace: true});
        }

        /*window.alert('미등록된 파트너입니다.\n등록 상담 신청을 통해 파트너 등록을 완료해주세요!')
        window.location.href = 'https://forms.gle/mpMXYxmcxyXetueB8'*/
      } else if (Status === "unready") {
        const StatusMessage = Value.status_message;
        window.alert(StatusMessage);
        navigate("/");
      } else if (Status === "other_user") {
        window.alert("동일한 이메일로 가입된 계정이 있습니다.\n해당 아이디로 로그인해주세요!");
        navigate("/");
      } else {
        window.alert("로그인이 실패했습니다.\n로그인 실패가 반복된다면 고객센터로 문의해주세요!");
        navigate("/");
      }
    });
}

export function AppleLoginHandler(apple) {
  const navigate = useNavigate();

  if (!apple) {
    const script = document.createElement("script");
    script.async = true;
    script.src = "https://appleid.cdn-apple.com/appleauth/static/jsapi/appleid/1/ko_KR/appleid.auth.js";
    document.head.appendChild(script);
    const thisLink = window.location.href.split("/")[2];

    script.onload = () => {
      window.AppleID.auth.init({
        clientId: "com.reports.semosL",
        scope: "name email",
        redirectURI: `https://${thisLink}/home`,
        state: "report_osi",
        usePopup: true, //or false defaults to false
      });

      document.addEventListener("AppleIDSignInOnSuccess", (data) => {
        const apple_token = data.detail.authorization.id_token;
        const apple_first = data.detail.user;

        fbevent("B2B_Login_Apple_Click", {});

        const frm = new FormData();

        if (apple_first) {
          const user_firstName = data.detail.user.name.firstName;
          const user_lastName = data.detail.user.name.lastName;
          const user_name = user_lastName + user_firstName;
          const user_email = data.detail.user.email;

          frm.append("mode", "apple_login");
          frm.append("apple_code", apple_token);
          frm.append("user_name", user_name);
          frm.append("user_email", user_email);
        } else {
          frm.append("mode", "apple_login");
          frm.append("apple_code", apple_token);
        }

        axios
          .post("https://ai.semos.kr/semos_partner/v3/profile/Login", frm, {
            headers: { "Content-Type": "multipart/form-data" },
          })
          .then((data) => {
            const Value = data.data;
            const Status = Value.login_status;
            const Token = Value.login_token;
            const UserData = Value.user_data;

            if (Status === "success") {
              const status = UserData.partner_status ? UserData.partner_status : null;
              const nickname = UserData.user_name ? UserData.user_name : "가입미완";
              const name = UserData.user_real_name ? UserData.user_real_name : "가입미완";
              const age = UserData.user_age;
              const profile = UserData.profile_img;
              const phone = UserData.user_phone_number;
              const gender = UserData.user_gender;
              const email = UserData.user_email;

              const LocalStorageData = [
                { type: "partner_status", value: status },
                { type: "nickname", value: nickname },
                { type: "name", value: name },
                { type: "age", value: age },
                { type: "profile", value: profile },
                { type: "phone", value: phone },
                { type: "gender", value: gender },
                { type: "email", value: email },
                { type: "token", value: Token },
                { type: "login_type", value: "애플" },
              ];

              if (SaveMany(LocalStorageData)) {
                navigate("/home");
              }
            } else if (Status === "un_partner") {
              const status = UserData.partner_status;
              const nickname = UserData.user_name ? UserData.user_name : "가입미완";
              const name = UserData.user_real_name ? UserData.user_real_name : "가입미완";
              const age = UserData.user_age;
              const profile = UserData.profile_img;
              const phone = UserData.user_phone_number;
              const gender = UserData.user_gender;
              const email = UserData.user_email;

              const LocalStorageData = [
                { type: "partner_status", value: status },
                { type: "nickname", value: nickname },
                { type: "name", value: name },
                { type: "age", value: age },
                { type: "profile", value: profile },
                { type: "phone", value: phone },
                { type: "gender", value: gender },
                { type: "email", value: email },
                { type: "token", value: Token },
                { type: "login_type", value: "애플" },
              ];

              if (SaveMany(LocalStorageData)) {
                navigate("/partner_register");
                //navigate("/partner_register_new/0", {replace: true});
              }
              /*window.alert('미등록된 파트너입니다.\n등록 상담 신청을 통해 파트너 등록을 완료해주세요!')
              window.location.href = 'https://forms.gle/mpMXYxmcxyXetueB8'*/
            } else if (Status === "unready") {
              const StatusMessage = Value.status_message;
              window.alert(StatusMessage);
              navigate("/");
            }  else if (Status === "other_user") {
              window.alert("동일한 이메일로 가입된 계정이 있습니다.\n해당 아이디로 로그인해주세요!");
              navigate("/");
            } else {
              window.alert("로그인이 실패했습니다.\n로그인 실패가 반복된다면 고객센터로 문의해주세요!");
              navigate("/");
            }
          });
      });
    };
  }

  return (
    <Label className="Real" htmlFor={`appleid-signin`}>
      <AppleButton>
        <LoginImg src={AppleLogo} />
        <input 
          style={{display : 'none'}} 
          type={`button`} 
          id="appleid-signin"
          data-mode="logo-only"
          data-color="black"
          data-border="false"
          data-border-radius="50"
          data-size={52 * DesignSize}
          language="Korean" 
        />
      </AppleButton>
    </Label>

  );
}

const Button = styled.div`
  width: ${52 * DesignSize}px;
  height: ${52 * DesignSize}px;
  padding: 6px 0;
  border-radius: 50%;
  line-height: 22.72px;
  font-weight: 500;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

const AppleButton = styled(Button)`
  background-color: #000000;
  color: white;
`;

const LoginImg = styled.img`
  width: ${28 * DesignSize}px;
  height: ${28 * DesignSize}px;
`;

const Label = styled.label`
    display : inline-flex;
`;