import React, { useEffect, useState } from "react";
import styled from "styled-components";

import colors from "../../styles/colors";

import { ReactComponent as CheckCircle } from "../../resource/icon/check_circle.svg";
import PartnerRuleInformation from "../signup/PartnerRuleInformation";
import PartnerRuleService from "../signup/PartnerRuleService";
import PartnerRuleAdvertisement from "../signup/PartnerRuleAdvertisement";

const TERMS_LIST = [
  { name: "개인정보 수집・이용 동의서 (필수)", value: "information" },
  { name: "서비스 이용약관 (필수)", value: "service" },
  {
    name: "광고성 정보 수신 동의에 대한 약관 (선택)",
    value: "advertise",
  },
];

const TermsForm = ({ STEP_LIST, step, setStep, data, setData, setNext }) => {
  const [terms, setTerms] = useState({
    information: false,
    service: false,
    advertise: false,
  });
  const [openTerms, setOpenTerms] = useState({
    information: false,
    service: false,
    advertise: false,
  });

  useEffect(() => {
    terms.information && terms.service ? setNext(true) : setNext(false);
    if (terms.advertise) {
      setData({ ...data, partnerPush: 1 });
    } else {
      setData({ ...data, partnerPush: 0 });
    }
  }, [terms]);

  const handleOpenTerms = (target) =>
    setOpenTerms({ ...openTerms, [target]: !openTerms[target] });

  const handleAllClick = () => {
    if (terms.information && terms.service && terms.advertise) {
      setTerms({ information: false, service: false, advertise: false });
    } else {
      setTerms({ information: true, service: true, advertise: true });
    }
  };

  const handleClick = (value) => setTerms({ ...terms, [value]: !terms[value] });

  return (
    <div>
      <RegisterTitle>{STEP_LIST[step.name].title}</RegisterTitle>
      <TermsContainer>
        <TermsAllSelect>
          <span>약관 전체 동의</span>
          <button onClick={() => handleAllClick()}>
            <CheckCircle
              fill={
                terms.information && terms.service && terms.advertise
                  ? colors.Bl07
                  : colors.Gr03
              }
            />
          </button>
        </TermsAllSelect>
        <TermsList>
          {TERMS_LIST.map((val) => {
            const { name, value } = val;
            return (
              <li key={value}>
                <span onClick={() => handleOpenTerms(value)}>{name}</span>
                <button onClick={() => handleClick(value)}>
                  <CheckCircle
                    fill={terms[value] ? colors.Bl07 : colors.Gr03}
                  />
                </button>
              </li>
            );
          })}
        </TermsList>
        <TermsSubText>
          동의시 할인 혜택 및 세모스 소식을 가장 먼저 받을 수 있어요
        </TermsSubText>
      </TermsContainer>

      {openTerms.information && (
        <PartnerRuleInformation
          setRule={() => handleOpenTerms("information")}
        />
      )}
      {openTerms.service && (
        <PartnerRuleService setRule={() => handleOpenTerms("service")} />
      )}
      {openTerms.advertise && (
        <PartnerRuleAdvertisement
          setRule={() => handleOpenTerms("advertise")}
        />
      )}
    </div>
  );
};

export default TermsForm;

const Container = styled.div``;

const RegisterTitle = styled.h2`
  padding: 32px 0 8px 0;
  color: ${colors.Gr06};
  white-space: pre-line;
  line-height: 26px;
  letter-spacing: -0.36px;
  font-size: 18px;
  font-weight: 700;
`;

const TermsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const TermsAllSelect = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 8px;
  padding: 8px 0px;

  span {
    color: ${colors.Gr06};
    line-height: 24px;
    font-size: 16px;
    font-weight: 600;
    letter-spacing: -0.32px;
  }

  svg {
    width: 24px;
    height: 24px;
  }
`;

const TermsList = styled.ul`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 8px;
  padding-top: 8px;
  border-top: 1px solid ${colors.Gr02};

  li {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 8px 0px;
    cursor: pointer;

    span {
      color: ${colors.Gr04};
      text-decoration-line: underline;
      line-height: 142%;
      font-size: 14px;
      font-weight: 600;
    }

    svg {
      width: 24px;
      height: 24px;
    }
  }
`;

const TermsSubText = styled.span`
  color: ${colors.Bl07};
  line-height: 22px;
  letter-spacing: -0.28px;
  font-size: 14px;
  font-weight: 500;
`;
