import styled from "styled-components";

// style
import colors from "../../styles/colors";

export const TableFilterContainer = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin: ${({ margin }) => margin};
`;

export const TableFilterContent = styled.div`
  display: inline-flex;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: space-between;
  width: ${({ width }) => `${width}`};
  margin: ${({ margin }) => margin};
  padding: 8px 16px;
  background-color: ${colors.White};
  border: 1px solid ${colors.Gr02};
  position: relative;
  white-space: nowrap;
  cursor: ${({ cursor }) => cursor};
`;

export const Wrapper = styled.div`
  display: ${({ display }) => `${display ? display : `inline-flex`}`};
  grid-template-columns: ${({ gt }) => `${gt}`};
  flex-direction: ${({ fd }) => `${fd}`};
  flex-wrap: ${({ wrap }) => `${wrap}`};
  align-items: ${({ ai }) => (ai ? ai : "center")};
  justify-content: ${({ jc }) => jc};
  gap: ${({ gap }) => `${gap}`};
  width: ${({ width }) => `${width}`};
  height: ${({ height }) => `${height}`};
  ${({ mh }) => mh && `max-height :${mh};`}
  margin: ${({ margin }) => margin};
  padding: ${({ padding }) => `${padding}`};
  background-color: ${({ bgc }) => `${bgc}`};
  border: ${({ border }) => `${border}`};
  border-top: ${({ bt }) => `${bt}`};
  border-right: ${({ br }) => `${br}`};
  border-bottom: ${({ bb }) => `${bb}`};
  border-left: ${({ bl }) => `${bl}`};
  border-radius: ${({ radius }) => `${radius}`};
  position: relative;
  position: ${({ position }) => `${position}`};
  ${({ transition }) => transition && `transition :${transition};`}
  ${({ of }) => of && `overflow :${of};`}
  ${({ ws }) => ws && `white-space :${ws};`}
  cursor: ${({ cursor }) => `${cursor}`};

  :hover {
    background-color: ${({ hvBgc }) => `${hvBgc}`};
  }
  :active {
    background-color: ${({ atBgc }) => `${atBgc}`};
  }

  #searchscroll {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }

  #searchscroll::-webkit-scrollbar {
    display: none; /* Chrome, Safari, Opera*/
  }
`;

export const Label = styled.label`
  display: ${({ display }) => `${display ? display : `inline-flex`}`};
  flex-wrap: ${({ wrap }) => `${wrap}`};
  align-items: center;
  justify-content: ${({ jc }) => jc};
  width: ${({ width }) => `${width}`};
  height: ${({ height }) => `${height}`};
  ${({ mh }) => mh && `max-height :${mh};`}
  margin: ${({ margin }) => margin};
  padding: ${({ padding }) => `${padding}`};
  background-color: ${({ bgc }) => `${bgc}`};
  border-top: ${({ bt }) => `${bt}`};
  border-right: ${({ br }) => `${br}`};
  border-bottom: ${({ bb }) => `${bb}`};
  border-left: ${({ bl }) => `${bl}`};
  border: ${({ border }) => `${border}`};
  border-radius: ${({ radius }) => `${radius}`};
  position: ${({ position }) => `${position}`};
  cursor: ${({ cursor }) => `${cursor}`};
  ${({ transition }) => transition && `transition :${transition};`}
  ${({ of }) => of && `overflow :${of};`}

  input {
    display: none;
    width: 0;
    height: 0;
  }

  img {
    display: inline-block;
    width: 100%;
  }
`;

export const WrapperRow = styled.div`
  display: ${({ display }) => `${display ? display : `inline-flex`}`};
  flex-direction: row;
  flex-wrap: ${({ wrap }) => `${wrap}`};
  align-items: ${({ ai }) => ai};
  justify-content: ${({ jc }) => jc};
  gap: ${({ gap }) => `${gap}`};
  width: ${({ width }) => `${width}`};
  height: ${({ height }) => `${height}`};
  background-color: ${({ bgc }) => `${bgc}`};
  margin: ${({ margin }) => margin};
  padding: ${({ padding }) => `${padding}`};
  border-top: ${({ bt }) => `${bt}`};
  border-right: ${({ br }) => `${br}`};
  border-bottom: ${({ bb }) => `${bb}`};
  border-left: ${({ bl }) => `${bl}`};
  border: ${({ border }) => `${border}`};
  border-radius: ${({ radius }) => `${radius}`};
  position: ${({ position }) => `${position}`};
  ${({ of }) => of && `overflow :${of};`}
  cursor: ${({ cursor }) => `${cursor}`};
`;

export const WrapperColumn = styled.div`
  display: ${({ display }) => `${display ? display : `inline-flex`}`};
  flex-direction: column;
  flex-wrap: ${({ wrap }) => `${wrap}`};
  align-items: ${({ ai }) => ai};
  justify-content: ${({ jc }) => jc};
  gap: ${({ gap }) => `${gap}`};
  width: ${({ width }) => `${width}`};
  height: ${({ height }) => `${height}`};
  margin: ${({ margin }) => margin};
  padding: ${({ padding }) => `${padding}`};
  background-color: ${({ bgc }) => `${bgc}`};
  border-top: ${({ bt }) => `${bt}`};
  border-right: ${({ br }) => `${br}`};
  border-bottom: ${({ bb }) => `${bb}`};
  border-left: ${({ bl }) => `${bl}`};
  border: ${({ border }) => `${border}`};
  border-radius: ${({ radius }) => `${radius}`};
  position: ${({ position }) => `${position}`};
  ${({ of }) => of && `overflow :${of};`}
  cursor: ${({ cursor }) => `${cursor}`};

  :hover {
    background-color: ${({ hvBgc }) => `${hvBgc}`};
    border: ${({ hvBorder }) => `${hvBorder}`};
  }
`;

export const AbsoluteWrapper = styled.div`
  display: ${({ display }) => `${display ? display : `inline-flex`}`};
  flex-direction: ${({ fd }) => `${fd}`};
  flex-wrap: ${({ wrap }) => `${wrap}`};
  align-items: ${({ ai }) => (ai ? ai : `center`)};
  justify-content: ${({ jc }) => jc};
  width: ${({ width }) => `${width}`};
  height: ${({ height }) => `${height}`};
  max-height: ${({ maxHeight }) => `${maxHeight}`};
  padding: ${({ padding }) => `${padding}`};
  background-color: ${({ bgc }) => `${bgc}`};
  border-bottom: ${({ bb }) => `${bb}`};
  border-right: ${({ br }) => `${br}`};
  border-left: ${({ bl }) => `${bl}`};
  border: ${({ border }) => `${border}`};
  border-radius: ${({ radius }) => `${radius}`};
  position: absolute;
  z-index: ${({ z }) => `${z}`};
  top: ${({ top }) => `${top}`};
  right: ${({ right }) => `${right}`};
  bottom: ${({ bottom }) => `${bottom}`};
  left: ${({ left }) => `${left}`};
  transform: ${({ transform }) => `${transform}`};
  transition: ${({ transition }) => `${transition}`};
  ${({ of }) => of && `overflow :${of};`}
  cursor: ${({ cursor }) => `${cursor}`};
`;

export const FixedWrapper = styled.div`
  display: ${({ display }) => `${display ? display : `inline-flex`}`};
  align-items: center;
  justify-content: ${({ jc }) => jc};
  width: ${({ width }) => `${width}`};
  height: ${({ height }) => `${height}`};
  padding: ${({ padding }) => `${padding}`};
  background-color: ${({ bgc }) => `${bgc}`};
  border-right: ${({ br }) => `${br}`};
  border-bottom: ${({ bb }) => `${bb}`};
  border-left: ${({ bl }) => `${bl}`};
  border: ${({ border }) => `${border}`};
  border-radius: ${({ radius }) => `${radius}`};
  position: fixed;
  z-index: ${({ z }) => `${z}`};
  top: ${({ top }) => `${top}`};
  right: ${({ right }) => `${right}`};
  bottom: ${({ bottom }) => `${bottom}`};
  left: ${({ left }) => `${left}`};
  transform: ${({ transform }) => `${transform}`};
  cursor: ${({ cursor }) => `${cursor}`};
`;

export const Icon = styled.img`
  display: ${({ display }) => `${display ? display : `inline-block`}`};
  width: ${({ width }) => `${width}`};
  height: ${({ height }) => `${height}`};
  margin: ${({ margin }) => margin};
  padding: ${({ padding }) => padding};
  border-radius: ${({ radius }) => radius};
  transform: ${({ transform }) => `${transform}`};
  transition: ${({ transition }) => `${transition}`};
  object-fit: ${({ of }) => of && of};
  cursor: ${({ cursor }) => cursor};
`;

export const Text = styled.span`
  display: inline-flex;
  flex-wrap: ${({ wrap }) => `${wrap}`};
  align-items: ${({ ai }) => (ai ? ai : `center`)};
  justify-content: ${({ jc }) => jc};
  width: ${({ width }) => `${width}`};
  margin: ${({ margin }) => margin};
  color: ${({ color }) => `${color}`};
  white-space: ${({ ws }) => `${ws}`};
  text-align: ${({ ta }) => `${ta}`};
  line-height: ${({ lh }) => (lh ? lh : `142%`)};
  letter-spacing: ${({ ls }) => `${ls}`};
  font-size: ${({ size }) => `${size}`};
  font-weight: ${({ weight }) => `${weight}`};
  cursor: ${({ cursor }) => cursor};
  word-break: keep-all;
  ${({ to }) => to && `
    text-overflow: ${to};
    overflow: hidden;
    white-space: nowrap;
  `}

  ${({ ellipsis, clamp }) =>
    `${
      ellipsis &&
      `
        text-overflow: ellipsis;
        overflow: hidden;
        word-break: break-word;
        display: -webkit-box;
        -webkit-line-clamp: ${clamp};
        -webkit-box-orient: vertical
      `
    }`};
`;

export const GmarketText = styled.span`
  display: inline-flex;
  flex-wrap: ${({ wrap }) => `${wrap}`};
  width: ${({ width }) => `${width}`};
  align-items: ${({ ai }) => (ai ? ai : `center`)};
  justify-content: ${({ jc }) => jc};
  margin: ${({ margin }) => margin};
  color: ${({ color }) => `${color}`};
  text-align: ${({ ta }) => `${ta}`};
  white-space: ${({ ws }) => `${ws}`};
  line-height: ${({ lh }) => (lh ? lh : `142%`)};
  letter-spacing: ${({ ls }) => `${ls}`};
  font-family: "Gmarket Sans" !important;
  font-size: ${({ size }) => `${size}`};
  font-weight: ${({ weight }) => `${weight}`};

  ${({ ellipsis }) =>
    `${
      ellipsis &&
      `text-overflow: ellipsis;
    overflow: hidden;`
    }`};
`;

export const TextArea = styled.textarea`
  display: inline-flex;
  flex-wrap: ${({ wrap }) => `${wrap}`};
  justify-content: ${({ jc }) => jc};
  font-size: ${({ size }) => `${size}`};
  width: ${({ width }) => `${width}`};
  height: ${({ height }) => `${height}`};
  margin: ${({ margin }) => margin};
  padding: ${({ padding }) => padding};
  background-color: ${({ bgc }) => `${bgc}`};
  letter-spacing: ${({ ls }) => `${ls}`};
  border: none;
  overflow: hidden auto;
  resize: none;
  outline: none;
  white-space: ${({ ws }) => `${ws}`};
  color: ${({ color }) => (color ? `${color}` : colors.Gr05)};
  text-decoration: none;
  line-height: ${({ lh }) => (lh ? lh : `142%`)};
  font-weight: ${({ weight }) => `${weight}`};

  &::placeholder {
    color: ${colors.Gr03};
  }
`;

export const PaddingText = styled.span`
  display: inline-flex;
  flex-wrap: ${({ wrap }) => `${wrap}`};
  align-items: ${({ ai }) => (ai ? ai : `center`)};
  justify-content: ${({ jc }) => jc};
  width: ${({ width }) => `${width}`};
  margin: ${({ margin }) => margin};
  padding: ${({ padding }) => `${padding}`};
  background-color: ${({ bgc }) => `${bgc}`};
  border-radius: ${({ radius }) => `${radius}`};
  color: ${({ color }) => `${color}`};
  text-align: ${({ ta }) => `${ta}`};
  line-height: ${({ lh }) => (lh ? lh : `142%`)};
  font-size: 14px;
  font-weight: ${({ weight }) => `${weight}`};
`;

export const TableContent = styled.div`
  display: inline-flex;
  flex-wrap: wrap;
  width: 100%;
  height: ${({ height }) => `${height}px`};

  section:first-child {
    display: inline-flex;
    flex-wrap: ${({ wrap }) => `${wrap}`};
    width: 100%;
    ${({ bgc }) => `background-color : ${bgc};`}
    ${({ border }) => `${border ? border : `1px solid ${colors.Gr02}`}`};
  }

  section:nth-child(2) {
    display: inline-block;
    width: 100%;
    height: ${({ height, contentHeight }) => `${height - contentHeight}px`};
    overflow: hidden auto;
  }
`;

export const TableColumn = styled.div`
  display: ${({ display }) => `${display ? display : `inline-block`}`};
  justify-content: ${({ jc }) => `${jc}`};
  width: ${({ width }) => `calc( 1% * ${width} )`};
  padding: ${({ padding }) => `${padding ? padding : `13px 16px`}`};
  border-right: ${({ br }) => `${br}`};
  color: ${({ color }) => (color ? color : `${colors.Gr05}`)};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: ${({ ta }) => `${ta ? ta : `center`}`};
  font-weight: ${({ weight }) => `${weight}`};

  /* &:hover {
    border-right: 1px solid ${colors.Bl07};
  } */
`;

export const TableFooter = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  padding: 20px 0;
`;

export const TableFooterPaging = styled.span`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 2.2rem;
  margin: ${({ margin }) => margin};
  padding: 0 6.5px;
  text-align: center;
  cursor: pointer;
  color: ${({ color }) => `${color}`};
  border-bottom: ${({ color }) => color == `${colors.Bl06}` && `1px solid ${colors.Bl06}`};
`;

export const TableWrapper = styled.div`
  display: ${({ display }) => `${display ? display : `inline-flex`}`};
  flex-wrap: ${({ wrap }) => `${wrap}`};
  align-items: center;
  justify-content: ${({ jc }) => jc};
  width: ${({ width }) => `${width}`};
  height: ${({ height }) => `${height}`};
  padding: ${({ padding }) => `${padding}`};
  background-color: ${({ bgc }) => `${bgc}`};
  border-top: ${({ bt }) => `${bt}`};
  border-right: ${({ br }) => `${br}`};
  border-bottom: ${({ bb }) => `${bb}`};
  border-left: ${({ bl }) => `${bl}`};
  border: ${({ border }) => `${border}`};
  border-radius: ${({ radius }) => `${radius}`};
  position: ${({ position }) => `${position}`};
  cursor: ${({ cursor }) => `${cursor}`};

  &:hover {
    border: 1px solid ${colors.Bl07};
  }
`;

export const DetailHeader = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin: ${({ margin }) => `${margin}`};
  padding: ${({ padding }) => `${padding}`};
`;

export const Devive = styled.div`
  display: inline-block;
  width: ${({ width }) => `${width}`};
  height: ${({ height }) => `${height}`};
  margin: ${({ margin }) => `${margin}`};
  background-color: ${({ bgc }) => `${bgc}`};
`;

export const Radio = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: ${({ width }) => `${width}px`};
  height: ${({ width }) => `${width}px`};
  background-color: ${colors.White};
  margin: ${({ margin }) => `${margin}`};
  border: ${({ border }) => `${border}`};
  border-radius: 50%;
  position: relative;
  cursor: ${({ cursor }) => cursor};

  div {
    width: ${({ width, padding }) => `${width - padding * 2}px`};
    height: ${({ width, padding }) => `${width - padding * 2}px`};
    background-color: ${({ bgc }) => `${bgc}`};
    border-radius: 50%;
    /*position : absolute;
    top : ${({ padding }) => `${padding}px`};
    left : ${({ padding }) => `${padding}px`};
    transform : ${({ padding }) => `translate(-${padding / 2}px, -${padding / 2}px)`};*/
  }
`;

export const Input = styled.input`
  width: ${({ width }) => `${width}`};
  border: none;
  cursor: text;
  color: ${({ color }) => `${color}`};
  white-space: ${({ ws }) => `${ws}`};
  line-height: ${({ lh }) => (lh ? lh : `142%`)};
  letter-spacing: ${({ ls }) => `${ls}`};
  text-align: ${({ ta }) => `${ta}`};
  font-size: ${({ size }) => `${size}`};
  font-weight: ${({ weight }) => `${weight}`};
  ::placeholder {
    color: ${({ phColor }) => `${phColor}`};
  }
`;
