import colors from "../styles/colors";
import styled from "styled-components";
import React, { useState, useEffect, useRef } from "react";

export const Loading = ({ description }) => {
  const [dotCount, setDotCount] = useState(0);

  const useInterval = (callback, delay) => {
    const savedCallback = useRef();
    useEffect(() => {
      savedCallback.current = callback;
    }, [callback]);

    useEffect(() => {
      function tick() {
        savedCallback.current();
      }
      if (delay !== null) {
        let id = setInterval(tick, delay);
        return () => clearInterval(id);
      }
    }, [delay]);
  };

  useInterval(() => {
    setDotCount(dotCount + 1);
  }, 500);

  return (
    <LoadingContainer>
      <Loader>
        <Sninner />
        <Description>
          <Text>
            {description ? description : "정보를 저장하고 있어요 "}
            {".".repeat((dotCount % 4) + 1)}
          </Text>
        </Description>
      </Loader>
    </LoadingContainer>
  );
};

const LoadingContainer = styled.div`
  position: fixed;
  z-index: 9999;
  top: 0;
  right: 0;
  align-items: center;
  justify-content: center;
  display: inline-flex;
  width: 100vw;
  /* height: 100vh; */
  height: calc(var(--vh, 1vh) * 100);
  background-color: ${colors.B30};
`;

const Loader = styled.div`
  display: inline-flex;
  position: relative;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
`;

const Sninner = styled.div`
  display: inline-flex;
  width: 30px;
  height: 30px;
  border-radius: 15px;
  border: 3px solid ${colors.Bl07};
  border-top-color: transparent;
  animation: spinner 0.8s linear infinite;

  @keyframes spinner {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
`;

const Description = styled.div`
  margin-top: 10px;
  display: inline-flex;
  width: 100vw;
  align-items: center;
  justify-content: center;
`;

const Text = styled.span`
  width: 170px;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  color: ${colors.White};
`;
