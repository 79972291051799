import React, { useEffect, useState, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import styled, { css } from "styled-components";
import axios from "axios";

//tool
import { GetOne } from "../tool/localstorage";

//style
import { Wrapper, Text, } from "../components/layouts/layoutStorage";
import colors from "../styles/colors";
import {PC, W, useWindowSize } from "../styles/globalStyles";

//component
import { Loading } from "../tool/Loading";
import FacilityHeader from "../components/facilityRegister/FacilityHeader";
import FacilityManageModal from "../components/facilityCrew/FacilityManageModal";

//icon
import { ReactComponent as Arrow } from "../resource/icon/arrow.svg";

// Default Data
const IMAGE_RATIO = 234.375 / 375;

const FacilityReservationU = () => {

    const params = useParams();
    const Id = params.id;

    const [loading, setLoading] = useState(false);
    const [facility, setFacility] = useState();
    const [titleList, setTitleList] = useState();
    const [title, setTitle] = useState(0);
    const [center, setCenter] = useState('로딩중...');

    const [selectDay, setSelectDay] = useState({
        year : new Date().getFullYear(),
        month : (new Date().getMonth() + 1),
        day : new Date().getDate()
    });

    const [checkTime, setCheckTime] = useState();

    const [width, setWidth] = useState(useWindowSize());

    let WID = useWindowSize();
    
    useEffect(() => {
        setWidth(WID);
    }, [WID]);

    const NextDay = () => {
        let ThisDate = `${selectDay.year}-${(selectDay.month > 9) ? selectDay.month : `0${selectDay.month}`}-${(selectDay.day > 9) ? selectDay.day : `0${selectDay.day}`}`;
    
        ThisDate = new Date(ThisDate);

        ThisDate.setDate(ThisDate.getDate() + 1);

        setSelectDay({
            year : ThisDate.getFullYear(),
            month : (ThisDate.getMonth() + 1),
            day : ThisDate.getDate()
        })
    }

    const PrevDay = () => {
        let ThisDate = `${selectDay.year}-${(selectDay.month > 9) ? selectDay.month : `0${selectDay.month}`}-${(selectDay.day > 9) ? selectDay.day : `0${selectDay.day}`}`;

        ThisDate = new Date(ThisDate);

        ThisDate.setDate(ThisDate.getDate() - 1);

        setSelectDay({
            year : ThisDate.getFullYear(),
            month : (ThisDate.getMonth() + 1),
            day : ThisDate.getDate()
        })
    }

    const GetData = () => {
        setLoading(true);

        var token = GetOne("token");

        const frm = new FormData();

        frm.append("mode", "facility_reservation_user");
        //frm.append("token", token);
        frm.append("center_id", Id);
        frm.append("year", selectDay.year);
        frm.append("month", (selectDay.month > 9) ? selectDay.month : `0${selectDay.month}`);
        frm.append("day", (selectDay.day > 9) ? selectDay.day : `0${selectDay.day}`);

        axios
        .post("https://ai.semos.kr/semos_partner/v3/activity/Facility", frm, {
            headers: { "Content-Type": "multipart/form-data" },
        })
        .then((data) => {
            var Value = data.data;
            var Status = Value.status;
            var StatusMessage = Value.status_message;
            var FacilityInfo = Value.facility_info;
            var FacilityTitle = Value.facility_title;
            var CenterTitle = Value.center_title;

            if (Status == "success") {
                setFacility(FacilityInfo);
                setTitleList(FacilityTitle);
                setCenter(CenterTitle);

                setLoading(false);
            } else {
                window.alert(StatusMessage);
                setLoading(false);
            }
        });
    };

    useEffect(() => {
        GetData();
    }, [selectDay]);

    useEffect(() => {
        const time = setInterval(() => {
            const Now = new Date();
            const Hour = Now.getHours();

            if(checkTime != Hour) {
                setCheckTime(Hour);
            }
        }, 60000);

        return () => clearInterval(time);
    }, [checkTime]);

    useEffect(() => {
        const Now = new Date();
        const Hour = Now.getHours();

        setCheckTime(Hour);
    }, []);

    const ReturnName = (NAME) => {

        let strName = NAME;

        if (strName.length > 2) {
            var originName = strName.split('');
            originName.forEach(function(strName, i) {
                if (i === 0 || i === originName.length - 1) return;
                originName[i] = '*';
            });
            var joinName = originName.join();
            strName = joinName.replace(/,/g, '');
        } else {
            var pattern = /.$/; // 정규식
            strName = strName.replace(pattern, '*');
        }

        return strName
    }

    const ListReturn = () => {

        //let Check = width < 1300;
        let Check = true;

        switch(Check) {

            case true :
                if(facility){
                    let target = facility[title];
                    const ChartTime = target.chart_time;
                    const ChartUser = target.chart_user;
                    const TicketList = target.ticket_list;
                    const ChartCount = target.chart_count;
                    const TimeList = target.time_list;
                    const FacilityId = target.id;
                    const OffList = target.off_list;
                    const CapacityList = target.capacity_list;
    
                    return (
                        <>
                            <Wrapper
                                display="inline-block"
                                width="100%"
                                height={`50px`}
                                style={{
                                    overflowY : 'hidden',
                                    overflowX : 'auto',
                                    whiteSpace : "nowrap",
                                }}
                            >
                                {
                                    titleList?.map((item, idx) => (
                                        <Wrapper
                                            key={`${idx}_chart`}
                                            display="inline-flex"
                                            padding={`5px 8px`}
                                            radius={`8px`}
                                            border={`1px solid ${(idx == title) ? colors.Bl07 : colors.Gr03}`}
                                            bgc={`${colors.White}`}
                                            ai={`center`}
                                            jc={`center`}
                                            cursor={`pointer`}
                                            margin={(idx == 0) ? `0` : `0 0 0 8px`}
                                            onClick={() => setTitle(idx)}
                                        >
                                            <Text 
                                                ta="center" 
                                                size="1.6rem" 
                                                weight="600" 
                                                color={(idx == title) ? colors.Bl07 :  colors.Gr05}
                                            >
                                                {item}
                                            </Text>
                                        </Wrapper>
                                    ))
                                }
                            </Wrapper>

                            <Wrapper
                                jc="flex-start"
                                display="inline-flex"
                                fd={`column`}
                                ai="center"
                                width="100%"
                                height={`calc(100% - 50px)`}
                                border={`1px solid ${colors.Gr02}`}
                                radius={`8px`}
                                of={`hidden auto`}
                                bgc={`${colors.Gr01}`}
                                wrap={`wrap`}
                            >
                                {
                                            <Wrapper
                                                jc="flex-start"
                                                display="inline-block"
                                                fd={`column`}
                                                ai="center"
                                                width={`calc(100%)`}
                                                height={`auto`}
                                                border={`1px solid ${colors.Gr02}`}
                                                radius={`8px`}
                                                of={`hidden auto`}
                                                bgc={`${colors.Gr01}`}
                                                wrap={`wrap`}
                                            >
                                                <Wrapper
                                                        bb={`solid 1px ${colors.Gr02}`}
                                                        br={`solid 1px ${colors.Gr02}`}
                                                        bgc={`${colors.Gr01}`}
                                                        width={`100%`}
                                                        //height={`45px`}
                                                        position={`sticky`}
                                                        style={{
                                                            top : 0,
                                                            left : 0,
                                                            zIndex : 10,
                                                            minHeight : 45
                                                        }}
                                                >
                                                        <Wrapper
                                                            width={`100%`}
                                                            //height={`45px`}
                                                            ai={`center`}
                                                            jc={`center`}
                                                            bgc={`${colors.Gr01}`}
                                                            style={{
                                                                minHeight : 45
                                                            }}
                                                        >
                                                            <Text 
                                                                ta="center" 
                                                                size={"1.8rem"} 
                                                                weight="600" 
                                                                color={colors.Gr05}
                                                            >
                                                                {target.title}
                                                            </Text>
                                                        </Wrapper>
                        
                                                </Wrapper>    
        
                                                {
                                                        ChartTime?.map((item, idx) => (
                            
                                                            <Wrapper
                                                                key={`${idx}_time_chart`}
                                                                bb={`solid 1px ${colors.Gr02}`}
                                                                br={`solid 1px ${colors.Gr02}`}
                                                                width={`100%`}
                                                                display={`flex`}
                                                                //height={`45px`}
                                                                style={{
                                                                    minHeight : 45
                                                                }}
                                                                bgc={OffList[item] ? 'transparent' : colors.B20}
                                                                cursor={`pointer`}
                                                            >
                                                                <Wrapper
                                                                    width={`20%`}
                                                                    //height={`45px`}
                                                                    //br={`1px solid ${colors.Gr02}`}
                                                                    ai={`center`}
                                                                    jc={`center`}
                                                                    style={{
                                                                        minHeight : 45,
                                                                        height : '100%'
                                                                    }}
                                                                >
                                                                    <Text 
                                                                        ta="center" 
                                                                        size="1.6rem" 
                                                                        weight="600" 
                                                                        color={colors.Gr05}
                                                                    >
                                                                        {item.split(' ')[1]}
                                                                    </Text>
                                                                </Wrapper>
                                
                                                                <Wrapper
                                                                    width={`80%`}
                                                                    //height={`45px`}
                                                                    bl={`1px solid ${colors.Gr02}`}
                                                                    padding={`2px 10px 2px 10px`}
                                                                    wrap={`nowrap`}
                                                                    gap={`5px`}
                                                                    style={{
                                                                        minHeight : 45
                                                                    }}
                                                                >
                                                                    <Wrapper
                                                                        width={`100%`}
                                                                        wrap={`wrap`}
                                                                        jc={`flex-end`}
                                                                        br={`solid 1px ${colors.Gr02}`}
                                                                        padding={`0 3px 0 0 `}
                                                                        gap={`5px`}
                                                                    >
                                                                        {
                                                                            ChartUser[item]?.map((i, id) => (
                                                                                <Text 
                                                                                    key={`${idx}_${id}_chart`}
                                                                                    ta="right" 
                                                                                    size="1.6rem" 
                                                                                    weight="600" 
                                                                                    color={colors.Gr05}
                                                                                    jc={`flex-end`}
                                                                                >
                                                                                    {(id == 0) ? `${ReturnName(i.name)}` : `, ${ReturnName(i.name)}`}
                                                                                </Text>
                                                                            ))
                                                                        }
                                                                        
                                                                    </Wrapper>
                                
                                                                    <Text 
                                                                        ta="right" 
                                                                        size="1.6rem" 
                                                                        weight="600" 
                                                                        width={`50px`}
                                                                        height={`100%`}
                                                                        color={colors.Gr05}
                                                                        ai={`center`}
                                                                        jc={`center`}
                                                                    >
                                                                        {`${ChartCount[item]}/${CapacityList[item]}`}
                                                                    </Text>
                                
                                                                </Wrapper>
                                                            </Wrapper>
                                                        ))
                                                }
                                            </Wrapper>
                                }
                            </Wrapper> 
                        </>
                    )
                }
            break;

            default :
                return (
                    <></>   
                )

        }

    } 

    return (
        <Container id="description_container">

            <FacilityHeader
                status={true}
                title={center ? center : ""}
                available={false}
                statusHandler={() => console.log("신규 회원 등록")}
                backHandler={true}
            />

            <Wrapper
                jc="flex-start"
                ai="center"
                fd={`column`}
                width="100%"
                height={`calc((var(--vh, 1vh) * 100) - 40px)`}
                padding={`0 24px 10px 24px`}
                margin={`0 0 0 0`}
                gap={`10px`}
                bb={`1px solid ${colors.Gr02}`}
            >
                <Wrapper
                    width={`100%`}
                    height={`50px`}
                    jc={'center'}
                    ai={`center`}
                    position={`relative`}
                >
                    <Wrapper
                        margin={`0 3rem 0 0`}
                        cursor={`pointer`}
                        onClick={() => PrevDay()}
                    >
                        <Arrow style={{transform : "rotate(180deg)"}} width="40px" height="40px" fill={colors.Gr06} />
                    </Wrapper>

                    <Text 
                        ta="center" 
                        size="2.2rem" 
                        weight="600" 
                        lh="22px" 
                        ls="-0.28px" 
                        color={colors.Gr05}
                    >
                        {`
                            ${selectDay.year}.
                            ${(selectDay.month > 9) ? selectDay.month : `0${selectDay.month}`}.
                            ${(selectDay.day > 9) ? selectDay.day : `0${selectDay.day}`}
                        `}
                    </Text>

                    <Wrapper
                        margin={`0 0 0 3rem`}
                        cursor={`pointer`}
                        onClick={() => NextDay()}
                    >
                        <Arrow width="40px" height="40px" fill={colors.Gr06} />
                    </Wrapper>

                </Wrapper>

                {ListReturn()}

            </Wrapper>

            {loading && <Loading />}

        </Container>
    );
};

export default FacilityReservationU;

const Container = styled.section`
    height: calc(var(--vh, 1vh) * 100);
    overflow-x: hidden;
    width: 100%;

    .slick-slider {
        width: 100%;
        height: ${W * IMAGE_RATIO}px;
    }
`;